import statistics from '@/store/modules/statistics/statistics'

const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { statistics },
}
