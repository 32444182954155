// Note: It is intended! these numbers do not match up with the db numbers as you should never rely on the values!
// Translate based on the enum!
export enum Partner {
  UNKNOWN_PARTNER = 'UNKNOWN_PARTNER',
  BANCO_DO_BRASIL = 'BANCO_DO_BRASIL',
  BANCOPAN = 'BANCOPAN',
  CREDIHOME = 'CREDIHOME',
  CREDITAS = 'CREDITAS',
  EMPRESTIMO_SIM = 'EMPRESTIMO_SIM',
  NOVERDE = 'NOVERDE',
  PROVU = 'PROVU',
  PORTOCRED = 'PORTOCRED',
  REBEL = 'REBEL',
  SIMPLIC = 'SIMPLIC',
  SUPERSIM = 'SUPERSIM',
  ZEMA = 'ZEMA',
  ITAU = 'ITAU',
  BV = 'BV',
  JEITTO = 'JEITTO',
  AGIL = 'AGIL',
  CREFAZ = 'CREFAZ',
  JBCRED = 'JBCRED',
  HCRED = 'HCRED',
  UPP = 'UPP',
  JUVO = 'JUVO',
  FLOWCREDI = 'FLOWCREDI',
  CONEXAO_FINANCEIRA = 'CONEXAO_FINANCEIRA',
  ZIPPI = 'ZIPPI',
  BLIPAY = 'BLIPAY',
  FACIO = 'FACIO',
}

export enum DocumentType {
  UNKNOWN_DOCUMENT_TYPE = 'UNKNOWN_DOCUMENT_TYPE',
  RG_ID_FRONT = 'RG_ID_FRONT',
  RG_ID_BACK = 'RG_ID_BACK',
  CNH_ID_FRONT = 'CNH_ID_FRONT',
  CNH_ID_BACK = 'CNH_ID_BACK',
  RNE_ID_FRONT = 'RNE_ID_FRONT',
  RNE_ID_BACK = 'RNE_ID_BACK',
  HOLERITE_PROOF_OF_INCOME = 'HOLERITE_PROOF_OF_INCOME',
  BANK_STATEMENT_PROOF_OF_INCOME = 'BANK_STATEMENT_PROOF_OF_INCOME',
  FGTS_PROOF_OF_INCOME = 'FGTS_PROOF_OF_INCOME',
  INCOME_STATEMENT_PROOF_OF_INCOME = 'INCOME_STATEMENT_PROOF_OF_INCOME',
  PROOF_OF_RESIDENCE = 'PROOF_OF_RESIDENCE',
  SELFIE = 'SELFIE',
  PROOF_OF_ID_FRONT = 'PROOF_OF_ID_FRONT',
  PROOF_OF_ID_BACK = 'PROOF_OF_ID_BACK',
  PROOF_OF_INCOME = 'PROOF_OF_INCOME',
  CRLV = 'CRLV',
  VEHICLE_ENGINE = 'VEHICLE_ENGINE',
  VEHICLE_REAR = 'VEHICLE_REAR',
}

export enum IdentificationType {
  IDENTIFICATION_TYPE_NOT_SET = 'IDENTIFICATION_TYPE_NOT_SET',
  PASSPORT = 'PASSPORT',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  ID_CARD = 'ID_CARD',
  RNM = 'RNM',
  CPF_CARD = 'CPF_CARD',
}

export enum IssuingBody {
  UNKNOWN_ISSUING_BODY = 'UNKNOWN_ISSUING_BODY',
  CBME = 'CBME',
  CBMER = 'CBMER',
  CIMCR = 'CIMCR',
  COREN = 'COREN',
  CRA = 'CRA',
  CRAS = 'CRAS',
  CRB = 'CRB',
  CRC = 'CRC',
  CRE = 'CRE',
  CREA = 'CREA',
  CRECI = 'CRECI',
  CREF = 'CREF',
  CREFI = 'CREFI',
  CREP = 'CREP',
  CRF = 'CRF',
  CRM = 'CRM',
  CRN = 'CRN',
  CRO = 'CRO',
  CRP = 'CRP',
  CRPRE = 'CRPRE',
  CRQ = 'CRQ',
  CRRC = 'CRRC',
  CRTA = 'CRTA',
  CRV = 'CRV',
  DEPAD = 'DEPAD',
  DETRA = 'DETRA',
  DIC = 'DIC',
  DNPSC = 'DNPSC',
  DOPS = 'DOPS',
  DPF = 'DPF',
  DPMAF = 'DPMAF',
  DPMAT = 'DPMAT',
  EST = 'EST',
  ESTR = 'ESTR',
  ICLA = 'ICLA',
  IFP = 'IFP',
  IPF = 'IPF',
  MAE = 'MAE',
  MAER = 'MAER',
  MB = 'MB',
  MD = 'MD',
  MEX = 'MEX',
  MJF = 'MJF',
  MM = 'MM',
  MRE = 'MRE',
  MRECC = 'MRECC',
  MTPS = 'MTPS',
  OAB = 'OAB',
  OMB = 'OMB',
  PGJ = 'PGJ',
  PM = 'PM',
  PMERJ = 'PMERJ',
  SDS = 'SDS',
  SEPC = 'SEPC',
  SIM = 'SIM',
  SPTC = 'SPTC',
  SRF = 'SRF',
  SSP = 'SSP',
  TRT = 'TRT',
}

export enum ProductType {
  UNKNOWN_PRODUCT_TYPE = 'UNKNOWN_PRODUCT_TYPE',
  ANTICIPATION_OF_INCOME_TAX_REFUND = 'ANTICIPATION_OF_INCOME_TAX_REFUND',
  AUTO_INSURANCE = 'AUTO_INSURANCE',
  BUSINESS_LOAN = 'BUSINESS_LOAN',
  CAR_FINANCING = 'CAR_FINANCING',
  CASH_FLOW_LOAN = 'CASH_FLOW_LOAN',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT_CARD_WITHDRAW = 'CREDIT_CARD_WITHDRAW',
  DEBT_RENEGOTIATION = 'DEBT_RENEGOTIATION',
  DENTAL_INSURANCE = 'DENTAL_INSURANCE',
  DIGITAL_ACCOUNT = 'DIGITAL_ACCOUNT',
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
  HOME_INSURANCE = 'HOME_INSURANCE',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  PAYROLL_LOAN = 'PAYROLL_LOAN',
  PERSONAL_LOAN = 'PERSONAL_LOAN',
  REAL_ESTATE_FINANCING = 'REAL_ESTATE_FINANCING',
  SECURED_LOAN_CAR = 'SECURED_LOAN_CAR',
  SECURED_LOAN_CELLPHONE = 'SECURED_LOAN_CELLPHONE',
  SECURED_LOAN_HOME = 'SECURED_LOAN_HOME',
  SECURED_LOAN_MOTORCYCLE = 'SECURED_LOAN_MOTORCYCLE',
  TRAVEL_INSURANCE = 'TRAVEL_INSURANCE',
}

export enum DocumentStatus {
  UNKNOWN_STATUS = 'UNKNOWN_STATUS',
  WAITING_FOR_UPLOAD = 'WAITING_FOR_UPLOAD',
  LINK_EXPIRED = 'LINK_EXPIRED',
  RECEIVED = 'RECEIVED',
  CONTAINS_ERRORS = 'CONTAINS_ERRORS',
  SENT_TO_PARTNER_QUEUE = 'SENT_TO_PARTNER_QUEUE',
  REJECTED_BY_PARTNER = 'REJECTED_BY_PARTNER',
  APPROVED_BY_PARTNER = 'APPROVED_BY_PARTNER',
  PARTNER_REQUEST_ERROR = 'PARTNER_REQUEST_ERROR',
  APPROVED_BY_JB = 'APPROVED_BY_JB',
  SUCCESSFULLY_UPLOADED_TO_PARTNER = 'SUCCESSFULLY_UPLOADED_TO_PARTNER',
}

export enum OfferStatus {
  UNKNOWN_OFFER_STATUS = 'UNKNOWN_OFFER_STATUS',
  // When an offer was successfully received after simulating
  // Incoming states: VALIDATING
  PROPOSED = 'PROPOSED',
  // When a user is either rejected based on a credit score on the filter already
  // Incoming states: VALIDATING = '// Incoming states: VALIDATING, ONGOING
  REJECTED = 'REJECTED',
  // When an offer was not finished with the selection process and expired
  // Incoming states: PROPOSED = '// Incoming states: PROPOSED, ONGOING
  EXPIRED = 'EXPIRED',
  // When the money is deposited
  // Incoming states: ONGOING
  GRANTED = 'GRANTED',
  // When the offer went through static checks such as income = '// When the offer went through static checks such as income, postal code etc
  // Incoming states: This is the initial state
  VALIDATING = 'VALIDATING',
  // When the user has selected the offer
  // Incoming states: PROPOSED = '// Incoming states: PROPOSED, RECOVERABLE_ERROR
  ONGOING = 'ONGOING',
  // When the offer was canceled by the user one way or the other
  // Incoming states: ONGOING
  CANCELED = 'CANCELED',
  // Errors that cannot be recovered without interaction of a human
  // Incoming states: ONGOING
  FATAL_ERROR = 'FATAL_ERROR',
  // Errors of temporary nature: time outs / gateway errors
  // Incoming states: ONGOING
  RECOVERABLE_ERROR = 'RECOVERABLE_ERROR',
}

export enum OfferSubStatus {
  UNKNOWN_OFFER_SUB_STATUS = 'UNKNOWN_OFFER_SUB_STATUS',
  // After the static checks when the offer has been submitted for eligibility
  WAITING_FOR_VALIDATION = 'WAITING_FOR_VALIDATION',
  // When the offerSelect message has been submitted to the respective partner select topic
  SUBMITTED_FOR_SELECTION = 'SUBMITTED_FOR_SELECTION',
  // when the offer has successfully been selected by the partner
  SELECTED = 'SELECTED',
  // When not all documents have been uploaded yet
  WAITING_FOR_DOCUMENT_UPLOADS = 'WAITING_FOR_DOCUMENT_UPLOADS',
  // After all documents have successfully been uploaded
  WAITING_FOR_AGREEMENT_ACCEPTANCE = 'WAITING_FOR_AGREEMENT_ACCEPTANCE',
  AGREEMENT_ACCEPTED = 'AGREEMENT_ACCEPTED',
  INFORMATION_INCORRECT = 'INFORMATION_INCORRECT',
  PROBLEM_WITH_DOCUMENT = 'PROBLEM_WITH_DOCUMENT',
}

export enum OfferErrorReason {
  INVALID_BANK_DIGIT = 'INVALID_BANK_DIGIT',
}

export enum State {
  UNKNOWN_STATE = 'UNKNOWN_STATE',
  AC = 'AC',
  AL = 'AL',
  AM = 'AM',
  AP = 'AP',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MG = 'MG',
  MS = 'MS',
  MT = 'MT',
  PA = 'PA',
  PB = 'PB',
  PE = 'PE',
  PI = 'PI',
  PR = 'PR',
  RJ = 'RJ',
  RN = 'RN',
  RO = 'RO',
  RR = 'RR',
  RS = 'RS',
  SC = 'SC',
  SE = 'SE',
  SP = 'SP',
  TO = 'TO',
}

export enum Gender {
  UNKNOWN_GENDER = 'UNKNOWN_GENDER',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum MaritalStatus {
  UNKNOWN_MARITAL_STATUS = 'UNKNOWN_MARITAL_STATUS',
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  WIDOWED = 'WIDOWED',
  DIVORCED = 'DIVORCED',
  STABLE_UNION = 'STABLE_UNION',
}

export enum EducationalStatus {
  UNKNOWN_EDUCATIONAL_LEVEL = 'UNKNOWN_EDUCATIONAL_LEVEL',
  INCOMPLETE_ELEMENTARY = 'INCOMPLETE_ELEMENTARY',
  ELEMENTARY = 'ELEMENTARY',
  INCOMPLETE_HIGH = 'INCOMPLETE_HIGH',
  HIGH = 'HIGH',
  INCOMPLETE_COLLEGE = 'INCOMPLETE_COLLEGE',
  COLLEGE = 'COLLEGE',
  INCOMPLETE_POSTGRADUATE = 'INCOMPLETE_POSTGRADUATE',
  POSTGRADUATE = 'POSTGRADUATE',
}

export enum AutoType {
  UNKNOWN_AUTO_TYPE = 'UNKNOWN_AUTO_TYPE',
  BOAT = 'BOAT',
  CAR = 'CAR',
  MOTORCYCLE = 'MOTORCYCLE',
  PLANE = 'PLANE',
  TRUCK = 'TRUCK',
}

export enum AccountType {
  UNKNOWN_ACCOUNT_TYPE = 'UNKNOWN_ACCOUNT_TYPE',
  CHECKING = 'CHECKING',
  SAVING = 'SAVING',
}

export enum IncomeSource {
  UNKNOWN_INCOME_SOURCE = 'UNKNOWN_INCOME_SOURCE',
  MILITARY = 'MILITARY',
  CLT = 'CLT',
  AUTONOMOUS = 'AUTONOMOUS',
  MEI = 'MEI',
  ENTREPRENEUR = 'ENTREPRENEUR',
  RETIREMENT = 'RETIREMENT',
  UNEMPLOYED = 'UNEMPLOYED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  FEDERAL_WORKER = 'FEDERAL_WORKER',
  PENSION = 'PENSION',
  OTHER_INCOME = 'OTHER_INCOME',
}

export enum AutoManufacturer {
  UNKNOWN_AUTO_MANUFACTURER = 'UNKNOWN_AUTO_MANUFACTURER',
  AGRALE = 'AGRALE',
  ALFA_ROMEO = 'ALFA_ROMEO',
  AUDI = 'AUDI',
  BABY_BUGGY = 'BABY_BUGGY',
  BENTLEY = 'BENTLEY',
  BMW = 'BMW',
  BRM = 'BRM',
  BYD = 'BYD',
  CADILLAC = 'CADILLAC',
  CAOA_CHERY = 'CAOA_CHERY',
  CFMOTO = 'CFMOTO',
  CHAMONIX = 'CHAMONIX',
  CHANA = 'CHANA',
  CHERY = 'CHERY',
  CHEVROLET = 'CHEVROLET',
  CHRYSLER = 'CHRYSLER',
  CITROEN = 'CITROEN',
  D2D_MOTORS = 'D2D_MOTORS',
  DODGE = 'DODGE',
  EFFA = 'EFFA',
  FERRARI = 'FERRARI',
  FIAT = 'FIAT',
  FORD = 'FORD',
  FOTON = 'FOTON',
  FYBER = 'FYBER',
  GMC = 'GMC',
  HITECH = 'HITECH',
  HONDA = 'HONDA',
  HYUNDAI = 'HYUNDAI',
  INFINITI = 'INFINITI',
  IVECO = 'IVECO',
  JAC = 'JAC',
  JAGUAR = 'JAGUAR',
  JEEP = 'JEEP',
  KIA = 'KIA',
  LAMBORGHINI = 'LAMBORGHINI',
  LAND_ROVER = 'LAND_ROVER',
  LEXUS = 'LEXUS',
  LIFAN = 'LIFAN',
  MASERATI = 'MASERATI',
  MAZDA = 'MAZDA',
  MCLAREN = 'MCLAREN',
  MERCEDES_BENZ = 'MERCEDES_BENZ',
  MINI = 'MINI',
  MITSUBISHI = 'MITSUBISHI',
  NISSAN = 'NISSAN',
  PEUGEOT = 'PEUGEOT',
  PONTIAC = 'PONTIAC',
  PORSCHE = 'PORSCHE',
  RAM = 'RAM',
  RENAULT = 'RENAULT',
  ROLLS_ROYCE = 'ROLLS_ROYCE',
  SELVAGEM = 'SELVAGEM',
  SMART = 'SMART',
  SSANGYONG = 'SSANGYONG',
  SUBARU = 'SUBARU',
  SUZUKI = 'SUZUKI',
  TAC = 'TAC',
  TESLA = 'TESLA',
  TOYOTA = 'TOYOTA',
  TROLLER = 'TROLLER',
  VOLKSWAGEN = 'VOLKSWAGEN',
  VOLVO = 'VOLVO',
  WAKE = 'WAKE',
}

export enum ContactMedium {
  UNKNOWN_CONTACT_MEDIUM = 'UNKNOWN_CONTACT_MEDIUM',
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
}

export enum LoanReason {
  UNKNOWN_LOAN_REASON = 'UNKNOWN_LOAN_REASON',
  BUY = 'BUY',
  BILLS = 'BILLS',
  PAY_ANOTHER_LOAN = 'PAY_ANOTHER_LOAN',
  MEDICAL_EXPENSES = 'MEDICAL_EXPENSES',
  HOME_IMPROVEMENT = 'HOME_IMPROVEMENT',
  FIX_CAR = 'FIX_CAR',
  HELP_FAMILY = 'HELP_FAMILY',
  VACATION = 'VACATION',
  EDUCATION_EXPENSES = 'EDUCATION_EXPENSES',
  INVESTMENT = 'INVESTMENT',
  MARRIAGE = 'MARRIAGE',
  OTHER_REASON = 'OTHER_REASON',
}

export enum OfferType {
  UNKNOWN_OFFER_TYPE = 'UNKNOWN_OFFER_TYPE',
  NO_COLLATERAL = 'NO_COLLATERAL',
  AUTO_EQUITY = 'AUTO_EQUITY',
}

export enum FuelType {
  UNKNOWN_FUEL_TYPE = 'UNKNOWN_FUEL_TYPE',
  GASOLINE = 'GASOLINE',
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRIC',
  ALCOHOL = 'ALCOHOL',
}

export enum AccessoryPackage {
  UNKNOWN_PACKAGE = 'UNKNOWN_PACKAGE',
  ARMORED = 'ARMORED',
  BASIC = 'BASIC',
  COMPLETE = 'COMPLETE',
}

export enum RealEstatePropertyType {
  UNKNOWN_PROPERTY_TYPE = 'UNKNOWN_PROPERTY_TYPE',
}

export enum RealEstateBuildingType {
  UNKNOWN_BUILDING_TYPE = 'UNKNOWN_BUILDING_TYPE',
}
