<template>
  <UnloggedLayout>
    <FormContainer>
      <RebelBankAccountForm
        v-if="!busy"
        :data="cptdCurrentForm['bank_account'] || {}"
        @submit="submit"
      />
      <FullscreenLoading v-if="busy">
        <template #loading-message> Solicitando oferta... </template>
      </FullscreenLoading>
      <OfferPartnerTimeoutFeedbackDialog
        v-if="!busy && displayOfferPartnerTimeoutFeedback"
        :display="displayOfferPartnerTimeoutFeedback"
        partner="REBEL"
      />
    </FormContainer>
  </UnloggedLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { waitForAResultWithOptions } from '@/helpers/offers'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const RebelBankAccountForm = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/no-collateral/partners/rebel/RebelBankAccountForm.vue'
    ),
)
const FormContainer = defineAsyncComponent(
  () => import('@/containers/forms/FormContainer.vue'),
)
const FullscreenLoading = defineAsyncComponent(
  () => import('@/components/commomComponents/loaders/FullscreenLoading.vue'),
)
const OfferPartnerTimeoutFeedbackDialog = defineAsyncComponent(
  () => import('@/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    UnloggedLayout,
    RebelBankAccountForm,
    FormContainer,
    FullscreenLoading,
    OfferPartnerTimeoutFeedbackDialog,
  },
  data() {
    return {
      busy: false,
      displayOfferPartnerTimeoutFeedback: false,
      formId: 'REBEL',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/gtNoCollateralOfferSelected',
      gtUser: 'user/user/gtUser',
    }),
    cptdCurrentForm() {
      return this.gtDynamicForms[this.formId] || {}
    },
  },
  created() {
    if (
      !this.gtNoCollateralOfferSelected ||
      !this.gtUser ||
      !this.cptdCurrentForm
    ) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actSelectNoCollateralOffer:
        'products/loans/no-collateral/no-collateral/actSelectNoCollateralOffer',
      createFeedback: 'utils/feedback/createFeedback',
      actGetNoCollateralOfferById:
        'products/loans/no-collateral/no-collateral/actGetNoCollateralOfferById',
      actSetNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/actSetNoCollateralOfferSelected',
      actUpdateForm: 'dynamic-forms/actUpdateForm',
    }),
    async submit(data) {
      this.actUpdateForm({
        formId: this.formId,
        key: 'bank_account',
        value: data,
      })

      await this.selectOffer(data)
    },
    async selectOffer(bank_account) {
      const { offer_id } = this.gtNoCollateralOfferSelected

      this.busy = true
      const selectResponse = await this.actSelectNoCollateralOffer({
        offer: this.gtNoCollateralOfferSelected,
        selectPayload: {
          rebel: {
            bank_account,
            occupation_group: this.cptdCurrentForm.occupation_group,
          },
        },
      })
      if (selectResponse?.status === 200) {
        let offer = null
        try {
          offer = await waitForAResultWithOptions(
            {
              partner: 'REBEL',
              status: 'ONGOING',
              sub_status: 'SELECTED',
            },
            async () => {
              return await this.actGetNoCollateralOfferById(offer_id)
            },
          )
          if (offer) {
            this.actSetNoCollateralOfferSelected(offer)
            this.$router.push({
              path: `/contratacao/sem-garantia/rebel/${offer_id}/redirecionamento`,
            })
          } else {
            this.displayOfferPartnerTimeoutFeedback = true
          }
        } catch (e) {
          if (e?.message === 'MAX_RETRIES_REACHED') {
            this.displayOfferPartnerTimeoutFeedback = true
          } else {
            this.createFeedback({
              text: 'Algo deu errado! Por favor tente novamente.',
            })
          }
        }
      } else {
        this.createFeedback({
          text: 'Algo deu errado! Por favor tente novamente.',
        })
      }
      this.busy = false
    },
  },
}
</script>
<style lang="scss"></style>
