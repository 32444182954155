<template>
  <UnloggedLayout>
    <div class="HomeEquityInteractiveFormPage">
      <HomeEquityInteractiveFormContainer v-bind="$attrs" />
      <ProgressWillBeLostModal
        v-if="displayExitDialog"
        :display="displayExitDialog"
        @close="hideExitModal"
        @exit="redirectToHome"
      />
    </div>
  </UnloggedLayout>
</template>

<script>
import { mapActions } from 'vuex'
import JbOauth from '~/helpers/oauth'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const HomeEquityInteractiveFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/onboarding/HomeEquityInteractiveFormContainer.vue'
    ),
)
// import { randomAbTest } from '~/helpers/abTest'
export default {
  name: 'OnboardingInteractiveFormPage',
  components: {
    UnloggedLayout,
    ProgressWillBeLostModal,
    HomeEquityInteractiveFormContainer,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (JbOauth.getAccessToken()) {
        if (!JbOauth.isAccessTokenValid()) {
          vm.createFeedback({
            text: 'Parece que você já possui uma conta conosco.\nPara continuar, basta realizar o login em sua conta.',
          })
          JbOauth.logout(false)
        }
        vm.$router.push({
          path: '/login',
          query: { ...vm.$route.query, product: 'home_equity' },
        })
        // return
      }
      // if (!vm.$route.query?.jb_ab_2482) {
      //   vm.$router.replace({
      //     query: {
      //       ...vm.$route.query,
      //       jb_ab_2482: randomAbTest('jb_ab_2482', ['A', 'B'])
      //     }
      //   })
      // }
    })
  },
  data() {
    return {
      displayExitDialog: false,
    }
  },
  created() {
    this.gEventBus.on('layout-unlogged:close-clicked', () => {
      this.displayExitModal()
    })
  },
  methods: {
    ...mapActions({
      createFeedback: 'utils/feedback/createFeedback',
    }),
    start() {
      this.gEventBus.emit('layout-unlogged:display-progress-bar')
    },
    redirectToSimulatingPage(extraQuery = {}) {
      const query = { ...(this.$route.query || {}) }
      delete query.form
      delete query.step
      this.$router.push({
        path: '/simulando',
        query: {
          ...query,
          ...extraQuery,
          product: 'home-equity',
        },
      })
    },
    redirectToHome() {
      if (this.$route.query?.form === 'home-equity') {
        this.redirectToSimulatingPage()
      } else if (['user', 'oauth'].includes(this.$route.query?.form)) {
        window.location.href = 'https://jurosbaixos.com.br/'
      } else {
        const query = { ...(this.$route.query || {}) }
        delete query.form
        delete query.step
        this.$router.push({ path: '/home', query })
      }
    },
    displayExitModal() {
      this.displayExitDialog = true
    },
    hideExitModal() {
      this.displayExitDialog = false
    },
  },
}
</script>

<style lang="scss"></style>
