<template>
  <FormVee
    ref="observer"
    :class="cptdClasses"
    class="Form"
    as="div"
    @submit="() => {}"
  >
    <div v-if="busy && busyMessage" class="loading">
      <div class="content">
        <Spinner />
        <p v-if="busyMessage">
          {{ busyMessage }}
        </p>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <h2 class="title"><slot name="title" /></h2>
        <p class="description">
          <slot name="description" />
        </p>
      </div>
      <div class="right">
        <Alert
          v-if="displayAlertError || error"
          ref="errorAlert"
          type="negative"
          :has-close="false"
        >
          {{ alertErrorMessage }}
        </Alert>
        <slot name="content" :validation="validating" />
        <div class="footer">
          <Button
            v-if="displaySubmitButton"
            class="submit"
            :busy="busy"
            type="primary"
            @click="submit"
          >
            {{ lblButtonSubmit }}
          </Button>
        </div>
      </div>
    </div>
  </FormVee>
</template>
<script>
import Spinner from '@/aasgard/components/feedback/Spinner.vue'
import Button from '@/aasgard/components/buttons/Button.vue'
import Alert from '@/aasgard/components/feedback/Alert.vue'
export default {
  name: 'Form',
  components: {
    Spinner,
    Button,
    Alert,
  },
  props: {
    busy: { type: Boolean, default: false },
    busyMessage: { type: String, default: '' },
    displaySubmitButton: { type: Boolean, default: () => true },
    validation: { type: Boolean, default: false },
    lblButtonSubmit: { type: String, default: 'Continuar' },
    portable: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    alertErrorMessage: {
      type: String,
      default: 'Verifique os campos e tente novamente.',
    },
  },
  emits: ['submit'],
  data() {
    return {
      displayAlertError: false,
      formIsValid: false,
      validating: false,
    }
  },
  computed: {
    cptdClasses() {
      return {
        portable: this.portable,
      }
    },
  },
  watch: {
    error() {
      if (this.error) {
        this.checkForm()
      }
    },
  },
  mounted() {
    this.validating = this.validation
    if (this.error) {
      setTimeout(() => this.checkForm(), 1000)
    }
  },
  methods: {
    async checkForm() {
      this.validating = true
      const result = await this.$refs.observer?.validate()
      this.formIsValid = result.valid
      if (!this.formIsValid || this.error) this.handleError()
    },
    handleError() {
      this.displayAlertError = true
      document.body.scrollTo({
        top: this.$refs?.errorAlert?.offsetTop ?? 0,
        behavior: 'smooth',
      })
    },
    async submit() {
      await this.checkForm()
      if (!this.formIsValid) return

      this.displayAlertError = false
      this.$emit('submit')
    },
  },
}
</script>
<style lang="scss">
.Form {
  > .loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @keyframes ani-fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      p {
        margin: 8px 0 0 0;
        color: $color-brand-primary;
        @include subtitle-2;
        animation: ani-fade-in linear 2s infinite;
      }
    }
  }

  > .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    > .left,
    > .right {
      width: 100%;

      &:first-child {
        margin-top: 0;
      }
      > .Alert {
        margin-bottom: 32px;
      }
    }

    > .left {
      > .title {
        margin: 0 0 32px 0;
        @include headline-5;
        color: $color-black-high;
      }

      > .description {
        @include body-1;
        margin: 0 0 16px 0;
        color: $color-black-medium;
        &:empty {
          display: none;
        }
      }
    }

    > .right {
      > *:first-child {
        margin-top: 0;
      }

      > .form-section-subtitle {
        @include subtitle-1;
        color: $color-black-medium;
        margin: 0 0 32px 0;
      }

      > .footer {
        width: 100%;
        padding: 16px;
        position: sticky;
        z-index: 1;
        bottom: 0;
        left: 0;
        background-color: $color-white-high;
        display: flex;
        flex-direction: column;

        & > .Button.submit {
          width: 100%;
        }
      }
    }
  }
}

@include desktop {
  .Form:not(.portable) {
    @include desktop {
      padding-bottom: 0;
    }

    > .content {
      @include desktop {
        flex-wrap: nowrap;
      }

      > .left,
      > .right {
        @include desktop {
          width: 50%;
        }
      }

      > .left {
        @include desktop {
          padding-right: 16px;
        }

        > .title {
          @include desktop {
            font-size: 32px;
            line-height: 48px;
          }
        }
      }

      > .right {
        @include desktop {
          padding-left: 16px;
        }

        > .footer {
          @include desktop {
            //padding: 0;
            //position: relative;
            //left: unset;
            //top: unset;
            //z-index: 0;
          }
        }
      }
    }
  }
}

.Form.portable {
  padding-bottom: 16px;

  > .content {
    > .right {
      > .footer {
        //width: 100%;
        //padding: 0;
        //background-color: $color-white-high;
        //display: flex;
        //flex-direction: column;
        //position: unset;
        //z-index: 0;
      }
    }
  }
}
</style>
