<template>
  <ClearLayout>
    <AutoEquityProfileFormContainer
      :progress="{
        step: 1,
        steps: 3,
        type: 'stepped',
      }"
      @submit="submit"
    />
  </ClearLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const AutoEquityProfileFormContainer = defineAsyncComponent(
  () =>
    import('@/containers/forms/loans/auto/AutoEquityProfileFormContainer.vue'),
)
export default {
  name: 'EmprestimoSimVerificarDadosPage',
  components: {
    ClearLayout,
    AutoEquityProfileFormContainer,
  },
  data() {
    return {
      formId: 'EMPRESTIMO_SIM',
    }
  },
  computed: {
    ...mapGetters({
      gtUser: 'user/user/gtUser',
      gtColdOfferSelected:
        'products/loans/auto-equity/cold-offer/gtColdOfferSelected',
    }),
    cptdCurrentForm() {
      if (this.gtDynamicForms && this.gtDynamicForms[this.formId]) {
        return this.gtDynamicForms[this.formId]
      }

      return {}
    },
  },
  created() {
    if (!this.gtColdOfferSelected || !this.gtUser || !this.cptdCurrentForm) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    submit() {
      this.$router.push({
        path: `/forms/emprestimo/auto/parceiros/emprestimo_sim/simular/dados-veiculo`,
      })
    },
  },
}
</script>
<style lang="scss"></style>
