<template>
  <button
    ref="ButtonComponent"
    class="Button"
    :class="[type, size, variation, cssBorderRadius]"
    :disabled="disabled"
    :autofocus="autoFocus"
    @click="onClick($event)"
  >
    <slot v-if="!busy" name="prefix" />
    <span v-if="!busy" class="text"><slot /></span>
    <slot v-if="!busy" name="suffix" />
    <Spinner v-if="busy" :theme="spinnerTheme" size="sm" />
  </button>
</template>

<script>
import Spinner from '../feedback/Spinner.vue'

export default {
  name: 'Button',
  components: {
    Spinner,
  },
  props: {
    autoFocus: { type: Boolean, default: false },
    busy: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    borderRadius: { type: Boolean, default: true },
    size: {
      type: String,
      default: 'large',
      validator(value) {
        return ['small', 'medium', 'large'].includes(value)
      },
    },
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'ghost', 'outlined', 'icon'].includes(value)
      },
    },
    variation: {
      type: String,
      default: '',
      required: false,
      validator(value) {
        return (
          ['info', 'negative', 'positive', 'warning', 'inversed'].includes(
            value,
          ) || value === ''
        )
      },
    },
  },
  emits: ['click'],
  computed: {
    cssBorderRadius() {
      return !this.borderRadius ? 'standard' : ''
    },
    spinnerTheme() {
      if (this.type === 'primary') {
        return 'brand-dark'
      }

      return 'brand-light'
    },
  },
  watch: {
    busy(v) {
      const ButtonComponent = this.$refs.ButtonComponent
      if (!v) {
        ButtonComponent.style = ''
        return
      }

      ButtonComponent.style = `width: ${ButtonComponent.clientWidth}px`
    },
  },
  methods: {
    onClick($event) {
      if (this.busy) return

      this.$emit('click', $event)
    },
  },
}
</script>

<style lang="scss">
.Button {
  cursor: pointer;
  transition: all 0.1s ease;
  white-space: nowrap;
  border-radius: 10000px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .text {
    margin: 0 4px;
  }

  &:not([disabled]) {
    @include hover-default;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.standard {
    border-radius: unset;
  }

  &.icon {
    color: $color-brand-primary;
    fill: $color-brand-primary;
    background-color: $color-white-high;
    border: none;

    &[disabled] {
      fill: $color-black-low;
    }

    &.info {
      .BaseIcon {
        fill: $color-feedback-info;
      }
    }

    &.negative {
      color: $color-feedback-negative;
    }

    &.positive {
      color: $color-feedback-positive;
    }

    &.warning {
      color: $color-feedback-warning;
    }
  }

  &.ghost {
    color: $color-brand-primary;
    background-color: transparent;
    border: none;

    &[disabled] {
      color: $color-black-low;
    }

    &.info {
      color: $color-feedback-info;
    }

    &.negative {
      color: $color-feedback-negative;
    }

    &.positive {
      color: $color-feedback-positive;
    }

    &.warning {
      color: $color-feedback-warning;
    }

    &.inversed {
      color: $color-white-high;

      &[disabled] {
        color: $color-white-low;
      }
    }
  }

  &.outlined {
    color: $color-brand-primary;
    background-color: transparent;
    border: solid 1px $color-brand-primary;

    &[disabled] {
      color: $color-black-low;
      border: solid 1px $color-black-low;
    }

    &.info {
      color: $color-feedback-info;
      border: solid 1px $color-feedback-info;
    }

    &.negative {
      color: $color-feedback-negative;
      border: solid 1px $color-feedback-negative;
    }

    &.positive {
      color: $color-feedback-positive;
      border: solid 1px $color-feedback-positive;
    }

    &.warning {
      color: $color-feedback-warning;
      border: solid 1px $color-feedback-warning;
    }

    &.inversed {
      color: $color-white-high;
      border: solid 1px $color-white-high;

      &[disabled] {
        color: $color-white-low;
        border: solid 1px $color-white-low;
      }
    }
  }

  &.primary {
    background-color: $color-brand-primary;
    color: $color-white-high;
    border: none;

    &[disabled] {
      background-color: $color-black-low;
      color: $color-black-low;
      border: none;
    }

    &.info {
      background-color: $color-feedback-info;
    }

    &.negative {
      background-color: $color-feedback-negative;
    }

    &.positive {
      background-color: $color-feedback-positive;
    }

    &.warning {
      background-color: $color-feedback-warning;
    }

    &.inversed {
      color: $color-brand-primary;
      background-color: $color-white-high;

      &[disabled] {
        background-color: $color-white-lower;
        color: $color-white-medium;
      }
    }
  }

  &.small {
    @include subtitle-2;
    height: 32px;
    padding: 0 8px;
  }

  &.medium {
    @include subtitle-2;
    height: 48px;
    padding: 0 12px;
  }

  &.large {
    @include subtitle-1;
    height: 48px;
    padding: 0 12px;
  }
}
</style>
