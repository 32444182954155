<template>
  <div class="Notification bounceIn" :class="[type]">
    <slot v-if="!typeDot" />
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    type: {
      type: String,
      default: 'number',
      validator(value) {
        return ['number', 'dot'].includes(value) || value === 'number'
      },
    },
  },
  computed: {
    typeDot() {
      return this.type === 'dot'
    },
  },
}
</script>

<style lang="scss">
.Notification {
  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  &.bounceIn {
    animation-duration: calc(1s * 0.75);
    animation-name: bounceIn;
  }
  @include caption;
  padding: 0 4px;
  background-color: $color-feedback-negative;
  color: $color-white-high;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;

  &.dot {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
  }

  &.number {
    min-width: 16px;
    border-radius: 1000px;
  }

  &,
  * {
    box-sizing: border-box;
  }
}
</style>
