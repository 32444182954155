export const CredihomeHiringSteps = [
  {
    name: 'Verificar elegibilidade',
    status: 'done',
  },
  {
    name: 'Fazer simulação',
    status: 'done',
  },
  {
    name: 'Solicitar oferta',
    status: 'done',
  },
  {
    name: 'Aguardar contato da Credihome',
    status: 'not-started',
  },
  {
    name: 'Enviar documentação',
    status: 'not-started',
  },
  {
    name: 'Aguardar análise',
    status: 'not-started',
  },
  {
    name: 'Liberação de empréstimo',
    status: 'not-started',
  },
]
