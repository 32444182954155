import { DocumentType } from '@/data/common'
import {
  retrieveOfferDocsEntriesUnauth,
  refreshOfferDocsLinksUnauth,
  getOfferDocumentUnauth,
  deleteOfferDocumentUnauth,
} from '@/api/appdocs/cx'

const UnifiedDocumentTypeRemap: Record<
  DocumentType | string,
  DocumentType | string
> = {
  UNKNOWN_DOCUMENT_TYPE: DocumentType.UNKNOWN_DOCUMENT_TYPE,
  RG_ID_FRONT: DocumentType.PROOF_OF_ID_FRONT,
  RG_ID_BACK: DocumentType.PROOF_OF_ID_BACK,
  CNH_ID_FRONT: DocumentType.PROOF_OF_ID_FRONT,
  CNH_ID_BACK: DocumentType.PROOF_OF_ID_FRONT,
  RNE_ID_FRONT: DocumentType.PROOF_OF_ID_FRONT,
  RNE_ID_BACK: DocumentType.PROOF_OF_ID_FRONT,
  PROOF_OF_ID_FRONT: DocumentType.PROOF_OF_ID_FRONT,
  PROOF_OF_ID_BACK: DocumentType.PROOF_OF_ID_BACK,
  HOLERITE_PROOF_OF_INCOME: DocumentType.PROOF_OF_INCOME,
  BANK_STATEMENT_PROOF_OF_INCOME: DocumentType.PROOF_OF_INCOME,
  FGTS_PROOF_OF_INCOME: DocumentType.PROOF_OF_INCOME,
  INCOME_STATEMENT_PROOF_OF_INCOME: DocumentType.PROOF_OF_INCOME,
  PROOF_OF_RESIDENCE: DocumentType.PROOF_OF_RESIDENCE,
  SELFIE: DocumentType.SELFIE,
  PROOF_OF_INCOME: DocumentType.PROOF_OF_INCOME,
  CRLV: DocumentType.CRLV,
  VEHICLE_ENGINE: DocumentType.VEHICLE_ENGINE,
  VEHICLE_REAR: DocumentType.VEHICLE_REAR,
}

// STATE
const state = (): any => ({
  allDocuments: undefined,
  currentDocument: undefined,
})

// GETTERS
const getters = {
  gtOfferDocumentsUnauth(state): any {
    return state.allDocuments
  },
  gtCurrentDocumentDataUnauth(state): any {
    return state.currentDocument
  },
}

// MUTATIONS
const mutations = {
  UPDATE_OFFER_DOCS_UNAUTH(state, payload): void {
    state.allDocuments = payload?.map((v) => {
      return {
        ...v,
        name: UnifiedDocumentTypeRemap[v.type],
      }
    })
  },
  UPDATE_DOC_UNAUTH(state, payload): void {
    state.currentDocument = payload
  },
}

// ACTIONS
const actions = {
  async actPutDocS3({ dispatch }, payload) {
    const errorMessage = {
      text: 'Oops! algo deu errado. Tente novamente!',
    }
    try {
      const response = await fetch(payload.url, {
        method: 'PUT',
        headers: {
          'Content-Type': payload.file.type,
        },
        body: payload.file,
      })
      const { status } = response
      if (status !== 200) {
        dispatch('utils/feedback/createFeedback', errorMessage, {
          root: true,
        })
      }
      return response
    } catch (error) {
      dispatch('utils/feedback/createFeedback', errorMessage, {
        root: true,
      })
    }
  },

  // send documents
  async actRetrieveOfferDocsUnauth(
    { commit, dispatch },
    { offer_id, product, payload, user_id },
  ) {
    try {
      const response = await retrieveOfferDocsEntriesUnauth({
        payload,
        offer_id,
        user_id,
        product,
      })
      commit('UPDATE_OFFER_DOCS_UNAUTH', response?.data)
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },

  async actRefreshOfferDocsLinksUnauth(
    { commit, dispatch, state },
    { offer_id, user_id },
  ) {
    try {
      const response = await refreshOfferDocsLinksUnauth({
        offer_id,
        user_id,
      })
      const { data } = response
      if (data) {
        commit('UPDATE_OFFER_DOCS_UNAUTH', data)

        const currentDoc = state.currentDocument
        if (currentDoc) {
          const newDoc = data.find(
            (doc) =>
              !!doc?.id &&
              !!currentDoc?.id &&
              doc.id === state.currentDocument?.id,
          )
          if (newDoc) {
            commit('UPDATE_DOC_UNAUTH', newDoc)
          }
        }
      }
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },
  // get specify document

  async actGetDocumentUnauth(
    { commit, dispatch },
    { offer_id, product, doc_id, user_id },
  ) {
    try {
      const response = await getOfferDocumentUnauth({
        offer_id,
        product,
        doc_id,
        user_id,
      })
      commit('UPDATE_DOC_UNAUTH', response?.data)
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },

  // delete a document
  async actDeleteDocumentUnauth(
    { dispatch },
    { offer_id, product, doc_id, user_id },
  ) {
    try {
      return await deleteOfferDocumentUnauth({
        offer_id,
        user_id,
        product,
        doc_id,
      })
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
