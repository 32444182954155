import BankAccountValidatorTool from '../../digitCalculator'
import utilities from '@/lib/accountValidator/bank-validator-js/utilities'

function checkBbBankAccount(number, number_digit, branch) {
  const branchPadded = utilities.padStart(branch, 4)
  if (branchPadded.length > 4) return false

  const numberPadded = utilities.padStart(number, 8)
  if ((numberPadded + number_digit).length > 9) return false

  const digit_calculator = new BankAccountValidatorTool.Banks.DigitCalculator(
    numberPadded,
    [9, 8, 7, 6, 5, 4, 3, 2],
  )

  const digit = digit_calculator.mod(11)

  if (digit === 10) return number_digit === 'X'
  if (digit === 11) return number_digit === '0'

  return String(digit) === number_digit
}

export default checkBbBankAccount
