import autoEquity from '@/store/modules/products/loans/auto-equity'
import homeEquity from '@/store/modules/products/loans/home-equity'
import noCollateral from '@/store/modules/products/loans/no-collateral'
const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    'auto-equity': autoEquity,
    'home-equity': homeEquity,
    'no-collateral': noCollateral,
  },
}
