<template>
  <Component :is="icon" :class="['BaseIcon', size, mono]" v-bind="$attrs" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'BaseIcon',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
      validator(value) {
        return ['xs', 'sm', 'md', 'lg', 'xl', 'unset'].includes(value)
      },
    },
  },
  computed: {
    icon() {
      try {
        if (!this.name) {
          return ''
        }
        return defineAsyncComponent(
          () =>
            import(`./../../assets/img/icons/ico-${this.name}.svg?component`),
        )
      } catch (error) {
        return ''
      }
    },
    mono() {
      if (this.name && this.name.includes('mono')) return 'mono'

      return ''
    },
  },
}
</script>

<style lang="scss">
.BaseIcon {
  &.mono {
    @include setSVGMonoColor($color-black-high, null);
  }

  &.xs {
    height: 16px;
    width: 16px;
  }
  &.sm {
    height: 24px;
    width: 24px;
  }

  &.md {
    height: 32px;
    width: 32px;
  }

  &.lg {
    height: 48px;
    width: 48px;
  }

  &.xl {
    height: 64px;
    width: 64px;
  }
}
</style>
