import { sentryException } from '@/helpers/sentry'

const normalizeKey = (key = '') => key?.replace(/-/gi, '_')?.toLowerCase()

const gaEventToGa4Event = (event: GaEvent): Ga4Event => {
  return {
    command: 'event',
    event: normalizeKey(event.eventCategory ?? ''),
    params: {
      action: event.eventAction,
      label: event.eventLabel,
      variant: event.variant || 'default',
      ...(!!event.ab_test && { ab_test: event.ab_test }),
      ...(!!event.source && { source: event.source }),
    },
  }
}

export interface GaEvent {
  eventCategory: string
  eventAction: string
  eventLabel: string
  source?: string
  ab_test?: string
  variant?: string
}

export interface Ga4Event {
  command: string
  event: string
  params: Record<string, any>
}

const actions = {
  actEmitGaEvent({ dispatch }, gaEvent: GaEvent): void {
    // TODO: CHECK LATER
    // this.$ga.event({
    //   eventCategory: gaEvent.eventCategory,
    //   eventAction: gaEvent.eventAction,
    //   eventLabel: gaEvent.eventLabel
    // })
    dispatch('actEmitGa4Event', gaEventToGa4Event(gaEvent))
  },
  actEmitGa4Event(_, { command, event, params }: Ga4Event): void {
    // eslint-disable-next-line
    // @ts-ignore
    if (window?.gtag) {
      try {
        window.gtag(command, event, {
          ...params,
          debug_mode: import.meta.env.DEV,
        })
      } catch (e: any) {
        sentryException(e)
      }
    }
  },
  actEmitGa4ConfigUser({ dispatch }, user_id) {
    dispatch('actEmitGa4Event', {
      command: 'config',
      event: import.meta.env.VITE_GA4_ID,
      params: { user_id },
    } as Ga4Event)
  },
  actEmitGa4LoginEvent({ dispatch }, { user_id, method }) {
    dispatch('actEmitGa4ConfigUser', user_id)

    dispatch('actEmitGa4Event', {
      command: 'event',
      event: 'login',
      params: {
        method,
      },
    } as Ga4Event)
  },
  actEmitGa4SignUpEvent({ dispatch }, { user_id, method }) {
    dispatch('actEmitGa4ConfigUser', user_id)
    dispatch('actEmitGa4Event', {
      command: 'event',
      event: 'sign_up',
      params: {
        method,
      },
    } as Ga4Event)
  },
}

export default { namespaced: true, actions }
