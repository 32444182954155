<template>
  <FeedbackDialog class="OfferFeedbackDialog" :persisted="true" v-bind="$attrs">
    <div class="offer-feedback-wrapper">
      <div class="offer-feedback-content">
        <slot name="content" />
      </div>
      <div v-if="requirements && requirements.length > 0" class="requirements">
        <ul>
          <li v-for="(req, index) in requirements" :key="index">
            <notification type="dot" />
            <p>{{ req }}</p>
          </li>
        </ul>
      </div>

      <div v-if="alternatives && alternatives.length > 0" class="alternatives">
        <h3>O que você pode tentar</h3>
        <ListItem
          v-for="(alt, index) in alternatives"
          :key="index"
          @click="$router.push(alt.path)"
        >
          <template v-if="alt.title" #title>
            {{ alt.title }}
          </template>
          <template v-if="alt.description" #description>
            {{ alt.description }}
          </template>
        </ListItem>
      </div>
    </div>
  </FeedbackDialog>
</template>
<script>
import FeedbackDialog from '@/components/commomComponents/feedback/FeedbackDialog.vue'
import ListItem from '@/aasgard/components/lists/ListItem.vue'
import Notification from '@/aasgard/components/feedback/Notification.vue'
export default {
  name: 'OfferFeedbackDialog',
  components: {
    FeedbackDialog,
    ListItem,
    Notification,
  },
  props: {
    requirements: { type: Array, default: () => [] },
    alternatives: { type: Array, default: () => [] },
  },
}
</script>
<style lang="scss">
.OfferFeedbackDialog {
  & > .content-wrapper > .content {
    & > .offer-feedback-wrapper {
      padding: 8px 0;

      & > .offer-feedback-content {
        padding: 8px 0;
        @include body-1;
        text-align: center;
        color: $color-black-high;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:empty {
          margin: 0;
          padding: 0;
        }
      }
      & > .requirements {
        padding: 16px;
        width: 100%;

        & > ul {
          list-style: none;
          margin: 0;
          padding: 0;

          & > li {
            display: flex;
            align-items: center;
            margin: 0 0 16px 0;

            &:last-child {
              margin: 0;
            }

            & > .dot {
              background: $color-feedback-positive;
              width: 4px;
              min-width: 4px;
              height: 4px;
              min-height: 4px;
              margin-right: 16px;
            }

            & p {
              @include body-2;
              color: $color-black-medium;
              margin: 0;
            }
          }
        }
      }
      & > .alternatives {
        background: $color-neutral-light;
        padding: 16px;
        margin: 8px 0;
        border-radius: 12px;
        width: 100%;

        & > h3 {
          @include subtitle-1;
          text-align: center;
          color: $color-black-high;
          margin-bottom: 24px;
        }

        & > .ListItem {
          border-radius: 4px;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
</style>
