import BankAccountValidatorTool from '../../digitCalculator'
import utilities from '@/lib/accountValidator/bank-validator-js/utilities'

function checkCaixaBankAccount(number, number_digit, branch) {
  const branchPadded = utilities.padStart(branch, 4)
  if (branchPadded.length > 4) return false

  let numberPadded = utilities.padStart(number, 11)
  if ((numberPadded + number_digit).length > 12) return false
  numberPadded = '001'.concat(numberPadded.slice(3))

  const fullAccountNumberWithoutDigit = branch + numberPadded
  const digit_calculator = new BankAccountValidatorTool.Banks.DigitCalculator(
    fullAccountNumberWithoutDigit,
    [8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2],
  )

  const sumDigit = digit_calculator.sum_numbers() * 10
  const divDigit = Math.floor(sumDigit / 11)
  const mulDigit = divDigit * 11
  const digit = sumDigit - mulDigit

  if (digit === 10) {
    return number_digit === '0'
  }

  return String(digit) === number_digit
}

export default checkCaixaBankAccount
