<template>
  <UnloggedLayout>
    <div class="FGTSInteractiveFormPage">
      <FGTSInteractiveFormContainer v-bind="$attrs" />
      <ProgressWillBeLostModal
        v-if="displayExitDialog"
        :display="displayExitDialog"
        @close="hideExitModal"
        @exit="redirectToHome"
      />
    </div>
  </UnloggedLayout>
</template>
<script>
import { mapActions } from 'vuex'
import JbOauth from '~/helpers/oauth'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const FGTSInteractiveFormContainer = defineAsyncComponent(
  () =>
    import('@/containers/forms/onboarding/FGTSInteractiveFormContainer.vue'),
)
// import { randomAbTest } from '~/helpers/abTest'
export default {
  name: 'OnboardingInteractiveFormPage',
  components: {
    UnloggedLayout,
    ProgressWillBeLostModal,
    FGTSInteractiveFormContainer,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (JbOauth.getAccessToken()) {
        if (!JbOauth.isAccessTokenValid()) {
          vm.createFeedback({
            text: 'Parece que você já possui uma conta conosco.\nPara continuar, basta realizar o login em sua conta.',
          })
          JbOauth.logout(false)
        }
        vm.$router.push({
          path: '/login',
          query: { ...vm.$route.query, product: 'fgts' },
        })
        // return
      }
      // if (!vm.$route.query?.jb_ab_2482) {
      //   vm.$router.replace({
      //     query: {
      //       ...vm.$route.query,
      //       jb_ab_2482: randomAbTest('jb_ab_2482', ['A', 'B'])
      //     }
      //   })
      // }
    })
  },
  data() {
    return {
      displayExitDialog: false,
    }
  },
  created() {
    this.gEventBus.on('layout-unlogged:close-clicked', () => {
      this.displayExitModal()
    })
  },
  methods: {
    ...mapActions({
      createFeedback: 'utils/feedback/createFeedback',
    }),
    start() {
      this.gEventBus.emit('layout-unlogged:display-progress-bar')
    },
    redirectToHome() {
      window.location.href = 'https://jurosbaixos.com.br/'
    },
    displayExitModal() {
      this.displayExitDialog = true
    },
    hideExitModal() {
      this.displayExitDialog = false
    },
  },
}
</script>
<style lang="scss"></style>
