import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { OfferStatus } from '@/data/common'

export const fetchNoCollateralOffers = async (payload: {
  status?: OfferStatus | Array<OfferStatus>
  simulationId?: string
}) => {
  return await JurosBaixosSystemsAPI.get(`loans/no-collateral/offers`, {
    params: Object.keys(payload).length > 0 ? payload : {},
  }).catch(createRequestException)
}

export const fetchNoCollateralOfferById = async (offerId) => {
  return await JurosBaixosSystemsAPI.get(
    `loans/no-collateral/offers/${offerId}`,
  ).catch(createRequestException)
}

export const fetchNoCollateralOfferUnauth = async ({
  userId,
  options = { status: OfferStatus.PROPOSED },
}) => {
  const queryParams = Object.keys(options).length > 0 ? options : {}
  const params = {
    params: queryParams,
  }

  return await JurosBaixosSystemsAPI.get(
    `/user/${userId}/loans/no-collateral/offers`,
    params,
  ).catch(createRequestException)
}
