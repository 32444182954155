<template>
  <LoggedLayout class="OnboardingSimulatingPage">
    <SimulatingContainer />
  </LoggedLayout>
</template>
<script>
import LoggedLayout from '@/layouts/LoggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const SimulatingContainer = defineAsyncComponent(
  () => import('@/containers/simulation/simulatingContainer.vue'),
)

export default {
  name: 'PageSimulating',
  components: {
    LoggedLayout,
    SimulatingContainer,
  },
}
</script>
<style lang="scss">
.OnboardingSimulatingPage {
  & > .main {
    & > .ContainerWrapper {
      @include media('lg') {
        max-width: unset !important;
      }
    }
  }
}
</style>
