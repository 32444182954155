export enum TierSubscription {
  UNKNOWN_TIER_SUBSCRIPTION = 'UNKNOWN_TIER_SUBSCRIPTION',
  PAID = 'PAID',
  FREE = 'FREE',
}

export enum ProductSubscription {
  UNKNOWN_PRODUCT_SUBSCRIPTION = 'UNKNOWN_PRODUCT_SUBSCRIPTION',
  JBPRIME = 'JBPRIME',
}

export interface Subscription {
  tier: TierSubscription
  valid_until: string
  product: ProductSubscription
}
