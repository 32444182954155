<template>
  <div class="ProgressBar">
    <div class="progress" :style="currentProgressStyle" />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100
      },
    },
  },
  computed: {
    currentProgressStyle() {
      return `width: ${this.progress}%;`
    },
  },
}
</script>

<style lang="scss">
.ProgressBar {
  width: 100%;
  height: 4px;
  position: relative;
  background-color: $color-neutral-dark;

  &,
  * {
    box-sizing: border-box;
  }

  .progress {
    background-color: $color-brand-primary;
    width: 0;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s linear;
  }
}
</style>
