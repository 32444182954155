<template>
  <ClearLayout>
    <OfferPartnerRedirectModal :partner="cptdPartner" :url="cptdOfferUrl">
      <template #title>
        {{ cptdTitleRedirect }}
      </template>
      <template #alert-message>
        {{ cptdRedirectMessage }}
      </template>
    </OfferPartnerRedirectModal>
  </ClearLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PartnerRedirectLinks, PartnersName } from '@/helpers/partners/common'
import { Partner } from '@/data/common'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
export default {
  components: {
    ClearLayout,
    OfferPartnerRedirectModal,
  },
  computed: {
    ...mapGetters({
      gtNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/gtNoCollateralOfferSelected',
    }),
    cptdPartner() {
      const { partner } =
        this.$route?.params ?? this.gtNoCollateralOfferSelected?.partner ?? ''
      return partner?.toUpperCase() ?? ''
    },
    cptdOfferUrl() {
      switch (this.cptdPartner) {
        case Partner.SUPERSIM:
          return (
            this.gtNoCollateralOfferSelected?.url ??
            PartnerRedirectLinks.NO_COLLATERAL[Partner.SUPERSIM] ??
            ''
          )
        default:
          return (
            PartnerRedirectLinks.NO_COLLATERAL[
              this.gtNoCollateralOfferSelected?.partner ?? ''
            ]?.() ??
            this.gtNoCollateralOfferSelected?.url ??
            ''
          )
      }
    },
    cptdPartnerNameFormatted() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdTitleRedirect() {
      return `Tudo certo! Finalize a contratação da sua oferta com a ${this.cptdPartnerNameFormatted}`
    },
    cptdRedirectMessage() {
      return `Este serviço é uma parceria com a ${this.cptdPartnerNameFormatted} e o processo continuará em seu site.`
    },
  },
  async mounted() {
    if (!this.gtNoCollateralOfferSelected) {
      const { offer_id } = this.$route?.params
      const offer = await this.actGetNoCollateralOfferById(offer_id)?.data
      this.actSetNoCollateralOfferSelected(offer)

      if (!offer) {
        this.createFeedback({ text: 'Oferta não encontrada' })
        this.scheduleRedirectTo('/ofertas')
      }
    }
  },
  methods: {
    ...mapActions({
      actGetNoCollateralOfferById:
        'products/loans/no-collateral/no-collateral/actGetNoCollateralOfferById',
      actSetNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/actSetNoCollateralOfferSelected',
      createFeedback: 'utils/feedback/createFeedback',
    }),
    scheduleRedirectTo(path) {
      setTimeout(() => {
        this.$router.push({
          path,
        })
      }, 6000)
    },
  },
}
</script>

<style lang="scss"></style>
