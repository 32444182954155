<template>
  <ClearLayout>
    <div class="DadosPerfilPage">
      <NavBar
        title="Dados do perfil"
        :extended="true"
        @back="$router.back()"
        @close="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <UserProfileContainer class="profile-container" />
        <PersonaInfoProfileContainer class="profile-container" />
        <PersonaResidenceProfileContainer class="profile-container" />
        <PersonaFinanceProfileContainer class="profile-container" />
        <PersonaFinanceBankAccountProfileContainer class="profile-container" />
      </ContentContainerWrapper>
    </div>
  </ClearLayout>
</template>
<script>
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'

const UserProfileContainer = defineAsyncComponent(
  () => import('@/containers/profile/UserProfileContainer.vue'),
)
const PersonaInfoProfileContainer = defineAsyncComponent(
  () => import('@/containers/profile/PersonaInfoProfileContainer.vue'),
)
const PersonaResidenceProfileContainer = defineAsyncComponent(
  () => import('@/containers/profile/PersonaResidenceProfileContainer.vue'),
)
const PersonaFinanceProfileContainer = defineAsyncComponent(
  () => import('@/containers/profile/PersonaFinanceProfileContainer.vue'),
)
const PersonaFinanceBankAccountProfileContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/profile/PersonaFinanceBankAccountProfileContainer.vue'
    ),
)

export default {
  name: 'DadosPerfilPage',
  components: {
    ClearLayout,
    UserProfileContainer,
    ContentContainerWrapper,
    NavBar,
    PersonaInfoProfileContainer,
    PersonaResidenceProfileContainer,
    PersonaFinanceProfileContainer,
    PersonaFinanceBankAccountProfileContainer,
  },
  data() {
    return {
      busy: true,
    }
  },
}
</script>
<style lang="scss">
.DadosPerfilPage {
  .profile-container {
    margin-bottom: 16px;

    &:last-child {
      margin: 0;
    }
  }
}
</style>
