<template>
  <ClearLayout>
    <ProofAutoRearContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProofAutoRearContainer = defineAsyncComponent(
  () => import('@/containers/newAppDocs/ProofAutoRearContainer.vue'),
)
export default {
  name: 'ProofAutoRearPage',
  components: {
    ClearLayout,
    ProofAutoRearContainer,
  },
}
</script>
<style lang="scss"></style>
