import { AxiosResponse } from 'axios'
import {
  CarModelRequestUriParams,
  CarModelResponse,
  CreditasCarModelResponse,
  FindPlateSummaryResponse,
} from '@/data/loans/auto/vehicle'
import { getCarModel, getCarModelByPlate } from '@/api/car/car'

// STATE
const state = (): any => ({
  carModelsList: [],
  getByPlateResult: {},
})

// GETTERS
const getters = {
  gtCarModelsList(
    state,
  ): Array<CarModelResponse | CreditasCarModelResponse> | null {
    return state.carModelsList
  },
  gtGetByPlateResult(state): FindPlateSummaryResponse | null {
    return state.getByPlateResult
  },
}

// MUTATIONS
enum MutationTypes {
  UPDATE_CAR_MODELS = 'UPDATE_CAR_MODELS',
  UPDATE_GET_BY_PLATE_RESULT = 'UPDATE_GET_BY_PLATE_RESULT',
}

const mutations = {
  [MutationTypes.UPDATE_CAR_MODELS](state, payload) {
    state.carModelsList = payload
  },
  [MutationTypes.UPDATE_GET_BY_PLATE_RESULT](state, payload) {
    state.getByPlateResult = payload
  },
}

// ACTIONS

const actions = {
  async actGetCarModelsList(
    { commit, dispatch },
    {
      payload,
      options,
    }: {
      payload: CarModelRequestUriParams
      options: Record<string, string | boolean | number> | undefined
    },
  ): Promise<
    AxiosResponse<Array<CarModelResponse | CreditasCarModelResponse>>
  > {
    try {
      const response = await getCarModel(payload, options)
      const { status, data } = response
      commit(MutationTypes.UPDATE_CAR_MODELS, status === 200 ? data : [])
      return response
    } catch (e: any) {
      commit(MutationTypes.UPDATE_CAR_MODELS, [])
      dispatch('utils/feedback/createFeedback', 'Oops! algo deu errado.', {
        root: true,
      })
      return e.response
    }
  },
  actResetGetByPlateResult({ commit }) {
    commit(MutationTypes.UPDATE_GET_BY_PLATE_RESULT, null)
  },
  async actGetCarModelByPlate(
    { commit, dispatch },
    plate: string,
  ): Promise<AxiosResponse<FindPlateSummaryResponse>> {
    try {
      const response = await getCarModelByPlate(plate.toUpperCase())
      const { status, data } = response
      commit(
        MutationTypes.UPDATE_GET_BY_PLATE_RESULT,
        status === 200 ? data : null,
      )
      return response
    } catch (e: any) {
      commit(MutationTypes.UPDATE_GET_BY_PLATE_RESULT, null)
      if (e.response?.status === 404) {
        dispatch(
          'utils/feedback/createFeedback',
          {
            text: 'Placa não encontrada. Por favor, verifique os dados informados.',
          },
          {
            root: true,
          },
        )
      } else {
        dispatch(
          'utils/feedback/createFeedback',
          { text: 'Oops! algo deu errado.' },
          {
            root: true,
          },
        )
      }

      return e.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
