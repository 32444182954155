import { loadAsyncScript } from '@/helpers/script'
import { sentryException } from '@/helpers/sentry'

window.addEventListener('load', () => {
  window['dataLayer'] = window['dataLayer'] || []

  setTimeout(() => {
    try {
      window['dataLayer'].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      loadAsyncScript({
        src: 'https://www.googletagmanager.com/gtm.js?id=GTM-5KFRDMX',
        defer: true,
      })
      const noscript = document.createElement('noscript')
      const iframe = document.createElement('iframe')
      iframe.setAttribute(
        'src',
        'https://www.googletagmanager.com/ns.html?id=GTM-5KFRDMX',
      )
      iframe.setAttribute('height', '0')
      iframe.setAttribute('width', '0')
      iframe.setAttribute('style', 'display:none;visibility:hidden')
      noscript.appendChild(iframe)
      document.body.prepend(noscript)
    } catch (e: any) {
      sentryException(e)
    }
  })
})
