const insuranceInfos = {
  auto_insurance: 'https://contatocidadaniaja.typeform.com/to/undEtXLP',
  life_insurance: 'https://contatocidadaniaja.typeform.com/to/HoDi8MhU',
  home_insurance: 'https://contatocidadaniaja.typeform.com/to/kTJT29gv',
  cellphone_insurance: 'https://contatocidadaniaja.typeform.com/to/uUxOBgfl',
  motorcycle_insurance: 'https://contatocidadaniaja.typeform.com/to/yzQaqJA4',
  travel_insurance: 'https://contatocidadaniaja.typeform.com/to/eMvbvPTV',
  portableequipment_insurance:
    'https://contatocidadaniaja.typeform.com/to/HWwLaFNU',
}

export { insuranceInfos }
