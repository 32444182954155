import IntentAPI from '@/api/user/intent'
import { UserIntentRequest } from '@/data/user/intent'
// STATE
const state = (): any => ({})

// GETTERS
const getters = {}

// MUTATIONS
const mutations = {}

// ACTIONS
const actions = {
  // Create a new user
  async actInsertIntent(_, payload: UserIntentRequest) {
    if (
      sessionStorage.getItem(`jb.state.intent.${payload.identifier}`) === 'true'
    ) {
      return
    }

    try {
      sessionStorage.setItem(`jb.state.intent.${payload.identifier}`, 'true')
      return await IntentAPI.insert(payload)
    } catch (e: any) {
      return e?.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
