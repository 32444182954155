<template>
  <ClearLayout>
    <OfferPartnerRedirectModal :partner="cptdPartner" :url="cptdOfferUrl">
      <template #title>
        {{ cptdTitleRedirect }}
      </template>
      <template #alert-message>
        {{ cptdRedirectMessage }}
      </template>
    </OfferPartnerRedirectModal>
  </ClearLayout>
</template>

<script>
import { mapActions } from 'vuex'
import { PartnerRedirectLinks, PartnersName } from '@/helpers/partners/common'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
export default {
  components: {
    ClearLayout,
    OfferPartnerRedirectModal,
  },
  data() {
    return {
      offer: undefined,
    }
  },
  computed: {
    cptdPartner() {
      const { partner } = this.$route?.params
      return partner?.toUpperCase() ?? ''
    },
    cptdOfferUrl() {
      return (
        PartnerRedirectLinks.HOME_EQUITY[this.cptdPartner ?? '']?.() ??
        this.offer?.url ??
        ''
      )
    },
    cptdPartnerNameFormatted() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdTitleRedirect() {
      return `Tudo certo! Finalize a contratação da sua oferta com a ${this.cptdPartnerNameFormatted}`
    },
    cptdRedirectMessage() {
      return `Este serviço é uma parceria com a ${this.cptdPartnerNameFormatted} e o processo continuará em seu site.`
    },
  },
  async mounted() {
    if (
      import.meta.env.VITE_FEATURE_NEW_HOME_EQUITY_FULL_INTEGRATION === 'true'
    ) {
      const { offer_id } = this.$route?.params
      this.offer = await this.actGetHomeEquityOfferById(offer_id)
      if (!this.offer) {
        this.createFeedback({ text: 'Oferta não encontrada' })
        this.scheduleRedirectTo('/ofertas')
      }
    }
  },
  methods: {
    ...mapActions({
      actGetHomeEquityOfferById:
        'products/loans/home-equity/home-equity/actGetHomeEquityOfferById',
      createFeedback: 'utils/feedback/createFeedback',
    }),
    scheduleRedirectTo(path) {
      setTimeout(() => {
        this.$router.push({
          path,
        })
      }, 6000)
    },
  },
}
</script>

<style lang="scss"></style>
