// STATE
import { moveArrIndexTo } from '@/helpers/array'

const lastLoanInteractionLSKey = 'jb.state.ui.last-loan-interaction'
const sortOrder = [
  'no-collateral', // nc e afiliados de nc
  'fgts',
  'auto-equity',
  'home-equity',
  'payroll', // picpay
  'payroll-anticipation', // blipay
  'credit-card-equity', // hcred
]

const state = (): any => ({
  lastLoanInteraction: JSON.parse(
    localStorage.getItem(lastLoanInteractionLSKey) || JSON.stringify(sortOrder),
  ) as Array<string>,
})

// GETTERS
const getters = {
  gtLastLoanInteraction(state) {
    return [
      state.lastLoanInteraction[0],
      ...state.lastLoanInteraction
        .filter((_, index) => index !== 0)
        .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)),
    ]
  },
}

// MUTATIONS
const mutations = {
  SET_LAST_LOAN_INTERACTION(state, payload) {
    if (!state.lastLoanInteraction.find((p) => p === payload)) {
      state.lastLoanInteraction.push(payload)
    }

    moveArrIndexTo(
      state.lastLoanInteraction,
      state.lastLoanInteraction.indexOf(payload),
      0,
    )
    localStorage.setItem(
      lastLoanInteractionLSKey,
      JSON.stringify(state.lastLoanInteraction),
    )
  },
}

// ACTIONS
const actions = {
  actSetLastLoanInteraction({ commit }, loanProduct) {
    setTimeout(() => {
      commit('SET_LAST_LOAN_INTERACTION', loanProduct)
    }, 1000)
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
