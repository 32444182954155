<template>
  <SelectInput
    :id="$attrs.id || 'bank'"
    v-bind="$attrs"
    :class="$attrs.class || 'field required'"
    :name="$attrs.name || 'bank'"
    :label="$attrs.label || 'Banco'"
    :placeholder="$attrs.label || 'Selecione um banco'"
    :helper-message="
      $attrs.helperMessgae ||
      'Caixa, Itaú, Bradesco, Santander e Banco do Brasil têm mais chances de aprovação.'
    "
    :validations="$attrs.validations || 'required'"
    :searchable="$attrs.searchable !== undefined ? $attrs.searchable : true"
    :options="bankOptions"
    @input="$emit('input', $event)"
  />
</template>
<script>
import banks from '@/helpers/banks'
import SelectInput from '@/components/ui/newComponents/SelectInput.vue'
export default {
  name: 'BankAccountBankSelectInput',
  components: {
    SelectInput,
  },
  inheritAttrs: false,
  props: {
    allowedBankCodes: {
      type: Array,
      default() {
        return []
      },
    },
  },
  emits: ['input'],
  computed: {
    bankOptions() {
      const allBanks = banks().map((v) => {
        return {
          ...v,
          name: `${v.code} - ${v.name}`,
          label: `${v.code} - ${v.name}`,
          value: v.code,
        }
      })

      if (this.allowedBankCodes?.length > 0) {
        return allBanks.filter((bank) => {
          return this.allowedBankCodes.includes(bank.code)
        })
      }

      return allBanks
    },
  },
}
</script>
<style lang="scss"></style>
