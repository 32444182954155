<template>
  <SimpleLayout>
    <SelectProductBanco24horasContainer />
  </SimpleLayout>
</template>
<script>
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import { defineAsyncComponent } from 'vue'
const SelectProductBanco24horasContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/partnerships/banco24horas/SelectProductBanco24horasContainer.vue'
    ),
)
export default {
  name: 'SelectProductBanco24horasPage',
  components: {
    SimpleLayout,
    SelectProductBanco24horasContainer,
  },
}
</script>
<style lang="scss">
.app {
  & > .ContainerWrapper {
    width: 100%;

    @include media('xl') {
      max-width: 100%;
    }
  }
}
</style>
