<template>
  <UnloggedLayout>
    <FormContainer>
      <RebelOccupationForm
        :data="cptdCurrentForm['occupation_form'] || {}"
        @submit="submit"
      />
    </FormContainer>
  </UnloggedLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const RebelOccupationForm = defineAsyncComponent(
  () =>
    import(
      '@/components/forms/loans/no-collateral/partners/rebel/RebelOccupationForm.vue'
    ),
)
const FormContainer = defineAsyncComponent(
  () => import('@/containers/forms/FormContainer.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    UnloggedLayout,
    RebelOccupationForm,
    FormContainer,
  },
  data() {
    return {
      formId: 'REBEL',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/gtNoCollateralOfferSelected',
      gtUser: 'user/user/gtUser',
    }),
    cptdCurrentForm() {
      if (this.gtDynamicForms && this.gtDynamicForms[this.formId]) {
        return this.gtDynamicForms[this.formId]
      }

      return {}
    },
  },
  created() {
    this.actSetupForm(this.formId)
    if (!this.gtNoCollateralOfferSelected || !this.gtUser) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actSetupForm: 'dynamic-forms/actSetupForm',
      actUpdateForm: 'dynamic-forms/actUpdateForm',
    }),
    submit(data) {
      this.actUpdateForm({
        formId: this.formId,
        key: 'occupation_group',
        value: data?.occupation_group,
      })
      this.$router.push({
        path: `/forms/emprestimo/sem-garantia/parceiros/rebel/selecionar-oferta/conta-deposito`,
      })
    },
  },
}
</script>
<style lang="scss"></style>
