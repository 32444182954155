<template>
  <div v-if="displaySelf" class="Dialog" :class="cssDialog">
    <div v-click-outside="onOutsideClick" class="content-wrapper">
      <div class="content" :style="contentStyle">
        <header class="header" :class="cssHeader">
          <h2 v-if="!hideTitle">
            <slot name="header-title" />
          </h2>
          <button v-if="!hideCloseButton" class="close" @click="hideSelf">
            <slot name="close-icon"><BaseIcon name="close-mono" /></slot>
          </button>
          <slot name="header" />
        </header>
        <slot name="content" />
      </div>
      <footer ref="footerSlot" class="footer">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import clickOutside from '../../directives/click-outside'
import debounce from '../../mixins/debounce'
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
export default {
  name: 'Dialog',
  components: { BaseIcon },
  directives: {
    ...clickOutside,
  },
  mixins: [debounce],
  props: {
    extraClass: {
      type: String,
      default: '',
    },
    display: {
      type: Boolean,
      default: false,
      required: true,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    persisted: {
      type: Boolean,
      default: false,
    },
    isFullscreenMobile: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: String,
      default: 'modal',
      validator(e) {
        return ['modal', 'bottom-sheet'].includes(e)
      },
    },
  },
  emits: ['close'],
  data() {
    return {
      displaySelf: this.display,
      contentStyle: {},
      setContentDebounceIntervalId: null,
      lastScrollTopPosition: 0,
    }
  },
  computed: {
    cssHeader() {
      return {
        'hide-close-button': this.hideCloseButton,
        'hide-title': this.hideTitle,
        'no-header-content': this.hideTitle && this.hideCloseButton,
      }
    },
    cssDialog() {
      return {
        'full-screen-mobile': this.isFullscreenMobile,
        'has-close-button': !this.hideCloseButton,
      }
    },
  },
  watch: {
    display(v) {
      this.displaySelf = v
    },
    displaySelf(v) {
      if (v) {
        this.setContentStyle()
        return
      }
      this.addOverflow()
      this.contentStyle = {}
    },
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.onEscKeyUp, !1)
    window.removeEventListener('resize', this.setContentStyle)
    this.addOverflow()
  },
  mounted() {
    if (this.displaySelf) {
      nextTick(() => {
        this.setContentStyle()
      })
      this.removeOverflow()
      document.addEventListener('keyup', this.onEscKeyUp, false)
      window.addEventListener('resize', this.setContentStyle)
    }
  },
  methods: {
    addOverflow() {
      const styles = {
        overflow: '',
      }
      Object.assign(document.body.style, styles)
      Object.assign(document.documentElement.style, styles)
      document.body.scrollTo(0, this.lastScrollTopPosition)
    },
    removeOverflow() {
      const styles = {
        overflow: 'hidden',
      }

      this.lastScrollTopPosition = Number(document.body.scrollTop || 0)
      Object.assign(document.body.style, styles)
      Object.assign(document.documentElement.style, styles)
    },
    setContentStyle() {
      this.setContentDebounceIntervalId = this.mxDebounce(
        this.setContentDebounceIntervalId,
        () => {
          this.contentStyle = {
            maxHeight:
              (window.innerHeight -
                this.heightBorderCalc() -
                this.$refs?.footerSlot?.clientHeight ?? 0) + 'px',
          }
        },
      )
    },
    onEscKeyUp(e) {
      if (e.keyCode === 27 && !this.persisted) {
        this.hideSelf()
      }
    },
    hideSelf() {
      this.displaySelf = false
      this.$emit('close')
    },
    onOutsideClick() {
      if (!this.persisted && this.displaySelf) {
        this.hideSelf()
      }
    },
    heightBorderCalc() {
      if (this.isFullscreenMobile && window.innerWidth <= 768) return 0

      if (window.innerWidth <= 768) {
        return 32
      } else {
        return 64
      }
    },
  },
}
</script>

<style lang="scss">
.Dialog {
  @-webkit-keyframes ani-slide-in {
    from {
      bottom: -300px;
    }

    to {
      bottom: 0;
    }
  }

  @-webkit-keyframes ani-fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &,
  * {
    box-sizing: border-box;
  }

  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10031;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-black-medium;
  animation: ani-fade-in 0.5s ease;

  &.full-screen-mobile {
    & > .content-wrapper {
      @media screen and (max-width: 768px) {
        border-radius: unset;
        width: 100vw;
        height: 100%;
        max-width: 100vw;
        max-height: 100%;
      }
    }
  }

  & > .content-wrapper {
    background: $color-white-high;
    border-radius: 12px;
    width: 100%;
    max-width: 592px;
    max-height: calc(100% - 64px);
    position: relative;
    overflow: hidden;
    box-shadow:
      0 0 2px $color-black-lower,
      0 4px 8px $color-black-lower;
    text-align: left;
    animation:
      ani-fade-in 0.5s ease,
      ani-slide-in 0.5s ease;

    @media screen and (max-width: 768px) {
      max-width: calc(100vw - 32px);
      max-height: calc(100% - 32px);
    }

    & > .footer {
      display: flex;
      z-index: 1;
      width: 100%;
      min-height: 0;
      position: sticky;
      left: 0;
      margin: 0;
      background-color: $color-white-high;
      padding: 24px;
      bottom: 0;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: center;

      &:empty {
        padding: 24px 0 0 0 !important;
      }

      @media screen and (max-width: 768px) {
        padding: 16px;

        &:empty {
          padding: 16px 0 0 0 !important;
        }
      }

      .Button {
        width: 100%;
        margin-bottom: 12px;
        margin-right: 0;

        &:last-child {
          margin: 0;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 12px;
          margin-right: 0;
        }
      }
    }

    & > .content {
      width: 100%;
      min-height: 0;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 24px;
      display: block;
      color: $color-black-medium;
      @include body-1;

      @media screen and (max-width: 768px) {
        padding: 0 16px;
      }

      & > .header {
        display: flex;
        width: 100%;
        margin: 0;
        background-color: $color-white-high;
        padding: 24px 0 32px 0;
        justify-content: space-between;
        align-items: flex-start;

        &:empty {
          padding: 24px 0 0 0 !important;
        }

        @media screen and (max-width: 768px) {
          padding: 16px 0 32px 0;

          &:empty {
            padding: 16px 0 0 0 !important;
          }
        }

        &.no-header-content {
          padding: 24px 0 0 0;

          @media screen and (max-width: 768px) {
            padding: 16px 0 0 0;
          }
        }

        &.hide-close-button {
          h2 {
            max-width: 100%;
          }
        }

        h2 {
          @include headline-6;
          margin: 0;
          color: $color-black-high;
          max-width: calc(100% - 40px);
        }

        button {
          background-color: transparent;
          width: 24px;
          height: 24px;
          border: none;
          padding: 0;
          margin-top: 2px;
          cursor: pointer;
        }
      }

      & > a {
        @include link-body-1;
      }
    }
  }

  &.has-close-button {
    & > .content-wrapper {
      & > .content {
        padding: 0 16px;

        & > .header {
          padding: 16px 0 32px 0;
        }
      }
      & > .footer {
        padding: 16px;
      }
    }
  }
}
</style>
