import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { HomeEquityOfferResponse } from '@/data/loans/home-equity/offer'
import { OfferStatus } from '@/data/common'

export const getHomeEquityOfferById = async (
  offerId: string,
): Promise<AxiosResponse<HomeEquityOfferResponse>> => {
  return await JurosBaixosSystemsAPI.get(
    `loans/homeequity/offers/${offerId}`,
  ).catch(createRequestException)
}

// Payload must be an object with queryParams
// EX: { status: 'PROPOSED' }
export const getHomeEquityOffers = async (payload: {
  status: OfferStatus | Array<OfferStatus>
}): Promise<
  AxiosResponse<Array<HomeEquityOfferResponse> | Array<any>> | any
> => {
  if (
    !(import.meta.env.VITE_FEATURE_NEW_HOME_EQUITY_FULL_INTEGRATION === 'true')
  ) {
    return { status: 200, data: [], statusText: '', headers: {}, config: {} }
  }

  return await JurosBaixosSystemsAPI.get(`loans/homeequity/offers`, {
    params: Object.keys(payload).length > 0 ? payload : {},
  }).catch(createRequestException)
}
