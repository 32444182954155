import {
  PersonaInfoRegexes,
  PersonaResidenceRegexes,
  BankAccountRegexes,
  genericRegexes,
} from './regex'
import {
  AutoManufacturer,
  FuelType,
  IncomeSource,
  LoanReason,
} from '@/data/common'
import banks from '@/helpers/banks'

const checkNullish = (value) => {
  return value !== null && value !== undefined
}

const isValid = (value) => {
  switch (typeof value) {
    case 'string':
      return value !== '' && checkNullish(value)
    case 'number':
      return value >= 0 && checkNullish(value)
    default:
      return true
  }
}

const EducationOptions = [
  {
    name: 'Ensino fundamental incompleto',
    value: 'INCOMPLETE_ELEMENTARY',
  },
  {
    name: 'Ensino fundamental completo',
    value: 'ELEMENTARY',
  },
  {
    name: 'Ensino médio incompleto',
    value: 'INCOMPLETE_HIGH',
  },
  {
    name: 'Ensino médio completo',
    value: 'HIGH',
  },
  {
    name: 'Ensino superior incompleto',
    value: 'INCOMPLETE_COLLEGE',
  },
  {
    name: 'Ensino superior completo',
    value: 'COLLEGE',
  },
  {
    name: 'Pós-graduação incompleta',
    value: 'INCOMPLETE_POSTGRADUATE',
  },
  {
    name: 'Pós-graduação completa',
    value: 'POSTGRADUATE',
  },
]

export const EducationLevelLabels = {
  INCOMPLETE_ELEMENTARY: 'Ensino fundamental incompleto',
  ELEMENTARY: 'Ensino fundamental completo',
  INCOMPLETE_HIGH: 'Ensino médio incompleto',
  HIGH: 'Ensino médio completo',
  INCOMPLETE_COLLEGE: 'Ensino superior incompleto',
  COLLEGE: 'Ensino superior completo',
  INCOMPLETE_POSTGRADUATE: 'Pós-graduação incompleta',
  POSTGRADUATE: 'Pós-graduação completa',
}

const MaritalOptions = [
  {
    name: 'Solteiro(a)',
    value: 'SINGLE',
  },
  {
    name: 'Casado(a)',
    value: 'MARRIED',
  },
  {
    name: 'União estável',
    value: 'STABLE_UNION',
  },
  {
    name: 'Viúvo(a)',
    value: 'WIDOWED',
  },
  {
    name: 'Divorciado(a)',
    value: 'DIVORCED',
  },
]

export const MaritalLabels = {
  SINGLE: 'Solteiro(a)',
  MARRIED: 'Casado(a)',
  STABLE_UNION: 'União estável',
  WIDOWED: 'Viúvo(a)',
  DIVORCED: 'Divorciado(a)',
}

const GenderOptions = [
  {
    label: 'Masculino',
    value: 'MALE',
  },
  {
    label: 'Feminino',
    value: 'FEMALE',
  },
]

export const GenderLabels = {
  MALE: 'Masculino',
  FEMALE: 'Feminino',
}

const IncomeSourceLabels: Record<IncomeSource, string> = {
  UNKNOWN_INCOME_SOURCE: '',
  MILITARY: 'Militar',
  CLT: 'Assalariado/Carteira Assinada',
  AUTONOMOUS: 'Autônomo(a) ou Informal',
  MEI: 'Micro Empreendedor Individual',
  ENTREPRENEUR: 'Empresário(a)',
  RETIREMENT: 'Aposentado(a)',
  UNEMPLOYED: 'Desempregado(a)',
  SELF_EMPLOYED: 'Profissional Liberal',
  FEDERAL_WORKER: 'Servidor Público',
  PENSION: 'Pensionista',
  OTHER_INCOME: 'Bolsista',
}
const IncomeSourceOptions = [
  {
    name: IncomeSourceLabels[IncomeSource.AUTONOMOUS],
    description: 'Quem trabalha por conta própria sem vínculo empregatício.',
    value: IncomeSource.AUTONOMOUS,
  },
  {
    name: IncomeSourceLabels[IncomeSource.CLT],
    description: 'Empregado de uma empresa ou entidade que recebe salário.',
    value: IncomeSource.CLT,
  },
  {
    name: IncomeSourceLabels[IncomeSource.MEI],
    description: 'Quem possui um MEI aberto e emite nota fiscal.',
    value: IncomeSource.MEI,
  },
  {
    name: IncomeSourceLabels[IncomeSource.MILITARY],
    description: 'Quem pertence a alguma organização das Forças Armadas.',
    value: IncomeSource.MILITARY,
  },
  {
    name: IncomeSourceLabels[IncomeSource.FEDERAL_WORKER],
    description:
      'Trabalhador de uma administração ou instituição municipal, estadual ou federal.',
    value: IncomeSource.FEDERAL_WORKER,
  },
  {
    name: IncomeSourceLabels[IncomeSource.SELF_EMPLOYED],
    description:
      'Trabalhador por conta própria cadastrado a uma ordem ou conselho.',
    value: IncomeSource.SELF_EMPLOYED,
  },
  {
    name: IncomeSourceLabels[IncomeSource.ENTREPRENEUR],
    description: 'Sócio/Proprietário de uma empresa ou agenciador de negócios.',
    value: IncomeSource.ENTREPRENEUR,
  },
  {
    name: IncomeSourceLabels[IncomeSource.RETIREMENT],
    description:
      'Aquele que se aposentou e/ou recebe sua aposentadoria mensalmente.',
    value: IncomeSource.RETIREMENT,
  },
  {
    name: IncomeSourceLabels[IncomeSource.PENSION],
    description: 'Quem recebe uma pensão por parte do estado (INSS).',
    value: IncomeSource.PENSION,
  },
  {
    name: IncomeSourceLabels[IncomeSource.OTHER_INCOME],
    description:
      'Quem recebe uma bolsa (subsídio) por alguma atividade exercida.',
    value: IncomeSource.OTHER_INCOME,
  },
  {
    name: IncomeSourceLabels[IncomeSource.UNEMPLOYED],
    description:
      'Quem não está trabalhando, mas que está à procura de trabalho.',
    value: IncomeSource.UNEMPLOYED,
  },
]

export const ReasonLabels: Record<LoanReason, string> = {
  UNKNOWN_LOAN_REASON: 'Outros objetivos',
  BUY: 'Compras',
  BILLS: 'Pagar contas',
  PAY_ANOTHER_LOAN: 'Pagar outro empréstimo',
  MEDICAL_EXPENSES: 'Despesas médicas',
  HOME_IMPROVEMENT: 'Reformas ou consertos',
  FIX_CAR: 'Concertar o carro',
  HELP_FAMILY: 'Família',
  VACATION: 'Viagem',
  EDUCATION_EXPENSES: 'Educação',
  INVESTMENT: 'Investimentos',
  MARRIAGE: 'Casamento',
  OTHER_REASON: 'Outros objetivos',
}

const validatePersonaAuto = (auto) => {
  const autoValidation = {
    auction_vehicle: typeof auto?.auction_vehicle === 'boolean',
    loans_paid: typeof auto?.loans_paid === 'boolean',
    manufacturer:
      isValid(auto?.manufacturer ?? '') &&
      AutoManufacturer[auto?.manufacturer] &&
      AutoManufacturer[auto?.manufacturer] !==
        AutoManufacturer.UNKNOWN_AUTO_MANUFACTURER,
    fuel_type:
      isValid(auto?.fuel_type ?? '') &&
      FuelType[auto.fuel_type] &&
      FuelType[auto.fuel_type] !== FuelType.UNKNOWN_FUEL_TYPE,
    model: isValid(auto?.manufacturer ?? ''),
    plate: isValid(auto?.plate ?? ''),
    vehicle_owner: typeof auto.vehicle_owner === 'boolean',
    year: isValid(auto?.year ?? ''),
  }

  return {
    valid: !Object.values(autoValidation).some((v) => !v),
    validatedFields: autoValidation,
  }
}

const validatePersonaInfo = (info) => {
  const infoValidation = {
    birth_city:
      isValid(info?.birth_city ?? '') &&
      !!info.birth_city.match(
        PersonaInfoRegexes['persona.info.birth_city'].regex,
      ),
    birth_date:
      isValid(info?.birth_date ?? '') &&
      !!info.birth_date.match(
        PersonaInfoRegexes['persona.info.birth_date'].regex,
      ),
    birth_state: isValid(info?.birth_state ?? ''),
    educational_level:
      isValid(info?.educational_level ?? '') &&
      EducationOptions.map((v) => v.value).includes(info.educational_level),
    gender:
      isValid(info?.gender ?? '') &&
      GenderOptions.map((v) => v.value).includes(info.gender),
    marital_status:
      isValid(info?.marital_status ?? '') &&
      MaritalOptions.map((v) => v.value).includes(info.marital_status),
    mothers_name:
      isValid(info?.mothers_name ?? '') &&
      !!info.mothers_name.match(
        PersonaInfoRegexes['persona.info.mothers_name'].regex,
      ),
    name:
      isValid(info.name ?? '') &&
      !!info.name.match(PersonaInfoRegexes['persona.info.name'].regex),
    rg_number:
      info.rg?.number &&
      isValid(info?.rg?.number ?? '') &&
      !!String(info?.rg?.number ?? '').match(genericRegexes.rg.regex),
    rg_issuer_state:
      info.rg?.issuer_state && isValid(info?.rg?.issuer_state ?? ''),
  }

  return {
    valid: !Object.values(infoValidation).some((v) => !v),
    validatedFields: infoValidation,
  }
}

const validatePersonaResidence = (residence) => {
  const residenceValidation = {
    address:
      isValid(residence?.address ?? '') &&
      !!residence.address.match(
        PersonaResidenceRegexes['persona.residence.address'].regex,
      ),
    city:
      isValid(residence?.city ?? '') &&
      !!residence.city.match(
        PersonaResidenceRegexes['persona.residence.city'].regex,
      ),
    district:
      isValid(residence?.district ?? '') &&
      !!residence.district.match(
        PersonaResidenceRegexes['persona.residence.district'].regex,
      ),
    complement: isValid(residence?.complement ?? '')
      ? !!residence.complement.match(
          PersonaResidenceRegexes['persona.residence.complement'].regex,
        )
      : true,
    number:
      isValid(residence.number ?? '') &&
      !!residence.number.match(
        PersonaResidenceRegexes['persona.residence.number'].regex,
      ),
    state: isValid(residence?.state ?? ''),
    zip_code:
      isValid(residence?.zip_code ?? '') &&
      residence.zip_code.replace(/[^\d]+/g, '').length === 8,
  }

  return {
    valid: !Object.values(residenceValidation).some((v) => !v),
    validatedFields: residenceValidation,
  }
}

export const validateBankAccount = (account: any = {}) => {
  let bankAccountValidation

  if (Object.keys(account).length > 0) {
    bankAccountValidation = {
      number:
        isValid(account?.number ?? '') &&
        !!account.number
          .replace(/[^0-9PX]/g, '')
          .match(BankAccountRegexes['bankaccount.number.sanitized'].regex),
      bank:
        isValid(account?.bank ?? '') &&
        !!account.bank.match(BankAccountRegexes['bankaccount.bank'].regex) &&
        !!banks().find((b) => b.code === account.bank && b.display),
      branch:
        isValid(account?.branch ?? '') &&
        !!account.branch.match(BankAccountRegexes['bankaccount.branch'].regex),
      type:
        isValid(account?.type ?? '') &&
        (account.type === 'CHECKING' || account.type === 'SAVING'),
    }

    return {
      valid:
        bankAccountValidation &&
        !Object.values(bankAccountValidation).some((v) => !v),
      validatedFields: bankAccountValidation || {},
    }
  } else {
    return {
      valid: true,
      validatedFields: {},
    }
  }
}

const validatePersonaFinance = (finance) => {
  const personaFinanceValidation = {
    annotated: typeof finance.annotated === 'boolean',
    income_source:
      isValid(finance?.income_source ?? '') &&
      IncomeSourceOptions.map((v) => v.value).includes(finance.income_source),
    monthly_income:
      isValid(finance?.monthly_income ?? '') &&
      finance.monthly_income > 0 &&
      finance.monthly_income < 1000000,
  }

  return {
    valid: !Object.values(personaFinanceValidation).some((v) => !v),
    validatedFields: personaFinanceValidation,
  }
}

const validatePersona = ({ info, residence, finance }) => {
  const isAutoValid = true
  const isFinanceValid = finance
    ? validatePersonaFinance(finance).valid
    : { valid: false }
  const isBankAccountValid = finance?.account
    ? validateBankAccount(finance.account).valid
    : { valid: true }
  const isInfoValid = info ? validatePersonaInfo(info).valid : { valid: false }
  const isResidenceValid = residence
    ? validatePersonaResidence(residence).valid
    : { valid: false }

  return {
    auto: isAutoValid,
    finance: isFinanceValid,
    bankAccount: isBankAccountValid,
    info: isInfoValid,
    residence: isResidenceValid,
  }
}

const sanitizePersonaInfo = (info) => {
  const validatedFields = validatePersonaInfo(info)?.validatedFields

  if (validatedFields) {
    if (!validatedFields.birth_city || !validatedFields.birth_state) {
      info.birth_city = ''
      info.birth_state = ''
    }

    info.gender = validatedFields.gender ? info?.gender : ''
    info.marital_status = validatedFields.marital_status
      ? info?.marital_status
      : ''
    info.educational_level = validatedFields.educational_level
      ? info?.educational_level
      : ''
    info.mothers_name = validatedFields.mothers_name ? info?.mothers_name : ''
    info.birth_date = validatedFields.birth_date ? info?.birth_date : ''
    if (info.rg) {
      info.rg.number = validatedFields.rg_number ? info?.rg?.number : ''
      info.rg.issuer_state = validatedFields.rg_issuer_state
        ? info?.rg?.issuer_state
        : ''
    }
  }
}

const sanitizePersonaFinance = (finance) => {
  const validatedFields = validatePersonaFinance(finance)?.validatedFields

  finance.income_source = validatedFields.income_source
    ? finance?.income_source
    : ''
  finance.annotated = validatedFields.annotated ? finance?.annotated : null
  finance.monthly_income = validatedFields.monthly_income
    ? finance?.monthly_income
    : 0
}

const sanitizePersonaAuto = (auto) => {
  const validatedFields = validatePersonaAuto(auto)?.validatedFields
  if (validatedFields) {
    auto.auction_vehicle = validatedFields?.auction_vehicle
      ? auto?.auction_vehicle
      : null
    auto.loans_paid = validatedFields?.loans_paid ? auto?.loans_paid : null
    auto.manufacturer = validatedFields?.manufacturer ? auto?.manufacturer : ''
    auto.fuel_type = validatedFields?.fuel_type ? auto?.fuel_type : ''
    auto.model = validatedFields?.model ? auto?.model : ''
    auto.plate = validatedFields?.plate ? auto?.plate : ''
    auto.vehicle_owner = validatedFields?.vehicle_owner
      ? auto?.vehicle_owner
      : null
    auto.year = validatedFields?.year ? auto?.year : ''
  }
}

const sanitizePersona = (
  { auto, finance, residence, info, bankAccount },
  personaData,
) => {
  if (finance) {
    sanitizePersonaFinance(personaData.finance)
  }

  if (info) {
    sanitizePersonaInfo(personaData.info)
  }

  if (residence) {
    delete personaData.residence
  }

  if (auto) {
    sanitizePersonaAuto(personaData.auto)
  }

  if (bankAccount) {
    delete personaData.finance.account
  }
}

export {
  EducationOptions,
  MaritalOptions,
  GenderOptions,
  IncomeSourceLabels,
  IncomeSourceOptions,
  validatePersona,
  validatePersonaResidence,
  validatePersonaInfo,
  validatePersonaFinance,
  validatePersonaAuto,
  sanitizePersona,
}
