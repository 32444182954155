import { OfferAssistantProducts } from '@/helpers/offer-assistant/data'

const state = (): any => ({
  form: {
    reason: undefined,
    amount: undefined,
    paymentDelay: undefined,
    hasFgts: undefined,
    collaterals: undefined,
  },
})

const getters = {
  gtOfferAssistantForm(state) {
    return state.form
  },
  gtOfferAssistantProducts(state) {
    const { reason, amount, paymentDelay, hasFgts, collaterals } = state.form
    if (
      reason === undefined ||
      amount === undefined ||
      paymentDelay === undefined ||
      hasFgts === undefined ||
      collaterals === undefined
    ) {
      return []
    }
    const products: any[] = []
    Object.keys(OfferAssistantProducts).forEach((productKey) => {
      const productFilter = OfferAssistantProducts[productKey].filters
      if (
        (productFilter.amount.length === 0 ||
          productFilter.amount.includes(amount)) &&
        (productFilter.collaterals.length === 0 ||
          (collaterals.length &&
            collaterals.some((c) => productFilter.collaterals.includes(c)))) &&
        (productFilter.hasFgts.length === 0 ||
          productFilter.hasFgts.includes(hasFgts)) &&
        (productFilter.paymentDelay.length === 0 ||
          productFilter.paymentDelay.includes(paymentDelay)) &&
        (productFilter.reasons.length === 0 ||
          productFilter.reasons.includes(reason))
      ) {
        products.push(OfferAssistantProducts[productKey])
      }
    })

    if (products.length === 0) return [OfferAssistantProducts.NO_COLLATERAL]
    return products.sort((a, b) => a.order - b.order)
  },
}

const mutations = {
  RESET_FORM(state) {
    state.form = {
      reason: undefined,
      amount: undefined,
      paymentDelay: undefined,
      hasFgts: undefined,
      collaterals: undefined,
    }
  },
  SET_FORM(state, payload) {
    state.form = {
      ...state.form,
      ...payload,
    }
  },
}

const actions = {
  actSetForm({ commit }, payload) {
    commit('SET_FORM', payload)
  },
  actResetForm({ commit }) {
    commit('RESET_FORM')
  },
}

export default { namespaced: true, state, getters, actions, mutations }
