<template>
  <LoggedLayout>
    <InsuranceOffersContainer />
  </LoggedLayout>
</template>
<script>
import { mapActions } from 'vuex'
import LoggedLayout from '@/layouts/LoggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const InsuranceOffersContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/logged/products/insurances/InsuranceOffersContainer.vue'
    ),
)
export default {
  name: 'InsuranceOffersPage',
  components: {
    LoggedLayout,
    InsuranceOffersContainer,
  },
  mounted() {
    this.actEmitInfobipPageview()
  },
  methods: {
    ...mapActions({
      actEmitInfobipPageview: 'services/events/actEmitInfobipPageview',
    }),
  },
}
</script>
