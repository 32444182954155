import {
  fetchUserAttributes,
  saveUserAttributes,
  updateUserAttributes,
} from '@/api/user/user-attributes'
// STATE
const state = (): any => ({
  userAttributes: {},
  firstUpdate: undefined,
})

// GETTERS
const getters = {
  gtUserAttributes(state): any {
    return state.userAttributes
  },
}

// MUTATIONS
const mutations = {
  UPDATE_USER_ATTRIBUTES(state, userAttributes): void {
    state.userAttributes = {
      ...state.userAttributes,
      ...userAttributes,
    }
  },
  UPDATE_USER_ATTRIBUTES_FIRST_UPDATE(state, firstUpdate): void {
    state.firstUpdate = firstUpdate
  },
}

// ACTIONS
const actions = {
  async actSetUserAttributes(
    { commit, dispatch },
    { userAttributes, save = true },
  ) {
    commit('UPDATE_USER_ATTRIBUTES', userAttributes)

    if (save) {
      await dispatch('actSaveUserAttributes', { useState: true })
    }
  },
  async actSaveUserAttributes(
    { dispatch, state, commit },
    { userAttributes, useState = false },
  ) {
    try {
      let response

      if (state.firstUpdate === undefined || state.firstUpdate === true) {
        response = await saveUserAttributes(
          useState ? state.userAttributes : userAttributes,
        )
      } else {
        response = await updateUserAttributes(
          useState ? state.userAttributes : userAttributes,
        )
      }

      if (response?.data) {
        commit('UPDATE_USER_ATTRIBUTES_FIRST_UPDATE', false)
        dispatch('actSetUserAttributes', {
          userAttributes: response?.data,
          save: false,
        })
        return response
      }
    } catch (e: any) {
      return e?.response
    }
  },
  async actGetUserAttributes({ dispatch, commit, state }) {
    if (state.firstUpdate || Object.keys(state.userAttributes).length) return
    try {
      const response = await fetchUserAttributes()
      commit('UPDATE_USER_ATTRIBUTES_FIRST_UPDATE', false)

      if (response?.data) {
        dispatch('actSetUserAttributes', {
          userAttributes: response?.data,
          save: false,
        })
      }
      return response
    } catch (e: any) {
      commit('UPDATE_USER_ATTRIBUTES_FIRST_UPDATE', e?.response?.status === 404)
      return e.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
