<template>
  <ClearLayout>
    <SelfieContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const SelfieContainer = defineAsyncComponent(
  () => import('@/containers/newAppDocs/SelfieContainer.vue'),
)
export default {
  name: 'SelfiePage',
  components: {
    ClearLayout,
    SelfieContainer,
  },
}
</script>
<style lang="scss"></style>
