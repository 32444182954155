<template>
  <div class="app">
    <!-- PAGE CONTENT -->
    <main class="main">
      <!-- LOADER -->
      <transition
        name="custom-classes"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
      >
        <AppLoader v-if="gtIsAppBusy" key="loader" simple />

        <!-- CONTENT -->
        <ContainerWrapper v-else key="content">
          <slot />
        </ContainerWrapper>
      </transition>

      <!-- FEEDBACK -->
      <Feedback :data="feedback" />
    </main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import AppLoader from '@/components/appPartials/AppLoader2.vue'
import Feedback from '@/components/ui/Feedback.vue'
import JbOAuth from '@/helpers/oauth'
export default {
  name: 'ClearLayout',
  components: {
    ContainerWrapper,
    AppLoader,
    Feedback,
  },
  data() {
    return {
      busy: true,
      currentPage: undefined,
    }
  },
  computed: {
    ...mapGetters({
      feedback: 'utils/feedback/feedback',
      gtIsAppBusy: 'ui/app/gtIsAppBusy',
    }),
  },
  beforeMount() {
    if (!JbOAuth.isAccessTokenValid()) {
      JbOAuth.logout()
    }
  },
}
</script>
<style lang="scss">
.app {
  & > .main {
    min-height: calc(100vh - 56px);
  }
}
</style>
