<template>
  <div class="ProgressBarStepped">
    <div
      v-for="stp in steps"
      :key="stp"
      class="step"
      :class="[cssDone(stp)]"
      @click="$emit('click', stp)"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressBarStepped',
  props: {
    step: {
      type: Number,
      required: true,
    },
    steps: {
      type: Number,
      required: true,
    },
  },
  emits: ['click'],
  methods: {
    cssDone(index) {
      return { done: index <= this.step }
    },
  },
}
</script>

<style lang="scss">
.ProgressBarStepped {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &,
  * {
    box-sizing: border-box;
  }

  .step {
    width: 32px;
    height: 4px;
    background-color: $color-neutral-dark;
    transition: all 0.3s linear;
    cursor: pointer;
    margin-right: 8px;

    &:last-child {
      margin: 0;
    }

    &.done {
      background-color: $color-brand-primary;
    }
  }
}
</style>
