import creditScore from '@/store/modules/products/credit-score/credit-score'
const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    'credit-score': creditScore,
  },
}
