<template>
  <ClearLayout>
    <ContainerWrapper v-if="gtAutoOfferSelected" class="LoanDataPage">
      <NavBar
        :progress="{ step: 2, steps: 3, type: 'stepped' }"
        @back="$router.back()"
        @close="displayProgressWillBeLost = true"
      />
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="displayProgressWillBeLost = false"
        @exit="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <BvBankAccountForm
          :data="cptdCurrentForm['bank_account'] || cptdUserCheckingBankAccount"
          :busy="busy"
          @submit="submit"
        />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const BvBankAccountForm = defineAsyncComponent(
  () =>
    import('@/components/forms/loans/auto/partners/bv/BvBankAccountForm.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    BvBankAccountForm,
    ClearLayout,
    ProgressWillBeLostModal,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      formId: 'BV',
      busy: false,
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      personaData: 'user/persona/personaData',
      gtAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/gtAutoOfferSelected',
    }),
    cptdCurrentForm() {
      if (this.gtDynamicForms && this.gtDynamicForms[this.formId]) {
        return this.gtDynamicForms[this.formId]
      }

      return {}
    },
    cptdUserCheckingBankAccount() {
      if (this.personaData?.finance?.account?.type === 'CHECKING') {
        return this.personaData?.finance?.account
      }
      return {}
    },
  },
  created() {
    if (!this.gtAutoOfferSelected || !this.cptdCurrentForm) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      setPersona: 'user/persona/setPersona',
    }),
    async submit(bankAccount) {
      this.busy = true
      await this.setPersona({
        persona: {
          finance: {
            ...(this.personaData?.finance || {}),
            account: {
              ...bankAccount,
            },
          },
        },
      })
      this.actUpdateForm({
        formId: this.formId,
        key: 'bank_account',
        value: bankAccount,
      })

      this.$router.push({
        path: '/forms/emprestimo/auto/parceiros/bv/selecionar-oferta/dados-adicionais-veiculo',
      })
    },
  },
}
</script>
<style lang="scss"></style>
