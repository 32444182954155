<template>
  <div class="SimuladorEmprestimosPage">
    <section class="jb-disclaimer">
      Atenção! A Juros Baixos não cobra depósito antecipado para liberação de
      empréstimo.
    </section>
    <section class="hero">
      <div class="left-content">
        <div class="content animate__animated animate__fadeInDown">
          <div class="header">
            <JBLogo class="logo" />
          </div>
          <h1 class="title">
            Simule e compare empréstimos em
            <strong>mais de 20 credoras</strong> em um único lugar.
          </h1>
          <LpPartnersCarousel />
        </div>
      </div>
      <div class="right-content">
        <div class="content animate__animated animate__fadeIn">
          <LpLoanSimulator />
          <div class="reclame-aqui">
            <LpReclameAquiWidget />
          </div>
        </div>
      </div>
    </section>
    <LpFooterSection />
  </div>
</template>
<script>
import JBLogo from '@/components/appPartials/AppLogo.vue'
import { calculateLoanInstallments } from '@/helpers/calculations'
import { parseToBRL } from '@/helpers/currency'
import { defineAsyncComponent } from 'vue'
const LpPartnersCarousel = defineAsyncComponent(
  () => import('@/components/landing-pages/LpPartnersCarousel.vue'),
)
const LpReclameAquiWidget = defineAsyncComponent(
  () => import('@/components/landing-pages/LpReclameAquiWidget.vue'),
)
const LpLoanSimulator = defineAsyncComponent(
  () => import('@/components/landing-pages/LpLoanSimulator.vue'),
)
const LpFooterSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpFooterSection.vue'),
)

export default {
  name: 'SimuladorEmprestimosPage',
  components: {
    LpFooterSection,
    LpLoanSimulator,
    LpReclameAquiWidget,
    LpPartnersCarousel,
    JBLogo,
  },
  data() {
    return {
      amount: 3000,
      duration: 36,
      interestRate: 0.1,
    }
  },
  computed: {
    cptdLabelAmount() {
      return parseToBRL(this.amount)
    },
    cptdLabelDuration() {
      return `${this.duration}x de ${this.cptdInstallmentValue}`
    },
    cptdInstallmentValue() {
      return calculateLoanInstallments(
        this.interestRate,
        this.amount,
        this.duration,
      )
    },
    cptdPartners() {
      let partners = []
      for (let i = 0; i < 2; i++) {
        partners = [...partners, ...this.partners]
      }
      return partners
    },
  },
  methods: {
    sendToInteractiveForm() {
      this.$router.push({
        path: '/forms/emprestimo/sem-garantia/interativo',
        query: {
          ...this.$route?.query,
          amount: this.amount,
          duration: this.duration,
        },
      })
    },
  },
}
</script>
<style lang="scss">
.SimuladorEmprestimosPage {
  height: 100%;
  display: flex;
  flex-direction: column;

  & > .jb-disclaimer {
    padding: 24px;
    text-align: center;
    color: $color-white-high;
    background-color: $color-brand-primary-dark;
    @include caption;
  }
  & > .hero {
    background-color: $color-brand-primary;
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
    align-items: center;

    & .animate__animated {
      --animate-duration: 500ms;
    }

    @include media(lg) {
      height: 100%;
    }

    & > .left-content,
    & > .right-content {
      width: 100%;
      display: flex;
      padding: 12px;
      gap: 24px;
      flex-grow: 1;
      flex-direction: column;

      @include media(lg) {
        width: 50%;
      }
    }

    & > .left-content {
      & > .content {
        & > .header {
          display: flex;
          padding: 24px 0;
          justify-content: center;
          align-items: center;
          gap: 24px;

          & > .logo {
            & > svg {
              width: 100%;
              min-width: 170px;
              max-width: 170px;
            }
          }
        }
        & > .title {
          padding: 24px 0;
          margin: 0;
          color: $color-white-medium;
          text-align: center;
          @include headline-4;
          letter-spacing: -1.5px;

          @include media(lg) {
            font-size: 48px;
            line-height: 72px;
          }

          & > strong {
            color: $color-white-high;
          }
        }
        & > .LpPartnersCarousel {
          & > .partners {
            & > .partner-logo {
              @include setSVGMonoColor($color-white-high, null);
            }
          }
        }
      }
    }

    & > .right-content {
      @include media(lg) {
        height: 100%;
      }
      & > .content {
        background-color: $color-white-high;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;

        @include media(lg) {
          height: 100%;
        }

        & > .reclame-aqui {
          padding: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  & > .LpFooterSection {
    background-color: $color-brand-primary-dark;
    color: $color-white-high;
    @include caption;
  }
}
</style>
