<template>
  <div class="ContainerWrapper" :class="size"><slot /></div>
</template>

<script>
export default {
  name: 'ContainerWrapper',
  props: {
    size: {
      type: String,
      default: 'xl',
      validator(value) {
        return ['sm', 'md', 'lg', 'xl'].includes(value)
      },
    },
  },
}
</script>

<style lang="scss">
.ContainerWrapper {
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  &.sm {
    max-width: 576px;
  }
  &.md {
    max-width: 768px;
  }
  &.lg {
    max-width: 970px;
  }
  &.xl {
    max-width: 1280px;
  }
}
</style>
