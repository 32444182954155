<template>
  <SimpleLayout>
    <ContainerWrapper class="IncomeLossPaymentSuccessPage">
      <NavBar :display-back-button="false" @close="redirectToHomePage" />
      <ContentContainerWrapper>
        <RequestedOfferComponent
          :steps="cptdSteps"
          @cta-clicked="redirectToHomePage"
        >
          <template #title>Pagamento aprovado!</template>
          <template #description>
            Em até 24 horas, você receberá um e-mail da Olik com o comprovante
            de compra e apólice do seu seguro.
          </template>
        </RequestedOfferComponent>
      </ContentContainerWrapper>
    </ContainerWrapper>
  </SimpleLayout>
</template>
<script>
import { mapActions } from 'vuex'
import JbOAuth from '@/helpers/oauth'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import { defineAsyncComponent } from 'vue'
const RequestedOfferComponent = defineAsyncComponent(
  () => import('@/components/hiring/RequestedOfferComponent.vue'),
)
export default {
  name: 'IncomeLossPaymentSuccessPage',
  components: {
    SimpleLayout,
    NavBar,
    ContainerWrapper,
    ContentContainerWrapper,
    RequestedOfferComponent,
  },
  computed: {
    cptdSteps() {
      return [
        {
          name: 'Escolher cobertura',
          status: 'done',
        },
        {
          name: 'Forma de pagamento',
          status: 'done',
        },
        {
          name: 'Conferir resumo',
          status: 'done',
        },
        {
          name: 'Finalizar pagamento',
          status: 'done',
        },
        {
          name: 'Aguardar apólice',
          status: 'not-started',
        },
      ]
    },
  },
  mounted() {
    if (JbOAuth.isAccessTokenValid()) {
      this.actEmitInfobipPageview()
    }
  },
  methods: {
    ...mapActions({
      actEmitInfobipPageview: 'services/events/actEmitInfobipPageview',
    }),
    redirectToHomePage() {
      this.$router.push({ path: '/home' })
    },
  },
}
</script>
<style scoped lang="scss">
.IncomeLossPaymentSuccessPage {
  @include media(md) {
    max-width: 768px;
  }
}
</style>
