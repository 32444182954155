<template>
  <div class="CallToActionCard" @click="$emit('click')">
    <slot name="icon" />
    <p class="title">
      <slot name="title" />
    </p>
    <p class="description">
      <slot name="description" />
    </p>
    <div class="call-to-action">
      <slot name="cta" />
      <BaseIcon name="menu-right-mono" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
export default {
  name: 'CallToActionCard',
  components: {
    BaseIcon,
  },
  emits: ['click'],
}
</script>

<style lang="scss">
.CallToActionCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background: $color-neutral-dark;
  border-radius: 12px;
  cursor: pointer;

  & > .BaseIcon {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: auto;
    max-height: unset;
    margin: 0 0 16px 0;
  }

  & > .title {
    @include subtitle-2;
    color: $color-black-high;
    margin: 0 0 8px 0;

    &:empty {
      margin: 0;
    }
  }
  & > .description {
    @include body-2;
    color: $color-black-high;
    margin: 0 0 16px 0;

    &:empty {
      margin: 0;
    }
  }
  & > .call-to-action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-black-lower;
    padding-top: 16px;
    color: $color-brand-primary;
    @include caption;

    & > .BaseIcon {
      @include setSVGMonoColor($color-brand-primary);
    }
  }
}
</style>
