<template>
  <div class="app--logo">
    <logo v-bind="$attrs" />
  </div>
</template>
<script>
import Logo from '@/assets/images/icons/jurosbaixos-logo.svg?component'
export default {
  name: 'JBLogo',
  components: {
    Logo,
  },
}
</script>
<style lang="scss">
.app--logo {
  display: flex;
  align-items: center;

  @include desktop {
    height: 48px;
    & > svg {
      height: 48px;
    }
  }
}
</style>
