import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const getRoutePath = (modulePathKey) => {
  const path = modulePathKey
    .replace(/(\/src\/pages)|(index\.vue)|(\.vue)/gi, '')
    .replace(/\/_/gi, '/:')

  if (path === '/') return path
  if (path[path.length - 1] === '/') return path.slice(0, path.length - 1)
  return path
}

const getRouteName = (path) => {
  if (path === '/') return 'index'
  return path.slice(1, path.length).replace(/\//gi, '-').replace(/:/gi, '-')
}

const getRoutes = (): RouteRecordRaw[] => {
  const modules: Record<string, any> = import.meta.glob('/src/pages/**/*.vue', {
    eager: true,
  })

  return Object.keys(modules).map((modulePathKey) => {
    const path = getRoutePath(modulePathKey)
    const name = getRouteName(path)
    return {
      path,
      name,
      component: modules[modulePathKey].default,
    } as RouteRecordRaw
  })
}

const routes = getRoutes()

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  },
  routes,
})

export default router
