import { createStore, createLogger } from 'vuex'
import modules from '@/store/modules'

const debug = import.meta.env.DEBUG

export default createStore({
  modules,
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})
