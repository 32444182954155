<template>
  <OfferSimulationFeedbackDialogContainer
    v-bind="$attrs"
    :tips="cptdTips"
    v-on="{
      close: onClose,
      'tip-click': onTipClick,
    }"
  />
</template>
<script>
import { mapActions } from 'vuex'
import OfferSimulationFeedbackDialogContainer from '@/containers/loans/OfferSimulationFeedbackDialogContainer.vue'
export default {
  name: 'AgilNoResultFeedbackDialogContainer',
  components: {
    OfferSimulationFeedbackDialogContainer,
  },
  computed: {
    cptdTips() {
      return [
        {
          action: 'auto-equity',
          description: 'Simular com veículo em garantia',
          path: '/forms/emprestimo/auto/simular',
        },
        {
          action: 'home-equity',
          description: 'Simular com imóvel em garantia',
          path: '/forms/emprestimo/home-equity/simular',
        },
        {
          action: 'fgts',
          description: 'Simular empréstimo FGTS',
          path: '/forms/emprestimo/fgts/simular',
        },
      ]
    },
  },
  mounted() {
    this.actEmitGaEvent({
      eventCategory: `agil-no-results-modal`,
      eventAction: `view-modal`,
      eventLabel: `view-modal`,
    })
    this.actEmitInfobipModalDisplayedEvent({
      context: 'simulation_denied_feedback',
      product: 'agil',
    })
  },
  methods: {
    ...mapActions({
      actEmitGaEvent: 'services/ga/actEmitGaEvent',
      actEmitInfobipModalDisplayedEvent:
        'services/events/actEmitInfobipModalDisplayedEvent',
    }),
    onTipClick(tip) {
      this.actEmitGaEvent({
        eventCategory: `agil-no-results-modal`,
        eventAction: `click-${tip.action}`,
        eventLabel: tip.description,
      })

      this.$router.push({ path: tip.path })
    },
    onClose() {
      this.actEmitGaEvent({
        eventCategory: `agil-no-results-modal`,
        eventAction: `click-close`,
        eventLabel: `click-close`,
      })
    },
  },
}
</script>
<style lang="scss"></style>
