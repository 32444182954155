<template>
  <div class="FacebookAdsHubPage">
    <section class="hero jb-hero">
      <div class="content-wrapper left">
        <div class="content animate__animated animate__fadeInDown">
          <JBLogo width="171" class="logo" />
          <h1 class="title">
            É fácil buscar um empréstimo quando se tem o especialista certo.
          </h1>
          <div class="reclame-aqui">
            <LpReclameAquiWidget />
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <ImageLazyLoader id="LoanBannerSection" alt="img" :src="bannerImgUrl" />
      </div>
    </section>
    <LpSocialProofsSection />
    <LpBigTitleSection title="Apenas um site, infinitas opções." />
    <LpNoCollateralOffersListSection />
    <LpPayrollOffersListSection />
    <LpMobileEquityOffersListSection />
    <LpCreditCardEquityOffersListSection />
    <LpFgtsOffersListSection />
    <LpAutoEquityOffersListSection />
    <LpHomeEquityOffersListSection />
    <LpBigTitleSection
      title="O buscador de empréstimos que consquistou a confiança de muita gente."
    />
    <LpGoogleReviewsCardsSection />
    <LpBigTitleSection title="Que tal buscar uma oferta agora?" />
    <section class="loan-simulator-section">
      <div class="content">
        <LpLoanSimulator />
      </div>
    </section>
    <LpFooterSection />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import JBLogo from '@/components/appPartials/AppLogo.vue'
import ImageLazyLoader from '@/aasgard/components/base/ImageLazyLoader.vue'
const LpSocialProofsSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpSocialProofsSection.vue'),
)
const LpGoogleReviewsCardsSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpGoogleReviewsCardsSection.vue'),
)
const LpLoanSimulator = defineAsyncComponent(
  () => import('@/components/landing-pages/LpLoanSimulator.vue'),
)
const LpNoCollateralOffersListSection = defineAsyncComponent(
  () =>
    import('@/components/landing-pages/LpNoCollateralOffersListSection.vue'),
)
const LpPayrollOffersListSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpPayrollOffersListSection.vue'),
)
const LpMobileEquityOffersListSection = defineAsyncComponent(
  () =>
    import('@/components/landing-pages/LpMobileEquityOffersListSection.vue'),
)
const LpFgtsOffersListSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpFgtsOffersListSection.vue'),
)
const LpAutoEquityOffersListSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpAutoEquityOffersListSection.vue'),
)
const LpHomeEquityOffersListSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpHomeEquityOffersListSection.vue'),
)
const LpFooterSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpFooterSection.vue'),
)
const LpBigTitleSection = defineAsyncComponent(
  () => import('@/components/landing-pages/LpBigTitleSection.vue'),
)
const LpReclameAquiWidget = defineAsyncComponent(
  () => import('@/components/landing-pages/LpReclameAquiWidget.vue'),
)
const LpCreditCardEquityOffersListSection = defineAsyncComponent(
  () =>
    import(
      '@/components/landing-pages/LpCreditCardEquityOffersListSection.vue'
    ),
)

export default {
  name: 'SimuladorEmprestimosPage',
  components: {
    LpCreditCardEquityOffersListSection,
    LpReclameAquiWidget,
    LpBigTitleSection,
    LpFooterSection,
    LpHomeEquityOffersListSection,
    LpAutoEquityOffersListSection,
    LpFgtsOffersListSection,
    LpMobileEquityOffersListSection,
    LpPayrollOffersListSection,
    LpNoCollateralOffersListSection,
    LpLoanSimulator,
    LpGoogleReviewsCardsSection,
    LpSocialProofsSection,
    ImageLazyLoader,
    JBLogo,
  },
  data() {
    return {
      bannerImgUrl: new URL(
        '/src/assets/images/landing-pages/girl-binoculars.jpg',
        import.meta.url,
      ),
    }
  },
  methods: { defineAsyncComponent },
}
</script>
<style lang="scss">
.FacebookAdsHubPage {
  & > .jb-hero {
    display: flex;
    padding: 24px;
    align-items: stretch;
    flex-direction: column;
    background-color: $color-brand-primary;

    @include media(lg) {
      flex-direction: row;
      height: 100%;
    }

    & .animate__animated {
      --animate-duration: 500ms;
    }

    & > .content-wrapper {
      width: 100%;
      display: flex;
      gap: 24px;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media(lg) {
        width: 50%;
      }

      & > .content {
        display: flex;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
      }
    }

    & > .content-wrapper.left {
      & > .content {
        & > .header {
          padding: 24px 0;
        }
        & > .title {
          padding: 24px 0;
          margin: 0;
          color: $color-white-high;
          text-align: center;
          @include headline-4;
          letter-spacing: -1.5px;

          @include media(lg) {
            font-size: 48px;
            line-height: 72px;
          }
        }

        & > .reclame-aqui {
          padding: 24px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  & > .big-title-section {
    display: flex;
    min-height: 224px;
    justify-content: center;
    align-items: center;

    & > h2 {
      color: $color-black-high;
      text-align: center;
      @include headline-4;
      margin: 0;

      @include media(lg) {
        font-size: 48px;
        line-height: 72px;
      }
    }
  }

  & > .loan-simulator-section {
    display: flex;
    min-height: 224px;
    justify-content: center;
    align-items: center;

    & > .content {
      width: 100%;
      display: flex;
      padding: 24px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      background: $color-neutral-light;
    }
  }
}
</style>
