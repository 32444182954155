<template>
  <div class="app">
    <!-- HEADER -->
    <AppHeader v-if="currentPage !== 'form-inicial'" />
    <!-- PAGE CONTENT -->
    <main
      id="page-wrapper"
      class="main"
      :class="['page-wrapper', { '-is-mobile': isMobile }]"
    >
      <transition
        name="custom-classes"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
      >
        <AppLoader v-if="gtIsAppBusy" key="loader" simple />
        <slot v-else key="content" />
      </transition>
      <Feedback :data="feedback" />
    </main>
    <!-- FOOTER -->
    <app-footer
      v-if="currentPage !== 'form-inicial' && currentPage !== 'login'"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AppHeader from '@/components/appPartials/AppHeader.vue'
import AppFooter from '@/components/appPartials/AppFooter.vue'
import AppLoader from '@/components/appPartials/AppLoader.vue'
import Feedback from '@/components/ui/Feedback.vue'
export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppFooter,
    AppLoader,
    Feedback,
  },
  data() {
    return {
      isMobile: false,
      busy: true,
      isValidLink: false,
      currentPage: undefined,
    }
  },
  computed: {
    ...mapGetters({
      feedback: 'utils/feedback/feedback',
      gtIsAppBusy: 'ui/app/gtIsAppBusy',
    }),
  },
  watch: {
    $route() {
      const el = document.getElementById('page-wrapper')
      if (el != null) {
        el.scrollTo(0, 0)
      }
    },
  },
  beforeMount() {
    const deployLink = location.hostname
    this.isValidLink = deployLink.includes('netlify')
  },
  mounted() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  },
}
</script>
<style lang="scss">
.app {
  min-height: 100vh;

  & > .page-wrapper {
    @media (min-width: 768px) {
      padding-top: 64px;
    }
  }
}
</style>
