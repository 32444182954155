import { genericRegexes, getValidatedValueFromRegexTest } from '@/helpers/regex'
import { sentryMessage } from '@/helpers/sentry'

export default (router) => {
  router.afterEach((to) => {
    const campaignKeys = [
      'utm_campaign',
      'utm_source',
      'utm_ad_set',
      'utm_ad_keyword',
      'utm_ad_creative',
      'aff_id',
    ]
    if (to.query) {
      if (Object.keys(to.query || {}).some((k) => campaignKeys.includes(k))) {
        campaignKeys.forEach((campaignKey) => {
          const campaignValue = String(to.query[campaignKey] || '').replace(
            /[^a-zA-Z0-9._-]/gi,
            '',
          )
          const campaignSanitized = getValidatedValueFromRegexTest(
            genericRegexes.campaigns.regex,
            campaignValue,
          )
          if (
            campaignValue &&
            campaignSanitized === '' &&
            sessionStorage.getItem(`${campaignKey}:error`) !== 'true'
          ) {
            sentryMessage(
              `${campaignKey}: Must match ${genericRegexes.campaigns.regex} - Got ${campaignValue}`,
            )
            sessionStorage.setItem(`${campaignKey}:error`, 'true')
          } else {
            sessionStorage.removeItem(`${campaignKey}:error`)
          }

          sessionStorage.setItem(campaignKey, campaignSanitized)
        })
      }

      sessionStorage.setItem('product', String(to.query?.product || ''))
    }
  })
}
