<template>
  <div v-if="displaySelf" class="Alert" :class="[type, CssExtraClasses]">
    <div class="icon-wrapper">
      <slot name="icon">
        <BaseIcon :name="iconName" />
      </slot>
    </div>
    <div class="content">
      <p class="description"><slot /></p>
    </div>
    <button v-if="hasClose" class="close" @click="close">
      <BaseIcon name="close-mono" />
    </button>
  </div>
</template>

<script>
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'

export default {
  name: 'Alert',
  components: {
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      default: 'info',
      validator(value) {
        return ['info', 'negative', 'positive', 'warning'].includes(value)
      },
    },
    hasClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      displaySelf: true,
      iconByTpeMap: {
        info: 'info-mono',
        negative: 'error-mono',
        positive: 'check-circle-mono',
        warning: 'warning-mono',
      },
    }
  },
  computed: {
    iconName() {
      return this.iconByTpeMap[this.type] || `info-mono`
    },
    CssExtraClasses() {
      return {
        '-close': this.hasClose,
      }
    },
  },
  methods: {
    close() {
      this.displaySelf = false
    },
  },
}
</script>

<style lang="scss">
.Alert {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 16px;
  border-radius: 8px;

  &,
  * {
    box-sizing: border-box;
  }

  .icon-wrapper {
    width: 24px;
    height: 24px;
  }

  .description {
    @include body-2;
    color: $color-black-high;
    margin: 0;
    padding: 0 0 0 8px;
    text-align: left;
  }

  .close {
    margin-left: auto;
    padding: 0;
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &.-close {
    justify-content: center;
  }

  &.info {
    background-color: $color-feedback-info-light;
  }

  &.negative {
    background-color: $color-feedback-negative-light;
  }

  &.positive {
    background-color: $color-feedback-positive-light;
  }

  &.warning {
    background-color: $color-feedback-warning-light;
  }
}
</style>
