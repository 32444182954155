export enum CreditasAccountOwnership {
  OWNERSHIP_NOT_SET = 'OWNERSHIP_NOT_SET',
  INDIVIDUAL = 'INDIVIDUAL',
  JOINT_ACCOUNT = 'JOINT_ACCOUNT',
}

export enum CreditasWorkingSinceEnum {
  UNKNOWN_WORKING_SINCE = 'UNKNOWN_WORKING_SINCE',
  LESS_THAN_SIX_MONTHS = 'LESS_THAN_SIX_MONTHS',
  BETWEEN_SIX_MONTHS_AND_ONE_YEAR = 'BETWEEN_SIX_MONTHS_AND_ONE_YEAR',
  BETWEEN_ONE_AND_TWO_YEARS = 'BETWEEN_ONE_AND_TWO_YEARS',
  BETWEEN_TWO_AND_THREE_YEARS = 'BETWEEN_TWO_AND_THREE_YEARS',
  BETWEEN_THREE_AND_FOUR_YEARS = 'BETWEEN_THREE_AND_FOUR_YEARS',
  BETWEEN_FOUR_AND_FIVE_YEARS = 'BETWEEN_FOUR_AND_FIVE_YEARS',
  BETWEEN_FIVE_AND_TEN_YEARS = 'BETWEEN_FIVE_AND_TEN_YEARS',
  MORE_THAN_TEN_YEARS = 'MORE_THAN_TEN_YEARS',
}

export enum CreditasOccupation {
  'Açougueiro(a)' = 'Açougueiro(a)',
  'Adestrador(a)' = 'Adestrador(a)',
  'Administrador(a)' = 'Administrador(a)',
  'Advogado(a)' = 'Advogado(a)',
  'Agenciador(a) de Propaganda' = 'Agenciador(a) de Propaganda',
  'Agente Administrativo' = 'Agente Administrativo',
  'Agente de Serviços Funerários' = 'Agente de Serviços Funerários',
  'Agente de Viagem' = 'Agente de Viagem',
  'Agricultor(a)' = 'Agricultor(a)',
  'Agrônomo(a)' = 'Agrônomo(a)',
  'Ajudante Geral' = 'Ajudante Geral',
  'Alfaiate' = 'Alfaiate',
  'Analista de Sistemas' = 'Analista de Sistemas',
  'Analista Financeiro(a)' = 'Analista Financeiro(a)',
  'Analista Fiscal' = 'Analista Fiscal',
  'Apicultor(a)' = 'Apicultor(a)',
  'Aposentado(a)' = 'Aposentado(a)',
  'Arqueólogo(a)' = 'Arqueólogo(a)',
  'Arquiteto(a)' = 'Arquiteto(a)',
  'Arquivista' = 'Arquivista',
  'Artesão / Artesã' = 'Artesão / Artesã',
  'Ascensorista' = 'Ascensorista',
  'Assessor(a) Parlamentar' = 'Assessor(a) Parlamentar',
  'Assistente' = 'Assistente',
  'Assistente Social' = 'Assistente Social',
  'Astrólogo(a)' = 'Astrólogo(a)',
  'Astrônomo(a)' = 'Astrônomo(a)',
  'Atleta' = 'Atleta',
  'Ator / Atriz e Diretor(a) de Espetáculos' = 'Ator / Atriz e Diretor(a) de Espetáculos',
  'Atuário(a)' = 'Atuário(a)',
  'Auditor(a)' = 'Auditor(a)',
  'Autônomo(a)' = 'Autônomo(a)',
  'Auxiliar de Almoxarifado' = 'Auxiliar de Almoxarifado',
  'Auxiliar de Cozinha' = 'Auxiliar de Cozinha',
  'Auxiliar de Escritórios e Similares' = 'Auxiliar de Escritórios e Similares',
  'Auxiliar de Laboratório' = 'Auxiliar de Laboratório',
  'Auxiliar de Obra' = 'Auxiliar de Obra',
  'Avaliador(a)' = 'Avaliador(a)',
  'Bailarino(a)' = 'Bailarino(a)',
  'Bancário(a)' = 'Bancário(a)',
  'Banqueiro(a)' = 'Banqueiro(a)',
  'Bibliotecário(a)' = 'Bibliotecário(a)',
  'Biólogo(a)' = 'Biólogo(a)',
  'Bolsista' = 'Bolsista',
  'Bombeiro(a)' = 'Bombeiro(a)',
  'Cabeleireiro(a)' = 'Cabeleireiro(a)',
  'Caixa' = 'Caixa',
  'Camareiro(a)' = 'Camareiro(a)',
  'Cantor(a)' = 'Cantor(a)',
  'Carcereiro(a)' = 'Carcereiro(a)',
  'Carregador(a)' = 'Carregador(a)',
  'Carteiro(a)' = 'Carteiro(a)',
  'Cartorário(a)' = 'Cartorário(a)',
  'Chapeiro(a)' = 'Chapeiro(a)',
  'Chaveiro(a)' = 'Chaveiro(a)',
  'Chef de Cozinha' = 'Chef de Cozinha',
  'Cinegrafista' = 'Cinegrafista',
  'Comandante(a) de Embarcações' = 'Comandante(a) de Embarcações',
  'Comissário(a) de Bordo' = 'Comissário(a) de Bordo',
  'Compositor(a)' = 'Compositor(a)',
  'Conselheiro(a)' = 'Conselheiro(a)',
  'Consultor(a)' = 'Consultor(a)',
  'Contador(a)' = 'Contador(a)',
  'Contramestre(a) de Embarcações' = 'Contramestre(a) de Embarcações',
  'Coordenador(a)' = 'Coordenador(a)',
  'Copeiro(a)' = 'Copeiro(a)',
  'Coreógrafo(a)' = 'Coreógrafo(a)',
  'Corretor(a) de Imóveis, Seguros, Títulos e Valores' = 'Corretor(a) de Imóveis, Seguros, Títulos e Valores',
  'Costureiro(a)' = 'Costureiro(a)',
  'Cozinheiro(a)' = 'Cozinheiro(a)',
  'Cuidador(a)' = 'Cuidador(a)',
  'Decorador(a)' = 'Decorador(a)',
  'Delegado(a) de Polícia' = 'Delegado(a) de Polícia',
  'Demonstrador(a)' = 'Demonstrador(a)',
  'Deputado(a) Estadual' = 'Deputado(a) Estadual',
  'Deputado(a) Federal' = 'Deputado(a) Federal',
  'Desembargador(a)' = 'Desembargador(a)',
  'Desenhista' = 'Desenhista',
  'Despachante' = 'Despachante',
  'Despachante Aduaneiro(a)' = 'Despachante Aduaneiro(a)',
  'Diplomata' = 'Diplomata',
  'Diretor(a) de Empresas' = 'Diretor(a) de Empresas',
  'Diretor(a) de Estabelecimento de Ensinos' = 'Diretor(a) de Estabelecimento de Ensinos',
  'Dono(a) de Casa' = 'Dono(a) de Casa',
  'Economista' = 'Economista',
  'Eletricista' = 'Eletricista',
  'Embalador(a)' = 'Embalador(a)',
  'Empregado(a) Doméstico' = 'Empregado(a) Doméstico',
  'Empresário(a)' = 'Empresário(a)',
  'Encanador(a)' = 'Encanador(a)',
  'Enfermeiro(a)' = 'Enfermeiro(a)',
  'Engenheiro(a)' = 'Engenheiro(a)',
  'Escritor(a)' = 'Escritor(a)',
  'Escriturário(a)' = 'Escriturário(a)',
  'Escultor(a)' = 'Escultor(a)',
  'Estagiário(a)' = 'Estagiário(a)',
  'Estatista' = 'Estatista',
  'Esteticista' = 'Esteticista',
  'Estivador(a)' = 'Estivador(a)',
  'Estudante' = 'Estudante',
  'Farmacêutico(a)' = 'Farmacêutico(a)',
  'Faxineiro(a)' = 'Faxineiro(a)',
  'Feirante' = 'Feirante',
  'Fiscal' = 'Fiscal',
  'Físico(a)' = 'Físico(a)',
  'Fisioterapeuta' = 'Fisioterapeuta',
  'Fonoaudiólogo(a)' = 'Fonoaudiólogo(a)',
  'Frentista' = 'Frentista',
  'Funcionário(a) Público(a)' = 'Funcionário(a) Público(a)',
  'Funileiro(a)' = 'Funileiro(a)',
  'Garçom / Garçonete' = 'Garçom / Garçonete',
  'Garimpeiro(a)' = 'Garimpeiro(a)',
  'Geógrafo(a)' = 'Geógrafo(a)',
  'Gerente' = 'Gerente',
  'Governador(a)' = 'Governador(a)',
  'Guia de Turismo' = 'Guia de Turismo',
  'Inspetor(a)' = 'Inspetor(a)',
  'Instalador(a)' = 'Instalador(a)',
  'Joalheiro(a)' = 'Joalheiro(a)',
  'Jornaleiro(a)' = 'Jornaleiro(a)',
  'Jornalista' = 'Jornalista',
  'Juiz(a) de Futebol' = 'Juiz(a) de Futebol',
  'Juiz(a) Estadual' = 'Juiz(a) Estadual',
  'Juiz(a) Federal' = 'Juiz(a) Federal',
  'Lavador(a) de Prato' = 'Lavador(a) de Prato',
  'Leiloeiro(a)' = 'Leiloeiro(a)',
  'Locutor(a) e Comentarista de Rádio e Televisão e Radialista' = 'Locutor(a) e Comentarista de Rádio e Televisão e Radialista',
  'Maestro / Maestrina' = 'Maestro / Maestrina',
  'Mágico(a)' = 'Mágico(a)',
  'Manobrista' = 'Manobrista',
  'Maquiador(a)' = 'Maquiador(a)',
  'Marceneiro(a)' = 'Marceneiro(a)',
  'Marinheiro(a)' = 'Marinheiro(a)',
  'Mecânico(a)' = 'Mecânico(a)',
  'Médico(a)' = 'Médico(a)',
  'Metalúrgico(a)' = 'Metalúrgico(a)',
  'Meteorologista' = 'Meteorologista',
  'Militar' = 'Militar',
  'Militar Reformado(a)' = 'Militar Reformado(a)',
  'Modelo de Moda' = 'Modelo de Moda',
  'Modista' = 'Modista',
  'Montador(a)' = 'Montador(a)',
  'Motorista' = 'Motorista',
  'Motorista de Aplicativo' = 'Motorista de Aplicativo',
  'Museólogo(a)' = 'Museólogo(a)',
  'Músico(a)' = 'Músico(a)',
  'Nutricionista' = 'Nutricionista',
  'Odontologia e Dentista' = 'Odontologia e Dentista',
  'Operador(a) de Telemarketing' = 'Operador(a) de Telemarketing',
  'Ourives' = 'Ourives',
  'Outros(as) Trabalhadores(as) de Nível Superior Ligados(as) ao Ensino' = 'Outros(as) Trabalhadores(as) de Nível Superior Ligados(as) ao Ensino',
  'Pedreiro(a)' = 'Pedreiro(a)',
  'Pensionista' = 'Pensionista',
  'Perito(a)' = 'Perito(a)',
  'Pescador(a)' = 'Pescador(a)',
  'Piloto(a) de Aeronaves' = 'Piloto(a) de Aeronaves',
  'Pintor(a)' = 'Pintor(a)',
  'Policial Civil' = 'Policial Civil',
  'Policial do Exército' = 'Policial do Exército',
  'Policial Federal' = 'Policial Federal',
  'Policial Militar' = 'Policial Militar',
  'Político(a)' = 'Político(a)',
  'Porteiro(a)' = 'Porteiro(a)',
  'Prefeito(a)' = 'Prefeito(a)',
  'Procurador(a)' = 'Procurador(a)',
  'Produtor(a)' = 'Produtor(a)',
  'Produtor(a) Agrícola' = 'Produtor(a) Agrícola',
  'Produtor(a) de Eventos' = 'Produtor(a) de Eventos',
  'Professor(a)' = 'Professor(a)',
  'Profissionais de Letras e Artes' = 'Profissionais de Letras e Artes',
  'Programador(a)' = 'Programador(a)',
  'Proprietário(a) de Estabelecimento Comercial' = 'Proprietário(a) de Estabelecimento Comercial',
  'Proprietário(a) de Imóveis' = 'Proprietário(a) de Imóveis',
  'Proprietário(a) de Micro Empresas' = 'Proprietário(a) de Micro Empresas',
  'Psicólogo(a)' = 'Psicólogo(a)',
  'Psiquiatra' = 'Psiquiatra',
  'Publicitário(a)' = 'Publicitário(a)',
  'Químico(a)' = 'Químico(a)',
  'Recepcionista' = 'Recepcionista',
  'Relações Públicas' = 'Relações Públicas',
  'Sacerdote / Sacerdotisa ou Membro(a) de Ordens ou Seitas Religiosas' = 'Sacerdote / Sacerdotisa ou Membro(a) de Ordens ou Seitas Religiosas',
  'Secretário(a)' = 'Secretário(a)',
  'Securitário(a)' = 'Securitário(a)',
  'Segurança' = 'Segurança',
  'Senador(a)' = 'Senador(a)',
  'Serralheiro(a)' = 'Serralheiro(a)',
  'Serventuário(a) de Justiça' = 'Serventuário(a) de Justiça',
  'Servidor(a) Público(a)' = 'Servidor(a) Público(a)',
  'Siderúrgico(a)' = 'Siderúrgico(a)',
  'Soldador(a)' = 'Soldador(a)',
  'Supervisor(a)' = 'Supervisor(a)',
  'Tabelião / Tabeliã' = 'Tabelião / Tabeliã',
  'Taxista' = 'Taxista',
  'Técnico(a) de Laboratório e Raio-X' = 'Técnico(a) de Laboratório e Raio-X',
  'Técnico(a) em Desportes' = 'Técnico(a) em Desportes',
  'Tecnólogo(a)' = 'Tecnólogo(a)',
  'Terapeuta Ocupacional' = 'Terapeuta Ocupacional',
  'Tesoureiro(a)' = 'Tesoureiro(a)',
  'Trabalhador(a) Agrícola' = 'Trabalhador(a) Agrícola',
  'Trabalhador(a) da Construção Civil' = 'Trabalhador(a) da Construção Civil',
  'Trabalhador(a) da Fabricação de Roupas' = 'Trabalhador(a) da Fabricação de Roupas',
  'Trabalhador(a) da Pecuária' = 'Trabalhador(a) da Pecuária',
  'Trabalhador(a) de Artes Gráficas' = 'Trabalhador(a) de Artes Gráficas',
  'Trabalhador(a) de Construção de Artefatos de Madeira' = 'Trabalhador(a) de Construção de Artefatos de Madeira',
  'Trabalhador(a) Florestal' = 'Trabalhador(a) Florestal',
  'Vendedor(a)' = 'Vendedor(a)',
  'Vereador(a)' = 'Vereador(a)',
  'Veterinário(a)' = 'Veterinário(a)',
  'Vidraceiro(a)' = 'Vidraceiro(a)',
  'Vigia' = 'Vigia',
  'Zootecnista' = 'Zootecnista',
}
