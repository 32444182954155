import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { CreditScoreReport } from '@/data/creditscore/creditscore'
import { createJbErrorMessageMappedByHttpStatusEnum } from '@/helpers/jb-error'

export const getLatestCreditScore = async (): Promise<
  AxiosResponse<CreditScoreReport> | undefined
> => {
  return await JurosBaixosSystemsAPI.get(`/creditscore/latest`)
    .catch(createRequestException)
    .catch(createJbErrorMessageMappedByHttpStatusEnum)
}
