<template>
  <SimpleLayout>
    <JbPrimePageContainer />
  </SimpleLayout>
</template>
<script>
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import { defineAsyncComponent } from 'vue'
const JbPrimePageContainer = defineAsyncComponent(
  () => import('@/containers/jb-prime/JbPrimePageContainer.vue'),
)
export default {
  name: 'JbPrimePage',
  components: {
    SimpleLayout,
    JbPrimePageContainer,
  },
}
</script>
