<template>
  <RouterView />
  <MediaQuerieUi />
</template>
<script>
import { RouterView } from 'vue-router'
import { mapActions, mapGetters } from 'vuex'
import JbOAuth from '@/helpers/oauth'
import MediaQuerieUi from '~/components/ui/MediaQuerieUi.vue'
export default {
  name: 'App',
  components: {
    MediaQuerieUi,
    RouterView,
  },
  data() {
    return {
      isMounted: false,
    }
  },
  computed: {
    ...mapGetters({
      gtUser: 'user/user/gtUser',
      personaData: 'user/persona/personaData',
      feedback: 'utils/feedback/feedback',
      gtAllProposedAutoOffers:
        'products/loans/auto-equity/auto-equity/gtAllProposedAutoOffers',
      gtAllProposedNoCollateralOffers:
        'products/loans/no-collateral/no-collateral/gtAllProposedNoCollateralOffers',
      gtAllOnGoingAutoOffers:
        'products/loans/auto-equity/auto-equity/gtAllOnGoingAutoOffers',
      gtAllOnGoingNoCollateralOffers:
        'products/loans/no-collateral/no-collateral/gtAllOnGoingNoCollateralOffers',
      gtAllOnGoingHomeEquityOffers:
        'products/loans/home-equity/home-equity/gtAllOnGoingHomeEquityOffers',
      gtNoCollateralLatestSimulationRequest:
        'products/loans/no-collateral/no-collateral/gtNoCollateralLatestSimulationRequest',
      gtCreditScoreLatest:
        'products/credit-score/credit-score/gtCreditScoreLatest',
    }),
    cptdCurrentPage() {
      return this.$route?.name ?? ''
    },
  },
  watch: {
    async $route() {
      if (this.isMounted) {
        this.actSetBusy(true)
        if (['ofertas', 'home'].includes(this.cptdCurrentPage)) {
          await Promise.allSettled([
            await this.actCreditScoreGetLatest(),
            await this.getOffers(),
          ])
          this.actSetBusy(false)
          this.resetScroll()
          return
        }
        setTimeout(() => {
          this.actSetBusy(false)
          this.resetScroll()
        }, 50)
      }
    },
  },
  async created() {
    this.actSetBusy(true)
    if (JbOAuth.isAccessTokenValid()) {
      await this.actGetUser()
      Promise.all([
        this.getPersona(),
        this.actGetUserAttributes(),
        this.actCreditScoreGetLatest(),
        this.actGetUserSubscriptions(),
        ...[
          ['ofertas', 'home'].includes(this.cptdCurrentPage) &&
            this.getOffers(),
        ],
      ]).then(async () => {
        this.isMounted = true
        this.actSetBusy(false)
      })
    } else {
      this.isMounted = true
      this.actSetBusy(false)
    }
  },
  methods: {
    ...mapActions({
      actGetUser: 'user/user/actGetUser',
      actGetUserAttributes: 'user/user-attributes/actGetUserAttributes',
      getPersona: 'user/persona/getPersona',
      actGetAutoOffers:
        'products/loans/auto-equity/auto-equity/actGetAutoOffers',
      actGetAutoColdOffers:
        'products/loans/auto-equity/cold-offer/actGetAutoColdOffers',
      actGetNoCollateralOffers:
        'products/loans/no-collateral/no-collateral/actGetNoCollateralOffers',
      actGetHomeEquityOffers:
        'products/loans/home-equity/home-equity/actGetHomeEquityOffers',
      actGetHomeEquityColdOffers:
        'products/loans/home-equity/cold-offer/actGetHomeEquityColdOffers',
      actCreditScoreGetLatest:
        'products/credit-score/credit-score/actCreditScoreGetLatest',
      actGetUserSubscriptions: 'user/subscriptions/actGetUserSubscriptions',
      actSetBusy: 'ui/app/actSetBusy',
      actGetAutoEquityLatestColdOfferRequest:
        'products/loans/auto-equity/cold-offer/actGetAutoEquityLatestColdOfferRequest',
      actGetNoCollateralLatestSimulationRequest:
        'products/loans/no-collateral/no-collateral/actGetNoCollateralLatestSimulationRequest',
      actGetHomeEquityLatestColdOfferRequest:
        'products/loans/home-equity/cold-offer/actGetHomeEquityLatestColdOfferRequest',
      simulateNoCollateralLoan:
        'products/loans/no-collateral/no-collateral/simulateNoCollateralLoan',
    }),
    getOffers() {
      return Promise.all([
        // [NO-COLLATERAL]
        this.actGetNoCollateralOffers(),
        this.actGetNoCollateralLatestSimulationRequest(),
        // [AUTO]
        this.actGetAutoColdOffers(),
        this.actGetAutoOffers(),
        this.actGetAutoEquityLatestColdOfferRequest(),
        // [HOME]
        this.actGetHomeEquityOffers(),
        this.actGetHomeEquityColdOffers(),
        this.actGetHomeEquityLatestColdOfferRequest(),
      ])
    },
    resetScroll() {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style lang="scss"></style>
