<template>
  <ClearLayout>
    <FgtsBalanceSimulateFormContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const FgtsBalanceSimulateFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/fgts/FgtsBalanceSimulateFormContainer.vue'
    ),
)
export default {
  name: 'SimularNoCollateral',
  components: {
    FgtsBalanceSimulateFormContainer,
    ClearLayout,
  },
}
</script>
<style lang="scss"></style>
