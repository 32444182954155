/* eslint-disable */
import { loadAsyncScript } from '@/helpers/script'
import { sentryException } from '@/helpers/sentry'
import JbOAuth from '@/helpers/oauth'

window.addEventListener('load', () => {
  window['dataLayer'] = window['dataLayer'] || []
  window['gtag'] =
    window['gtag'] ||
    function () {
      window['dataLayer'].push(arguments)
    }
  setTimeout(() => {
    try {
      loadAsyncScript({
        src: `https://www.googletagmanager.com/gtag/js?id=${
          import.meta.env.VITE_GA4_ID
        }`,
        defer: true
      })
      // @ts-ignore
      window['gtag']('js', new Date())
      const user_id = JbOAuth.getDecodedAccessToken()?.id
      const params: any = {}
      if (user_id) params.user_id = user_id
      // @ts-ignore
      window['gtag']('config', import.meta.env.VITE_GA4_ID, params)
    } catch (e: any) {
      sentryException(e)
    }
  })
})
