import { Provider } from '@/data/oauth/oauth'
import { getLinkedAccounts, link, login, register } from '@/api/oauth/oauth'
import { User } from '@/data/user/user'
import JbOAuth from '@/helpers/oauth'
import { HttpStatusCode } from '@/helpers/jb-error'
// STATE
const state = (): any => ({})

// GETTERS
const getters = {}

// MUTATIONS
const mutations = {}

// ACTIONS
const actions = {
  actOAuthSilentLogout({ dispatch }) {
    try {
      dispatch('actOAuthLogout', '')
    } catch (e: any) {}
  },
  actOAuthLogout(_, redirectTo = 'https://jurosbaixos.com.br/') {
    try {
      JbOAuth.logout(redirectTo)
    } catch (e: any) {
      return e?.response
    }
  },
  async actOAuthLogin(
    { dispatch },
    { provider, accessToken }: { provider: Provider; accessToken: string },
  ) {
    try {
      const response = await login(provider, accessToken)
      if (response.status === HttpStatusCode.OK && response?.data) {
        JbOAuth.handleJwtResponse(response?.data, provider)
        await dispatch('user/user/actGetUser', null, { root: true })
        await Promise.all([
          dispatch('user/persona/getPersona', null, { root: true }),
          dispatch(
            'products/credit-score/credit-score/actCreditScoreGetLatest',
            null,
            { root: true },
          ),
          dispatch('user/subscriptions/actGetUserSubscriptions', null, {
            root: true,
          }),

          dispatch('user/user/actTrackAdCampaign', 'login', { root: true }),
        ])

        sessionStorage.setItem(
          'jb.state.oauth.login-trough-send-token',
          'false',
        )
      }

      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actOAuthRegisterUser(
    { dispatch },
    {
      provider,
      user,
      accessToken,
    }: { provider: Provider; user: User; accessToken: string },
  ) {
    try {
      const sanitazedUser = {
        email: user.email,
        mobile_phone: (user.mobile_phone || '').replace(/\D/g, ''),
        cpf: (user.cpf || '').replace(/\D/g, ''),
        name: user.name,
      }
      const response = await register(provider, sanitazedUser, accessToken)
      if (response?.status === HttpStatusCode.OK) {
        sessionStorage.setItem(
          'jb.state.oauth.login-trough-send-token',
          'false',
        )
        sessionStorage.removeItem('jb.state.oauth.provider-data')
        JbOAuth.handleJwtResponse(response?.data, provider)
        await dispatch('user/user/actGetUser', null, { root: true })
        await dispatch('user/user/actTrackAdCampaign', 'sign_up', {
          root: true,
        })
        dispatch(
          'user/persona/setPersona',
          {
            persona: {
              info: { name: user.name },
            },
            save: false,
          },
          { root: true },
        )
      }

      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actOAuthLink(
    _,
    { provider, accessToken }: { provider: Provider; accessToken: string },
  ) {
    try {
      const response = await link(provider, accessToken)
      if (response?.status === HttpStatusCode.OK) {
        sessionStorage.setItem(
          'jb.state.oauth.login-trough-send-token',
          'false',
        )
        JbOAuth.handleJwtResponse(response?.data, provider)
      }

      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actGetLinkedAccounts() {
    try {
      return await getLinkedAccounts()
    } catch (e: any) {
      return e?.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
