<template>
  <div class="OfferAssistantResultPage">
    <ContainerWrapper size="xl">
      <div class="header">
        <JBLogo class="logo" @click="redirectToHome" />
      </div>
      <ContentContainerWrapper size="md">
        <div class="ico-artwork">
          <BaseIcon name="stars-mono" size="md" />
        </div>
        <h1 class="title">
          Muito bem! Aqui estão ótimas opções para você continuar
        </h1>
        <p class="description">
          Sabemos o quanto é difícil decidir, então fique à vontade para tentar
          novamente quando quiser.
        </p>
        <Button
          class="redo-button"
          type="outlined"
          variation="inversed"
          @click="redo"
        >
          Refazer
        </Button>
        <OfferAssistantProductsList :products="gtOfferAssistantProducts" />
        <div class="feedback">
          <p class="title">O quanto você gostou desta informação?</p>
          <FeedbackSmile @rate="handleFeedbackRate" />
        </div>
      </ContentContainerWrapper>
    </ContainerWrapper>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import Button from '@/aasgard/components/buttons/Button.vue'
import { defineAsyncComponent } from 'vue'
const OfferAssistantProductsList = defineAsyncComponent(
  () =>
    import('@/components/forms/offer-assistant/OfferAssistantProductsList.vue'),
)
const JBLogo = defineAsyncComponent(
  () => import('@/components/appPartials/AppLogo.vue'),
)
const BaseIcon = defineAsyncComponent(
  () => import('@/aasgard/components/base/BaseIcon.vue'),
)
const FeedbackSmile = defineAsyncComponent(
  () => import('@/components/commomComponents/feedback/FeedbackSmile.vue'),
)
export default {
  name: 'OfferAssistantResultPage',
  components: {
    FeedbackSmile,
    BaseIcon,
    JBLogo,
    OfferAssistantProductsList,
    ContainerWrapper,
    ContentContainerWrapper,
    Button,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      gtOfferAssistantProducts:
        'products/offer-assistant/gtOfferAssistantProducts',
    }),
  },
  methods: {
    ...mapActions({
      actEmitGaEvent: 'services/ga/actEmitGaEvent',
      actResetForm: 'products/offer-assistant/actResetForm',
    }),
    handleFeedbackRate(rate) {
      this.actEmitGaEvent({
        eventCategory: 'offer_assistant',
        eventAction: 'click_feedback_button',
        eventLabel: 'click_feedback_button',
        variant: rate,
      })
    },
    redo() {
      this.actResetForm()
      this.$router.push({
        path: '/forms/assistente-ofertas',
        query: { started: true },
      })
    },
    redirectToHome() {
      window.location.href =
        this.$route.query?.from || 'https://jurosbaixos.com.br'
    },
  },
}
</script>
<style lang="scss">
.OfferAssistantResultPage {
  background-color: $color-brand-primary;
  min-height: 100vh;

  & > .ContainerWrapper {
    & > .header {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > .logo {
        min-width: 136px;
        max-width: 136px;
      }
    }

    & > .ContentContainerWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .ico-artwork {
        display: flex;
        width: 64px;
        height: 64px;
        padding: 14px;
        justify-content: center;
        align-items: center;
        border-radius: 10000px;
        background: $color-black-lower;

        & > .BaseIcon {
          path {
            fill: $color-feedback-warning;
          }
        }
      }

      & > .title {
        @include headline-5;
        color: $color-white-high;
        text-align: center;
        margin: 0 0 16px 0;
      }
      & > .description {
        @include body-1;
        color: $color-white-medium;
        text-align: center;
        margin: 0 0 32px 0;
      }
      & > .redo-button {
        width: 216px;
        margin: 0 0 24px 0;
      }
      & > .feedback {
        margin: 32px 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > .title {
          color: $color-white-high;
          text-align: center;
          @include subtitle-2;
          margin: 0 0 16px 0;
        }
      }
    }
  }
}
</style>
