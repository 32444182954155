<template>
  <Dialog
    :is-fullscreen-mobile="true"
    class="FeedbackDialog"
    v-bind="$attrs"
    hide-title
  >
    <template #content>
      <div :class="['feedback-type', type]">
        <BaseIcon :name="type + '-artwork'" size="lg" />
        <h2 v-if="title">
          {{ title }}
        </h2>
      </div>
      <slot />
    </template>
  </Dialog>
</template>
<script>
import Dialog from '@/aasgard/components/modals/Dialog.vue'
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
export default {
  name: 'FeedBackDialog',
  components: {
    Dialog,
    BaseIcon,
  },
  props: {
    type: { type: String, default: 'warning' },
    title: { type: String, default: '' },
  },
}
</script>
<style lang="scss">
.FeedbackDialog {
  & > .content-wrapper {
    & > .content {
      & > .feedback-type {
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 12px;

        &.positive {
          background: $color-feedback-positive-light;
        }

        &.negative {
          background: $color-feedback-negative-light;
        }

        &.warning {
          background: $color-feedback-warning-light;
        }

        &.info {
          background: $color-feedback-info-light;
        }

        & > svg {
          width: 48px;
          height: 48px;
        }

        & > h2 {
          @include headline-5;
          color: $color-black-high;
          margin: 0;
          text-align: center;
          max-width: 312px;
        }
      }
    }
  }
}
</style>
