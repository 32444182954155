<template>
  <ClearLayout>
    <div class="ProfilePersonalInfoPage">
      <NavBar @back="$router.back()" @close="$router.push('/home')" />
      <ContentContainerWrapper>
        <FormContainer>
          <PersonaResidenceFormContainer @submit="submit" />
        </FormContainer>
      </ContentContainerWrapper>
    </div>
  </ClearLayout>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
const FormContainer = defineAsyncComponent(
  () => import('@/containers/forms/FormContainer.vue'),
)
const PersonaResidenceFormContainer = defineAsyncComponent(
  () => import('@/containers/forms/persona/PersonaResidenceFormContainer.vue'),
)

export default {
  name: 'ProfileResidencePage',
  components: {
    ClearLayout,
    ContentContainerWrapper,
    NavBar,
    FormContainer,
    PersonaResidenceFormContainer,
  },
  methods: {
    submit() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss"></style>
