<template>
  <DefaultLayout>
    <div />
  </DefaultLayout>
</template>
<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'

export default {
  name: 'Index',
  components: { DefaultLayout },
  created() {
    this.$router.push({ path: '/login', query: this.$route.query })
  },
}
</script>
<style scoped lang="scss"></style>
