<template>
  <ClearLayout>
    <ContainerWrapper class="LoanDataPage">
      <NavBar
        :extended="true"
        @back="$router.back()"
        @close="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <LoanForm
          :duration-list="[12, 18, 24, 36]"
          :min-amount="100"
          :max-amount="50000"
          :min-duration="12"
          :max-duration="36"
          :monthly-interest-rate="10"
          :monthly-income="cptdMonthlyIncome"
          @submit="submit($event)"
        />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const LoanForm = defineAsyncComponent(
  () => import('@/components/forms/loans/LoanForm.vue'),
)
export default {
  name: 'NoCollateralLoanData',
  components: {
    ClearLayout,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
    LoanForm,
  },
  computed: {
    ...mapGetters({
      personaData: 'user/persona/personaData',
    }),
    cptdMonthlyIncome() {
      return this.personaData?.finance?.monthly_income
    },
  },
  methods: {
    submit(data) {
      localStorage.setItem(
        'jb.state.loan',
        JSON.stringify({
          amount: data.amount,
          duration: data.duration,
          reason: data.reason,
        }),
      )
      this.$router.push({
        path: '/forms/emprestimo/sem-garantia/simular',
        query: this.$route?.query || {},
      })
    },
  },
}
</script>
