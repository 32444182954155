import UserSubscriptionsApi from '@/api/user/subscriptions'
import {
  ProductSubscription,
  Subscription,
  TierSubscription,
} from '@/data/subscriptions/subscriptions'
import { localDate } from '@/helpers/date'
// STATE
const state = (): any => ({
  subscriptions: [],
})

// GETTERS
const getters = {
  gtUserSubscriptions(state): any {
    return state.subscriptions
  },
  gtUserHasJbPrimeSubscription(state): boolean {
    return state.subscriptions.some(
      ({ product, tier, valid_until }: Subscription) =>
        product === ProductSubscription.JBPRIME &&
        TierSubscription.PAID === tier &&
        localDate(valid_until).getTime() > new Date().getTime(),
    )
  },
}

// MUTATIONS
const mutations = {
  SET_USER_SUBSCRIPTIONS(state, subscriptions): void {
    state.subscriptions = subscriptions
  },
}

// ACTIONS
const actions = {
  async actGetUserSubscriptions({ commit }) {
    try {
      const response = await UserSubscriptionsApi.getSubscriptions()

      if (response?.status === 200 && response?.data) {
        commit('SET_USER_SUBSCRIPTIONS', response?.data)
      }
      return response
    } catch (e: any) {
      commit('SET_USER_SUBSCRIPTIONS', [])
      return e.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
