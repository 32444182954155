import formatDate from '@/helpers/formatDate'
import { sanitizeAddress, sanitizeAddressNumber } from '~/helpers/address'
import { LoanReason } from '~/data/common'

function translatePersonaToNoCollateralSimulation({
  loan,
  persona,
  user,
  source,
  score,
}) {
  if (persona && loan && user) {
    const todayDate = new Date()
    return {
      info: {
        ...persona.info,
        politically_exposed: persona?.info?.politically_exposed || false, // We don't have this info on frontend yet,
        name: user.name ?? persona?.info?.name,
      },
      residence: {
        ...persona.residence,
        number: sanitizeAddressNumber(persona?.residence?.number),
        address: sanitizeAddress(persona?.residence?.address),
        district: sanitizeAddress(persona?.residence?.district),
      },
      finance: {
        ...persona.finance,
        annotated: score >= 800 ? false : persona?.finance?.annotated,
        ...(persona?.finance?.account?.number
          ? {
              account: {
                ...persona.finance.account,
                number: persona.finance.account.number.replace(/[^0-9PX]/g, ''),
              },
            }
          : {}),
      },
      due_date: formatDate(
        new Date(
          new Date().setDate(todayDate.getDate() + 30),
        ).toLocaleDateString('pt-BR'),
      ),
      ...loan,
      reason: loan.reason ?? LoanReason.BILLS, // reason: BILLS DEFAULT
      source,
      email: user.email,
      mobile_phone: user.mobile_phone,
      cpf: user.cpf,
      user_id: user.id,
    }
  }

  return {}
}

export { translatePersonaToNoCollateralSimulation }
