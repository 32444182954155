<template>
  <LoginPageLayout>
    <HelpContainer />
  </LoginPageLayout>
</template>
<script>
import LoginPageLayout from '@/layouts/LoginLayout.vue'
import { defineAsyncComponent } from 'vue'
const HelpContainer = defineAsyncComponent(
  () => import('@/containers/login/HelpContainer.vue'),
)
export default {
  name: 'HelpPage',
  components: {
    LoginPageLayout,
    HelpContainer,
  },
}
</script>
<style lang="sass"></style>
