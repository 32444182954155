<template>
  <ClearLayout>
    <CreditScoreReasonsPageContainer />
  </ClearLayout>
</template>
<script>
import { defineAsyncComponent } from 'vue'

const CreditScoreReasonsPageContainer = defineAsyncComponent(
  () => import('@/containers/credit-score/CreditScoreReasonsPageContainer.vue'),
)
import ClearLayout from '@/layouts/ClearLayout.vue'
export default {
  name: 'CreditScoreReasonsPage',
  components: {
    ClearLayout,
    CreditScoreReasonsPageContainer,
  },
}
</script>
