import { EmprestimoSimHiringSteps } from '@/helpers/partners/emprestimo_sim'
import { BvHiringSteps } from '@/helpers/partners/bv'
import { Partner } from '@/data/common'
import { generateWhatsAppLink } from '@/helpers/whatsapp'
import { isAndroid, isIOS, isMobile } from '@/helpers/globalFunctions'
import { CredihomeHiringSteps } from '~/helpers/partners/credihome'
import { isBusinessHours } from '~/helpers/time'
import { ConexaoFinanceiraHiringSteps } from '~/helpers/partners/flowcredi'
import { FlowcrediHiringSteps } from '~/helpers/partners/conexao_financeira'
import { AgilHiringSteps } from '~/helpers/partners/agil'

export const PartnersName: Record<Partner | string, string> = {
  SIMPLIC: 'Simplic',
  UNKNOWN_PARTNER: '',
  EMPRESTIMO_SIM: 'Sim (Santander)',
  BANCOPAN: 'Banco Pan',
  CREDIHOME: 'Credihome',
  CREDITAS: 'Creditas',
  ITAU: 'iti - Itaú',
  SUPERSIM: 'SuperSim',
  BANCO_DO_BRASIL: 'Banco do Brasil',
  ZEMA: 'Zema Financeira',
  REBEL: 'Geru',
  PROVU: 'Provu',
  PORTOCRED: 'Portocred',
  NOVERDE: 'NoVerde',
  BV: 'Banco BV',
  BV_FGTS: 'Banco BV',
  JEITTO: 'Jeitto',
  AGIL: 'Ágil',
  CREFAZ: 'Crefaz',
  JBCRED: 'JBCred',
  JUVO: 'Juvo',
  PICPAY: 'PicPay',
  HCRED: 'H Cred',
  UPP: 'Up.p',
  BLIPAY: 'Blipay',
  CONEXAO_FINANCEIRA: 'Conexão Financeira',
  FLOWCREDI: 'FlowCredi',
  ZIPPI: 'Zippi',
  FACIO: 'Facio',
}

export const PartnerByPassStatusCheck: Record<Partner | string, string | any> =
  {
    SUPERSIM: true,
  }

export const PartnerEnableHelpCenter: Record<Partner | string, string | any> = {
  SUPERSIM: true,
}

export const PartnerRedirectLinks: Record<
  string,
  Record<Partner | string, any>
> = {
  NO_COLLATERAL: {
    JEITTO: () => undefined,
    AGIL: () => undefined,
    NOVERDE: () => undefined,
    REBEL: () => undefined,
    CREFAZ: () => {
      if (isBusinessHours()) {
        return generateWhatsAppLink({
          text: 'Quero continuar a minha oferta com a Crefaz. Pode me ajudar?',
        })
      }

      return undefined
    },
    EMPRESTIMO_SIM: () => {
      if (isIOS) {
        return 'https://apps.apple.com/br/app/empr%C3%A9stimo-sim-cr%C3%A9dito-online/id1479233209'
      } else if (isAndroid) {
        return 'https://play.google.com/store/apps/details?id=com.aymore.sim'
      } else {
        return 'https://emprestimosim.com.br/app/#/login/continueProposal'
      }
    },
    PORTOCRED: () => {
      return generateWhatsAppLink({
        phone: '5511972385014',
        text: 'Olá! Tive uma oferta com a Portocred no site do Juros Baixos e queria continuar a contratação. Pode me ajudar?',
      })
    },
    SUPERSIM: () => {
      return 'https://www.supersim.com.br/entrar/'
    },
    SIMPLIC: () => undefined,
    ITAU: () => {
      return 'https://p98p.adj.st/emprestimo?adj_t=6inkqkp&adj_campaign=jurosbaixos&adj_adgroup=visitantes&adj_creative=btnbaixarapp&adj_fallback=https%3A%2F%2Femprestimo.conta.iti.itau%2F%3Futm_source%3Dcanal_proprietario%26utm_medium%3Dsite%26utm_campaign%3Diti_credito-vendas-jurosbaixos-externo%26utm_content%3Dvisitantes-botao-visitantes-btnbaixarapp&adj_redirect_macos=https%3A%2F%2Femprestimo.conta.iti.itau%2F%3Futm_source%3Dcanal_proprietario%26utm_medium%3Dsite%26utm_campaign%3Diti_credito-vendas-jurosbaixos-externo%26utm_content%3Dvisitantes-botao-visitantes-btnbaixarapp'
    },
  },
  AUTO: {
    EMPRESTIMO_SIM: () => {
      if (isBusinessHours()) {
        return generateWhatsAppLink({
          text: 'Olá! Quero continuar minha contratação com a Empréstimo Sim.',
        })
      }
      if (isIOS) {
        return 'https://apps.apple.com/br/app/empr%C3%A9stimo-sim-cr%C3%A9dito-online/id1479233209'
      }

      if (isAndroid) {
        return 'https://play.google.com/store/apps/details?id=com.aymore.sim'
      }

      return 'https://emprestimosim.com.br/app/#/login/continueProposal'
    },
    CREDITAS: () => {
      return generateWhatsAppLink({
        phone: '551135228020',
        text: 'Olá sou cliente Juros Baixos e gostaria de seguir com minha oferta da Creditas.',
      })
    },
    BV: () => {
      return ''
    },
  },
  HOME_EQUITY: {
    CREDIHOME: () =>
      generateWhatsAppLink({
        phone: '5511958974423',
        text: 'Olá! Sou cliente da Juros Baixos e gostaria de continuar na contratação da minha oferta Credihome.',
      }),
  },
}

export const PartnerWithQrCode: Record<Partner | string, string | any> = {
  ITAU: true,
}

export const PartnerEditableConditions: Record<Partner | string, string | any> =
  {
    NOVERDE: false,
  }

export const PartnerRedirectDestinationType: Record<
  Partner | string,
  string | any
> = {
  [Partner.ITAU]: () => {
    return isMobile ? 'aplicativo' : 'site'
  },
  [Partner.BV]: () => 'whatsapp',
  [Partner.CREFAZ]: () => 'whatsapp',
}

export const PartnersHiringSteps: Record<
  string | Partner,
  Array<Record<string, any>>
> = {
  CREDIHOME: CredihomeHiringSteps,
  EMPRESTIMO_SIM: EmprestimoSimHiringSteps,
  BV: BvHiringSteps,
  CONEXAO_FINANCEIRA: ConexaoFinanceiraHiringSteps,
  FLOWCREDI: FlowcrediHiringSteps,
  AGIL: AgilHiringSteps,
}

export const PartnersTips: Record<Partner | string, string | any> = {
  SIMPLIC:
    'Selecione a forma de pagamento Débito Automático para aumentar as suas chances de contratação.',
}

export const PartnerBankCodesAllowed: Record<
  Partner | string,
  Array<string>
> = {
  REBEL: ['001', '033', '104', '237', '341'],
  SUPERSIM: ['001', '033', '041', '077', '104', '237', '260', '341'],
}
