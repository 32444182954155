<template>
  <ClearLayout>
    <div class="ProfileBankAccountPage">
      <NavBar @back="$router.back()" @close="$router.push('/home')" />
      <ContentContainerWrapper>
        <FormContainer>
          <PersonaFinanceBankAccountFormContainer @submit="submit" />
        </FormContainer>
      </ContentContainerWrapper>
    </div>
  </ClearLayout>
</template>
<script>
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const FormContainer = defineAsyncComponent(
  () => import('@/containers/forms/FormContainer.vue'),
)
const PersonaFinanceBankAccountFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/persona/PersonaFinanceBankAccountFormContainer.vue'
    ),
)
export default {
  name: 'ProfileBankAccountPage',
  components: {
    ClearLayout,
    ContentContainerWrapper,
    NavBar,
    FormContainer,
    PersonaFinanceBankAccountFormContainer,
  },
  methods: {
    submit() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss"></style>
