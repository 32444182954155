const initialState = () => ({})

const state = initialState

// GETTERS
const getters = {
  gtDynamicForms(state): any {
    return state
  },
}

// MUTATIONS
const mutations = {
  SETUP_FORM(state, formId) {
    if (!formId) {
      throw new Error('DYNAMIC_FORMS_STORE: formId is required')
    }
    state[formId] = {
      ...(state[formId] || {}),
    }
  },
  UPDATE_FORM(state, { formId, key, value }): void {
    if (!formId) {
      throw new Error('DYNAMIC_FORMS_STORE: formId is required')
    }

    if (!state[formId]) {
      throw new Error('DYNAMIC_FORMS_STORE: form not found')
    }

    if (key) {
      state[formId] = {
        ...state[formId],
        [key]: value,
      }
    } else if (!key && typeof value === 'object') {
      state[formId] = {
        ...state[formId],
        ...value,
      }
    }
  },
  SET_INITIAL_STATE(state, formId) {
    delete state[formId]
  },
}

// ACTIONS
const actions = {
  actSetupForm({ commit }, formId) {
    commit('SETUP_FORM', formId)
  },
  actUpdateForm({ commit }, { formId, key, value }) {
    commit('UPDATE_FORM', { formId, key, value })
  },
  actSetInitialState({ commit }, formId) {
    commit('SET_INITIAL_STATE', formId)
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
