import noCollateral from '@/store/modules/products/loans/no-collateral/no-collateral'
import unauthNoCollateral from '@/store/modules/products/loans/no-collateral/unauth-no-collateral'
const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    'no-collateral': noCollateral,
    'unauth-no-collateral': unauthNoCollateral,
  },
}
