const gmailList = [
  'gmail.com.br',
  'gmail.co',
  'gmil.com',
  'gmal.com',
  'mail.com',
  'gnail.com',
  'gmai.com',
  'gamil.com',
  'ymail.com',
  'gmail.com.com',
  'email.com',
  'gmsil.com',
  'hmail.com',
  'gmeil.com',
  'gemail.com',
  'gamail.com',
  'gmaill.com',
  'gmaol.com',
  'gmail.cm',
  'gmqil.com',
  'gmaik.com',
  'gmali.com',
  'gmail.co.com',
  'g.mail.com',
  'gmial.com',
  'gmaim.com',
  'gml.com',
  'gmaik.com',
  'gmali.com',
  'gmail.co.com',
]

const hotmailList = [
  'hotmai.com',
  'hormail.com',
  'homail.com',
  'hitmail.com',
  'hotmal.com',
  'hotmsil.com',
  'hotamil.com',
  'hotnail.com',
  'gotmail.com',
]

const outlookList = [
  'outook.com',
  'outllok.com',
  'outloo.com',
  'outlok.com',
  'outloook.com',
  'outlool.com',
  'outllook.com',
  'oulook.com',
]

const icloudList = ['iclou.com']

export const validateEmailDomain = (
  email = '',
): { valid: boolean; message: string } => {
  const result = {
    valid: true,
    message: '',
  }

  if (!email) {
    return {
      valid: false,
      message: 'Preencha um email válido.',
    }
  }

  const [left, domain] = email.split('@')

  if (!left || !domain) {
    return {
      valid: false,
      message: 'Preencha um email válido.',
    }
  }

  const invalidMainDomainMessage = `O domínio @${domain} está incorreto.`

  if (gmailList.includes(domain)) {
    return {
      valid: false,
      message: invalidMainDomainMessage.replace(
        '{domainTemplate}',
        '@gmail.com',
      ),
    }
  }

  if (hotmailList.includes(domain)) {
    return {
      valid: false,
      message: invalidMainDomainMessage.replace(
        '{domainTemplate}',
        '@hotmail.com',
      ),
    }
  }

  if (outlookList.includes(domain)) {
    return {
      valid: false,
      message: invalidMainDomainMessage.replace(
        '{domainTemplate}',
        '@outlook.com',
      ),
    }
  }

  if (icloudList.includes(domain)) {
    return {
      valid: false,
      message: invalidMainDomainMessage.replace(
        '{domainTemplate}',
        '@icloud.com',
      ),
    }
  }

  return result
}
