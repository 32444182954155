<template>
  <SimpleLayout>
    <div class="CxAppDocsPage">
      <CxDocsContainer />
    </div>
  </SimpleLayout>
</template>
<script>
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import { defineAsyncComponent } from 'vue'
const CxDocsContainer = defineAsyncComponent(
  () => import('@/containers/cx/CxDocsContainer.vue'),
)
export default {
  name: 'CxDocsPage',
  components: {
    SimpleLayout,
    CxDocsContainer,
  },
}
</script>
<style lang="scss">
.CxAppDocsPage {
  max-width: 624px;
  margin: 0 auto;
}
</style>
