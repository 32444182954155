import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'

export const retrieveOfferDocsEntries = async ({
  offer_id,
  product,
  payload,
}) => {
  return await JurosBaixosSystemsAPI.post(
    `/loans/${product}/offers/${offer_id}/docs`,
    payload,
  ).catch(createRequestException)
}

export const actRefreshOfferDocsLinks = async ({ offer_id, product }) => {
  return await JurosBaixosSystemsAPI.post(
    `/loans/${product}/offers/${offer_id}/docs/refresh-links`,
  ).catch(createRequestException)
}

export const getOfferDocument = async ({ offer_id, product, doc_id }) => {
  return await JurosBaixosSystemsAPI.get(
    `/loans/${product}/offers/${offer_id}/docs/${doc_id}`,
  ).catch(createRequestException)
}

export const updateOfferDocument = async ({
  doc_id,
  offer_id,
  payload,
  product,
}) => {
  return await JurosBaixosSystemsAPI.patch(
    `/loans/${product}/offers/${offer_id}/docs/${doc_id}`,
    payload,
  ).catch(createRequestException)
}

export const deleteOfferDocument = async ({ doc_id, offer_id, product }) => {
  return await JurosBaixosSystemsAPI.delete(
    `/loans/${product}/offers/${offer_id}/docs/${doc_id}`,
  ).catch(createRequestException)
}

export const getPersonaDocuments = async () => {
  return await JurosBaixosSystemsAPI.get(`/persona/docs`).catch(
    createRequestException,
  )
}

export const getPersonaDocument = async (doc_id) => {
  return await JurosBaixosSystemsAPI.get(`/persona/docs/${doc_id}`).catch(
    createRequestException,
  )
}

export const deletePersonaDocument = async (doc_id) => {
  return await JurosBaixosSystemsAPI.delete(`/persona/docs/${doc_id}`).catch(
    createRequestException,
  )
}
