import { ComponentOptionsMixin } from 'vue'

export const MEDIA_QUERIES_MAP = {
  XS: {
    minWidth: 0,
    maxWidth: 575,
  },
  SM: {
    minWidth: 576,
    maxWidth: 767,
  },
  MD: {
    minWidth: 768,
    maxWidth: 969,
  },
  LG: {
    minWidth: 970,
    maxWidth: 1199,
  },
  XL: {
    minWidth: 1200,
    maxWidth: 9999,
  },
}

export const mediaQueryMixin: ComponentOptionsMixin = {
  data() {
    return {
      extraSmallMediaQuery: false,
      smallMediaQuery: false,
      mediumMediaQuery: false,
      largeMediaQuery: false,
      extraLargeMediaQuery: false,
    }
  },
  computed: {
    isDesktop() {
      return (
        this.mediumMediaQuery ||
        this.largeMediaQuery ||
        this.extraLargeMediaQuery
      )
    },
  },
  mounted() {
    const { innerWidth } = window
    const { XS, SM, MD, LG, XL } = MEDIA_QUERIES_MAP
    // eslint-disable-next-line
    // @ts-ignore
    this.extraSmallMediaQuery =
      innerWidth >= XS.minWidth && innerWidth <= XS.maxWidth
    // eslint-disable-next-line
    // @ts-ignore
    this.smallMediaQuery =
      innerWidth >= SM.minWidth && innerWidth <= SM.maxWidth
    // eslint-disable-next-line
    // @ts-ignore
    this.mediumMediaQuery =
      innerWidth >= MD.minWidth && innerWidth <= MD.maxWidth
    // eslint-disable-next-line
    // @ts-ignore
    this.largeMediaQuery =
      innerWidth >= LG.minWidth && innerWidth <= LG.maxWidth
    // eslint-disable-next-line
    // @ts-ignore
    this.extraLargeMediaQuery = innerWidth >= XL.minWidth
  },
  created() {
    this.gEventBus.on('JB_UI:media-query-changed', ({ key, value }) => {
      this[key] = value
    })
  },
}
