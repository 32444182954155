import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { OfferStatistics } from '@/data/offerstatistics/offerstatistics'

export const getNoCollateralOffersStatistics = async (): Promise<
  AxiosResponse<OfferStatistics> | undefined
> => {
  if (import.meta.env.VITE_FEATURE_STATISTICS_NO_COLLATERAL !== 'true') return
  return await JurosBaixosSystemsAPI.get(
    `/statistics/loans/nocollateral`,
  ).catch(createRequestException)
}
