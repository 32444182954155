import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import {
  ColdOfferRequest,
  ColdOfferResponse,
} from '@/data/loans/auto/coldoffer'

export const submitAutoColdOffer = async (payload: ColdOfferRequest) => {
  return await JurosBaixosSystemsAPI.post(`loans/auto`, payload).catch(
    createRequestException,
  )
}

export const fetchUserColdOffers = async (): Promise<
  AxiosResponse<Array<ColdOfferResponse> | Array<any>>
> => {
  return await JurosBaixosSystemsAPI.get(`loans/auto/cold-offers`).catch(
    createRequestException,
  )
}

export const getUserColdOffersByRequestId = async (
  requestId: string,
): Promise<AxiosResponse<Array<ColdOfferResponse>>> => {
  return await JurosBaixosSystemsAPI.get(
    `/loans/auto/cold-offers/${requestId}`,
  ).catch(createRequestException)
}

export const getColdOfferRequest = async (
  coldOfferId: string,
): Promise<AxiosResponse<Array<ColdOfferRequest>>> => {
  return await JurosBaixosSystemsAPI.get(
    `/loans/auto/cold-offers/${coldOfferId}/request`,
  ).catch(createRequestException)
}
export const getLatestColdOfferRequest = async (): Promise<
  AxiosResponse<ColdOfferRequest>
> => {
  return await JurosBaixosSystemsAPI.get(
    `/loans/auto/cold-request/latest`,
  ).catch(createRequestException)
}
