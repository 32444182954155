import {
  getHomeEquityOfferById,
  getHomeEquityOffers,
} from '@/api/loans/home-equity/offers'
import { selectHomeEquityOffer } from '@/api/loans/home-equity/offers/select-offer'
import {
  filterExpiredOffers,
  offerPartnersConfig,
  partnerHasStepFields,
  sortOffersByInterestRate,
} from '@/helpers/offers'
import { SelectAutoEquityOffer } from '@/data/loans/auto/select'
import { OfferStatus } from '@/data/common'
import { getHomeEquityOffersBySimulationId } from '@/api/loans/home-equity/simulate'
import { HomeEquityOfferResponse } from '@/data/loans/home-equity/offer'
import router from '@/router'

// STATE
const state = (): any => ({
  proposedOffers: [],
  onGoingOffers: [],
  validatingOffers: [],
  simulationInfo: {},
  homeEquityOfferSelected: null,
  homeEquityOfferSelectedContract: '',
})

// GETTERS
const getters = {
  gtAllProposedHomeEquityOffers(state) {
    return state.proposedOffers
      .filter(filterExpiredOffers)
      .sort(sortOffersByInterestRate)
  },
  gtAllValidatingHomeEquityOffers(state) {
    return state.validatingOffers
  },
  gtAllOnGoingHomeEquityOffers(state) {
    return state.onGoingOffers
  },
  gtHomeEquityOfferSelected(state): any {
    return state.homeEquityOfferSelected
  },
  gtHomeEquityOfferSelectedContract(state): any {
    return state.homeEquityOfferSelectedContract
  },
}

// MUTATIONS

const mutations = {
  SET_HOME_EQUITY_OFFERS(state, payload: Array<HomeEquityOfferResponse>): void {
    payload.forEach((o: HomeEquityOfferResponse | any) => {
      // STANDARDIZE OFFER
      o.product_type = 'home-equity'
      o.interest_rate_monthly =
        o.interest_rate_monthly ||
        offerPartnersConfig[o.partner].interest_monthly_min
      o.duration_max =
        o.duration_max || offerPartnersConfig[o.partner].duration_max

      // SET OFFER DECIMALS
      o.cet_rate = o.cet_rate * 100
      o.interest_rate_annually = o.interest_rate_annually * 100
      o.interest_rate_monthly = o.interest_rate_monthly * 100
    })
    state.proposedOffers = payload.filter(
      (o) => o.status === OfferStatus.PROPOSED,
    )
    state.onGoingOffers = payload.filter(
      (o) => o.status === OfferStatus.ONGOING,
    )
    state.validatingOffers = payload.filter(
      (o) => o.status === OfferStatus.VALIDATING,
    )
  },
  SET_HOME_EQUITY_OFFER_SELECTED(state, payload) {
    state.homeEquityOfferSelected = payload
  },
}

// ACTIONS

const actions = {
  // NEW MODEL METHODS
  actSetHomeEquityOfferSelected({ commit }, payload) {
    commit('SET_HOME_EQUITY_OFFER_SELECTED', payload)
  },
  async actGetHomeEquityOffers(
    { dispatch, commit },
    options = {
      status: [OfferStatus.PROPOSED, OfferStatus.ONGOING],
    },
  ) {
    const disableHomeEquityCall = true
    if (disableHomeEquityCall) return []
    const errorMsg =
      'Tivemos um probleminha ao consultar novas ofertas para voce'
    try {
      const response = await getHomeEquityOffers(options)
      const { data } = response
      if (data) {
        commit('SET_HOME_EQUITY_OFFERS', data)
      } else {
        dispatch('utils/feedback/createFeedback', errorMsg, { root: true })
        return
      }
      return response
    } catch (e: any) {
      dispatch('utils/feedback/createFeedback', errorMsg, { root: true })
      commit('SET_HOME_EQUITY_OFFERS', [])
      return []
    }
  },
  async actGetHomeEquityOfferById(_, offer_id) {
    try {
      const response = await getHomeEquityOfferById(offer_id)
      if (response?.data) {
        response.data = {
          ...response.data,
          cet_rate: response.data?.cet_rate * 100,
          interest_rate_annually: response.data?.cet_rate * 100,
          interest_rate_monthly: response.data?.cet_rate * 100,
        }
      }
      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actGetHomeEquityOffersBySimulationId(
    _,
    {
      simulationId,
      statusList,
    }: { simulationId: string; statusList: Array<OfferStatus> | undefined },
  ) {
    try {
      const response = await getHomeEquityOffersBySimulationId(
        simulationId,
        statusList,
      )
      if (response?.status === 200 && response?.data?.length > 0) {
        response.data.forEach((o: HomeEquityOfferResponse) => {
          // STANDARDIZE OFFER
          o.interest_rate_monthly =
            o.interest_rate_monthly ||
            offerPartnersConfig[o.partner].interest_monthly_min
          o.duration_max =
            o.duration_max || offerPartnersConfig[o.partner].duration_max

          // SET OFFER DECIMALS
          o.cet_rate = o.cet_rate * 100
          o.interest_rate_annually = o.interest_rate_annually * 100
          o.interest_rate_monthly = o.interest_rate_monthly * 100
        })
      }
      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actSelectHomeEquityOffer(
    _,
    {
      offer,
      payload = {},
    }: {
      offer: HomeEquityOfferResponse
      payload: SelectAutoEquityOffer | object
    },
  ) {
    try {
      const partnerFields = partnerHasStepFields({ offer, step: 'home:select' })
      const { partner } = offer

      if (partnerFields && !(Object.keys(payload).length > 0)) {
        router.push({
          path: `/forms/emprestimo/home-equity/parceiros/${partner.toLowerCase()}/selecionar-oferta`,
        })
        return 'REDIRECTED_TO_PARTNER_PAGES'
      }

      return await selectHomeEquityOffer({
        payload,
        offer,
      })
    } catch (e: any) {
      return e?.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
