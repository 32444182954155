<template>
  <ClearLayout>
    <OfferPartnerRedirectModal :partner="cptdPartner" :url="cptdOfferUrl">
      <template #title>
        {{ cptdTitleRedirect }}
      </template>
      <template #alert-message>
        {{ cptdRedirectMessage }}
      </template>
    </OfferPartnerRedirectModal>
  </ClearLayout>
</template>
<script>
import { mapActions } from 'vuex'

import { PartnerRedirectLinks, PartnersName } from '@/helpers/partners/common'
import { Partner } from '@/data/common'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
import { isBusinessHours } from '@/helpers/time'
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
export default {
  name: 'RedirectPage',
  components: {
    ClearLayout,
    OfferPartnerRedirectModal,
  },
  data() {
    return {
      offer: undefined,
    }
  },
  computed: {
    cptdPartner() {
      const { partner } = this.$route?.params
      return partner?.toUpperCase() ?? ''
    },
    cptdOfferUrl() {
      return (
        PartnerRedirectLinks.AUTO[this.cptdPartner ?? '']?.() ??
        this.offer?.url ??
        ''
      )
    },
    cptdPartnerNameFormatted() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdTitleRedirect() {
      switch (this.cptdPartner) {
        case Partner.CREDITAS:
          return `Tudo certo! Finalize a contratação no WhatsApp da ${this.cptdPartnerNameFormatted}`
        default:
          return `Tudo certo! Finalize a contratação da sua oferta com a ${this.cptdPartnerNameFormatted}`
      }
    },
    cptdRedirectMessage() {
      const defaultMsg = `Este serviço é uma parceria com a ${this.cptdPartnerNameFormatted} e o processo continuará em seu site.`
      switch (this.cptdPartner) {
        case Partner.CREDITAS:
          return `Este serviço é uma parceria com a ${this.cptdPartnerNameFormatted} e o processo continuará em seu WhatsApp.`
        case Partner.EMPRESTIMO_SIM:
          if (isBusinessHours()) {
            return `Este serviço é uma parceria com a ${this.cptdPartnerNameFormatted} e o processo continuará em nosso WhatsApp.`
          }
          return defaultMsg
        default:
          return defaultMsg
      }
    },
  },
  async mounted() {
    const { offer_id } = this.$route?.params
    this.offer = await this.actGetAutoOfferById(offer_id)
    if (!this.offer) {
      this.createFeedback({ text: 'Oferta não encontrada' })
      this.scheduleRedirectTo('/ofertas')
    }
  },
  methods: {
    ...mapActions({
      actGetAutoOfferById:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferById',
      createFeedback: 'utils/feedback/createFeedback',
    }),
    scheduleRedirectTo(path) {
      setTimeout(() => {
        this.$router.push({
          path,
        })
      }, 6000)
    },
  },
}
</script>
<style lang="scss"></style>
