<template>
  <ClearLayout>
    <CreditScorePageContainer />
  </ClearLayout>
</template>
<script>
import { defineAsyncComponent } from 'vue'

const CreditScorePageContainer = defineAsyncComponent(
  () => import('@/containers/credit-score/CreditScorePageContainer.vue'),
)
import ClearLayout from '@/layouts/ClearLayout.vue'
export default {
  name: 'DadosPerfilPage',
  components: {
    ClearLayout,
    CreditScorePageContainer,
  },
}
</script>
