export const parseToBRL = (value) => {
  value = value || 0
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

export const parseToDecimal = (value) => {
  value = value || 0
  return value.toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
  })
}
