import {
  OfferAssistantAmountRangesEnum,
  OfferAssistantCollateralTypesEnum,
  OfferAssistantHasFgtsBalanceEnum,
  OfferAssistantPaymentDelayEnum,
  OfferAssistantReasonsEnum,
} from '@/helpers/offer-assistant/types'

export const OfferAssistantProducts = {
  NO_COLLATERAL: {
    icon: 'hand-money-mono',
    label: 'Empréstimo pessoal sem garantia',
    simulations: '90.302',
    id: 'no_collateral',
    filters: {
      amount: [
        OfferAssistantAmountRangesEnum.FROM_500_TO_5000,
        OfferAssistantAmountRangesEnum.FROM_5001_TO_10000,
        OfferAssistantAmountRangesEnum.FROM_10001_TO_20000,
        OfferAssistantAmountRangesEnum.FROM_20001_TO_30000,
        OfferAssistantAmountRangesEnum.FROM_30001_TO_40000,
        OfferAssistantAmountRangesEnum.FROM_40001_TO_50000,
      ],
      collaterals: [],
      hasFgts: [],
      paymentDelay: [],
      reasons: [],
    },
    order: 0,
    data: {
      details: [
        {
          label: 'De R$ 500 à R$ 20.000',
          icon: 'paid-mono',
        },
        {
          label: 'Taxa de juros de 8% à 20%',
          icon: 'offers-mono',
        },
        {
          label: 'Parcelas de 3 à 60 vezes',
          icon: 'calendar-mono',
        },
      ],
      qualification: [
        {
          label: 'Possuir renda mensal acima de um salário mínimo.',
          icon: 'check-circle-mono',
        },
        {
          label: 'Estar com o nome limpo na Serasa, SPC ou Boa Vista.',
          icon: 'check-circle-mono',
        },
        {
          label: 'Optar por uma parcela menor que 30% da sua renda mensal.',
          icon: 'check-circle-mono',
        },
      ],
      observations: [
        {
          label: 'A liberação do dinheiro é mais rápida.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'Não é preciso fornecer garantias para obter o empréstimo.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'O processo de contratação é mais simples.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'A taxa de juros pode ser mais alta que outras modalidades.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
        {
          label: 'As credoras geralmente disponibilizam valores menores.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
        {
          label: 'É necessário comprovar identidade e renda mensal.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
      ],
    },
    path: '/criar-conta',
    query: {
      nextPage: '/simular?product=personal_loan',
    },
  },
  MOBILE_EQUITY: {
    icon: 'smartphone-mono',
    label: 'Empréstimo com garantia de celular',
    simulations: '90.302',
    id: 'mobile_equity',
    filters: {
      amount: [
        OfferAssistantAmountRangesEnum.LOWER_THAN_500,
        OfferAssistantAmountRangesEnum.FROM_500_TO_5000,
      ],
      collaterals: [OfferAssistantCollateralTypesEnum.MOBILE_PHONE],
      hasFgts: [],
      paymentDelay: [],
      reasons: [
        OfferAssistantReasonsEnum.BILLS,
        OfferAssistantReasonsEnum.HOME_IMPROVEMENT,
        OfferAssistantReasonsEnum.FIX_CAR,
        OfferAssistantReasonsEnum.MEDICAL_EXPENSES,
        OfferAssistantReasonsEnum.BUY,
        OfferAssistantReasonsEnum.EDUCATION_EXPENSES,
        OfferAssistantReasonsEnum.HELP_FAMILY,
        OfferAssistantReasonsEnum.OTHER_REASON,
      ],
    },
    order: 1,
    data: {
      details: [
        {
          label: 'De R$ 500 à R$ 4.500',
          icon: 'paid-mono',
        },
        {
          label: 'Taxa de juros de 8,49% à 12,49%',
          icon: 'offers-mono',
        },
        {
          label: 'Parcelas de 3 à 12 vezes',
          icon: 'calendar-mono',
        },
      ],
      qualification: [
        {
          label:
            'Possuir um celular para oferecer como garantia do empréstimo.',
          icon: 'check-circle-mono',
        },
      ],
      observations: [
        {
          label: 'Aceitam pessoas negativadas e não consultam o SPC e Serasa.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'Aceitam celulares iOS e Android como garantia.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'O processo de contratação é mais simples e rápido.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'Bloqueio temporário de apps em caso de atrasos no pagamento.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
      ],
    },
    path: '/criar-conta',
    query: {
      nextPage: '/simular?product=personal_loan',
    },
  },
  AUTO_EQUITY: {
    icon: 'car-money-mono',
    label: 'Empréstimo com garantia de veículo',
    simulations: '14.696',
    id: 'auto_equity',
    filters: {
      amount: [
        OfferAssistantAmountRangesEnum.FROM_5001_TO_10000,
        OfferAssistantAmountRangesEnum.FROM_10001_TO_20000,
        OfferAssistantAmountRangesEnum.FROM_20001_TO_30000,
        OfferAssistantAmountRangesEnum.FROM_30001_TO_40000,
        OfferAssistantAmountRangesEnum.FROM_40001_TO_50000,
        OfferAssistantAmountRangesEnum.FROM_50001_TO_100000,
        OfferAssistantAmountRangesEnum.GREATER_THAN_100000,
      ],
      collaterals: [OfferAssistantCollateralTypesEnum.CAR],
      hasFgts: [],
      paymentDelay: [],
      reasons: [],
    },
    order: 2,
    data: {
      details: [
        {
          label: 'De R$ 500 à R$ 200.000',
          icon: 'paid-mono',
        },
        {
          label: 'Taxa de juros de 1,49% à 8,49%',
          icon: 'offers-mono',
        },
        {
          label: 'Parcelas de 6 à 60 vezes',
          icon: 'calendar-mono',
        },
      ],
      qualification: [
        {
          label: 'O veículo deve estar em nome do solicitante.',
          icon: 'check-circle-mono',
        },
        {
          label: 'O veículo deve estar pelo menos 80% quitado.',
          icon: 'check-circle-mono',
        },
        {
          label:
            'Veículo sem alienação fiduciária em bancos ou instituições financeiras.',
          icon: 'check-circle-mono',
        },
      ],
      observations: [
        {
          label: 'Os juros são 3,5x menores do que os do empréstimo pessoal.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'As insituições financeiras aceitam pessoas negativadas.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label:
            'Pode utilizar o veículo normalmente durante o período do empréstimo.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'O processo de contratação é mais detalhado.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
        {
          label:
            'Há risco de perder o veículo por falta de pagamento das parcelas.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
        {
          label: 'É necessário avaliar se o veículo é elegível como garantia.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
      ],
    },
    path: '/criar-conta',
    query: {
      nextPage: '/simular?product=auto_equity',
    },
  },
  HOME_EQUITY: {
    icon: 'home-money-mono',
    label: 'Empréstimo com garantia de imóvel',
    simulations: '1.238',
    id: 'home_equity',
    filters: {
      amount: [
        OfferAssistantAmountRangesEnum.FROM_40001_TO_50000,
        OfferAssistantAmountRangesEnum.FROM_50001_TO_100000,
        OfferAssistantAmountRangesEnum.GREATER_THAN_100000,
      ],
      collaterals: [OfferAssistantCollateralTypesEnum.PROPERTY],
      hasFgts: [],
      paymentDelay: [
        OfferAssistantPaymentDelayEnum.ONE_WEEK,
        OfferAssistantPaymentDelayEnum.FROM_TWO_TO_THREE_WEEKS,
        OfferAssistantPaymentDelayEnum.MORE_THAN_FOUR_WEEKS,
      ],
      reasons: [],
    },
    order: 3,
    data: {
      details: [
        {
          label: 'De R$ 40.000 à R$ 20.000',
          icon: 'paid-mono',
        },
        {
          label: 'Taxa de juros de 1,29% à 1,99%',
          icon: 'offers-mono',
        },
        {
          label: 'Parcelas de 12 à 240 vezes',
          icon: 'calendar-mono',
        },
      ],
      qualification: [
        {
          label: 'O imóvel deve estar em nome do solicitante.',
          icon: 'check-circle-mono',
        },
        {
          label: 'O imóvel deve estar totalmente quitado.',
          icon: 'check-circle-mono',
        },
        {
          label:
            'O imóvel não pode ter sido obtido por meio de programas sociais.',
          icon: 'check-circle-mono',
        },
      ],
      observations: [
        {
          label:
            'A modalidade de empréstimo com a menor taxa de juros do mercado.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label:
            'Possui o prazo de pagamento mais longo que as outras modalidades.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label:
            'As instituições financeiras liberam valores de crédito mais altos.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'O processo de contratação é mais detalhado.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
        {
          label:
            'Há risco de perder o imóvel por falta de pagamento das parcelas.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
        {
          label: 'É necessário avaliar se o imóvel é elegível como garantia.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
      ],
    },
    path: '/criar-conta',
    query: {
      nextPage: '/simular?product=home_equity',
    },
  },
  FGTS: {
    icon: 'gift-mono',
    label: 'Antecipação do Saque-Aniversário do FGTS',
    simulations: '35.891',
    id: 'fgts',
    filters: {
      amount: [],
      collaterals: [],
      hasFgts: [OfferAssistantHasFgtsBalanceEnum.YES],
      paymentDelay: [],
      reasons: [],
    },
    order: 4,
    data: {
      details: [
        {
          label: 'De R$ 300 à R$ 50.000',
          icon: 'paid-mono',
        },
        {
          label: 'Taxa de juros de 1,49% à 2,05%',
          icon: 'offers-mono',
        },
        {
          label: 'Parcelas de 3 à 120 vezes',
          icon: 'calendar-mono',
        },
      ],
      qualification: [
        {
          label: 'Ter mais de R$ 300,00 de saldo no FGTS desbloqueado.',
          icon: 'check-circle-mono',
        },
        {
          label:
            'Ter mais de 18 anos de idade e não ser uma pessoa aposentada.',
          icon: 'check-circle-mono',
        },
      ],
      observations: [
        {
          label: 'Aceitam pessoas negativadas e não consultam o SPC e Serasa.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'O valor é debitado do FGTS, sem parcelas mensais.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'O FGTS é bloqueado em caso de demissão.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
        {
          label: 'O prazo de pagamento varia de 2 a 12 anos para quitar.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
      ],
    },
    path: '/criar-conta',
    query: {
      nextPage: '/simular?product=fgts',
    },
  },
  CREDIT_CARD: {
    icon: 'credit-card-mono',
    label: 'Cartão de crédito',
    simulations: '20.321',
    id: 'credit_card',
    filters: {
      amount: [],
      collaterals: [],
      hasFgts: [],
      paymentDelay: [],
      reasons: [
        OfferAssistantReasonsEnum.BILLS,
        OfferAssistantReasonsEnum.HOME_IMPROVEMENT,
        OfferAssistantReasonsEnum.FIX_CAR,
        OfferAssistantReasonsEnum.MEDICAL_EXPENSES,
        OfferAssistantReasonsEnum.BUY,
        OfferAssistantReasonsEnum.EDUCATION_EXPENSES,
        OfferAssistantReasonsEnum.VACATION,
        OfferAssistantReasonsEnum.HELP_FAMILY,
        OfferAssistantReasonsEnum.OTHER_REASON,
      ],
    },
    order: 5,
    data: {
      details: [
        {
          label: 'Limites de R$ 100 à R$ 5.000',
          icon: 'paid-mono',
        },
      ],
      qualification: [
        {
          label:
            'Ter mais de 18 anos de idade e não ser uma pessoa aposentada.',
          icon: 'check-circle-mono',
        },
      ],
      observations: [
        {
          label: 'O processo de contratação é mais simples e rápido.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'Ótima opção para usar em emergências e compras.',
          icon: 'check-circle-mono',
          iconClass: 'positive',
        },
        {
          label: 'A taxa de juros é uma das mais altas do mercado.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
        {
          label: 'O valor do limite dependerá do seu perfil de crédito.',
          icon: 'clear-mono',
          iconClass: 'negative',
        },
      ],
    },
    path: '/criar-conta',
    query: {
      nextPage: '/simular?product=credit_card',
    },
  },
}
