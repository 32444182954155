import products from '@/store/modules/products'
import services from '@/store/modules/services'
import dynamicForms from '@/store/modules/dynamic-forms'
import oauth from '@/store/modules/oauth'
import token from '@/store/modules/token'
import utils from '@/store/modules/utils'
import ui from '@/store/modules/ui'
import user from '@/store/modules/user'
import statistics from '@/store/modules/statistics'
export default {
  products,
  services,
  statistics,
  ui,
  user,
  utils,
  'dynamic-forms': dynamicForms,
  oauth,
  token,
}
