<template>
  <ClearLayout>
    <IncomeLossInsuranceResumePageContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const IncomeLossInsuranceResumePageContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/income-loss/hiring/IncomeLossInsuranceResumePageContainer.vue'
    ),
)
export default {
  name: 'IncomeLossInsuranceResumePage',
  components: {
    ClearLayout,
    IncomeLossInsuranceResumePageContainer,
  },
}
</script>
<style lang="scss"></style>
