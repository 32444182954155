import { appendToHeadTag, loadAsyncScript } from '@/helpers/script'
import { sentryException } from '@/helpers/sentry'

window.addEventListener('load', () => {
  setTimeout(() => {
    if (import.meta.env.VITE_FACEBOOK_PIXEL_ID) {
      try {
        loadAsyncScript({
          text: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${import.meta.env.VITE_FACEBOOK_PIXEL_ID});
              fbq('track', 'PageView');
            `,
          defer: true,
        })
        const noscript = document.createElement('noscript')
        const img = document.createElement('img')
        img.setAttribute('height', '1')
        img.setAttribute('width', '1')
        img.setAttribute('style', 'display:none;')
        img.setAttribute(
          'src',
          `https://www.facebook.com/tr?id=${
            import.meta.env.VITE_FACEBOOK_PIXEL_ID
          }&ev=PageView&noscript=1`,
        )
        noscript.appendChild(img)
        appendToHeadTag(noscript)
      } catch (e: any) {
        sentryException(e)
      }
    }
  })
})
