import address from '@/store/modules/services/address'
import events from '@/store/modules/services/events'
import ga from '@/store/modules/services/ga'
import tracker from '@/store/modules/services/tracker'
import facebook from '@/store/modules/services/facebook'

const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { address, events, ga, tracker, facebook },
}
