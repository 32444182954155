<template>
  <DefaultLayout>
    <div />
  </DefaultLayout>
</template>
<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'

export default {
  name: 'InicioPage',
  components: {
    DefaultLayout,
  },
}
</script>
<style scoped lang="scss"></style>
