import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { NoCollateralSimulationLatestResponse } from '@/data/loans/no-collateral/simulation'

export const simulateNoCollateralLoan = async (payload) => {
  return await JurosBaixosSystemsAPI.post(
    `loans/no-collateral/simulate`,
    payload,
  ).catch(createRequestException)
}

export const getLatestSimulation = async (): Promise<
  AxiosResponse<NoCollateralSimulationLatestResponse>
> => {
  return await JurosBaixosSystemsAPI.get(
    '/loans/no-collateral/simulation/latest',
  ).catch(createRequestException)
}
