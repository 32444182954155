<template>
  <LoggedLayout>
    <HomeContainer />
  </LoggedLayout>
</template>
<script>
import LoggedLayout from '@/layouts/LoggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const HomeContainer = defineAsyncComponent(
  () => import('@/containers/home/HomeContainer.vue'),
)

export default {
  name: 'Home',
  components: {
    LoggedLayout,
    HomeContainer,
  },
}
</script>
<style lang="scss"></style>
