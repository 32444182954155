<template>
  <div class="ListItem" :class="[cssClickable]" @click="onClick">
    <div class="icon-wrapper">
      <slot name="icon" />
    </div>
    <div class="content">
      <p class="title">
        <slot name="title" />
      </p>
      <p class="description">
        <slot name="description" />
      </p>
      <div class="extra-content">
        <slot name="extra-content" />
      </div>
    </div>
    <div v-if="!hideInfoArea" class="info-area">
      <slot name="info-area">
        <BaseIcon name="menu-right-mono" />
      </slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'

export default {
  name: 'ListItem',
  components: { BaseIcon },
  props: {
    hideInfoArea: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickable: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: [Number, String, Boolean, Object],
      default: null,
    },
  },
  emits: ['click'],
  computed: {
    cssClickable() {
      return { 'is-clickable': this.clickable }
    },
  },
  methods: {
    onClick() {
      if (!this.clickable) {
        return
      }
      this.$emit('click', this.value)
    },
  },
}
</script>

<style lang="scss">
.ListItem {
  background-color: $color-white-high;
  padding: 20px 16px;
  display: flex;
  align-items: center;

  &,
  * {
    box-sizing: border-box;
  }

  &.is-clickable {
    cursor: pointer;
  }

  .icon-wrapper,
  .title,
  .description,
  .extra-content {
    &:empty {
      display: none;
    }
  }

  .icon-wrapper {
    display: inline-flex;
    margin-right: 16px;
  }

  .content {
    .title {
      @include subtitle-1;
      color: $color-black-high;
      margin: 0;
    }

    .description {
      @include body-2;
      color: $color-black-medium;
      margin: 0;
    }

    .extra-content {
      margin-top: 8px;
    }
  }

  .info-area {
    margin-left: auto;
    padding-left: 16px;
    display: flex;
  }
}
</style>
