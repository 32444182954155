<template>
  <div class="Checkbox">
    <CheckboxDS
      :id="id"
      :key="$attrs.key"
      v-model="value"
      :value="value"
      :checked="checked"
      :label="label"
      :optional="cptdIsOptional"
      :error="(validation || meta.touched) && !!errorMessage"
      :error-message="errorMessage"
      :background="background"
      @input="onChange($event)"
    >
      <slot />
    </CheckboxDS>
  </div>
</template>
<script>
import CheckboxDS from '@/aasgard/components/form/Checkbox.vue'
import { toRef } from 'vue'
import { useField } from 'vee-validate'
export default {
  name: 'CheckboxV2',
  components: {
    CheckboxDS,
  },
  props: {
    id: { type: [String, Number], required: true },
    label: { type: [String, Number], default: '' },
    checked: { type: Boolean, default: () => false },
    modelValue: { type: [Boolean] },
    validation: { type: Boolean, default: false },
    validations: { type: [Object, String], default: '' },
    disabled: { type: Boolean, default: false },
    background: { type: Boolean, default: true },
  },
  setup(props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'id')
    const rules = toRef(props, 'validations')

    const { errorMessage, setTouched, handleChange, meta, value, resetField } =
      useField(name, rules, {
        syncVModel: true,
      })

    return {
      handleChange,
      setTouched,
      errorMessage,
      meta,
      value,
      resetField,
    }
  },
  data() {
    return {
      errors: [],
    }
  },
  computed: {
    cptdIsOptional() {
      return this.validations?.required === false
    },
  },
  methods: {
    onChange(event) {
      this.setTouched(true)
      this.handleChange(event)
    },
  },
}
</script>
<style lang="scss">
.Checkbox {
  margin-bottom: 24px;

  & > .Checkbox {
    margin: 0;
    width: 100%;
  }

  & > label {
    @include subtitle-1;
    color: $color-black-high;
    margin-bottom: 11px;

    &.-disabled {
      color: $color-brand-tertiary;
    }
  }
  & > .validation {
    margin-top: 8px;
    & > .error-message {
      @include body-2;
      color: $color-feedback-negative;
    }
  }
}
</style>
