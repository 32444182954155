import * as Sentry from '@sentry/browser'
export function sentryMessage(message: string, extra?: any) {
  if (import.meta.env.PROD) {
    Sentry.withScope((scope) => {
      scope.setExtras(extra)
      Sentry.captureMessage(message)
    })
  }
}

export function sentryException(err: Error, extra?: any) {
  if (import.meta.env.PROD) {
    Sentry.withScope((scope) => {
      scope.setExtras(extra)
      Sentry.captureException(err)
    })
  }
}
