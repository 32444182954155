const defaultScriptType = 'text/javascript'
export const createScriptTag = () => {
  return document.createElement('script')
}

export const createTextScript = ({ text, type, async = true }) => {
  const script = createScriptTag()
  script.type = type || defaultScriptType
  script.text = text
  script.async = async
  return script
}
export const createExternalScript = ({
  src,
  type,
  defer = false,
  async = true,
}) => {
  const script = createScriptTag()
  script.type = type || defaultScriptType
  script.src = src
  script.defer = defer
  script.async = defer ? false : async

  return script
}

export const createScript = ({
  src = '',
  text = '',
  type = '',
  async = true,
  defer = false,
}) => {
  let script
  if (src) {
    script = createExternalScript({ src, type, defer, async })
  } else if (text) {
    script = createTextScript({ text, type, async })
  }

  return script
}
export const appendToHeadTag = (tag) => {
  if (!tag) return
  document.getElementsByTagName('head')[0].appendChild(tag)
}

export const loadAsyncScript = ({
  src = '',
  text = '',
  type = '',
  async = true,
  defer = false,
}) => {
  try {
    appendToHeadTag(createScript({ src, text, type, async, defer }))
  } catch (e) {}
}
