<template>
  <ClearLayout>
    <AutoEquityProfileFormContainer
      :progress="{
        step: 1,
        steps: 4,
        type: 'stepped',
      }"
      @submit="submit"
    />
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const AutoEquityProfileFormContainer = defineAsyncComponent(
  () =>
    import('@/containers/forms/loans/auto/AutoEquityProfileFormContainer.vue'),
)
export default {
  name: 'BvVerificarDadosPage',
  components: {
    ClearLayout,
    AutoEquityProfileFormContainer,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      formId: 'GENERIC_AUTO_EQUITY',
    }
  },
  computed: {
    ...mapGetters({
      gtUser: 'user/user/gtUser',
      gtAutoColdOffers:
        'products/loans/auto-equity/cold-offer/gtAutoColdOffers',
    }),
    cptdCurrentForm() {
      if (this.gtDynamicForms && this.gtDynamicForms[this.formId]) {
        return this.gtDynamicForms[this.formId]
      }

      return {}
    },
  },
  created() {
    this.actSetupForm(this.formId)

    if (
      !this.gtAutoColdOffers.length ||
      !this.gtUser ||
      !this.cptdCurrentForm
    ) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actSetupForm: 'dynamic-forms/actSetupForm',
    }),
    submit() {
      this.$router.push({
        path: `/forms/emprestimo/auto/parceiros/simular/dados-veiculo`,
      })
    },
  },
}
</script>
<style lang="scss"></style>
