import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'

export const getOfferContract = async ({ offer_id, product }) => {
  return await JurosBaixosSystemsAPI.get(
    `/loans/${product}/offers/${offer_id}/contract`,
  ).catch(createRequestException)
}

export const getContractToken = async ({ offer_id, product }) => {
  return await JurosBaixosSystemsAPI.get(
    `/loans/${product}/offers/${offer_id}/contract/token`,
  ).catch(createRequestException)
}

export const signContract = async ({ offer_id, product, payload }) => {
  return await JurosBaixosSystemsAPI.post(
    `/loans/${product}/offers/${offer_id}/contract`,
    payload,
  ).catch(createRequestException)
}
