<template>
  <ClearLayout>
    <OfferPartnerRedirectModal
      v-bind="$attrs"
      :url="url"
      :partner="partner"
      @redirected="registerIntent"
    >
      <template #title>
        Tudo certo! Finalize a contratação da sua oferta com a
        {{ cptdPartnerName }}
      </template>

      <template #logo>
        <DebtPartnerLogo v-if="cptdPartner" :partner="cptdPartner" />
      </template>

      <template #alert-message>
        Este serviço é uma parceria com a {{ cptdPartnerName }} e o processo
        continuará em seu site.
      </template>
    </OfferPartnerRedirectModal>

    <AdManagerInterstitialSlot
      ad-unit-path="/22834235630/cp.jurosbaixos.com.br/Cp_Interstitial"
    />
  </ClearLayout>
</template>

<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
const DebtPartnerLogo = defineAsyncComponent(
  () => import('@/components/products/debtRenegotiation/DebtPartnerLogo.vue'),
)
const AdManagerInterstitialSlot = defineAsyncComponent(
  () => import('@/components/ads/AdManagerInterstitialSlot.vue'),
)
export default {
  name: 'DebtRenegotiationRedirect',
  components: {
    AdManagerInterstitialSlot,
    ClearLayout,
    OfferPartnerRedirectModal,
    DebtPartnerLogo,
  },
  data() {
    return {
      url: '',
      partner: '',
    }
  },
  computed: {
    cptdPartnerName() {
      return this.$route?.query?.name ?? ''
    },
    cptdPartner() {
      return this.partner ?? ''
    },
  },
  mounted() {
    if (
      !this.$route?.query?.url ||
      !this.$route?.query?.partner ||
      !this.$route?.query?.name
    ) {
      this.$router.push('/negociacao-dividas')
    }

    this.url = this.$route?.query?.url
    this.partner = this.$route?.query?.partner
  },
  methods: {
    ...mapActions({
      actInsertIntent: 'user/intent/actInsertIntent',
    }),
    registerIntent() {
      if (this.partner === 'GOBRAVO') {
        const { type } = this.$route?.query || {}
        const typeString = type ? `_${type.toUpperCase()}` : ''
        this.actInsertIntent({
          identifier: `PARTNER_${this.cptdPartner}${typeString}_REDIRECT`,
        })
      }
    },
  },
}
</script>
