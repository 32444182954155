<template>
  <ClearLayout>
    <GoBravoSimulationContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const GoBravoSimulationContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/debt-renegotiation/affiliates/gobravo/GoBravoSimulationContainer.vue'
    ),
)
export default {
  name: 'GoBravoSimularPage',
  components: {
    ClearLayout,
    GoBravoSimulationContainer,
  },
}
</script>

<style lang="scss"></style>
