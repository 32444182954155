<template>
  <LoginLayout>
    <LoginContainer />
  </LoginLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import LoginLayout from '@/layouts/LoginLayout.vue'
import { defineAsyncComponent } from 'vue'
const LoginContainer = defineAsyncComponent(
  () => import('@/containers/login/LoginContainer.vue'),
)
export default {
  name: 'LoginPage',
  components: {
    LoginLayout,
    LoginContainer,
  },
  computed: {
    ...mapGetters({
      feedback: 'utils/feedback/feedback',
      gtIsAppBusy: 'ui/app/gtIsAppBusy',
    }),
  },
}
</script>
<style lang="sass"></style>
