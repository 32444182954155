import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import {
  HomeEquityColdRequest,
  HomeEquityColdOfferResponse,
} from '@/data/loans/home-equity/cold-offer'

export const submitHomeEquityColdOffer = async (
  payload: HomeEquityColdRequest,
) => {
  return await JurosBaixosSystemsAPI.post(`loans/homeequity`, payload).catch(
    createRequestException,
  )
}

export const getUserHomeEquityColdOffers = async (): Promise<
  AxiosResponse<Array<HomeEquityColdOfferResponse> | Array<any>>
> => {
  return await JurosBaixosSystemsAPI.get(`loans/homeequity/cold-offers`).catch(
    createRequestException,
  )
}

export const getHomeEquityColdOfferRequest = async (
  coldOfferId: string,
): Promise<AxiosResponse<HomeEquityColdRequest>> => {
  return await JurosBaixosSystemsAPI.get(
    `/loans/homeequity/cold-offers/${coldOfferId}/request`,
  ).catch(createRequestException)
}
export const getLatestColdOfferRequest = async (): Promise<
  AxiosResponse<HomeEquityColdRequest>
> => {
  return await JurosBaixosSystemsAPI.get(
    `/loans/homeequity/cold-request/latest`,
  ).catch(createRequestException)
}
