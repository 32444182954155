<template>
  <div
    :class="[
      'app-loader',
      { '-blur': blur },
      { '-clear': clear },
      { '-purple': purple },
      { '-simple': simple },
    ]"
  >
    <Spinner />
    <p v-if="waitMessage" class="text">
      {{ message ? message : 'Aguarde um momento...' }}
    </p>
  </div>
</template>
<script>
import Spinner from '@/aasgard/components/feedback/Spinner.vue'
export default {
  name: 'AppLoader',
  components: {
    Spinner,
  },
  props: {
    blur: { type: Boolean, default: false },
    clear: { type: Boolean, default: false },
    purple: { type: Boolean, default: false },
    simple: { type: Boolean, default: false },
    waitMessage: { type: Boolean, default: false },
    message: { type: String, default: '' },
  },
  mounted() {
    this.removeOverflow()
  },
  beforeUnmount() {
    this.addOverflow()
  },
  methods: {
    addOverflow() {
      const styles = {
        overflow: '',
        position: '',
        height: '',
      }
      Object.assign(document.body.style, styles)
      Object.assign(document.documentElement.style, styles)
    },
    removeOverflow() {
      const styles = {
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
      }
      Object.assign(document.body.style, styles)
      Object.assign(document.documentElement.style, styles)
    },
  },
}
</script>
<style lang="scss">
.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  background-color: $color-white-high;
  z-index: 50;
  & > .loading > .spinner {
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }

  & > .text {
    @include body-2;
    color: $color-white-high;
  }

  & > .loading > .item {
    margin: 0;
  }

  &.-purple {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    margin-top: -64px;
    & > .text {
      color: $color-brand-primary;
    }
    & > .loading > .spinner > .sk-chase-dot {
      &::before {
        background-color: $color-brand-primary;
      }
    }
  }

  &.-blur {
    height: calc(100vh - 64px);
    max-height: 100vh;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background-color: rgba(black, 0.4);
    margin-top: -64px;
    min-height: calc(100vh + 64px);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
  }
  &.-clear {
    position: relative;
    min-height: auto;
    background-color: transparent;
    margin: 20px auto;
  }

  &.-simple {
    height: auto;
    min-height: 500px;
    margin-top: 0;
  }
}
#page-wrapper {
  padding: 0;
}
</style>
