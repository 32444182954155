<template>
  <UnloggedLayout>
    <FormContainer>
      <SuperSimBankAccountForm
        v-if="!busy"
        :data="cptdCurrentForm['bank_account'] || {}"
        @submit="submit"
      />
      <FullscreenLoading v-if="busy">
        <template #loading-message> Solicitando oferta... </template>
      </FullscreenLoading>
      <SuperSimNoResultFeedbackDialogContainer
        v-if="!busy && displayNoResultFeedbackDialog"
        :display="displayNoResultFeedbackDialog"
      />
      <OfferPartnerTimeoutFeedbackDialog
        v-if="!busy && displayOfferPartnerTimeoutFeedback"
        :display="displayOfferPartnerTimeoutFeedback"
        partner="SUPERSIM"
      />
    </FormContainer>
  </UnloggedLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { waitForAResultWithOptions } from '@/helpers/offers'
import { OfferSubStatus, Partner, OfferStatus } from '@/data/common'
import { formatDateToNumericDate } from '@/helpers/date'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { PartnerBankCodesAllowed } from '@/helpers/partners/common'
import { defineAsyncComponent } from 'vue'
const FormContainer = defineAsyncComponent(
  () => import('@/containers/forms/FormContainer.vue'),
)
const SuperSimBankAccountForm = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/no-collateral/partners/supersim/SuperSimBankAccountForm.vue'
    ),
)
const FullscreenLoading = defineAsyncComponent(
  () => import('@/components/commomComponents/loaders/FullscreenLoading.vue'),
)
const OfferPartnerTimeoutFeedbackDialog = defineAsyncComponent(
  () => import('@/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'),
)
const SuperSimNoResultFeedbackDialogContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/no-collateral/partners/supersim/SuperSimNoResultFeedbackDialogContainer.vue'
    ),
)
export default {
  name: 'DynamicFormPage',
  components: {
    SuperSimNoResultFeedbackDialogContainer,
    UnloggedLayout,
    SuperSimBankAccountForm,
    FormContainer,
    FullscreenLoading,
    OfferPartnerTimeoutFeedbackDialog,
  },
  data() {
    return {
      busy: false,
      displayOfferPartnerTimeoutFeedback: false,
      displayNoResultFeedbackDialog: false,
      formId: 'SUPERSIM',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/gtNoCollateralOfferSelected',
      gtUser: 'user/user/gtUser',
      personaData: 'user/persona/personaData',
    }),
    cptdCurrentForm() {
      return this.gtDynamicForms[this.formId] || {}
    },
  },
  created() {
    this.actSetupForm(this.formId)
    if (!this.gtNoCollateralOfferSelected || !this.gtUser) {
      this.$router.push({
        path: '/ofertas',
      })
    }
    if (
      PartnerBankCodesAllowed[Partner.SUPERSIM].includes(
        this.personaData?.finance?.account?.bank,
      )
    ) {
      this.submit(this.personaData.finance.account)
    }
  },
  methods: {
    ...mapActions({
      actSelectNoCollateralOffer:
        'products/loans/no-collateral/no-collateral/actSelectNoCollateralOffer',
      createFeedback: 'utils/feedback/createFeedback',
      actGetNoCollateralOfferById:
        'products/loans/no-collateral/no-collateral/actGetNoCollateralOfferById',
      actSetNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/actSetNoCollateralOfferSelected',
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      actSetupForm: 'dynamic-forms/actSetupForm',
    }),
    async submit(data) {
      this.actUpdateForm({
        formId: this.formId,
        key: 'bank_account',
        value: data,
      })

      await this.selectOffer(data)
    },
    async selectOffer(bank_account) {
      const { offer_id } = this.gtNoCollateralOfferSelected

      this.busy = true
      const selectResponse = await this.actSelectNoCollateralOffer({
        offer: this.gtNoCollateralOfferSelected,
        selectPayload: {
          supersim: {
            bank_account,
            user_selected_installment_date: formatDateToNumericDate(
              new Date(),
              'yyyy-mm-dd',
            ),
          },
        },
      })
      if (selectResponse?.status === 200) {
        let offer = null
        try {
          offer = await waitForAResultWithOptions(
            {
              partner: Partner.SUPERSIM,
              status: (s) =>
                [OfferStatus.ONGOING, OfferStatus.REJECTED].includes(s),
              sub_status: (ss) =>
                [
                  OfferSubStatus.WAITING_FOR_AGREEMENT_ACCEPTANCE,
                  OfferSubStatus.UNKNOWN_OFFER_SUB_STATUS,
                ].includes(ss),
            },
            async () => {
              return await this.actGetNoCollateralOfferById(offer_id)
            },
          )

          if (offer) {
            switch (offer.status) {
              case OfferStatus.ONGOING:
                this.actSetNoCollateralOfferSelected(offer)
                this.$router.push({
                  path: `/contratacao/sem-garantia/supersim/${offer_id}/redirecionamento`,
                })
                break
              case OfferStatus.REJECTED:
                this.displayNoResultFeedbackDialog = true
                break
              default:
                this.createFeedback({
                  text: 'Algo deu errado! Por favor tente novamente.',
                })
                break
            }
          } else {
            this.displayNoResultFeedbackDialog = true
          }
        } catch (e) {
          if (e?.message === 'MAX_RETRIES_REACHED') {
            this.displayOfferPartnerTimeoutFeedback = true
          } else {
            this.createFeedback({
              text: 'Algo deu errado! Por favor tente novamente.',
            })
          }
        }
      } else {
        this.createFeedback({
          text: 'Algo deu errado! Por favor tente novamente.',
        })
      }
      this.busy = false
    },
  },
}
</script>
<style lang="scss"></style>
