// statecred
import { getLatestCreditScore } from '@/api/creditscore/creditscore'
import { getCookie } from '@/helpers/cookie'
import {
  FacebookTrackEventNameEnum,
  FacebookTrackLeadParameters,
} from '@/store/modules/services/facebook'

const state = (): any => ({
  latestCreditScore: {
    data: undefined,
    busy: false,
    error: {},
    loaded: false,
  },
})

const getters = {
  gtCreditScoreLatest(state) {
    return state.latestCreditScore
  },
}

const mutations = {
  SET_CREDIT_SCORE_LATEST(state, { data, busy, error, loaded }) {
    state.latestCreditScore = {
      data: data ?? state.latestCreditScore.data,
      busy: busy ?? state.latestCreditScore.busy,
      error: error ?? state.latestCreditScore.error,
      loaded: loaded ?? state.latestCreditScore.loaded,
    }
  },
}

const actions = {
  async actCreditScoreGetLatest({ commit, dispatch, getters }) {
    if (
      getters.gtCreditScoreLatest?.loaded &&
      (getters.gtCreditScoreLatest?.data?.score > 0 ||
        !!getters.gtCreditScoreLatest.error?.INTERNAL_SERVER_ERROR ||
        !!getters.gtCreditScoreLatest.error?.SERVICE_UNAVAILABLE)
    )
      return
    try {
      commit('SET_CREDIT_SCORE_LATEST', {
        busy: true,
      })
      const response = await getLatestCreditScore()
      commit('SET_CREDIT_SCORE_LATEST', {
        data: response?.data,
        busy: false,
        loaded: true,
        error: {},
      })
      dispatch('actCreditScoreFbTrack', response?.data || {})
      return response
    } catch (e: any) {
      commit('SET_CREDIT_SCORE_LATEST', {
        error: e?.response?.jbError,
        busy: false,
        loaded: true,
      })
      return e
    }
  },
  actCreditScoreFbTrack({ dispatch }, { created, score }) {
    const userScoreLSKey = `jb.state.${getCookie('user_id')}.score`
    const latestCreditScore = localStorage.getItem(userScoreLSKey)
    if (created && score && created !== latestCreditScore) {
      localStorage.setItem(userScoreLSKey, String(created))
      dispatch(
        'services/facebook/actEmitFacebookTrackEvent',
        {
          eventName: FacebookTrackEventNameEnum.LEAD,
          parameters: {
            content_category: 'loan',
            content_name: 'Score Update',
            value: score,
            currency: 'BRL',
          } as FacebookTrackLeadParameters,
        },
        { root: true },
      )
    }
  },
}

export default { namespaced: true, state, getters, actions, mutations }
