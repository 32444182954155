<template>
  <ClearLayout>
    <IdDocumentContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const IdDocumentContainer = defineAsyncComponent(
  () => import('@/containers/newAppDocs/IdDocumentFrontContainer.vue'),
)
export default {
  name: 'IdDocumentFront',
  components: {
    ClearLayout,
    IdDocumentContainer,
  },
}
</script>
<style lang="scss"></style>
