const whatsappUrl = 'https://api.whatsapp.com/send?'
const phone = '5511930296839'

export const generateWhatsAppLink = (
  urlParams: Record<string, string>,
): string => {
  if (Object.keys(urlParams).length > 0) {
    return `${whatsappUrl}${Object.entries({ phone, ...urlParams })
      .map(([k, v]) => `&${encodeURI(k)}=${encodeURI(v)}`)
      .reduce((c, n) => c + n)}`
  }

  return whatsappUrl
}
