<template>
  <OfferFeedbackDialog
    title="Tempo excedido!"
    v-bind="$attrs"
    type="warning"
    @close="sendToRequestedOffers"
  >
    <template #content>
      A {{ cptdPartnerName }} demorou em nos responder. Clique no botão abaixo
      para ser redirecionado para a página de Ofertas, lá você poderá acompanhar
      o status da sua oferta.
      <Button
        style="margin-top: 16px"
        type="ghost"
        @click="sendToRequestedOffers"
      >
        Ir para página de ofertas
      </Button>
    </template>
  </OfferFeedbackDialog>
</template>
<script>
import { PartnersName } from '@/helpers/partners/common'
import Button from '@/aasgard/components/buttons/Button.vue'
import OfferFeedbackDialog from '@/components/commomComponents/feedback/OfferFeedbackDialog.vue'
export default {
  name: 'OfferPartnerTimeoutFeedbackDialog',
  components: {
    Button,
    OfferFeedbackDialog,
  },
  props: {
    partner: { type: String, required: true },
  },
  computed: {
    cptdPartnerName() {
      return PartnersName[this.partner.toUpperCase() ?? ''] ?? ''
    },
  },
  methods: {
    sendToRequestedOffers() {
      this.$router.push({
        path: '/ofertas',
      })
    },
  },
}
</script>
<style lang="scss"></style>
