import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import {
  JurosBaixosSystemsAPI,
  JurosBaixosSystemsPublicAPI,
} from '@/helpers/axios/jurosbaixos-systems'
import { Provider } from '@/data/oauth/oauth'
import { User } from '@/data/user/user'
import JbOAuth from '@/helpers/oauth'
import { JWTTokenResponse } from '@/data/authentication/jwt'

export const getUserInfo = async (provider: Provider, accessToken: string) => {
  return await JurosBaixosSystemsPublicAPI.get(
    `/oauth/${provider.toLowerCase()}/userinfo`,
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    },
  ).catch(createRequestException)
}

export const login = async (
  provider: Provider,
  accessToken: string,
): Promise<AxiosResponse<JWTTokenResponse>> => {
  return await JurosBaixosSystemsPublicAPI.get(
    `/oauth/${provider.toLowerCase()}/login`,
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    },
  ).catch(createRequestException)
}
export const register = async (
  provider: Provider,
  user: User,
  accessToken: string,
): Promise<AxiosResponse<JWTTokenResponse>> => {
  return await JurosBaixosSystemsPublicAPI.post(
    `/oauth/${provider.toLowerCase()}/register`,
    {
      name: user.name,
      email: user.email,
      mobile_phone: user.mobile_phone,
      cpf: user.cpf,
    },
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    },
  ).catch(createRequestException)
}

export const link = async (provider: Provider, accessToken: string) => {
  return await JurosBaixosSystemsPublicAPI.post(
    `/oauth/${provider.toLowerCase()}/link`,
    {
      jwt: JbOAuth.getAccessToken(),
    },
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    },
  ).catch(createRequestException)
}

export const getLinkedAccounts = async () => {
  return await JurosBaixosSystemsAPI.get(`/oauth/accounts`).catch(
    createRequestException,
  )
}
