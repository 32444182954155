<template>
  <ClearLayout>
    <OfferPartnerRedirectModal
      :partner="cptdPartner"
      :url="cptdOfferUrl"
      @redirected="registerIntent"
    >
      <template #title>
        {{ cptdTitleRedirect }}
      </template>
      <template #alert-message>
        {{ cptdRedirectMessage }}
      </template>
    </OfferPartnerRedirectModal>
  </ClearLayout>
</template>

<script>
import { mapActions } from 'vuex'
import { PartnersName } from '@/helpers/partners/common'
import { generateWhatsAppLink } from '@/helpers/whatsapp'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
export default {
  components: {
    ClearLayout,
    OfferPartnerRedirectModal,
  },
  computed: {
    cptdPartner() {
      const { partner } = this.$route?.params
      return partner?.toUpperCase() ?? ''
    },
    cptdOfferUrl() {
      switch (this.cptdPartner) {
        case 'HCRED':
          return generateWhatsAppLink({
            text: `Olá! Gostaria de uma simulação para antecipar o limite do meu cartão de crédito. Pode me ajudar?`,
          })
        default:
          return generateWhatsAppLink({
            text: `Olá! Gostaria de uma simulação para antecipar o limite do meu cartão de crédito. Pode me ajudar?`,
          })
      }
    },
    cptdPartnerName() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdTitleRedirect() {
      switch (this.cptdPartner) {
        case 'HCRED':
          return `Muito bem! Inicie uma conversa em nosso whastapp para continuar`
        default:
          return `Tudo certo! Finalize a contratação da sua oferta com a ${this.cptdPartnerName}`
      }
    },
    cptdRedirectMessage() {
      switch (this.cptdPartner) {
        case 'HCRED':
          return `Este serviço é uma parceria com a ${this.cptdPartnerName} e o processo continuará em nosso WhatsApp.`
        default:
          return `Este serviço é uma parceria com a ${this.cptdPartnerName} e o processo continuará em seu site.`
      }
    },
  },
  mounted() {
    this.actEmitInfobipAffiliateOfferSelected({
      affiliate: this.cptdPartner,
    })
  },
  methods: {
    ...mapActions({
      actInsertIntent: 'user/intent/actInsertIntent',
      actEmitInfobipAffiliateOfferSelected:
        'services/events/actEmitInfobipAffiliateOfferSelected',
    }),
    registerIntent() {
      this.actInsertIntent({
        identifier: `PARTNER_${this.cptdPartner}_REDIRECT`,
      })
    },
  },
}
</script>

<style lang="scss"></style>
