import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { UserIntentRequest } from '@/data/user/intent'

const insert = async (payload: UserIntentRequest) => {
  return await JurosBaixosSystemsAPI.post(`/user/intent`, payload).catch(
    createRequestException,
  )
}

export default { insert }
