import BankAccountValidatorTool from '../../digitCalculator'
import utilities from '@/lib/accountValidator/bank-validator-js/utilities'

function checkBradescoBankAccount(number, number_digit, branch) {
  const branchPadded = utilities.padStart(branch, 4)
  if (branchPadded.length > 4) return false

  const numberPadded = utilities.padStart(number, 7)
  if ((numberPadded + number_digit).length > 8) return false

  const digit_calculator = new BankAccountValidatorTool.Banks.DigitCalculator(
    numberPadded,
    [2, 7, 6, 5, 4, 3, 2],
  )

  const digit = digit_calculator.sum_numbers() % 11
  let correctVerificationDigit

  if (digit === 0) {
    correctVerificationDigit = '0'
  } else if (digit === 1) {
    correctVerificationDigit = 'P'
  } else {
    correctVerificationDigit = String(11 - digit)
  }

  if (number_digit === '0') {
    return (
      number_digit === correctVerificationDigit ||
      correctVerificationDigit === 'P'
    )
  }

  return number_digit === correctVerificationDigit
}

export default checkBradescoBankAccount
