<template>
  <SimpleLayout>
    <ContentContainerWrapper class="form-inicial">
      <h1>Dados iniciais</h1>
      <FormVee ref="observer" tag="div" class="step-form" @submit="() => {}">
        <div class="section-form">
          <div v-if="!personaGenerated" class="generate-persona-wrapper">
            <p>Deseja gerar uma persona?</p>
            <Button type="outlined" :busy="isLoading" @click="generatePersona">
              Gerar Persona
            </Button>
          </div>
          <CheckInput
            id="loan_type"
            v-model="data.type"
            class="field required"
            name="gender"
            label="Qual tipo de empréstimo você deseja simular?"
            :options="loanTypes"
            :validation="validation"
            :validations="{ required: true }"
          />
          <SliderInput
            id="amount"
            class="field required"
            label="De quanto você precisa?"
            :min="minValue"
            :max="maxValue"
            :value="data.amount"
            @input="data.amount = $event"
          />
          <SliderInput
            id="amount"
            class="field required"
            label="Preço do imóvel"
            :min="120000"
            :max="5000000"
            :value="data.collateral_value"
            @input="data.collateral_value = $event"
          />

          <SelectInput
            id="duration"
            v-model="data.duration"
            class="field required"
            name="duration"
            label="Quantidade de parcelas"
            :options="durationOptions"
            :validation="validation"
            :value="data.duration"
            @input="data.duration = $event"
          />

          <EmailInput v-model="data.email" :validation="validation" />
        </div>

        <div class="actions">
          <Button :busy="isLoading" @click="submit"> Continuar </Button>

          <p class="login">
            Já possui conta?
            <span @click="$router.push('/login')">Acessar conta</span>
          </p>
        </div>
      </FormVee>
    </ContentContainerWrapper>
  </SimpleLayout>
</template>
<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
const ContentContainerWrapper = defineAsyncComponent(
  () => import('@/components/ui/newComponents/ContentContainerWrapper.vue'),
)
const Button = defineAsyncComponent(
  () => import('@/aasgard/components/buttons/Button.vue'),
)
const EmailInput = defineAsyncComponent(
  () => import('@/components/forms/fields/input/EmailInput.vue'),
)
const SelectInput = defineAsyncComponent(
  () => import('@/components/ui/newComponents/SelectInput.vue'),
)
const CheckInput = defineAsyncComponent(
  () => import('@/components/ui/newComponents/CheckInput.vue'),
)
const SimpleLayout = defineAsyncComponent(
  () => import('@/layouts/SimpleLayout.vue'),
)
const SliderInput = defineAsyncComponent(
  () => import('@/components/ui/newComponents/SliderInput.vue'),
)
export default {
  name: 'FormInicial',
  components: {
    SliderInput,
    SimpleLayout,
    ContentContainerWrapper,
    Button,
    EmailInput,
    SelectInput,
    CheckInput,
  },
  data() {
    return {
      id: 'initial-form',
      isValid: true,
      isLoading: false,
      validation: false,
      personaGenerated: false,
      data: {
        amount: 500,
        duration: '',
        collateral_value: 120000,
        email: '',
        type: '',
      },
      durationOptions: [
        {
          name: '3 parcelas',
          label: '3 parcelas',
          value: 3,
        },
        {
          name: '6 parcelas',
          label: '6 parcelas',
          value: 6,
        },
        {
          name: '12 parcelas',
          label: '12 parcelas',
          value: 12,
        },
        {
          name: '18 parcelas',
          label: '18 parcelas',
          value: 18,
        },
        {
          name: '24 parcelas',
          label: '24 parcelas',
          value: 24,
        },
        {
          name: '36 parcelas',
          label: '36 parcelas',
          value: 36,
        },
      ],
      loanTypes: [
        {
          label: 'Sem garantia',
          value: 'no_collateral',
        },
        {
          label: 'Veículo em garantia',
          value: 'auto_equity',
        },
        {
          label: 'Imóvel em garantia',
          value: 'home-equity',
        },
      ],
    }
  },
  computed: {
    minValue() {
      if (this.data.type === 'auto_equity') {
        return 5000
      }
      return 500
    },
    maxValue() {
      if (this.data.type === 'auto_equity') {
        return 150000
      }
      return 50000
    },
  },
  mounted() {
    document.cookie = 'customerId=JB; expires=Thu, 18 Dec 2013 12:00:00 UTC'
    document.cookie = 'loanId=JB; expires=Thu, 18 Dec 2013 12:00:00 UTC'
  },
  methods: {
    ...mapActions({
      setPersona: 'user/persona/setPersona',
      actUpdateUser: 'user/user/actUpdateUser',
      actGeneratePersona: 'user/persona/actGeneratePersona',
    }),
    /* to use this locally install the chrome extension below
    https://chrome.google.com/webstore/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino/related */
    async generatePersona() {
      try {
        this.isLoading = true
        this.personaGenerated = true
        const data = await this.actGeneratePersona()

        if (!data || data.error) {
          return
        }

        const { user, persona } = data

        this.data.email = user.email
        await this.actUpdateUser(user)

        await this.setPersona({
          persona,
          save: false,
        })
      } catch (e) {
        this.personaGenerated = false
      }
      this.isLoading = false
    },
    async submit() {
      this.isLoading = true
      this.validation = true
      const result = (await this.$refs.observer?.validate()) || {}
      if (result.valid) {
        const query = {
          amount: this.data.amount,
          duration: this.data?.duration,
          collateral_value: this.data?.collateral_value,
        }

        if (this.data.type === 'auto_equity') {
          query.form = 'auto_equity'
          return this.$router.push({
            path: '/forms/emprestimo/auto/interativo',
            query,
          })
        } else if (this.data.type === 'home-equity') {
          query.form = 'home-equity'
          return this.$router.push({
            path: '/forms/emprestimo/home-equity/interativo',
            query,
          })
        } else {
          query.form = 'no-collateral'
        }

        this.$router.push({
          path: '/forms/emprestimo/sem-garantia/interativo',
          query,
        })
      }
      this.isValid = true
      this.isLoading = false
    },
  },
}
</script>
<style lang="scss">
.form-inicial {
  padding-bottom: 48px;

  @include desktop {
    display: flex;
    justify-content: space-between;
  }

  & > h1 {
    @include headline-5;
    color: $color-black-high;
    margin-bottom: 24px;
    margin-top: 32px;

    @include desktop {
      width: 50%;
      max-width: 560px;
      font-size: 32px;
      line-height: 48px;
    }
  }
  & > .step-form {
    @include desktop {
      width: 50%;
      max-width: 560px;
      margin-top: 42px;
    }
    & .validate-error {
      background: $color-feedback-negative-light;
      padding: 16px;
      width: 100vw;
      margin-left: -16px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      @include desktop {
        width: 100%;
        margin-left: 0;
      }

      & > svg {
        margin-right: 12px;

        path {
          fill: #655c5c;
        }
      }

      & > p {
        margin: 0;
        @include body-2;
        color: $color-black-high;
      }
    }

    & > .section-form {
      padding: 0;

      .generate-persona-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 0 24px 0;

        p {
          @include body-1;
          color: $color-black-high;
          margin-bottom: 11px;
        }
      }
    }
    & > .actions {
      flex: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 32px;

      @include media(md) {
        justify-content: flex-end;
      }

      & > .login {
        margin-top: 24px;
        color: $color-black-medium;
        @include caption;
        text-align: center;

        & > span {
          color: $color-brand-primary;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.select-icon {
  top: 9px;
  height: 40px;
  width: 46px;

  & > svg {
    height: 24px;
    width: 24px;
  }
}
</style>
