import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'

const getUser = async () => {
  return await JurosBaixosSystemsAPI.get(`/user`).catch(createRequestException)
}

const updateUser = async (payload) => {
  return await JurosBaixosSystemsAPI.put(`/user`, payload).catch(
    createRequestException,
  )
}

const patchUser = async (payload) => {
  return await JurosBaixosSystemsAPI.patch(`/user`, payload).catch(
    createRequestException,
  )
}

const deleteUser = async () => {
  return await JurosBaixosSystemsAPI.delete(`/user`).catch(
    createRequestException,
  )
}

export default { getUser, updateUser, patchUser, deleteUser }
