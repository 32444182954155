import { getNoCollateralOffersStatistics } from '@/api/statistics/statistics'
import { OfferStatistics } from '@/data/offerstatistics/offerstatistics'

const state = (): any => ({
  noCollateralOfferStatistics: {},
})

const getters = {
  gtNocollateralOffersStatistics(state): OfferStatistics {
    return state.noCollateralOfferStatistics
  },
}

const mutations = {
  SET_NO_COLLATERAL_STATISTICS(state, payload): void {
    state.noCollateralOfferStatistics = payload
  },
}

const actions = {
  async actGetNoCollateralOfferStatistics({ commit, state }) {
    try {
      if (import.meta.env.VITE_FEATURE_STATISTICS_NO_COLLATERAL !== 'true')
        return
      if (Object.keys(state.noCollateralOfferStatistics || {}).length) return

      const response = await getNoCollateralOffersStatistics()

      if (response?.status === 200 && response?.data) {
        commit('SET_NO_COLLATERAL_STATISTICS', response?.data)
      } else {
        commit('SET_NO_COLLATERAL_STATISTICS', {})
      }
    } catch (e: any) {}
  },
}

export default { namespaced: true, state, getters, actions, mutations }
