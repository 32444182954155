import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { createRequestException } from '@/helpers/requestException'
import { HomeEquityOfferResponse } from '@/data/loans/home-equity/offer'

export const selectHomeEquityOffer = async ({
  payload,
  offer,
}: {
  payload: any
  offer: HomeEquityOfferResponse
}) => {
  const { offer_id } = offer
  return await JurosBaixosSystemsAPI.post(
    `loans/homeequity/offers/${offer_id}/select`,
    payload,
  ).catch(createRequestException)
}
