<template>
  <div
    :class="['Radio', cssRadio]"
    tabindex="0"
    @click="checked && onChange($event)"
    @keyup.enter="onChange(true)"
  >
    <input
      :id="id"
      :value="value"
      type="radio"
      tabindex="-1"
      :checked="checked"
      :disabled="disabled"
      @input="onChange($event)"
    />
    <label :for="id">
      <span class="check" />
      <div class="content">
        <div class="data">
          <p class="title">{{ label }}</p>
          <p v-if="description" class="description">
            {{ description }}
          </p>
        </div>
        <Badge v-if="badgeInfo" type="info">{{ badgeInfo }}</Badge>
      </div>
    </label>
  </div>
</template>

<script>
import Badge from '@/aasgard/components/feedback/Badge.vue'
export default {
  name: 'Radio',
  components: {
    Badge,
  },
  props: {
    id: {
      type: [String, Number],
      default: 'radio',
    },
    label: {
      type: [String, Number],
      default: '',
    },
    description: {
      type: [String, Number],
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Boolean, Number],
      default: '',
    },
    valueType: {
      type: String,
      default: 'string',
    },
    badgeInfo: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      valueState: false,
    }
  },
  computed: {
    cptdId() {
      return `Radio_${this.id}`
    },
    cssRadio() {
      return {
        disabled: this.disabled,
        radius: this.radius,
        checked: this.checked,
        'no-label': !this.label,
      }
    },
  },
  created() {
    this.setValue(this.value)
  },
  methods: {
    setValue(value) {
      switch (this.valueType) {
        case 'number':
          this.valueState = Number(value)
          break
        case 'boolean':
          this.valueState = value === 'true'
          break
        default:
          this.valueState = value
      }
    },
    onChange(event) {
      if (event?.target?.value) {
        this.setValue(event.target.value)
      }
      this.$emit('input', this.valueState)
      this.$emit('update:value', this.valueState)
    },
  },
}
</script>

<style lang="scss">
.Radio {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  width: 100%;

  &.disabled {
    opacity: 0.5;
  }

  &.no-label {
    width: 20px;
    height: 20px;
    justify-content: center;

    input[type='radio'] + label {
      width: 20px;
      height: 20px;
      padding: 0;

      .check {
        margin: 0;
      }
    }
  }

  &.radius {
    &.checked {
      input[type='radio'] + label {
        background-color: $color-neutral-light;
        border: 1px solid $color-brand-primary;
      }
    }
    input[type='radio'] + label {
      padding: 16px;
      border: 1px solid $color-neutral-dark;
      border-radius: 8px;

      &:hover {
        background-color: $color-neutral-light;
      }
    }
  }

  input[type='radio'] {
    position: fixed;
    top: -1.5em;
    left: -1.5em;
  }
  input[type='radio'] + label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 16px 0 16px 6px;
    box-sizing: border-box;
    transition: all 0.2s linear;

    .check {
      align-items: center;
      justify-content: center;
      border: 2px solid $color-neutral-dark;
      color: $color-brand-primary;
      display: inline-flex;
      margin-right: 20px;
      border-radius: 50%;
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      position: relative;
      box-sizing: border-box;
      transition: all 0.2s linear;

      &:before {
        content: '';
        width: 0;
        height: 0;
        background-color: transparent;
        box-sizing: border-box;
        border-radius: 50%;
        transition: all 0.2s linear;
      }
    }

    & > .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      & > .data {
        margin-right: 20px;

        & > .title {
          @include body-1;
          color: $color-black-high;
          margin: 0;
        }

        & > .description {
          @include caption;
          color: $color-black-medium;
          margin: 4px 0 0;
          padding: 0 0 4px;
        }
      }
    }
  }
  input[type='radio']:checked + label {
    color: $color-brand-primary;

    .check {
      border: 2px solid $color-brand-primary;

      &:before {
        width: 10px;
        height: 10px;
        background-color: $color-brand-primary;
      }
    }
  }
}
</style>
