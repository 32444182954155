<template>
  <LoggedLayout>
    <AccountContainer />
  </LoggedLayout>
</template>
<script>
import LoggedLayout from '@/layouts/LoggedLayout.vue'
const AccountContainer = defineAsyncComponent(
  () => import('@/containers/logged/AccountContainer.vue'),
)
import { defineAsyncComponent } from 'vue'
export default {
  name: 'Account',
  components: {
    LoggedLayout,
    AccountContainer,
  },
}
</script>
<style lang="scss"></style>
