<template><div /></template>
<script>
import { MEDIA_QUERIES_MAP } from '@/mixins/media'

export default {
  name: 'MediaQuerieUi',
  data() {
    return {
      mediaQueries: {
        extraSmallMediaQuery: window.matchMedia(
          `(min-width: ${MEDIA_QUERIES_MAP.XS.minWidth}px) and (max-width: ${MEDIA_QUERIES_MAP.XS.maxWidth}px)`,
        ),
        smallMediaQuery: window.matchMedia(
          `(min-width: ${MEDIA_QUERIES_MAP.SM.minWidth}px) and (max-width: ${MEDIA_QUERIES_MAP.SM.maxWidth}px)`,
        ),
        mediumMediaQuery: window.matchMedia(
          `(min-width: ${MEDIA_QUERIES_MAP.MD.minWidth}px) and (max-width: ${MEDIA_QUERIES_MAP.MD.maxWidth}px)`,
        ),
        largeMediaQuery: window.matchMedia(
          `(min-width: ${MEDIA_QUERIES_MAP.LG.minWidth}px) and (max-width: ${MEDIA_QUERIES_MAP.LG.maxWidth}px)`,
        ),
        extraLargeMediaQuery: window.matchMedia(
          `(min-width: ${MEDIA_QUERIES_MAP.XL.minWidth}px)`,
        ),
      },
    }
  },
  created() {
    this.setListeners()
  },
  beforeUnmount() {
    this.removeListeners()
  },
  methods: {
    handleMediaQueryChange(mediaQueryListEvent) {
      const mediaQueryChangedKey = Object.keys(this.mediaQueries).find(
        (key) => this.mediaQueries[key].media === mediaQueryListEvent.media,
      )

      if (mediaQueryChangedKey) {
        this.gEventBus.emit('JB_UI:media-query-changed', {
          key: mediaQueryChangedKey,
          value: this.mediaQueries[mediaQueryChangedKey].matches,
        })
      }
    },
    setListeners() {
      Object.keys(this.mediaQueries).forEach((key) => {
        if (this.mediaQueries[key]?.addEventListener) {
          this.mediaQueries[key].addEventListener(
            'change',
            this.handleMediaQueryChange,
          )
        } else {
          this.mediaQueries[key].addListener(this.handleMediaQueryChange)
        }
      })
    },
    removeListeners() {
      Object.keys(this.mediaQueries).forEach((key) => {
        if (this.mediaQueries[key]?.addEventListener) {
          this.mediaQueries[key].removeEventListener(
            'change',
            this.handleMediaQueryChange,
          )
        } else {
          this.mediaQueries[key].removeListener(this.handleMediaQueryChange)
        }
      })
    },
  },
}
</script>
