function getCookieAsObject() {
  const sessionId = {}
  window.document.cookie
    .split(';')
    .map((cookieStr) => cookieStr.trim())
    .map((cookieStr) => {
      const separatorIndex = cookieStr.indexOf('=')
      sessionId[cookieStr.slice(0, separatorIndex)] = cookieStr.slice(
        separatorIndex + 1,
        cookieStr.length,
      )
    })
  return sessionId
}

const getCookie = (key) => {
  return getCookieAsObject()[key] || ''
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=0'
}

export { getCookie, setCookie, eraseCookie }
