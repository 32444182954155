<template>
  <LoggedLayout>
    <OffersContainer />
  </LoggedLayout>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import LoggedLayout from '@/layouts/LoggedLayout.vue'
const OffersContainer = defineAsyncComponent(
  () => import('@/containers/loans/OffersContainer.vue'),
)
export default {
  name: 'Offers',
  components: {
    LoggedLayout,
    OffersContainer,
  },
}
</script>
<style lang="scss"></style>
