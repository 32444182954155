import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsPublicAPI } from '@/helpers/axios/jurosbaixos-systems'

export const retrieveOfferDocsEntriesUnauth = async ({
  offer_id,
  user_id,
  product,
  payload,
}) => {
  return await JurosBaixosSystemsPublicAPI.post(
    `/user/${user_id}/loans/${product}/offers/${offer_id}/docs`,
    payload,
  ).catch(createRequestException)
}

export const refreshOfferDocsLinksUnauth = async ({ offer_id, user_id }) => {
  // TODO: this url is out our pattern... we need Jasper told me the idea is
  // to change it on the future
  return await JurosBaixosSystemsPublicAPI.post(
    `/user/${user_id}/loans/no-collateral/offers/${offer_id}/docs/refresh-links`,
  ).catch(createRequestException)
}

export const getOfferDocumentUnauth = async ({
  offer_id,
  user_id,
  product,
  doc_id,
}) => {
  return await JurosBaixosSystemsPublicAPI.get(
    `/user/${user_id}/loans/${product}/offers/${offer_id}/docs/${doc_id}`,
  ).catch(createRequestException)
}

export const updateOfferDocumentUnauth = async ({
  doc_id,
  offer_id,
  user_id,
  payload,
  product,
}) => {
  return await JurosBaixosSystemsPublicAPI.patch(
    `/user/${user_id}/loans/${product}/offers/${offer_id}/docs/${doc_id}`,
    payload,
  ).catch(createRequestException)
}

export const deleteOfferDocumentUnauth = async ({
  doc_id,
  user_id,
  offer_id,
  product,
}) => {
  return await JurosBaixosSystemsPublicAPI.delete(
    `/user/${user_id}/loans/${product}/offers/${offer_id}/docs/${doc_id}`,
  ).catch(createRequestException)
}
