<template>
  <transition name="feedback">
    <div v-if="data.text" class="feedback-component">
      <BaseIcon
        v-if="cptdIconName"
        class="feedback-icon"
        :name="cptdIconName"
      />
      <section class="content">
        <p class="text">{{ data.text }}</p>
      </section>
    </div>
  </transition>
</template>
<script>
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
export default {
  name: 'Feedback',
  components: {
    BaseIcon,
  },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      iconByTypeDict: {
        info: 'info-mono',
        negative: 'error-mono',
        positive: 'positive-artwork',
        warning: 'warning-mono',
      },
    }
  },
  computed: {
    cptdIconName() {
      if (this.data?.type) {
        return this.iconByTypeDict[this.data?.type ?? ''] || ''
      }

      return ''
    },
  },
}
</script>
<style lang="scss">
.feedback-component {
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  position: fixed;
  bottom: 16px;
  left: 16px;
  background-color: $color-brand-tertiary-dark;
  padding: 12px;
  z-index: 10032;
  border-radius: 8px;
  transform: translateY(0);
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    opacity 0.3s ease;

  @include desktop {
    width: 450px;
    bottom: 32px;
    left: 32px;
  }

  & > .feedback-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    *[stroke] {
      stroke: #fff;
      stroke-opacity: 1;
    }

    *[fill] {
      fill: #fff;
      fill-opacity: 1;
    }
  }

  & > .content {
    margin-right: auto;

    & > .text {
      color: white;
      @include body-2;
      margin: 0;
    }
  }
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
