<template>
  <div class="app">
    <!-- PAGE CONTENT -->
    <main class="main page">
      <header class="login-header">
        <JBLogo />
      </header>
      <Feedback :data="feedback" />
      <transition
        name="custom-classes"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
      >
        <AppLoader v-if="gtIsAppBusy" key="loader" simple />

        <!-- CONTENT -->
        <slot v-else key="content" />
      </transition>
      <!-- LOADER -->
    </main>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AppLoader from '@/components/appPartials/AppLoader.vue'
import JBLogo from '@/components/appPartials/AppLogo.vue'
import Feedback from '@/components/ui/Feedback.vue'
export default {
  name: 'LoginPageLayout',
  components: {
    AppLoader,
    JBLogo,
    Feedback,
  },
  data() {
    return {
      busy: true,
      currentPage: undefined,
    }
  },
  computed: {
    ...mapGetters({
      feedback: 'utils/feedback/feedback',
      gtIsAppBusy: 'ui/app/gtIsAppBusy',
    }),
  },
  mounted() {
    if (this.$route?.query?.expired) {
      this.createFeedback({
        text: 'Sessão expirada! Por favor faça o login para continuar.',
      })
    }
  },
  methods: {
    ...mapActions({
      createFeedback: 'utils/feedback/createFeedback',
    }),
  },
}
</script>
<style lang="scss">
.login-header {
  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;
  background: $color-white-high;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}
</style>
