import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { AutoOfferResponse } from '@/data/loans/auto/offer'
import { OfferStatus } from '@/data/common'

export const fetchAutoOfferById = async (
  offerId: string,
): Promise<AxiosResponse<AutoOfferResponse>> => {
  return await JurosBaixosSystemsAPI.get(`loans/auto/offers/${offerId}`).catch(
    createRequestException,
  )
}

// Payload must be an object with queryParams
// EX: { status: 'PROPOSED' }
export const fetchAutoOffers = async (payload: {
  status: OfferStatus | Array<OfferStatus>
}): Promise<AxiosResponse<Array<AutoOfferResponse> | Array<any>>> => {
  return await JurosBaixosSystemsAPI.get(`loans/auto/offers`, {
    params: Object.keys(payload).length > 0 ? payload : {},
  }).catch(createRequestException)
}
