<template>
  <TextInput
    :id="$attrs.id || 'bank_branch'"
    v-bind="$attrs"
    :class="$attrs.class || 'field required'"
    :name="$attrs.name || 'bank_branch'"
    :label="$attrs.label || 'Agência'"
    :only-numbers="true"
    maxlength="4"
    :placeholder="$attrs.placeholder || '0000'"
    validations="required|min:2|rgx:bankaccount.branch"
    @input="$emit('input', $event)"
  />
</template>
<script>
import TextInput from '@/components/ui/newComponents/TextInput.vue'
export default {
  name: 'BankAccountBranchInput',
  components: {
    TextInput,
  },
  inheritAttrs: false,
  emits: ['input'],
}
</script>
<style lang="scss"></style>
