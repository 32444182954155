import { parseJwt } from '@/helpers/globalFunctions'
import { setCookie } from '@/helpers/cookie'
import { isDateGreaterThan } from '@/helpers/date'
import JwtAPI from '@/api/oauth/jwt'
import { HttpStatusCode } from '@/helpers/jb-error'
import { JWTTokenResponse } from '@/data/authentication/jwt'

const storageKeys = {
  ACCESS_TOKEN: 'jb.state.oauth.access-token',
  ACCESS_TOKEN_PROVIDER: 'jb.state.oauth.access-token-provider',
  ACCESS_TOKEN_EXP: 'jb.state.oauth.access-token-expires-in',
  REFRESH_TOKEN: 'jb.state.oauth.refresh-token',
}

const logout = (redirectTo = location.origin + '/login?expired=true'): void => {
  localStorage.removeItem(storageKeys.ACCESS_TOKEN)
  localStorage.removeItem(storageKeys.ACCESS_TOKEN_EXP)
  localStorage.removeItem(storageKeys.ACCESS_TOKEN_PROVIDER)
  localStorage.removeItem(storageKeys.REFRESH_TOKEN)

  if (redirectTo) {
    window.location.href = redirectTo
  }
}

const setAccessToken = (accessToken: string, provider: string): void => {
  const accessTokenDecoded = parseJwt(accessToken)
  setCookie(
    'user_id',
    accessTokenDecoded?.id,
    accessTokenDecoded.exp / (3600 * 24),
  )
  localStorage.setItem(storageKeys.ACCESS_TOKEN, accessToken)
  localStorage.setItem(
    storageKeys.ACCESS_TOKEN_EXP,
    new Date(accessTokenDecoded.exp * 1000).toUTCString(),
  )
  localStorage.setItem(storageKeys.ACCESS_TOKEN_PROVIDER, provider)
}

const getAccessToken = (): string =>
  localStorage.getItem(storageKeys.ACCESS_TOKEN) ?? ''
const getAccessTokenProvider = (): string =>
  localStorage.getItem(storageKeys.ACCESS_TOKEN_PROVIDER) ?? ''

const getDecodedAccessToken = (): any => parseJwt(getAccessToken())

const getRefreshToken = (): string =>
  localStorage.getItem(storageKeys.REFRESH_TOKEN) ?? ''

const setRefreshToken = (refreshToken): void =>
  localStorage.setItem(storageKeys.REFRESH_TOKEN, refreshToken)

const getAccessTokenExp = (): string =>
  localStorage.getItem(storageKeys.ACCESS_TOKEN_EXP) ?? ''

const isAccessTokenValid = (): boolean => {
  const jbOAuthAccessToken = getAccessToken()
  const jbOAuthAccessTokenExpiresIn = getAccessTokenExp()

  try {
    return (
      !!jbOAuthAccessToken &&
      !!jbOAuthAccessTokenExpiresIn &&
      isDateGreaterThan(new Date(jbOAuthAccessTokenExpiresIn), new Date())
    )
  } catch (err) {
    return false
  }
}

const isAccessTokenCloseToExpire = (): boolean => {
  const expirationDate = new Date(getAccessTokenExp())
  return isDateGreaterThan(
    new Date(),
    new Date(expirationDate.getTime() - 60 * 5 * 1000),
  )
}

const handleJwtResponse = (jwt: JWTTokenResponse, provider: string) => {
  setAccessToken(jwt.accessToken, provider || getAccessTokenProvider())
  setRefreshToken(jwt.refreshToken)
}
const jwtRefresh = async () => {
  const refreshToken = getRefreshToken()
  if (!refreshToken || (isAccessTokenValid() && !isAccessTokenCloseToExpire()))
    return
  try {
    const response = await JwtAPI.refresh({
      refresh_token: refreshToken,
    })
    if (response.status === HttpStatusCode.OK) {
      handleJwtResponse(response?.data, getAccessTokenProvider())
    }
    return response
  } catch (e: any) {
    return e?.response
  }
}

export default {
  getAccessToken,
  getAccessTokenExp,
  getAccessTokenProvider,
  getDecodedAccessToken,
  setAccessToken,
  isAccessTokenValid,
  logout,
  storageKeys,
  jwtRefresh,
  getRefreshToken,
  setRefreshToken,
  isAccessTokenCloseToExpire,
  handleJwtResponse,
}
