<template>
  <UnloggedLayout>
    <ContainerWrapper class="OfferAssistantForm" size="sm">
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="displayProgressWillBeLost = false"
        @exit="onExit"
      />
      <div class="AutoInteractiveFormPage">
        <OfferAssistantPresentationDialog
          v-if="!started"
          :display="!started"
          @continue="start"
          @close="onExit"
        />
        <OfferAssistantInteractiveFormContainer
          v-else
          @submit="
            $router.push({
              path: '/forms/assistente-ofertas/resultado',
              query: { from: referrer },
            })
          "
        />
      </div>
    </ContainerWrapper>
  </UnloggedLayout>
</template>
<script>
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const OfferAssistantPresentationDialog = defineAsyncComponent(
  () =>
    import(
      '@/components/forms/offer-assistant/OfferAssistantPresentationDialog.vue'
    ),
)
const OfferAssistantInteractiveFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/offer-assistant/OfferAssistantInteractiveFormContainer.vue'
    ),
)
export default {
  name: 'OnboardingInteractiveFormPage',
  components: {
    OfferAssistantInteractiveFormContainer,
    UnloggedLayout,
    ContainerWrapper,
    OfferAssistantPresentationDialog,
    ProgressWillBeLostModal,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      started: false,
      referrer:
        document.referrer ||
        'https://jurosbaixos.com.br/simulador-de-emprestimo',
    }
  },
  created() {
    this.gEventBus.on('layout-unlogged:close-clicked', () => {
      this.displayProgressWillBeLost = true
    })

    this.gEventBus.on('layout-unlogged:back-clicked', () => {
      if (!this.started) {
        this.$router.back()
      }
    })

    this.started = this.$route.query?.started || false
  },
  methods: {
    start() {
      this.started = true
      this.gEventBus.emit('layout-unlogged:display-progress-bar')
    },
    onExit() {
      window.location.href = this.referrer
    },
  },
}
</script>
<style lang="scss"></style>
