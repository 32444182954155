<template>
  <UnloggedLayout>
    <FormContainer redirect-to-path="/ofertas">
      <OfferDetails
        v-if="!busy && gtAutoOfferSelected"
        :offer="gtAutoOfferSelected"
        :contract-html="cptdContract"
        @submit="signContract"
      />
      <UnexpectedPartnerOfferErrorFeedbackDialog
        v-if="displayUnexpectedPartnerOfferErrorFeedbackDialog"
        :display="displayUnexpectedPartnerOfferErrorFeedbackDialog"
        :offer-id="cptdOfferId"
        :partner="cptdPartner"
        :step="cptdCurrentStep"
      />
      <OfferPartnerTimeoutFeedbackDialog
        v-if="displayOfferPartnerTimeoutFeedbackDialog"
        :display="displayOfferPartnerTimeoutFeedbackDialog"
        :partner="cptdPartner"
      />
    </FormContainer>
  </UnloggedLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { OfferStatus, OfferSubStatus } from '@/data/common'
import { UnexpectedPartnerOfferStepEnum } from '@/helpers/offer-types'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const UnexpectedPartnerOfferErrorFeedbackDialog = defineAsyncComponent(
  () =>
    import('@/components/loans/UnexpectedPartnerOfferErrorFeedbackDialog.vue'),
)
const OfferPartnerTimeoutFeedbackDialog = defineAsyncComponent(
  () => import('@/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'),
)
const FormContainer = defineAsyncComponent(
  () => import('@/containers/forms/FormContainer.vue'),
)
const OfferDetails = defineAsyncComponent(
  () => import('@/components/commomComponents/OfferDetails.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    UnloggedLayout,
    UnexpectedPartnerOfferErrorFeedbackDialog,
    OfferPartnerTimeoutFeedbackDialog,
    FormContainer,
    OfferDetails,
  },
  data() {
    return {
      busy: false,
      formId: undefined,
      displayUnexpectedPartnerOfferErrorFeedbackDialog: false,
      displayOfferPartnerTimeoutFeedbackDialog: false,
      waitForAgreementAcceptedOfferTimeout: {
        id: undefined,
        attempts: 1,
        maxRetries: 3,
      },
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      gtAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/gtAutoOfferSelected',
      gtAutoOfferSelectedContract:
        'products/loans/auto-equity/auto-equity/gtAutoOfferSelectedContract',
    }),
    cptdCurrentStep() {
      return UnexpectedPartnerOfferStepEnum.SIGN_CONTRACT
    },
    cptdPartner() {
      return this.$route?.params?.partner
    },
    cptdOfferId() {
      return this.$route?.params?.offer_id
    },
    cptdContract() {
      return this.gtAutoOfferSelectedContract ?? ''
    },
  },
  async created() {
    const { offer_id, partner } = this.$route?.params || {}
    this.formId = partner.toUpperCase()
    if (!this.gtAutoOfferSelected) {
      const response = await this.actGetAutoOfferById(offer_id)
      const { data, status } = response

      if (status === 200 && data) {
        this.actSetAutoOfferSelected(data)
      } else {
        this.createFeedback({ text: 'Oferta não encontrada' })
        this.scheduleRedirectTo('/ofertas')
      }
    }
    this.actGetAutoOfferContract(offer_id)
  },
  methods: {
    ...mapActions({
      createFeedback: 'utils/feedback/createFeedback',
      actGetAutoOfferById:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferById',
      actSetAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/actSetAutoOfferSelected',
      actGetAutoOfferContract:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferContract',
      actSignAutoOfferContract:
        'products/loans/auto-equity/auto-equity/actSignAutoOfferContract',
    }),
    async signContract() {
      const { offer_id, partner } = this.$route?.params
      const response = await this.actSignAutoOfferContract({
        offer: {
          offer_id,
          partner: partner.toUpperCase(),
        },
        payload: {},
      })

      if (response?.status === 200) {
        this.scheduleWaitForAgreementAcceptedOffer()
      }
    },
    scheduleRedirectTo(path) {
      setTimeout(() => {
        this.$router.push({
          path,
        })
      }, 6000)
    },
    scheduleWaitForAgreementAcceptedOffer() {
      const { offer_id } = this.$route?.params
      this.waitForAgreementAcceptedOfferTimeout.id = setInterval(async () => {
        if (
          this.waitForAgreementAcceptedOfferTimeout.attempts >
          this.waitForAgreementAcceptedOfferTimeout.maxRetries
        ) {
          this.waitForAgreementAcceptedOfferTimeout.attempts = 1
          this.displayOfferPartnerTimeoutFeedbackDialog = true
          this.busy = false
          clearTimeout(this.waitForAgreementAcceptedOfferTimeout.id)
          return
        }
        try {
          const response = await this.actGetAutoOfferById(offer_id)
          if (response?.data && response?.status === 200) {
            if (
              response?.data?.sub_status ===
                OfferSubStatus.AGREEMENT_ACCEPTED ||
              response?.data.status === OfferStatus.GRANTED
            ) {
              this.$router.push({
                path: `/oferta/aceita/emprestimo_sim/${offer_id}`,
              })
              clearTimeout(this.waitForAgreementAcceptedOfferTimeout.id)
              return
            }

            if (
              response?.data?.sub_status ===
                OfferSubStatus.INFORMATION_INCORRECT ||
              response?.data?.sub_status ===
                OfferSubStatus.PROBLEM_WITH_DOCUMENT
            ) {
              this.busy = false
              this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
              clearTimeout(this.waitForAgreementAcceptedOfferTimeout.id)
              return
            }
          }
        } catch (e) {}

        this.waitForAgreementAcceptedOfferTimeout.attempts += 1
      }, 10000)
    },
  },
}
</script>
<style lang="scss">
.offer-details-component {
  margin: 0 -16px;
}
</style>
