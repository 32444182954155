<template>
  <div
    class="select-input"
    :class="{ '-disabled': disabled || !options.length }"
  >
    <label
      :class="{
        '-disabled': disabled || !options.length,
        '-optional': cptdIsOptional,
      }"
    >
      {{ label }}
      <span v-if="!presentation && cptdIsOptional" class="optional"
        >Opcional</span
      >
      <span v-if="!presentation && !cptdIsOptional" class="required">*</span>
    </label>

    <Multiselect
      ref="selectField"
      v-model="value"
      :value="value"
      class="field"
      :class="{
        '-disabled': disabled || !options.length,
        '-invalid': (validation || meta.touched) && !value && !!errorMessage,
        '-valid': (validation || meta.touched) && !!value,
      }"
      :options="options"
      select-label=""
      deselect-label=""
      :searchable="searchable"
      :allow-absent="value !== ''"
      :disabled="disabled || !options.length"
      selected-label=""
      :caret="false"
      :can-clear="false"
      track-by="name"
      no-options="Lista vazia"
      :placeholder="placeholder || 'Selecione uma opção'"
      no-results-text="Nenhum resultado foi encontrado."
      no-options-text="Lista vazia."
      :search-filter="searchFilter"
      @change="onChange"
    />
    <span
      class="select-icon"
      :class="{
        '-invalid': !!errorMessage && !value,
        '-valid': !!value,
      }"
    >
      <BaseIcon name="menu-down-mono" />
    </span>

    <span
      v-if="(validation || meta.touched) && !!errorMessage && !value"
      class="validation-icon"
    >
      <OutlineIcon name="outline-error" />
    </span>

    <span v-else-if="value" class="validation-icon">
      <OutlineSuccessIcon name="outline-error" />
    </span>

    <div class="complement">
      <div v-if="(validation || meta.touched) && !value" class="validation">
        <span class="error-message">
          {{ errorMessage }}
        </span>
      </div>

      <div v-if="helperMessage" class="helper">
        <span class="message">
          {{ helperMessage }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import { normalizeString } from '@/helpers/globalFunctions'
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
import OutlineIcon from '@/assets/images/icons/outline-error.svg?component'
import OutlineSuccessIcon from '@/assets/images/icons/outline-success.svg?component'
import { toRef } from 'vue'
import { useField } from 'vee-validate'

export default {
  name: 'SelectInput',
  components: {
    BaseIcon,
    OutlineIcon,
    OutlineSuccessIcon,
    Multiselect,
  },
  props: {
    modelValue: { type: [String, Number, Boolean], default: undefined },
    label: { type: String, default: '' },
    displayValueKey: { type: String, default: 'name' },
    id: { type: String, required: true },
    presentation: { type: Boolean, default: false },
    validations: { type: [Object, String], default: '' },
    validation: { type: Boolean, default: false },
    helperMessage: { type: String, default: undefined },
    placeholder: { type: String, default: 'Selecione' },
    disabled: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    options: { type: Array, required: true },
  },
  emits: ['update:modelValue', 'input'],
  setup(props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'id')
    const rules = toRef(props, 'validations')

    const { errorMessage, setTouched, handleChange, meta, value, resetField } =
      useField(name, rules, {
        syncVModel: true,
      })

    return {
      handleChange,
      setTouched,
      errorMessage,
      meta,
      value,
      resetField,
    }
  },
  computed: {
    cptdIsOptional() {
      return !this.validations || this.validations?.required === false
    },
  },
  mounted() {
    if (this.value) {
      this.setTouched(true)
    }
  },
  methods: {
    searchFilter({ name }, query) {
      if (!query) return true
      return normalizeString(name)
        .toLocaleLowerCase()
        .includes(normalizeString(query).toLocaleLowerCase())
    },
    onChange(value) {
      this.setTouched(true)
      this.handleChange(value)
      this.$emit('input', value)
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css" />
<style lang="scss">
.select-input {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  padding-bottom: 8px;

  & > label {
    @include subtitle-1;
    color: $color-black-high;
    margin-bottom: 11px;

    &.-optional {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .optional {
        @include body-2;
        color: $color-black-medium;
      }
    }

    &.-disabled {
      color: $color-brand-tertiary;
    }

    & > .required {
      color: $color-feedback-negative;
    }
  }

  .multiselect {
    --ms-font-size: 14px;
    --ms-line-height: 24px;
    --ms-bg: #{$color-white-high};
    --ms-bg-disabled: #{$color-neutral-light};
    --ms-border-color: #{$color-neutral-dark};
    --ms-border-width: 1.5px;
    --ms-border-color-active: #{$color-neutral-dark};
    --ms-border-width-active: 1.5px;
    --ms-radius: 4px;
    --ms-py: 8px;
    --ms-px: 14px;
    --ms-ring-width: 0px;
    --ms-ring-color: #{$color-brand-primary};
    --ms-placeholder-color: #{$color-brand-tertiary};
    --ms-max-height: 160px;

    --ms-spinner-color: #{$color-brand-primary};
    --ms-caret-color: #{$color-black-high};
    --ms-clear-color: #{$color-black-high};
    --ms-clear-color-hover: #{$color-black-high};

    --ms-tag-font-size: 12px;
    --ms-tag-line-height: 16px;
    --ms-tag-font-weight: 600;
    --ms-tag-bg: #{$color-brand-primary};
    --ms-tag-bg-disabled: #{$color-neutral-light};
    --ms-tag-color: #{$color-brand-tertiary};
    --ms-tag-color-disabled: #{$color-black-low};
    --ms-tag-radius: 4px;
    --ms-tag-py: 2px;
    --ms-tag-px: 8px;
    --ms-tag-my: 4px;
    --ms-tag-mx: 4px;

    --ms-tag-remove-radius: 4px;
    --ms-tag-remove-py: 4px;
    --ms-tag-remove-px: 4px;
    --ms-tag-remove-my: 0rem;
    --ms-tag-remove-mx: 2px;

    --ms-dropdown-bg: #{$color-white-high};
    --ms-dropdown-border-color: #{$color-neutral-dark};
    --ms-dropdown-border-width: 1.5px;
    --ms-dropdown-radius: 4px;

    --ms-group-label-py: 4px;
    --ms-group-label-px: 12px;
    --ms-group-label-line-height: 24px;
    --ms-group-label-bg: #{$color-white-high};
    --ms-group-label-color: #{$color-brand-tertiary};
    --ms-group-label-bg-pointed: #{$color-neutral};
    --ms-group-label-color-pointed: #{$color-black-high};
    --ms-group-label-bg-disabled: #{$color-neutral-light};
    --ms-group-label-color-disabled: #{$color-black-low};
    --ms-group-label-bg-selected: #{$color-brand-primary};
    --ms-group-label-color-selected: #{$color-white-high};
    --ms-group-label-bg-selected-pointed: #{$color-brand-primary};
    --ms-group-label-color-selected-pointed: #{$color-white-high};
    --ms-group-label-bg-selected-disabled: #{$color-neutral-light};
    --ms-group-label-color-selected-disabled: #{$color-black-low};

    --ms-option-font-size: 14px;
    --ms-option-line-height: 24px;
    --ms-option-bg-pointed: #{$color-neutral};
    --ms-option-color-pointed: #{$color-black-high};
    --ms-option-bg-selected: #{$color-brand-primary};
    --ms-option-color-selected: #{$color-white-high};
    --ms-option-bg-disabled: #{$color-neutral-light};
    --ms-option-color-disabled: #{$color-black-low};
    --ms-option-bg-selected-pointed: #{$color-brand-primary};
    --ms-option-color-selected-pointed: #{$color-white-high};
    --ms-option-bg-selected-disabled: #{$color-neutral-light};
    --ms-option-color-selected-disabled: #{$color-black-low};
    --ms-option-py: 8px;
    --ms-option-px: 12px;

    --ms-empty-color: #{$color-brand-tertiary};
  }

  .multiselect {
    &.-invalid {
      border-color: $color-feedback-negative;
    }

    &.-valid {
      border-color: $color-feedback-positive;
    }
  }

  .multiselect,
  .multiselect-wrapper {
    min-height: 48px;
    @include body-2;
  }

  .multiselect-search {
    padding: 14px 72px 14px 16px;
    cursor: pointer;
    color: $color-brand-primary-dark;
    @include body-2;
  }

  .multiselect-single-label {
    color: $color-brand-primary-dark;
    @include body-2;
  }

  & > .select-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 36px;
    right: 1px;
    cursor: pointer;
    width: 48px;
    pointer-events: none;
    height: 46px;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    &.-invalid > svg {
      @include setSVGMonoColor($color-feedback-negative);
    }

    &.-valid > svg {
      @include setSVGMonoColor($color-feedback-positive);
    }
  }

  & > .validation-icon {
    background: transparent;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 46px;
    right: 42px;
    cursor: pointer;
    pointer-events: none;

    &:hover {
      cursor: pointer;
    }
  }

  & > .complement {
    & > .helper {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .message,
      .size {
        @include caption;
        color: $color-black-medium;
      }
    }

    & > .validation {
      margin-top: 8px;

      & > .error-message {
        @include caption;
        color: $color-feedback-negative;
      }
    }
  }
}
</style>
