// TODO: BASICALLY THIS EXISTS DUE TO EMPRESTIMO_SIM
export const partnerAppDocsFilterByTypeMap = {
  emprestimo_sim: ['SELFIE'], // WE DISPLAY SELFIE LATER AND NOT IN THE APP DOCS
  portocred: [],
}

export const productsMap = {
  auto: 'SECURED_LOAN_CAR',
  'no-collateral': 'PERSONAL_LOAN',
}

export const partnerAppDocsHelp = ['PORTOCRED']
