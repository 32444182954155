<template>
  <Dialog
    :is-fullscreen-mobile="true"
    :persisted="true"
    class="OfferSimulationFeedbackDialog"
    v-bind="$attrs"
    :hide-title="true"
  >
    <template #content>
      <IluHandBinoculars class="ilu" />
      <h2 class="title">
        Ops! Seu perfil de crédito não foi aprovado pelas credoras
      </h2>
      <p class="description">
        Sabemos das dificuldades em conseguir um empréstimo, mas não se
        preocupe! Podemos ajudar você entender os motivos.
      </p>
      <!--<div v-if="reasons && reasons.length > 0" class="reasons primary-scroll">
        <CardArtwork
          v-for="reason in reasons"
          :key="reason.title"
          class="reason"
        >
          <template #title>{{ reason.title }}</template>
          <template #description>{{ reason.description }}</template>
        </CardArtwork>
      </div>-->
      <div class="tips">
        <h3>Ajuda do especialista</h3>
        <CallToActionCard
          class="whatsapp-call-to-action"
          @click="$emit('jb-prime-service-attendence')"
        >
          <template #icon><BaseIcon name="whatsapp-mono" /></template>
          <template #title>WhatsApp da Juros Baixos</template>
          <template #description
            >Entenda os motivos que impedem você de conseguir um
            empréstimo.</template
          >
          <template #cta>Iniciar conversa com especialista</template>
        </CallToActionCard>
        <h3>Outras opções</h3>
        <template v-if="tips && tips.length > 0">
          <ListItem
            v-for="(tip, index) in tips"
            :key="index"
            @click="$emit('tip-click', tip)"
          >
            <template v-if="tip.title" #title>
              {{ tip.title }}
            </template>
            <template v-if="tip.description" #description>
              {{ tip.description }}
            </template>
          </ListItem>
        </template>
      </div>
    </template>
  </Dialog>
</template>
<script>
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
import ListItem from '@/aasgard/components/lists/ListItem.vue'
import IluHandBinoculars from '@/aasgard/assets/img/ilu/ilu-hand-binoculars.svg?component'
import Dialog from '@/aasgard/components/modals/Dialog.vue'
import CallToActionCard from '@/components/commomComponents/cards/CallToActionCard.vue'
export default {
  name: 'OfferSimulationFeedbackDialog',
  components: {
    CallToActionCard,
    BaseIcon,
    ListItem,
    IluHandBinoculars,
    Dialog,
  },
  props: {
    reasons: { type: Array, default: () => [] },
    tips: { type: Array, default: () => [] },
  },
  emits: ['jb-prime-service-attendence', 'tip-click'],
}
</script>
<style lang="scss">
.OfferSimulationFeedbackDialog {
  & > .content-wrapper {
    & > .content {
      & > .ilu {
        width: 96px;
      }
      & > .title {
        @include headline-5;
        color: $color-black-high;
        margin: 16px 0;
      }
      & > .description {
        @include body-1;
        color: $color-black-medium;
        margin: 0;
      }

      & > .reasons {
        max-width: 100%;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding-bottom: 16px;
        margin: 24px 0 0 0;
        align-items: flex-start;

        & > .reason {
          min-width: 268px;
          min-height: 0;
          height: 100%;
        }
      }

      & > .tips {
        & > h3 {
          @include subtitle-1;
          color: $color-black-high;
          margin: 24px 0 16px 0;
        }

        & > .whatsapp-call-to-action {
          background-color: $color-feedback-positive-light;

          & > .call-to-action {
            color: $color-feedback-positive;

            & > .BaseIcon {
              @include setSVGMonoColor($color-feedback-positive);
            }
          }
        }

        & > .ListItem {
          margin-top: 8px;
          background-color: $color-neutral-light;
          border-radius: 8px;

          & > .content {
            & > .description {
              color: $color-black-high;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
