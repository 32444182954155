<template>
  <ClearLayout>
    <ContainerWrapper v-if="cptdCurrentForm" class="LoanDataPage">
      <NavBar
        :progress="{ step: 3, steps: 4, type: 'stepped' }"
        @back="$router.back()"
        @close="displayProgressWillBeLost = true"
      />
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="displayProgressWillBeLost = false"
        @exit="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <InfocarAutoFormContainer
          :data="cptdCurrentForm['auto'] || {}"
          @submit="submit"
        />
        <FullscreenLoading v-if="busy">
          <template #loading-message>
            Carregando valores da oferta...
            <p>Isso pode levar alguns segundos.</p>
          </template>
        </FullscreenLoading>
        <AutoEquityOfferNoResultFeedbackDialogContainer
          v-if="!busy && displayAutoEquityNoResultFeedbackDialog"
          :display="displayAutoEquityNoResultFeedbackDialog"
        />
        <OfferPartnerTimeoutFeedbackDialog
          v-if="!busy && displayOfferPartnerTimeoutFeedbackDialog"
          :display="displayOfferPartnerTimeoutFeedbackDialog"
          partner="BV"
        />
        <UnexpectedPartnerOfferErrorFeedbackDialog
          :display="displayUnexpectedPartnerOfferErrorFeedbackDialog"
          :offer-id="offerErrorId"
          partner="BV"
          :step="currentStep"
        />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { OfferStatus } from '@/data/common'
import { waitForAResultWithOptions } from '@/helpers/offers'
import { UnexpectedPartnerOfferStepEnum } from '@/helpers/offer-types'
import { formatDateToNumericDate } from '@/helpers/date'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const AutoEquityOfferNoResultFeedbackDialogContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/auto/AutoEquityNoResultFeedbackDialogContainer.vue'
    ),
)
const UnexpectedPartnerOfferErrorFeedbackDialog = defineAsyncComponent(
  () =>
    import('@/components/loans/UnexpectedPartnerOfferErrorFeedbackDialog.vue'),
)
const OfferPartnerTimeoutFeedbackDialog = defineAsyncComponent(
  () => import('@/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'),
)
const FullscreenLoading = defineAsyncComponent(
  () => import('@/components/commomComponents/loaders/FullscreenLoading.vue'),
)
const InfocarAutoFormContainer = defineAsyncComponent(
  () => import('@/containers/forms/persona/InfocarAutoFormContainer.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    ClearLayout,
    ProgressWillBeLostModal,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
    AutoEquityOfferNoResultFeedbackDialogContainer,
    UnexpectedPartnerOfferErrorFeedbackDialog,
    OfferPartnerTimeoutFeedbackDialog,
    FullscreenLoading,
    InfocarAutoFormContainer,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      busy: false,
      displayAutoEquityNoResultFeedbackDialog: false,
      formId: 'BV',
      offer: null,
      offerErrorId: '',
      displayOfferPartnerTimeoutFeedbackDialog: false,
      displayUnexpectedPartnerOfferErrorFeedbackDialog: false,
      currentStep: '',
      errorMsg: 'Algo deu errado! Por favor tente novamente.',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      personaData: 'user/persona/personaData',
      gtColdOfferSelected:
        'products/loans/auto-equity/cold-offer/gtColdOfferSelected',
      gtColdOfferSelectedRequest:
        'products/loans/auto-equity/cold-offer/gtColdOfferSelectedRequest',
    }),
    cptdCurrentForm() {
      return this.gtDynamicForms?.[this.formId] || {}
    },
  },
  created() {
    if (
      !this.gtColdOfferSelected ||
      !this.gtColdOfferSelectedRequest ||
      !this.cptdCurrentForm
    ) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      createFeedback: 'utils/feedback/createFeedback',
      actSimulateAutoColdOfferV2:
        'products/loans/auto-equity/cold-offer/actSimulateAutoColdOfferV2',
      actGetAutoOffersBySimulationId:
        'products/loans/auto-equity/auto-equity/actGetAutoOffersBySimulationId',
      actSetAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/actSetAutoOfferSelected',
    }),
    async submit(data) {
      this.busy = true
      await this.actUpdateForm({ formId: this.formId, value: data })
      await this.simulate(data)
      this.busy = false
    },
    async simulate({ auto }) {
      try {
        const payload = {
          amount: 17000,
          duration: 60,
          email: this.gtUser?.email,
          mobile_phone: this.gtUser?.mobile_phone,
          cpf: this.gtUser?.cpf,
          info: this.personaData?.info,
          finance: this.personaData?.finance,
          residence: this.personaData?.residence,
          auto: {
            manufacturer: auto.brandName,
            model_year: auto.modelYear,
            plate: auto.plate,
            type: auto.vehicleType,
            year: auto.year,
            licensed_state: auto.state,
            model: auto.molicar?.modelVersion,
            accessoryPackage: auto.molicar?.accessoryPackage,
            fuel_type: auto.molicar?.fuelType,
            numberOfDoors: auto.molicar?.numberOfDoors,
            auction_vehicle:
              this.gtColdOfferSelectedRequest?.vehicle?.vehicle_is_auctioned,
            vehicle_owner:
              this.gtColdOfferSelectedRequest?.vehicle?.vehicle_owner,
            loans_paid: !this.gtColdOfferSelectedRequest?.vehicle?.vehicle_debt,
            outstanding_financing:
              this.gtColdOfferSelectedRequest?.vehicle?.outstanding_financing,
            user_selected_installment_date:
              this.gtColdOfferSelected?.first_installment_date_max ||
              formatDateToNumericDate(
                new Date(new Date().setDate(new Date().getDate() + 30)),
                'yyyy-mm-dd',
              ),
            is_not_a_taxi:
              !this.gtColdOfferSelectedRequest?.vehicle?.vehicle_is_taxi,
            is_not_adapted:
              !this.gtColdOfferSelectedRequest?.vehicle?.vehicle_is_adapted,
            fipe: {
              model: auto.fipe,
              fuel_type: auto.fuelType,
            },
          },
          partner_simulation_fields: {
            bv: {
              cold_offer_id: this.gtColdOfferSelected?.cold_offer_id,
            },
          },
        }

        const response = await this.actSimulateAutoColdOfferV2(payload)
        if (response?.status === 200 && response?.data?.bv?.id) {
          return await this.trackOfferChanges(response?.data?.bv?.id)
        } else {
          this.createFeedback({ text: this.errorMsg })
        }
      } catch (e) {
        this.createFeedback({ text: this.errorMsg })
      }
    },
    async trackOfferChanges(simulationId) {
      let offerResponse = null
      try {
        offerResponse = await waitForAResultWithOptions(
          {
            status: (s) =>
              [
                OfferStatus.PROPOSED,
                OfferStatus.FATAL_ERROR,
                OfferStatus.REJECTED,
              ].includes(s),
          },
          async () => {
            return await this.actGetAutoOffersBySimulationId({ simulationId })
          },
          10000,
          6,
          1,
        )

        if (offerResponse) {
          this.offer = offerResponse
          switch (this.offer.status) {
            case OfferStatus.PROPOSED:
              this.actSetAutoOfferSelected(this.offer)
              this.$router.push({
                path: '/forms/emprestimo/auto/parceiros/bv/simular/dados-emprestimo',
              })
              break
            case OfferStatus.REJECTED:
              this.displayAutoEquityNoResultFeedbackDialog = true
              break
            case OfferStatus.FATAL_ERROR:
              this.currentStep =
                UnexpectedPartnerOfferStepEnum.COLD_OFFER_SIMULATE
              this.offerErrorId = this.gtColdOfferSelected.cold_offer_id
              this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
              break
            default:
              this.createFeedback({ text: this.errorMsg })
              break
          }
        } else {
          this.displayAutoEquityNoResultFeedbackDialog = true
        }
      } catch (e) {
        if (e?.message === 'MAX_RETRIES_REACHED') {
          this.displayAutoEquityNoResultFeedbackDialog = true
        } else {
          this.createFeedback({ text: this.errorMsg })
        }
      }
    },
  },
}
</script>
<style lang="scss"></style>
