import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import {
  JWTTokenResponse,
  RefreshSessionRequest,
} from '@/data/authentication/jwt'

const refresh = async (
  payload: RefreshSessionRequest,
): Promise<AxiosResponse<JWTTokenResponse>> => {
  return await JurosBaixosSystemsAPI.post(`/jwt/refresh`, payload).catch(
    createRequestException,
  )
}

export default { refresh }
