import { translatePersonaToNoCollateralSimulation } from '@/helpers/simulation'
import {
  partnerHasStepFields,
  waitForAResultWithOptions,
} from '@/helpers/offers'
import {
  getLatestSimulation,
  simulateNoCollateralLoan,
} from '@/api/loans/no-collateral/simulate'
import {
  fetchNoCollateralOfferById,
  fetchNoCollateralOffers,
} from '@/api/loans/no-collateral/offers'
import { selectNoCollateralOffer } from '@/api/loans/no-collateral/offers/select'
import { NoCollateralOfferResponse } from '@/data/loans/no-collateral/offer'
import { NoCollateralSelectOfferPostSchema } from '@/data/loans/no-collateral/select'
import { OfferStatus, Partner } from '@/data/common'
import { isIOS } from '@/helpers/globalFunctions'
import router from '@/router'
// import { abTest } from '~/helpers/abTest'

interface NoCollateralState {
  proposedOffers: NoCollateralOfferResponse[]
  onGoingOffers: NoCollateralOfferResponse[]
  grantedOffers: NoCollateralOfferResponse[]
  noCollateralOfferSelected: NoCollateralOfferResponse | null
  noCollateralOfferSelectedContract: string
  lastSimulationId: string
  latestSimulationRequest: string | null
}

// STATE
const state = (): NoCollateralState => ({
  proposedOffers: [],
  onGoingOffers: [],
  grantedOffers: [],
  noCollateralOfferSelected: null,
  noCollateralOfferSelectedContract: '',
  lastSimulationId: '',
  latestSimulationRequest: null,
})

const noCollateralPartnersOffersEpc: Record<
  Partner | string,
  Record<'loan' | 'micro', number | undefined>
> = {
  NOVERDE: {
    loan: 53.37,
    micro: undefined,
  },
  JUVO: {
    loan: 16.34,
    micro: undefined,
  },
  SUPERSIM: {
    loan: 2.31,
    micro: 12.31,
  },
  JEITTO: {
    loan: 9.89,
    micro: 0.97,
  },
  SIMPLIC: {
    loan: 1.8,
    micro: undefined,
  },
}

// GETTERS
const getters = {
  gtAllProposedNoCollateralOffers(
    state: NoCollateralState,
  ): NoCollateralOfferResponse[] {
    // The key is loan if the offer duration is greater than 1, otherwise it's micro
    const getEpcKey = (offer) => {
      return offer.duration > 1 ? 'loan' : 'micro'
    }

    const proposedOffers = state.proposedOffers
      .filter(
        (o) =>
          !(
            import.meta.env.VITE_NO_COLLATERAL_FILTER_PARTNER_OFFER ?? ''
          ).includes(o.partner),
      )
      .filter(({ partner }) => {
        return !(isIOS && partner === Partner.JEITTO)
      })
    // .filter(({ partner }) => {
    //   return (
    //     partner !== Partner.CREFAZ ||
    //     (partner === Partner.CREFAZ &&
    //       abTest([
    //         '0',
    //         '1',
    //         '2',
    //         '3',
    //         '4',
    //         '5',
    //         '6',
    //         '7',
    //         '8',
    //         '9',
    //         'a',
    //         'b',
    //         'c',
    //       ]) === 'B')
    //   )
    // })
    // .filter(({ partner }) => {
    //   return (
    //     partner !== Partner.AGIL ||
    //     (partner === Partner.AGIL &&
    //       abTest([
    //         '3',
    //         '4',
    //         '5',
    //         '6',
    //         '7',
    //         '8',
    //         '9',
    //         'a',
    //         'b',
    //         'c',
    //         'd',
    //         'e',
    //         'f',
    //       ]) === 'B')
    //   )
    // })

    // sort by epc
    return proposedOffers.sort((a, b) => {
      const aEpc = noCollateralPartnersOffersEpc[a.partner]?.[getEpcKey(a)] || 0
      const bEpc = noCollateralPartnersOffersEpc[b.partner]?.[getEpcKey(b)] || 0

      return bEpc - aEpc
    })
  },
  gtAllOnGoingNoCollateralOffers(
    state: NoCollateralState,
  ): NoCollateralOfferResponse[] {
    return state.onGoingOffers.filter(
      (o) =>
        !(
          import.meta.env.VITE_NO_COLLATERAL_FILTER_PARTNER_OFFER ?? ''
        ).includes(o.partner),
    )
  },
  gtAllGrantedNoCollateralOffers(
    state: NoCollateralState,
  ): NoCollateralOfferResponse[] {
    return state.grantedOffers.filter(
      (o) =>
        !(
          import.meta.env.VITE_NO_COLLATERAL_FILTER_PARTNER_OFFER ?? ''
        ).includes(o.partner),
    )
  },
  gtNoCollateralOfferSelected(
    state: NoCollateralState,
  ): NoCollateralOfferResponse | null {
    return state.noCollateralOfferSelected
  },
  gtNoCollateralLastSimulationId(state: NoCollateralState): string {
    return state.lastSimulationId
  },
  gtNoCollateralLatestSimulationRequest(
    state: NoCollateralState,
  ): string | null {
    return state.latestSimulationRequest
  },
}

// MUTATIONS
const mutations = {
  // NEW STUFF
  SET_NO_COLLATERAL_OFFERS(
    state,
    payload: Array<NoCollateralOfferResponse | any>,
  ): void {
    payload.forEach((o) => {
      o.product_type = 'no-collateral'
      o.display_interest_rate_monthly = o.interest_rate_monthly * 100
    })
    state.proposedOffers = payload.filter(
      (o) => o.status === OfferStatus.PROPOSED,
    )
    state.onGoingOffers = payload.filter(
      (o) => o.status === OfferStatus.ONGOING,
    )
    state.grantedOffers = payload.filter(
      (o) => o.status === OfferStatus.GRANTED,
    )
  },
  SET_NO_COLLATERAL_OFFER_SELECTED(state, payload) {
    state.noCollateralOfferSelected = payload
  },
  SET_LAST_SIMULATION_ID(state, payload) {
    state.lastSimulationId = payload
  },
  SET_LATEST_SIMULATION_REQUEST(state, payload) {
    state.latestSimulationRequest = payload
  },
}

// ACTIONS
const actions = {
  async actGetNoCollateralOfferById(_, offer_id) {
    try {
      return await fetchNoCollateralOfferById(offer_id)
    } catch (e: any) {
      return e?.response
    }
  },
  async simulateNoCollateralLoan(
    { dispatch, commit },
    { loan, persona, user, source, score },
  ) {
    const simulationPayload = translatePersonaToNoCollateralSimulation({
      loan,
      persona,
      user,
      source,
      score,
    })
    try {
      const response = await simulateNoCollateralLoan(simulationPayload)
      if (!response?.data || !response?.data?.id) {
        dispatch(
          'utils/feedback/createFeedback',
          'Tivemos um probleminha ao enviar seus dados',
          { root: true },
        )
      }
      commit('SET_LAST_SIMULATION_ID', response?.data?.id ?? '')
      return response
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        'Tivemos um probleminha ao enviar seus dados',
        { root: true },
      )
      return e?.response
    }
  },
  async actGetNoCollateralOffers(
    { dispatch, commit },
    options = {
      status: [
        OfferStatus.PROPOSED,
        OfferStatus.ONGOING,
        OfferStatus.VALIDATING,
      ],
    },
  ) {
    const errorMsg =
      'Tivemos um probleminha ao consultar novas ofertas para voce'
    try {
      const response = await fetchNoCollateralOffers(options)
      const { data } = response
      if (data) {
        commit('SET_NO_COLLATERAL_OFFERS', response?.data ?? [])
      } else {
        dispatch('utils/feedback/createFeedback', errorMsg, { root: true })
      }
      return response
    } catch (e: any) {
      dispatch('utils/feedback/createFeedback', errorMsg, { root: true })
      commit('SET_NO_COLLATERAL_OFFERS', [])
      return e?.response
    }
  },
  async actTrackNoCollateralSimulationEnd(_, { simulationId }) {
    let response
    try {
      response = await waitForAResultWithOptions(
        (data) => {
          return (
            data?.length &&
            !data.some((o) => o?.status === OfferStatus.VALIDATING)
          )
        },
        async () => {
          try {
            return await fetchNoCollateralOffers({
              simulationId,
            })
          } catch (e: any) {
            return e?.response
          }
        },
      )
    } catch (e: any) {
      response = null
    }

    return response
  },
  actSetNoCollateralOfferSelected({ commit }, payload) {
    commit('SET_NO_COLLATERAL_OFFER_SELECTED', payload)
  },
  async actSelectNoCollateralOffer(
    _,
    {
      offer,
      selectPayload = {},
    }: {
      offer: NoCollateralOfferResponse
      selectPayload: NoCollateralSelectOfferPostSchema | object
    },
  ) {
    try {
      const partnerFields = partnerHasStepFields({
        offer,
        step: 'no-collateral:select',
      })
      const { offer_id, partner } = offer

      if (partnerFields && !(Object.keys(selectPayload).length > 0)) {
        router.push({
          path: `/forms/emprestimo/sem-garantia/parceiros/${partner.toLowerCase()}/selecionar-oferta`,
        })
        return 'REDIRECTED_TO_PARTNER_PAGES'
      }

      return await selectNoCollateralOffer({
        selectPayload,
        offerId: offer_id,
      })
    } catch (e: any) {
      return e?.response
    }
  },
  async actGetNoCollateralLatestSimulationRequest({ commit }) {
    try {
      const response = await getLatestSimulation()
      commit('SET_LATEST_SIMULATION_REQUEST', response?.data || {})
    } catch (e: any) {
      commit('SET_LATEST_SIMULATION_REQUEST', {})
      return e?.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
