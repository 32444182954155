import { sentryException } from '@/helpers/sentry'
// https://developers.facebook.com/docs/meta-pixel/reference
export enum FacebookTrackEventNameEnum {
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  ADD_TO_CART = 'AddToCart',
  ADD_TO_WISHLIST = 'AddToWishlist',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  CONTACT = 'Contact',
  CUSTOMIZE_PRODUCT = 'CustomizeProduct',
  DONATE = 'Donate',
  FIND_LOCATION = 'FindLocation',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  LEAD = 'Lead',
  PURCHASE = 'Purchase',
  SCHEDULE = 'Schedule',
  SEARCH = 'Search',
  START_TRIAL = 'StartTrial',
  SUBMIT_APPLICATION = 'SubmitApplication',
  SUBSCRIBE = 'Subscribe',
  VIEW_CONTENT = 'ViewContent',
}

type FbContentCategory = string
type FbContentIds = Array<number | string>
type FbContentName = string
type FbContentType = string
type FbContents = Array<object>
type FbCurrency = string
type FbNumItems = number
type FbPredictedLtv = number
type FbSearchString = string
type FbStatus = boolean
type FbValue = number

export interface FacebookTrackAddPaymentInfoParameters {
  content_category?: FbContentCategory
  content_ids?: FbContentIds
  contents?: FbContents
  currency?: FbCurrency
  value?: FbValue
}
export interface FacebookTrackAddToCartParameters {
  content_ids?: FbContentIds
  content_name?: FbContentName
  content_type?: FbContentType
  contents?: FbContents
  currency?: FbCurrency
  value?: FbValue
}
export interface FacebookTrackAddToWishlistParameters {
  content_name?: FbContentName
  content_category?: FbContentCategory
  content_ids?: FbContentIds
  contents?: FbContents
  currency?: FbCurrency
  value?: FbValue
}
export interface FacebookTrackCompleteRegistrationParameters {
  content_name?: FbContentName
  currency?: FbCurrency
  status?: FbStatus
  value?: FbValue
}

export interface FacebookTrackInitiateCheckoutParameters {
  content_category?: FbContentCategory
  content_ids?: FbContentIds
  contents?: FbContents
  currency?: FbCurrency
  num_items?: FbNumItems
  value?: FbValue
}
export interface FacebookTrackLeadParameters {
  content_category?: FbContentCategory
  content_name?: FbContentName
  currency?: FbCurrency
  value?: FbValue
}
export interface FacebookTrackPurchaseParameters {
  content_ids: FbContentIds
  content_name?: FbContentName
  content_type?: FbContentType
  contents: FbContents
  currency: FbCurrency
  num_items?: FbNumItems
  value: FbValue
}
export interface FacebookTrackSearchParameters {
  content_category?: FbContentCategory
  content_ids?: FbContentIds
  content_type?: FbContentType
  contents?: FbContents
  currency?: FbCurrency
  search_string?: FbSearchString
  value?: FbValue
}
export interface FacebookTrackStartTrialParameters {
  currency?: FbCurrency
  predicted_ltv?: FbPredictedLtv
  value?: FbValue
}
export interface FacebookTrackSubscribeParameters {
  currency?: FbCurrency
  predicted_ltv?: FbPredictedLtv
  value?: FbValue
}

export interface FacebookTrackViewContentParameters {
  content_ids?: FbContentIds
  content_category?: FbContentCategory
  content_name?: FbContentName
  content_type?: FbContentType
  contents?: FbContents
  currency?: FbCurrency
  value?: FbValue
}

const actions = {
  actEmitFacebookTrackEvent(
    _,
    {
      eventName,
      parameters,
    }: { eventName: FacebookTrackEventNameEnum; parameters: any },
  ): void {
    try {
      window?.fbq('track', eventName, parameters)
    } catch (e: any) {
      sentryException(e)
    }
  },
}

export default { namespaced: true, actions }
