import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { createRequestException } from '@/helpers/requestException'
import { SelectAutoEquityOffer } from '@/data/loans/auto/select'
import { AutoOfferResponse } from '@/data/loans/auto/offer'

export const selectAutoOffer = async ({
  payload,
  offer,
}: {
  payload: SelectAutoEquityOffer
  offer: AutoOfferResponse
}) => {
  const { offer_id } = offer
  return await JurosBaixosSystemsAPI.post(
    `loans/auto/offers/${offer_id}/select`,
    payload,
  ).catch(createRequestException)
}
