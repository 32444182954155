function sanitizeAccount(acc, size) {
  const accParsed = acc.toString()
  const ZeroAditionalQtd = size - accParsed.length

  if (accParsed.length === size) {
    return accParsed
  }

  const zeros: Array<string> = []
  for (let i = 0; i < ZeroAditionalQtd; i++) {
    zeros.push('0')
  }

  return zeros.join('') + accParsed
}

// remove dots and dashes
function sanitizeValues(str) {
  return (str || '').toString().replace(/[^a-zA-Z0-9]/gi, '')
}

function sanitizeBankAccountValues(BankAccount) {
  const sanitizedNumber = sanitizeValues(BankAccount.number)
  const number = sanitizedNumber.slice(0, -1)
  const number_digit = sanitizedNumber.slice(-1)

  return {
    bank: String(sanitizeValues(BankAccount.bank)).toUpperCase(),
    number: String(number).toUpperCase(),
    branch: String(sanitizeValues(BankAccount.branch)).toUpperCase(),
    number_digit: String(number_digit).toUpperCase(),
  }
}

function padStart(account, size) {
  return String(account).padStart(size, '0')
}

export default {
  sanitizeAccount,
  sanitizeValues,
  sanitizeBankAccountValues,
  padStart,
}
