import axios from 'axios'
import { defineRule } from 'vee-validate'
import { required, email, min, max } from '@vee-validate/rules'
import ValidateBankAccount from '@/lib/accountValidator/bankvalidator'
import validCpf from '@/helpers/cpfValidation'
import validDDD from '@/helpers/validDDD'
import { allRegexes } from '@/helpers/regex'
import { validateEmailDomain } from '@/helpers/email'

// fields required
defineRule('required', (value) => {
  if (required(value)) return true
  return 'Este campo é obrigatório!'
})

defineRule('checkboxRequired', (value) => {
  if (value) return true
  return 'Este campo é obrigatório!'
})

defineRule('booleanRequired', (value) => {
  if (value === true || value === false) return true
  return 'Este campo é obrigatório!'
})

// select fields required
defineRule('select-required', (value) => {
  if (!!value && value !== 'Escolha') return true
  return 'Este campo é obrigatório!'
})

/**
 * DATA VALIDATIONS
 */

// cpf validation
defineRule('cpf', (value) => {
  if (validCpf(value)) return true
  return 'Preencha um CPF válido.'
})

defineRule('cpf-length', (value) => {
  if (value.replace(/[^\d]+/g, '')?.length >= 11) return true
  return 'Complete o CPF.'
})

defineRule('rgLength', (value) => {
  if (value?.length >= 7) return true
  return 'Complete o RG.'
})

// check DDD value
defineRule('validDDD', (value) => {
  const splited = parseInt(value.substr(1, 2))
  if (validDDD.includes(splited)) return true
  return 'Preencha um DDD válido.'
})

// cep validation
defineRule('cep', async (value) => {
  const cep = await axios.get(
    `https://ws.apicep.com/cep.json?code=${value.replace('-', '')}`,
  )
  if (cep?.data?.ok) return true
  return 'Preencha um CEP válido.'
})

// cep length
defineRule('cep-length', (value) => {
  if (value.replace(/[^\d]+/g, '')?.length >= 8) return true
  return 'Complete o CEP.'
})

// cep validation
defineRule('cepIsValid', (value) => {
  const replaced = value.replace('-', '')
  if (!replaced.split('').every((char) => char === replaced[0])) return true
  return 'Preencha um CEP válido.'
})

// negative value
defineRule('negativeValue', (value) => {
  if (value >= 0) return true
  return 'É necessário ser um valor positivo.'
})

// negative money value
defineRule('valueRequired', (value) => {
  if (value !== 0) return true
  return 'É necessário ser um valor maior que R$0.'
})

// numbers not allowed
defineRule('notNumbers', (value) => {
  if (!/\d/.test(value)) return true
  return 'Não são permitidos números nesse campo'
})

defineRule('onlyValidLetters', (value) => {
  if (
    value.split(' ').filter((v) => !/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\\s]*$/.test(v))
      .length == 0
  )
    return true
  return 'Não são permitidos caracteres especiais'
})

// plate validation
defineRule('carPlate', (value) => {
  const replaced = value.replace('-', '')

  const regexPlate = /^[a-zA-Z]{3}[0-9]{4}$/
  const regexPlateMercosul = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/

  if (regexPlate.test(replaced) || regexPlateMercosul.test(replaced))
    return true
  return 'É necessário uma placa válida.'
})

// mobile number validation
defineRule('tel', (value) => {
  if (value.length > 15) return true
  return 'Complete o número do celular.'
})

// check if has '9' before the number
defineRule('ninenumber', (value) => {
  if (value.length === 16) {
    if (parseInt((value || '').split('')[5]) === 9) return true
    return 'É necessário o número 9 após o DDD.'
  }

  return true
})

/**
 * DATE VALIDATIONS
 */

// Check if the date is valid

defineRule('validDate', (value) => {
  const errorMsg = 'Preencha uma data válida.'
  if (value === '00/00/0000') return errorMsg
  const parts = value.split('/')
  if (parts[0] === '00' || parts[1] === '00' || parts[2] === '0000')
    return errorMsg
  if (
    parseInt(parts[2]) < 1900 ||
    parseInt(parts[1]) > 12 ||
    parseInt(parts[0]) > 31
  ) {
    return errorMsg
  }
  const today = new Date()
  const date = new Date(parts[2], parts[1] - 1, parts[0])

  if (value.length === 10 && date <= today) return true

  return errorMsg
})

defineRule('creditCardExpiration', (value) => {
  const errorMessage = 'Preencha uma data de expiração válida.'
  if (value === '00/0000') return errorMessage
  const [month, year] = value.split('/')
  if (month[0] === '00' || year[2] === '0000') return errorMessage

  const today = new Date()
  if (month > 12) return errorMessage
  if (year < today.getFullYear()) return errorMessage
  if (year === today.getFullYear() && month < today.getMonth() + 1)
    return errorMessage

  if (value.length >= 6) return true
  return errorMessage
})

defineRule('date-length', (value) => {
  if (value?.length === 10) return true
  return 'Preencha uma data válida.'
})

// check if is adult
defineRule('adult', (value) => {
  const dateParts = value.split('/')
  const birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
  const newDate = new Date()
  newDate.setFullYear(newDate.getFullYear() - 18)

  if (birthDate <= newDate) return true
  return 'É necessário ter ao menos 18 anos!'
})

// limit in 100 years old
defineRule('limitAge', (value) => {
  const dateParts = value.split('/')
  const birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
  const newDate = new Date()
  newDate.setFullYear(newDate.getFullYear() - 100)
  if (birthDate >= newDate) return true
  return 'É necessário ter menos de 100 anos!'
})

/**
 * TEXT VALIDATIONS
 */

defineRule('min', (value, args) => {
  if (min(value, args)) return true
  return `Este campo precisa de mais caracteres.`
})

defineRule('max', (value, args) => {
  if (max(value, args)) return true
  return `Este campo precisa de menos caracteres.`
})

defineRule('twophrases', (value) => {
  const arr = value.split(' ')
  if (arr.length >= 2 && arr[0].length > 2 && arr[1].length > 1) return true
  return 'Complete o nome e sobrenome.'
})

defineRule('name', (value) => {
  const arr = value.split(' ')
  if (arr.length >= 2 && arr[0].length > 2 && arr[1].length > 1) return true
  return 'Complete o nome e sobrenome.'
})

defineRule('email', (value) => {
  if (email(value)) return true
  return 'Preencha um e-mail válido.'
})

//
// GENERIC VALIDATIONS
//

defineRule('greaterThan', (value, [intValue]) => {
  const intV = Number(intValue)
  if (value > intV) return true
  return `Preencha um valor maior que ${intValue}`
})
defineRule('lowerThan', (value, [intValue]) => {
  const intV = Number(intValue)
  if (value < intV) return true
  return `Preencha um valor menor que ${intValue}`
})

defineRule('radioRequired', (value) => {
  if (value !== null && value !== undefined && value !== '') return true
  return 'Este campo é obrigatório!'
})

defineRule('rgx', (value, [key]) => {
  if (allRegexes[key]) {
    if (String(value).match(allRegexes[key].regex)) return true
    return allRegexes[key].message
  }

  // Return true since can probably a typo or a key not mapped yet by us
  return true
})

// bank validation
defineRule('bank', (value, [bankData]) => {
  if (!bankData) return true

  if (
    ValidateBankAccount({
      bank: bankData?.bank,
      branch: bankData?.branch,
      number: value,
      type: bankData?.type,
    })
  ) {
    return true
  }

  return 'Dados bancários inválidos! Verifique e tente novamente.'
})

defineRule('validateEmailDomain', (value) => {
  if (validateEmailDomain(value).valid) return true
  return validateEmailDomain(value).message
})
