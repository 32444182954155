import { AxiosResponse } from 'axios'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { createRequestException } from '@/helpers/requestException'
import {
  AutoEquitySimulationRequest,
  AutoEquitySimulationRequestV2,
} from '@/data/loans/auto/simulation'
import { AutoOfferResponse } from '@/data/loans/auto/offer'
import { OfferStatus } from '@/data/common'

export const simulateAutoColdOffer = async (
  autoEquitySimulationRequest: AutoEquitySimulationRequest,
): Promise<AxiosResponse> => {
  return await JurosBaixosSystemsAPI.post(
    `loans/auto/simulate`,
    autoEquitySimulationRequest,
  ).catch(createRequestException)
}
export const simulateAutoColdOfferV2 = async (
  autoEquitySimulationRequest: AutoEquitySimulationRequestV2,
): Promise<AxiosResponse> => {
  return await JurosBaixosSystemsAPI.post(
    `v2/loans/auto/simulate`,
    autoEquitySimulationRequest,
  ).catch(createRequestException)
}

export const fetchAutoOffersBySimulationId = async (
  simulationId: string,
  statusList: Array<OfferStatus> = [],
): Promise<AxiosResponse<Array<AutoOfferResponse>>> => {
  const statusParam =
    statusList.length > 0 ? `status=${statusList.join('&status=')}` : ''
  return await JurosBaixosSystemsAPI.get(
    `loans/auto/simulate/${simulationId}/offers?${statusParam}`,
  ).catch(createRequestException)
}
