import userActivity from '@/store/modules/ui/user-activity'
import app from '@/store/modules/ui/app'

const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { 'user-activity': userActivity, app },
}
