import { AxiosResponse } from 'axios'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { createRequestException } from '@/helpers/requestException'
import { HomeEquitySimulationRequest } from '@/data/loans/home-equity/simulation'
import { HomeEquityOfferResponse } from '@/data/loans/home-equity/offer'
import { OfferStatus } from '@/data/common'

export const simulateHomeEquityColdOffer = async (
  homeEquitySimulationRequest: HomeEquitySimulationRequest,
): Promise<AxiosResponse> => {
  return await JurosBaixosSystemsAPI.post(
    `loans/homeequity/simulate`,
    homeEquitySimulationRequest,
  ).catch(createRequestException)
}

export const getHomeEquityOffersBySimulationId = async (
  simulationId: string,
  statusList: Array<OfferStatus> = [],
): Promise<AxiosResponse<Array<HomeEquityOfferResponse>>> => {
  const statusParam =
    statusList.length > 0 ? `status=${statusList.join('&status=')}` : ''
  return await JurosBaixosSystemsAPI.get(
    `loans/homeequity/simulate/${simulationId}/offers?${statusParam}`,
  ).catch(createRequestException)
}
