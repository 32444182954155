<template>
  <div class="app">
    <main class="main">
      <transition
        name="custom-classes"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
      >
        <AppLoader v-if="gtIsAppBusy" key="loader" simple />

        <ContainerWrapper v-else key="content">
          <slot />
        </ContainerWrapper>
      </transition>
      <!-- FEEDBACK -->
      <Feedback :data="feedback" />
    </main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import AppLoader from '@/components/appPartials/AppLoader.vue'
import Feedback from '@/components/ui/Feedback.vue'
export default {
  name: 'SimpleLayout',
  components: {
    Feedback,
    ContainerWrapper,
    AppLoader,
  },
  data() {
    return {
      busy: true,
      currentPage: undefined,
    }
  },
  computed: {
    ...mapGetters({
      feedback: 'utils/feedback/feedback',
      gtIsAppBusy: 'ui/app/gtIsAppBusy',
    }),
  },
}
</script>
<style lang="scss">
.app {
  & > .main {
    min-height: calc(100vh - 56px);
  }
}
</style>
