import { createRequestException } from '@/helpers/requestException'
import {
  validatePersonaAuto,
  validatePersonaFinance,
  validatePersonaInfo,
  validatePersonaResidence,
} from '@/helpers/persona'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'

export const fetchPersona = async () => {
  return await JurosBaixosSystemsAPI.get(`persona`).catch(
    createRequestException,
  )
}

export const savePersonaInfo = async ({ payload }) => {
  return await JurosBaixosSystemsAPI.post(`persona/info`, payload).catch(
    createRequestException,
  )
}

export const updatePersonaAuto = async ({ payload, autoId }) => {
  return await JurosBaixosSystemsAPI.put(
    `/persona/auto/${autoId}`,
    payload,
  ).catch(createRequestException)
}

export const savePersonaAuto = async ({ payload }) => {
  const postData = {
    ...payload,
    model: payload?.model.substring(0, 100),
    type: 'CAR',
  }

  if (payload.id) {
    return await updatePersonaAuto({
      payload: postData,
      autoId: payload.id,
    })
  }
  return await JurosBaixosSystemsAPI.post(`persona/auto`, postData).catch(
    createRequestException,
  )
}

export const savePersonaResidence = async ({ payload }) => {
  if (!payload.complement) {
    delete payload.complement
  }

  return await JurosBaixosSystemsAPI.post(`persona/residence`, payload).catch(
    createRequestException,
  )
}

export const savePersonaFinance = async ({ payload }) => {
  const sanitizedData = {
    ...payload,
  }

  if (payload?.account && payload.account?.number) {
    sanitizedData.account = {
      ...sanitizedData.account,
      ...{
        ...payload.account,
        number: payload.account.number.replace(/[^0-9PX]/g, ''),
      },
    }
  }

  return await JurosBaixosSystemsAPI.post(
    `persona/finance`,
    sanitizedData,
  ).catch(createRequestException)
}

// Receives a persona -> { info: {}, residence: {} ...}
export const updateAllPersonaFields = async ({ payload }) => {
  const postsMap = {
    auto: {
      execute: savePersonaAuto,
      validate: validatePersonaAuto,
    },
    finance: {
      execute: savePersonaFinance,
      validate: validatePersonaFinance,
    },
    info: {
      execute: savePersonaInfo,
      validate: validatePersonaInfo,
    },
    residence: {
      execute: savePersonaResidence,
      validate: validatePersonaResidence,
    },
  }
  return await Promise.all(
    Object.keys(payload).map((k) => {
      if (
        postsMap[k] &&
        postsMap[k].validate &&
        postsMap[k].execute &&
        postsMap[k].validate(payload[k]).valid
      ) {
        return postsMap[k].execute({
          payload: payload[k],
        })
      }
    }),
  ).catch(createRequestException)
}
