<template>
  <Dialog v-bind="$attrs" :hide-close-button="true" @close="$emit('close')">
    <template #header-title>Deseja abandonar seu progresso?</template>
    <template #content>
      <div style="max-width: 540px">
        <slot
          >Ao clicar em sair, você perderá todo o progresso feito até
          agora.</slot
        >
      </div>
    </template>
    <template #footer>
      <Button type="primary" variation="negative" @click="$emit('exit')">
        Sim, desejo sair
      </Button>
      <Button type="ghost" @click="$emit('close')"> Cancelar </Button>
    </template>
  </Dialog>
</template>
<script>
import Dialog from '@/aasgard/components/modals/Dialog.vue'
import Button from '@/aasgard/components/buttons/Button.vue'
export default {
  name: 'ProgressWillBeLostModal',
  components: {
    Dialog,
    Button,
  },
  emits: ['close', 'exit'],
}
</script>
