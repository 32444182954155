<template>
  <UnloggedLayout>
    <FormContainer>
      <BankAccountForm
        v-if="!busy"
        ref="agilBankAccountForm"
        :data="cptdCurrentForm['bank_account'] || {}"
        title="Conta para depósito do empréstimo"
        @submit="submit"
      />
      <FullscreenLoading v-if="busy">
        <template #loading-message> Solicitando oferta... </template>
      </FullscreenLoading>
      <AgilNoResultFeedbackDialogContainer
        v-if="!busy && displayNoResultFeedbackDialog"
        :display="displayNoResultFeedbackDialog"
      />
      <OfferPartnerTimeoutFeedbackDialog
        v-if="!busy && displayOfferPartnerTimeoutFeedback"
        :display="displayOfferPartnerTimeoutFeedback"
        partner="AGIL"
      />
    </FormContainer>
  </UnloggedLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { waitForAResultWithOptions } from '~/helpers/offers'
import { OfferSubStatus, Partner, OfferStatus } from '~/data/common'
import FormContainer from '~/containers/forms/FormContainer.vue'
import FullscreenLoading from '~/components/commomComponents/loaders/FullscreenLoading.vue'
import OfferPartnerTimeoutFeedbackDialog from '~/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import AgilNoResultFeedbackDialogContainer from '~/containers/forms/loans/no-collateral/partners/agil/AgilNoResultFeedbackDialogContainer.vue'
import BankAccountForm from '~/components/forms/payment/BankAccountForm.vue'
export default {
  name: 'DynamicFormPage',
  components: {
    BankAccountForm,
    AgilNoResultFeedbackDialogContainer,
    UnloggedLayout,
    FormContainer,
    FullscreenLoading,
    OfferPartnerTimeoutFeedbackDialog,
  },
  layout: 'migration',
  data() {
    return {
      busy: false,
      displayOfferPartnerTimeoutFeedback: false,
      displayNoResultFeedbackDialog: false,
      formId: 'AGIL',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/gtNoCollateralOfferSelected',
      gtUser: 'user/user/gtUser',
      personaData: 'user/persona/personaData',
    }),
    cptdCurrentForm() {
      return this.gtDynamicForms[this.formId] || {}
    },
  },
  created() {
    this.actSetupForm(this.formId)
    if (!this.gtNoCollateralOfferSelected || !this.gtUser) {
      this.$router.push({
        path: '/ofertas',
      })
    }
    if (this.personaData?.finance?.account?.bank) {
      this.submit(this.personaData.finance.account)
    }
  },
  methods: {
    ...mapActions({
      actSelectNoCollateralOffer:
        'products/loans/no-collateral/no-collateral/actSelectNoCollateralOffer',
      createFeedback: 'utils/feedback/createFeedback',
      actGetNoCollateralOfferById:
        'products/loans/no-collateral/no-collateral/actGetNoCollateralOfferById',
      actSetNoCollateralOfferSelected:
        'products/loans/no-collateral/no-collateral/actSetNoCollateralOfferSelected',
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      actSetupForm: 'dynamic-forms/actSetupForm',
    }),
    async submit(data) {
      this.actUpdateForm({
        formId: this.formId,
        key: 'bank_account',
        value: data,
      })

      await this.selectOffer(data)
    },
    async selectOffer(bank_account) {
      const { offer_id } = this.gtNoCollateralOfferSelected

      this.busy = true
      const selectResponse = await this.actSelectNoCollateralOffer({
        offer: this.gtNoCollateralOfferSelected,
        selectPayload: {
          agil: {
            bank_account: {
              ...bank_account,
              number: bank_account.number.replace(/\D/g, ''),
            },
          },
        },
      })
      if (selectResponse?.status === 200) {
        let offer = null
        try {
          offer = await waitForAResultWithOptions(
            {
              partner: Partner.AGIL,
              status: (s) =>
                [
                  OfferStatus.ONGOING,
                  OfferStatus.REJECTED,
                  OfferStatus.FATAL_ERROR,
                ].includes(s),
              sub_status: (ss) =>
                [
                  OfferSubStatus.SELECTED,
                  OfferSubStatus.INFORMATION_INCORRECT,
                ].includes(ss),
            },
            async () => {
              return await this.actGetNoCollateralOfferById(offer_id)
            },
          )

          if (offer) {
            switch (offer.status) {
              case OfferStatus.ONGOING:
                this.actSetNoCollateralOfferSelected(offer)
                this.$router.push({
                  path: `/oferta/aceita/agil/${offer_id}`,
                })
                break
              case OfferStatus.FATAL_ERROR:
              case OfferStatus.REJECTED:
                this.displayNoResultFeedbackDialog = true
                break
              default:
                this.createFeedback({
                  text: 'Algo deu errado! Por favor tente novamente.',
                })
                break
            }
          } else {
            this.displayNoResultFeedbackDialog = true
          }
        } catch (e) {
          if (e?.message === 'MAX_RETRIES_REACHED') {
            this.displayOfferPartnerTimeoutFeedback = true
          } else {
            this.createFeedback({
              text: 'Algo deu errado! Por favor tente novamente.',
            })
          }
        }
      } else {
        this.createFeedback({
          text: 'Algo deu errado! Por favor tente novamente.',
        })
      }
      this.busy = false
    },
  },
}
</script>
<style lang="scss" scoped></style>
