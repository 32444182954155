<template>
  <ClearLayout>
    <SimulateContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const SimulateContainer = defineAsyncComponent(
  () => import('@/containers/simulation/simulationContainer.vue'),
)
export default {
  name: 'Simular',
  components: {
    ClearLayout,
    SimulateContainer,
  },
}
</script>
<style lang="scss"></style>
