import { InfobipEventRequest } from '@/data/events/eventapi'
import { postUserEvent } from '@/api/events/eventapi'

export enum InfobipEventNameEnum {
  PAGEVIEW = 'pageview',
  BUTTON_CLICK = 'buttonclick',
  MODAL_DISPLAYED = 'modaldisplayed',
  AFFILIATE_OFFER_SELECTED = 'affiliateofferselected',
  PRODUCT_OPT_IN = 'productoptin',
}

export interface InfobipEvent {
  event_name: InfobipEventNameEnum
  event_key_values: Record<string, string>
}

export const getPageViewEvent = (): InfobipEvent => {
  return {
    event_name: InfobipEventNameEnum.PAGEVIEW,
    event_key_values: {},
  }
}

export const getButtonClickEvent = (): InfobipEvent => {
  return {
    event_name: InfobipEventNameEnum.BUTTON_CLICK,
    event_key_values: {},
  }
}

export const getModalDisplayedEvent = (): InfobipEvent => {
  return {
    event_name: InfobipEventNameEnum.MODAL_DISPLAYED,
    event_key_values: {},
  }
}
export const getAffiliateOfferSelectedEvent = (): InfobipEvent => {
  return {
    event_name: InfobipEventNameEnum.AFFILIATE_OFFER_SELECTED,
    event_key_values: {},
  }
}

export const getProductOptInEvent = (): InfobipEvent => {
  return {
    event_name: InfobipEventNameEnum.PRODUCT_OPT_IN,
    event_key_values: {},
  }
}

export const setInfobipEventParams = (
  infobipEvent: InfobipEvent,
  params: object,
): void => {
  infobipEvent.event_key_values = {
    ...params,
    url: location.pathname,
  }
}

export const isInfobipPageviewEventAlreadyTriggered = (
  infobipEvent: InfobipEventRequest,
): boolean => {
  return (
    !!infobipEvent?.event_key_values?.url &&
    sessionStorage.getItem(
      `infobip_${InfobipEventNameEnum.PAGEVIEW}_${
        infobipEvent?.event_key_values?.url ?? ''
      }`,
    ) === 'true'
  )
}

const actions = {
  async actEmitInfobipPageview({ dispatch }) {
    const pageViewEvent = getPageViewEvent()
    setInfobipEventParams(pageViewEvent, {})
    if (isInfobipPageviewEventAlreadyTriggered(pageViewEvent)) {
      return
    }

    await dispatch('actEmitInfobipEvent', pageViewEvent)
    sessionStorage.setItem(pageViewEvent.event_key_values.url, 'true')
  },
  async actEmitInfobipButtonClickEvent({ dispatch }, params = {}) {
    const buttonClickEvent = getButtonClickEvent()
    setInfobipEventParams(buttonClickEvent, params)
    try {
      await dispatch('actEmitInfobipEvent', buttonClickEvent)
    } catch (e: any) {}
  },
  async actEmitInfobipModalDisplayedEvent({ dispatch }, params = {}) {
    const modalDisplayedEvent = getModalDisplayedEvent()
    setInfobipEventParams(modalDisplayedEvent, params)
    try {
      await dispatch('actEmitInfobipEvent', modalDisplayedEvent)
    } catch (e: any) {}
  },
  async actEmitInfobipAffiliateOfferSelected({ dispatch }, params = {}) {
    const affiliateOfferSelectedEvent = getAffiliateOfferSelectedEvent()
    setInfobipEventParams(affiliateOfferSelectedEvent, params)
    try {
      await dispatch('actEmitInfobipEvent', affiliateOfferSelectedEvent)
    } catch (e: any) {}
  },
  async actEmitInfobipProductOptInEvent({ dispatch }, params = {}) {
    const productOptInEvent = getProductOptInEvent()
    setInfobipEventParams(productOptInEvent, params)
    try {
      await dispatch('actEmitInfobipEvent', productOptInEvent)
    } catch (e: any) {}
  },
  async actEmitInfobipEvent(_, payload: InfobipEvent) {
    try {
      await postUserEvent(payload)
    } catch (e: any) {}
  },
}

export default { namespaced: true, actions }
