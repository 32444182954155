import { fetchPersona, updateAllPersonaFields } from '@/api/persona/persona'
import { sanitizePersona, validatePersona } from '@/helpers/persona'
import { trimAllObjectValues } from '@/helpers/globalFunctions'
import axios from 'axios'
// STATE

const state = (): any => ({
  persona: {},
  personaHasError: false,
})

// GETTERS

const getters = {
  personaData(state): any {
    return state.persona
  },
}

// MUTATIONS

const mutations = {
  UPDATE_PERSONA_FIELDS(state, payload): void {
    let newPersona = { ...state.persona }
    Object.keys(payload).forEach((key) => {
      newPersona = {
        ...newPersona,
        [key]: {
          ...state.persona[key],
          ...payload[key],
        },
      }
    })
    state.persona = { ...newPersona }
  },
  UPDATE_PERSONA(state, payload): void {
    state.persona = { ...payload }
  },
}

// ACTIONS

const actions = {
  async getPersona({ commit, dispatch, rootState }) {
    try {
      const response = await fetchPersona()
      const personaData = response?.data
      trimAllObjectValues(personaData)

      // we don't have a multiple cars experience yet, so we manipulate only 1 auto per persona for now
      if (personaData.auto?.length) {
        personaData.auto = personaData.auto[0]
      } else {
        personaData.auto = {}
      }

      const personaValidateResult = validatePersona(personaData)

      const personaHasError = Object.values(personaValidateResult).some(
        (v) => !v,
      )
      if (personaHasError) {
        sanitizePersona(
          {
            auto: !personaValidateResult.auto,
            finance: !personaValidateResult.finance,
            residence: !personaValidateResult.residence,
            info: !personaValidateResult.info,
            bankAccount: !personaValidateResult.bankAccount,
          },
          personaData,
        )
      }

      commit('UPDATE_PERSONA', personaData)

      const userName = rootState.user.user.user?.name
      const personaName = personaData?.info?.name

      const updateUserWithoutName = !userName && !!personaName
      if (updateUserWithoutName) {
        dispatch(
          'user/user/actPatchUser',
          { name: personaName },
          { root: true },
        )
      }

      const updatePersonaWithNameDifferentThanUser =
        !personaHasError &&
        !!personaName &&
        !!userName &&
        personaName !== userName

      if (updatePersonaWithNameDifferentThanUser) {
        dispatch('setPersona', {
          persona: {
            info: {
              ...personaData.info,
              name: rootState.user.user.user?.name,
            },
          },
        })
      }

      return response
    } catch (e: any) {
      return e
    }
  },
  // This actions recieve a piece of persona like { info } or if don't we just save the actual persona's state
  async savePersona({ commit, state }, { persona, useState = false }) {
    try {
      let newPersona
      if (useState) {
        newPersona = { ...state.persona }
      } else {
        newPersona = { ...persona }
      }
      const personaValidation = validatePersona(state.persona)
      if (state.personaHasError) {
        if (!personaValidation.auto) {
          delete newPersona.auto
        }
        if (!personaValidation.info) {
          delete newPersona.info
        }
        if (!personaValidation.residence) {
          delete newPersona.residence
        }
        if (!personaValidation.finance) {
          delete newPersona.finance
        }
        if (!personaValidation.bankAccount) {
          if (newPersona?.finance?.account) {
            delete newPersona.finance.account
          }
        }
      }

      if (Object.keys(newPersona).length > 0) {
        const promises = await updateAllPersonaFields({
          payload: newPersona,
        })

        promises.forEach((response) => {
          if (response.status === 200) {
            const data = response.data
            if (data) {
              if (data?.plate) {
                commit('UPDATE_PERSONA_FIELDS', { auto: data })
              }
            }
          }
        })
      }
    } catch (e: any) {
      return e
    }
  },
  async setPersona({ commit, state, dispatch }, { persona, save = true }) {
    try {
      let newPersona = {} as any
      Object.keys(persona).forEach((key) => {
        newPersona = {
          ...newPersona,
          [key]: {
            ...(state.persona[key] || {}),
            ...persona[key],
          },
        }
      })

      commit('UPDATE_PERSONA_FIELDS', newPersona)
      if (save) {
        await dispatch('savePersona', { persona: newPersona })
      }
    } catch (e: any) {
      return e
    }
  },
  async actGeneratePersona() {
    try {
      const formdata = new FormData()
      formdata.append('acao', 'gerar_pessoa')
      formdata.append('sexo', 'I')
      formdata.append('pontuacao', 'N')
      formdata.append('idade', '0')
      formdata.append('cep_estado', 'SP')
      formdata.append('txt_qtde', '1')
      formdata.append('cep_cidade', '')

      const { data } = await axios.post(
        'https://www.4devs.com.br/ferramentas_online.php',
        formdata,
      )

      let response = {}
      if (data && data[0]) {
        const personaGenerated = data[0]
        response = {
          persona: {
            info: {
              gender:
                personaGenerated.sexo.toLowerCase() === 'masculino'
                  ? 'MALE'
                  : 'FEMALE',
              name: personaGenerated.nome,
              educational_level: 'POSTGRADUATE',
              birth_city: personaGenerated.cidade,
              birth_date: personaGenerated.data_nasc,
              birth_state: personaGenerated.estado,
              marital_status: 'MARRIED',
              mothers_name: personaGenerated.mae,
              rg: {
                number: personaGenerated.rg,
                issuer_state: personaGenerated.estado,
              },
            },
            residence: {
              address: personaGenerated.endereco,
              city: personaGenerated.cidade,
              complement: '',
              district: personaGenerated.bairro,
              number: String(personaGenerated.numero),
              state: personaGenerated.estado,
              zip_code: personaGenerated.cep,
            },
            finance: {
              annotated: false,
              income_source: 'CLT',
              monthly_income: 7000,
            },
          },
          user: {
            email: personaGenerated.email,
            cpf: personaGenerated.cpf,
            mobile_phone: personaGenerated.celular,
            name: personaGenerated.nome,
          },
        }
      } else {
        response = { error: true }
      }
      return response
    } catch (e: any) {
      return { error: true }
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
