<template>
  <ClearLayout>
    <ContainerWrapper
      v-if="cptdCurrentForm && gtColdOfferSelected"
      class="LoanDataPage"
    >
      <NavBar
        :progress="{ step: 4, steps: 4, type: 'stepped' }"
        @back="$router.back()"
        @close="displayProgressWillBeLost = true"
      />
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="handleProgressWillBeLostClose"
        @exit="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <LoanForm
          :display-reason-list="false"
          :duration-list="[12, 24, 36, 48, 60]"
          :data="cptdCurrentForm['loan'] || {}"
          :min-amount="cptdAmountMin"
          :max-amount="cptdAmountMax"
          :min-duration="cptdDurationMin"
          :max-duration="cptdDurationMax"
          :monthly-interest-rate="cptdMonthlyInterestRate"
          :monthly-income="cptdMonthlyIncome"
          @submit="submit"
        >
          <template #extra-fields="{ validation }">
            <Checkbox
              id="bacenAccepted"
              key="bacenAccepted"
              label="Eu autorizo a Juros Baixos a compartilhar com o Banco BV todas as informações do crédito solicitado e que ambos possam entrar em contato comigo por qualquer meio de comunicação."
              validations="checkboxRequired"
              :validation="validation"
              :value="optIn"
              :checked="optIn"
              @input="optIn = $event"
            />
          </template>
        </LoanForm>
        <FullscreenLoading v-if="busy">
          <template #loading-message>
            Carregando detalhes da oferta...
            <p>Isso pode levar alguns minutos.</p>
          </template>
        </FullscreenLoading>
        <OfferDetailsModal
          v-if="!busy && displayOfferDetailsModal && offer"
          :display="displayOfferDetailsModal"
          :offer="offer"
          :busy="busy"
          :persisted="true"
          :is-fullscreen-mobile="true"
          @close="
            ;(displayProgressWillBeLost = true),
              (displayOfferDetailsModal = false)
          "
          @submit="selectOffer"
        />
        <AutoEquityOfferNoResultFeedbackDialogContainer
          v-if="!busy && displayAutoEquityNoResultFeedbackDialog"
          :display="displayAutoEquityNoResultFeedbackDialog"
        />
        <OfferPartnerTimeoutFeedbackDialog
          v-if="!busy && displayOfferPartnerTimeoutFeedbackDialog"
          :display="displayOfferPartnerTimeoutFeedbackDialog"
          partner="BV"
        />
        <UnexpectedPartnerOfferErrorFeedbackDialog
          :display="displayUnexpectedPartnerOfferErrorFeedbackDialog"
          :offer-id="offerErrorId"
          partner="BV"
          :step="currentStep"
        />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import {
  isOfferSelected,
  isOfferWaitingForAgreementAcceptance,
  isOfferWaitingForDocumentUploads,
  partnerHasFullIntegration,
  waitForAResultWithOptions,
} from '@/helpers/offers'
import { OfferStatus, OfferSubStatus } from '@/data/common'
import { UnexpectedPartnerOfferStepEnum } from '@/helpers/offer-types'
import { formatDateToNumericDate } from '@/helpers/date'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const Checkbox = defineAsyncComponent(
  () => import('@/components/ui/newComponents/Checkbox.vue'),
)
const LoanForm = defineAsyncComponent(
  () => import('@/components/forms/loans/LoanForm.vue'),
)
const UnexpectedPartnerOfferErrorFeedbackDialog = defineAsyncComponent(
  () =>
    import('@/components/loans/UnexpectedPartnerOfferErrorFeedbackDialog.vue'),
)
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const OfferPartnerTimeoutFeedbackDialog = defineAsyncComponent(
  () => import('@/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'),
)
const AutoEquityOfferNoResultFeedbackDialogContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/auto/AutoEquityNoResultFeedbackDialogContainer.vue'
    ),
)
const OfferDetailsModal = defineAsyncComponent(
  () => import('@/components/commomComponents/modals/OfferDetailsModal.vue'),
)
const FullscreenLoading = defineAsyncComponent(
  () => import('@/components/commomComponents/loaders/FullscreenLoading.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    ClearLayout,
    ProgressWillBeLostModal,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
    AutoEquityOfferNoResultFeedbackDialogContainer,
    Checkbox,
    LoanForm,
    UnexpectedPartnerOfferErrorFeedbackDialog,
    OfferPartnerTimeoutFeedbackDialog,
    OfferDetailsModal,
    FullscreenLoading,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      busy: false,
      optIn: false,
      bacenAccepted: false,
      whatsappAccepted: false,
      displayOfferDetailsModal: false,
      displayAutoEquityNoResultFeedbackDialog: false,
      formId: 'BV',
      offer: null,
      offerErrorId: '',
      displayOfferPartnerTimeoutFeedbackDialog: false,
      displayColdOfferValidatingFeedbackDialog: false,
      displayUnexpectedPartnerOfferErrorFeedbackDialog: false,
      displayCpfOrEmailHasAnActiveLeadDialog: false,
      currentStep: '',
      errorMsg: 'Algo deu errado! Por favor tente novamente.',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      personaData: 'user/persona/personaData',
      gtColdOfferSelected:
        'products/loans/auto-equity/cold-offer/gtColdOfferSelected',
      gtAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/gtAutoOfferSelected',
      gtCarModelsList: 'products/loans/auto-equity/car/gtCarModelsList',
      gtColdOfferSelectedRequest:
        'products/loans/auto-equity/cold-offer/gtColdOfferSelectedRequest',
    }),
    cptdCurrentForm() {
      if (this.gtDynamicForms && this.gtDynamicForms[this.formId]) {
        return this.gtDynamicForms[this.formId]
      }

      return {}
    },
    cptdMonthlyIncome() {
      return this.personaData?.finance?.monthly_income
    },
    cptdAmountMin() {
      return (
        this.gtAutoOfferSelected?.amount_min ??
        this.gtColdOfferSelected?.amount_min
      )
    },
    cptdAmountMax() {
      return (
        this.gtAutoOfferSelected?.amount_max ??
        this.gtColdOfferSelected?.amount_max
      )
    },
    cptdDurationMin() {
      return (
        this.gtAutoOfferSelected?.duration_min ??
        this.gtColdOfferSelected?.duration_min
      )
    },
    cptdDurationMax() {
      return (
        this.gtAutoOfferSelected?.duration_max ??
        this.gtColdOfferSelected?.duration_max
      )
    },
    cptdMonthlyInterestRate() {
      return (
        this.gtAutoOfferSelected?.interest_rate_monthly ??
        this.gtColdOfferSelected?.interest_monthly_min
      )
    },
  },
  created() {
    if (
      !this.gtColdOfferSelected ||
      !this.gtColdOfferSelectedRequest ||
      !this.cptdCurrentForm ||
      !this.cptdCurrentForm.auto
    ) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      createFeedback: 'utils/feedback/createFeedback',
      actGetAutoOfferById:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferById',
      actGetAutoOffersBySimulationId:
        'products/loans/auto-equity/auto-equity/actGetAutoOffersBySimulationId',
      actSelectAutoOffer:
        'products/loans/auto-equity/auto-equity/actSelectAutoOffer',
      actSetAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/actSetAutoOfferSelected',
      actSimulateAutoColdOfferV2:
        'products/loans/auto-equity/cold-offer/actSimulateAutoColdOfferV2',
    }),
    handleProgressWillBeLostClose() {
      this.displayProgressWillBeLost = false
      if (this.offer) {
        this.displayOfferDetailsModal = true
      }
    },
    async submit(loan) {
      this.actUpdateForm({
        formId: this.formId,
        key: 'loan',
        value: loan,
      })

      this.busy = true

      try {
        const payload = {
          amount: loan?.amount,
          duration: loan?.duration,
          email: this.gtUser?.email,
          mobile_phone: this.gtUser?.mobile_phone,
          cpf: this.gtUser?.cpf,
          info: this.personaData?.info,
          finance: this.personaData?.finance,
          residence: this.personaData?.residence,
          auto: {
            manufacturer: this.cptdCurrentForm?.auto?.brandName,
            model_year: this.cptdCurrentForm?.auto?.modelYear,
            plate: this.cptdCurrentForm?.auto?.plate,
            type: this.cptdCurrentForm?.auto?.vehicleType,
            year: this.cptdCurrentForm?.auto?.year,
            licensed_state: this.cptdCurrentForm?.auto?.state,
            model: this.cptdCurrentForm?.auto?.molicar?.modelVersion,
            accessoryPackage:
              this.cptdCurrentForm?.auto?.molicar?.accessoryPackage,
            fuel_type: this.cptdCurrentForm?.auto?.molicar?.fuelType,
            numberOfDoors: this.cptdCurrentForm?.auto?.molicar?.numberOfDoors,
            auction_vehicle:
              this.gtColdOfferSelectedRequest?.vehicle?.vehicle_is_auctioned,
            vehicle_owner:
              this.gtColdOfferSelectedRequest?.vehicle?.vehicle_owner,
            loans_paid: !this.gtColdOfferSelectedRequest?.vehicle?.vehicle_debt,
            outstanding_financing:
              this.gtColdOfferSelectedRequest?.vehicle?.outstanding_financing,
            user_selected_installment_date:
              this.gtColdOfferSelected?.first_installment_date_max ||
              formatDateToNumericDate(
                new Date(new Date().setDate(new Date().getDate() + 30)),
                'yyyy-mm-dd',
              ),
            is_not_a_taxi:
              !this.gtColdOfferSelectedRequest?.vehicle?.vehicle_is_taxi,
            is_not_adapted:
              !this.gtColdOfferSelectedRequest?.vehicle?.vehicle_is_adapted,
            fipe: {
              model: this.cptdCurrentForm?.auto?.fipe,
              fuel_type: this.cptdCurrentForm?.auto?.fuelType,
            },
          },
          partner_simulation_fields: {
            bv: {
              cold_offer_id: this.gtColdOfferSelected?.cold_offer_id,
            },
          },
        }
        const response = await this.actSimulateAutoColdOfferV2(payload)
        if (response?.status === 200 && response?.data?.bv?.id) {
          return await this.trackOfferChanges(response?.data?.bv?.id)
        } else {
          this.createFeedback({ text: this.errorMsg })
        }
      } catch (e) {
        this.createFeedback({ text: this.errorMsg })
      }
      this.busy = false
    },
    async trackOfferChanges(simulationId) {
      let offerResponse = null
      try {
        offerResponse = await waitForAResultWithOptions(
          {
            status: (s) =>
              [
                OfferStatus.PROPOSED,
                OfferStatus.FATAL_ERROR,
                OfferStatus.REJECTED,
              ].includes(s),
          },
          async () => {
            return await this.actGetAutoOffersBySimulationId({ simulationId })
          },
          10000,
          6,
          1,
        )

        if (offerResponse) {
          this.offer = offerResponse
          switch (this.offer.status) {
            case OfferStatus.PROPOSED:
              this.actSetAutoOfferSelected(this.offer)
              this.displayOfferDetailsModal = true
              break
            case OfferStatus.REJECTED:
              this.displayAutoEquityNoResultFeedbackDialog = true
              break
            case OfferStatus.FATAL_ERROR:
              this.currentStep =
                UnexpectedPartnerOfferStepEnum.COLD_OFFER_SIMULATE
              this.offerErrorId = this.gtColdOfferSelected.cold_offer_id
              this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
              break
            default:
              this.createFeedback({ text: this.errorMsg })
              break
          }
        } else {
          this.displayAutoEquityNoResultFeedbackDialog = true
        }
      } catch (e) {
        if (e?.message === 'MAX_RETRIES_REACHED') {
          this.displayAutoEquityNoResultFeedbackDialog = true
        } else {
          this.createFeedback({ text: this.errorMsg })
        }
      }
      this.busy = false
    },
    async selectOffer() {
      this.busy = true
      const selectResponse = await this.actSelectAutoOffer({
        offer: this.offer,
      })
      if (selectResponse?.status === 200) {
        return await this.waitCurrentOfferTurnsIntoSelected()
      } else {
        if (selectResponse === 'REDIRECTED_TO_PARTNER_PAGES') {
          this.actSetAutoOfferSelected(this.offer)
          return
        }
        this.createFeedback({ text: this.errorMsg })
      }
      this.busy = false
    },
    async waitCurrentOfferTurnsIntoSelected() {
      const { offer_id, partner } = this.offer
      let offerResponse = null
      this.busy = true
      try {
        offerResponse = await waitForAResultWithOptions(
          {
            status: (os) =>
              [OfferStatus.ONGOING, OfferStatus.FATAL_ERROR].includes(os),
            sub_status: (oss) =>
              [
                OfferSubStatus.SELECTED,
                OfferSubStatus.WAITING_FOR_DOCUMENT_UPLOADS,
                OfferSubStatus.WAITING_FOR_AGREEMENT_ACCEPTANCE,
                OfferSubStatus.INFORMATION_INCORRECT,
              ].includes(oss),
          },
          async () => {
            return await this.actGetAutoOfferById(offer_id)
          },
        )
        if (offerResponse?.offer_id) {
          this.offer = offerResponse
          this.actSetAutoOfferSelected(offerResponse)
          if (
            isOfferSelected(this.offer) ||
            isOfferWaitingForDocumentUploads(this.offer) ||
            isOfferWaitingForAgreementAcceptance(this.offer)
          ) {
            if (
              partnerHasFullIntegration({
                partner,
                product_type: 'auto',
              })
            ) {
              this.$router.push({
                path: `/contratacao/auto/${partner.toLowerCase()}/${offer_id}`,
              })
            } else {
              this.$router.push({
                path: `/contratacao/auto/${partner.toLowerCase()}/${offer_id}/redirecionamento`,
              })
            }
          } else {
            this.currentStep = UnexpectedPartnerOfferStepEnum.SELECT
            this.offerErrorId = this.offer?.offer_id
            this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
          }
        } else {
          this.displayOfferPartnerTimeoutFeedbackDialog = true
        }
      } catch (e) {
        if (e?.message === 'MAX_RETRIES_REACHED') {
          this.offerErrorId = this.offer?.offer_id
          this.currentStep = UnexpectedPartnerOfferStepEnum.SELECT
          this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
        } else {
          this.createFeedback({ text: this.errorMsg })
        }
      }

      this.busy = false
    },
  },
}
</script>
<style lang="scss"></style>
