<template>
  <ClearLayout>
    <ContainerWrapper v-if="cptdCurrentForm" class="LoanDataPage">
      <NavBar
        :progress="{ step: 2, steps: 4, type: 'stepped' }"
        @back="$router.back()"
        @close="displayProgressWillBeLost = true"
      />
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="displayProgressWillBeLost = false"
        @exit="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <InfocarAutoFormContainer
          :data="cptdCurrentForm['auto'] || {}"
          @submit="submit"
        />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const InfocarAutoFormContainer = defineAsyncComponent(
  () => import('@/containers/forms/persona/InfocarAutoFormContainer.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    InfocarAutoFormContainer,
    ClearLayout,
    ProgressWillBeLostModal,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      formId: 'CREDITAS',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      gtColdOfferSelected:
        'products/loans/auto-equity/cold-offer/gtColdOfferSelected',
    }),
    cptdCurrentForm() {
      return this.gtDynamicForms[this.formId] || {}
    },
  },
  created() {
    if (!this.gtColdOfferSelected || !this.gtUser || !this.cptdCurrentForm) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actUpdateForm: 'dynamic-forms/actUpdateForm',
    }),
    submit(data) {
      this.actUpdateForm({ formId: this.formId, value: data })
      this.$router.push({
        path: '/forms/emprestimo/auto/parceiros/creditas/simular/dados-ocupacao',
      })
    },
  },
}
</script>
<style lang="scss"></style>
