import { AxiosResponse } from 'axios'
import {
  CarModelRequestUriParams,
  CarModelResponse,
  CreditasCarModelResponse,
  FindPlateSummaryResponse,
} from '@/data/loans/auto/vehicle'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { createUrlQueryParams } from '@/helpers/url'

export const getCarModel = async (
  request: CarModelRequestUriParams,
  options: Record<string, string | boolean | number> | undefined,
): Promise<
  AxiosResponse<Array<CarModelResponse | CreditasCarModelResponse>>
> => {
  return await JurosBaixosSystemsAPI.get(
    `/car/${request.brand}/${request.year}/${
      request.fuel_type
    }${createUrlQueryParams(options)}`,
  ).catch(createRequestException)
}

export const getCarModelByPlate = async (
  plate: string,
): Promise<AxiosResponse<FindPlateSummaryResponse>> => {
  return await JurosBaixosSystemsAPI.get(`/car/plate/${plate}`).catch(
    createRequestException,
  )
}
