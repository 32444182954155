<template>
  <div class="FormContainer">
    <slot />
    <ProgressWillBeLostModal
      v-if="displayExitDialog"
      :display="displayExitDialog"
      @close="hideExitModal"
      @exit="redirectTo"
    />
  </div>
</template>
<script>
import ProgressWillBeLostModal from '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
export default {
  name: 'FormContainer',
  components: {
    ProgressWillBeLostModal,
  },
  props: {
    redirectToPath: { type: String, default: '/home' },
    emitOnExit: { type: Boolean, default: false },
  },
  emits: ['exit'],
  data() {
    return {
      displayExitDialog: false,
    }
  },
  beforeUnmount() {
    this.gEventBus.off('layout-unlogged:close-clicked')
    this.gEventBus.off('layout-unlogged:back-clicked')
  },
  created() {
    this.gEventBus.on('layout-unlogged:close-clicked', () => {
      this.displayExitModal()
    })

    this.gEventBus.on('layout-unlogged:back-clicked', () => {
      this.$router.back()
    })
  },
  methods: {
    redirectTo() {
      if (this.emitOnExit) {
        this.$emit('exit')
        return
      }
      this.$router.push({
        path: this.redirectToPath,
      })
    },
    displayExitModal() {
      this.displayExitDialog = true
    },
    hideExitModal() {
      this.displayExitDialog = false
    },
  },
}
</script>
<style lang="scss"></style>
