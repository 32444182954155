import BankAccountValidatorTool from '../../digitCalculator'
import utilities from '@/lib/accountValidator/bank-validator-js/utilities'

function checkSantanderBankAccount(number, number_digit, branch) {
  const branchPadded = utilities.padStart(branch, 4)
  if (branchPadded.length > 4) return false

  const numberPadded = utilities.padStart(number, 8)
  if ((numberPadded + number_digit).length > 9) return false

  const fullAccountNumberWithoutDigit = branch + numberPadded
  const digit_calculator = new BankAccountValidatorTool.Banks.DigitCalculator(
    fullAccountNumberWithoutDigit,
    [9, 7, 3, 1, 9, 7, 1, 3, 1, 9, 7, 3],
  )

  let digit = digit_calculator.sum_numbers()
  digit = String(digit)
  digit = parseInt(digit[digit.length - 1])

  if (digit !== 0) {
    return String(10 - digit) === number_digit
  }

  return String(digit) === number_digit
}

export default checkSantanderBankAccount
