<template>
  <ClearLayout>
    <CreditCardSimulateFormContainer />
  </ClearLayout>
</template>

<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const CreditCardSimulateFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/credit-card/CreditCardSimulateFormContainer.vue'
    ),
)
export default {
  name: 'SimularCreditCard',
  components: {
    CreditCardSimulateFormContainer,
    ClearLayout,
  },
}
</script>
<style lang="scss"></style>
