<template>
  <UnloggedLayout>
    <AutoColdOfferFormContainer
      @submit="$router.push('/simulando/emprestimo?product=auto')"
    />
  </UnloggedLayout>
</template>
<script>
import { defineAsyncComponent } from 'vue'

const AutoColdOfferFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/auto/cold-offer/AutoColdOfferFormContainer.vue'
    ),
)
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
export default {
  name: 'SimularAuto',
  components: {
    UnloggedLayout,
    AutoColdOfferFormContainer,
  },
}
</script>
<style lang="scss"></style>
