import { sentryMessage } from '@/helpers/sentry'

const genericRegexes = {
  address: {
    regex: /^[\p{L}\s\d.'/-]+$/giu,
    message:
      'Verifique os caracteres. É permitido apenas: letras, números, espaço, ponto, barra (/) e traço (-).',
  },
  city: {
    regex: /^[\p{L}\s.'/-]+$/giu,
    message:
      "Verifique os caracteres. É permitido apenas: letras, espaço, ponto, aspas simples ('), barra (/) e traço (-).",
  },
  name: {
    regex: /^[\p{L}\s'/-]+$/giu,
    message:
      "Verifique os caracteres. É permitido apenas: letras, espaço, aspas simples ('), barra (/) e traço (-).",
  },
  date: { regex: /\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])/g, message: '' },
  'date.pt-br': {
    regex: /([0-2]\d|3[01])\/([0]\d|1[0-2])\/\d{4}/g,
    message: 'Prencha uma data válida.',
  },
  email: {
    regex: /^([a-zA-Z0-9._+-]+)@([a-zA-Z0-9-]+)(\.[a-zA-Z]+)+$/gi,
    message:
      'Verifique os caracteres. É permitido  apenas: letras, números, ponto, traço (-), arroba (@), sinal de mais (+) e underline (_).',
  },
  campaigns: {
    regex: /^[a-zA-Z0-9._-]+$/gi,
    message:
      'Verifique os caracteres. É permitido apenas: letras, números, ponto, traço (-) e underline (_).',
  },
  rg: {
    regex: /^[a-zA-Z0-9]+$/gi,
    message: 'Verifique os caracteres. É permitido apenas: letras e números.',
  },
}
const PersonaResidenceRegexes = {
  'persona.residence.address': {
    regex: genericRegexes.address.regex,
    message: genericRegexes.address.message,
  },
  'persona.residence.city': { regex: genericRegexes.city.regex, message: '' },
  'persona.residence.complement': {
    regex: genericRegexes.address.regex,
    message: genericRegexes.address.message,
  },
  'persona.residence.district': {
    regex: genericRegexes.address.regex,
    message: genericRegexes.address.message,
  },
  'persona.residence.number': {
    regex: /^[a-zA-Z\d\s,.]{1,16}$/g,
    message:
      'Verifique os caracteres. É permitido apenas: letras, números, espaço, ponto, vírgula. Utilize o campo complemento para informações adicionais.',
  },
}
const BankAccountRegexes = {
  'bankaccount.number': {
    regex: /^[0-9-]{4,15}[X|P|0-9]{1}$/g,
    message:
      'Verifique os caracteres. É permitido apenas: números e letras X ou P.',
  },
  'bankaccount.number.sanitized': {
    regex: /^[0-9]{3,15}[X|P|0-9]{1}$/g,
    message:
      'Verifique os caracteres. É permitido apenas: números e letras X ou P.',
  },
  'bankaccount.bank': {
    regex: /^[0-9]{2,8}$/g,
    message: 'Verifique os caracteres. É permitido apenas: números.',
  },
  'bankaccount.branch': {
    regex: /^[0-9]{2,8}$/g,
    message: 'Verifique os caracteres. É permitido apenas: números.',
  },
}
const PersonaInfoRegexes = {
  'persona.info.name': {
    regex: genericRegexes.name.regex,
    message: genericRegexes.name.message,
  },
  'persona.info.mothers_name': {
    regex: genericRegexes.name.regex,
    message: genericRegexes.name.message,
  },
  'persona.info.birth_city': {
    regex: genericRegexes.city.regex,
    message: genericRegexes.city.message,
  },
  'persona.info.birth_date': {
    regex: genericRegexes.date.regex,
    message: genericRegexes.date.message,
  },
}

const allRegexes = {
  ...genericRegexes,
  ...PersonaResidenceRegexes,
  ...BankAccountRegexes,
  ...PersonaInfoRegexes,
}

const getValidatedValueFromRegexTest = (
  regex,
  value,
  sentryCaptureMessage?,
) => {
  if (!value) return ''
  if (!String(value).match(regex)) {
    if (sentryCaptureMessage) {
      sentryMessage(sentryCaptureMessage)
    }

    return ''
  }

  return value
}

export {
  PersonaResidenceRegexes,
  BankAccountRegexes,
  PersonaInfoRegexes,
  genericRegexes,
  allRegexes,
  getValidatedValueFromRegexTest,
}
