<template>
  <ClearLayout>
    <ContainerWrapper v-if="gtAutoOfferSelected" class="LoanDataPage">
      <NavBar
        :progress="{ step: 1, steps: 2, type: 'stepped' }"
        @back="$router.back()"
        @close="displayProgressWillBeLost = true"
      />
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="displayProgressWillBeLost = false"
        @exit="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <BvOccupationForm :data="cptdCurrentForm || {}" @submit="submit" />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const BvOccupationForm = defineAsyncComponent(
  () =>
    import('@/components/forms/loans/auto/partners/bv/BvOccupationForm.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    ClearLayout,
    ProgressWillBeLostModal,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
    BvOccupationForm,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      busy: false,
      displayOfferPartnerTimeoutFeedbackDialog: false,
      displayUnexpectedPartnerOfferErrorFeedbackDialog: false,
      errorMsg: 'Algo deu errado! Por favor tente novamente.',
      currentStep: '',
      formId: 'BV',
      offerErrorId: '',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      gtAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/gtAutoOfferSelected',
    }),
    cptdCurrentForm() {
      return this.gtDynamicForms[this.formId] || {}
    },
  },
  created() {
    this.actSetupForm(this.formId)

    if (!this.gtAutoOfferSelected || !this.gtUser) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actSetupForm: 'dynamic-forms/actSetupForm',
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      actSetAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/actSetAutoOfferSelected',
      actSelectAutoOffer:
        'products/loans/auto-equity/auto-equity/actSelectAutoOffer',
      actGetAutoOfferById:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferById',
    }),
    submit(data) {
      this.actUpdateForm({ formId: this.formId, value: data })
      this.$router.push({
        path: '/forms/emprestimo/auto/parceiros/bv/selecionar-oferta/dados-bancarios',
      })
    },
  },
}
</script>
<style lang="scss"></style>
