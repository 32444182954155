<template>
  <UnloggedLayout>
    <div>
      <FullscreenLoading v-if="busy">
        <template #loading-message>
          Carregando detalhes da oferta...
          <p>Isso pode levar alguns minutos.</p>
        </template>
      </FullscreenLoading>
      <HomeEquityNoResultFeedbackDialogContainer
        v-if="!busy && displayHomeEquityNoResultFeedbackDialog"
        :display="displayHomeEquityNoResultFeedbackDialog"
      />
      <OfferPartnerTimeoutFeedbackDialog
        v-if="!busy && displayOfferPartnerTimeoutFeedbackDialog"
        :display="displayOfferPartnerTimeoutFeedbackDialog"
        partner="CREDIHOME"
      />
      <UnexpectedPartnerOfferErrorFeedbackDialog
        :display="displayUnexpectedPartnerOfferErrorFeedbackDialog"
        :offer-id="offerErrorId"
        partner="CREDIHOME"
        :step="currentStep"
      />
    </div>
  </UnloggedLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { waitForAResultWithOptions } from '@/helpers/offers'
import { OfferStatus } from '@/data/common'
import { UnexpectedPartnerOfferStepEnum } from '@/helpers/offer-types'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const HomeEquityNoResultFeedbackDialogContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/home-equity/HomeEquityNoResultFeedbackDialogContainer.vue'
    ),
)
const UnexpectedPartnerOfferErrorFeedbackDialog = defineAsyncComponent(
  () =>
    import('@/components/loans/UnexpectedPartnerOfferErrorFeedbackDialog.vue'),
)
const OfferPartnerTimeoutFeedbackDialog = defineAsyncComponent(
  () => import('@/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'),
)
const FullscreenLoading = defineAsyncComponent(
  () => import('@/components/commomComponents/loaders/FullscreenLoading.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    UnloggedLayout,
    HomeEquityNoResultFeedbackDialogContainer,
    UnexpectedPartnerOfferErrorFeedbackDialog,
    OfferPartnerTimeoutFeedbackDialog,
    FullscreenLoading,
  },
  data() {
    return {
      busy: true,
      displayOfferDetailsModal: false,
      displayHomeEquityNoResultFeedbackDialog: false,
      formId: 'CREDIHOME',
      offer: null,
      offerErrorId: '',
      displayOfferPartnerTimeoutFeedbackDialog: false,
      displayUnexpectedPartnerOfferErrorFeedbackDialog: false,
      currentStep: '',
      errorMsg: 'Algo deu errado! Por favor tente novamente.',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      personaData: 'user/persona/personaData',
      gtHomeEquityColdOfferSelected:
        'products/loans/home-equity/cold-offer/gtHomeEquityColdOfferSelected',
      gtHomeEquityColdOfferRequest:
        'products/loans/home-equity/cold-offer/gtHomeEquityColdOfferRequest',
    }),
    cptdCurrentForm() {
      if (this.gtDynamicForms && this.gtDynamicForms[this.formId]) {
        return this.gtDynamicForms[this.formId]
      }

      return {}
    },
    cptdAmountMin() {
      return this.gtHomeEquityColdOfferSelected?.amount_min
    },
    cptdAmountMax() {
      return this.gtHomeEquityColdOfferSelected?.amount_max
    },
    cptdDurationMin() {
      return this.gtHomeEquityColdOfferSelected?.duration_min
    },
    cptdDurationMax() {
      return this.gtHomeEquityColdOfferSelected?.duration_max
    },
    cptdMonthlyInterestRate() {
      return this.gtHomeEquityColdOfferSelected?.interest_monthly_min
    },
    cptdMonthlyIncome() {
      return this.personaData?.finance?.monthly_income
    },
  },
  created() {
    if (
      !this.gtHomeEquityColdOfferSelected ||
      !this.gtHomeEquityColdOfferRequest ||
      !this.gtUser
    ) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  mounted() {
    setTimeout(() => {
      this.submit()
    }, 6000)
  },
  methods: {
    ...mapActions({
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      createFeedback: 'utils/feedback/createFeedback',
      actGetHomeEquityOfferById:
        'products/loans/home-equity/home-equity/actGetHomeEquityOfferById',
      actGetHomeEquityOffersBySimulationId:
        'products/loans/home-equity/home-equity/actGetHomeEquityOffersBySimulationId',
      actSetHomeEquityOfferSelected:
        'products/loans/home-equity/home-equity/actSetHomeEquityOfferSelected',
      actSimulateHomeEquityColdOffer:
        'products/loans/home-equity/cold-offer/actSimulateHomeEquityColdOffer',
      actSetRequestedHomeEquityColdOffers:
        'products/loans/home-equity/cold-offer/actSetRequestedHomeEquityColdOffers',
    }),
    async submit() {
      this.busy = true

      try {
        const response = await this.actSimulateHomeEquityColdOffer({
          name: this.gtUser?.name,
          email: this.gtUser?.email,
          mobile_phone: this.gtUser?.mobile_phone,
          requested_loan_amount:
            this.gtHomeEquityColdOfferRequest?.requested_loan_amount,
          loan_duration: this.gtHomeEquityColdOfferRequest?.loan_duration,
          cpf: this.gtUser?.cpf,
          birth_date:
            this.personaData?.info?.birth_date ??
            this.gtHomeEquityColdOfferRequest?.birth_date,
          property_value: this.gtHomeEquityColdOfferRequest?.collateral_value,
          mortgage_value:
            this.gtHomeEquityColdOfferRequest?.requested_loan_amount,
          credihome: {},
        })
        if (response?.status === 200 && response?.data?.simulation_id) {
          return await this.trackOfferChanges(response?.data?.simulation_id)
        } else {
          this.createFeedback({ text: this.errorMsg })
        }
      } catch (e) {
        this.createFeedback({ text: this.errorMsg })
      }
      this.busy = false
    },
    async trackOfferChanges(simulationId) {
      if (
        import.meta.env.VITE_FEATURE_NEW_HOME_EQUITY_FULL_INTEGRATION !== 'true'
      ) {
        this.actSetHomeEquityOfferSelected(this.offer)
        this.actSetRequestedHomeEquityColdOffers(
          this.gtHomeEquityColdOfferSelected?.cold_offer_id,
        )
        this.$router.push({
          path: `/oferta/aceita/credihome/${this.gtHomeEquityColdOfferSelected?.cold_offer_id}`,
        })
        return
      }

      let offerResponse = null
      try {
        offerResponse = await waitForAResultWithOptions(
          {
            status: (s) =>
              [
                OfferStatus.PROPOSED,
                OfferStatus.VALIDATING,
                OfferStatus.FATAL_ERROR,
                OfferStatus.REJECTED,
              ].includes(s),
          },
          async () => {
            return await this.actGetHomeEquityOffersBySimulationId({
              simulationId,
            })
          },
          10000,
          6,
          1,
        )

        if (offerResponse) {
          this.offer = offerResponse
          switch (this.offer.status) {
            case OfferStatus.PROPOSED:
            case OfferStatus.VALIDATING:
              this.actSetHomeEquityOfferSelected(this.offer)
              this.$router.push({
                path: `/oferta/aceita/credihome/${this.offer?.offer_id}`,
              })
              break
            case OfferStatus.REJECTED:
              this.displayHomeEquityNoResultFeedbackDialog = true
              break
            case OfferStatus.FATAL_ERROR:
              this.currentStep =
                UnexpectedPartnerOfferStepEnum.COLD_OFFER_SIMULATE
              this.offerErrorId =
                this.gtHomeEquityColdOfferSelected.cold_offer_id
              this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
              break
            default:
              this.createFeedback({ text: this.errorMsg })
              break
          }
        } else {
          this.displayHomeEquityNoResultFeedbackDialog = true
        }
      } catch (e) {
        if (e?.message === 'MAX_RETRIES_REACHED') {
          this.displayHomeEquityNoResultFeedbackDialog = true
        } else {
          this.createFeedback({ text: this.errorMsg })
        }
      }
      this.busy = false
    },
    onCloseOfferDetails() {
      this.gEventBus.emit('layout-unlogged:close-clicked')
    },
  },
}
</script>

<style lang="scss"></style>
