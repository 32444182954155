import { DocumentType } from '@/data/common'

function formatDocumentName(value) {
  switch (value) {
    case DocumentType.PROOF_OF_ID_FRONT:
      return 'proof_of_id'
    case DocumentType.RG_ID_FRONT:
      return 'proof_of_id'
    case DocumentType.CNH_ID_FRONT:
      return 'proof_of_id'
    case DocumentType.RNE_ID_FRONT:
      return 'proof_of_id'
    case DocumentType.PROOF_OF_ID_BACK:
      return 'proof_of_id_back'
    case DocumentType.RG_ID_BACK:
      return 'proof_of_id_back'
    case DocumentType.CNH_ID_BACK:
      return 'proof_of_id_back'
    case DocumentType.RNE_ID_BACK:
      return 'proof_of_id_back'
    case DocumentType.HOLERITE_PROOF_OF_INCOME:
      return 'proof_of_income'
    case DocumentType.BANK_STATEMENT_PROOF_OF_INCOME:
      return 'proof_of_income'
    case DocumentType.FGTS_PROOF_OF_INCOME:
      return 'proof_of_income'
    case DocumentType.PROOF_OF_INCOME:
      return 'proof_of_income'
    case DocumentType.INCOME_STATEMENT_PROOF_OF_INCOME:
      return 'proof_of_income'
    case DocumentType.PROOF_OF_RESIDENCE:
      return 'proof_of_residence'
    case DocumentType.SELFIE:
      return 'selfie'
    case DocumentType.CRLV:
      return 'crlv'
    case DocumentType.VEHICLE_REAR:
      return 'vehicle_rear'
    case DocumentType.VEHICLE_ENGINE:
      return 'vehicle_engine'
    default:
      return null
  }
}

export default formatDocumentName
