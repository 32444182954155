<template>
  <ClearLayout>
    <IdAutoDocument />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const IdAutoDocument = defineAsyncComponent(
  () => import('@/containers/newAppDocs/IdAutoDocument.vue'),
)
export default {
  name: 'IdAutoDocumentPage',
  components: {
    ClearLayout,
    IdAutoDocument,
  },
}
</script>
<style lang="scss"></style>
