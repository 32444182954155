export const sanitizeAddress = (address = '') =>
  address
    .replace(/\s\((.*?)\)/g, '') // crop patterns like:  (something)
    .replace(/\((.*?)\)/g, '') // crop patterns like:  (something)
    .replace(/(\s-\s).*/g, '') // crop patterns like:  - something
    .replace(/(,\s).*/g, '') // crop patterns like: , something

export const sanitizeAddressNumber = (numberStr = '') => {
  if (/^[0-9]+$/gi.test(numberStr)) {
    return numberStr
  }

  return '0'
}
