const BankAccountValidatorTool = {
  Banks: (function () {
    function DigitCalculator(fullaccount, rules) {
      // eslint-disable-next-line
      // @ts-ignore
      this.stringNumber = fullaccount
      // eslint-disable-next-line
      // @ts-ignore
      this.weights = rules
    }

    DigitCalculator.prototype = {
      sum_numbers(sumDigitsWhenGreaterThanNine = false) {
        let finalValue = 0

        this.stringNumber.split('').forEach((char, index) => {
          let result = parseInt(char) * this.weights[index]

          if (sumDigitsWhenGreaterThanNine && result > 9) {
            result =
              parseInt(result.toString()[0]) + parseInt(result.toString()[1])
          }

          finalValue += result
        })

        return finalValue
      },

      mod(modNumber, sumDigitsWhenGreaterThanNine = false) {
        return (
          modNumber -
          (this.sum_numbers(sumDigitsWhenGreaterThanNine) % modNumber)
        )
      },
    }

    return { DigitCalculator }
  })(),
}

export default BankAccountValidatorTool
