import * as Sentry from '@sentry/browser'
const createRequestException = (e) => {
  const status = e?.response?.status
  // TIP: For non expected Not Found Stuff put the triggers on actions try/catches :)
  if (status && (status >= 500 || [400].includes(status))) {
    if (!e?.response?.data?.message?.toLowerCase().includes('already exists')) {
      Sentry.captureException(e)
    }
  }
  throw e
}

export { createRequestException }
