<template>
  <ClearLayout>
    <SimulateContainer :hide-products="cptdHideProducts" />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const SimulateContainer = defineAsyncComponent(
  () => import('@/containers/simulation/simulationContainer.vue'),
)
export default {
  name: 'Simular',
  components: {
    ClearLayout,
    SimulateContainer,
  },
  computed: {
    cptdHideProducts() {
      return ['fgts', 'credit_card', 'insurances']
    },
  },
}
</script>
<style lang="scss"></style>
