<template>
  <ClearLayout>
    <ContainerWrapper v-if="cptdCurrentForm" class="LoanDataPage">
      <NavBar
        :progress="{ step: 4, steps: 4, type: 'stepped' }"
        @back="$router.back()"
        @close="displayProgressWillBeLost = true"
      />
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="handleProgressWillBeLostClose"
        @exit="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <LoanForm
          :display-reason-list="false"
          :duration-list="[12, 24, 36, 48, 60]"
          :data="cptdCurrentForm['loan'] || {}"
          :min-amount="cptdAmountMin"
          :max-amount="cptdAmountMax"
          :min-duration="cptdDurationMin"
          :max-duration="cptdDurationMax"
          :monthly-interest-rate="cptdMonthlyInterestRate"
          :monthly-income="cptdMonthlyIncome"
          @submit="submit"
        />
        <FullscreenLoading v-if="busy">
          <template #loading-message>
            Carregando detalhes da oferta...
            <p>Isso pode levar alguns minutos.</p>
          </template>
        </FullscreenLoading>
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { orderBy as _orderBy } from 'lodash'
import { Partner } from '@/data/common'
import { formatDateToNumericDate } from '@/helpers/date'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const LoanForm = defineAsyncComponent(
  () => import('@/components/forms/loans/LoanForm.vue'),
)
const FullscreenLoading = defineAsyncComponent(
  () => import('@/components/commomComponents/loaders/FullscreenLoading.vue'),
)
import { CreditasAccountOwnership } from '@/data/loans/auto/partners/creditas'
import { defineAsyncComponent } from 'vue'
export default {
  name: 'DynamicFormPage',
  components: {
    ClearLayout,
    ProgressWillBeLostModal,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
    LoanForm,
    FullscreenLoading,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      busy: false,
      bacenAccepted: false,
      whatsappAccepted: false,
      formId: 'GENERIC_AUTO_EQUITY',
      offer: null,
      errorMsg: 'Algo deu errado! Por favor tente novamente.',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      personaData: 'user/persona/personaData',
      gtAutoEquityLatestColdOfferRequest:
        'products/loans/auto-equity/cold-offer/gtAutoEquityLatestColdOfferRequest',
      gtAutoColdOffers:
        'products/loans/auto-equity/cold-offer/gtAutoColdOffers',
    }),
    cptdCurrentForm() {
      if (this.gtDynamicForms && this.gtDynamicForms[this.formId]) {
        return this.gtDynamicForms[this.formId]
      }

      return {}
    },
    cptdBvOffer() {
      return this.gtAutoColdOffers.find((o) => o.partner === Partner.BV)
    },
    cptdCreditasOffer() {
      return this.gtAutoColdOffers.find((o) => o.partner === Partner.CREDITAS)
    },
    cptdMonthlyIncome() {
      return this.personaData?.finance?.monthly_income ?? 0
    },
    cptdAmountMin() {
      return _orderBy(
        [this.cptdBvOffer, this.cptdCreditasOffer],
        'amount_min',
        'asc',
      )[0]?.amount_min
    },
    cptdAmountMax() {
      return _orderBy(
        [this.cptdBvOffer, this.cptdCreditasOffer],
        'amount_min',
        'desc',
      )[0]?.amount_max
    },
    cptdDurationMin() {
      return _orderBy(
        [this.cptdBvOffer, this.cptdCreditasOffer],
        'amount_min',
        'asc',
      )[0]?.duration_min
    },
    cptdDurationMax() {
      return _orderBy(
        [this.cptdBvOffer, this.cptdCreditasOffer],
        'amount_min',
        'desc',
      )[0]?.duration_max
    },
    cptdMonthlyInterestRate() {
      return _orderBy(
        [this.cptdBvOffer, this.cptdCreditasOffer],
        'amount_min',
        'asc',
      )[0]?.interest_monthly_min
    },
  },
  created() {
    if (
      !this.gtAutoColdOffers.length ||
      !this.gtUser ||
      !this.cptdCurrentForm ||
      !this.cptdCurrentForm.auto ||
      !this.gtAutoEquityLatestColdOfferRequest
    ) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  mounted() {
    if (!this.cptdBvOffer && !this.cptdCreditasOffer) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      createFeedback: 'utils/feedback/createFeedback',
      actGetAutoOfferById:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferById',
      actGetAutoOffersBySimulationId:
        'products/loans/auto-equity/auto-equity/actGetAutoOffersBySimulationId',
      actSelectAutoOffer:
        'products/loans/auto-equity/auto-equity/actSelectAutoOffer',
      actSetAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/actSetAutoOfferSelected',
      actSimulateAutoColdOfferV2:
        'products/loans/auto-equity/cold-offer/actSimulateAutoColdOfferV2',
    }),
    handleProgressWillBeLostClose() {
      this.displayProgressWillBeLost = false
    },
    async submit(loan) {
      await this.actUpdateForm({
        formId: this.formId,
        key: 'loan',
        value: loan,
      })

      this.busy = true

      const response = await this.simulatePartners(loan)
      if (!response) {
        this.createFeedback({
          text: 'Algo deu errado! Por favor tente novamente.',
        })
        this.busy = false
      } else {
        setTimeout(() => {
          this.$router.push({
            path: '/ofertas',
            query: {
              last_product_simulation: 'auto-equity',
            },
          })
        }, 30000)
      }
    },
    async simulatePartners(loan) {
      const bv = {
        cold_offer_id: this.cptdBvOffer?.cold_offer_id,
      }

      const creditas = {
        cold_offer_id: this.cptdCreditasOffer?.cold_offer_id,
        bacenAccepted: true,
        whatsappAccepted: true,
        hasJobSince: this.cptdCurrentForm?.hasJobSince,
        occupation: this.cptdCurrentForm?.occupation,
        bankAccountType: CreditasAccountOwnership.INDIVIDUAL,
      }

      let amount, duration
      if (loan?.amount < this.cptdAmountMin) {
        amount = this.cptdDurationMin
      } else if (loan?.amount > this.cptdAmountMax) {
        amount = this.cptdDurationMax
      } else {
        amount = loan?.amount
      }

      if (loan?.duration < this.cptdDurationMin) {
        duration = this.cptdDurationMin
      } else if (loan?.duration > this.cptdDurationMax) {
        duration = this.cptdDurationMax
      } else {
        duration = loan?.duration
      }

      const response = await this.actSimulateAutoColdOfferV2({
        amount,
        duration,
        email: this.gtUser?.email,
        mobile_phone: this.gtUser?.mobile_phone,
        cpf: this.gtUser?.cpf,
        info: this.personaData?.info,
        finance: this.personaData?.finance,
        residence: this.personaData?.residence,
        auto: {
          manufacturer: this.cptdCurrentForm?.auto?.brandName,
          model_year: this.cptdCurrentForm?.auto?.modelYear,
          plate: this.cptdCurrentForm?.auto?.plate,
          type: this.cptdCurrentForm?.auto?.vehicleType,
          year: this.cptdCurrentForm?.auto?.year,
          licensed_state: this.cptdCurrentForm?.auto?.state,
          model: this.cptdCurrentForm?.auto?.molicar?.modelVersion,
          accessoryPackage:
            this.cptdCurrentForm?.auto?.molicar?.accessoryPackage,
          fuel_type: this.cptdCurrentForm?.auto?.molicar?.fuelType,
          numberOfDoors: this.cptdCurrentForm?.auto?.molicar?.numberOfDoors,
          auction_vehicle:
            this.gtAutoEquityLatestColdOfferRequest?.vehicle
              ?.vehicle_is_auctioned,
          vehicle_owner:
            this.gtAutoEquityLatestColdOfferRequest?.vehicle?.vehicle_owner,
          loans_paid:
            !this.gtAutoEquityLatestColdOfferRequest?.vehicle?.vehicle_debt,
          outstanding_financing:
            this.gtAutoEquityLatestColdOfferRequest?.vehicle
              ?.outstanding_financing,
          user_selected_installment_date: formatDateToNumericDate(
            new Date(new Date().setDate(new Date().getDate() + 30)),
            'yyyy-mm-dd',
          ),
          is_not_a_taxi:
            !this.gtAutoEquityLatestColdOfferRequest?.vehicle?.vehicle_is_taxi,
          is_not_adapted:
            !this.gtAutoEquityLatestColdOfferRequest?.vehicle
              ?.vehicle_is_adapted,
          fipe: {
            model: this.cptdCurrentForm?.auto?.fipe,
            fuel_type: this.cptdCurrentForm?.auto?.fuelType,
          },
        },
        partner_simulation_fields: {
          ...(bv ? { bv } : {}),
          ...(creditas ? { creditas } : {}),
        },
      })

      return response?.status === 200
    },
  },
}
</script>
<style lang="scss"></style>
