import formatDocumentName from '@/helpers/filterDocuments'
import {
  deleteOfferDocument,
  deletePersonaDocument,
  getOfferDocument,
  getPersonaDocument,
  getPersonaDocuments,
  actRefreshOfferDocsLinks,
  retrieveOfferDocsEntries,
} from '@/api/appdocs/docs'

// STATE
const state = (): any => ({
  // documents for a specific offer
  offerDocuments: undefined,
  // all user documents
  personaDocuments: undefined,
  // data about a specific document
  currentDocument: undefined,
  offerDocumentsMappedByType: undefined,
})

// GETTERS
const getters = {
  gtOfferDocumentsMappedByType(state): any {
    return state.offerDocumentsMappedByType?.reduce((map, obj) => {
      map[obj.name] = obj
      return map
    }, {})
  },
  gtOfferDocuments(state): any {
    return state.offerDocuments
  },
  gtCurrentDocumentData(state): any {
    return state.currentDocument
  },
}

// MUTATIONS
const mutations = {
  UPDATE_PERSONA_DOCS(state, payload): void {
    state.personaDocuments = payload
  },
  UPDATE_OFFER_DOCS(state, payload): void {
    state.offerDocuments = payload
    state.offerDocumentsMappedByType = payload?.map((v) => {
      return {
        ...v,
        name: formatDocumentName(v.type),
      }
    })
  },
  UPDATE_DOC(state, payload): void {
    state.currentDocument = payload
  },
}

// ACTIONS
const actions = {
  async actPutDocS3({ dispatch }, payload) {
    const errorMessage = {
      text: 'Oops! algo deu errado. Tente novamente!',
    }
    try {
      const response = await fetch(payload.url, {
        method: 'PUT',
        headers: {
          'Content-Type': payload.file.type,
        },
        body: payload.file,
      })
      const { status } = response
      if (status !== 200) {
        dispatch('utils/feedback/createFeedback', errorMessage, {
          root: true,
        })
      }
      return response
    } catch (error) {
      dispatch('utils/feedback/createFeedback', errorMessage, {
        root: true,
      })
    }
  },
  // send documents
  async actRetrieveOfferDocs(
    { commit, dispatch },
    { offer_id, product, payload },
  ) {
    try {
      const response = await retrieveOfferDocsEntries({
        payload,
        offer_id,
        product,
      })
      commit('UPDATE_OFFER_DOCS', response?.data)
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },

  async actRefreshOfferDocsLinks(
    { commit, dispatch, state },
    { offer_id, product },
  ) {
    try {
      const response = await actRefreshOfferDocsLinks({
        offer_id,
        product,
      })
      const { data } = response
      if (data) {
        commit('UPDATE_OFFER_DOCS', data)

        const currentDoc = state.currentDocument
        if (currentDoc) {
          const newDoc = data.find(
            (doc) =>
              !!doc?.id &&
              !!currentDoc?.id &&
              doc.id === state.currentDocument?.id,
          )
          if (newDoc) {
            commit('UPDATE_DOC', newDoc)
          }
        }
      }
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },
  // get specify document

  async actGetDocument({ commit, dispatch }, { offer_id, product, doc_id }) {
    try {
      const response = await getOfferDocument({
        offer_id,
        product,
        doc_id,
      })
      commit('UPDATE_DOC', response?.data)
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },

  // delete a document
  async deleteDocument({ dispatch }, { offer_id, product, doc_id }) {
    try {
      return await deleteOfferDocument({ offer_id, product, doc_id })
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },

  // get persona docs
  async personaDocs({ commit, dispatch }) {
    try {
      const response = await getPersonaDocuments()
      commit('UPDATE_PERSONA_DOCS', response?.data)
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },

  // get specific doc from persona
  async personaDocument({ dispatch }, doc_id) {
    try {
      return await getPersonaDocument(doc_id)
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },

  // get specific doc
  async deletePersonaDoc({ dispatch }, doc_id) {
    try {
      return await deletePersonaDocument(doc_id)
    } catch (e: any) {
      dispatch(
        'utils/feedback/createFeedback',
        {
          text: 'Oops! algo deu errado.',
        },
        { root: true },
      )

      return e?.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
