<template>
  <ClearLayout>
    <ProofOfAddressContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProofOfAddressContainer = defineAsyncComponent(
  () => import('@/containers/newAppDocs/ProofOfAddressContainer.vue'),
)
export default {
  name: 'ProofOfAddress',
  components: {
    ClearLayout,
    ProofOfAddressContainer,
  },
}
</script>
<style lang="scss"></style>
