<template>
  <ClearLayout>
    <ContainerWrapper>
      <NavBar
        title="Ótimo! Para simular, precisamos saber um pouco mais sobre você"
        subtitle="Isso é importante para verificarmos se você e seu veículo atendem aos requisitos necessários."
        :extended="true"
        @back="$router.back()"
        @close="$router.push('/ofertas')"
      />
      <ContentContainerWrapper>
        <Button type="primary" @click="start">Continuar</Button>
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import Button from '@/aasgard/components/buttons/Button.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
export default {
  name: 'DynamicFormPage',
  components: {
    ClearLayout,
    ContentContainerWrapper,
    ContainerWrapper,
    NavBar,
    Button,
  },
  data() {
    return {
      formId: 'BV',
    }
  },
  computed: {
    ...mapGetters({
      gtUser: 'user/user/gtUser',
      gtColdOfferSelected:
        'products/loans/auto-equity/cold-offer/gtColdOfferSelected',
    }),
  },
  created() {
    this.actSetupForm(this.formId)

    if (!this.gtColdOfferSelected) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actSetupForm: 'dynamic-forms/actSetupForm',
    }),
    start() {
      this.$router.push({
        path: `/forms/emprestimo/auto/parceiros/bv/simular/verificar-dados`,
      })
    },
  },
}
</script>
<style lang="scss"></style>
