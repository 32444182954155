<template>
  <div class="NavBar" :class="theme">
    <div class="header">
      <BaseIcon
        v-if="displayBackButton"
        class="back-icon"
        name="arrow-back-mono"
        @click="$emit('back')"
      />
      <div v-if="cptdDisplayProgress" class="progress-wrapper">
        <ProgressBar v-if="cptdDisplayProgressBar" :progress="progress" />
        <ProgressBarStepped
          v-if="cptdDisplaySteppedProgressBar"
          :step="progress.step"
          :steps="progress.steps"
        />
      </div>
      <h1 v-if="cptdDisplayTitle && !extended" class="title-inline">
        {{ title }}
      </h1>
      <slot name="actions">
        <BaseIcon
          v-if="displayCloseButton"
          class="close-icon"
          name="close-mono"
          @click="$emit('close')"
        />
      </slot>
    </div>
    <div v-if="(cptdDisplayTitle && extended) || subtitle" class="title-area">
      <h1 v-if="cptdDisplayTitle && extended" class="title-block">
        {{ title }}
      </h1>
      <h2 v-if="subtitle" class="subtitle">{{ subtitle }}</h2>
    </div>
  </div>
</template>

<script>
import ProgressBarStepped from '@/aasgard/components/feedback/ProgressBarStepped.vue'
import ProgressBar from '@/aasgard/components/feedback/ProgressBar.vue'
import BaseIcon from '~/aasgard/components/base/BaseIcon.vue'
export default {
  name: 'NavBar',
  components: {
    ProgressBarStepped,
    ProgressBar,
    BaseIcon,
  },
  props: {
    progress: { type: [Number, Object], default: 0 },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    theme: { type: String, default: '' },
    extended: { type: Boolean, default: false },
    displayBackButton: { type: Boolean, default: true },
    displayCloseButton: { type: Boolean, default: true },
  },
  emits: ['back', 'close'],
  computed: {
    cptdDisplayProgress() {
      return this.progress !== null
    },
    cptdDisplayProgressBar() {
      return typeof progress === 'number'
    },
    cptdDisplaySteppedProgressBar() {
      return (
        typeof this.progress === 'object' &&
        this.progress.type === 'stepped' &&
        typeof this.progress.step === 'number' &&
        typeof this.progress.steps === 'number'
      )
    },
    cptdDisplayTitle() {
      return this.title && (!this.cptdDisplayProgress || this.extended)
    },
  },
}
</script>

<style lang="scss">
.NavBar {
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 16px;
    position: relative;

    & > .title-inline {
      @include subtitle-1;
      color: $color-black-high;
      margin: 0;
    }

    & > .progress-wrapper {
      width: 100%;
      margin-left: auto;
      padding: 0 24px;
    }

    & > .BaseIcon {
      cursor: pointer;
    }
  }

  & > .title-area {
    padding: 0 16px 16px 16px;

    & > .title-block {
      @include headline-5;
      color: $color-black-high;
      margin: 0;
    }

    & > .subtitle {
      @include body-1;
      color: $color-black-medium;
      margin: 8px 0 0 0;
    }
  }
}
.NavBar.white {
  & > .header {
    & > .back-icon {
      @include setSVGMonoColor($color-white-high);
    }
    & > .title-inline {
      color: $color-white-high;
    }
    & > .actions {
      & > .BaseIcon {
        @include setSVGMonoColor($color-white-high);
      }
    }
  }

  & > .title-area {
    & > .title-block {
      color: $color-white-high;
    }

    & > .subtitle {
      color: $color-white-medium;
    }
  }
}
</style>
