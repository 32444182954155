<template>
  <ClearLayout>
    <HCredQuestionsContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const HCredQuestionsContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/loans/credit-card-equity/offer/affiliates/hcred/HCredQuestionsContainer.vue'
    ),
)
export default {
  name: 'HCredSimularPage',
  components: {
    ClearLayout,
    HCredQuestionsContainer,
  },
}
</script>

<style lang="scss"></style>
