export default () =>
  [
    {
      code: '001',
      name: 'Banco do Brasil S.A.',
      major: true,
      display: true,
    },
    {
      code: '003',
      name: 'BANCO DA AMAZONIA S.A.',
      major: false,
      display: true,
    },
    {
      code: '004',
      name: 'Banco do Nordeste do Brasil S.A.',
      major: false,
      display: true,
    },
    {
      code: '007',
      name: 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL',
      major: false,
      display: true,
    },
    {
      code: '010',
      name: 'CREDICOAMO CREDITO RURAL COOPERATIVA',
      major: false,
      display: true,
    },
    {
      code: '011',
      name: 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
      major: false,
      display: true,
    },
    {
      code: '012',
      name: 'Banco Inbursa S.A.',
      major: false,
      display: true,
    },
    {
      code: '014',
      name: 'STATE STREET BRASIL S.A. ? BANCO COMERCIAL',
      major: false,
      display: true,
    },
    {
      code: '015',
      name: 'UBS Brasil Corretora de Câmbio',
      major: false,
      display: true,
    },
    {
      code: '016',
      name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
      major: false,
      display: true,
    },
    {
      code: '017',
      name: 'BNY Mellon Banco S.A.',
      major: false,
      display: true,
    },
    {
      code: '018',
      name: 'Banco Tricury S.A.',
      major: false,
      display: false,
    },
    {
      code: '021',
      name: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO',
      major: false,
      display: true,
    },
    {
      code: '024',
      name: 'Banco Bandepe S.A.',
      major: false,
      display: true,
    },
    {
      code: '025',
      name: 'Banco Alfa S.A.',
      major: false,
      display: true,
    },
    {
      code: '029',
      name: 'Banco Itaú Consignado S.A.',
      major: false,
      display: true,
    },
    {
      code: '033',
      name: 'BANCO SANTANDER (BRASIL) S.A.',
      major: true,
      display: true,
    },
    {
      code: '036',
      name: 'Banco Bradesco BBI S.A.',
      major: false,
      display: true,
    },
    {
      code: '037',
      name: 'Banco do Estado do Pará S.A.',
      major: false,
      display: true,
    },
    {
      code: '040',
      name: 'Banco Cargill S.A.',
      major: false,
      display: true,
    },
    {
      code: '041',
      name: 'Banco do Estado do Rio Grande do Sul S.A.',
      major: false,
      display: true,
    },
    {
      code: '047',
      name: 'Banco do Estado de Sergipe S.A.',
      major: false,
      display: true,
    },
    {
      code: '060',
      name: 'Confidence Corretora de Câmbio S.A.',
      major: false,
      display: true,
    },
    {
      code: '062',
      name: 'Hipercard Banco Múltiplo S.A.',
      major: false,
      display: true,
    },
    {
      code: '063',
      name: 'Banco Bradescard S.A.',
      major: false,
      display: true,
    },
    {
      code: '064',
      name: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.',
      major: false,
      display: true,
    },
    {
      code: '065',
      name: 'Banco AndBank (Brasil) S.A.',
      major: false,
      display: true,
    },
    {
      code: '066',
      name: 'BANCO MORGAN STANLEY S.A.',
      major: false,
      display: true,
    },
    {
      code: '069',
      name: 'Banco Crefisa S.A.',
      major: false,
      display: true,
    },
    {
      code: '070',
      name: 'BRB - BANCO DE BRASILIA S.A.',
      major: false,
      display: true,
    },
    {
      code: '074',
      name: 'Banco J. Safra S.A.',
      major: false,
      display: true,
    },
    {
      code: '075',
      name: 'Banco ABN Amro S.A.',
      major: false,
      display: true,
    },
    {
      code: '076',
      name: 'Banco KDB do Brasil S.A.',
      major: false,
      display: true,
    },
    {
      code: '077',
      name: 'Banco Inter S.A.',
      major: false,
      display: true,
    },
    {
      code: '078',
      name: 'Haitong Banco de Investimento do Brasil S.A.',
      major: false,
      display: false,
    },
    {
      code: '079',
      name: 'Banco Original do Agronegócio S.A.',
      major: false,
      display: true,
    },
    {
      code: '080',
      name: 'B&T CORRETORA DE CAMBIO LTDA.',
      major: false,
      display: false,
    },
    {
      code: '081',
      name: 'BancoSeguro S.A.',
      major: false,
      display: true,
    },
    {
      code: '082',
      name: 'BANCO TOPÁZIO S.A.',
      major: false,
      display: false,
    },
    {
      code: '083',
      name: 'Banco da China Brasil S.A.',
      major: false,
      display: false,
    },
    {
      code: '084',
      name: 'UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA',
      major: false,
      display: true,
    },
    {
      code: '085',
      name: 'Cooperativa Central de Crédito - Ailos',
      major: false,
      display: true,
    },
    {
      code: '088',
      name: 'BANCO RANDON S.A.',
      major: false,
      display: true,
    },
    {
      code: '089',
      name: 'CREDISAN COOPERATIVA DE CRÉDITO',
      major: false,
      display: true,
    },
    {
      code: '091',
      name: 'CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S',
      major: false,
      display: true,
    },
    {
      code: '092',
      name: 'BRK S.A. Crédito',
      major: false,
      display: false,
    },
    {
      code: '093',
      name: 'PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
      major: false,
      display: false,
    },
    {
      code: '094',
      name: 'Banco Finaxis S.A.',
      major: false,
      display: false,
    },
    {
      code: '095',
      name: 'Travelex Banco de Câmbio S.A.',
      major: false,
      display: false,
    },
    {
      code: '096',
      name: 'Banco B3 S.A.',
      major: false,
      display: false,
    },
    {
      code: '097',
      name: 'Credisis - Central de Cooperativas de Crédito Ltda.',
      major: false,
      display: true,
    },
    {
      code: '098',
      name: 'Credialiança Cooperativa de Crédito Rural',
      major: false,
      display: true,
    },
    {
      code: '099',
      name: 'UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.',
      major: false,
      display: true,
    },
    {
      code: '100',
      name: 'Planner Corretora de Valores S.A.',
      major: false,
      display: false,
    },
    {
      code: '101',
      name: 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
      major: false,
      display: false,
    },
    {
      code: '102',
      name: 'XP INVESTIMENTOS CORRETORA DE CÂMBIO',
      major: false,
      display: true,
    },
    {
      code: '104',
      name: 'CAIXA ECONOMICA FEDERAL',
      major: true,
      display: true,
    },
    {
      code: '105',
      name: 'Lecca Crédito',
      major: false,
      display: true,
    },
    {
      code: '107',
      name: 'Banco Bocom BBM S.A.',
      major: false,
      display: true,
    },
    {
      code: '108',
      name: 'PORTOCRED S.A. - CREDITO',
      major: false,
      display: false,
    },
    {
      code: '111',
      name: 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
      major: false,
      display: true,
    },
    {
      code: '113',
      name: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios',
      major: false,
      display: false,
    },
    {
      code: '114',
      name: 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
      major: false,
      display: true,
    },
    {
      code: '117',
      name: 'ADVANCED CORRETORA DE CÂMBIO LTDA',
      major: false,
      display: false,
    },
    {
      code: '119',
      name: 'Banco Western Union do Brasil S.A.',
      major: false,
      display: false,
    },
    {
      code: '120',
      name: 'BANCO RODOBENS S.A.',
      major: false,
      display: false,
    },
    {
      code: '121',
      name: 'Banco Agibank S.A.',
      major: false,
      display: true,
    },
    {
      code: '122',
      name: 'Banco Bradesco BERJ S.A.',
      major: false,
      display: true,
    },
    {
      code: '124',
      name: 'Banco Woori Bank do Brasil S.A.',
      major: false,
      display: true,
    },
    {
      code: '125',
      name: 'Plural S.A. Banco Múltiplo',
      major: false,
      display: true,
    },
    {
      code: '126',
      name: 'BR Partners Banco de Investimento S.A.',
      major: false,
      display: false,
    },
    {
      code: '127',
      name: 'Codepe Corretora de Valores e Câmbio S.A.',
      major: false,
      display: false,
    },
    {
      code: '128',
      name: 'MS Bank S.A. Banco de Câmbio',
      major: false,
      display: true,
    },
    {
      code: '129',
      name: 'UBS Brasil Banco de Investimento S.A.',
      major: false,
      display: false,
    },
    {
      code: '130',
      name: 'CARUANA S.A. - SOCIEDADE DE CRÉDITO',
      major: false,
      display: false,
    },
    {
      code: '131',
      name: 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
      major: false,
      display: false,
    },
    {
      code: '132',
      name: 'ICBC do Brasil Banco Múltiplo S.A.',
      major: false,
      display: false,
    },
    {
      code: '133',
      name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
      major: false,
      display: true,
    },
    {
      code: '134',
      name: 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
      major: false,
      display: false,
    },
    {
      code: '136',
      name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI',
      major: false,
      display: true,
    },
    {
      code: '138',
      name: 'Get Money Corretora de Câmbio S.A.',
      major: false,
      display: false,
    },
    {
      code: '139',
      name: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
      major: false,
      display: false,
    },
    {
      code: '140',
      name: 'Easynvest - Título Corretora de Valores SA',
      major: false,
      display: true,
    },
    {
      code: '142',
      name: 'Broker Brasil Corretora de Câmbio Ltda.',
      major: false,
      display: false,
    },
    {
      code: '143',
      name: 'Treviso Corretora de Câmbio S.A.',
      major: false,
      display: false,
    },
    {
      code: '144',
      name: 'BEXS BANCO DE CÂMBIO S/A',
      major: false,
      display: false,
    },
    {
      code: '145',
      name: 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
      major: false,
      display: false,
    },
    {
      code: '146',
      name: 'GUITTA CORRETORA DE CAMBIO LTDA.',
      major: false,
      display: false,
    },
    {
      code: '149',
      name: 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
      major: false,
      display: true,
    },
    {
      code: '157',
      name: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
      major: false,
      display: false,
    },
    {
      code: '159',
      name: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
      major: false,
      display: false,
    },
    {
      code: '163',
      name: 'Commerzbank Brasil S.A. - Banco Múltiplo',
      major: false,
      display: false,
    },
    {
      code: '169',
      name: 'BANCO OLÉ CONSIGNADO S.A.',
      major: false,
      display: true,
    },
    {
      code: '173',
      name: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
      major: false,
      display: false,
    },
    {
      code: '174',
      name: 'PEFISA S.A. - CRÉDITO',
      major: false,
      display: true,
    },
    {
      code: '177',
      name: 'Guide Investimentos S.A. Corretora de Valores',
      major: false,
      display: false,
    },
    {
      code: '180',
      name: 'CM CAPITAL MARKETS CORRETORA DE CÂMBIO',
      major: false,
      display: false,
    },
    {
      code: '183',
      name: 'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
      major: false,
      display: true,
    },
    {
      code: '184',
      name: 'Banco Itaú BBA S.A.',
      major: false,
      display: true,
    },
    {
      code: '188',
      name: 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS',
      major: false,
      display: false,
    },
    {
      code: '189',
      name: 'HS FINANCEIRA S/A CREDITO',
      major: false,
      display: true,
    },
    {
      code: '190',
      name: 'SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN',
      major: false,
      display: true,
    },
    {
      code: '191',
      name: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
      major: false,
      display: false,
    },
    {
      code: '194',
      name: 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
      major: false,
      display: false,
    },
    {
      code: '196',
      name: 'FAIR CORRETORA DE CAMBIO S.A.',
      major: false,
      display: false,
    },
    {
      code: '197',
      name: 'Stone Pagamentos S.A.',
      major: false,
      display: true,
    },
    {
      code: '208',
      name: 'Banco BTG Pactual S.A.',
      major: false,
      display: true,
    },
    {
      code: '212',
      name: 'Banco Original S.A.',
      major: false,
      display: true,
    },
    {
      code: '213',
      name: 'Banco Arbi S.A.',
      major: false,
      display: true,
    },
    {
      code: '217',
      name: 'Banco John Deere S.A.',
      major: false,
      display: false,
    },
    {
      code: '218',
      name: 'Banco BS2 S.A.',
      major: false,
      display: true,
    },
    {
      code: '222',
      name: 'BANCO CRÉDIT AGRICOLE BRASIL S.A.',
      major: false,
      display: false,
    },
    {
      code: '224',
      name: 'Banco Fibra S.A.',
      major: false,
      display: true,
    },
    {
      code: '233',
      name: 'Banco Cifra S.A.',
      major: false,
      display: false,
    },
    {
      code: '237',
      name: 'Banco Bradesco S.A.',
      major: true,
      display: true,
    },
    {
      code: '241',
      name: 'BANCO CLASSICO S.A.',
      major: false,
      display: true,
    },
    {
      code: '243',
      name: 'Banco Máxima S.A.',
      major: false,
      display: false,
    },
    {
      code: '246',
      name: 'Banco ABC Brasil S.A.',
      major: false,
      display: true,
    },
    {
      code: '249',
      name: 'Banco Investcred Unibanco S.A.',
      major: false,
      display: true,
    },
    {
      code: '250',
      name: 'BCV - BANCO DE CRÉDITO E VAREJO S.A.',
      major: false,
      display: true,
    },
    {
      code: '253',
      name: 'Bexs Corretora de Câmbio S/A',
      major: false,
      display: false,
    },
    {
      code: '254',
      name: 'PARANÁ BANCO S.A.',
      major: false,
      display: false,
    },
    {
      code: '259',
      name: 'MONEYCORP BANCO DE CÂMBIO S.A.',
      major: false,
      display: false,
    },
    {
      code: '260',
      name: 'Nu Pagamentos S.A.',
      major: false,
      display: true,
    },
    {
      code: '265',
      name: 'Banco Fator S.A.',
      major: false,
      display: true,
    },
    {
      code: '266',
      name: 'BANCO CEDULA S.A.',
      major: false,
      display: false,
    },
    {
      code: '268',
      name: 'BARI COMPANHIA HIPOTECÁRIA',
      major: false,
      display: true,
    },
    {
      code: '269',
      name: 'BANCO HSBC S.A.',
      major: false,
      display: false,
    },
    {
      code: '270',
      name: 'Sagitur Corretora de Câmbio Ltda.',
      major: false,
      display: false,
    },
    {
      code: '271',
      name: 'IB Corretora de Câmbio',
      major: false,
      display: false,
    },
    {
      code: '272',
      name: 'AGK CORRETORA DE CAMBIO S.A.',
      major: false,
      display: false,
    },
    {
      code: '273',
      name: 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
      major: false,
      display: true,
    },
    {
      code: '274',
      name: 'MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
      major: false,
      display: false,
    },
    {
      code: '276',
      name: 'BANCO SENFF S.A.',
      major: false,
      display: false,
    },
    {
      code: '278',
      name: 'Genial Investimentos Corretora de Valores Mobiliários S.A.',
      major: false,
      display: true,
    },
    {
      code: '279',
      name: 'COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE',
      major: false,
      display: true,
    },
    {
      code: '280',
      name: 'WILL FINANCEIRA S.A. CRÉDITO',
      major: false,
      display: true,
    },
    {
      code: '281',
      name: 'Cooperativa de Crédito Rural Coopavel',
      major: false,
      display: true,
    },
    {
      code: '283',
      name: 'RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA',
      major: false,
      display: false,
    },
    {
      code: '285',
      name: 'Frente Corretora de Câmbio Ltda.',
      major: false,
      display: false,
    },
    {
      code: '286',
      name: 'COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO',
      major: false,
      display: true,
    },
    {
      code: '288',
      name: 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
      major: false,
      display: false,
    },
    {
      code: '289',
      name: 'DECYSEO CORRETORA DE CAMBIO LTDA.',
      major: false,
      display: true,
    },
    {
      code: '290',
      name: 'Pagseguro Internet S.A.',
      major: false,
      display: true,
    },
    {
      code: '292',
      name: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
      major: false,
      display: false,
    },
    {
      code: '293',
      name: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
      major: false,
      display: false,
    },
    {
      code: '296',
      name: 'VISION S.A. CORRETORA DE CAMBIO',
      major: false,
      display: false,
    },
    {
      code: '298',
      name: 'Vips Corretora de Câmbio Ltda.',
      major: false,
      display: true,
    },
    {
      code: '299',
      name: 'SOROCRED CRÉDITO',
      major: false,
      display: false,
    },
    {
      code: '300',
      name: 'Banco de la Nacion Argentina',
      major: false,
      display: false,
    },
    {
      code: '301',
      name: 'BPP Instituição de Pagamento S.A.',
      major: false,
      display: true,
    },
    {
      code: '306',
      name: 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
      major: false,
      display: false,
    },
    {
      code: '307',
      name: 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
      major: false,
      display: false,
    },
    {
      code: '309',
      name: 'CAMBIONET CORRETORA DE CÂMBIO LTDA.',
      major: false,
      display: false,
    },
    {
      code: '310',
      name: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
      major: false,
      display: false,
    },
    {
      code: '311',
      name: 'DOURADA CORRETORA DE CÂMBIO LTDA.',
      major: false,
      display: true,
    },
    {
      code: '313',
      name: 'AMAZÔNIA CORRETORA DE CÂMBIO LTDA.',
      major: false,
      display: false,
    },
    {
      code: '315',
      name: 'PI Distribuidora de Títulos e Valores Mobiliários S.A.',
      major: false,
      display: false,
    },
    {
      code: '318',
      name: 'Banco BMG S.A.',
      major: false,
      display: true,
    },
    {
      code: '319',
      name: 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
      major: false,
      display: false,
    },
    {
      code: '320',
      name: 'China Construction Bank (Brasil) Banco Múltiplo S/A',
      major: false,
      display: false,
    },
    {
      code: '321',
      name: 'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
      major: false,
      display: false,
    },
    {
      code: '322',
      name: 'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
      major: false,
      display: true,
    },
    {
      code: '323',
      name: 'MERCADOPAGO.COM REPRESENTACOES LTDA.',
      major: false,
      display: true,
    },
    {
      code: '324',
      name: 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
      major: false,
      display: false,
    },
    {
      code: '325',
      name: 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
      major: false,
      display: false,
    },
    {
      code: '326',
      name: 'PARATI - CREDITO',
      major: false,
      display: true,
    },
    {
      code: '329',
      name: 'QI Sociedade de Crédito Direto S.A.',
      major: false,
      display: true,
    },
    {
      code: '330',
      name: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
      major: false,
      display: true,
    },
    {
      code: '331',
      name: 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
      major: false,
      display: false,
    },
    {
      code: '332',
      name: 'Acesso Soluções de Pagamento S.A.',
      major: false,
      display: true,
    },
    {
      code: '335',
      name: 'Banco Digio S.A.',
      major: false,
      display: true,
    },
    {
      code: '336',
      name: 'Banco C6 S.A.',
      major: false,
      display: true,
    },
    {
      code: '340',
      name: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.',
      major: false,
      display: true,
    },
    {
      code: '341',
      name: 'ITAÚ UNIBANCO S.A.',
      major: true,
      display: true,
    },
    {
      code: '342',
      name: 'Creditas Sociedade de Crédito Direto S.A.',
      major: false,
      display: true,
    },
    {
      code: '343',
      name: 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
      major: false,
      display: false,
    },
    {
      code: '348',
      name: 'Banco XP S.A.',
      major: false,
      display: true,
    },
    {
      code: '349',
      name: 'AL5 S.A. CRÉDITO',
      major: false,
      display: true,
    },
    {
      code: '350',
      name: 'COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE',
      major: false,
      display: false,
    },
    {
      code: '352',
      name: 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
      major: false,
      display: true,
    },
    {
      code: '354',
      name: 'NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES',
      major: false,
      display: true,
    },
    {
      code: '355',
      name: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
      major: false,
      display: false,
    },
    {
      code: '358',
      name: 'MIDWAY S.A. - CRÉDITO',
      major: false,
      display: true,
    },
    {
      code: '359',
      name: 'ZEMA CRÉDITO',
      major: false,
      display: true,
    },
    {
      code: '360',
      name: 'TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
      major: false,
      display: true,
    },
    {
      code: '362',
      name: 'CIELO S.A.',
      major: false,
      display: true,
    },
    {
      code: '363',
      name: 'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
      major: false,
      display: false,
    },
    {
      code: '364',
      name: 'GERENCIANET S.A.',
      major: false,
      display: true,
    },
    {
      code: '365',
      name: 'SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS',
      major: false,
      display: false,
    },
    {
      code: '366',
      name: 'BANCO SOCIETE GENERALE BRASIL S.A.',
      major: false,
      display: false,
    },
    {
      code: '367',
      name: 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
      major: false,
      display: false,
    },
    {
      code: '368',
      name: 'Banco CSF S.A.',
      major: false,
      display: true,
    },
    {
      code: '370',
      name: 'Banco Mizuho do Brasil S.A.',
      major: false,
      display: false,
    },
    {
      code: '371',
      name: 'WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.',
      major: false,
      display: false,
    },
    {
      code: '373',
      name: 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
      major: false,
      display: false,
    },
    {
      code: '374',
      name: 'REALIZE CRÉDITO',
      major: false,
      display: false,
    },
    {
      code: '376',
      name: 'BANCO J.P. MORGAN S.A.',
      major: false,
      display: false,
    },
    {
      code: '377',
      name: 'BMS SOCIEDADE DE CRÉDITO DIRETO S.A.',
      major: false,
      display: false,
    },
    {
      code: '378',
      name: 'BBC LEASING S.A. - ARRENDAMENTO MERCANTIL',
      major: false,
      display: true,
    },
    {
      code: '379',
      name: 'COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU',
      major: false,
      display: false,
    },
    {
      code: '380',
      name: 'PICPAY SERVICOS S.A.',
      major: false,
      display: true,
    },
    {
      code: '381',
      name: 'BANCO MERCEDES-BENZ DO BRASIL S.A.',
      major: false,
      display: false,
    },
    {
      code: '382',
      name: 'FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L',
      major: false,
      display: false,
    },
    {
      code: '383',
      name: 'BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.',
      major: false,
      display: true,
    },
    {
      code: '384',
      name: 'GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO',
      major: false,
      display: false,
    },
    {
      code: '386',
      name: 'NU FINANCEIRA S.A. - Sociedade de Crédito',
      major: false,
      display: true,
    },
    {
      code: '387',
      name: 'Banco Toyota do Brasil S.A.',
      major: false,
      display: false,
    },
    {
      code: '389',
      name: 'Banco Mercantil do Brasil S.A.',
      major: false,
      display: true,
    },
    {
      code: '390',
      name: 'BANCO GM S.A.',
      major: false,
      display: false,
    },
    {
      code: '391',
      name: 'COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM',
      major: false,
      display: true,
    },
    {
      code: '393',
      name: 'Banco Volkswagen S.A.',
      major: false,
      display: true,
    },
    {
      code: '394',
      name: 'Banco Bradesco Financiamentos S.A.',
      major: false,
      display: true,
    },
    {
      code: '395',
      name: 'F D GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
      major: false,
      display: true,
    },
    {
      code: '396',
      name: 'HUB PAGAMENTOS S.A',
      major: false,
      display: true,
    },
    {
      code: '397',
      name: 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
      major: false,
      display: true,
    },
    {
      code: '398',
      name: 'IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
      major: false,
      display: false,
    },
    {
      code: '399',
      name: 'Kirton Bank S.A. - Banco Múltiplo',
      major: false,
      display: false,
    },
    {
      code: '402',
      name: 'COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A.',
      major: false,
      display: false,
    },
    {
      code: '403',
      name: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
      major: false,
      display: true,
    },
    {
      code: '404',
      name: 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
      major: false,
      display: true,
    },
    {
      code: '406',
      name: 'ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.',
      major: false,
      display: false,
    },
    {
      code: '408',
      name: 'BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
      major: false,
      display: false,
    },
    {
      code: '412',
      name: 'BANCO CAPITAL S.A.',
      major: false,
      display: false,
    },
    {
      code: '422',
      name: 'Banco Safra S.A.',
      major: false,
      display: true,
    },
    {
      code: '456',
      name: 'Banco MUFG Brasil S.A.',
      major: false,
      display: false,
    },
    {
      code: '464',
      name: 'Banco Sumitomo Mitsui Brasileiro S.A.',
      major: false,
      display: false,
    },
    {
      code: '473',
      name: 'Banco Caixa Geral - Brasil S.A.',
      major: false,
      display: true,
    },
    {
      code: '477',
      name: 'Citibank N.A.',
      major: false,
      display: false,
    },
    {
      code: '479',
      name: 'Banco ItauBank S.A.',
      major: false,
      display: true,
    },
    {
      code: '487',
      name: 'DEUTSCHE BANK S.A. - BANCO ALEMAO',
      major: false,
      display: false,
    },
    {
      code: '488',
      name: 'JPMorgan Chase Bank',
      major: false,
      display: false,
    },
    {
      code: '492',
      name: 'ING Bank N.V.',
      major: false,
      display: true,
    },
    {
      code: '495',
      name: 'Banco de La Provincia de Buenos Aires',
      major: false,
      display: false,
    },
    {
      code: '505',
      name: 'Banco Credit Suisse (Brasil) S.A.',
      major: false,
      display: false,
    },
    {
      code: '545',
      name: 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
      major: false,
      display: false,
    },
    {
      code: '600',
      name: 'Banco Luso Brasileiro S.A.',
      major: false,
      display: false,
    },
    {
      code: '604',
      name: 'Banco Industrial do Brasil S.A.',
      major: false,
      display: false,
    },
    {
      code: '610',
      name: 'Banco VR S.A.',
      major: false,
      display: false,
    },
    {
      code: '611',
      name: 'Banco Paulista S.A.',
      major: false,
      display: true,
    },
    {
      code: '612',
      name: 'Banco Guanabara S.A.',
      major: false,
      display: false,
    },
    {
      code: '613',
      name: 'Omni Banco S.A.',
      major: false,
      display: true,
    },
    {
      code: '623',
      name: 'Banco Pan S.A.',
      major: false,
      display: true,
    },
    {
      code: '626',
      name: 'BANCO C6 CONSIGNADO S.A.',
      major: false,
      display: true,
    },
    {
      code: '630',
      name: 'Banco Smartbank S.A.',
      major: false,
      display: true,
    },
    {
      code: '633',
      name: 'Banco Rendimento S.A.',
      major: false,
      display: true,
    },
    {
      code: '634',
      name: 'BANCO TRIANGULO S.A.',
      major: false,
      display: true,
    },
    {
      code: '637',
      name: 'BANCO SOFISA S.A.',
      major: false,
      display: true,
    },
    {
      code: '643',
      name: 'Banco Pine S.A.',
      major: false,
      display: false,
    },
    {
      code: '652',
      name: 'Itaú Unibanco Holding S.A.',
      major: false,
      display: true,
    },
    {
      code: '653',
      name: 'BANCO INDUSVAL S.A.',
      major: false,
      display: false,
    },
    {
      code: '654',
      name: 'BANCO DIGIMAIS S.A.',
      major: false,
      display: true,
    },
    {
      code: '655',
      name: 'Banco Votorantim S.A.',
      major: false,
      display: true,
    },
    {
      code: '707',
      name: 'Banco Daycoval S.A.',
      major: false,
      display: true,
    },
    {
      code: '712',
      name: 'Banco Ourinvest S.A.',
      major: false,
      display: false,
    },
    {
      name: 'Neon Pagamentos S.A',
      code: '735',
      major: false,
      display: true,
    },
    {
      code: '739',
      name: 'Banco Cetelem S.A.',
      major: false,
      display: false,
    },
    {
      code: '741',
      name: 'BANCO RIBEIRAO PRETO S.A.',
      major: false,
      display: false,
    },
    {
      code: '743',
      name: 'Banco Semear S.A.',
      major: false,
      display: true,
    },
    {
      code: '745',
      name: 'Banco Citibank S.A.',
      major: false,
      display: true,
    },
    {
      code: '746',
      name: 'Banco Modal S.A.',
      major: false,
      display: true,
    },
    {
      code: '747',
      name: 'Banco Rabobank International Brasil S.A.',
      major: false,
      display: true,
    },
    {
      code: '748',
      name: 'BANCO COOPERATIVO SICREDI S.A.',
      major: false,
      display: true,
    },
    {
      code: '751',
      name: 'Scotiabank Brasil S.A. Banco Múltiplo',
      major: false,
      display: false,
    },
    {
      code: '752',
      name: 'Banco BNP Paribas Brasil S.A.',
      major: false,
      display: true,
    },
    {
      code: '753',
      name: 'Novo Banco Continental S.A. - Banco Múltiplo',
      major: false,
      display: false,
    },
    {
      code: '754',
      name: 'Banco Sistema S.A.',
      major: false,
      display: true,
    },
    {
      code: '755',
      name: 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
      major: false,
      display: false,
    },
    {
      code: '756',
      name: 'BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB',
      major: false,
      display: true,
    },
    {
      code: '757',
      name: 'BANCO KEB HANA DO BRASIL S.A.',
      major: false,
      display: false,
    },
  ]
    .filter((b) => b.display)
    .sort((a, b) => Number(b.major) - Number(a.major))
    .map((b) => ({ ...b, name: b.name.toUpperCase() }))
