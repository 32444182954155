<template>
  <ClearLayout>
    <OfferPartnerQrCodeRedirect
      v-if="cptdPartnerQrCodeUrl"
      :partner="cptdPartner"
      :url="cptdPartnerQrCodeUrl"
    />
    <OfferPartnerRedirectModal
      v-else
      :partner="cptdPartner"
      :url="cptdOfferUrl"
      @redirected="registerIntent"
    >
      <template #title>
        {{ cptdTitleRedirect }}
      </template>
      <template #alert-message>
        {{ cptdRedirectMessage }}
      </template>
    </OfferPartnerRedirectModal>
  </ClearLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PartnersName, PartnerWithQrCode } from '@/helpers/partners/common'
import { generateWhatsAppLink } from '@/helpers/whatsapp'
import { IncomeSource } from '@/data/common'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const OfferPartnerQrCodeRedirect = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerQrCodeRedirect.vue'
    ),
)
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
export default {
  components: {
    ClearLayout,
    OfferPartnerQrCodeRedirect,
    OfferPartnerRedirectModal,
  },
  computed: {
    ...mapGetters({
      personaData: 'user/persona/personaData',
    }),
    cptdPartner() {
      const { partner } = this.$route?.params
      return partner?.toUpperCase() ?? ''
    },
    cptdPartnerQrCodeUrl() {
      if (PartnerWithQrCode[this.cptdPartner ?? '']) {
        const { origin, pathname } = location || {}
        if (origin && pathname) {
          return `${origin}${pathname}/qrcode`
        }
      }

      return ''
    },
    cptdOfferUrl() {
      switch (this.cptdPartner) {
        case 'CREFAZ':
          return 'https://indiky.link/card-jb-autocontratacao'
        case 'ZIPPI':
          return 'https://zippi.com.br/?utm_source=juros-baixos&utm_medium=juros-baixos'
        case 'JEITTO':
          return 'https://jeitto.com.br/?utm_source=parceiro&utm_medium=jurosbaixos&utm_campaign=site'
        case 'AGIL':
          return 'https://emprestimo-pessoal.agil.com.br/?campaign_id=180e2b95-76b8-4de4-b310-dbc3a7949ad2'
        case 'JBCRED':
          switch (this.personaData?.finance?.income_source ?? '') {
            case IncomeSource.CLT:
              return 'https://marketing.jbcred.com.br/qa3-sp-ok'
            case IncomeSource.MILITARY:
            case IncomeSource.FEDERAL_WORKER:
              return 'https://marketing.jbcred.com.br/qa3-fp-ok'
            case IncomeSource.PENSION:
            case IncomeSource.RETIREMENT:
              return 'https://marketing.jbcred.com.br/qa3-api-ok'
            default:
              return 'https://marketing.jbcred.com.br/qa3-sp-ok'
          }
        default:
          return generateWhatsAppLink({
            text: `Olá! Quero continuar minha solicitação de oferta com a ${this.cptdPartnerName}. Poderia me ajudar?`,
          })
      }
    },
    cptdPartnerName() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdTitleRedirect() {
      return `Tudo certo! Finalize a contratação da sua oferta com a ${this.cptdPartnerName}`
    },
    cptdRedirectMessage() {
      return `Este serviço é uma parceria com a ${this.cptdPartnerName} e o processo continuará em seu site.`
    },
  },
  mounted() {
    this.actEmitInfobipAffiliateOfferSelected({
      affiliate: this.cptdPartner,
    })
  },
  methods: {
    ...mapActions({
      actInsertIntent: 'user/intent/actInsertIntent',
      actEmitInfobipAffiliateOfferSelected:
        'services/events/actEmitInfobipAffiliateOfferSelected',
    }),
    registerIntent() {
      this.actInsertIntent({
        identifier: `PARTNER_${this.cptdPartner}_REDIRECT`,
      })
    },
  },
}
</script>

<style lang="scss"></style>
