<template>
  <ClearLayout>
    <OfferPartnerRedirectModal
      :partner="cptdPartner"
      :url="cptdOfferUrl"
      @redirected="registerIntent"
    >
      <template #title>
        {{ cptdTitleRedirect }}
      </template>
      <template #alert-message>
        {{ cptdRedirectMessage }}
      </template>
    </OfferPartnerRedirectModal>
  </ClearLayout>
</template>

<script>
import { mapActions } from 'vuex'
import { PartnersName } from '@/helpers/partners/common'
import { generateWhatsAppLink } from '@/helpers/whatsapp'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
export default {
  components: {
    ClearLayout,
    OfferPartnerRedirectModal,
  },
  computed: {
    cptdPartner() {
      const { partner } = this.$route?.params
      return partner?.toUpperCase() ?? ''
    },
    cptdOfferUrl() {
      if (this.$route.query?.url) return this.$route.query?.url

      switch (this.cptdPartner) {
        case 'PICPAY':
          return 'https://bxblue.g2afse.com/click?pid=164&offer_id=2'
        default:
          return generateWhatsAppLink({
            text: `Olá! Quero continuar minha solicitação de oferta com a ${this.cptdPartnerName}. Poderia me ajudar?`,
          })
      }
    },
    cptdPartnerName() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdTitleRedirect() {
      return `Tudo certo! Finalize a contratação da sua oferta com a ${this.cptdPartnerName}`
    },
    cptdRedirectMessage() {
      return `Este serviço é uma parceria com a ${this.cptdPartnerName} e o processo continuará em seu site.`
    },
  },
  mounted() {
    this.actEmitInfobipAffiliateOfferSelected({
      affiliate: this.cptdPartner,
    })
  },
  methods: {
    ...mapActions({
      actInsertIntent: 'user/intent/actInsertIntent',
      actEmitInfobipAffiliateOfferSelected:
        'services/events/actEmitInfobipAffiliateOfferSelected',
    }),
    registerIntent() {
      const { type } = this.$route?.query || {}
      const typeString = type ? `_${type.toUpperCase()}` : ''
      this.actInsertIntent({
        identifier: `PARTNER_${this.cptdPartner}${typeString}_REDIRECT`,
      })
    },
  },
}
</script>

<style lang="scss"></style>
