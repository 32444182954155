<template>
  <ClearLayout>
    <IncomeLossInsuranceHiringCoveragesPageContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const IncomeLossInsuranceHiringCoveragesPageContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/income-loss/hiring/IncomeLossInsuranceHiringCoveragesPageContainer.vue'
    ),
)
export default {
  name: 'IncomeLossInsuranceHiringCoveragesPage',
  components: {
    ClearLayout,
    IncomeLossInsuranceHiringCoveragesPageContainer,
  },
}
</script>
<style lang="scss"></style>
