// remove all null keys of an object

export const filterNotNullKeys = (obj) => {
  const newObj = {}
  Object.keys(obj)
    .filter(
      (key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== '',
    )
    .forEach((key) => {
      newObj[key] = obj[key]
    })
  return newObj
}

// TODO: Improve this method later to stop searching when a key is found
export const updateObjectByKey = (obj, key, value) => {
  if (obj && typeof obj === 'object' && key in obj) {
    obj[key] = value
    return
  }

  Object.keys(obj).forEach((k) => {
    if (obj[k] && typeof obj[k] === 'object') {
      updateObjectByKey(obj[k], key, value)
    }
  })
}

export const trimAllObjectValues = (obj) => {
  for (const k in obj) {
    if (typeof obj[k] === 'string') obj[k] = obj[k].trim()
    else if (typeof obj[k] === 'object' && !Array.isArray(obj[k]))
      trimAllObjectValues(obj[k])
  }
}

export const normalizeString = (str) =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')

export const wait = async (ms = 1000) =>
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve({})
    }, ms)
  })

export const isElementVisible = (e) => {
  const rect = e?.getBoundingClientRect?.()
  if (rect && document?.documentElement?.clientHeight && window?.innerHeight) {
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight,
    )
    return rect?.bottom >= 0 && rect?.bottom - rect?.height * 0.25 <= viewHeight
  }
}

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return JSON.parse(jsonPayload)
  } catch (e) {
    return null
  }
}

export const isOnClientSide = () => typeof window !== 'undefined'

export const inBrowser = typeof window !== 'undefined'
export const UA = inBrowser && window.navigator.userAgent.toLowerCase()
export const isAndroid = UA && UA.indexOf('android') > 0
export const isIOS = UA && /iphone|ipad|ipod|ios/.test(UA)
export const isMobile = isAndroid || isIOS
export const isWebView =
  UA && /WebView|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv)/gi.test(UA)
