<template>
  <UnloggedLayout>
    <div class="OAuthInteractiveFormPage">
      <OAuthCreateAccountInteractiveFormContainer
        v-bind="$attrs"
        @submit="submit"
      />
      <ProgressWillBeLostModal
        v-if="displayExitDialog"
        :display="displayExitDialog"
        @close="hideExitModal"
        @exit="redirectToHome"
      />
    </div>
  </UnloggedLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { GtmPushDataLayer } from '@/helpers/google'
import { insuranceInfos } from '@/helpers/insurances'
import { createUrlQueryParams } from '@/helpers/url'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import JbOauth from '~/helpers/oauth'
import { defineAsyncComponent } from 'vue'

const OAuthCreateAccountInteractiveFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/oauth/interactive/OAuthCreateAccountInteractiveFormContainer.vue'
    ),
)
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)

// import { randomAbTest } from '~/helpers/abTest'
export default {
  name: 'OAuthOnboardingInteractiveFormPage',
  components: {
    UnloggedLayout,
    OAuthCreateAccountInteractiveFormContainer,
    ProgressWillBeLostModal,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (JbOauth.getAccessToken()) {
        if (!JbOauth.isAccessTokenValid()) {
          vm.createFeedback({
            text: 'Parece que você já possui uma conta conosco.\nPara continuar, basta realizar o login em sua conta.',
          })
          JbOauth.logout(false)
        }
        vm.$router.push({
          path: '/login',
          query: { ...vm.$route.query },
        })
        // return
      }
      // if (!vm.$route.query?.jb_ab_2482) {
      //   vm.$router.replace({
      //     query: {
      //       ...vm.$route.query,
      //       jb_ab_2482: randomAbTest('jb_ab_2482', ['A', 'B'])
      //     }
      //   })
      // }
    })
  },
  data() {
    return {
      started: false,
      displayExitDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      gtUser: 'user/user/gtUser',
    }),
  },
  created() {
    this.gEventBus.on('layout-unlogged:close-clicked', () => {
      this.displayExitModal()
    })
  },
  methods: {
    ...mapActions({
      actGetUser: 'user/user/actGetUser',
      actOAuthSilentLogout: 'oauth/actOAuthSilentLogout',
      registerInsuranceTypes: 'services/tracker/registerInsuranceTypes',
      createFeedback: 'utils/feedback/createFeedback',
    }),
    start() {
      this.started = true
      this.gEventBus.emit('layout-unlogged:display-progress-bar')
    },
    redirectToHome() {
      window.location.href = 'https://jurosbaixos.com.br/'
    },
    displayExitModal() {
      this.displayExitDialog = true
    },
    hideExitModal() {
      this.displayExitDialog = false
    },
    submit() {
      localStorage.setItem('jb.state.user-created-from', 'create-account')
      this.loginAction()
    },
    async loginAction() {
      const currentProduct = sessionStorage.getItem('product') || ''

      // GTM EVENT
      GtmPushDataLayer({
        user_id: this.gtUser?.id ?? '',
      })

      if (Object.keys(insuranceInfos)?.includes(currentProduct)) {
        await this.registerInsuranceTypes({
          ...this.gtUser,
          product: currentProduct,
          aff_id: sessionStorage.getItem('aff_id') ?? null,
          source: 'create_account',
          utm_campaign: sessionStorage.getItem('utm_campaign'),
        })

        this.actOAuthSilentLogout()

        window.location.href = `${
          insuranceInfos[currentProduct]
        }${createUrlQueryParams({
          cpf: this.gtUser?.cpf.replace(/\D/g, ''),
          email: this.gtUser?.email,
          user_id: this.gtUser?.id ?? '',
          source: 'create_account',
          utm_campaign: sessionStorage.getItem('utm_campaign'),
        })}`
      } else {
        this.redirectUserToLoggedArea()
      }
    },
    redirectUserToLoggedArea() {
      const productQuery = this.$route?.query?.product
      const redirectUserTo = (path, query) => {
        history.replaceState(
          {
            ...history.state,
            back: '/home',
            current: path,
          },
          'home',
          location.origin + '/home',
        )
        this.$router.push({
          path,
          ...(query ? { query } : {}),
        })
      }

      if (productQuery) {
        if (productQuery === 'debt_renegotiation') {
          redirectUserTo('/negociacao-dividas', { product: productQuery })
        } else if (productQuery === 'credit_card') {
          redirectUserTo('/cartao-credito/ofertas', { product: productQuery })
        } else if (productQuery === 'fgts') {
          redirectUserTo('/forms/emprestimo/fgts/simular')
        } else if (productQuery === 'jbprime') {
          const { jb_prime_subscription } = this.$route?.query
          const query = jb_prime_subscription
            ? { jb_prime_subscription }
            : undefined
          redirectUserTo('/jb-prime', query)
        } else if (productQuery === 'income_loss_insurance') {
          redirectUserTo('/seguro-perda-renda')
        } else {
          redirectUserTo('/simular', { product: productQuery })
        }
      } else if (this.$route.query?.step === 'docs') {
        const { offer_id, partner, loan_type } = this.$route?.query
        redirectUserTo(`/contratacao/${loan_type}/${partner}/${offer_id}`, null)
      } else if (this.$route.query?.from === 'unlogged') {
        redirectUserTo('/ofertas', null)
      } else if (this.$route.query?.nextPage) {
        redirectUserTo(this.$route.query?.nextPage, null)
      } else {
        redirectUserTo('/home', null)
      }
    },
  },
}
</script>
<style lang="scss"></style>
