<template>
  <AdManagerAnchorSlot v-bind="$attrs" />
</template>
<script>
import { mapActions } from 'vuex'
import AdManagerAnchorSlot from '@/components/ads/AdManagerAnchorSlot.vue'
export default {
  name: 'JbAnchorAdsContainer',
  components: {
    AdManagerAnchorSlot,
  },
  mounted() {
    window.addEventListener('blur', this.handleWindowBlur)
  },
  beforeUnmount() {
    window.removeEventListener('blur', this.handleWindowBlur)
  },
  methods: {
    ...mapActions({
      actInsertIntent: 'user/intent/actInsertIntent',
    }),
    async handleWindowBlur() {
      const activeElement = document.activeElement
      if (
        activeElement?.tagName === 'IFRAME' &&
        activeElement?.id?.includes('google_ads_iframe') &&
        activeElement?.id?.includes(`${this.$attrs['data-ad-slot']}`)
      ) {
        await this.actInsertIntent({
          identifier: `AD_CLICK_${this.$attrs['data-ad-slot']}`,
        })
      }
    },
  },
}
</script>
<style lang="scss"></style>
