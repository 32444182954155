<template>
  <TextInput
    :id="$attrs.id || 'bank_number'"
    v-bind="$attrs"
    :class="$attrs.class || 'field required'"
    :name="$attrs.name || 'bank_number'"
    :label="$attrs.label || 'Conta com dígito'"
    mask="[
      '##-X',
      '###-X',
      '####-X',
      '#####-X',
      '######-X',
      '#######-X',
      '########-X',
      '#########-X',
      '##########-X',
      '###########-X',
      '############-X',
      '#############-X',
      '##############-X',
      '###############-X'
    ]"
    data-maska-tokens="X:[XP0-9]"
    data-maska-reversed
    data-maska-eager
    :placeholder="$attrs.placeholder || '00000-0'"
    :validations="{
      required: true,
      min: 5,
      max: 17,
      rgx: 'bankaccount.number',
    }"
    @input="$emit('input', $event)"
  />
</template>
<script>
import TextInput from '@/components/ui/newComponents/TextInput.vue'
export default {
  name: 'BankAccountNumberInput',
  components: {
    TextInput,
  },
  inheritAttrs: false,
  props: {
    bankdata: { type: Object, default: () => ({}) },
  },
  emits: ['input'],
}
</script>
<style lang="scss"></style>
