const feedbackTemplate = {
  type: undefined,
  title: undefined,
  text: undefined,
}

const state = (): any => ({
  feedback: {
    type: '',
    title: '',
    text: '',
  },
  closeTimeoutId: undefined,
  percent: '0%',
})

const getters = {
  feedback(state): any {
    return state.feedback
  },
  percent(state): any {
    return state.percent
  },
}

const mutations = {
  UPDATE_FEEDBACK(state, payload): void {
    state.feedback = { ...payload }
  },
  SET_CLOSE_FEEDBACK_TIMEOUT(state, timeoutId): void {
    state.closeTimeoutId = timeoutId
  },
  UPDATE_PERCENT(state, payload): void {
    state.percent = payload
  },
}

const actions = {
  createFeedback({ commit, dispatch }, payload) {
    dispatch('closeFeedback')
    commit('UPDATE_FEEDBACK', payload)
    commit(
      'SET_CLOSE_FEEDBACK_TIMEOUT',
      setTimeout(() => {
        dispatch('closeFeedback')
      }, 6000),
    )
  },
  closeFeedback({ commit, state }) {
    commit('UPDATE_FEEDBACK', feedbackTemplate)
    clearTimeout(state.closeTimeoutId)
    commit('SET_CLOSE_FEEDBACK_TIMEOUT', undefined)
  },

  // change percent for page
  changePercent({ commit }, payload) {
    commit('UPDATE_PERCENT', payload)
  },
}

export default { namespaced: true, state, getters, actions, mutations }
