<template>
  <LoggedLayout class="LoanSimulatingPage">
    <SimulatingComponent
      :product="cptdProduct"
      @auto-ended="onAutoEnded"
      @home-equity-ended="checkForHomeEquityColdOffers"
      @submit="redirectUserToOffersPage"
    />
  </LoggedLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import LoggedLayout from '@/layouts/LoggedLayout.vue'
import { defineAsyncComponent } from 'vue'
import { Partner } from '~/data/common'
const SimulatingComponent = defineAsyncComponent(
  () => import('~/components/appForm/loanResults/SimulatingComponent.vue'),
)
export default {
  name: 'PageSimulating',
  components: {
    LoggedLayout,
    SimulatingComponent,
  },
  computed: {
    ...mapGetters({
      gtNoCollateralLastSimulationId:
        'products/loans/no-collateral/no-collateral/gtNoCollateralLastSimulationId',
      gtAutoColdOfferLastSimulationId:
        'products/loans/auto-equity/cold-offer/gtAutoColdOfferLastSimulationId',
    }),
    cptdProduct() {
      return String(this.$route.query?.product || '')
    },
  },
  mounted() {
    this.checkNoCollateralSimulationResult()
    this.checkAutoColdOfferSimulationResult()
  },
  methods: {
    ...mapActions({
      actTrackNoCollateralSimulationEnd:
        'products/loans/no-collateral/no-collateral/actTrackNoCollateralSimulationEnd',
      actGetAutoColdOffers:
        'products/loans/auto-equity/cold-offer/actGetAutoColdOffers',
      actTrackAutoColdOfferSimulationEnd:
        'products/loans/auto-equity/cold-offer/actTrackAutoColdOfferSimulationEnd',
      actSetSelectedColdOffer:
        'products/loans/auto-equity/cold-offer/actSetSelectedColdOffer',
      actCreditScoreGetLatest:
        'products/credit-score/credit-score/actCreditScoreGetLatest',
      actGetHomeEquityLatestColdOfferRequest:
        'products/loans/home-equity/cold-offer/actGetHomeEquityLatestColdOfferRequest',
    }),
    async onAutoEnded() {
      const response = await this.actGetAutoColdOffers()
      const { data } = response
      if (data?.length === 1) {
        await this.actSetSelectedColdOffer(data[0])
        this.$router.push({
          path: `/forms/emprestimo/auto/parceiros/${data[0]?.partner.toLowerCase()}/simular`,
        })

        return
      }

      if (
        data?.length === 2 &&
        [Partner.CREDITAS, Partner.BV].every((partner) =>
          data.some((coldOffer) => coldOffer.partner === partner),
        )
      ) {
        this.$router.push({
          path: `/forms/emprestimo/auto/parceiros/simular`,
        })

        return
      }

      this.redirectUserToOffersPage()
    },
    async checkForHomeEquityColdOffers() {
      await Promise.all([
        this.actCreditScoreGetLatest(),
        this.actGetHomeEquityLatestColdOfferRequest(),
      ])

      this.redirectUserToOffersPage()
    },
    redirectUserToOffersPage() {
      this.$router.push({
        name: 'ofertas',
        query: {
          last_product_simulation: this.cptdProduct,
        },
      })
    },
    async checkNoCollateralSimulationResult() {
      if (
        this.cptdProduct === 'no-collateral' &&
        this.gtNoCollateralLastSimulationId
      ) {
        const response = await this.actTrackNoCollateralSimulationEnd({
          simulationId: this.gtNoCollateralLastSimulationId,
        })

        if (response) {
          this.$router.push({ path: '/ofertas' })
        }
      }
    },
    async checkAutoColdOfferSimulationResult() {
      if (this.cptdProduct === 'auto' && this.gtAutoColdOfferLastSimulationId) {
        const response = await this.actTrackAutoColdOfferSimulationEnd({
          requestId: this.gtAutoColdOfferLastSimulationId,
        })

        if (response) {
          this.onAutoEnded()
        }
      }
    },
  },
}
</script>
<style lang="scss">
.LoanSimulatingPage {
  & > .main {
    & > .ContainerWrapper {
      @include media('lg') {
        max-width: unset !important;
      }
    }
  }
}
</style>
