export const createUrlQueryParams = (
  options: Record<string, any> | undefined,
) => {
  if (!options || !Object.keys(options).length) {
    return ''
  }
  return '?'.concat(
    Object.keys(options)
      .sort()
      .filter((k) => options[k] !== undefined)
      .map((k) => {
        if (Array.isArray(options[k])) {
          // eslint-disable-next-line
          // @ts-ignore
          return `${encodeURIComponent(k)}=${options[k].join(
            `&${encodeURIComponent(k)}=${encodeURIComponent(options[k])}`,
          )}`
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(options[k])}`
      })
      .join('&'),
  )
}

export const createNoEncondedUrlQueryParams = (
  options: Record<string, any> | undefined,
  separator = true,
) => {
  if (!options || !Object.keys(options).length) {
    return ''
  }
  const query = separator ? '?' : ''
  return query.concat(
    Object.keys(options)
      .sort()
      .filter((k) => options[k] !== undefined)
      .map((k) => {
        if (Array.isArray(options[k])) {
          // eslint-disable-next-line
          // @ts-ignore
          return `${k}=${options[k].join(`&${k}=${options[k]}`)}`
        }
        return `${k}=${options[k]}`
      })
      .join('&'),
  )
}

export const removeQueryString = (url, queryStringName) => {
  const regex = new RegExp(`${queryStringName}=(([^&#]*)(&)?)`, 'gi')
  return url.replace(regex, '')
}
