import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsPublicAPI } from '@/helpers/axios/jurosbaixos-systems'
import { UserTokenRequest } from '@/data/user/user'
import { JWTTokenResponse } from '@/data/authentication/jwt'

const register = async (payload): Promise<AxiosResponse<JWTTokenResponse>> => {
  return await JurosBaixosSystemsPublicAPI.post(
    `/token/register`,
    payload,
  ).catch(createRequestException)
}

const verify = async (payload): Promise<AxiosResponse<JWTTokenResponse>> => {
  return await JurosBaixosSystemsPublicAPI.post(`/token/verify`, payload).catch(
    createRequestException,
  )
}

const login = async (payload: UserTokenRequest) => {
  return await JurosBaixosSystemsPublicAPI.post('/token/login', payload).catch(
    createRequestException,
  )
}

export default { register, verify, login }
