<template>
  <div :id="id" :key="id" class="RadioInputList">
    <div v-if="title" class="title" :class="cssSearchable">
      <label>{{ title }}<label class="required">&nbsp;*</label></label>
      <span v-if="description" class="description">
        <BaseIcon name="help-mono" @click="displayDescriptionDialog = true" />
        <Dialog
          :display="displayDescriptionDialog"
          @close="displayDescriptionDialog = false"
        >
          <template #header-title>
            {{ title }}
          </template>
          <template #content>
            {{ description }}
          </template>
          <template #footer>
            <Button @click="displayDescriptionDialog = false"> Entendi </Button>
          </template>
        </Dialog>
      </span>
    </div>
    <div v-if="searchable" class="search">
      <TextInput
        id="name"
        v-model="search"
        class="field required"
        name="name"
        :placeholder="searchPlaceholder"
        :helper-message="searchHelperMessage"
        :presentation="true"
        :validations="{ required: false }"
      />
    </div>
    <div class="validation">
      <span v-if="helperMessage" class="helper">{{ helperMessage }}</span>
      <span
        v-if="(validation || meta.touched) && errorMessage"
        class="error-message"
      >
        {{ errorMessage }}
      </span>
    </div>
    <div class="radio-list" :class="{ radius: radius }" tabindex="0">
      <Radio
        v-for="op in cptdSearchResult"
        :id="op.value"
        :key="op.value"
        v-model="value"
        :label="op.label"
        :badge-info="op.options?.badge?.label || ''"
        :description="op.description || ''"
        :value="op.value"
        :disabled="op.disabled"
        :radius="radius"
        :checked="value === op.value"
        :value-type="valueType"
        @input="onRadioChange($event)"
      />
    </div>
  </div>
</template>
<script>
import { normalizeString } from '@/helpers/globalFunctions'
import Button from '@/aasgard/components/buttons/Button.vue'
import Dialog from '@/aasgard/components/modals/Dialog.vue'
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
import TextInput from '@/components/ui/newComponents/TextInput.vue'
import Radio from '@/aasgard/components/form/Radio.vue'
import { toRef } from 'vue'
import { useField } from 'vee-validate'
export default {
  name: 'RadioInputList',
  components: {
    Button,
    Dialog,
    BaseIcon,
    TextInput,
    Radio,
  },
  inheritAttrs: false,
  props: {
    modelValue: { type: [String, Number, Boolean], default: '' },
    id: { type: String, required: true },
    searchPlaceholder: { type: String, default: '' },
    validations: { type: [Object, String], default: 'radioRequired' },
    validation: { type: Boolean, default: false },
    description: { type: String, default: '' },
    searchHelperMessage: { type: String, default: '' },
    helperMessage: { type: String, default: '' },
    title: { type: String, default: '' },
    valueType: { type: String, default: 'string' },
    placeholder: { type: String, default: 'Selecione' },
    searchable: { type: Boolean, default: true },
    radius: { type: Boolean, default: false },
    options: { type: Array, required: true },
  },
  emits: ['update:modelValue', 'input'],
  setup(props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'id')
    const rules = toRef(props, 'validations')

    const { errorMessage, setTouched, handleChange, meta, value, resetField } =
      useField(name, rules, {
        syncVModel: true,
      })

    return {
      handleChange,
      setTouched,
      errorMessage,
      meta,
      value,
      resetField,
    }
  },
  data() {
    return {
      search: '',
      displayDescriptionDialog: false,
      errors: [],
    }
  },
  computed: {
    cptdSearchResult() {
      if (!this.options) return []

      if (this.search) {
        return this.options.filter((op) =>
          normalizeString(String(op.label).toLowerCase()).includes(
            normalizeString(String(this.search).toLowerCase()),
          ),
        )
      }

      return this.options
    },
    cssSearchable() {
      return this.searchable ? 'searchable' : ''
    },
  },
  mounted() {
    if (
      this.searchable &&
      this.value &&
      ['string', 'number'].includes(typeof this.value)
    ) {
      this.search =
        this.options.find((opt) => opt.value === this.value)?.label || ''
    }
    if (this.value) {
      this.setTouched(true)
    }
  },
  methods: {
    onRadioChange(value) {
      this.setTouched(true)
      this.handleChange(value)
      this.$emit('input', value)
    },
  },
}
</script>
<style lang="scss">
.RadioInputList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 16px 0;

  & > .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 16px 0;

    & > label {
      @include subtitle-1;
      color: $color-black-high;

      &.-optional {
        justify-content: space-between;

        & > span {
          @include body-2;
          color: $color-black-medium;
        }
      }

      &.-disabled {
        color: $color-brand-tertiary;
      }

      & > .required {
        color: $color-feedback-negative;
      }
    }

    & > .description {
      cursor: pointer;
      margin: 0 0 0 16px;
      height: 24px;
    }

    &.searchable {
      margin: 0 0 8px 0;
    }
  }

  & > .search {
    width: 100%;

    .text-input {
      position: relative;
      padding: 0;
      margin: 0 0 14px 0;

      & > .input-wrapper {
        & > .input-field {
          border-top: none;
          border-left: none;
          border-right: none;
          border-width: 1px;
          border-radius: 0;
          padding: 12px 16px 12px 0;

          &:focus,
          &.-valid,
          &.-invalid {
            border-width: 2px;
          }
        }
      }
    }
  }

  & > .radio-list {
    width: 100%;

    &.radius {
      .Radio {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .Radio {
      width: 100%;
    }
  }

  & > .validation {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:empty {
      display: none;
      margin: 0;
    }

    & > .error-message {
      @include caption;
      margin: 8px 0 0 0;
      color: $color-feedback-negative;
    }

    & > .helper {
      @include caption;
      display: block;
      margin: 8px 0 0 0;
      color: $color-black-medium;
    }
  }
}
</style>
