import { AxiosResponse } from 'axios'
import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'
import { InfobipEventRequest } from '@/data/events/eventapi'
export const postUserEvent = async (
  request: InfobipEventRequest,
): Promise<AxiosResponse> => {
  return await JurosBaixosSystemsAPI.post(`/user/event`, request).catch(
    createRequestException,
  )
}
