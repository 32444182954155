<template>
  <SimpleLayout>
    <ContainerWrapper class="JbPrimeWelcomePageContainer">
      <NavBar
        :display-back-button="false"
        @close="$router.push({ path: '/ofertas' })"
      />
      <ContentContainerWrapper>
        <RequestedOfferComponent
          :partner-name="partnerName"
          :steps="partnerSteps"
          cta-text="Ir para Ofertas"
          @cta-clicked="$router.push({ path: '/ofertas' })"
        />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </SimpleLayout>
</template>
<script>
import { PartnersHiringSteps, PartnersName } from '@/helpers/partners/common'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import { defineAsyncComponent } from 'vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import { Partner } from '@/data/common'
const RequestedOfferComponent = defineAsyncComponent(
  () => import('@/components/hiring/RequestedOfferComponent.vue'),
)
export default {
  name: 'OfferAcceptedPage',
  components: {
    SimpleLayout,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
    RequestedOfferComponent,
  },
  computed: {
    ...mapGetters({
      gtHomeEquityLatestColdOfferRequest:
        'products/loans/home-equity/cold-offer/gtHomeEquityLatestColdOfferRequest',
      gtHomeEquityExtraQuestions:
        'products/loans/home-equity/cold-offer/gtHomeEquityExtraQuestions',
      gtUser: 'user/user/gtUser',
    }),
    partnerName() {
      if (this.$route?.params?.partner) {
        return (
          PartnersName[this.$route?.params?.partner.toUpperCase() ?? ''] ?? ''
        )
      }

      return ''
    },
    partnerSteps() {
      if (this.$route?.params?.partner) {
        return (
          PartnersHiringSteps[
            this.$route?.params?.partner.toUpperCase() ?? ''
          ] ?? ''
        )
      }

      return []
    },
  },
  mounted() {
    this.onMountedActions()
  },
  methods: {
    ...mapActions({
      actEmitGaEvent: 'services/ga/actEmitGaEvent',
      actInsertIntent: 'user/intent/actInsertIntent',
      actSetLastLoanInteraction: 'ui/user-activity/actSetLastLoanInteraction',
      getCep: 'services/address/getCep',
    }),
    onMountedActions() {
      switch (this.$route?.params?.partner?.toUpperCase()) {
        case Partner.FLOWCREDI:
          this.selectFlowCrediOffer()
          break
        default:
          break
      }
    },
    async selectFlowCrediOffer() {
      await this.actInsertIntent({
        identifier: 'PARTNER_FLOWCREDI_ACCEPTED',
      })

      try {
        const options = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
        let cepData = {}
        if (this?.gtHomeEquityLatestColdOfferRequest?.zip_code) {
          const response = await this.getCep(
            this.gtHomeEquityLatestColdOfferRequest?.zip_code,
          )
          cepData = response.data
        }

        await axios.post(
          'https://hooks.zapier.com/hooks/catch/13541339/3jir637/',
          JSON.stringify({
            name: this.gtUser?.name,
            cpf: this?.gtUser?.cpf,
            mobile_phone: this?.gtUser?.mobile_phone,
            email: this?.gtUser?.email,
            birth_date: this?.gtHomeEquityLatestColdOfferRequest?.birth_date,
            monthly_income:
              this?.gtHomeEquityLatestColdOfferRequest?.monthly_income,
            collateral_value:
              this?.gtHomeEquityLatestColdOfferRequest?.collateral_value,
            requested_amount:
              this?.gtHomeEquityLatestColdOfferRequest?.requested_loan_amount,
            collateral_state:
              cepData?.state ||
              this?.gtHomeEquityLatestColdOfferRequest?.zip_code,
            collateral_city:
              cepData?.city ||
              this?.gtHomeEquityLatestColdOfferRequest?.zip_code,
            is_paid: !this?.gtHomeEquityLatestColdOfferRequest?.residence_debt, // se é quitada
            outstanding_balance: this?.gtHomeEquityLatestColdOfferRequest
              ?.residence_debt
              ? this?.gtHomeEquityExtraQuestions?.outstanding_financing
              : 0,
          }),
          options,
        )
      } catch (e) {}
    },
  },
}
</script>
<style lang="scss"></style>
