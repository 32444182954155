import axios from 'axios'
import { debounce as _debounce } from 'lodash'
import JbOAuth from '@/helpers/oauth'
import { trimAllObjectValues } from '@/helpers/globalFunctions'
import { HttpStatusCode } from '@/helpers/jb-error'

const JurosBaixosSystemsAPIConfig = {
  baseURL: import.meta.env.VITE_JUROS_BAIXOS_SYSTEMS_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  paramsSerializer: {
    indexes: null,
  },
}

// JUROSBAIXOS-SYSTEMS API
export const JurosBaixosSystemsAPI = axios.create({
  ...JurosBaixosSystemsAPIConfig,
})

const refreshToken = _debounce(() => JbOAuth.jwtRefresh(), 1500)

JurosBaixosSystemsAPI.interceptors.request.use((config) => {
  refreshToken()

  if (config.data) {
    trimAllObjectValues(config.data)
  }

  config.headers.Authorization = `Bearer ${JbOAuth.getAccessToken()}`
  return config
})

JurosBaixosSystemsAPI.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === HttpStatusCode.UNAUTHORIZED) {
      JbOAuth.logout()
    }
    return Promise.reject(error)
  },
)

// JUROSBAIXOS-SYSTEMS PUBLIC API
export const JurosBaixosSystemsPublicAPI = axios.create({
  ...JurosBaixosSystemsAPIConfig,
  paramsSerializer: {
    indexes: null,
  },
})

JurosBaixosSystemsPublicAPI.interceptors.request.use((config) => {
  if (config.data) {
    trimAllObjectValues(config.data)
  }
  return config
})
