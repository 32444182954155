import { throttle as _throttle } from 'lodash'
import { isElementVisible } from '@/helpers/globalFunctions'

export default {
  'when-visible': {
    created(el, binding) {
      el.onVisible = _throttle(function () {
        if (isElementVisible(el)) {
          binding.value()
          document.body.removeEventListener('scroll', el.onVisible)
        }
      }, 300)
      document.body.addEventListener('scroll', el.onVisible)
      setTimeout(el.onVisible, 1000)
    },
    beforeUnmount(el) {
      document.body.removeEventListener('click', el.onVisible)
    },
  },
}
