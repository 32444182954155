import axios from 'axios'
import * as Sentry from '@sentry/browser'

const actions = {
  async registerInsuranceTypes(_, payload) {
    try {
      const options = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
      await axios.post(
        `https://hooks.zapier.com/hooks/catch/719337/bwsw7ut/`,
        JSON.stringify(payload),
        options,
      )
    } catch (error) {
      Sentry.captureException(error)
    }
  },
}

export default { namespaced: true, actions }
