const cities = {
  AC: [
    { value: 'Acrelândia', name: 'Acrelândia', label: 'Acrelândia' },
    { value: 'Assis Brasil', name: 'Assis Brasil', label: 'Assis Brasil' },
    { value: 'Brasiléia', name: 'Brasiléia', label: 'Brasiléia' },
    { value: 'Bujari', name: 'Bujari', label: 'Bujari' },
    { value: 'Capixaba', name: 'Capixaba', label: 'Capixaba' },
    {
      value: 'Cruzeiro do Sul',
      name: 'Cruzeiro do Sul',
      label: 'Cruzeiro do Sul',
    },
    {
      value: 'Epitaciolândia',
      name: 'Epitaciolândia',
      label: 'Epitaciolândia',
    },
    { value: 'Feijó', name: 'Feijó', label: 'Feijó' },
    { value: 'Jordão', name: 'Jordão', label: 'Jordão' },
    { value: 'Mâncio Lima', name: 'Mâncio Lima', label: 'Mâncio Lima' },
    { value: 'Manoel Urbano', name: 'Manoel Urbano', label: 'Manoel Urbano' },
    {
      value: 'Marechal Thaumaturgo',
      name: 'Marechal Thaumaturgo',
      label: 'Marechal Thaumaturgo',
    },
    {
      value: 'Plácido de Castro',
      name: 'Plácido de Castro',
      label: 'Plácido de Castro',
    },
    { value: 'Porto Acre', name: 'Porto Acre', label: 'Porto Acre' },
    { value: 'Porto Walter', name: 'Porto Walter', label: 'Porto Walter' },
    { value: 'Rio Branco', name: 'Rio Branco', label: 'Rio Branco' },
    {
      value: 'Rodrigues Alves',
      name: 'Rodrigues Alves',
      label: 'Rodrigues Alves',
    },
    {
      value: 'Santa Rosa do Purus',
      name: 'Santa Rosa do Purus',
      label: 'Santa Rosa do Purus',
    },
    {
      value: 'Sena Madureira',
      name: 'Sena Madureira',
      label: 'Sena Madureira',
    },
    {
      value: 'Senador Guiomard',
      name: 'Senador Guiomard',
      label: 'Senador Guiomard',
    },
    { value: 'Tarauacá', name: 'Tarauacá', label: 'Tarauacá' },
    { value: 'Xapuri', name: 'Xapuri', label: 'Xapuri' },
  ],
  AL: [
    { value: 'Água Branca', name: 'Água Branca', label: 'Água Branca' },
    { value: 'Anadia', name: 'Anadia', label: 'Anadia' },
    { value: 'Arapiraca', name: 'Arapiraca', label: 'Arapiraca' },
    { value: 'Atalaia', name: 'Atalaia', label: 'Atalaia' },
    {
      value: 'Barra de Santo Antônio',
      name: 'Barra de Santo Antônio',
      label: 'Barra de Santo Antônio',
    },
    {
      value: 'Barra de São Miguel',
      name: 'Barra de São Miguel',
      label: 'Barra de São Miguel',
    },
    { value: 'Batalha', name: 'Batalha', label: 'Batalha' },
    { value: 'Belém', name: 'Belém', label: 'Belém' },
    { value: 'Belo Monte', name: 'Belo Monte', label: 'Belo Monte' },
    { value: 'Boca da Mata', name: 'Boca da Mata', label: 'Boca da Mata' },
    { value: 'Branquinha', name: 'Branquinha', label: 'Branquinha' },
    { value: 'Cacimbinhas', name: 'Cacimbinhas', label: 'Cacimbinhas' },
    { value: 'Cajueiro', name: 'Cajueiro', label: 'Cajueiro' },
    { value: 'Campestre', name: 'Campestre', label: 'Campestre' },
    { value: 'Campo Alegre', name: 'Campo Alegre', label: 'Campo Alegre' },
    { value: 'Campo Grande', name: 'Campo Grande', label: 'Campo Grande' },
    { value: 'Canapi', name: 'Canapi', label: 'Canapi' },
    { value: 'Capela', name: 'Capela', label: 'Capela' },
    { value: 'Carneiros', name: 'Carneiros', label: 'Carneiros' },
    { value: 'Chã Preta', name: 'Chã Preta', label: 'Chã Preta' },
    { value: 'Coité do Nóia', name: 'Coité do Nóia', label: 'Coité do Nóia' },
    {
      value: 'Colônia Leopoldina',
      name: 'Colônia Leopoldina',
      label: 'Colônia Leopoldina',
    },
    { value: 'Coqueiro Seco', name: 'Coqueiro Seco', label: 'Coqueiro Seco' },
    { value: 'Coruripe', name: 'Coruripe', label: 'Coruripe' },
    { value: 'Craíbas', name: 'Craíbas', label: 'Craíbas' },
    {
      value: 'Delmiro Gouveia',
      name: 'Delmiro Gouveia',
      label: 'Delmiro Gouveia',
    },
    { value: 'Dois Riachos', name: 'Dois Riachos', label: 'Dois Riachos' },
    {
      value: 'Estrela de Alagoas',
      name: 'Estrela de Alagoas',
      label: 'Estrela de Alagoas',
    },
    { value: 'Feira Grande', name: 'Feira Grande', label: 'Feira Grande' },
    { value: 'Feliz Deserto', name: 'Feliz Deserto', label: 'Feliz Deserto' },
    { value: 'Flexeiras', name: 'Flexeiras', label: 'Flexeiras' },
    {
      value: 'Girau do Ponciano',
      name: 'Girau do Ponciano',
      label: 'Girau do Ponciano',
    },
    { value: 'Ibateguara', name: 'Ibateguara', label: 'Ibateguara' },
    { value: 'Igaci', name: 'Igaci', label: 'Igaci' },
    { value: 'Igreja Nova', name: 'Igreja Nova', label: 'Igreja Nova' },
    { value: 'Inhapi', name: 'Inhapi', label: 'Inhapi' },
    {
      value: 'Jacaré dos Homens',
      name: 'Jacaré dos Homens',
      label: 'Jacaré dos Homens',
    },
    { value: 'Jacuípe', name: 'Jacuípe', label: 'Jacuípe' },
    { value: 'Japaratinga', name: 'Japaratinga', label: 'Japaratinga' },
    { value: 'Jaramataia', name: 'Jaramataia', label: 'Jaramataia' },
    {
      value: 'Jequiá da Praia',
      name: 'Jequiá da Praia',
      label: 'Jequiá da Praia',
    },
    { value: 'Joaquim Gomes', name: 'Joaquim Gomes', label: 'Joaquim Gomes' },
    { value: 'Jundiá', name: 'Jundiá', label: 'Jundiá' },
    { value: 'Junqueiro', name: 'Junqueiro', label: 'Junqueiro' },
    {
      value: 'Lagoa da Canoa',
      name: 'Lagoa da Canoa',
      label: 'Lagoa da Canoa',
    },
    {
      value: 'Limoeiro de Anadia',
      name: 'Limoeiro de Anadia',
      label: 'Limoeiro de Anadia',
    },
    { value: 'Maceió', name: 'Maceió', label: 'Maceió' },
    { value: 'Major Isidoro', name: 'Major Isidoro', label: 'Major Isidoro' },
    { value: 'Mar Vermelho', name: 'Mar Vermelho', label: 'Mar Vermelho' },
    { value: 'Maragogi', name: 'Maragogi', label: 'Maragogi' },
    { value: 'Maravilha', name: 'Maravilha', label: 'Maravilha' },
    {
      value: 'Marechal Deodoro',
      name: 'Marechal Deodoro',
      label: 'Marechal Deodoro',
    },
    { value: 'Maribondo', name: 'Maribondo', label: 'Maribondo' },
    { value: 'Mata Grande', name: 'Mata Grande', label: 'Mata Grande' },
    {
      value: 'Matriz de Camaragibe',
      name: 'Matriz de Camaragibe',
      label: 'Matriz de Camaragibe',
    },
    { value: 'Messias', name: 'Messias', label: 'Messias' },
    {
      value: 'Minador do Negrão',
      name: 'Minador do Negrão',
      label: 'Minador do Negrão',
    },
    { value: 'Monteirópolis', name: 'Monteirópolis', label: 'Monteirópolis' },
    { value: 'Murici', name: 'Murici', label: 'Murici' },
    { value: 'Novo Lino', name: 'Novo Lino', label: 'Novo Lino' },
    {
      value: "Olho D'Água das Flores",
      name: "Olho D'Água das Flores",
      label: "Olho D'Água das Flores",
    },
    {
      value: "Olho D'Água do Casado",
      name: "Olho D'Água do Casado",
      label: "Olho D'Água do Casado",
    },
    {
      value: "Olho D'Água Grande",
      name: "Olho D'Água Grande",
      label: "Olho D'Água Grande",
    },
    { value: 'Olivença', name: 'Olivença', label: 'Olivença' },
    { value: 'Ouro Branco', name: 'Ouro Branco', label: 'Ouro Branco' },
    { value: 'Palestina', name: 'Palestina', label: 'Palestina' },
    {
      value: 'Palmeira dos Índios',
      name: 'Palmeira dos Índios',
      label: 'Palmeira dos Índios',
    },
    { value: 'Pão de Açúcar', name: 'Pão de Açúcar', label: 'Pão de Açúcar' },
    { value: 'Pariconha', name: 'Pariconha', label: 'Pariconha' },
    { value: 'Paripueira', name: 'Paripueira', label: 'Paripueira' },
    {
      value: 'Passo de Camaragibe',
      name: 'Passo de Camaragibe',
      label: 'Passo de Camaragibe',
    },
    { value: 'Paulo Jacinto', name: 'Paulo Jacinto', label: 'Paulo Jacinto' },
    { value: 'Penedo', name: 'Penedo', label: 'Penedo' },
    { value: 'Piaçabuçu', name: 'Piaçabuçu', label: 'Piaçabuçu' },
    { value: 'Pilar', name: 'Pilar', label: 'Pilar' },
    { value: 'Pindoba', name: 'Pindoba', label: 'Pindoba' },
    { value: 'Piranhas', name: 'Piranhas', label: 'Piranhas' },
    {
      value: 'Poço das Trincheiras',
      name: 'Poço das Trincheiras',
      label: 'Poço das Trincheiras',
    },
    { value: 'Porto Calvo', name: 'Porto Calvo', label: 'Porto Calvo' },
    {
      value: 'Porto de Pedras',
      name: 'Porto de Pedras',
      label: 'Porto de Pedras',
    },
    {
      value: 'Porto Real do Colégio',
      name: 'Porto Real do Colégio',
      label: 'Porto Real do Colégio',
    },
    { value: 'Quebrangulo', name: 'Quebrangulo', label: 'Quebrangulo' },
    { value: 'Rio Largo', name: 'Rio Largo', label: 'Rio Largo' },
    { value: 'Roteiro', name: 'Roteiro', label: 'Roteiro' },
    {
      value: 'Santa Luzia do Norte',
      name: 'Santa Luzia do Norte',
      label: 'Santa Luzia do Norte',
    },
    {
      value: 'Santana do Ipanema',
      name: 'Santana do Ipanema',
      label: 'Santana do Ipanema',
    },
    {
      value: 'Santana do Mundaú',
      name: 'Santana do Mundaú',
      label: 'Santana do Mundaú',
    },
    { value: 'São Brás', name: 'São Brás', label: 'São Brás' },
    {
      value: 'São José da Laje',
      name: 'São José da Laje',
      label: 'São José da Laje',
    },
    {
      value: 'São José da Tapera',
      name: 'São José da Tapera',
      label: 'São José da Tapera',
    },
    {
      value: 'São Luís do Quitunde',
      name: 'São Luís do Quitunde',
      label: 'São Luís do Quitunde',
    },
    {
      value: 'São Miguel dos Campos',
      name: 'São Miguel dos Campos',
      label: 'São Miguel dos Campos',
    },
    {
      value: 'São Miguel dos Milagres',
      name: 'São Miguel dos Milagres',
      label: 'São Miguel dos Milagres',
    },
    { value: 'São Sebastião', name: 'São Sebastião', label: 'São Sebastião' },
    { value: 'Satuba', name: 'Satuba', label: 'Satuba' },
    {
      value: 'Senador Rui Palmeira',
      name: 'Senador Rui Palmeira',
      label: 'Senador Rui Palmeira',
    },
    { value: "Tanque D'Arca", name: "Tanque D'Arca", label: "Tanque D'Arca" },
    { value: 'Taquarana', name: 'Taquarana', label: 'Taquarana' },
    {
      value: 'Teotônio Vilela',
      name: 'Teotônio Vilela',
      label: 'Teotônio Vilela',
    },
    { value: 'Traipu', name: 'Traipu', label: 'Traipu' },
    {
      value: 'União dos Palmares',
      name: 'União dos Palmares',
      label: 'União dos Palmares',
    },
    { value: 'Viçosa', name: 'Viçosa', label: 'Viçosa' },
  ],
  AM: [
    { value: 'Alvarães', name: 'Alvarães', label: 'Alvarães' },
    { value: 'Amaturá', name: 'Amaturá', label: 'Amaturá' },
    { value: 'Anamã', name: 'Anamã', label: 'Anamã' },
    { value: 'Anori', name: 'Anori', label: 'Anori' },
    { value: 'Apuí', name: 'Apuí', label: 'Apuí' },
    {
      value: 'Atalaia do Norte',
      name: 'Atalaia do Norte',
      label: 'Atalaia do Norte',
    },
    { value: 'Autazes', name: 'Autazes', label: 'Autazes' },
    { value: 'Barcelos', name: 'Barcelos', label: 'Barcelos' },
    { value: 'Barreirinha', name: 'Barreirinha', label: 'Barreirinha' },
    {
      value: 'Benjamin Constant',
      name: 'Benjamin Constant',
      label: 'Benjamin Constant',
    },
    { value: 'Beruri', name: 'Beruri', label: 'Beruri' },
    {
      value: 'Boa Vista do Ramos',
      name: 'Boa Vista do Ramos',
      label: 'Boa Vista do Ramos',
    },
    { value: 'Boca do Acre', name: 'Boca do Acre', label: 'Boca do Acre' },
    { value: 'Borba', name: 'Borba', label: 'Borba' },
    { value: 'Caapiranga', name: 'Caapiranga', label: 'Caapiranga' },
    { value: 'Canutama', name: 'Canutama', label: 'Canutama' },
    { value: 'Carauari', name: 'Carauari', label: 'Carauari' },
    { value: 'Careiro', name: 'Careiro', label: 'Careiro' },
    {
      value: 'Careiro da Várzea',
      name: 'Careiro da Várzea',
      label: 'Careiro da Várzea',
    },
    { value: 'Coari', name: 'Coari', label: 'Coari' },
    { value: 'Codajás', name: 'Codajás', label: 'Codajás' },
    { value: 'Eirunepé', name: 'Eirunepé', label: 'Eirunepé' },
    { value: 'Envira', name: 'Envira', label: 'Envira' },
    { value: 'Fonte Boa', name: 'Fonte Boa', label: 'Fonte Boa' },
    { value: 'Guajará', name: 'Guajará', label: 'Guajará' },
    { value: 'Humaitá', name: 'Humaitá', label: 'Humaitá' },
    { value: 'Ipixuna', name: 'Ipixuna', label: 'Ipixuna' },
    { value: 'Iranduba', name: 'Iranduba', label: 'Iranduba' },
    { value: 'Itacoatiara', name: 'Itacoatiara', label: 'Itacoatiara' },
    { value: 'Itamarati', name: 'Itamarati', label: 'Itamarati' },
    { value: 'Itapiranga', name: 'Itapiranga', label: 'Itapiranga' },
    { value: 'Japurá', name: 'Japurá', label: 'Japurá' },
    { value: 'Juruá', name: 'Juruá', label: 'Juruá' },
    { value: 'Jutaí', name: 'Jutaí', label: 'Jutaí' },
    { value: 'Lábrea', name: 'Lábrea', label: 'Lábrea' },
    { value: 'Manacapuru', name: 'Manacapuru', label: 'Manacapuru' },
    { value: 'Manaquiri', name: 'Manaquiri', label: 'Manaquiri' },
    { value: 'Manaus', name: 'Manaus', label: 'Manaus' },
    { value: 'Manicoré', name: 'Manicoré', label: 'Manicoré' },
    { value: 'Maraã', name: 'Maraã', label: 'Maraã' },
    { value: 'Maués', name: 'Maués', label: 'Maués' },
    { value: 'Nhamundá', name: 'Nhamundá', label: 'Nhamundá' },
    {
      value: 'Nova Olinda do Norte',
      name: 'Nova Olinda do Norte',
      label: 'Nova Olinda do Norte',
    },
    { value: 'Novo Airão', name: 'Novo Airão', label: 'Novo Airão' },
    { value: 'Novo Aripuanã', name: 'Novo Aripuanã', label: 'Novo Aripuanã' },
    { value: 'Parintins', name: 'Parintins', label: 'Parintins' },
    { value: 'Pauini', name: 'Pauini', label: 'Pauini' },
    {
      value: 'Presidente Figueiredo',
      name: 'Presidente Figueiredo',
      label: 'Presidente Figueiredo',
    },
    {
      value: 'Rio Preto da Eva',
      name: 'Rio Preto da Eva',
      label: 'Rio Preto da Eva',
    },
    {
      value: 'Santa Isabel do Rio Negro',
      name: 'Santa Isabel do Rio Negro',
      label: 'Santa Isabel do Rio Negro',
    },
    {
      value: 'Santo Antônio do Içá',
      name: 'Santo Antônio do Içá',
      label: 'Santo Antônio do Içá',
    },
    {
      value: 'São Gabriel da Cachoeira',
      name: 'São Gabriel da Cachoeira',
      label: 'São Gabriel da Cachoeira',
    },
    {
      value: 'São Paulo de Olivença',
      name: 'São Paulo de Olivença',
      label: 'São Paulo de Olivença',
    },
    {
      value: 'São Sebastião do Uatumã',
      name: 'São Sebastião do Uatumã',
      label: 'São Sebastião do Uatumã',
    },
    { value: 'Silves', name: 'Silves', label: 'Silves' },
    { value: 'Tabatinga', name: 'Tabatinga', label: 'Tabatinga' },
    { value: 'Tapauá', name: 'Tapauá', label: 'Tapauá' },
    { value: 'Tefé', name: 'Tefé', label: 'Tefé' },
    { value: 'Tonantins', name: 'Tonantins', label: 'Tonantins' },
    { value: 'Uarini', name: 'Uarini', label: 'Uarini' },
    { value: 'Urucará', name: 'Urucará', label: 'Urucará' },
    { value: 'Urucurituba', name: 'Urucurituba', label: 'Urucurituba' },
  ],
  AP: [
    { value: 'Amapá', name: 'Amapá', label: 'Amapá' },
    { value: 'Calçoene', name: 'Calçoene', label: 'Calçoene' },
    { value: 'Cutias', name: 'Cutias', label: 'Cutias' },
    {
      value: 'Ferreira Gomes',
      name: 'Ferreira Gomes',
      label: 'Ferreira Gomes',
    },
    { value: 'Itaubal', name: 'Itaubal', label: 'Itaubal' },
    {
      value: 'Laranjal do Jari',
      name: 'Laranjal do Jari',
      label: 'Laranjal do Jari',
    },
    { value: 'Macapá', name: 'Macapá', label: 'Macapá' },
    { value: 'Mazagão', name: 'Mazagão', label: 'Mazagão' },
    { value: 'Oiapoque', name: 'Oiapoque', label: 'Oiapoque' },
    {
      value: 'Pedra Branca do Amapari',
      name: 'Pedra Branca do Amapari',
      label: 'Pedra Branca do Amapari',
    },
    { value: 'Porto Grande', name: 'Porto Grande', label: 'Porto Grande' },
    { value: 'Pracuúba', name: 'Pracuúba', label: 'Pracuúba' },
    { value: 'Santana', name: 'Santana', label: 'Santana' },
    {
      value: 'Serra do Navio',
      name: 'Serra do Navio',
      label: 'Serra do Navio',
    },
    {
      value: 'Tartarugalzinho',
      name: 'Tartarugalzinho',
      label: 'Tartarugalzinho',
    },
    {
      value: 'Vitória do Jari',
      name: 'Vitória do Jari',
      label: 'Vitória do Jari',
    },
  ],
  BA: [
    { value: 'Abaíra', name: 'Abaíra', label: 'Abaíra' },
    { value: 'Abaré', name: 'Abaré', label: 'Abaré' },
    { value: 'Acajutiba', name: 'Acajutiba', label: 'Acajutiba' },
    { value: 'Adustina', name: 'Adustina', label: 'Adustina' },
    { value: 'Água Fria', name: 'Água Fria', label: 'Água Fria' },
    { value: 'Aiquara', name: 'Aiquara', label: 'Aiquara' },
    { value: 'Alagoinhas', name: 'Alagoinhas', label: 'Alagoinhas' },
    { value: 'Alcobaça', name: 'Alcobaça', label: 'Alcobaça' },
    { value: 'Almadina', name: 'Almadina', label: 'Almadina' },
    { value: 'Amargosa', name: 'Amargosa', label: 'Amargosa' },
    {
      value: 'Amélia Rodrigues',
      name: 'Amélia Rodrigues',
      label: 'Amélia Rodrigues',
    },
    {
      value: 'América Dourada',
      name: 'América Dourada',
      label: 'América Dourada',
    },
    { value: 'Anagé', name: 'Anagé', label: 'Anagé' },
    { value: 'Andaraí', name: 'Andaraí', label: 'Andaraí' },
    { value: 'Andorinha', name: 'Andorinha', label: 'Andorinha' },
    { value: 'Angical', name: 'Angical', label: 'Angical' },
    { value: 'Anguera', name: 'Anguera', label: 'Anguera' },
    { value: 'Antas', name: 'Antas', label: 'Antas' },
    {
      value: 'Antônio Cardoso',
      name: 'Antônio Cardoso',
      label: 'Antônio Cardoso',
    },
    {
      value: 'Antônio Gonçalves',
      name: 'Antônio Gonçalves',
      label: 'Antônio Gonçalves',
    },
    { value: 'Aporá', name: 'Aporá', label: 'Aporá' },
    { value: 'Apuarema', name: 'Apuarema', label: 'Apuarema' },
    { value: 'Araças', name: 'Araças', label: 'Araças' },
    { value: 'Aracatu', name: 'Aracatu', label: 'Aracatu' },
    { value: 'Araci', name: 'Araci', label: 'Araci' },
    { value: 'Aramari', name: 'Aramari', label: 'Aramari' },
    { value: 'Arataca', name: 'Arataca', label: 'Arataca' },
    { value: 'Aratuípe', name: 'Aratuípe', label: 'Aratuípe' },
    { value: 'Aurelino Leal', name: 'Aurelino Leal', label: 'Aurelino Leal' },
    { value: 'Baianópolis', name: 'Baianópolis', label: 'Baianópolis' },
    { value: 'Baixa Grande', name: 'Baixa Grande', label: 'Baixa Grande' },
    { value: 'Banzaê', name: 'Banzaê', label: 'Banzaê' },
    { value: 'Barra', name: 'Barra', label: 'Barra' },
    {
      value: 'Barra da Estiva',
      name: 'Barra da Estiva',
      label: 'Barra da Estiva',
    },
    {
      value: 'Barra do Choça',
      name: 'Barra do Choça',
      label: 'Barra do Choça',
    },
    {
      value: 'Barra do Mendes',
      name: 'Barra do Mendes',
      label: 'Barra do Mendes',
    },
    {
      value: 'Barra do Rocha',
      name: 'Barra do Rocha',
      label: 'Barra do Rocha',
    },
    { value: 'Barreiras', name: 'Barreiras', label: 'Barreiras' },
    { value: 'Barro Alto', name: 'Barro Alto', label: 'Barro Alto' },
    { value: 'Barrocas', name: 'Barrocas', label: 'Barrocas' },
    { value: 'Barro Preto', name: 'Barro Preto', label: 'Barro Preto' },
    { value: 'Belmonte', name: 'Belmonte', label: 'Belmonte' },
    { value: 'Belo Campo', name: 'Belo Campo', label: 'Belo Campo' },
    { value: 'Biritinga', name: 'Biritinga', label: 'Biritinga' },
    { value: 'Boa Nova', name: 'Boa Nova', label: 'Boa Nova' },
    {
      value: 'Boa Vista do Tupim',
      name: 'Boa Vista do Tupim',
      label: 'Boa Vista do Tupim',
    },
    {
      value: 'Bom Jesus da Lapa',
      name: 'Bom Jesus da Lapa',
      label: 'Bom Jesus da Lapa',
    },
    {
      value: 'Bom Jesus da Serra',
      name: 'Bom Jesus da Serra',
      label: 'Bom Jesus da Serra',
    },
    { value: 'Boninal', name: 'Boninal', label: 'Boninal' },
    { value: 'Bonito', name: 'Bonito', label: 'Bonito' },
    { value: 'Boquira', name: 'Boquira', label: 'Boquira' },
    { value: 'Botuporã', name: 'Botuporã', label: 'Botuporã' },
    { value: 'Brejões', name: 'Brejões', label: 'Brejões' },
    { value: 'Brejolândia', name: 'Brejolândia', label: 'Brejolândia' },
    {
      value: 'Brotas de Macaúbas',
      name: 'Brotas de Macaúbas',
      label: 'Brotas de Macaúbas',
    },
    { value: 'Brumado', name: 'Brumado', label: 'Brumado' },
    { value: 'Buerarema', name: 'Buerarema', label: 'Buerarema' },
    { value: 'Buritirama', name: 'Buritirama', label: 'Buritirama' },
    { value: 'Caatiba', name: 'Caatiba', label: 'Caatiba' },
    {
      value: 'Cabaceiras do Paraguaçu',
      name: 'Cabaceiras do Paraguaçu',
      label: 'Cabaceiras do Paraguaçu',
    },
    { value: 'Cachoeira', name: 'Cachoeira', label: 'Cachoeira' },
    { value: 'Caculé', name: 'Caculé', label: 'Caculé' },
    { value: 'Caém', name: 'Caém', label: 'Caém' },
    { value: 'Caetanos', name: 'Caetanos', label: 'Caetanos' },
    { value: 'Caetité', name: 'Caetité', label: 'Caetité' },
    { value: 'Cafarnaum', name: 'Cafarnaum', label: 'Cafarnaum' },
    { value: 'Cairu', name: 'Cairu', label: 'Cairu' },
    {
      value: 'Caldeirão Grande',
      name: 'Caldeirão Grande',
      label: 'Caldeirão Grande',
    },
    { value: 'Camacan', name: 'Camacan', label: 'Camacan' },
    { value: 'Camaçari', name: 'Camaçari', label: 'Camaçari' },
    { value: 'Camamu', name: 'Camamu', label: 'Camamu' },
    {
      value: 'Campo Alegre de Lourdes',
      name: 'Campo Alegre de Lourdes',
      label: 'Campo Alegre de Lourdes',
    },
    { value: 'Campo Formoso', name: 'Campo Formoso', label: 'Campo Formoso' },
    { value: 'Canápolis', name: 'Canápolis', label: 'Canápolis' },
    { value: 'Canarana', name: 'Canarana', label: 'Canarana' },
    { value: 'Canavieiras', name: 'Canavieiras', label: 'Canavieiras' },
    { value: 'Candeal', name: 'Candeal', label: 'Candeal' },
    { value: 'Candeias', name: 'Candeias', label: 'Candeias' },
    { value: 'Candiba', name: 'Candiba', label: 'Candiba' },
    { value: 'Cândido Sales', name: 'Cândido Sales', label: 'Cândido Sales' },
    { value: 'Cansanção', name: 'Cansanção', label: 'Cansanção' },
    { value: 'Canudos', name: 'Canudos', label: 'Canudos' },
    {
      value: 'Capela do Alto Alegre',
      name: 'Capela do Alto Alegre',
      label: 'Capela do Alto Alegre',
    },
    { value: 'Capim Grosso', name: 'Capim Grosso', label: 'Capim Grosso' },
    { value: 'Caraíbas', name: 'Caraíbas', label: 'Caraíbas' },
    { value: 'Caravelas', name: 'Caravelas', label: 'Caravelas' },
    {
      value: 'Cardeal da Silva',
      name: 'Cardeal da Silva',
      label: 'Cardeal da Silva',
    },
    { value: 'Carinhanha', name: 'Carinhanha', label: 'Carinhanha' },
    { value: 'Casa Nova', name: 'Casa Nova', label: 'Casa Nova' },
    { value: 'Castro Alves', name: 'Castro Alves', label: 'Castro Alves' },
    { value: 'Catolândia', name: 'Catolândia', label: 'Catolândia' },
    { value: 'Catu', name: 'Catu', label: 'Catu' },
    { value: 'Caturama', name: 'Caturama', label: 'Caturama' },
    { value: 'Central', name: 'Central', label: 'Central' },
    { value: 'Chorrochó', name: 'Chorrochó', label: 'Chorrochó' },
    { value: 'Cícero Dantas', name: 'Cícero Dantas', label: 'Cícero Dantas' },
    { value: 'Cipó', name: 'Cipó', label: 'Cipó' },
    { value: 'Coaraci', name: 'Coaraci', label: 'Coaraci' },
    { value: 'Cocos', name: 'Cocos', label: 'Cocos' },
    {
      value: 'Conceição da Feira',
      name: 'Conceição da Feira',
      label: 'Conceição da Feira',
    },
    {
      value: 'Conceição do Almeida',
      name: 'Conceição do Almeida',
      label: 'Conceição do Almeida',
    },
    {
      value: 'Conceição do Coité',
      name: 'Conceição do Coité',
      label: 'Conceição do Coité',
    },
    {
      value: 'Conceição do Jacuípe',
      name: 'Conceição do Jacuípe',
      label: 'Conceição do Jacuípe',
    },
    { value: 'Conde', name: 'Conde', label: 'Conde' },
    { value: 'Condeúba', name: 'Condeúba', label: 'Condeúba' },
    {
      value: 'Contendas do Sincorá',
      name: 'Contendas do Sincorá',
      label: 'Contendas do Sincorá',
    },
    {
      value: 'Coração de Maria',
      name: 'Coração de Maria',
      label: 'Coração de Maria',
    },
    { value: 'Cordeiros', name: 'Cordeiros', label: 'Cordeiros' },
    { value: 'Coribe', name: 'Coribe', label: 'Coribe' },
    {
      value: 'Coronel João Sá',
      name: 'Coronel João Sá',
      label: 'Coronel João Sá',
    },
    { value: 'Correntina', name: 'Correntina', label: 'Correntina' },
    { value: 'Cotegipe', name: 'Cotegipe', label: 'Cotegipe' },
    { value: 'Cravolândia', name: 'Cravolândia', label: 'Cravolândia' },
    { value: 'Crisópolis', name: 'Crisópolis', label: 'Crisópolis' },
    { value: 'Cristópolis', name: 'Cristópolis', label: 'Cristópolis' },
    {
      value: 'Cruz das Almas',
      name: 'Cruz das Almas',
      label: 'Cruz das Almas',
    },
    { value: 'Curaçá', name: 'Curaçá', label: 'Curaçá' },
    { value: 'Dário Meira', name: 'Dário Meira', label: 'Dário Meira' },
    { value: "Dias D'Ávila", name: "Dias D'Ávila", label: "Dias D'Ávila" },
    { value: 'Dom Basílio', name: 'Dom Basílio', label: 'Dom Basílio' },
    {
      value: 'Dom Macedo Costa',
      name: 'Dom Macedo Costa',
      label: 'Dom Macedo Costa',
    },
    {
      value: 'Elísio Medrado',
      name: 'Elísio Medrado',
      label: 'Elísio Medrado',
    },
    { value: 'Encruzilhada', name: 'Encruzilhada', label: 'Encruzilhada' },
    { value: 'Entre Rios', name: 'Entre Rios', label: 'Entre Rios' },
    { value: 'Érico Cardoso', name: 'Érico Cardoso', label: 'Érico Cardoso' },
    { value: 'Esplanada', name: 'Esplanada', label: 'Esplanada' },
    {
      value: 'Euclides da Cunha',
      name: 'Euclides da Cunha',
      label: 'Euclides da Cunha',
    },
    { value: 'Eunápolis', name: 'Eunápolis', label: 'Eunápolis' },
    { value: 'Fátima', name: 'Fátima', label: 'Fátima' },
    { value: 'Feira da Mata', name: 'Feira da Mata', label: 'Feira da Mata' },
    {
      value: 'Feira de Santana',
      name: 'Feira de Santana',
      label: 'Feira de Santana',
    },
    { value: 'Filadélfia', name: 'Filadélfia', label: 'Filadélfia' },
    { value: 'Firmino Alves', name: 'Firmino Alves', label: 'Firmino Alves' },
    { value: 'Floresta Azul', name: 'Floresta Azul', label: 'Floresta Azul' },
    {
      value: 'Formosa do Rio Preto',
      name: 'Formosa do Rio Preto',
      label: 'Formosa do Rio Preto',
    },
    { value: 'Gandu', name: 'Gandu', label: 'Gandu' },
    { value: 'Gavião', name: 'Gavião', label: 'Gavião' },
    {
      value: 'Gentio do Ouro',
      name: 'Gentio do Ouro',
      label: 'Gentio do Ouro',
    },
    { value: 'Glória', name: 'Glória', label: 'Glória' },
    { value: 'Gongogi', name: 'Gongogi', label: 'Gongogi' },
    {
      value: 'Governador Mangabeira',
      name: 'Governador Mangabeira',
      label: 'Governador Mangabeira',
    },
    { value: 'Guajeru', name: 'Guajeru', label: 'Guajeru' },
    { value: 'Guanambi', name: 'Guanambi', label: 'Guanambi' },
    { value: 'Guaratinga', name: 'Guaratinga', label: 'Guaratinga' },
    { value: 'Heliópolis', name: 'Heliópolis', label: 'Heliópolis' },
    { value: 'Iaçu', name: 'Iaçu', label: 'Iaçu' },
    { value: 'Ibiassucê', name: 'Ibiassucê', label: 'Ibiassucê' },
    { value: 'Ibicaraí', name: 'Ibicaraí', label: 'Ibicaraí' },
    { value: 'Ibicoara', name: 'Ibicoara', label: 'Ibicoara' },
    { value: 'Ibicuí', name: 'Ibicuí', label: 'Ibicuí' },
    { value: 'Ibipeba', name: 'Ibipeba', label: 'Ibipeba' },
    { value: 'Ibipitanga', name: 'Ibipitanga', label: 'Ibipitanga' },
    { value: 'Ibiquera', name: 'Ibiquera', label: 'Ibiquera' },
    { value: 'Ibirapitanga', name: 'Ibirapitanga', label: 'Ibirapitanga' },
    { value: 'Ibirapuã', name: 'Ibirapuã', label: 'Ibirapuã' },
    { value: 'Ibirataia', name: 'Ibirataia', label: 'Ibirataia' },
    { value: 'Ibitiara', name: 'Ibitiara', label: 'Ibitiara' },
    { value: 'Ibititá', name: 'Ibititá', label: 'Ibititá' },
    { value: 'Ibotirama', name: 'Ibotirama', label: 'Ibotirama' },
    { value: 'Ichu', name: 'Ichu', label: 'Ichu' },
    { value: 'Igaporã', name: 'Igaporã', label: 'Igaporã' },
    { value: 'Igrapiúna', name: 'Igrapiúna', label: 'Igrapiúna' },
    { value: 'Iguaí', name: 'Iguaí', label: 'Iguaí' },
    { value: 'Ilhéus', name: 'Ilhéus', label: 'Ilhéus' },
    { value: 'Inhambupe', name: 'Inhambupe', label: 'Inhambupe' },
    { value: 'Ipecaetá', name: 'Ipecaetá', label: 'Ipecaetá' },
    { value: 'Ipiaú', name: 'Ipiaú', label: 'Ipiaú' },
    { value: 'Ipirá', name: 'Ipirá', label: 'Ipirá' },
    { value: 'Ipupiara', name: 'Ipupiara', label: 'Ipupiara' },
    { value: 'Irajuba', name: 'Irajuba', label: 'Irajuba' },
    { value: 'Iramaia', name: 'Iramaia', label: 'Iramaia' },
    { value: 'Iraquara', name: 'Iraquara', label: 'Iraquara' },
    { value: 'Irará', name: 'Irará', label: 'Irará' },
    { value: 'Irecê', name: 'Irecê', label: 'Irecê' },
    { value: 'Itabela', name: 'Itabela', label: 'Itabela' },
    { value: 'Itaberaba', name: 'Itaberaba', label: 'Itaberaba' },
    { value: 'Itabuna', name: 'Itabuna', label: 'Itabuna' },
    { value: 'Itacaré', name: 'Itacaré', label: 'Itacaré' },
    { value: 'Itaeté', name: 'Itaeté', label: 'Itaeté' },
    { value: 'Itagi', name: 'Itagi', label: 'Itagi' },
    { value: 'Itagibá', name: 'Itagibá', label: 'Itagibá' },
    { value: 'Itagimirim', name: 'Itagimirim', label: 'Itagimirim' },
    {
      value: 'Itaguaçu da Bahia',
      name: 'Itaguaçu da Bahia',
      label: 'Itaguaçu da Bahia',
    },
    {
      value: 'Itaju do Colônia',
      name: 'Itaju do Colônia',
      label: 'Itaju do Colônia',
    },
    { value: 'Itajuípe', name: 'Itajuípe', label: 'Itajuípe' },
    { value: 'Itamaraju', name: 'Itamaraju', label: 'Itamaraju' },
    { value: 'Itamari', name: 'Itamari', label: 'Itamari' },
    { value: 'Itambé', name: 'Itambé', label: 'Itambé' },
    { value: 'Itanagra', name: 'Itanagra', label: 'Itanagra' },
    { value: 'Itanhém', name: 'Itanhém', label: 'Itanhém' },
    { value: 'Itaparica', name: 'Itaparica', label: 'Itaparica' },
    { value: 'Itapé', name: 'Itapé', label: 'Itapé' },
    { value: 'Itapebi', name: 'Itapebi', label: 'Itapebi' },
    { value: 'Itapetinga', name: 'Itapetinga', label: 'Itapetinga' },
    { value: 'Itapicuru', name: 'Itapicuru', label: 'Itapicuru' },
    { value: 'Itapitanga', name: 'Itapitanga', label: 'Itapitanga' },
    { value: 'Itaquara', name: 'Itaquara', label: 'Itaquara' },
    { value: 'Itarantim', name: 'Itarantim', label: 'Itarantim' },
    { value: 'Itatim', name: 'Itatim', label: 'Itatim' },
    { value: 'Itiruçu', name: 'Itiruçu', label: 'Itiruçu' },
    { value: 'Itiúba', name: 'Itiúba', label: 'Itiúba' },
    { value: 'Itororó', name: 'Itororó', label: 'Itororó' },
    { value: 'Ituaçu', name: 'Ituaçu', label: 'Ituaçu' },
    { value: 'Ituberá', name: 'Ituberá', label: 'Ituberá' },
    { value: 'Iuiú', name: 'Iuiú', label: 'Iuiú' },
    { value: 'Jaborandi', name: 'Jaborandi', label: 'Jaborandi' },
    { value: 'Jacaraci', name: 'Jacaraci', label: 'Jacaraci' },
    { value: 'Jacobina', name: 'Jacobina', label: 'Jacobina' },
    { value: 'Jaguaquara', name: 'Jaguaquara', label: 'Jaguaquara' },
    { value: 'Jaguarari', name: 'Jaguarari', label: 'Jaguarari' },
    { value: 'Jaguaripe', name: 'Jaguaripe', label: 'Jaguaripe' },
    { value: 'Jandaíra', name: 'Jandaíra', label: 'Jandaíra' },
    { value: 'Jequié', name: 'Jequié', label: 'Jequié' },
    { value: 'Jeremoabo', name: 'Jeremoabo', label: 'Jeremoabo' },
    { value: 'Jiquiriçá', name: 'Jiquiriçá', label: 'Jiquiriçá' },
    { value: 'Jitaúna', name: 'Jitaúna', label: 'Jitaúna' },
    { value: 'João Dourado', name: 'João Dourado', label: 'João Dourado' },
    { value: 'Juazeiro', name: 'Juazeiro', label: 'Juazeiro' },
    { value: 'Jucuruçu', name: 'Jucuruçu', label: 'Jucuruçu' },
    { value: 'Jussara', name: 'Jussara', label: 'Jussara' },
    { value: 'Jussari', name: 'Jussari', label: 'Jussari' },
    { value: 'Jussiape', name: 'Jussiape', label: 'Jussiape' },
    {
      value: 'Lafaiete Coutinho',
      name: 'Lafaiete Coutinho',
      label: 'Lafaiete Coutinho',
    },
    { value: 'Lagoa Real', name: 'Lagoa Real', label: 'Lagoa Real' },
    { value: 'Laje', name: 'Laje', label: 'Laje' },
    { value: 'Lajedão', name: 'Lajedão', label: 'Lajedão' },
    { value: 'Lajedinho', name: 'Lajedinho', label: 'Lajedinho' },
    {
      value: 'Lajedo do Tabocal',
      name: 'Lajedo do Tabocal',
      label: 'Lajedo do Tabocal',
    },
    { value: 'Lamarão', name: 'Lamarão', label: 'Lamarão' },
    { value: 'Lapão', name: 'Lapão', label: 'Lapão' },
    {
      value: 'Lauro de Freitas',
      name: 'Lauro de Freitas',
      label: 'Lauro de Freitas',
    },
    { value: 'Lençóis', name: 'Lençóis', label: 'Lençóis' },
    {
      value: 'Licínio de Almeida',
      name: 'Licínio de Almeida',
      label: 'Licínio de Almeida',
    },
    {
      value: 'Livramento de Nossa Senhora',
      name: 'Livramento de Nossa Senhora',
      label: 'Livramento de Nossa Senhora',
    },
    {
      value: 'Luís Eduardo Magalhães',
      name: 'Luís Eduardo Magalhães',
      label: 'Luís Eduardo Magalhães',
    },
    { value: 'Macajuba', name: 'Macajuba', label: 'Macajuba' },
    { value: 'Macarani', name: 'Macarani', label: 'Macarani' },
    { value: 'Macaúbas', name: 'Macaúbas', label: 'Macaúbas' },
    { value: 'Macururé', name: 'Macururé', label: 'Macururé' },
    { value: 'Madre de Deus', name: 'Madre de Deus', label: 'Madre de Deus' },
    { value: 'Maetinga', name: 'Maetinga', label: 'Maetinga' },
    { value: 'Maiquinique', name: 'Maiquinique', label: 'Maiquinique' },
    { value: 'Mairi', name: 'Mairi', label: 'Mairi' },
    { value: 'Malhada', name: 'Malhada', label: 'Malhada' },
    {
      value: 'Malhada de Pedras',
      name: 'Malhada de Pedras',
      label: 'Malhada de Pedras',
    },
    {
      value: 'Manoel Vitorino',
      name: 'Manoel Vitorino',
      label: 'Manoel Vitorino',
    },
    { value: 'Mansidão', name: 'Mansidão', label: 'Mansidão' },
    { value: 'Maracás', name: 'Maracás', label: 'Maracás' },
    { value: 'Maragogipe', name: 'Maragogipe', label: 'Maragogipe' },
    { value: 'Maraú', name: 'Maraú', label: 'Maraú' },
    {
      value: 'Marcionílio Souza',
      name: 'Marcionílio Souza',
      label: 'Marcionílio Souza',
    },
    { value: 'Mascote', name: 'Mascote', label: 'Mascote' },
    {
      value: 'Mata de São João',
      name: 'Mata de São João',
      label: 'Mata de São João',
    },
    { value: 'Matina', name: 'Matina', label: 'Matina' },
    { value: 'Medeiros Neto', name: 'Medeiros Neto', label: 'Medeiros Neto' },
    { value: 'Miguel Calmon', name: 'Miguel Calmon', label: 'Miguel Calmon' },
    { value: 'Milagres', name: 'Milagres', label: 'Milagres' },
    { value: 'Mirangaba', name: 'Mirangaba', label: 'Mirangaba' },
    { value: 'Mirante', name: 'Mirante', label: 'Mirante' },
    { value: 'Monte Santo', name: 'Monte Santo', label: 'Monte Santo' },
    { value: 'Morpará', name: 'Morpará', label: 'Morpará' },
    {
      value: 'Morro do Chapéu',
      name: 'Morro do Chapéu',
      label: 'Morro do Chapéu',
    },
    { value: 'Mortugaba', name: 'Mortugaba', label: 'Mortugaba' },
    { value: 'Mucugê', name: 'Mucugê', label: 'Mucugê' },
    { value: 'Mucuri', name: 'Mucuri', label: 'Mucuri' },
    {
      value: 'Mulungu do Morro',
      name: 'Mulungu do Morro',
      label: 'Mulungu do Morro',
    },
    { value: 'Mundo Novo', name: 'Mundo Novo', label: 'Mundo Novo' },
    {
      value: 'Muniz Ferreira',
      name: 'Muniz Ferreira',
      label: 'Muniz Ferreira',
    },
    {
      value: 'Muquém do São Francisco',
      name: 'Muquém do São Francisco',
      label: 'Muquém do São Francisco',
    },
    { value: 'Muritiba', name: 'Muritiba', label: 'Muritiba' },
    { value: 'Mutuípe', name: 'Mutuípe', label: 'Mutuípe' },
    { value: 'Nazaré', name: 'Nazaré', label: 'Nazaré' },
    { value: 'Nilo Peçanha', name: 'Nilo Peçanha', label: 'Nilo Peçanha' },
    { value: 'Nordestina', name: 'Nordestina', label: 'Nordestina' },
    { value: 'Nova Canaã', name: 'Nova Canaã', label: 'Nova Canaã' },
    { value: 'Nova Fátima', name: 'Nova Fátima', label: 'Nova Fátima' },
    { value: 'Nova Ibiá', name: 'Nova Ibiá', label: 'Nova Ibiá' },
    { value: 'Nova Itarana', name: 'Nova Itarana', label: 'Nova Itarana' },
    { value: 'Nova Redenção', name: 'Nova Redenção', label: 'Nova Redenção' },
    { value: 'Nova Soure', name: 'Nova Soure', label: 'Nova Soure' },
    { value: 'Nova Viçosa', name: 'Nova Viçosa', label: 'Nova Viçosa' },
    {
      value: 'Novo Horizonte',
      name: 'Novo Horizonte',
      label: 'Novo Horizonte',
    },
    { value: 'Novo Triunfo', name: 'Novo Triunfo', label: 'Novo Triunfo' },
    { value: 'Olindina', name: 'Olindina', label: 'Olindina' },
    {
      value: 'Oliveira dos Brejinhos',
      name: 'Oliveira dos Brejinhos',
      label: 'Oliveira dos Brejinhos',
    },
    { value: 'Ouriçangas', name: 'Ouriçangas', label: 'Ouriçangas' },
    { value: 'Ourolândia', name: 'Ourolândia', label: 'Ourolândia' },
    {
      value: 'Palmas de Monte Alto',
      name: 'Palmas de Monte Alto',
      label: 'Palmas de Monte Alto',
    },
    { value: 'Palmeiras', name: 'Palmeiras', label: 'Palmeiras' },
    { value: 'Paramirim', name: 'Paramirim', label: 'Paramirim' },
    { value: 'Paratinga', name: 'Paratinga', label: 'Paratinga' },
    { value: 'Paripiranga', name: 'Paripiranga', label: 'Paripiranga' },
    { value: 'Pau Brasil', name: 'Pau Brasil', label: 'Pau Brasil' },
    { value: 'Paulo Afonso', name: 'Paulo Afonso', label: 'Paulo Afonso' },
    { value: 'Pé de Serra', name: 'Pé de Serra', label: 'Pé de Serra' },
    { value: 'Pedrão', name: 'Pedrão', label: 'Pedrão' },
    {
      value: 'Pedro Alexandre',
      name: 'Pedro Alexandre',
      label: 'Pedro Alexandre',
    },
    { value: 'Piatã', name: 'Piatã', label: 'Piatã' },
    { value: 'Pilão Arcado', name: 'Pilão Arcado', label: 'Pilão Arcado' },
    { value: 'Pindaí', name: 'Pindaí', label: 'Pindaí' },
    { value: 'Pindobaçu', name: 'Pindobaçu', label: 'Pindobaçu' },
    { value: 'Pintadas', name: 'Pintadas', label: 'Pintadas' },
    {
      value: 'Piraí do Norte',
      name: 'Piraí do Norte',
      label: 'Piraí do Norte',
    },
    { value: 'Piripá', name: 'Piripá', label: 'Piripá' },
    { value: 'Piritiba', name: 'Piritiba', label: 'Piritiba' },
    { value: 'Planaltino', name: 'Planaltino', label: 'Planaltino' },
    { value: 'Planalto', name: 'Planalto', label: 'Planalto' },
    { value: 'Poções', name: 'Poções', label: 'Poções' },
    { value: 'Pojuca', name: 'Pojuca', label: 'Pojuca' },
    { value: 'Ponto Novo', name: 'Ponto Novo', label: 'Ponto Novo' },
    { value: 'Porto Seguro', name: 'Porto Seguro', label: 'Porto Seguro' },
    { value: 'Potiraguá', name: 'Potiraguá', label: 'Potiraguá' },
    { value: 'Prado', name: 'Prado', label: 'Prado' },
    {
      value: 'Presidente Dutra',
      name: 'Presidente Dutra',
      label: 'Presidente Dutra',
    },
    {
      value: 'Presidente Jânio Quadros',
      name: 'Presidente Jânio Quadros',
      label: 'Presidente Jânio Quadros',
    },
    {
      value: 'Presidente Tancredo Neves',
      name: 'Presidente Tancredo Neves',
      label: 'Presidente Tancredo Neves',
    },
    { value: 'Queimadas', name: 'Queimadas', label: 'Queimadas' },
    { value: 'Quijingue', name: 'Quijingue', label: 'Quijingue' },
    { value: 'Quixabeira', name: 'Quixabeira', label: 'Quixabeira' },
    {
      value: 'Rafael Jambeiro',
      name: 'Rafael Jambeiro',
      label: 'Rafael Jambeiro',
    },
    { value: 'Remanso', name: 'Remanso', label: 'Remanso' },
    { value: 'Retirolândia', name: 'Retirolândia', label: 'Retirolândia' },
    {
      value: 'Riachão das Neves',
      name: 'Riachão das Neves',
      label: 'Riachão das Neves',
    },
    {
      value: 'Riachão do Jacuípe',
      name: 'Riachão do Jacuípe',
      label: 'Riachão do Jacuípe',
    },
    {
      value: 'Riacho de Santana',
      name: 'Riacho de Santana',
      label: 'Riacho de Santana',
    },
    {
      value: 'Ribeira do Amparo',
      name: 'Ribeira do Amparo',
      label: 'Ribeira do Amparo',
    },
    {
      value: 'Ribeira do Pombal',
      name: 'Ribeira do Pombal',
      label: 'Ribeira do Pombal',
    },
    {
      value: 'Ribeirão do Largo',
      name: 'Ribeirão do Largo',
      label: 'Ribeirão do Largo',
    },
    { value: 'Rio de Contas', name: 'Rio de Contas', label: 'Rio de Contas' },
    {
      value: 'Rio do Antônio',
      name: 'Rio do Antônio',
      label: 'Rio do Antônio',
    },
    { value: 'Rio do Pires', name: 'Rio do Pires', label: 'Rio do Pires' },
    { value: 'Rio Real', name: 'Rio Real', label: 'Rio Real' },
    { value: 'Rodelas', name: 'Rodelas', label: 'Rodelas' },
    { value: 'Ruy Barbosa', name: 'Ruy Barbosa', label: 'Ruy Barbosa' },
    {
      value: 'Salinas da Margarida',
      name: 'Salinas da Margarida',
      label: 'Salinas da Margarida',
    },
    { value: 'Salvador', name: 'Salvador', label: 'Salvador' },
    { value: 'Santa Bárbara', name: 'Santa Bárbara', label: 'Santa Bárbara' },
    { value: 'Santa Brígida', name: 'Santa Brígida', label: 'Santa Brígida' },
    {
      value: 'Santa Cruz Cabrália',
      name: 'Santa Cruz Cabrália',
      label: 'Santa Cruz Cabrália',
    },
    {
      value: 'Santa Cruz da Vitória',
      name: 'Santa Cruz da Vitória',
      label: 'Santa Cruz da Vitória',
    },
    { value: 'Santa Inês', name: 'Santa Inês', label: 'Santa Inês' },
    { value: 'Santa Luzia', name: 'Santa Luzia', label: 'Santa Luzia' },
    {
      value: 'Santa Maria da Vitória',
      name: 'Santa Maria da Vitória',
      label: 'Santa Maria da Vitória',
    },
    {
      value: 'Santa Rita de Cássia',
      name: 'Santa Rita de Cássia',
      label: 'Santa Rita de Cássia',
    },
    {
      value: 'Santa Teresinha',
      name: 'Santa Teresinha',
      label: 'Santa Teresinha',
    },
    { value: 'Santaluz', name: 'Santaluz', label: 'Santaluz' },
    { value: 'Santana', name: 'Santana', label: 'Santana' },
    { value: 'Santanópolis', name: 'Santanópolis', label: 'Santanópolis' },
    { value: 'Santo Amaro', name: 'Santo Amaro', label: 'Santo Amaro' },
    {
      value: 'Santo Antônio de Jesus',
      name: 'Santo Antônio de Jesus',
      label: 'Santo Antônio de Jesus',
    },
    { value: 'Santo Estêvão', name: 'Santo Estêvão', label: 'Santo Estêvão' },
    { value: 'São Desidério', name: 'São Desidério', label: 'São Desidério' },
    { value: 'São Domingos', name: 'São Domingos', label: 'São Domingos' },
    { value: 'São Felipe', name: 'São Felipe', label: 'São Felipe' },
    { value: 'São Félix', name: 'São Félix', label: 'São Félix' },
    {
      value: 'São Félix do Coribe',
      name: 'São Félix do Coribe',
      label: 'São Félix do Coribe',
    },
    {
      value: 'São Francisco do Conde',
      name: 'São Francisco do Conde',
      label: 'São Francisco do Conde',
    },
    { value: 'São Gabriel', name: 'São Gabriel', label: 'São Gabriel' },
    {
      value: 'São Gonçalo dos Campos',
      name: 'São Gonçalo dos Campos',
      label: 'São Gonçalo dos Campos',
    },
    {
      value: 'São José da Vitória',
      name: 'São José da Vitória',
      label: 'São José da Vitória',
    },
    {
      value: 'São José do Jacuípe',
      name: 'São José do Jacuípe',
      label: 'São José do Jacuípe',
    },
    {
      value: 'São Miguel das Matas',
      name: 'São Miguel das Matas',
      label: 'São Miguel das Matas',
    },
    {
      value: 'São Sebastião do Passé',
      name: 'São Sebastião do Passé',
      label: 'São Sebastião do Passé',
    },
    { value: 'Sapeaçu', name: 'Sapeaçu', label: 'Sapeaçu' },
    { value: 'Sátiro Dias', name: 'Sátiro Dias', label: 'Sátiro Dias' },
    { value: 'Saubara', name: 'Saubara', label: 'Saubara' },
    { value: 'Saúde', name: 'Saúde', label: 'Saúde' },
    { value: 'Seabra', name: 'Seabra', label: 'Seabra' },
    {
      value: 'Sebastião Laranjeiras',
      name: 'Sebastião Laranjeiras',
      label: 'Sebastião Laranjeiras',
    },
    {
      value: 'Senhor do Bonfim',
      name: 'Senhor do Bonfim',
      label: 'Senhor do Bonfim',
    },
    { value: 'Sento Sé', name: 'Sento Sé', label: 'Sento Sé' },
    {
      value: 'Serra do Ramalho',
      name: 'Serra do Ramalho',
      label: 'Serra do Ramalho',
    },
    { value: 'Serra Dourada', name: 'Serra Dourada', label: 'Serra Dourada' },
    { value: 'Serra Preta', name: 'Serra Preta', label: 'Serra Preta' },
    { value: 'Serrinha', name: 'Serrinha', label: 'Serrinha' },
    { value: 'Serrolândia', name: 'Serrolândia', label: 'Serrolândia' },
    { value: 'Simões Filho', name: 'Simões Filho', label: 'Simões Filho' },
    { value: 'Sítio do Mato', name: 'Sítio do Mato', label: 'Sítio do Mato' },
    {
      value: 'Sítio do Quinto',
      name: 'Sítio do Quinto',
      label: 'Sítio do Quinto',
    },
    { value: 'Sobradinho', name: 'Sobradinho', label: 'Sobradinho' },
    { value: 'Souto Soares', name: 'Souto Soares', label: 'Souto Soares' },
    {
      value: 'Tabocas do Brejo Velho',
      name: 'Tabocas do Brejo Velho',
      label: 'Tabocas do Brejo Velho',
    },
    { value: 'Tanhaçu', name: 'Tanhaçu', label: 'Tanhaçu' },
    { value: 'Tanque Novo', name: 'Tanque Novo', label: 'Tanque Novo' },
    { value: 'Tanquinho', name: 'Tanquinho', label: 'Tanquinho' },
    { value: 'Taperoá', name: 'Taperoá', label: 'Taperoá' },
    { value: 'Tapiramutá', name: 'Tapiramutá', label: 'Tapiramutá' },
    {
      value: 'Teixeira de Freitas',
      name: 'Teixeira de Freitas',
      label: 'Teixeira de Freitas',
    },
    {
      value: 'Teodoro Sampaio',
      name: 'Teodoro Sampaio',
      label: 'Teodoro Sampaio',
    },
    { value: 'Teofilândia', name: 'Teofilândia', label: 'Teofilândia' },
    { value: 'Teolândia', name: 'Teolândia', label: 'Teolândia' },
    { value: 'Terra Nova', name: 'Terra Nova', label: 'Terra Nova' },
    { value: 'Tremedal', name: 'Tremedal', label: 'Tremedal' },
    { value: 'Tucano', name: 'Tucano', label: 'Tucano' },
    { value: 'Uauá', name: 'Uauá', label: 'Uauá' },
    { value: 'Ubaíra', name: 'Ubaíra', label: 'Ubaíra' },
    { value: 'Ubaitaba', name: 'Ubaitaba', label: 'Ubaitaba' },
    { value: 'Ubatã', name: 'Ubatã', label: 'Ubatã' },
    { value: 'Uibaí', name: 'Uibaí', label: 'Uibaí' },
    { value: 'Umburanas', name: 'Umburanas', label: 'Umburanas' },
    { value: 'Una', name: 'Una', label: 'Una' },
    { value: 'Urandi', name: 'Urandi', label: 'Urandi' },
    { value: 'Uruçuca', name: 'Uruçuca', label: 'Uruçuca' },
    { value: 'Utinga', name: 'Utinga', label: 'Utinga' },
    { value: 'Valença', name: 'Valença', label: 'Valença' },
    { value: 'Valente', name: 'Valente', label: 'Valente' },
    {
      value: 'Várzea da Roça',
      name: 'Várzea da Roça',
      label: 'Várzea da Roça',
    },
    {
      value: 'Várzea do Poço',
      name: 'Várzea do Poço',
      label: 'Várzea do Poço',
    },
    { value: 'Várzea Nova', name: 'Várzea Nova', label: 'Várzea Nova' },
    { value: 'Varzedo', name: 'Varzedo', label: 'Varzedo' },
    { value: 'Vera Cruz', name: 'Vera Cruz', label: 'Vera Cruz' },
    { value: 'Vereda', name: 'Vereda', label: 'Vereda' },
    {
      value: 'Vitória da Conquista',
      name: 'Vitória da Conquista',
      label: 'Vitória da Conquista',
    },
    { value: 'Wagner', name: 'Wagner', label: 'Wagner' },
    { value: 'Wanderley', name: 'Wanderley', label: 'Wanderley' },
    {
      value: 'Wenceslau Guimarães',
      name: 'Wenceslau Guimarães',
      label: 'Wenceslau Guimarães',
    },
    { value: 'Xique-Xique', name: 'Xique-Xique', label: 'Xique-Xique' },
  ],
  CE: [
    { value: 'Abaiara', name: 'Abaiara', label: 'Abaiara' },
    { value: 'Acarapé', name: 'Acarapé', label: 'Acarapé' },
    { value: 'Acaraú', name: 'Acaraú', label: 'Acaraú' },
    { value: 'Acopiara', name: 'Acopiara', label: 'Acopiara' },
    { value: 'Aiuaba', name: 'Aiuaba', label: 'Aiuaba' },
    { value: 'Alcântaras', name: 'Alcântaras', label: 'Alcântaras' },
    { value: 'Altaneira', name: 'Altaneira', label: 'Altaneira' },
    { value: 'Alto Santo', name: 'Alto Santo', label: 'Alto Santo' },
    { value: 'Amontada', name: 'Amontada', label: 'Amontada' },
    {
      value: 'Antonina do Norte',
      name: 'Antonina do Norte',
      label: 'Antonina do Norte',
    },
    { value: 'Apuiarés', name: 'Apuiarés', label: 'Apuiarés' },
    { value: 'Aquiraz', name: 'Aquiraz', label: 'Aquiraz' },
    { value: 'Aracati', name: 'Aracati', label: 'Aracati' },
    { value: 'Aracatiaçu', name: 'Aracatiaçu', label: 'Aracatiaçu' },
    { value: 'Aracoiaba', name: 'Aracoiaba', label: 'Aracoiaba' },
    { value: 'Ararendá', name: 'Ararendá', label: 'Ararendá' },
    { value: 'Araripe', name: 'Araripe', label: 'Araripe' },
    { value: 'Aratuba', name: 'Aratuba', label: 'Aratuba' },
    { value: 'Arneiroz', name: 'Arneiroz', label: 'Arneiroz' },
    { value: 'Assaré', name: 'Assaré', label: 'Assaré' },
    { value: 'Aurora', name: 'Aurora', label: 'Aurora' },
    { value: 'Baixio', name: 'Baixio', label: 'Baixio' },
    { value: 'Banabuiú', name: 'Banabuiú', label: 'Banabuiú' },
    { value: 'Barbalha', name: 'Barbalha', label: 'Barbalha' },
    { value: 'Barreira', name: 'Barreira', label: 'Barreira' },
    { value: 'Barro', name: 'Barro', label: 'Barro' },
    { value: 'Barroquinha', name: 'Barroquinha', label: 'Barroquinha' },
    { value: 'Baturité', name: 'Baturité', label: 'Baturité' },
    { value: 'Beberibe', name: 'Beberibe', label: 'Beberibe' },
    { value: 'Bela Cruz', name: 'Bela Cruz', label: 'Bela Cruz' },
    { value: 'Boa Viagem', name: 'Boa Viagem', label: 'Boa Viagem' },
    { value: 'Brejo Santo', name: 'Brejo Santo', label: 'Brejo Santo' },
    { value: 'Camocim', name: 'Camocim', label: 'Camocim' },
    { value: 'Campos Sales', name: 'Campos Sales', label: 'Campos Sales' },
    { value: 'Canindé', name: 'Canindé', label: 'Canindé' },
    { value: 'Capistrano', name: 'Capistrano', label: 'Capistrano' },
    { value: 'Caridade', name: 'Caridade', label: 'Caridade' },
    { value: 'Cariré', name: 'Cariré', label: 'Cariré' },
    { value: 'Caririaçu', name: 'Caririaçu', label: 'Caririaçu' },
    { value: 'Cariús', name: 'Cariús', label: 'Cariús' },
    { value: 'Carnaubal', name: 'Carnaubal', label: 'Carnaubal' },
    { value: 'Cascavel', name: 'Cascavel', label: 'Cascavel' },
    { value: 'Catarina', name: 'Catarina', label: 'Catarina' },
    { value: 'Catunda', name: 'Catunda', label: 'Catunda' },
    { value: 'Caucaia', name: 'Caucaia', label: 'Caucaia' },
    { value: 'Cedro', name: 'Cedro', label: 'Cedro' },
    { value: 'Chaval', name: 'Chaval', label: 'Chaval' },
    { value: 'Choró', name: 'Choró', label: 'Choró' },
    { value: 'Chorozinho', name: 'Chorozinho', label: 'Chorozinho' },
    { value: 'Coreaú', name: 'Coreaú', label: 'Coreaú' },
    { value: 'Crateús', name: 'Crateús', label: 'Crateús' },
    { value: 'Crato', name: 'Crato', label: 'Crato' },
    { value: 'Croatá', name: 'Croatá', label: 'Croatá' },
    { value: 'Cruz', name: 'Cruz', label: 'Cruz' },
    {
      value: 'Deputado Irapuan Pinheiro',
      name: 'Deputado Irapuan Pinheiro',
      label: 'Deputado Irapuan Pinheiro',
    },
    { value: 'Ererê', name: 'Ererê', label: 'Ererê' },
    { value: 'Eusébio', name: 'Eusébio', label: 'Eusébio' },
    { value: 'Farias Brito', name: 'Farias Brito', label: 'Farias Brito' },
    { value: 'Forquilha', name: 'Forquilha', label: 'Forquilha' },
    { value: 'Fortaleza', name: 'Fortaleza', label: 'Fortaleza' },
    { value: 'Fortim', name: 'Fortim', label: 'Fortim' },
    { value: 'Frecheirinha', name: 'Frecheirinha', label: 'Frecheirinha' },
    {
      value: 'General Sampaio',
      name: 'General Sampaio',
      label: 'General Sampaio',
    },
    { value: 'Graça', name: 'Graça', label: 'Graça' },
    { value: 'Granja', name: 'Granja', label: 'Granja' },
    { value: 'Granjeiro', name: 'Granjeiro', label: 'Granjeiro' },
    { value: 'Groaíras', name: 'Groaíras', label: 'Groaíras' },
    { value: 'Guaiúba', name: 'Guaiúba', label: 'Guaiúba' },
    {
      value: 'Guaraciaba do Norte',
      name: 'Guaraciaba do Norte',
      label: 'Guaraciaba do Norte',
    },
    { value: 'Guaramiranga', name: 'Guaramiranga', label: 'Guaramiranga' },
    { value: 'Hidrolândia', name: 'Hidrolândia', label: 'Hidrolândia' },
    { value: 'Horizonte', name: 'Horizonte', label: 'Horizonte' },
    { value: 'Ibaretama', name: 'Ibaretama', label: 'Ibaretama' },
    { value: 'Ibiapina', name: 'Ibiapina', label: 'Ibiapina' },
    { value: 'Ibicuitinga', name: 'Ibicuitinga', label: 'Ibicuitinga' },
    { value: 'Icapuí', name: 'Icapuí', label: 'Icapuí' },
    { value: 'Icó', name: 'Icó', label: 'Icó' },
    { value: 'Iguatu', name: 'Iguatu', label: 'Iguatu' },
    { value: 'Independência', name: 'Independência', label: 'Independência' },
    { value: 'Ipaporanga', name: 'Ipaporanga', label: 'Ipaporanga' },
    { value: 'Ipaumirim', name: 'Ipaumirim', label: 'Ipaumirim' },
    { value: 'Ipu', name: 'Ipu', label: 'Ipu' },
    { value: 'Ipueiras', name: 'Ipueiras', label: 'Ipueiras' },
    { value: 'Iracema', name: 'Iracema', label: 'Iracema' },
    { value: 'Irauçuba', name: 'Irauçuba', label: 'Irauçuba' },
    { value: 'Itaiçaba', name: 'Itaiçaba', label: 'Itaiçaba' },
    { value: 'Itaitinga', name: 'Itaitinga', label: 'Itaitinga' },
    { value: 'Itapajé', name: 'Itapajé', label: 'Itapajé' },
    { value: 'Itapipoca', name: 'Itapipoca', label: 'Itapipoca' },
    { value: 'Itapiúna', name: 'Itapiúna', label: 'Itapiúna' },
    { value: 'Itarema', name: 'Itarema', label: 'Itarema' },
    { value: 'Itatira', name: 'Itatira', label: 'Itatira' },
    { value: 'Jaguaretama', name: 'Jaguaretama', label: 'Jaguaretama' },
    { value: 'Jaguaribara', name: 'Jaguaribara', label: 'Jaguaribara' },
    { value: 'Jaguaribe', name: 'Jaguaribe', label: 'Jaguaribe' },
    { value: 'Jaguaruana', name: 'Jaguaruana', label: 'Jaguaruana' },
    { value: 'Jaibaras', name: 'Jaibaras', label: 'Jaibaras' },
    { value: 'Jardim', name: 'Jardim', label: 'Jardim' },
    { value: 'Jati', name: 'Jati', label: 'Jati' },
    {
      value: 'Jijoca de Jericoacoara',
      name: 'Jijoca de Jericoacoara',
      label: 'Jijoca de Jericoacoara',
    },
    {
      value: 'Juazeiro do Norte',
      name: 'Juazeiro do Norte',
      label: 'Juazeiro do Norte',
    },
    { value: 'Jucás', name: 'Jucás', label: 'Jucás' },
    {
      value: 'Lavras da Mangabeira',
      name: 'Lavras da Mangabeira',
      label: 'Lavras da Mangabeira',
    },
    {
      value: 'Limoeiro do Norte',
      name: 'Limoeiro do Norte',
      label: 'Limoeiro do Norte',
    },
    { value: 'Madalena', name: 'Madalena', label: 'Madalena' },
    { value: 'Maracanaú', name: 'Maracanaú', label: 'Maracanaú' },
    { value: 'Maranguape', name: 'Maranguape', label: 'Maranguape' },
    { value: 'Marco', name: 'Marco', label: 'Marco' },
    { value: 'Martinópole', name: 'Martinópole', label: 'Martinópole' },
    { value: 'Massapê', name: 'Massapê', label: 'Massapê' },
    { value: 'Mauriti', name: 'Mauriti', label: 'Mauriti' },
    { value: 'Meruoca', name: 'Meruoca', label: 'Meruoca' },
    { value: 'Milagres', name: 'Milagres', label: 'Milagres' },
    { value: 'Milhã', name: 'Milhã', label: 'Milhã' },
    { value: 'Miraíma', name: 'Miraíma', label: 'Miraíma' },
    { value: 'Missão Velha', name: 'Missão Velha', label: 'Missão Velha' },
    { value: 'Mombaça', name: 'Mombaça', label: 'Mombaça' },
    {
      value: 'Monsenhor Tabosa',
      name: 'Monsenhor Tabosa',
      label: 'Monsenhor Tabosa',
    },
    { value: 'Morada Nova', name: 'Morada Nova', label: 'Morada Nova' },
    { value: 'Moraújo', name: 'Moraújo', label: 'Moraújo' },
    { value: 'Morrinhos', name: 'Morrinhos', label: 'Morrinhos' },
    { value: 'Mucambo', name: 'Mucambo', label: 'Mucambo' },
    { value: 'Mulungu', name: 'Mulungu', label: 'Mulungu' },
    { value: 'Nova Olinda', name: 'Nova Olinda', label: 'Nova Olinda' },
    { value: 'Nova Russas', name: 'Nova Russas', label: 'Nova Russas' },
    { value: 'Novo Oriente', name: 'Novo Oriente', label: 'Novo Oriente' },
    { value: 'Ocara', name: 'Ocara', label: 'Ocara' },
    { value: 'Orós', name: 'Orós', label: 'Orós' },
    { value: 'Pacajus', name: 'Pacajus', label: 'Pacajus' },
    { value: 'Pacatuba', name: 'Pacatuba', label: 'Pacatuba' },
    { value: 'Pacoti', name: 'Pacoti', label: 'Pacoti' },
    { value: 'Pacujá', name: 'Pacujá', label: 'Pacujá' },
    { value: 'Palhano', name: 'Palhano', label: 'Palhano' },
    { value: 'Palmácia', name: 'Palmácia', label: 'Palmácia' },
    { value: 'Paracuru', name: 'Paracuru', label: 'Paracuru' },
    { value: 'Paraipaba', name: 'Paraipaba', label: 'Paraipaba' },
    { value: 'Parambu', name: 'Parambu', label: 'Parambu' },
    { value: 'Paramoti', name: 'Paramoti', label: 'Paramoti' },
    { value: 'Pedra Branca', name: 'Pedra Branca', label: 'Pedra Branca' },
    { value: 'Penaforte', name: 'Penaforte', label: 'Penaforte' },
    { value: 'Pentecoste', name: 'Pentecoste', label: 'Pentecoste' },
    { value: 'Pereiro', name: 'Pereiro', label: 'Pereiro' },
    { value: 'Pindoretama', name: 'Pindoretama', label: 'Pindoretama' },
    {
      value: 'Piquet Carneiro',
      name: 'Piquet Carneiro',
      label: 'Piquet Carneiro',
    },
    {
      value: 'Pires Ferreira',
      name: 'Pires Ferreira',
      label: 'Pires Ferreira',
    },
    { value: 'Poranga', name: 'Poranga', label: 'Poranga' },
    { value: 'Porteiras', name: 'Porteiras', label: 'Porteiras' },
    { value: 'Potengi', name: 'Potengi', label: 'Potengi' },
    { value: 'Potiretama', name: 'Potiretama', label: 'Potiretama' },
    {
      value: 'Quiterianópolis',
      name: 'Quiterianópolis',
      label: 'Quiterianópolis',
    },
    { value: 'Quixadá', name: 'Quixadá', label: 'Quixadá' },
    { value: 'Quixelô', name: 'Quixelô', label: 'Quixelô' },
    { value: 'Quixeramobim', name: 'Quixeramobim', label: 'Quixeramobim' },
    { value: 'Quixeré', name: 'Quixeré', label: 'Quixeré' },
    { value: 'Redenção', name: 'Redenção', label: 'Redenção' },
    { value: 'Reriutaba', name: 'Reriutaba', label: 'Reriutaba' },
    { value: 'Russas', name: 'Russas', label: 'Russas' },
    { value: 'Saboeiro', name: 'Saboeiro', label: 'Saboeiro' },
    { value: 'Salitre', name: 'Salitre', label: 'Salitre' },
    {
      value: 'Santa Quitéria',
      name: 'Santa Quitéria',
      label: 'Santa Quitéria',
    },
    {
      value: 'Santana do Acaraú',
      name: 'Santana do Acaraú',
      label: 'Santana do Acaraú',
    },
    {
      value: 'Santana do Cariri',
      name: 'Santana do Cariri',
      label: 'Santana do Cariri',
    },
    { value: 'São Benedito', name: 'São Benedito', label: 'São Benedito' },
    {
      value: 'São Gonçalo do Amarante',
      name: 'São Gonçalo do Amarante',
      label: 'São Gonçalo do Amarante',
    },
    {
      value: 'São João do Jaguaribe',
      name: 'São João do Jaguaribe',
      label: 'São João do Jaguaribe',
    },
    {
      value: 'São Luís do Curu',
      name: 'São Luís do Curu',
      label: 'São Luís do Curu',
    },
    {
      value: 'Sapupara',
      name: 'Sapupara',
      label: 'Sapupara',
    },
    {
      value: 'Senador Pompeu',
      name: 'Senador Pompeu',
      label: 'Senador Pompeu',
    },
    { value: 'Senador Sá', name: 'Senador Sá', label: 'Senador Sá' },
    { value: 'Sobral', name: 'Sobral', label: 'Sobral' },
    { value: 'Solonópole', name: 'Solonópole', label: 'Solonópole' },
    {
      value: 'Tabuleiro do Norte',
      name: 'Tabuleiro do Norte',
      label: 'Tabuleiro do Norte',
    },
    { value: 'Tamboril', name: 'Tamboril', label: 'Tamboril' },
    { value: 'Tarrafas', name: 'Tarrafas', label: 'Tarrafas' },
    { value: 'Tauá', name: 'Tauá', label: 'Tauá' },
    { value: 'Tejuçuoca', name: 'Tejuçuoca', label: 'Tejuçuoca' },
    { value: 'Tianguá', name: 'Tianguá', label: 'Tianguá' },
    { value: 'Trairi', name: 'Trairi', label: 'Trairi' },
    { value: 'Tururu', name: 'Tururu', label: 'Tururu' },
    { value: 'Ubajara', name: 'Ubajara', label: 'Ubajara' },
    { value: 'Umari', name: 'Umari', label: 'Umari' },
    { value: 'Umirim', name: 'Umirim', label: 'Umirim' },
    { value: 'Uruburetama', name: 'Uruburetama', label: 'Uruburetama' },
    { value: 'Uruoca', name: 'Uruoca', label: 'Uruoca' },
    { value: 'Varjota', name: 'Varjota', label: 'Varjota' },
    { value: 'Várzea Alegre', name: 'Várzea Alegre', label: 'Várzea Alegre' },
    {
      value: 'Viçosa do Ceará',
      name: 'Viçosa do Ceará',
      label: 'Viçosa do Ceará',
    },
  ],
  DF: [{ value: 'Brasília', name: 'Brasília', label: 'Brasília' }],
  ES: [
    {
      value: 'Afonso Cláudio',
      name: 'Afonso Cláudio',
      label: 'Afonso Cláudio',
    },
    {
      value: 'Água Doce do Norte',
      name: 'Água Doce do Norte',
      label: 'Água Doce do Norte',
    },
    { value: 'Águia Branca', name: 'Águia Branca', label: 'Águia Branca' },
    { value: 'Alegre', name: 'Alegre', label: 'Alegre' },
    {
      value: 'Alfredo Chaves',
      name: 'Alfredo Chaves',
      label: 'Alfredo Chaves',
    },
    { value: 'Alto Rio Novo', name: 'Alto Rio Novo', label: 'Alto Rio Novo' },
    { value: 'Anchieta', name: 'Anchieta', label: 'Anchieta' },
    { value: 'Apiacá', name: 'Apiacá', label: 'Apiacá' },
    { value: 'Aracruz', name: 'Aracruz', label: 'Aracruz' },
    {
      value: 'Atilio Vivacqua',
      name: 'Atilio Vivacqua',
      label: 'Atilio Vivacqua',
    },
    { value: 'Baixo Guandu', name: 'Baixo Guandu', label: 'Baixo Guandu' },
    {
      value: 'Barra de São Francisco',
      name: 'Barra de São Francisco',
      label: 'Barra de São Francisco',
    },
    { value: 'Boa Esperança', name: 'Boa Esperança', label: 'Boa Esperança' },
    {
      value: 'Bom Jesus do Norte',
      name: 'Bom Jesus do Norte',
      label: 'Bom Jesus do Norte',
    },
    { value: 'Brejetuba', name: 'Brejetuba', label: 'Brejetuba' },
    {
      value: 'Cachoeiro de Itapemirim',
      name: 'Cachoeiro de Itapemirim',
      label: 'Cachoeiro de Itapemirim',
    },
    { value: 'Cariacica', name: 'Cariacica', label: 'Cariacica' },
    { value: 'Castelo', name: 'Castelo', label: 'Castelo' },
    { value: 'Colatina', name: 'Colatina', label: 'Colatina' },
    {
      value: 'Conceição da Barra',
      name: 'Conceição da Barra',
      label: 'Conceição da Barra',
    },
    {
      value: 'Conceição do Castelo',
      name: 'Conceição do Castelo',
      label: 'Conceição do Castelo',
    },
    {
      value: 'Divino de São Lourenço',
      name: 'Divino de São Lourenço',
      label: 'Divino de São Lourenço',
    },
    {
      value: 'Domingos Martins',
      name: 'Domingos Martins',
      label: 'Domingos Martins',
    },
    {
      value: 'Dores do Rio Preto',
      name: 'Dores do Rio Preto',
      label: 'Dores do Rio Preto',
    },
    { value: 'Ecoporanga', name: 'Ecoporanga', label: 'Ecoporanga' },
    { value: 'Fundão', name: 'Fundão', label: 'Fundão' },
    {
      value: 'Governador Lindenberg',
      name: 'Governador Lindenberg',
      label: 'Governador Lindenberg',
    },
    { value: 'Guaçuí', name: 'Guaçuí', label: 'Guaçuí' },
    { value: 'Guarapari', name: 'Guarapari', label: 'Guarapari' },
    { value: 'Ibatiba', name: 'Ibatiba', label: 'Ibatiba' },
    { value: 'Ibiraçu', name: 'Ibiraçu', label: 'Ibiraçu' },
    { value: 'Ibitirama', name: 'Ibitirama', label: 'Ibitirama' },
    { value: 'Iconha', name: 'Iconha', label: 'Iconha' },
    { value: 'Irupi', name: 'Irupi', label: 'Irupi' },
    { value: 'Itaguaçu', name: 'Itaguaçu', label: 'Itaguaçu' },
    { value: 'Itapemirim', name: 'Itapemirim', label: 'Itapemirim' },
    { value: 'Itarana', name: 'Itarana', label: 'Itarana' },
    { value: 'Iúna', name: 'Iúna', label: 'Iúna' },
    { value: 'Jaguaré', name: 'Jaguaré', label: 'Jaguaré' },
    {
      value: 'Jerônimo Monteiro',
      name: 'Jerônimo Monteiro',
      label: 'Jerônimo Monteiro',
    },
    { value: 'João Neiva', name: 'João Neiva', label: 'João Neiva' },
    {
      value: 'Laranja da Terra',
      name: 'Laranja da Terra',
      label: 'Laranja da Terra',
    },
    { value: 'Linhares', name: 'Linhares', label: 'Linhares' },
    { value: 'Mantenópolis', name: 'Mantenópolis', label: 'Mantenópolis' },
    { value: 'Marataizes', name: 'Marataizes', label: 'Marataizes' },
    {
      value: 'Marechal Floriano',
      name: 'Marechal Floriano',
      label: 'Marechal Floriano',
    },
    { value: 'Marilândia', name: 'Marilândia', label: 'Marilândia' },
    { value: 'Mimoso do Sul', name: 'Mimoso do Sul', label: 'Mimoso do Sul' },
    { value: 'Montanha', name: 'Montanha', label: 'Montanha' },
    { value: 'Mucurici', name: 'Mucurici', label: 'Mucurici' },
    { value: 'Muniz Freire', name: 'Muniz Freire', label: 'Muniz Freire' },
    { value: 'Muqui', name: 'Muqui', label: 'Muqui' },
    { value: 'Nova Venécia', name: 'Nova Venécia', label: 'Nova Venécia' },
    { value: 'Pancas', name: 'Pancas', label: 'Pancas' },
    { value: 'Pedro Canário', name: 'Pedro Canário', label: 'Pedro Canário' },
    { value: 'Pinheiros', name: 'Pinheiros', label: 'Pinheiros' },
    { value: 'Piúma', name: 'Piúma', label: 'Piúma' },
    { value: 'Ponto Belo', name: 'Ponto Belo', label: 'Ponto Belo' },
    {
      value: 'Presidente Kennedy',
      name: 'Presidente Kennedy',
      label: 'Presidente Kennedy',
    },
    { value: 'Rio Bananal', name: 'Rio Bananal', label: 'Rio Bananal' },
    {
      value: 'Rio Novo do Sul',
      name: 'Rio Novo do Sul',
      label: 'Rio Novo do Sul',
    },
    {
      value: 'Santa Leopoldina',
      name: 'Santa Leopoldina',
      label: 'Santa Leopoldina',
    },
    {
      value: 'Santa Maria de Jetibá',
      name: 'Santa Maria de Jetibá',
      label: 'Santa Maria de Jetibá',
    },
    { value: 'Santa Teresa', name: 'Santa Teresa', label: 'Santa Teresa' },
    {
      value: 'São Domingos do Norte',
      name: 'São Domingos do Norte',
      label: 'São Domingos do Norte',
    },
    {
      value: 'São Gabriel da Palha',
      name: 'São Gabriel da Palha',
      label: 'São Gabriel da Palha',
    },
    {
      value: 'São José do Calçado',
      name: 'São José do Calçado',
      label: 'São José do Calçado',
    },
    { value: 'São Mateus', name: 'São Mateus', label: 'São Mateus' },
    {
      value: 'São Roque do Canaã',
      name: 'São Roque do Canaã',
      label: 'São Roque do Canaã',
    },
    { value: 'Serra', name: 'Serra', label: 'Serra' },
    { value: 'Sooretama', name: 'Sooretama', label: 'Sooretama' },
    { value: 'Vargem Alta', name: 'Vargem Alta', label: 'Vargem Alta' },
    {
      value: 'Venda Nova do Imigrante',
      name: 'Venda Nova do Imigrante',
      label: 'Venda Nova do Imigrante',
    },
    { value: 'Viana', name: 'Viana', label: 'Viana' },
    { value: 'Vila Pavão', name: 'Vila Pavão', label: 'Vila Pavão' },
    { value: 'Vila Valério', name: 'Vila Valério', label: 'Vila Valério' },
    { value: 'Vila Velha', name: 'Vila Velha', label: 'Vila Velha' },
    { value: 'Vitória', name: 'Vitória', label: 'Vitória' },
  ],
  GO: [
    {
      value: 'Abadia de Goiás',
      name: 'Abadia de Goiás',
      label: 'Abadia de Goiás',
    },
    { value: 'Abadiânia', name: 'Abadiânia', label: 'Abadiânia' },
    { value: 'Acreúna', name: 'Acreúna', label: 'Acreúna' },
    { value: 'Adelândia', name: 'Adelândia', label: 'Adelândia' },
    {
      value: 'Água Fria de Goiás',
      name: 'Água Fria de Goiás',
      label: 'Água Fria de Goiás',
    },
    { value: 'Água Limpa', name: 'Água Limpa', label: 'Água Limpa' },
    {
      value: 'Águas Lindas de Goiás',
      name: 'Águas Lindas de Goiás',
      label: 'Águas Lindas de Goiás',
    },
    { value: 'Alexânia', name: 'Alexânia', label: 'Alexânia' },
    { value: 'Aloândia', name: 'Aloândia', label: 'Aloândia' },
    {
      value: 'Alto Horizonte',
      name: 'Alto Horizonte',
      label: 'Alto Horizonte',
    },
    {
      value: 'Alto Paraíso de Goiás',
      name: 'Alto Paraíso de Goiás',
      label: 'Alto Paraíso de Goiás',
    },
    {
      value: 'Alvorada do Norte',
      name: 'Alvorada do Norte',
      label: 'Alvorada do Norte',
    },
    { value: 'Amaralina', name: 'Amaralina', label: 'Amaralina' },
    {
      value: 'Americano do Brasil',
      name: 'Americano do Brasil',
      label: 'Americano do Brasil',
    },
    { value: 'Amorinópolis', name: 'Amorinópolis', label: 'Amorinópolis' },
    { value: 'Anápolis', name: 'Anápolis', label: 'Anápolis' },
    { value: 'Anhanguera', name: 'Anhanguera', label: 'Anhanguera' },
    { value: 'Anicuns', name: 'Anicuns', label: 'Anicuns' },
    {
      value: 'Aparecida de Goiânia',
      name: 'Aparecida de Goiânia',
      label: 'Aparecida de Goiânia',
    },
    {
      value: 'Aparecida do Rio Doce',
      name: 'Aparecida do Rio Doce',
      label: 'Aparecida do Rio Doce',
    },
    { value: 'Aporé', name: 'Aporé', label: 'Aporé' },
    { value: 'Araçu', name: 'Araçu', label: 'Araçu' },
    { value: 'Aragarças', name: 'Aragarças', label: 'Aragarças' },
    { value: 'Aragoiânia', name: 'Aragoiânia', label: 'Aragoiânia' },
    { value: 'Araguapaz', name: 'Araguapaz', label: 'Araguapaz' },
    { value: 'Arenópolis', name: 'Arenópolis', label: 'Arenópolis' },
    { value: 'Aruanã', name: 'Aruanã', label: 'Aruanã' },
    { value: 'Aurilândia', name: 'Aurilândia', label: 'Aurilândia' },
    { value: 'Avelinópolis', name: 'Avelinópolis', label: 'Avelinópolis' },
    { value: 'Baliza', name: 'Baliza', label: 'Baliza' },
    { value: 'Barro Alto', name: 'Barro Alto', label: 'Barro Alto' },
    {
      value: 'Bela Vista de Goiás',
      name: 'Bela Vista de Goiás',
      label: 'Bela Vista de Goiás',
    },
    {
      value: 'Bom Jardim de Goiás',
      name: 'Bom Jardim de Goiás',
      label: 'Bom Jardim de Goiás',
    },
    {
      value: 'Bom Jesus de Goiás',
      name: 'Bom Jesus de Goiás',
      label: 'Bom Jesus de Goiás',
    },
    { value: 'Bonfinópolis', name: 'Bonfinópolis', label: 'Bonfinópolis' },
    { value: 'Bonópolis', name: 'Bonópolis', label: 'Bonópolis' },
    { value: 'Brazabrantes', name: 'Brazabrantes', label: 'Brazabrantes' },
    { value: 'Britânia', name: 'Britânia', label: 'Britânia' },
    { value: 'Buriti Alegre', name: 'Buriti Alegre', label: 'Buriti Alegre' },
    {
      value: 'Buriti de Goiás',
      name: 'Buriti de Goiás',
      label: 'Buriti de Goiás',
    },
    { value: 'Buritinópolis', name: 'Buritinópolis', label: 'Buritinópolis' },
    { value: 'Cabeceiras', name: 'Cabeceiras', label: 'Cabeceiras' },
    {
      value: 'Cachoeira Alta',
      name: 'Cachoeira Alta',
      label: 'Cachoeira Alta',
    },
    {
      value: 'Cachoeira de Goiás',
      name: 'Cachoeira de Goiás',
      label: 'Cachoeira de Goiás',
    },
    {
      value: 'Cachoeira Dourada',
      name: 'Cachoeira Dourada',
      label: 'Cachoeira Dourada',
    },
    { value: 'Caçu', name: 'Caçu', label: 'Caçu' },
    { value: 'Caiapônia', name: 'Caiapônia', label: 'Caiapônia' },
    { value: 'Caldas Novas', name: 'Caldas Novas', label: 'Caldas Novas' },
    { value: 'Caldazinha', name: 'Caldazinha', label: 'Caldazinha' },
    {
      value: 'Campestre de Goiás',
      name: 'Campestre de Goiás',
      label: 'Campestre de Goiás',
    },
    { value: 'Campinaçu', name: 'Campinaçu', label: 'Campinaçu' },
    { value: 'Campinorte', name: 'Campinorte', label: 'Campinorte' },
    {
      value: 'Campo Alegre de Goiás',
      name: 'Campo Alegre de Goiás',
      label: 'Campo Alegre de Goiás',
    },
    {
      value: 'Campos Limpo de Goiás',
      name: 'Campos Limpo de Goiás',
      label: 'Campos Limpo de Goiás',
    },
    { value: 'Campos Belos', name: 'Campos Belos', label: 'Campos Belos' },
    { value: 'Campos Verdes', name: 'Campos Verdes', label: 'Campos Verdes' },
    {
      value: 'Carmo do Rio Verde',
      name: 'Carmo do Rio Verde',
      label: 'Carmo do Rio Verde',
    },
    { value: 'Castelândia', name: 'Castelândia', label: 'Castelândia' },
    { value: 'Catalão', name: 'Catalão', label: 'Catalão' },
    { value: 'Caturaí', name: 'Caturaí', label: 'Caturaí' },
    { value: 'Cavalcante', name: 'Cavalcante', label: 'Cavalcante' },
    { value: 'Ceres', name: 'Ceres', label: 'Ceres' },
    { value: 'Cezarina', name: 'Cezarina', label: 'Cezarina' },
    {
      value: 'Chapadão do Céu',
      name: 'Chapadão do Céu',
      label: 'Chapadão do Céu',
    },
    {
      value: 'Cidade Ocidental',
      name: 'Cidade Ocidental',
      label: 'Cidade Ocidental',
    },
    {
      value: 'Cocalzinho de Goiás',
      name: 'Cocalzinho de Goiás',
      label: 'Cocalzinho de Goiás',
    },
    {
      value: 'Colinas do Sul',
      name: 'Colinas do Sul',
      label: 'Colinas do Sul',
    },
    {
      value: 'Córrego do Ouro',
      name: 'Córrego do Ouro',
      label: 'Córrego do Ouro',
    },
    {
      value: 'Corumbá de Goiás',
      name: 'Corumbá de Goiás',
      label: 'Corumbá de Goiás',
    },
    { value: 'Corumbaíba', name: 'Corumbaíba', label: 'Corumbaíba' },
    { value: 'Cristalina', name: 'Cristalina', label: 'Cristalina' },
    {
      value: 'Cristianópolis',
      name: 'Cristianópolis',
      label: 'Cristianópolis',
    },
    { value: 'Crixás', name: 'Crixás', label: 'Crixás' },
    { value: 'Cromínia', name: 'Cromínia', label: 'Cromínia' },
    { value: 'Cumari', name: 'Cumari', label: 'Cumari' },
    { value: 'Damianópolis', name: 'Damianópolis', label: 'Damianópolis' },
    { value: 'Damolândia', name: 'Damolândia', label: 'Damolândia' },
    { value: 'Davinópolis', name: 'Davinópolis', label: 'Davinópolis' },
    { value: 'Diorama', name: 'Diorama', label: 'Diorama' },
    {
      value: 'Divinópolis de Goiás',
      name: 'Divinópolis de Goiás',
      label: 'Divinópolis de Goiás',
    },
    { value: 'Doverlândia', name: 'Doverlândia', label: 'Doverlândia' },
    { value: 'Edealina', name: 'Edealina', label: 'Edealina' },
    { value: 'Edéia', name: 'Edéia', label: 'Edéia' },
    {
      value: 'Estrela do Norte',
      name: 'Estrela do Norte',
      label: 'Estrela do Norte',
    },
    { value: 'Faina', name: 'Faina', label: 'Faina' },
    { value: 'Fazenda Nova', name: 'Fazenda Nova', label: 'Fazenda Nova' },
    { value: 'Firminópolis', name: 'Firminópolis', label: 'Firminópolis' },
    {
      value: 'Flores de Goiás',
      name: 'Flores de Goiás',
      label: 'Flores de Goiás',
    },
    { value: 'Formosa', name: 'Formosa', label: 'Formosa' },
    { value: 'Formoso', name: 'Formoso', label: 'Formoso' },
    {
      value: 'Gameleira de Goiás',
      name: 'Gameleira de Goiás',
      label: 'Gameleira de Goiás',
    },
    { value: 'Goianápolis', name: 'Goianápolis', label: 'Goianápolis' },
    { value: 'Goiandira', name: 'Goiandira', label: 'Goiandira' },
    { value: 'Goianésia', name: 'Goianésia', label: 'Goianésia' },
    { value: 'Goiânia', name: 'Goiânia', label: 'Goiânia' },
    { value: 'Goianira', name: 'Goianira', label: 'Goianira' },
    { value: 'Goiás', name: 'Goiás', label: 'Goiás' },
    { value: 'Goiatuba', name: 'Goiatuba', label: 'Goiatuba' },
    { value: 'Gouvelândia', name: 'Gouvelândia', label: 'Gouvelândia' },
    { value: 'Guapó', name: 'Guapó', label: 'Guapó' },
    { value: 'Guaraíta', name: 'Guaraíta', label: 'Guaraíta' },
    {
      value: 'Guarani de Goiás',
      name: 'Guarani de Goiás',
      label: 'Guarani de Goiás',
    },
    { value: 'Guarinos', name: 'Guarinos', label: 'Guarinos' },
    { value: 'Heitoraí', name: 'Heitoraí', label: 'Heitoraí' },
    { value: 'Hidrolândia', name: 'Hidrolândia', label: 'Hidrolândia' },
    { value: 'Hidrolina', name: 'Hidrolina', label: 'Hidrolina' },
    { value: 'Iaciara', name: 'Iaciara', label: 'Iaciara' },
    { value: 'Inaciolândia', name: 'Inaciolândia', label: 'Inaciolândia' },
    { value: 'Indiara', name: 'Indiara', label: 'Indiara' },
    { value: 'Inhumas', name: 'Inhumas', label: 'Inhumas' },
    { value: 'Ipameri', name: 'Ipameri', label: 'Ipameri' },
    {
      value: 'Ipiranga de Goiás',
      name: 'Ipiranga de Goiás',
      label: 'Ipiranga de Goiás',
    },
    { value: 'Iporá', name: 'Iporá', label: 'Iporá' },
    { value: 'Israelândia', name: 'Israelândia', label: 'Israelândia' },
    { value: 'Itaberaí', name: 'Itaberaí', label: 'Itaberaí' },
    { value: 'Itaguari', name: 'Itaguari', label: 'Itaguari' },
    { value: 'Itaguaru', name: 'Itaguaru', label: 'Itaguaru' },
    { value: 'Itajá', name: 'Itajá', label: 'Itajá' },
    { value: 'Itapaci', name: 'Itapaci', label: 'Itapaci' },
    { value: 'Itapirapuã', name: 'Itapirapuã', label: 'Itapirapuã' },
    { value: 'Itapuranga', name: 'Itapuranga', label: 'Itapuranga' },
    { value: 'Itarumã', name: 'Itarumã', label: 'Itarumã' },
    { value: 'Itauçu', name: 'Itauçu', label: 'Itauçu' },
    { value: 'Itumbiara', name: 'Itumbiara', label: 'Itumbiara' },
    { value: 'Ivolândia', name: 'Ivolândia', label: 'Ivolândia' },
    { value: 'Jandaia', name: 'Jandaia', label: 'Jandaia' },
    { value: 'Jaraguá', name: 'Jaraguá', label: 'Jaraguá' },
    { value: 'Jataí', name: 'Jataí', label: 'Jataí' },
    { value: 'Jaupaci', name: 'Jaupaci', label: 'Jaupaci' },
    { value: 'Jesúpolis', name: 'Jesúpolis', label: 'Jesúpolis' },
    { value: 'Joviânia', name: 'Joviânia', label: 'Joviânia' },
    { value: 'Jussara', name: 'Jussara', label: 'Jussara' },
    { value: 'Lagoa Santa', name: 'Lagoa Santa', label: 'Lagoa Santa' },
    {
      value: 'Leopoldo de Bulhões',
      name: 'Leopoldo de Bulhões',
      label: 'Leopoldo de Bulhões',
    },
    { value: 'Luziânia', name: 'Luziânia', label: 'Luziânia' },
    { value: 'Mairipotaba', name: 'Mairipotaba', label: 'Mairipotaba' },
    { value: 'Mambaí', name: 'Mambaí', label: 'Mambaí' },
    { value: 'Mara Rosa', name: 'Mara Rosa', label: 'Mara Rosa' },
    { value: 'Marzagão', name: 'Marzagão', label: 'Marzagão' },
    { value: 'Matrinchã', name: 'Matrinchã', label: 'Matrinchã' },
    { value: 'Maurilândia', name: 'Maurilândia', label: 'Maurilândia' },
    {
      value: 'Mimoso de Goiás',
      name: 'Mimoso de Goiás',
      label: 'Mimoso de Goiás',
    },
    { value: 'Minaçu', name: 'Minaçu', label: 'Minaçu' },
    { value: 'Mineiros', name: 'Mineiros', label: 'Mineiros' },
    { value: 'Moiporá', name: 'Moiporá', label: 'Moiporá' },
    {
      value: 'Monte Alegre de Goiás',
      name: 'Monte Alegre de Goiás',
      label: 'Monte Alegre de Goiás',
    },
    {
      value: 'Montes Claros de Goiás',
      name: 'Montes Claros de Goiás',
      label: 'Montes Claros de Goiás',
    },
    { value: 'Montividiu', name: 'Montividiu', label: 'Montividiu' },
    {
      value: 'Montividiu do Norte',
      name: 'Montividiu do Norte',
      label: 'Montividiu do Norte',
    },
    { value: 'Morrinhos', name: 'Morrinhos', label: 'Morrinhos' },
    {
      value: 'Morro Agudo de Goiás',
      name: 'Morro Agudo de Goiás',
      label: 'Morro Agudo de Goiás',
    },
    { value: 'Mossâmedes', name: 'Mossâmedes', label: 'Mossâmedes' },
    { value: 'Mozarlândia', name: 'Mozarlândia', label: 'Mozarlândia' },
    { value: 'Mundo Novo', name: 'Mundo Novo', label: 'Mundo Novo' },
    { value: 'Mutunópolis', name: 'Mutunópolis', label: 'Mutunópolis' },
    { value: 'Nazário', name: 'Nazário', label: 'Nazário' },
    { value: 'Nerópolis', name: 'Nerópolis', label: 'Nerópolis' },
    { value: 'Niquelândia', name: 'Niquelândia', label: 'Niquelândia' },
    { value: 'Nova América', name: 'Nova América', label: 'Nova América' },
    { value: 'Nova Aurora', name: 'Nova Aurora', label: 'Nova Aurora' },
    { value: 'Nova Crixás', name: 'Nova Crixás', label: 'Nova Crixás' },
    { value: 'Nova Glória', name: 'Nova Glória', label: 'Nova Glória' },
    {
      value: 'Nova Iguaçu de Goiás',
      name: 'Nova Iguaçu de Goiás',
      label: 'Nova Iguaçu de Goiás',
    },
    { value: 'Nova Roma', name: 'Nova Roma', label: 'Nova Roma' },
    { value: 'Nova Veneza', name: 'Nova Veneza', label: 'Nova Veneza' },
    { value: 'Novo Brasil', name: 'Novo Brasil', label: 'Novo Brasil' },
    { value: 'Novo Gama', name: 'Novo Gama', label: 'Novo Gama' },
    { value: 'Novo Planalto', name: 'Novo Planalto', label: 'Novo Planalto' },
    { value: 'Orizona', name: 'Orizona', label: 'Orizona' },
    {
      value: 'Ouro Verde de Goiás',
      name: 'Ouro Verde de Goiás',
      label: 'Ouro Verde de Goiás',
    },
    { value: 'Ouvidor', name: 'Ouvidor', label: 'Ouvidor' },
    {
      value: 'Padre Bernardo',
      name: 'Padre Bernardo',
      label: 'Padre Bernardo',
    },
    {
      value: 'Palestina de Goiás',
      name: 'Palestina de Goiás',
      label: 'Palestina de Goiás',
    },
    {
      value: 'Palmeiras de Goiás',
      name: 'Palmeiras de Goiás',
      label: 'Palmeiras de Goiás',
    },
    { value: 'Palmelo', name: 'Palmelo', label: 'Palmelo' },
    { value: 'Palminópolis', name: 'Palminópolis', label: 'Palminópolis' },
    { value: 'Panamá', name: 'Panamá', label: 'Panamá' },
    { value: 'Paranaiguara', name: 'Paranaiguara', label: 'Paranaiguara' },
    { value: 'Paraúna', name: 'Paraúna', label: 'Paraúna' },
    { value: 'Perolândia', name: 'Perolândia', label: 'Perolândia' },
    {
      value: 'Petrolina de Goiás',
      name: 'Petrolina de Goiás',
      label: 'Petrolina de Goiás',
    },
    {
      value: 'Pilar de Goiás',
      name: 'Pilar de Goiás',
      label: 'Pilar de Goiás',
    },
    { value: 'Piracanjuba', name: 'Piracanjuba', label: 'Piracanjuba' },
    { value: 'Piranhas', name: 'Piranhas', label: 'Piranhas' },
    { value: 'Pirenópolis', name: 'Pirenópolis', label: 'Pirenópolis' },
    { value: 'Pires do Rio', name: 'Pires do Rio', label: 'Pires do Rio' },
    { value: 'Planaltina', name: 'Planaltina', label: 'Planaltina' },
    { value: 'Pontalina', name: 'Pontalina', label: 'Pontalina' },
    { value: 'Porangatu', name: 'Porangatu', label: 'Porangatu' },
    { value: 'Porteirão', name: 'Porteirão', label: 'Porteirão' },
    { value: 'Portelândia', name: 'Portelândia', label: 'Portelândia' },
    { value: 'Posse', name: 'Posse', label: 'Posse' },
    {
      value: 'Professor Jamil',
      name: 'Professor Jamil',
      label: 'Professor Jamil',
    },
    { value: 'Quirinópolis', name: 'Quirinópolis', label: 'Quirinópolis' },
    { value: 'Rialma', name: 'Rialma', label: 'Rialma' },
    { value: 'Rianápolis', name: 'Rianápolis', label: 'Rianápolis' },
    { value: 'Rio Quente', name: 'Rio Quente', label: 'Rio Quente' },
    { value: 'Rio Verde', name: 'Rio Verde', label: 'Rio Verde' },
    { value: 'Rubiataba', name: 'Rubiataba', label: 'Rubiataba' },
    { value: 'Sanclerlândia', name: 'Sanclerlândia', label: 'Sanclerlândia' },
    {
      value: 'Santa Bárbara de Goiás',
      name: 'Santa Bárbara de Goiás',
      label: 'Santa Bárbara de Goiás',
    },
    {
      value: 'Santa Cruz de Goiás',
      name: 'Santa Cruz de Goiás',
      label: 'Santa Cruz de Goiás',
    },
    {
      value: 'Santa Fé de Goiás',
      name: 'Santa Fé de Goiás',
      label: 'Santa Fé de Goiás',
    },
    {
      value: 'Santa Helena de Goiás',
      name: 'Santa Helena de Goiás',
      label: 'Santa Helena de Goiás',
    },
    { value: 'Santa Isabel', name: 'Santa Isabel', label: 'Santa Isabel' },
    {
      value: 'Santa Rita do Araguaia',
      name: 'Santa Rita do Araguaia',
      label: 'Santa Rita do Araguaia',
    },
    {
      value: 'Santa Rita do Novo Destino',
      name: 'Santa Rita do Novo Destino',
      label: 'Santa Rita do Novo Destino',
    },
    {
      value: 'Santa Rosa de Goiás',
      name: 'Santa Rosa de Goiás',
      label: 'Santa Rosa de Goiás',
    },
    {
      value: 'Santa Tereza de Goiás',
      name: 'Santa Tereza de Goiás',
      label: 'Santa Tereza de Goiás',
    },
    {
      value: 'Santa Terezinha de Goiás',
      name: 'Santa Terezinha de Goiás',
      label: 'Santa Terezinha de Goiás',
    },
    {
      value: 'Santo Antônio da Barra',
      name: 'Santo Antônio da Barra',
      label: 'Santo Antônio da Barra',
    },
    {
      value: 'Santo Antônio de Goiás',
      name: 'Santo Antônio de Goiás',
      label: 'Santo Antônio de Goiás',
    },
    {
      value: 'Santo Antônio do Descoberto',
      name: 'Santo Antônio do Descoberto',
      label: 'Santo Antônio do Descoberto',
    },
    { value: 'São Domingos', name: 'São Domingos', label: 'São Domingos' },
    {
      value: 'São Francisco de Goiás',
      name: 'São Francisco de Goiás',
      label: 'São Francisco de Goiás',
    },
    {
      value: "São João D'Aliança",
      name: "São João D'Aliança",
      label: "São João D'Aliança",
    },
    {
      value: 'São João da Paraúna',
      name: 'São João da Paraúna',
      label: 'São João da Paraúna',
    },
    {
      value: 'São Luís de Montes Belos',
      name: 'São Luís de Montes Belos',
      label: 'São Luís de Montes Belos',
    },
    {
      value: 'São Luíz do Norte',
      name: 'São Luíz do Norte',
      label: 'São Luíz do Norte',
    },
    {
      value: 'São Miguel do Araguaia',
      name: 'São Miguel do Araguaia',
      label: 'São Miguel do Araguaia',
    },
    {
      value: 'São Miguel do Passa Quatro',
      name: 'São Miguel do Passa Quatro',
      label: 'São Miguel do Passa Quatro',
    },
    { value: 'São Patrício', name: 'São Patrício', label: 'São Patrício' },
    { value: 'São Simão', name: 'São Simão', label: 'São Simão' },
    {
      value: 'Senador Canedo',
      name: 'Senador Canedo',
      label: 'Senador Canedo',
    },
    { value: 'Serranópolis', name: 'Serranópolis', label: 'Serranópolis' },
    { value: 'Silvânia', name: 'Silvânia', label: 'Silvânia' },
    { value: 'Simolândia', name: 'Simolândia', label: 'Simolândia' },
    {
      value: "Sítio D'Abadia",
      name: "Sítio D'Abadia",
      label: "Sítio D'Abadia",
    },
    {
      value: 'Taquaral de Goiás',
      name: 'Taquaral de Goiás',
      label: 'Taquaral de Goiás',
    },
    {
      value: 'Teresina de Goiás',
      name: 'Teresina de Goiás',
      label: 'Teresina de Goiás',
    },
    {
      value: 'Terezópolis de Goiás',
      name: 'Terezópolis de Goiás',
      label: 'Terezópolis de Goiás',
    },
    { value: 'Três Ranchos', name: 'Três Ranchos', label: 'Três Ranchos' },
    { value: 'Trindade', name: 'Trindade', label: 'Trindade' },
    { value: 'Trombas', name: 'Trombas', label: 'Trombas' },
    { value: 'Turvânia', name: 'Turvânia', label: 'Turvânia' },
    { value: 'Turvelândia', name: 'Turvelândia', label: 'Turvelândia' },
    { value: 'Uirapuru', name: 'Uirapuru', label: 'Uirapuru' },
    { value: 'Uruaçu', name: 'Uruaçu', label: 'Uruaçu' },
    { value: 'Uruana', name: 'Uruana', label: 'Uruana' },
    { value: 'Urutaí', name: 'Urutaí', label: 'Urutaí' },
    {
      value: 'Valparaíso de Goiás',
      name: 'Valparaíso de Goiás',
      label: 'Valparaíso de Goiás',
    },
    { value: 'Varjão', name: 'Varjão', label: 'Varjão' },
    { value: 'Vianópolis', name: 'Vianópolis', label: 'Vianópolis' },
    {
      value: 'Vicentinópolis',
      name: 'Vicentinópolis',
      label: 'Vicentinópolis',
    },
    { value: 'Vila Boa', name: 'Vila Boa', label: 'Vila Boa' },
    { value: 'Vila Propício', name: 'Vila Propício', label: 'Vila Propício' },
  ],
  MA: [
    { value: 'Açailândia', name: 'Açailândia', label: 'Açailândia' },
    { value: 'Afonso Cunha', name: 'Afonso Cunha', label: 'Afonso Cunha' },
    {
      value: 'Água Doce do Maranhão',
      name: 'Água Doce do Maranhão',
      label: 'Água Doce do Maranhão',
    },
    { value: 'Alcântara', name: 'Alcântara', label: 'Alcântara' },
    { value: 'Aldeias Altas', name: 'Aldeias Altas', label: 'Aldeias Altas' },
    {
      value: 'Altamira do Maranhão',
      name: 'Altamira do Maranhão',
      label: 'Altamira do Maranhão',
    },
    {
      value: 'Alto Alegre do Maranhão',
      name: 'Alto Alegre do Maranhão',
      label: 'Alto Alegre do Maranhão',
    },
    {
      value: 'Alto Alegre do Pindaré',
      name: 'Alto Alegre do Pindaré',
      label: 'Alto Alegre do Pindaré',
    },
    { value: 'Alto Parnaíba', name: 'Alto Parnaíba', label: 'Alto Parnaíba' },
    {
      value: 'Amapá do Maranhão',
      name: 'Amapá do Maranhão',
      label: 'Amapá do Maranhão',
    },
    {
      value: 'Amarante do Maranhão',
      name: 'Amarante do Maranhão',
      label: 'Amarante do Maranhão',
    },
    { value: 'Anajatuba', name: 'Anajatuba', label: 'Anajatuba' },
    { value: 'Anapurus', name: 'Anapurus', label: 'Anapurus' },
    { value: 'Apicum-Açu', name: 'Apicum-Açu', label: 'Apicum-Açu' },
    { value: 'Araguanã', name: 'Araguanã', label: 'Araguanã' },
    { value: 'Araioses', name: 'Araioses', label: 'Araioses' },
    { value: 'Arame', name: 'Arame', label: 'Arame' },
    { value: 'Arari', name: 'Arari', label: 'Arari' },
    { value: 'Axixá', name: 'Axixá', label: 'Axixá' },
    { value: 'Bacabal', name: 'Bacabal', label: 'Bacabal' },
    { value: 'Bacabeira', name: 'Bacabeira', label: 'Bacabeira' },
    { value: 'Bacuri', name: 'Bacuri', label: 'Bacuri' },
    { value: 'Bacurituba', name: 'Bacurituba', label: 'Bacurituba' },
    { value: 'Balsas', name: 'Balsas', label: 'Balsas' },
    {
      value: 'Barão de Grajaú',
      name: 'Barão de Grajaú',
      label: 'Barão de Grajaú',
    },
    {
      value: 'Barra do Corda',
      name: 'Barra do Corda',
      label: 'Barra do Corda',
    },
    { value: 'Barreirinhas', name: 'Barreirinhas', label: 'Barreirinhas' },
    {
      value: 'Bela Vista do Maranhão',
      name: 'Bela Vista do Maranhão',
      label: 'Bela Vista do Maranhão',
    },
    { value: 'Belágua', name: 'Belágua', label: 'Belágua' },
    {
      value: 'Benedito Leite',
      name: 'Benedito Leite',
      label: 'Benedito Leite',
    },
    { value: 'Bequimão', name: 'Bequimão', label: 'Bequimão' },
    {
      value: 'Bernardo do Mearim',
      name: 'Bernardo do Mearim',
      label: 'Bernardo do Mearim',
    },
    {
      value: 'Boa Vista do Gurupi',
      name: 'Boa Vista do Gurupi',
      label: 'Boa Vista do Gurupi',
    },
    { value: 'Bom Jardim', name: 'Bom Jardim', label: 'Bom Jardim' },
    {
      value: 'Bom Jesus das Selvas',
      name: 'Bom Jesus das Selvas',
      label: 'Bom Jesus das Selvas',
    },
    { value: 'Bom Lugar', name: 'Bom Lugar', label: 'Bom Lugar' },
    { value: 'Brejo', name: 'Brejo', label: 'Brejo' },
    {
      value: 'Brejo de Areia',
      name: 'Brejo de Areia',
      label: 'Brejo de Areia',
    },
    { value: 'Buriti', name: 'Buriti', label: 'Buriti' },
    { value: 'Buriti Bravo', name: 'Buriti Bravo', label: 'Buriti Bravo' },
    { value: 'Buriticupu', name: 'Buriticupu', label: 'Buriticupu' },
    { value: 'Buritirana', name: 'Buritirana', label: 'Buritirana' },
    {
      value: 'Cachoeira Grande',
      name: 'Cachoeira Grande',
      label: 'Cachoeira Grande',
    },
    { value: 'Cajapió', name: 'Cajapió', label: 'Cajapió' },
    { value: 'Cajari', name: 'Cajari', label: 'Cajari' },
    {
      value: 'Campestre do Maranhão',
      name: 'Campestre do Maranhão',
      label: 'Campestre do Maranhão',
    },
    {
      value: 'Cândido Mendes',
      name: 'Cândido Mendes',
      label: 'Cândido Mendes',
    },
    { value: 'Cantanhede', name: 'Cantanhede', label: 'Cantanhede' },
    {
      value: 'Capinzal do Norte',
      name: 'Capinzal do Norte',
      label: 'Capinzal do Norte',
    },
    { value: 'Carolina', name: 'Carolina', label: 'Carolina' },
    { value: 'Carutapera', name: 'Carutapera', label: 'Carutapera' },
    { value: 'Caxias', name: 'Caxias', label: 'Caxias' },
    { value: 'Cedral', name: 'Cedral', label: 'Cedral' },
    {
      value: 'Central do Maranhão',
      name: 'Central do Maranhão',
      label: 'Central do Maranhão',
    },
    {
      value: 'Centro do Guilherme',
      name: 'Centro do Guilherme',
      label: 'Centro do Guilherme',
    },
    {
      value: 'Centro Novo do Maranhão',
      name: 'Centro Novo do Maranhão',
      label: 'Centro Novo do Maranhão',
    },
    { value: 'Chapadinha', name: 'Chapadinha', label: 'Chapadinha' },
    { value: 'Cidelândia', name: 'Cidelândia', label: 'Cidelândia' },
    { value: 'Codó', name: 'Codó', label: 'Codó' },
    { value: 'Coelho Neto', name: 'Coelho Neto', label: 'Coelho Neto' },
    { value: 'Colinas', name: 'Colinas', label: 'Colinas' },
    {
      value: 'Conceição do Lago-Açu',
      name: 'Conceição do Lago-Açu',
      label: 'Conceição do Lago-Açu',
    },
    { value: 'Coroatá', name: 'Coroatá', label: 'Coroatá' },
    { value: 'Cururupu', name: 'Cururupu', label: 'Cururupu' },
    { value: 'Davinópolis', name: 'Davinópolis', label: 'Davinópolis' },
    { value: 'Dom Pedro', name: 'Dom Pedro', label: 'Dom Pedro' },
    { value: 'Duque Bacelar', name: 'Duque Bacelar', label: 'Duque Bacelar' },
    {
      value: 'Esperantinópolis',
      name: 'Esperantinópolis',
      label: 'Esperantinópolis',
    },
    { value: 'Estreito', name: 'Estreito', label: 'Estreito' },
    {
      value: 'Feira Nova do Maranhão',
      name: 'Feira Nova do Maranhão',
      label: 'Feira Nova do Maranhão',
    },
    {
      value: 'Fernando Falcão',
      name: 'Fernando Falcão',
      label: 'Fernando Falcão',
    },
    {
      value: 'Formosa da Serra Negra',
      name: 'Formosa da Serra Negra',
      label: 'Formosa da Serra Negra',
    },
    {
      value: 'Fortaleza dos Nogueiras',
      name: 'Fortaleza dos Nogueiras',
      label: 'Fortaleza dos Nogueiras',
    },
    { value: 'Fortuna', name: 'Fortuna', label: 'Fortuna' },
    {
      value: 'Godofredo Viana',
      name: 'Godofredo Viana',
      label: 'Godofredo Viana',
    },
    {
      value: 'Gonçalves Dias',
      name: 'Gonçalves Dias',
      label: 'Gonçalves Dias',
    },
    {
      value: 'Governador Archer',
      name: 'Governador Archer',
      label: 'Governador Archer',
    },
    {
      value: 'Governador Edison Lobão',
      name: 'Governador Edison Lobão',
      label: 'Governador Edison Lobão',
    },
    {
      value: 'Governador Eugênio Barros',
      name: 'Governador Eugênio Barros',
      label: 'Governador Eugênio Barros',
    },
    {
      value: 'Governador Luiz Rocha',
      name: 'Governador Luiz Rocha',
      label: 'Governador Luiz Rocha',
    },
    {
      value: 'Governador Newton Bello',
      name: 'Governador Newton Bello',
      label: 'Governador Newton Bello',
    },
    {
      value: 'Governador Nunes Freire',
      name: 'Governador Nunes Freire',
      label: 'Governador Nunes Freire',
    },
    { value: 'Graça Aranha', name: 'Graça Aranha', label: 'Graça Aranha' },
    { value: 'Grajaú', name: 'Grajaú', label: 'Grajaú' },
    { value: 'Guimarães', name: 'Guimarães', label: 'Guimarães' },
    {
      value: 'Humberto de Campos',
      name: 'Humberto de Campos',
      label: 'Humberto de Campos',
    },
    { value: 'Icatu', name: 'Icatu', label: 'Icatu' },
    {
      value: 'Igarapé do Meio',
      name: 'Igarapé do Meio',
      label: 'Igarapé do Meio',
    },
    {
      value: 'Igarapé Grande',
      name: 'Igarapé Grande',
      label: 'Igarapé Grande',
    },
    { value: 'Imperatriz', name: 'Imperatriz', label: 'Imperatriz' },
    {
      value: 'Itaipava do Grajaú',
      name: 'Itaipava do Grajaú',
      label: 'Itaipava do Grajaú',
    },
    {
      value: 'Itapecuru Mirim',
      name: 'Itapecuru Mirim',
      label: 'Itapecuru Mirim',
    },
    {
      value: 'Itinga do Maranhão',
      name: 'Itinga do Maranhão',
      label: 'Itinga do Maranhão',
    },
    { value: 'Jatobá', name: 'Jatobá', label: 'Jatobá' },
    {
      value: 'Jenipapo dos Vieiras',
      name: 'Jenipapo dos Vieiras',
      label: 'Jenipapo dos Vieiras',
    },
    { value: 'João Lisboa', name: 'João Lisboa', label: 'João Lisboa' },
    { value: 'Joselândia', name: 'Joselândia', label: 'Joselândia' },
    {
      value: 'Junco do Maranhão',
      name: 'Junco do Maranhão',
      label: 'Junco do Maranhão',
    },
    { value: 'Lago da Pedra', name: 'Lago da Pedra', label: 'Lago da Pedra' },
    { value: 'Lago do Junco', name: 'Lago do Junco', label: 'Lago do Junco' },
    {
      value: 'Lago dos Rodrigues',
      name: 'Lago dos Rodrigues',
      label: 'Lago dos Rodrigues',
    },
    { value: 'Lago Verde', name: 'Lago Verde', label: 'Lago Verde' },
    { value: 'Lagoa do Mato', name: 'Lagoa do Mato', label: 'Lagoa do Mato' },
    {
      value: 'Lagoa Grande do Maranhão',
      name: 'Lagoa Grande do Maranhão',
      label: 'Lagoa Grande do Maranhão',
    },
    { value: 'Lajeado Novo', name: 'Lajeado Novo', label: 'Lajeado Novo' },
    { value: 'Lima Campos', name: 'Lima Campos', label: 'Lima Campos' },
    { value: 'Loreto', name: 'Loreto', label: 'Loreto' },
    {
      value: 'Luís Domingues',
      name: 'Luís Domingues',
      label: 'Luís Domingues',
    },
    {
      value: 'Magalhães de Almeida',
      name: 'Magalhães de Almeida',
      label: 'Magalhães de Almeida',
    },
    { value: 'Maracaçumé', name: 'Maracaçumé', label: 'Maracaçumé' },
    {
      value: 'Marajá do Sena',
      name: 'Marajá do Sena',
      label: 'Marajá do Sena',
    },
    { value: 'Maranhãozinho', name: 'Maranhãozinho', label: 'Maranhãozinho' },
    { value: 'Mata Roma', name: 'Mata Roma', label: 'Mata Roma' },
    { value: 'Matinha', name: 'Matinha', label: 'Matinha' },
    { value: 'Matões', name: 'Matões', label: 'Matões' },
    {
      value: 'Matões do Norte',
      name: 'Matões do Norte',
      label: 'Matões do Norte',
    },
    {
      value: 'Milagres do Maranhão',
      name: 'Milagres do Maranhão',
      label: 'Milagres do Maranhão',
    },
    { value: 'Mirador', name: 'Mirador', label: 'Mirador' },
    {
      value: 'Miranda do Norte',
      name: 'Miranda do Norte',
      label: 'Miranda do Norte',
    },
    { value: 'Mirinzal', name: 'Mirinzal', label: 'Mirinzal' },
    { value: 'Monção', name: 'Monção', label: 'Monção' },
    { value: 'Montes Altos', name: 'Montes Altos', label: 'Montes Altos' },
    { value: 'Morros', name: 'Morros', label: 'Morros' },
    {
      value: 'Nina Rodrigues',
      name: 'Nina Rodrigues',
      label: 'Nina Rodrigues',
    },
    { value: 'Nova Colinas', name: 'Nova Colinas', label: 'Nova Colinas' },
    { value: 'Nova Iorque', name: 'Nova Iorque', label: 'Nova Iorque' },
    {
      value: 'Nova Olinda do Maranhão',
      name: 'Nova Olinda do Maranhão',
      label: 'Nova Olinda do Maranhão',
    },
    {
      value: "Olho D'Água das Cunhãs",
      name: "Olho D'Água das Cunhãs",
      label: "Olho D'Água das Cunhãs",
    },
    {
      value: 'Olinda Nova do Maranhão',
      name: 'Olinda Nova do Maranhão',
      label: 'Olinda Nova do Maranhão',
    },
    {
      value: 'Paço do Lumiar',
      name: 'Paço do Lumiar',
      label: 'Paço do Lumiar',
    },
    { value: 'Palmeirândia', name: 'Palmeirândia', label: 'Palmeirândia' },
    { value: 'Paraibano', name: 'Paraibano', label: 'Paraibano' },
    { value: 'Parnarama', name: 'Parnarama', label: 'Parnarama' },
    {
      value: 'Passagem Franca',
      name: 'Passagem Franca',
      label: 'Passagem Franca',
    },
    { value: 'Pastos Bons', name: 'Pastos Bons', label: 'Pastos Bons' },
    { value: 'Paulino Neves', name: 'Paulino Neves', label: 'Paulino Neves' },
    { value: 'Paulo Ramos', name: 'Paulo Ramos', label: 'Paulo Ramos' },
    { value: 'Pedreiras', name: 'Pedreiras', label: 'Pedreiras' },
    {
      value: 'Pedro do Rosário',
      name: 'Pedro do Rosário',
      label: 'Pedro do Rosário',
    },
    { value: 'Penalva', name: 'Penalva', label: 'Penalva' },
    { value: 'Peri Mirim', name: 'Peri Mirim', label: 'Peri Mirim' },
    { value: 'Peritoró', name: 'Peritoró', label: 'Peritoró' },
    { value: 'Pindaré Mirim', name: 'Pindaré Mirim', label: 'Pindaré Mirim' },
    { value: 'Pinheiro', name: 'Pinheiro', label: 'Pinheiro' },
    { value: 'Pio XII', name: 'Pio XII', label: 'Pio XII' },
    { value: 'Pirapemas', name: 'Pirapemas', label: 'Pirapemas' },
    {
      value: 'Poção de Pedras',
      name: 'Poção de Pedras',
      label: 'Poção de Pedras',
    },
    { value: 'Porto Franco', name: 'Porto Franco', label: 'Porto Franco' },
    {
      value: 'Porto Rico do Maranhão',
      name: 'Porto Rico do Maranhão',
      label: 'Porto Rico do Maranhão',
    },
    {
      value: 'Presidente Dutra',
      name: 'Presidente Dutra',
      label: 'Presidente Dutra',
    },
    {
      value: 'Presidente Juscelino',
      name: 'Presidente Juscelino',
      label: 'Presidente Juscelino',
    },
    {
      value: 'Presidente Médici',
      name: 'Presidente Médici',
      label: 'Presidente Médici',
    },
    {
      value: 'Presidente Sarney',
      name: 'Presidente Sarney',
      label: 'Presidente Sarney',
    },
    {
      value: 'Presidente Vargas',
      name: 'Presidente Vargas',
      label: 'Presidente Vargas',
    },
    { value: 'Primeira Cruz', name: 'Primeira Cruz', label: 'Primeira Cruz' },
    { value: 'Raposa', name: 'Raposa', label: 'Raposa' },
    { value: 'Riachão', name: 'Riachão', label: 'Riachão' },
    {
      value: 'Ribamar Fiquene',
      name: 'Ribamar Fiquene',
      label: 'Ribamar Fiquene',
    },
    { value: 'Rosário', name: 'Rosário', label: 'Rosário' },
    { value: 'Sambaíba', name: 'Sambaíba', label: 'Sambaíba' },
    {
      value: 'Santa Filomena do Maranhão',
      name: 'Santa Filomena do Maranhão',
      label: 'Santa Filomena do Maranhão',
    },
    { value: 'Santa Helena', name: 'Santa Helena', label: 'Santa Helena' },
    { value: 'Santa Inês', name: 'Santa Inês', label: 'Santa Inês' },
    { value: 'Santa Luzia', name: 'Santa Luzia', label: 'Santa Luzia' },
    {
      value: 'Santa Luzia do Paruá',
      name: 'Santa Luzia do Paruá',
      label: 'Santa Luzia do Paruá',
    },
    {
      value: 'Santa Quitéria do Maranhão',
      name: 'Santa Quitéria do Maranhão',
      label: 'Santa Quitéria do Maranhão',
    },
    { value: 'Santa Rita', name: 'Santa Rita', label: 'Santa Rita' },
    {
      value: 'Santana do Maranhão',
      name: 'Santana do Maranhão',
      label: 'Santana do Maranhão',
    },
    {
      value: 'Santo Amaro do Maranhão',
      name: 'Santo Amaro do Maranhão',
      label: 'Santo Amaro do Maranhão',
    },
    {
      value: 'Santo Antônio dos Lopes',
      name: 'Santo Antônio dos Lopes',
      label: 'Santo Antônio dos Lopes',
    },
    {
      value: 'São Benedito do Rio Preto',
      name: 'São Benedito do Rio Preto',
      label: 'São Benedito do Rio Preto',
    },
    { value: 'São Bento', name: 'São Bento', label: 'São Bento' },
    { value: 'São Bernardo', name: 'São Bernardo', label: 'São Bernardo' },
    {
      value: 'São Domingos do Azeitão',
      name: 'São Domingos do Azeitão',
      label: 'São Domingos do Azeitão',
    },
    {
      value: 'São Domingos do Maranhão',
      name: 'São Domingos do Maranhão',
      label: 'São Domingos do Maranhão',
    },
    {
      value: 'São Félix de Balsas',
      name: 'São Félix de Balsas',
      label: 'São Félix de Balsas',
    },
    {
      value: 'São Francisco do Brejão',
      name: 'São Francisco do Brejão',
      label: 'São Francisco do Brejão',
    },
    {
      value: 'São Francisco do Maranhão',
      name: 'São Francisco do Maranhão',
      label: 'São Francisco do Maranhão',
    },
    {
      value: 'São João Batista',
      name: 'São João Batista',
      label: 'São João Batista',
    },
    {
      value: 'São João do Carú',
      name: 'São João do Carú',
      label: 'São João do Carú',
    },
    {
      value: 'São João do Paraíso',
      name: 'São João do Paraíso',
      label: 'São João do Paraíso',
    },
    {
      value: 'São João do Soter',
      name: 'São João do Soter',
      label: 'São João do Soter',
    },
    {
      value: 'São João dos Patos',
      name: 'São João dos Patos',
      label: 'São João dos Patos',
    },
    {
      value: 'São José de Ribamar',
      name: 'São José de Ribamar',
      label: 'São José de Ribamar',
    },
    {
      value: 'São José dos Basílios',
      name: 'São José dos Basílios',
      label: 'São José dos Basílios',
    },
    { value: 'São Luís', name: 'São Luís', label: 'São Luís' },
    {
      value: 'São Luís Gonzaga do Maranhão',
      name: 'São Luís Gonzaga do Maranhão',
      label: 'São Luís Gonzaga do Maranhão',
    },
    {
      value: 'São Mateus do Maranhão',
      name: 'São Mateus do Maranhão',
      label: 'São Mateus do Maranhão',
    },
    {
      value: 'São Pedro da Água Branca',
      name: 'São Pedro da Água Branca',
      label: 'São Pedro da Água Branca',
    },
    {
      value: 'São Pedro dos Crentes',
      name: 'São Pedro dos Crentes',
      label: 'São Pedro dos Crentes',
    },
    {
      value: 'São Raimundo das Mangabeiras',
      name: 'São Raimundo das Mangabeiras',
      label: 'São Raimundo das Mangabeiras',
    },
    {
      value: 'São Raimundo do Doca Bezerra',
      name: 'São Raimundo do Doca Bezerra',
      label: 'São Raimundo do Doca Bezerra',
    },
    { value: 'São Roberto', name: 'São Roberto', label: 'São Roberto' },
    {
      value: 'São Vicente Ferrer',
      name: 'São Vicente Ferrer',
      label: 'São Vicente Ferrer',
    },
    { value: 'Satubinha', name: 'Satubinha', label: 'Satubinha' },
    {
      value: 'Senador Alexandre Costa',
      name: 'Senador Alexandre Costa',
      label: 'Senador Alexandre Costa',
    },
    {
      value: 'Senador La Rocque',
      name: 'Senador La Rocque',
      label: 'Senador La Rocque',
    },
    {
      value: 'Serrano do Maranhão',
      name: 'Serrano do Maranhão',
      label: 'Serrano do Maranhão',
    },
    { value: 'Sítio Novo', name: 'Sítio Novo', label: 'Sítio Novo' },
    {
      value: 'Sucupira do Norte',
      name: 'Sucupira do Norte',
      label: 'Sucupira do Norte',
    },
    {
      value: 'Sucupira do Riachão',
      name: 'Sucupira do Riachão',
      label: 'Sucupira do Riachão',
    },
    { value: 'Tasso Fragoso', name: 'Tasso Fragoso', label: 'Tasso Fragoso' },
    { value: 'Timbiras', name: 'Timbiras', label: 'Timbiras' },
    { value: 'Timon', name: 'Timon', label: 'Timon' },
    {
      value: 'Trizidela do Vale',
      name: 'Trizidela do Vale',
      label: 'Trizidela do Vale',
    },
    { value: 'Tufilândia', name: 'Tufilândia', label: 'Tufilândia' },
    { value: 'Tuntum', name: 'Tuntum', label: 'Tuntum' },
    { value: 'Turiaçu', name: 'Turiaçu', label: 'Turiaçu' },
    { value: 'Turilândia', name: 'Turilândia', label: 'Turilândia' },
    { value: 'Tutóia', name: 'Tutóia', label: 'Tutóia' },
    { value: 'Urbano Santos', name: 'Urbano Santos', label: 'Urbano Santos' },
    { value: 'Vargem Grande', name: 'Vargem Grande', label: 'Vargem Grande' },
    { value: 'Viana', name: 'Viana', label: 'Viana' },
    {
      value: 'Vila Nova dos Martírios',
      name: 'Vila Nova dos Martírios',
      label: 'Vila Nova dos Martírios',
    },
    {
      value: 'Vitória do Mearim',
      name: 'Vitória do Mearim',
      label: 'Vitória do Mearim',
    },
    {
      value: 'Vitorino Freire',
      name: 'Vitorino Freire',
      label: 'Vitorino Freire',
    },
    { value: 'Zé Doca', name: 'Zé Doca', label: 'Zé Doca' },
  ],
  MG: [
    {
      value: 'Abadia dos Dourados',
      name: 'Abadia dos Dourados',
      label: 'Abadia dos Dourados',
    },
    { value: 'Abaeté', name: 'Abaeté', label: 'Abaeté' },
    { value: 'Abre Campo', name: 'Abre Campo', label: 'Abre Campo' },
    { value: 'Acaiaca', name: 'Acaiaca', label: 'Acaiaca' },
    { value: 'Açucena', name: 'Açucena', label: 'Açucena' },
    { value: 'Água Boa', name: 'Água Boa', label: 'Água Boa' },
    { value: 'Água Comprida', name: 'Água Comprida', label: 'Água Comprida' },
    { value: 'Aguanil', name: 'Aguanil', label: 'Aguanil' },
    {
      value: 'Águas Formosas',
      name: 'Águas Formosas',
      label: 'Águas Formosas',
    },
    {
      value: 'Águas Vermelhas',
      name: 'Águas Vermelhas',
      label: 'Águas Vermelhas',
    },
    { value: 'Aimorés', name: 'Aimorés', label: 'Aimorés' },
    { value: 'Aiuruoca', name: 'Aiuruoca', label: 'Aiuruoca' },
    { value: 'Alagoa', name: 'Alagoa', label: 'Alagoa' },
    { value: 'Albertina', name: 'Albertina', label: 'Albertina' },
    { value: 'Além Paraíba', name: 'Além Paraíba', label: 'Além Paraíba' },
    { value: 'Alfenas', name: 'Alfenas', label: 'Alfenas' },
    {
      value: 'Alfredo Vasconcelos',
      name: 'Alfredo Vasconcelos',
      label: 'Alfredo Vasconcelos',
    },
    { value: 'Almenara', name: 'Almenara', label: 'Almenara' },
    { value: 'Alpercata', name: 'Alpercata', label: 'Alpercata' },
    { value: 'Alpinópolis', name: 'Alpinópolis', label: 'Alpinópolis' },
    { value: 'Alterosa', name: 'Alterosa', label: 'Alterosa' },
    { value: 'Alto Caparaó', name: 'Alto Caparaó', label: 'Alto Caparaó' },
    {
      value: 'Alto Jequitibá',
      name: 'Alto Jequitibá',
      label: 'Alto Jequitibá',
    },
    { value: 'Alto Rio Doce', name: 'Alto Rio Doce', label: 'Alto Rio Doce' },
    { value: 'Alvarenga', name: 'Alvarenga', label: 'Alvarenga' },
    { value: 'Alvinópolis', name: 'Alvinópolis', label: 'Alvinópolis' },
    {
      value: 'Alvorada de Minas',
      name: 'Alvorada de Minas',
      label: 'Alvorada de Minas',
    },
    {
      value: 'Amparo do Serra',
      name: 'Amparo do Serra',
      label: 'Amparo do Serra',
    },
    { value: 'Andradas', name: 'Andradas', label: 'Andradas' },
    { value: 'Andrelândia', name: 'Andrelândia', label: 'Andrelândia' },
    { value: 'Angelândia', name: 'Angelândia', label: 'Angelândia' },
    {
      value: 'Antônio Carlos',
      name: 'Antônio Carlos',
      label: 'Antônio Carlos',
    },
    { value: 'Antônio Dias', name: 'Antônio Dias', label: 'Antônio Dias' },
    {
      value: 'Antônio Prado de Minas',
      name: 'Antônio Prado de Minas',
      label: 'Antônio Prado de Minas',
    },
    { value: 'Araçaí', name: 'Araçaí', label: 'Araçaí' },
    { value: 'Aracitaba', name: 'Aracitaba', label: 'Aracitaba' },
    { value: 'Araçuaí', name: 'Araçuaí', label: 'Araçuaí' },
    { value: 'Araguari', name: 'Araguari', label: 'Araguari' },
    { value: 'Arantina', name: 'Arantina', label: 'Arantina' },
    { value: 'Araponga', name: 'Araponga', label: 'Araponga' },
    { value: 'Araporã', name: 'Araporã', label: 'Araporã' },
    { value: 'Arapuá', name: 'Arapuá', label: 'Arapuá' },
    { value: 'Araújos', name: 'Araújos', label: 'Araújos' },
    { value: 'Araxá', name: 'Araxá', label: 'Araxá' },
    { value: 'Arceburgo', name: 'Arceburgo', label: 'Arceburgo' },
    { value: 'Arcos', name: 'Arcos', label: 'Arcos' },
    { value: 'Areado', name: 'Areado', label: 'Areado' },
    { value: 'Argirita', name: 'Argirita', label: 'Argirita' },
    { value: 'Aricanduva', name: 'Aricanduva', label: 'Aricanduva' },
    { value: 'Arinos', name: 'Arinos', label: 'Arinos' },
    { value: 'Astolfo Dutra', name: 'Astolfo Dutra', label: 'Astolfo Dutra' },
    { value: 'Ataléia', name: 'Ataléia', label: 'Ataléia' },
    {
      value: 'Augusto de Lima',
      name: 'Augusto de Lima',
      label: 'Augusto de Lima',
    },
    { value: 'Baependi', name: 'Baependi', label: 'Baependi' },
    { value: 'Baldim', name: 'Baldim', label: 'Baldim' },
    { value: 'Bambuí', name: 'Bambuí', label: 'Bambuí' },
    { value: 'Bandeira', name: 'Bandeira', label: 'Bandeira' },
    {
      value: 'Bandeira do Sul',
      name: 'Bandeira do Sul',
      label: 'Bandeira do Sul',
    },
    {
      value: 'Barão de Cocais',
      name: 'Barão de Cocais',
      label: 'Barão de Cocais',
    },
    {
      value: 'Barão de Monte Alto',
      name: 'Barão de Monte Alto',
      label: 'Barão de Monte Alto',
    },
    { value: 'Barbacena', name: 'Barbacena', label: 'Barbacena' },
    { value: 'Barra Longa', name: 'Barra Longa', label: 'Barra Longa' },
    { value: 'Barroso', name: 'Barroso', label: 'Barroso' },
    {
      value: 'Bela Vista de Minas',
      name: 'Bela Vista de Minas',
      label: 'Bela Vista de Minas',
    },
    { value: 'Belmiro Braga', name: 'Belmiro Braga', label: 'Belmiro Braga' },
    {
      value: 'Belo Horizonte',
      name: 'Belo Horizonte',
      label: 'Belo Horizonte',
    },
    { value: 'Belo Oriente', name: 'Belo Oriente', label: 'Belo Oriente' },
    { value: 'Belo Vale', name: 'Belo Vale', label: 'Belo Vale' },
    { value: 'Berilo', name: 'Berilo', label: 'Berilo' },
    { value: 'Berizal', name: 'Berizal', label: 'Berizal' },
    { value: 'Bertópolis', name: 'Bertópolis', label: 'Bertópolis' },
    { value: 'Betim', name: 'Betim', label: 'Betim' },
    { value: 'Bias Fortes', name: 'Bias Fortes', label: 'Bias Fortes' },
    { value: 'Bicas', name: 'Bicas', label: 'Bicas' },
    { value: 'Biquinhas', name: 'Biquinhas', label: 'Biquinhas' },
    { value: 'Boa Esperança', name: 'Boa Esperança', label: 'Boa Esperança' },
    {
      value: 'Bocaina de Minas',
      name: 'Bocaina de Minas',
      label: 'Bocaina de Minas',
    },
    { value: 'Bocaiúva', name: 'Bocaiúva', label: 'Bocaiúva' },
    { value: 'Bom Despacho', name: 'Bom Despacho', label: 'Bom Despacho' },
    {
      value: 'Bom Jardim de Minas',
      name: 'Bom Jardim de Minas',
      label: 'Bom Jardim de Minas',
    },
    {
      value: 'Bom Jesus da Penha',
      name: 'Bom Jesus da Penha',
      label: 'Bom Jesus da Penha',
    },
    {
      value: 'Bom Jesus do Amparo',
      name: 'Bom Jesus do Amparo',
      label: 'Bom Jesus do Amparo',
    },
    {
      value: 'Bom Jesus do Galho',
      name: 'Bom Jesus do Galho',
      label: 'Bom Jesus do Galho',
    },
    { value: 'Bom Repouso', name: 'Bom Repouso', label: 'Bom Repouso' },
    { value: 'Bom Sucesso', name: 'Bom Sucesso', label: 'Bom Sucesso' },
    { value: 'Bonfim', name: 'Bonfim', label: 'Bonfim' },
    {
      value: 'Bonfinópolis de Minas',
      name: 'Bonfinópolis de Minas',
      label: 'Bonfinópolis de Minas',
    },
    {
      value: 'Bonito de Minas',
      name: 'Bonito de Minas',
      label: 'Bonito de Minas',
    },
    { value: 'Borda da Mata', name: 'Borda da Mata', label: 'Borda da Mata' },
    { value: 'Botelhos', name: 'Botelhos', label: 'Botelhos' },
    { value: 'Botumirim', name: 'Botumirim', label: 'Botumirim' },
    { value: 'Brás Pires', name: 'Brás Pires', label: 'Brás Pires' },
    {
      value: 'Brasilândia de Minas',
      name: 'Brasilândia de Minas',
      label: 'Brasilândia de Minas',
    },
    {
      value: 'Brasília de Minas',
      name: 'Brasília de Minas',
      label: 'Brasília de Minas',
    },
    { value: 'Brasópolis', name: 'Brasópolis', label: 'Brasópolis' },
    { value: 'Braúnas', name: 'Braúnas', label: 'Braúnas' },
    { value: 'Brumadinho', name: 'Brumadinho', label: 'Brumadinho' },
    { value: 'Bueno Brandão', name: 'Bueno Brandão', label: 'Bueno Brandão' },
    { value: 'Buenópolis', name: 'Buenópolis', label: 'Buenópolis' },
    { value: 'Bugre', name: 'Bugre', label: 'Bugre' },
    { value: 'Buritis', name: 'Buritis', label: 'Buritis' },
    { value: 'Buritizeiro', name: 'Buritizeiro', label: 'Buritizeiro' },
    {
      value: 'Cabeceira Grande',
      name: 'Cabeceira Grande',
      label: 'Cabeceira Grande',
    },
    { value: 'Cabo Verde', name: 'Cabo Verde', label: 'Cabo Verde' },
    {
      value: 'Cachoeira da Prata',
      name: 'Cachoeira da Prata',
      label: 'Cachoeira da Prata',
    },
    {
      value: 'Cachoeira de Minas',
      name: 'Cachoeira de Minas',
      label: 'Cachoeira de Minas',
    },
    {
      value: 'Cachoeira de Pajeú',
      name: 'Cachoeira de Pajeú',
      label: 'Cachoeira de Pajeú',
    },
    {
      value: 'Cachoeira Dourada',
      name: 'Cachoeira Dourada',
      label: 'Cachoeira Dourada',
    },
    { value: 'Caetanópolis', name: 'Caetanópolis', label: 'Caetanópolis' },
    { value: 'Caeté', name: 'Caeté', label: 'Caeté' },
    { value: 'Caiana', name: 'Caiana', label: 'Caiana' },
    { value: 'Cajuri', name: 'Cajuri', label: 'Cajuri' },
    { value: 'Caldas', name: 'Caldas', label: 'Caldas' },
    { value: 'Camacho', name: 'Camacho', label: 'Camacho' },
    { value: 'Camanducaia', name: 'Camanducaia', label: 'Camanducaia' },
    { value: 'Cambuí', name: 'Cambuí', label: 'Cambuí' },
    { value: 'Cambuquira', name: 'Cambuquira', label: 'Cambuquira' },
    { value: 'Campanário', name: 'Campanário', label: 'Campanário' },
    { value: 'Campanha', name: 'Campanha', label: 'Campanha' },
    { value: 'Campestre', name: 'Campestre', label: 'Campestre' },
    { value: 'Campina Verde', name: 'Campina Verde', label: 'Campina Verde' },
    { value: 'Campo Azul', name: 'Campo Azul', label: 'Campo Azul' },
    { value: 'Campo Belo', name: 'Campo Belo', label: 'Campo Belo' },
    { value: 'Campo do Meio', name: 'Campo do Meio', label: 'Campo do Meio' },
    { value: 'Campo Florido', name: 'Campo Florido', label: 'Campo Florido' },
    { value: 'Campos Altos', name: 'Campos Altos', label: 'Campos Altos' },
    { value: 'Campos Gerais', name: 'Campos Gerais', label: 'Campos Gerais' },
    { value: 'Cana Verde', name: 'Cana Verde', label: 'Cana Verde' },
    { value: 'Canaã', name: 'Canaã', label: 'Canaã' },
    { value: 'Canápolis', name: 'Canápolis', label: 'Canápolis' },
    { value: 'Candeias', name: 'Candeias', label: 'Candeias' },
    { value: 'Cantagalo', name: 'Cantagalo', label: 'Cantagalo' },
    { value: 'Caparaó', name: 'Caparaó', label: 'Caparaó' },
    { value: 'Capela Nova', name: 'Capela Nova', label: 'Capela Nova' },
    { value: 'Capelinha', name: 'Capelinha', label: 'Capelinha' },
    { value: 'Capetinga', name: 'Capetinga', label: 'Capetinga' },
    { value: 'Capim Branco', name: 'Capim Branco', label: 'Capim Branco' },
    { value: 'Capinópolis', name: 'Capinópolis', label: 'Capinópolis' },
    {
      value: 'Capitão Andrade',
      name: 'Capitão Andrade',
      label: 'Capitão Andrade',
    },
    { value: 'Capitão Enéas', name: 'Capitão Enéas', label: 'Capitão Enéas' },
    { value: 'Capitólio', name: 'Capitólio', label: 'Capitólio' },
    { value: 'Caputira', name: 'Caputira', label: 'Caputira' },
    { value: 'Caraí', name: 'Caraí', label: 'Caraí' },
    { value: 'Caranaíba', name: 'Caranaíba', label: 'Caranaíba' },
    { value: 'Carandaí', name: 'Carandaí', label: 'Carandaí' },
    { value: 'Carangola', name: 'Carangola', label: 'Carangola' },
    { value: 'Caratinga', name: 'Caratinga', label: 'Caratinga' },
    { value: 'Carbonita', name: 'Carbonita', label: 'Carbonita' },
    { value: 'Careaçu', name: 'Careaçu', label: 'Careaçu' },
    { value: 'Carlos Chagas', name: 'Carlos Chagas', label: 'Carlos Chagas' },
    { value: 'Carmésia', name: 'Carmésia', label: 'Carmésia' },
    {
      value: 'Carmo da Cachoeira',
      name: 'Carmo da Cachoeira',
      label: 'Carmo da Cachoeira',
    },
    { value: 'Carmo da Mata', name: 'Carmo da Mata', label: 'Carmo da Mata' },
    {
      value: 'Carmo de Minas',
      name: 'Carmo de Minas',
      label: 'Carmo de Minas',
    },
    {
      value: 'Carmo do Cajuru',
      name: 'Carmo do Cajuru',
      label: 'Carmo do Cajuru',
    },
    {
      value: 'Carmo do Paranaíba',
      name: 'Carmo do Paranaíba',
      label: 'Carmo do Paranaíba',
    },
    {
      value: 'Carmo do Rio Claro',
      name: 'Carmo do Rio Claro',
      label: 'Carmo do Rio Claro',
    },
    {
      value: 'Carmópolis de Minas',
      name: 'Carmópolis de Minas',
      label: 'Carmópolis de Minas',
    },
    { value: 'Carneirinho', name: 'Carneirinho', label: 'Carneirinho' },
    { value: 'Carrancas', name: 'Carrancas', label: 'Carrancas' },
    { value: 'Carvalhópolis', name: 'Carvalhópolis', label: 'Carvalhópolis' },
    { value: 'Carvalhos', name: 'Carvalhos', label: 'Carvalhos' },
    { value: 'Casa Grande', name: 'Casa Grande', label: 'Casa Grande' },
    { value: 'Cascalho Rico', name: 'Cascalho Rico', label: 'Cascalho Rico' },
    { value: 'Cássia', name: 'Cássia', label: 'Cássia' },
    { value: 'Cataguases', name: 'Cataguases', label: 'Cataguases' },
    { value: 'Catas Altas', name: 'Catas Altas', label: 'Catas Altas' },
    {
      value: 'Catas Altas da Noruega',
      name: 'Catas Altas da Noruega',
      label: 'Catas Altas da Noruega',
    },
    { value: 'Catuji', name: 'Catuji', label: 'Catuji' },
    { value: 'Catuti', name: 'Catuti', label: 'Catuti' },
    { value: 'Caxambu', name: 'Caxambu', label: 'Caxambu' },
    {
      value: 'Cedro do Abaeté',
      name: 'Cedro do Abaeté',
      label: 'Cedro do Abaeté',
    },
    {
      value: 'Central de Minas',
      name: 'Central de Minas',
      label: 'Central de Minas',
    },
    { value: 'Centralina', name: 'Centralina', label: 'Centralina' },
    { value: 'Chácara', name: 'Chácara', label: 'Chácara' },
    { value: 'Chalé', name: 'Chalé', label: 'Chalé' },
    {
      value: 'Chapada do Norte',
      name: 'Chapada do Norte',
      label: 'Chapada do Norte',
    },
    {
      value: 'Chapada Gaúcha',
      name: 'Chapada Gaúcha',
      label: 'Chapada Gaúcha',
    },
    { value: 'Chiador', name: 'Chiador', label: 'Chiador' },
    { value: 'Cipotânea', name: 'Cipotânea', label: 'Cipotânea' },
    { value: 'Claraval', name: 'Claraval', label: 'Claraval' },
    {
      value: 'Claro dos Poções',
      name: 'Claro dos Poções',
      label: 'Claro dos Poções',
    },
    { value: 'Cláudio', name: 'Cláudio', label: 'Cláudio' },
    { value: 'Coimbra', name: 'Coimbra', label: 'Coimbra' },
    { value: 'Coluna', name: 'Coluna', label: 'Coluna' },
    {
      value: 'Comendador Gomes',
      name: 'Comendador Gomes',
      label: 'Comendador Gomes',
    },
    { value: 'Comercinho', name: 'Comercinho', label: 'Comercinho' },
    {
      value: 'Conceição da Aparecida',
      name: 'Conceição da Aparecida',
      label: 'Conceição da Aparecida',
    },
    {
      value: 'Conceição da Barra de Minas',
      name: 'Conceição da Barra de Minas',
      label: 'Conceição da Barra de Minas',
    },
    {
      value: 'Conceição das Alagoas',
      name: 'Conceição das Alagoas',
      label: 'Conceição das Alagoas',
    },
    {
      value: 'Conceição das Pedras',
      name: 'Conceição das Pedras',
      label: 'Conceição das Pedras',
    },
    {
      value: 'Conceição de Ipanema',
      name: 'Conceição de Ipanema',
      label: 'Conceição de Ipanema',
    },
    {
      value: 'Conceição do Mato Dentro',
      name: 'Conceição do Mato Dentro',
      label: 'Conceição do Mato Dentro',
    },
    {
      value: 'Conceição do Pará',
      name: 'Conceição do Pará',
      label: 'Conceição do Pará',
    },
    {
      value: 'Conceição do Rio Verde',
      name: 'Conceição do Rio Verde',
      label: 'Conceição do Rio Verde',
    },
    {
      value: 'Conceição dos Ouros',
      name: 'Conceição dos Ouros',
      label: 'Conceição dos Ouros',
    },
    {
      value: 'Cônego Marinho',
      name: 'Cônego Marinho',
      label: 'Cônego Marinho',
    },
    { value: 'Confins', name: 'Confins', label: 'Confins' },
    { value: 'Congonhal', name: 'Congonhal', label: 'Congonhal' },
    { value: 'Congonhas', name: 'Congonhas', label: 'Congonhas' },
    {
      value: 'Congonhas do Norte',
      name: 'Congonhas do Norte',
      label: 'Congonhas do Norte',
    },
    { value: 'Conquista', name: 'Conquista', label: 'Conquista' },
    {
      value: 'Conselheiro Lafaiete',
      name: 'Conselheiro Lafaiete',
      label: 'Conselheiro Lafaiete',
    },
    {
      value: 'Conselheiro Pena',
      name: 'Conselheiro Pena',
      label: 'Conselheiro Pena',
    },
    { value: 'Consolação', name: 'Consolação', label: 'Consolação' },
    { value: 'Contagem', name: 'Contagem', label: 'Contagem' },
    { value: 'Coqueiral', name: 'Coqueiral', label: 'Coqueiral' },
    {
      value: 'Coração de Jesus',
      name: 'Coração de Jesus',
      label: 'Coração de Jesus',
    },
    { value: 'Cordisburgo', name: 'Cordisburgo', label: 'Cordisburgo' },
    { value: 'Cordislândia', name: 'Cordislândia', label: 'Cordislândia' },
    { value: 'Corinto', name: 'Corinto', label: 'Corinto' },
    { value: 'Coroaci', name: 'Coroaci', label: 'Coroaci' },
    { value: 'Coromandel', name: 'Coromandel', label: 'Coromandel' },
    {
      value: 'Coronel Fabriciano',
      name: 'Coronel Fabriciano',
      label: 'Coronel Fabriciano',
    },
    { value: 'Coronel Murta', name: 'Coronel Murta', label: 'Coronel Murta' },
    {
      value: 'Coronel Pacheco',
      name: 'Coronel Pacheco',
      label: 'Coronel Pacheco',
    },
    {
      value: 'Coronel Xavier Chaves',
      name: 'Coronel Xavier Chaves',
      label: 'Coronel Xavier Chaves',
    },
    { value: 'Córrego Danta', name: 'Córrego Danta', label: 'Córrego Danta' },
    {
      value: 'Córrego do Bom Jesus',
      name: 'Córrego do Bom Jesus',
      label: 'Córrego do Bom Jesus',
    },
    { value: 'Córrego Fundo', name: 'Córrego Fundo', label: 'Córrego Fundo' },
    { value: 'Córrego Novo', name: 'Córrego Novo', label: 'Córrego Novo' },
    {
      value: 'Couto de Magalhães de Minas',
      name: 'Couto de Magalhães de Minas',
      label: 'Couto de Magalhães de Minas',
    },
    { value: 'Crisólita', name: 'Crisólita', label: 'Crisólita' },
    { value: 'Cristais', name: 'Cristais', label: 'Cristais' },
    { value: 'Cristália', name: 'Cristália', label: 'Cristália' },
    {
      value: 'Cristiano Otoni',
      name: 'Cristiano Otoni',
      label: 'Cristiano Otoni',
    },
    { value: 'Cristina', name: 'Cristina', label: 'Cristina' },
    { value: 'Crucilândia', name: 'Crucilândia', label: 'Crucilândia' },
    {
      value: 'Cruzeiro da Fortaleza',
      name: 'Cruzeiro da Fortaleza',
      label: 'Cruzeiro da Fortaleza',
    },
    { value: 'Cruzília', name: 'Cruzília', label: 'Cruzília' },
    { value: 'Cuparaque', name: 'Cuparaque', label: 'Cuparaque' },
    {
      value: 'Curral de Dentro',
      name: 'Curral de Dentro',
      label: 'Curral de Dentro',
    },
    { value: 'Curvelo', name: 'Curvelo', label: 'Curvelo' },
    { value: 'Datas', name: 'Datas', label: 'Datas' },
    {
      value: 'Delfim Moreira',
      name: 'Delfim Moreira',
      label: 'Delfim Moreira',
    },
    { value: 'Delfinópolis', name: 'Delfinópolis', label: 'Delfinópolis' },
    { value: 'Delta', name: 'Delta', label: 'Delta' },
    { value: 'Descoberto', name: 'Descoberto', label: 'Descoberto' },
    {
      value: 'Desterro de Entre Rios',
      name: 'Desterro de Entre Rios',
      label: 'Desterro de Entre Rios',
    },
    {
      value: 'Desterro do Melo',
      name: 'Desterro do Melo',
      label: 'Desterro do Melo',
    },
    { value: 'Diamantina', name: 'Diamantina', label: 'Diamantina' },
    {
      value: 'Diogo de Vasconcelos',
      name: 'Diogo de Vasconcelos',
      label: 'Diogo de Vasconcelos',
    },
    { value: 'Dionísio', name: 'Dionísio', label: 'Dionísio' },
    { value: 'Divinésia', name: 'Divinésia', label: 'Divinésia' },
    { value: 'Divino', name: 'Divino', label: 'Divino' },
    {
      value: 'Divino das Laranjeiras',
      name: 'Divino das Laranjeiras',
      label: 'Divino das Laranjeiras',
    },
    {
      value: 'Divinolândia de Minas',
      name: 'Divinolândia de Minas',
      label: 'Divinolândia de Minas',
    },
    { value: 'Divinópolis', name: 'Divinópolis', label: 'Divinópolis' },
    { value: 'Divisa Alegre', name: 'Divisa Alegre', label: 'Divisa Alegre' },
    { value: 'Divisa Nova', name: 'Divisa Nova', label: 'Divisa Nova' },
    { value: 'Divisópolis', name: 'Divisópolis', label: 'Divisópolis' },
    { value: 'Dom Bosco', name: 'Dom Bosco', label: 'Dom Bosco' },
    { value: 'Dom Cavati', name: 'Dom Cavati', label: 'Dom Cavati' },
    { value: 'Dom Joaquim', name: 'Dom Joaquim', label: 'Dom Joaquim' },
    { value: 'Dom Silvério', name: 'Dom Silvério', label: 'Dom Silvério' },
    { value: 'Dom Viçoso', name: 'Dom Viçoso', label: 'Dom Viçoso' },
    { value: 'Dona Euzébia', name: 'Dona Euzébia', label: 'Dona Euzébia' },
    {
      value: 'Dores de Campos',
      name: 'Dores de Campos',
      label: 'Dores de Campos',
    },
    {
      value: 'Dores de Guanhães',
      name: 'Dores de Guanhães',
      label: 'Dores de Guanhães',
    },
    {
      value: 'Dores do Indaiá',
      name: 'Dores do Indaiá',
      label: 'Dores do Indaiá',
    },
    {
      value: 'Dores do Turvo',
      name: 'Dores do Turvo',
      label: 'Dores do Turvo',
    },
    { value: 'Doresópolis', name: 'Doresópolis', label: 'Doresópolis' },
    { value: 'Douradoquara', name: 'Douradoquara', label: 'Douradoquara' },
    { value: 'Durandé', name: 'Durandé', label: 'Durandé' },
    { value: 'Elói Mendes', name: 'Elói Mendes', label: 'Elói Mendes' },
    {
      value: 'Engenheiro Caldas',
      name: 'Engenheiro Caldas',
      label: 'Engenheiro Caldas',
    },
    {
      value: 'Engenheiro Navarro',
      name: 'Engenheiro Navarro',
      label: 'Engenheiro Navarro',
    },
    { value: 'Entre Folhas', name: 'Entre Folhas', label: 'Entre Folhas' },
    {
      value: 'Entre Rios de Minas',
      name: 'Entre Rios de Minas',
      label: 'Entre Rios de Minas',
    },
    { value: 'Ervália', name: 'Ervália', label: 'Ervália' },
    { value: 'Esmeraldas', name: 'Esmeraldas', label: 'Esmeraldas' },
    { value: 'Espera Feliz', name: 'Espera Feliz', label: 'Espera Feliz' },
    { value: 'Espinosa', name: 'Espinosa', label: 'Espinosa' },
    {
      value: 'Espírito Santo do Dourado',
      name: 'Espírito Santo do Dourado',
      label: 'Espírito Santo do Dourado',
    },
    { value: 'Estiva', name: 'Estiva', label: 'Estiva' },
    { value: 'Estrela Dalva', name: 'Estrela Dalva', label: 'Estrela Dalva' },
    {
      value: 'Estrela do Indaiá',
      name: 'Estrela do Indaiá',
      label: 'Estrela do Indaiá',
    },
    {
      value: 'Estrela do Sul',
      name: 'Estrela do Sul',
      label: 'Estrela do Sul',
    },
    { value: 'Eugenópolis', name: 'Eugenópolis', label: 'Eugenópolis' },
    {
      value: 'Ewbank da Câmara',
      name: 'Ewbank da Câmara',
      label: 'Ewbank da Câmara',
    },
    { value: 'Extrema', name: 'Extrema', label: 'Extrema' },
    { value: 'Fama', name: 'Fama', label: 'Fama' },
    { value: 'Faria Lemos', name: 'Faria Lemos', label: 'Faria Lemos' },
    {
      value: 'Felício dos Santos',
      name: 'Felício dos Santos',
      label: 'Felício dos Santos',
    },
    { value: 'Felisburgo', name: 'Felisburgo', label: 'Felisburgo' },
    { value: 'Felixlândia', name: 'Felixlândia', label: 'Felixlândia' },
    {
      value: 'Fernandes Tourinho',
      name: 'Fernandes Tourinho',
      label: 'Fernandes Tourinho',
    },
    { value: 'Ferros', name: 'Ferros', label: 'Ferros' },
    { value: 'Fervedouro', name: 'Fervedouro', label: 'Fervedouro' },
    { value: 'Florestal', name: 'Florestal', label: 'Florestal' },
    { value: 'Formiga', name: 'Formiga', label: 'Formiga' },
    { value: 'Formoso', name: 'Formoso', label: 'Formoso' },
    {
      value: 'Fortaleza de Minas',
      name: 'Fortaleza de Minas',
      label: 'Fortaleza de Minas',
    },
    {
      value: 'Fortuna de Minas',
      name: 'Fortuna de Minas',
      label: 'Fortuna de Minas',
    },
    {
      value: 'Francisco Badaró',
      name: 'Francisco Badaró',
      label: 'Francisco Badaró',
    },
    {
      value: 'Francisco Dumont',
      name: 'Francisco Dumont',
      label: 'Francisco Dumont',
    },
    { value: 'Francisco Sá', name: 'Francisco Sá', label: 'Francisco Sá' },
    {
      value: 'Franciscópolis',
      name: 'Franciscópolis',
      label: 'Franciscópolis',
    },
    { value: 'Frei Gaspar', name: 'Frei Gaspar', label: 'Frei Gaspar' },
    {
      value: 'Frei Inocêncio',
      name: 'Frei Inocêncio',
      label: 'Frei Inocêncio',
    },
    {
      value: 'Frei Lagonegro',
      name: 'Frei Lagonegro',
      label: 'Frei Lagonegro',
    },
    { value: 'Fronteira', name: 'Fronteira', label: 'Fronteira' },
    {
      value: 'Fronteira dos Vales',
      name: 'Fronteira dos Vales',
      label: 'Fronteira dos Vales',
    },
    {
      value: 'Fruta de Leite',
      name: 'Fruta de Leite',
      label: 'Fruta de Leite',
    },
    { value: 'Frutal', name: 'Frutal', label: 'Frutal' },
    { value: 'Funilândia', name: 'Funilândia', label: 'Funilândia' },
    { value: 'Galiléia', name: 'Galiléia', label: 'Galiléia' },
    { value: 'Gameleiras', name: 'Gameleiras', label: 'Gameleiras' },
    { value: 'Glaucilândia', name: 'Glaucilândia', label: 'Glaucilândia' },
    { value: 'Goiabeira', name: 'Goiabeira', label: 'Goiabeira' },
    { value: 'Goianá', name: 'Goianá', label: 'Goianá' },
    { value: 'Gonçalves', name: 'Gonçalves', label: 'Gonçalves' },
    { value: 'Gonzaga', name: 'Gonzaga', label: 'Gonzaga' },
    { value: 'Gouveia', name: 'Gouveia', label: 'Gouveia' },
    {
      value: 'Governador Valadares',
      name: 'Governador Valadares',
      label: 'Governador Valadares',
    },
    { value: 'Grão Mogol', name: 'Grão Mogol', label: 'Grão Mogol' },
    { value: 'Grupiara', name: 'Grupiara', label: 'Grupiara' },
    { value: 'Guanhães', name: 'Guanhães', label: 'Guanhães' },
    { value: 'Guapé', name: 'Guapé', label: 'Guapé' },
    { value: 'Guaraciaba', name: 'Guaraciaba', label: 'Guaraciaba' },
    { value: 'Guaraciama', name: 'Guaraciama', label: 'Guaraciama' },
    { value: 'Guaranésia', name: 'Guaranésia', label: 'Guaranésia' },
    { value: 'Guarani', name: 'Guarani', label: 'Guarani' },
    { value: 'Guarará', name: 'Guarará', label: 'Guarará' },
    { value: 'Guarda-Mor', name: 'Guarda-Mor', label: 'Guarda-Mor' },
    { value: 'Guaxupé', name: 'Guaxupé', label: 'Guaxupé' },
    { value: 'Guidoval', name: 'Guidoval', label: 'Guidoval' },
    { value: 'Guimarânia', name: 'Guimarânia', label: 'Guimarânia' },
    { value: 'Guiricema', name: 'Guiricema', label: 'Guiricema' },
    { value: 'Gurinhatã', name: 'Gurinhatã', label: 'Gurinhatã' },
    { value: 'Heliodora', name: 'Heliodora', label: 'Heliodora' },
    { value: 'Iapu', name: 'Iapu', label: 'Iapu' },
    { value: 'Ibertioga', name: 'Ibertioga', label: 'Ibertioga' },
    { value: 'Ibiá', name: 'Ibiá', label: 'Ibiá' },
    { value: 'Ibiaí', name: 'Ibiaí', label: 'Ibiaí' },
    { value: 'Ibiracatu', name: 'Ibiracatu', label: 'Ibiracatu' },
    { value: 'Ibiraci', name: 'Ibiraci', label: 'Ibiraci' },
    { value: 'Ibirité', name: 'Ibirité', label: 'Ibirité' },
    {
      value: 'Ibitiúra de Minas',
      name: 'Ibitiúra de Minas',
      label: 'Ibitiúra de Minas',
    },
    { value: 'Ibituruna', name: 'Ibituruna', label: 'Ibituruna' },
    {
      value: 'Icaraí de Minas',
      name: 'Icaraí de Minas',
      label: 'Icaraí de Minas',
    },
    { value: 'Igarapé', name: 'Igarapé', label: 'Igarapé' },
    { value: 'Igaratinga', name: 'Igaratinga', label: 'Igaratinga' },
    { value: 'Iguatama', name: 'Iguatama', label: 'Iguatama' },
    { value: 'Ijaci', name: 'Ijaci', label: 'Ijaci' },
    { value: 'Ilicínea', name: 'Ilicínea', label: 'Ilicínea' },
    { value: 'Imbé de Minas', name: 'Imbé de Minas', label: 'Imbé de Minas' },
    { value: 'Inconfidentes', name: 'Inconfidentes', label: 'Inconfidentes' },
    { value: 'Indaiabira', name: 'Indaiabira', label: 'Indaiabira' },
    { value: 'Indianópolis', name: 'Indianópolis', label: 'Indianópolis' },
    { value: 'Ingaí', name: 'Ingaí', label: 'Ingaí' },
    { value: 'Inhapim', name: 'Inhapim', label: 'Inhapim' },
    { value: 'Inhaúma', name: 'Inhaúma', label: 'Inhaúma' },
    { value: 'Inimutaba', name: 'Inimutaba', label: 'Inimutaba' },
    { value: 'Ipaba', name: 'Ipaba', label: 'Ipaba' },
    { value: 'Ipanema', name: 'Ipanema', label: 'Ipanema' },
    { value: 'Ipatinga', name: 'Ipatinga', label: 'Ipatinga' },
    { value: 'Ipiaçu', name: 'Ipiaçu', label: 'Ipiaçu' },
    { value: 'Ipuiúna', name: 'Ipuiúna', label: 'Ipuiúna' },
    { value: 'Iraí de Minas', name: 'Iraí de Minas', label: 'Iraí de Minas' },
    { value: 'Itabira', name: 'Itabira', label: 'Itabira' },
    {
      value: 'Itabirinha de Mantena',
      name: 'Itabirinha de Mantena',
      label: 'Itabirinha de Mantena',
    },
    { value: 'Itabirito', name: 'Itabirito', label: 'Itabirito' },
    { value: 'Itacambira', name: 'Itacambira', label: 'Itacambira' },
    { value: 'Itacarambi', name: 'Itacarambi', label: 'Itacarambi' },
    { value: 'Itaguara', name: 'Itaguara', label: 'Itaguara' },
    { value: 'Itaipé', name: 'Itaipé', label: 'Itaipé' },
    { value: 'Itajubá', name: 'Itajubá', label: 'Itajubá' },
    { value: 'Itamarandiba', name: 'Itamarandiba', label: 'Itamarandiba' },
    {
      value: 'Itamarati de Minas',
      name: 'Itamarati de Minas',
      label: 'Itamarati de Minas',
    },
    { value: 'Itambacuri', name: 'Itambacuri', label: 'Itambacuri' },
    {
      value: 'Itambé do Mato Dentro',
      name: 'Itambé do Mato Dentro',
      label: 'Itambé do Mato Dentro',
    },
    { value: 'Itamogi', name: 'Itamogi', label: 'Itamogi' },
    { value: 'Itamonte', name: 'Itamonte', label: 'Itamonte' },
    { value: 'Itanhandu', name: 'Itanhandu', label: 'Itanhandu' },
    { value: 'Itanhomi', name: 'Itanhomi', label: 'Itanhomi' },
    { value: 'Itaobim', name: 'Itaobim', label: 'Itaobim' },
    { value: 'Itapagipe', name: 'Itapagipe', label: 'Itapagipe' },
    { value: 'Itapecerica', name: 'Itapecerica', label: 'Itapecerica' },
    { value: 'Itapeva', name: 'Itapeva', label: 'Itapeva' },
    { value: 'Itatiaiuçu', name: 'Itatiaiuçu', label: 'Itatiaiuçu' },
    { value: 'Itaú de Minas', name: 'Itaú de Minas', label: 'Itaú de Minas' },
    { value: 'Itaúna', name: 'Itaúna', label: 'Itaúna' },
    { value: 'Itaverava', name: 'Itaverava', label: 'Itaverava' },
    { value: 'Itinga', name: 'Itinga', label: 'Itinga' },
    { value: 'Itueta', name: 'Itueta', label: 'Itueta' },
    { value: 'Ituiutaba', name: 'Ituiutaba', label: 'Ituiutaba' },
    { value: 'Itumirim', name: 'Itumirim', label: 'Itumirim' },
    { value: 'Iturama', name: 'Iturama', label: 'Iturama' },
    { value: 'Itutinga', name: 'Itutinga', label: 'Itutinga' },
    { value: 'Jaboticatubas', name: 'Jaboticatubas', label: 'Jaboticatubas' },
    { value: 'Jacinto', name: 'Jacinto', label: 'Jacinto' },
    { value: 'Jacuí', name: 'Jacuí', label: 'Jacuí' },
    { value: 'Jacutinga', name: 'Jacutinga', label: 'Jacutinga' },
    { value: 'Jaguaraçu', name: 'Jaguaraçu', label: 'Jaguaraçu' },
    { value: 'Jaíba', name: 'Jaíba', label: 'Jaíba' },
    { value: 'Jampruca', name: 'Jampruca', label: 'Jampruca' },
    { value: 'Janaúba', name: 'Janaúba', label: 'Janaúba' },
    { value: 'Januária', name: 'Januária', label: 'Januária' },
    { value: 'Japaraíba', name: 'Japaraíba', label: 'Japaraíba' },
    { value: 'Japonvar', name: 'Japonvar', label: 'Japonvar' },
    { value: 'Jeceaba', name: 'Jeceaba', label: 'Jeceaba' },
    {
      value: 'Jenipapo de Minas',
      name: 'Jenipapo de Minas',
      label: 'Jenipapo de Minas',
    },
    { value: 'Jequeri', name: 'Jequeri', label: 'Jequeri' },
    { value: 'Jequitaí', name: 'Jequitaí', label: 'Jequitaí' },
    { value: 'Jequitibá', name: 'Jequitibá', label: 'Jequitibá' },
    { value: 'Jequitinhonha', name: 'Jequitinhonha', label: 'Jequitinhonha' },
    { value: 'Jesuânia', name: 'Jesuânia', label: 'Jesuânia' },
    { value: 'Joaíma', name: 'Joaíma', label: 'Joaíma' },
    { value: 'Joanésia', name: 'Joanésia', label: 'Joanésia' },
    {
      value: 'João Monlevade',
      name: 'João Monlevade',
      label: 'João Monlevade',
    },
    { value: 'João Pinheiro', name: 'João Pinheiro', label: 'João Pinheiro' },
    {
      value: 'Joaquim Felício',
      name: 'Joaquim Felício',
      label: 'Joaquim Felício',
    },
    { value: 'Jordânia', name: 'Jordânia', label: 'Jordânia' },
    {
      value: 'José Gonçalves de Minas',
      name: 'José Gonçalves de Minas',
      label: 'José Gonçalves de Minas',
    },
    { value: 'José Raydan', name: 'José Raydan', label: 'José Raydan' },
    { value: 'Josenópolis', name: 'Josenópolis', label: 'Josenópolis' },
    { value: 'Juatuba', name: 'Juatuba', label: 'Juatuba' },
    { value: 'Juiz de Fora', name: 'Juiz de Fora', label: 'Juiz de Fora' },
    { value: 'Juramento', name: 'Juramento', label: 'Juramento' },
    { value: 'Juruaia', name: 'Juruaia', label: 'Juruaia' },
    { value: 'Juvenília', name: 'Juvenília', label: 'Juvenília' },
    { value: 'Ladainha', name: 'Ladainha', label: 'Ladainha' },
    { value: 'Lagamar', name: 'Lagamar', label: 'Lagamar' },
    {
      value: 'Lagoa da Prata',
      name: 'Lagoa da Prata',
      label: 'Lagoa da Prata',
    },
    {
      value: 'Lagoa dos Patos',
      name: 'Lagoa dos Patos',
      label: 'Lagoa dos Patos',
    },
    { value: 'Lagoa Dourada', name: 'Lagoa Dourada', label: 'Lagoa Dourada' },
    { value: 'Lagoa Formosa', name: 'Lagoa Formosa', label: 'Lagoa Formosa' },
    { value: 'Lagoa Grande', name: 'Lagoa Grande', label: 'Lagoa Grande' },
    { value: 'Lagoa Santa', name: 'Lagoa Santa', label: 'Lagoa Santa' },
    { value: 'Lajinha', name: 'Lajinha', label: 'Lajinha' },
    { value: 'Lambari', name: 'Lambari', label: 'Lambari' },
    { value: 'Lamim', name: 'Lamim', label: 'Lamim' },
    { value: 'Laranjal', name: 'Laranjal', label: 'Laranjal' },
    { value: 'Lassance', name: 'Lassance', label: 'Lassance' },
    { value: 'Lavras', name: 'Lavras', label: 'Lavras' },
    {
      value: 'Leandro Ferreira',
      name: 'Leandro Ferreira',
      label: 'Leandro Ferreira',
    },
    { value: 'Leme do Prado', name: 'Leme do Prado', label: 'Leme do Prado' },
    { value: 'Leopoldina', name: 'Leopoldina', label: 'Leopoldina' },
    { value: 'Liberdade', name: 'Liberdade', label: 'Liberdade' },
    { value: 'Lima Duarte', name: 'Lima Duarte', label: 'Lima Duarte' },
    {
      value: 'Limeira do Oeste',
      name: 'Limeira do Oeste',
      label: 'Limeira do Oeste',
    },
    { value: 'Lontra', name: 'Lontra', label: 'Lontra' },
    { value: 'Luisburgo', name: 'Luisburgo', label: 'Luisburgo' },
    { value: 'Luislândia', name: 'Luislândia', label: 'Luislândia' },
    { value: 'Luminárias', name: 'Luminárias', label: 'Luminárias' },
    { value: 'Luz', name: 'Luz', label: 'Luz' },
    { value: 'Machacalis', name: 'Machacalis', label: 'Machacalis' },
    { value: 'Machado', name: 'Machado', label: 'Machado' },
    {
      value: 'Madre de Deus de Minas',
      name: 'Madre de Deus de Minas',
      label: 'Madre de Deus de Minas',
    },
    { value: 'Malacacheta', name: 'Malacacheta', label: 'Malacacheta' },
    { value: 'Mamonas', name: 'Mamonas', label: 'Mamonas' },
    { value: 'Manga', name: 'Manga', label: 'Manga' },
    { value: 'Manhuaçu', name: 'Manhuaçu', label: 'Manhuaçu' },
    { value: 'Manhumirim', name: 'Manhumirim', label: 'Manhumirim' },
    { value: 'Mantena', name: 'Mantena', label: 'Mantena' },
    {
      value: 'Mar de Espanha',
      name: 'Mar de Espanha',
      label: 'Mar de Espanha',
    },
    { value: 'Maravilhas', name: 'Maravilhas', label: 'Maravilhas' },
    { value: 'Maria da Fé', name: 'Maria da Fé', label: 'Maria da Fé' },
    { value: 'Mariana', name: 'Mariana', label: 'Mariana' },
    { value: 'Marilac', name: 'Marilac', label: 'Marilac' },
    { value: 'Mário Campos', name: 'Mário Campos', label: 'Mário Campos' },
    {
      value: 'Maripá de Minas',
      name: 'Maripá de Minas',
      label: 'Maripá de Minas',
    },
    { value: 'Marliéria', name: 'Marliéria', label: 'Marliéria' },
    { value: 'Marmelópolis', name: 'Marmelópolis', label: 'Marmelópolis' },
    {
      value: 'Martinho Campos',
      name: 'Martinho Campos',
      label: 'Martinho Campos',
    },
    {
      value: 'Martins Soares',
      name: 'Martins Soares',
      label: 'Martins Soares',
    },
    { value: 'Mata Verde', name: 'Mata Verde', label: 'Mata Verde' },
    { value: 'Materlândia', name: 'Materlândia', label: 'Materlândia' },
    { value: 'Mateus Leme', name: 'Mateus Leme', label: 'Mateus Leme' },
    {
      value: 'Mathias Lobato',
      name: 'Mathias Lobato',
      label: 'Mathias Lobato',
    },
    {
      value: 'Matias Barbosa',
      name: 'Matias Barbosa',
      label: 'Matias Barbosa',
    },
    {
      value: 'Matias Cardoso',
      name: 'Matias Cardoso',
      label: 'Matias Cardoso',
    },
    { value: 'Matipó', name: 'Matipó', label: 'Matipó' },
    { value: 'Mato Verde', name: 'Mato Verde', label: 'Mato Verde' },
    { value: 'Matozinhos', name: 'Matozinhos', label: 'Matozinhos' },
    { value: 'Matutina', name: 'Matutina', label: 'Matutina' },
    { value: 'Medeiros', name: 'Medeiros', label: 'Medeiros' },
    { value: 'Medina', name: 'Medina', label: 'Medina' },
    {
      value: 'Mendes Pimentel',
      name: 'Mendes Pimentel',
      label: 'Mendes Pimentel',
    },
    { value: 'Mercês', name: 'Mercês', label: 'Mercês' },
    { value: 'Mesquita', name: 'Mesquita', label: 'Mesquita' },
    { value: 'Minas Novas', name: 'Minas Novas', label: 'Minas Novas' },
    { value: 'Minduri', name: 'Minduri', label: 'Minduri' },
    { value: 'Mirabela', name: 'Mirabela', label: 'Mirabela' },
    { value: 'Miradouro', name: 'Miradouro', label: 'Miradouro' },
    { value: 'Miraí', name: 'Miraí', label: 'Miraí' },
    { value: 'Miravânia', name: 'Miravânia', label: 'Miravânia' },
    { value: 'Moeda', name: 'Moeda', label: 'Moeda' },
    { value: 'Moema', name: 'Moema', label: 'Moema' },
    { value: 'Monjolos', name: 'Monjolos', label: 'Monjolos' },
    {
      value: 'Monsenhor Paulo',
      name: 'Monsenhor Paulo',
      label: 'Monsenhor Paulo',
    },
    { value: 'Montalvânia', name: 'Montalvânia', label: 'Montalvânia' },
    {
      value: 'Monte Alegre de Minas',
      name: 'Monte Alegre de Minas',
      label: 'Monte Alegre de Minas',
    },
    { value: 'Monte Azul', name: 'Monte Azul', label: 'Monte Azul' },
    { value: 'Monte Belo', name: 'Monte Belo', label: 'Monte Belo' },
    { value: 'Monte Carmelo', name: 'Monte Carmelo', label: 'Monte Carmelo' },
    { value: 'Monte Formoso', name: 'Monte Formoso', label: 'Monte Formoso' },
    {
      value: 'Monte Santo de Minas',
      name: 'Monte Santo de Minas',
      label: 'Monte Santo de Minas',
    },
    { value: 'Monte Sião', name: 'Monte Sião', label: 'Monte Sião' },
    { value: 'Montes Claros', name: 'Montes Claros', label: 'Montes Claros' },
    { value: 'Montezuma', name: 'Montezuma', label: 'Montezuma' },
    {
      value: 'Morada Nova de Minas',
      name: 'Morada Nova de Minas',
      label: 'Morada Nova de Minas',
    },
    {
      value: 'Morro da Garça',
      name: 'Morro da Garça',
      label: 'Morro da Garça',
    },
    {
      value: 'Morro do Pilar',
      name: 'Morro do Pilar',
      label: 'Morro do Pilar',
    },
    { value: 'Munhoz', name: 'Munhoz', label: 'Munhoz' },
    { value: 'Muriaé', name: 'Muriaé', label: 'Muriaé' },
    { value: 'Mutum', name: 'Mutum', label: 'Mutum' },
    { value: 'Muzambinho', name: 'Muzambinho', label: 'Muzambinho' },
    { value: 'Nacip Raydan', name: 'Nacip Raydan', label: 'Nacip Raydan' },
    { value: 'Nanuque', name: 'Nanuque', label: 'Nanuque' },
    { value: 'Naque', name: 'Naque', label: 'Naque' },
    { value: 'Natalândia', name: 'Natalândia', label: 'Natalândia' },
    { value: 'Natércia', name: 'Natércia', label: 'Natércia' },
    { value: 'Nazareno', name: 'Nazareno', label: 'Nazareno' },
    { value: 'Nepomuceno', name: 'Nepomuceno', label: 'Nepomuceno' },
    { value: 'Ninheira', name: 'Ninheira', label: 'Ninheira' },
    { value: 'Nova Belém', name: 'Nova Belém', label: 'Nova Belém' },
    { value: 'Nova Era', name: 'Nova Era', label: 'Nova Era' },
    { value: 'Nova Lima', name: 'Nova Lima', label: 'Nova Lima' },
    { value: 'Nova Módica', name: 'Nova Módica', label: 'Nova Módica' },
    { value: 'Nova Ponte', name: 'Nova Ponte', label: 'Nova Ponte' },
    {
      value: 'Nova Porteirinha',
      name: 'Nova Porteirinha',
      label: 'Nova Porteirinha',
    },
    { value: 'Nova Resende', name: 'Nova Resende', label: 'Nova Resende' },
    { value: 'Nova Serrana', name: 'Nova Serrana', label: 'Nova Serrana' },
    { value: 'Nova União', name: 'Nova União', label: 'Nova União' },
    { value: 'Novo Cruzeiro', name: 'Novo Cruzeiro', label: 'Novo Cruzeiro' },
    {
      value: 'Novo Oriente de Minas',
      name: 'Novo Oriente de Minas',
      label: 'Novo Oriente de Minas',
    },
    { value: 'Novorizonte', name: 'Novorizonte', label: 'Novorizonte' },
    { value: 'Olaria', name: 'Olaria', label: 'Olaria' },
    { value: "Olhos D'Água", name: "Olhos D'Água", label: "Olhos D'Água" },
    {
      value: 'Olímpio Noronha',
      name: 'Olímpio Noronha',
      label: 'Olímpio Noronha',
    },
    { value: 'Oliveira', name: 'Oliveira', label: 'Oliveira' },
    {
      value: 'Oliveira Fortes',
      name: 'Oliveira Fortes',
      label: 'Oliveira Fortes',
    },
    {
      value: 'Onça de Pitangui',
      name: 'Onça de Pitangui',
      label: 'Onça de Pitangui',
    },
    { value: 'Oratórios', name: 'Oratórios', label: 'Oratórios' },
    { value: 'Orizânia', name: 'Orizânia', label: 'Orizânia' },
    { value: 'Ouro Branco', name: 'Ouro Branco', label: 'Ouro Branco' },
    { value: 'Ouro Fino', name: 'Ouro Fino', label: 'Ouro Fino' },
    { value: 'Ouro Preto', name: 'Ouro Preto', label: 'Ouro Preto' },
    {
      value: 'Ouro Verde de Minas',
      name: 'Ouro Verde de Minas',
      label: 'Ouro Verde de Minas',
    },
    {
      value: 'Padre Carvalho',
      name: 'Padre Carvalho',
      label: 'Padre Carvalho',
    },
    { value: 'Padre Paraíso', name: 'Padre Paraíso', label: 'Padre Paraíso' },
    { value: 'Pai Pedro', name: 'Pai Pedro', label: 'Pai Pedro' },
    { value: 'Paineiras', name: 'Paineiras', label: 'Paineiras' },
    { value: 'Pains', name: 'Pains', label: 'Pains' },
    { value: 'Paiva', name: 'Paiva', label: 'Paiva' },
    { value: 'Palma', name: 'Palma', label: 'Palma' },
    { value: 'Palmópolis', name: 'Palmópolis', label: 'Palmópolis' },
    { value: 'Papagaios', name: 'Papagaios', label: 'Papagaios' },
    { value: 'Pará de Minas', name: 'Pará de Minas', label: 'Pará de Minas' },
    { value: 'Paracatu', name: 'Paracatu', label: 'Paracatu' },
    { value: 'Paraguaçu', name: 'Paraguaçu', label: 'Paraguaçu' },
    { value: 'Paraisópolis', name: 'Paraisópolis', label: 'Paraisópolis' },
    { value: 'Paraopeba', name: 'Paraopeba', label: 'Paraopeba' },
    { value: 'Passa Quatro', name: 'Passa Quatro', label: 'Passa Quatro' },
    { value: 'Passa Tempo', name: 'Passa Tempo', label: 'Passa Tempo' },
    { value: 'Passa Vinte', name: 'Passa Vinte', label: 'Passa Vinte' },
    { value: 'Passabém', name: 'Passabém', label: 'Passabém' },
    { value: 'Passos', name: 'Passos', label: 'Passos' },
    { value: 'Patis', name: 'Patis', label: 'Patis' },
    {
      value: 'Patos de Minas',
      name: 'Patos de Minas',
      label: 'Patos de Minas',
    },
    { value: 'Patrocínio', name: 'Patrocínio', label: 'Patrocínio' },
    {
      value: 'Patrocínio do Muriaé',
      name: 'Patrocínio do Muriaé',
      label: 'Patrocínio do Muriaé',
    },
    { value: 'Paula Cândido', name: 'Paula Cândido', label: 'Paula Cândido' },
    { value: 'Paulistas', name: 'Paulistas', label: 'Paulistas' },
    { value: 'Pavão', name: 'Pavão', label: 'Pavão' },
    { value: 'Peçanha', name: 'Peçanha', label: 'Peçanha' },
    { value: 'Pedra Azul', name: 'Pedra Azul', label: 'Pedra Azul' },
    { value: 'Pedra Bonita', name: 'Pedra Bonita', label: 'Pedra Bonita' },
    { value: 'Pedra do Anta', name: 'Pedra do Anta', label: 'Pedra do Anta' },
    {
      value: 'Pedra do Indaiá',
      name: 'Pedra do Indaiá',
      label: 'Pedra do Indaiá',
    },
    { value: 'Pedra Dourada', name: 'Pedra Dourada', label: 'Pedra Dourada' },
    { value: 'Pedralva', name: 'Pedralva', label: 'Pedralva' },
    {
      value: 'Pedras de Maria da Cruz',
      name: 'Pedras de Maria da Cruz',
      label: 'Pedras de Maria da Cruz',
    },
    { value: 'Pedrinópolis', name: 'Pedrinópolis', label: 'Pedrinópolis' },
    {
      value: 'Pedro Leopoldo',
      name: 'Pedro Leopoldo',
      label: 'Pedro Leopoldo',
    },
    {
      value: 'Pedro Teixeira',
      name: 'Pedro Teixeira',
      label: 'Pedro Teixeira',
    },
    { value: 'Pequeri', name: 'Pequeri', label: 'Pequeri' },
    { value: 'Pequi', name: 'Pequi', label: 'Pequi' },
    { value: 'Perdigão', name: 'Perdigão', label: 'Perdigão' },
    { value: 'Perdizes', name: 'Perdizes', label: 'Perdizes' },
    { value: 'Perdões', name: 'Perdões', label: 'Perdões' },
    { value: 'Periquito', name: 'Periquito', label: 'Periquito' },
    { value: 'Pescador', name: 'Pescador', label: 'Pescador' },
    { value: 'Piau', name: 'Piau', label: 'Piau' },
    {
      value: 'Piedade de Caratinga',
      name: 'Piedade de Caratinga',
      label: 'Piedade de Caratinga',
    },
    {
      value: 'Piedade de Ponte Nova',
      name: 'Piedade de Ponte Nova',
      label: 'Piedade de Ponte Nova',
    },
    {
      value: 'Piedade do Rio Grande',
      name: 'Piedade do Rio Grande',
      label: 'Piedade do Rio Grande',
    },
    {
      value: 'Piedade dos Gerais',
      name: 'Piedade dos Gerais',
      label: 'Piedade dos Gerais',
    },
    { value: 'Pimenta', name: 'Pimenta', label: 'Pimenta' },
    { value: "Pingo D'Água", name: "Pingo D'Água", label: "Pingo D'Água" },
    { value: 'Pintópolis', name: 'Pintópolis', label: 'Pintópolis' },
    { value: 'Piracema', name: 'Piracema', label: 'Piracema' },
    { value: 'Pirajuba', name: 'Pirajuba', label: 'Pirajuba' },
    { value: 'Piranga', name: 'Piranga', label: 'Piranga' },
    { value: 'Piranguçu', name: 'Piranguçu', label: 'Piranguçu' },
    { value: 'Piranguinho', name: 'Piranguinho', label: 'Piranguinho' },
    { value: 'Pirapetinga', name: 'Pirapetinga', label: 'Pirapetinga' },
    { value: 'Pirapora', name: 'Pirapora', label: 'Pirapora' },
    { value: 'Piraúba', name: 'Piraúba', label: 'Piraúba' },
    { value: 'Pitangui', name: 'Pitangui', label: 'Pitangui' },
    { value: 'Piumhi', name: 'Piumhi', label: 'Piumhi' },
    { value: 'Planura', name: 'Planura', label: 'Planura' },
    { value: 'Poço Fundo', name: 'Poço Fundo', label: 'Poço Fundo' },
    {
      value: 'Poços de Caldas',
      name: 'Poços de Caldas',
      label: 'Poços de Caldas',
    },
    { value: 'Pocrane', name: 'Pocrane', label: 'Pocrane' },
    { value: 'Pompéu', name: 'Pompéu', label: 'Pompéu' },
    { value: 'Ponte Nova', name: 'Ponte Nova', label: 'Ponte Nova' },
    { value: 'Ponto Chique', name: 'Ponto Chique', label: 'Ponto Chique' },
    {
      value: 'Ponto dos Volantes',
      name: 'Ponto dos Volantes',
      label: 'Ponto dos Volantes',
    },
    { value: 'Porteirinha', name: 'Porteirinha', label: 'Porteirinha' },
    { value: 'Porto Firme', name: 'Porto Firme', label: 'Porto Firme' },
    { value: 'Poté', name: 'Poté', label: 'Poté' },
    { value: 'Pouso Alegre', name: 'Pouso Alegre', label: 'Pouso Alegre' },
    { value: 'Pouso Alto', name: 'Pouso Alto', label: 'Pouso Alto' },
    { value: 'Prados', name: 'Prados', label: 'Prados' },
    { value: 'Prata', name: 'Prata', label: 'Prata' },
    { value: 'Pratápolis', name: 'Pratápolis', label: 'Pratápolis' },
    { value: 'Pratinha', name: 'Pratinha', label: 'Pratinha' },
    {
      value: 'Presidente Bernardes',
      name: 'Presidente Bernardes',
      label: 'Presidente Bernardes',
    },
    {
      value: 'Presidente Juscelino',
      name: 'Presidente Juscelino',
      label: 'Presidente Juscelino',
    },
    {
      value: 'Presidente Kubitschek',
      name: 'Presidente Kubitschek',
      label: 'Presidente Kubitschek',
    },
    {
      value: 'Presidente Olegário',
      name: 'Presidente Olegário',
      label: 'Presidente Olegário',
    },
    {
      value: 'Prudente de Morais',
      name: 'Prudente de Morais',
      label: 'Prudente de Morais',
    },
    { value: 'Quartel Geral', name: 'Quartel Geral', label: 'Quartel Geral' },
    { value: 'Queluzito', name: 'Queluzito', label: 'Queluzito' },
    { value: 'Raposos', name: 'Raposos', label: 'Raposos' },
    { value: 'Raul Soares', name: 'Raul Soares', label: 'Raul Soares' },
    { value: 'Recreio', name: 'Recreio', label: 'Recreio' },
    { value: 'Reduto', name: 'Reduto', label: 'Reduto' },
    { value: 'Resende Costa', name: 'Resende Costa', label: 'Resende Costa' },
    { value: 'Resplendor', name: 'Resplendor', label: 'Resplendor' },
    { value: 'Ressaquinha', name: 'Ressaquinha', label: 'Ressaquinha' },
    { value: 'Riachinho', name: 'Riachinho', label: 'Riachinho' },
    {
      value: 'Riacho dos Machados',
      name: 'Riacho dos Machados',
      label: 'Riacho dos Machados',
    },
    {
      value: 'Ribeirão das Neves',
      name: 'Ribeirão das Neves',
      label: 'Ribeirão das Neves',
    },
    {
      value: 'Ribeirão Vermelho',
      name: 'Ribeirão Vermelho',
      label: 'Ribeirão Vermelho',
    },
    { value: 'Rio Acima', name: 'Rio Acima', label: 'Rio Acima' },
    { value: 'Rio Casca', name: 'Rio Casca', label: 'Rio Casca' },
    { value: 'Rio do Prado', name: 'Rio do Prado', label: 'Rio do Prado' },
    { value: 'Rio Doce', name: 'Rio Doce', label: 'Rio Doce' },
    { value: 'Rio Espera', name: 'Rio Espera', label: 'Rio Espera' },
    { value: 'Rio Manso', name: 'Rio Manso', label: 'Rio Manso' },
    { value: 'Rio Novo', name: 'Rio Novo', label: 'Rio Novo' },
    { value: 'Rio Paranaíba', name: 'Rio Paranaíba', label: 'Rio Paranaíba' },
    {
      value: 'Rio Pardo de Minas',
      name: 'Rio Pardo de Minas',
      label: 'Rio Pardo de Minas',
    },
    {
      value: 'Rio Piracicaba',
      name: 'Rio Piracicaba',
      label: 'Rio Piracicaba',
    },
    { value: 'Rio Pomba', name: 'Rio Pomba', label: 'Rio Pomba' },
    { value: 'Rio Preto', name: 'Rio Preto', label: 'Rio Preto' },
    { value: 'Rio Vermelho', name: 'Rio Vermelho', label: 'Rio Vermelho' },
    { value: 'Ritápolis', name: 'Ritápolis', label: 'Ritápolis' },
    {
      value: 'Rochedo de Minas',
      name: 'Rochedo de Minas',
      label: 'Rochedo de Minas',
    },
    { value: 'Rodeiro', name: 'Rodeiro', label: 'Rodeiro' },
    { value: 'Romaria', name: 'Romaria', label: 'Romaria' },
    {
      value: 'Rosário da Limeira',
      name: 'Rosário da Limeira',
      label: 'Rosário da Limeira',
    },
    { value: 'Rubelita', name: 'Rubelita', label: 'Rubelita' },
    { value: 'Rubim', name: 'Rubim', label: 'Rubim' },
    { value: 'Sabará', name: 'Sabará', label: 'Sabará' },
    { value: 'Sabinópolis', name: 'Sabinópolis', label: 'Sabinópolis' },
    { value: 'Sacramento', name: 'Sacramento', label: 'Sacramento' },
    { value: 'Salinas', name: 'Salinas', label: 'Salinas' },
    {
      value: 'Salto da Divisa',
      name: 'Salto da Divisa',
      label: 'Salto da Divisa',
    },
    { value: 'Santa Bárbara', name: 'Santa Bárbara', label: 'Santa Bárbara' },
    {
      value: 'Santa Bárbara do Leste',
      name: 'Santa Bárbara do Leste',
      label: 'Santa Bárbara do Leste',
    },
    {
      value: 'Santa Bárbara do Monte Verde',
      name: 'Santa Bárbara do Monte Verde',
      label: 'Santa Bárbara do Monte Verde',
    },
    {
      value: 'Santa Bárbara do Tugúrio',
      name: 'Santa Bárbara do Tugúrio',
      label: 'Santa Bárbara do Tugúrio',
    },
    {
      value: 'Santa Cruz de Minas',
      name: 'Santa Cruz de Minas',
      label: 'Santa Cruz de Minas',
    },
    {
      value: 'Santa Cruz de Salinas',
      name: 'Santa Cruz de Salinas',
      label: 'Santa Cruz de Salinas',
    },
    {
      value: 'Santa Cruz do Escalvado',
      name: 'Santa Cruz do Escalvado',
      label: 'Santa Cruz do Escalvado',
    },
    {
      value: 'Santa Efigênia de Minas',
      name: 'Santa Efigênia de Minas',
      label: 'Santa Efigênia de Minas',
    },
    {
      value: 'Santa Fé de Minas',
      name: 'Santa Fé de Minas',
      label: 'Santa Fé de Minas',
    },
    {
      value: 'Santa Helena de Minas',
      name: 'Santa Helena de Minas',
      label: 'Santa Helena de Minas',
    },
    { value: 'Santa Juliana', name: 'Santa Juliana', label: 'Santa Juliana' },
    { value: 'Santa Luzia', name: 'Santa Luzia', label: 'Santa Luzia' },
    {
      value: 'Santa Margarida',
      name: 'Santa Margarida',
      label: 'Santa Margarida',
    },
    {
      value: 'Santa Maria de Itabira',
      name: 'Santa Maria de Itabira',
      label: 'Santa Maria de Itabira',
    },
    {
      value: 'Santa Maria do Salto',
      name: 'Santa Maria do Salto',
      label: 'Santa Maria do Salto',
    },
    {
      value: 'Santa Maria do Suaçuí',
      name: 'Santa Maria do Suaçuí',
      label: 'Santa Maria do Suaçuí',
    },
    {
      value: 'Santa Rita de Caldas',
      name: 'Santa Rita de Caldas',
      label: 'Santa Rita de Caldas',
    },
    {
      value: 'Santa Rita de Ibitipoca',
      name: 'Santa Rita de Ibitipoca',
      label: 'Santa Rita de Ibitipoca',
    },
    {
      value: 'Santa Rita de Jacutinga',
      name: 'Santa Rita de Jacutinga',
      label: 'Santa Rita de Jacutinga',
    },
    {
      value: 'Santa Rita de Minas',
      name: 'Santa Rita de Minas',
      label: 'Santa Rita de Minas',
    },
    {
      value: 'Santa Rita do Itueto',
      name: 'Santa Rita do Itueto',
      label: 'Santa Rita do Itueto',
    },
    {
      value: 'Santa Rita do Sapucaí',
      name: 'Santa Rita do Sapucaí',
      label: 'Santa Rita do Sapucaí',
    },
    {
      value: 'Santa Rosa da Serra',
      name: 'Santa Rosa da Serra',
      label: 'Santa Rosa da Serra',
    },
    { value: 'Santa Vitória', name: 'Santa Vitória', label: 'Santa Vitória' },
    {
      value: 'Santana da Vargem',
      name: 'Santana da Vargem',
      label: 'Santana da Vargem',
    },
    {
      value: 'Santana de Cataguases',
      name: 'Santana de Cataguases',
      label: 'Santana de Cataguases',
    },
    {
      value: 'Santana de Pirapama',
      name: 'Santana de Pirapama',
      label: 'Santana de Pirapama',
    },
    {
      value: 'Santana do Deserto',
      name: 'Santana do Deserto',
      label: 'Santana do Deserto',
    },
    {
      value: 'Santana do Garambéu',
      name: 'Santana do Garambéu',
      label: 'Santana do Garambéu',
    },
    {
      value: 'Santana do Jacaré',
      name: 'Santana do Jacaré',
      label: 'Santana do Jacaré',
    },
    {
      value: 'Santana do Manhuaçu',
      name: 'Santana do Manhuaçu',
      label: 'Santana do Manhuaçu',
    },
    {
      value: 'Santana do Paraíso',
      name: 'Santana do Paraíso',
      label: 'Santana do Paraíso',
    },
    {
      value: 'Santana do Riacho',
      name: 'Santana do Riacho',
      label: 'Santana do Riacho',
    },
    {
      value: 'Santana dos Montes',
      name: 'Santana dos Montes',
      label: 'Santana dos Montes',
    },
    {
      value: 'Santo Antônio do Amparo',
      name: 'Santo Antônio do Amparo',
      label: 'Santo Antônio do Amparo',
    },
    {
      value: 'Santo Antônio do Aventureiro',
      name: 'Santo Antônio do Aventureiro',
      label: 'Santo Antônio do Aventureiro',
    },
    {
      value: 'Santo Antônio do Grama',
      name: 'Santo Antônio do Grama',
      label: 'Santo Antônio do Grama',
    },
    {
      value: 'Santo Antônio do Itambé',
      name: 'Santo Antônio do Itambé',
      label: 'Santo Antônio do Itambé',
    },
    {
      value: 'Santo Antônio do Jacinto',
      name: 'Santo Antônio do Jacinto',
      label: 'Santo Antônio do Jacinto',
    },
    {
      value: 'Santo Antônio do Monte',
      name: 'Santo Antônio do Monte',
      label: 'Santo Antônio do Monte',
    },
    {
      value: 'Santo Antônio do Retiro',
      name: 'Santo Antônio do Retiro',
      label: 'Santo Antônio do Retiro',
    },
    {
      value: 'Santo Antônio do Rio Abaixo',
      name: 'Santo Antônio do Rio Abaixo',
      label: 'Santo Antônio do Rio Abaixo',
    },
    {
      value: 'Santo Hipólito',
      name: 'Santo Hipólito',
      label: 'Santo Hipólito',
    },
    { value: 'Santos Dumont', name: 'Santos Dumont', label: 'Santos Dumont' },
    {
      value: 'São Bento Abade',
      name: 'São Bento Abade',
      label: 'São Bento Abade',
    },
    {
      value: 'São Brás do Suaçuí',
      name: 'São Brás do Suaçuí',
      label: 'São Brás do Suaçuí',
    },
    {
      value: 'São Domingos das Dores',
      name: 'São Domingos das Dores',
      label: 'São Domingos das Dores',
    },
    {
      value: 'São Domingos do Prata',
      name: 'São Domingos do Prata',
      label: 'São Domingos do Prata',
    },
    {
      value: 'São Félix de Minas',
      name: 'São Félix de Minas',
      label: 'São Félix de Minas',
    },
    { value: 'São Francisco', name: 'São Francisco', label: 'São Francisco' },
    {
      value: 'São Francisco de Paula',
      name: 'São Francisco de Paula',
      label: 'São Francisco de Paula',
    },
    {
      value: 'São Francisco de Sales',
      name: 'São Francisco de Sales',
      label: 'São Francisco de Sales',
    },
    {
      value: 'São Francisco do Glória',
      name: 'São Francisco do Glória',
      label: 'São Francisco do Glória',
    },
    { value: 'São Geraldo', name: 'São Geraldo', label: 'São Geraldo' },
    {
      value: 'São Geraldo da Piedade',
      name: 'São Geraldo da Piedade',
      label: 'São Geraldo da Piedade',
    },
    {
      value: 'São Geraldo do Baixio',
      name: 'São Geraldo do Baixio',
      label: 'São Geraldo do Baixio',
    },
    {
      value: 'São Gonçalo do Abaeté',
      name: 'São Gonçalo do Abaeté',
      label: 'São Gonçalo do Abaeté',
    },
    {
      value: 'São Gonçalo do Pará',
      name: 'São Gonçalo do Pará',
      label: 'São Gonçalo do Pará',
    },
    {
      value: 'São Gonçalo do Rio Abaixo',
      name: 'São Gonçalo do Rio Abaixo',
      label: 'São Gonçalo do Rio Abaixo',
    },
    {
      value: 'São Gonçalo do Rio Preto',
      name: 'São Gonçalo do Rio Preto',
      label: 'São Gonçalo do Rio Preto',
    },
    {
      value: 'São Gonçalo do Sapucaí',
      name: 'São Gonçalo do Sapucaí',
      label: 'São Gonçalo do Sapucaí',
    },
    { value: 'São Gotardo', name: 'São Gotardo', label: 'São Gotardo' },
    {
      value: 'São João Batista do Glória',
      name: 'São João Batista do Glória',
      label: 'São João Batista do Glória',
    },
    {
      value: 'São João da Lagoa',
      name: 'São João da Lagoa',
      label: 'São João da Lagoa',
    },
    {
      value: 'São João da Mata',
      name: 'São João da Mata',
      label: 'São João da Mata',
    },
    {
      value: 'São João da Ponte',
      name: 'São João da Ponte',
      label: 'São João da Ponte',
    },
    {
      value: 'São João das Missões',
      name: 'São João das Missões',
      label: 'São João das Missões',
    },
    {
      value: 'São João del Rei',
      name: 'São João del Rei',
      label: 'São João del Rei',
    },
    {
      value: 'São João do Manhuaçu',
      name: 'São João do Manhuaçu',
      label: 'São João do Manhuaçu',
    },
    {
      value: 'São João do Manteninha',
      name: 'São João do Manteninha',
      label: 'São João do Manteninha',
    },
    {
      value: 'São João do Oriente',
      name: 'São João do Oriente',
      label: 'São João do Oriente',
    },
    {
      value: 'São João do Pacuí',
      name: 'São João do Pacuí',
      label: 'São João do Pacuí',
    },
    {
      value: 'São João do Paraíso',
      name: 'São João do Paraíso',
      label: 'São João do Paraíso',
    },
    {
      value: 'São João Evangelista',
      name: 'São João Evangelista',
      label: 'São João Evangelista',
    },
    {
      value: 'São João Nepomuceno',
      name: 'São João Nepomuceno',
      label: 'São João Nepomuceno',
    },
    {
      value: 'São Joaquim de Bicas',
      name: 'São Joaquim de Bicas',
      label: 'São Joaquim de Bicas',
    },
    {
      value: 'São José da Barra',
      name: 'São José da Barra',
      label: 'São José da Barra',
    },
    {
      value: 'São José da Lapa',
      name: 'São José da Lapa',
      label: 'São José da Lapa',
    },
    {
      value: 'São José da Safira',
      name: 'São José da Safira',
      label: 'São José da Safira',
    },
    {
      value: 'São José da Varginha',
      name: 'São José da Varginha',
      label: 'São José da Varginha',
    },
    {
      value: 'São José do Alegre',
      name: 'São José do Alegre',
      label: 'São José do Alegre',
    },
    {
      value: 'São José do Divino',
      name: 'São José do Divino',
      label: 'São José do Divino',
    },
    {
      value: 'São José do Goiabal',
      name: 'São José do Goiabal',
      label: 'São José do Goiabal',
    },
    {
      value: 'São José do Jacuri',
      name: 'São José do Jacuri',
      label: 'São José do Jacuri',
    },
    {
      value: 'São José do Mantimento',
      name: 'São José do Mantimento',
      label: 'São José do Mantimento',
    },
    { value: 'São Lourenço', name: 'São Lourenço', label: 'São Lourenço' },
    {
      value: 'São Miguel do Anta',
      name: 'São Miguel do Anta',
      label: 'São Miguel do Anta',
    },
    {
      value: 'São Pedro da União',
      name: 'São Pedro da União',
      label: 'São Pedro da União',
    },
    {
      value: 'São Pedro do Suaçuí',
      name: 'São Pedro do Suaçuí',
      label: 'São Pedro do Suaçuí',
    },
    {
      value: 'São Pedro dos Ferros',
      name: 'São Pedro dos Ferros',
      label: 'São Pedro dos Ferros',
    },
    { value: 'São Romão', name: 'São Romão', label: 'São Romão' },
    {
      value: 'São Roque de Minas',
      name: 'São Roque de Minas',
      label: 'São Roque de Minas',
    },
    {
      value: 'São Sebastião da Bela Vista',
      name: 'São Sebastião da Bela Vista',
      label: 'São Sebastião da Bela Vista',
    },
    {
      value: 'São Sebastião da Vargem Alegre',
      name: 'São Sebastião da Vargem Alegre',
      label: 'São Sebastião da Vargem Alegre',
    },
    {
      value: 'São Sebastião do Anta',
      name: 'São Sebastião do Anta',
      label: 'São Sebastião do Anta',
    },
    {
      value: 'São Sebastião do Maranhão',
      name: 'São Sebastião do Maranhão',
      label: 'São Sebastião do Maranhão',
    },
    {
      value: 'São Sebastião do Oeste',
      name: 'São Sebastião do Oeste',
      label: 'São Sebastião do Oeste',
    },
    {
      value: 'São Sebastião do Paraíso',
      name: 'São Sebastião do Paraíso',
      label: 'São Sebastião do Paraíso',
    },
    {
      value: 'São Sebastião do Rio Preto',
      name: 'São Sebastião do Rio Preto',
      label: 'São Sebastião do Rio Preto',
    },
    {
      value: 'São Sebastião do Rio Verde',
      name: 'São Sebastião do Rio Verde',
      label: 'São Sebastião do Rio Verde',
    },
    {
      value: 'São Thomé das Letras',
      name: 'São Thomé das Letras',
      label: 'São Thomé das Letras',
    },
    { value: 'São Tiago', name: 'São Tiago', label: 'São Tiago' },
    {
      value: 'São Tomás de Aquino',
      name: 'São Tomás de Aquino',
      label: 'São Tomás de Aquino',
    },
    {
      value: 'São Vicente de Minas',
      name: 'São Vicente de Minas',
      label: 'São Vicente de Minas',
    },
    { value: 'Sapucaí-Mirim', name: 'Sapucaí-Mirim', label: 'Sapucaí-Mirim' },
    { value: 'Sardoá', name: 'Sardoá', label: 'Sardoá' },
    { value: 'Sarzedo', name: 'Sarzedo', label: 'Sarzedo' },
    { value: 'Sem Peixe', name: 'Sem Peixe', label: 'Sem Peixe' },
    {
      value: 'Senador Amaral',
      name: 'Senador Amaral',
      label: 'Senador Amaral',
    },
    {
      value: 'Senador Cortes',
      name: 'Senador Cortes',
      label: 'Senador Cortes',
    },
    {
      value: 'Senador Firmino',
      name: 'Senador Firmino',
      label: 'Senador Firmino',
    },
    {
      value: 'Senador José Bento',
      name: 'Senador José Bento',
      label: 'Senador José Bento',
    },
    {
      value: 'Senador Modestino Gonçalves',
      name: 'Senador Modestino Gonçalves',
      label: 'Senador Modestino Gonçalves',
    },
    {
      value: 'Senhora de Oliveira',
      name: 'Senhora de Oliveira',
      label: 'Senhora de Oliveira',
    },
    {
      value: 'Senhora do Porto',
      name: 'Senhora do Porto',
      label: 'Senhora do Porto',
    },
    {
      value: 'Senhora dos Remédios',
      name: 'Senhora dos Remédios',
      label: 'Senhora dos Remédios',
    },
    { value: 'Sericita', name: 'Sericita', label: 'Sericita' },
    { value: 'Seritinga', name: 'Seritinga', label: 'Seritinga' },
    {
      value: 'Serra Azul de Minas',
      name: 'Serra Azul de Minas',
      label: 'Serra Azul de Minas',
    },
    {
      value: 'Serra da Saudade',
      name: 'Serra da Saudade',
      label: 'Serra da Saudade',
    },
    {
      value: 'Serra do Salitre',
      name: 'Serra do Salitre',
      label: 'Serra do Salitre',
    },
    {
      value: 'Serra dos Aimorés',
      name: 'Serra dos Aimorés',
      label: 'Serra dos Aimorés',
    },
    { value: 'Serrania', name: 'Serrania', label: 'Serrania' },
    {
      value: 'Serranópolis de Minas',
      name: 'Serranópolis de Minas',
      label: 'Serranópolis de Minas',
    },
    { value: 'Serranos', name: 'Serranos', label: 'Serranos' },
    { value: 'Serro', name: 'Serro', label: 'Serro' },
    { value: 'Sete Lagoas', name: 'Sete Lagoas', label: 'Sete Lagoas' },
    { value: 'Setubinha', name: 'Setubinha', label: 'Setubinha' },
    { value: 'Silveirânia', name: 'Silveirânia', label: 'Silveirânia' },
    { value: 'Silvianópolis', name: 'Silvianópolis', label: 'Silvianópolis' },
    { value: 'Simão Pereira', name: 'Simão Pereira', label: 'Simão Pereira' },
    { value: 'Simonésia', name: 'Simonésia', label: 'Simonésia' },
    { value: 'Sobrália', name: 'Sobrália', label: 'Sobrália' },
    {
      value: 'Soledade de Minas',
      name: 'Soledade de Minas',
      label: 'Soledade de Minas',
    },
    { value: 'Tabuleiro', name: 'Tabuleiro', label: 'Tabuleiro' },
    { value: 'Taiobeiras', name: 'Taiobeiras', label: 'Taiobeiras' },
    { value: 'Taparuba', name: 'Taparuba', label: 'Taparuba' },
    { value: 'Tapira', name: 'Tapira', label: 'Tapira' },
    { value: 'Tapiraí', name: 'Tapiraí', label: 'Tapiraí' },
    {
      value: 'Taquaraçu de Minas',
      name: 'Taquaraçu de Minas',
      label: 'Taquaraçu de Minas',
    },
    { value: 'Tarumirim', name: 'Tarumirim', label: 'Tarumirim' },
    { value: 'Teixeiras', name: 'Teixeiras', label: 'Teixeiras' },
    { value: 'Teófilo Otoni', name: 'Teófilo Otoni', label: 'Teófilo Otoni' },
    { value: 'Timóteo', name: 'Timóteo', label: 'Timóteo' },
    { value: 'Tiradentes', name: 'Tiradentes', label: 'Tiradentes' },
    { value: 'Tiros', name: 'Tiros', label: 'Tiros' },
    { value: 'Tocantins', name: 'Tocantins', label: 'Tocantins' },
    { value: 'Tocos do Moji', name: 'Tocos do Moji', label: 'Tocos do Moji' },
    { value: 'Toledo', name: 'Toledo', label: 'Toledo' },
    { value: 'Tombos', name: 'Tombos', label: 'Tombos' },
    { value: 'Três Corações', name: 'Três Corações', label: 'Três Corações' },
    { value: 'Três Marias', name: 'Três Marias', label: 'Três Marias' },
    { value: 'Três Pontas', name: 'Três Pontas', label: 'Três Pontas' },
    { value: 'Tumiritinga', name: 'Tumiritinga', label: 'Tumiritinga' },
    { value: 'Tupaciguara', name: 'Tupaciguara', label: 'Tupaciguara' },
    { value: 'Turmalina', name: 'Turmalina', label: 'Turmalina' },
    { value: 'Turvolândia', name: 'Turvolândia', label: 'Turvolândia' },
    { value: 'Ubá', name: 'Ubá', label: 'Ubá' },
    { value: 'Ubaí', name: 'Ubaí', label: 'Ubaí' },
    { value: 'Ubaporanga', name: 'Ubaporanga', label: 'Ubaporanga' },
    { value: 'Uberaba', name: 'Uberaba', label: 'Uberaba' },
    { value: 'Uberlândia', name: 'Uberlândia', label: 'Uberlândia' },
    { value: 'Umburatiba', name: 'Umburatiba', label: 'Umburatiba' },
    { value: 'Unaí', name: 'Unaí', label: 'Unaí' },
    {
      value: 'União de Minas',
      name: 'União de Minas',
      label: 'União de Minas',
    },
    {
      value: 'Uruana de Minas',
      name: 'Uruana de Minas',
      label: 'Uruana de Minas',
    },
    { value: 'Urucânia', name: 'Urucânia', label: 'Urucânia' },
    { value: 'Urucuia', name: 'Urucuia', label: 'Urucuia' },
    { value: 'Vargem Alegre', name: 'Vargem Alegre', label: 'Vargem Alegre' },
    { value: 'Vargem Bonita', name: 'Vargem Bonita', label: 'Vargem Bonita' },
    {
      value: 'Vargem Grande do Rio Pardo',
      name: 'Vargem Grande do Rio Pardo',
      label: 'Vargem Grande do Rio Pardo',
    },
    { value: 'Varginha', name: 'Varginha', label: 'Varginha' },
    {
      value: 'Varjão de Minas',
      name: 'Varjão de Minas',
      label: 'Varjão de Minas',
    },
    {
      value: 'Várzea da Palma',
      name: 'Várzea da Palma',
      label: 'Várzea da Palma',
    },
    { value: 'Varzelândia', name: 'Varzelândia', label: 'Varzelândia' },
    { value: 'Vazante', name: 'Vazante', label: 'Vazante' },
    { value: 'Verdelândia', name: 'Verdelândia', label: 'Verdelândia' },
    { value: 'Veredinha', name: 'Veredinha', label: 'Veredinha' },
    { value: 'Veríssimo', name: 'Veríssimo', label: 'Veríssimo' },
    { value: 'Vermelho Novo', name: 'Vermelho Novo', label: 'Vermelho Novo' },
    { value: 'Vespasiano', name: 'Vespasiano', label: 'Vespasiano' },
    { value: 'Viçosa', name: 'Viçosa', label: 'Viçosa' },
    { value: 'Vieiras', name: 'Vieiras', label: 'Vieiras' },
    {
      value: 'Virgem da Lapa',
      name: 'Virgem da Lapa',
      label: 'Virgem da Lapa',
    },
    { value: 'Virgínia', name: 'Virgínia', label: 'Virgínia' },
    { value: 'Virginópolis', name: 'Virginópolis', label: 'Virginópolis' },
    { value: 'Virgolândia', name: 'Virgolândia', label: 'Virgolândia' },
    {
      value: 'Visconde do Rio Branco',
      name: 'Visconde do Rio Branco',
      label: 'Visconde do Rio Branco',
    },
    { value: 'Volta Grande', name: 'Volta Grande', label: 'Volta Grande' },
    {
      value: 'Wenceslau Braz',
      name: 'Wenceslau Braz',
      label: 'Wenceslau Braz',
    },
  ],
  MS: [
    { value: 'Água Clara', name: 'Água Clara', label: 'Água Clara' },
    { value: 'Alcinópolis', name: 'Alcinópolis', label: 'Alcinópolis' },
    { value: 'Amambaí', name: 'Amambaí', label: 'Amambaí' },
    { value: 'Anastácio', name: 'Anastácio', label: 'Anastácio' },
    { value: 'Anaurilândia', name: 'Anaurilândia', label: 'Anaurilândia' },
    { value: 'Angélica', name: 'Angélica', label: 'Angélica' },
    { value: 'Antônio João', name: 'Antônio João', label: 'Antônio João' },
    {
      value: 'Aparecida do Taboado',
      name: 'Aparecida do Taboado',
      label: 'Aparecida do Taboado',
    },
    { value: 'Aquidauana', name: 'Aquidauana', label: 'Aquidauana' },
    { value: 'Aral Moreira', name: 'Aral Moreira', label: 'Aral Moreira' },
    { value: 'Bandeirantes', name: 'Bandeirantes', label: 'Bandeirantes' },
    { value: 'Bataguassu', name: 'Bataguassu', label: 'Bataguassu' },
    { value: 'Bataiporã', name: 'Bataiporã', label: 'Bataiporã' },
    { value: 'Bela Vista', name: 'Bela Vista', label: 'Bela Vista' },
    { value: 'Bodoquena', name: 'Bodoquena', label: 'Bodoquena' },
    { value: 'Bonito', name: 'Bonito', label: 'Bonito' },
    { value: 'Brasilândia', name: 'Brasilândia', label: 'Brasilândia' },
    { value: 'Caarapó', name: 'Caarapó', label: 'Caarapó' },
    { value: 'Camapuã', name: 'Camapuã', label: 'Camapuã' },
    { value: 'Campo Grande', name: 'Campo Grande', label: 'Campo Grande' },
    { value: 'Caracol', name: 'Caracol', label: 'Caracol' },
    { value: 'Cassilândia', name: 'Cassilândia', label: 'Cassilândia' },
    {
      value: 'Chapadão do Sul',
      name: 'Chapadão do Sul',
      label: 'Chapadão do Sul',
    },
    { value: 'Corguinho', name: 'Corguinho', label: 'Corguinho' },
    {
      value: 'Coronel Sapucaia',
      name: 'Coronel Sapucaia',
      label: 'Coronel Sapucaia',
    },
    { value: 'Corumbá', name: 'Corumbá', label: 'Corumbá' },
    { value: 'Costa Rica', name: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Coxim', name: 'Coxim', label: 'Coxim' },
    { value: 'Deodápolis', name: 'Deodápolis', label: 'Deodápolis' },
    {
      value: 'Dois Irmãos do Buriti',
      name: 'Dois Irmãos do Buriti',
      label: 'Dois Irmãos do Buriti',
    },
    { value: 'Douradina', name: 'Douradina', label: 'Douradina' },
    { value: 'Dourados', name: 'Dourados', label: 'Dourados' },
    { value: 'Eldorado', name: 'Eldorado', label: 'Eldorado' },
    { value: 'Fátima do Sul', name: 'Fátima do Sul', label: 'Fátima do Sul' },
    {
      value: 'Glória de Dourados',
      name: 'Glória de Dourados',
      label: 'Glória de Dourados',
    },
    {
      value: 'Guia Lopes da Laguna',
      name: 'Guia Lopes da Laguna',
      label: 'Guia Lopes da Laguna',
    },
    { value: 'Iguatemi', name: 'Iguatemi', label: 'Iguatemi' },
    { value: 'Inocência', name: 'Inocência', label: 'Inocência' },
    { value: 'Itaporã', name: 'Itaporã', label: 'Itaporã' },
    { value: 'Itaquiraí', name: 'Itaquiraí', label: 'Itaquiraí' },
    { value: 'Ivinhema', name: 'Ivinhema', label: 'Ivinhema' },
    { value: 'Japorã', name: 'Japorã', label: 'Japorã' },
    { value: 'Jaraguari', name: 'Jaraguari', label: 'Jaraguari' },
    { value: 'Jardim', name: 'Jardim', label: 'Jardim' },
    { value: 'Jateí', name: 'Jateí', label: 'Jateí' },
    { value: 'Juti', name: 'Juti', label: 'Juti' },
    { value: 'Ladário', name: 'Ladário', label: 'Ladário' },
    { value: 'Laguna Carapã', name: 'Laguna Carapã', label: 'Laguna Carapã' },
    { value: 'Maracaju', name: 'Maracaju', label: 'Maracaju' },
    { value: 'Miranda', name: 'Miranda', label: 'Miranda' },
    { value: 'Mundo Novo', name: 'Mundo Novo', label: 'Mundo Novo' },
    { value: 'Naviraí', name: 'Naviraí', label: 'Naviraí' },
    { value: 'Nioaque', name: 'Nioaque', label: 'Nioaque' },
    {
      value: 'Nova Alvorada do Sul',
      name: 'Nova Alvorada do Sul',
      label: 'Nova Alvorada do Sul',
    },
    {
      value: 'Nova Andradina',
      name: 'Nova Andradina',
      label: 'Nova Andradina',
    },
    {
      value: 'Novo Horizonte do Sul',
      name: 'Novo Horizonte do Sul',
      label: 'Novo Horizonte do Sul',
    },
    { value: 'Paranaíba', name: 'Paranaíba', label: 'Paranaíba' },
    { value: 'Paranhos', name: 'Paranhos', label: 'Paranhos' },
    { value: 'Pedro Gomes', name: 'Pedro Gomes', label: 'Pedro Gomes' },
    { value: 'Ponta Porã', name: 'Ponta Porã', label: 'Ponta Porã' },
    {
      value: 'Porto Murtinho',
      name: 'Porto Murtinho',
      label: 'Porto Murtinho',
    },
    {
      value: 'Ribas do Rio Pardo',
      name: 'Ribas do Rio Pardo',
      label: 'Ribas do Rio Pardo',
    },
    { value: 'Rio Brilhante', name: 'Rio Brilhante', label: 'Rio Brilhante' },
    { value: 'Rio Negro', name: 'Rio Negro', label: 'Rio Negro' },
    {
      value: 'Rio Verde de Mato Grosso',
      name: 'Rio Verde de Mato Grosso',
      label: 'Rio Verde de Mato Grosso',
    },
    { value: 'Rochedo', name: 'Rochedo', label: 'Rochedo' },
    {
      value: 'Santa Rita do Pardo',
      name: 'Santa Rita do Pardo',
      label: 'Santa Rita do Pardo',
    },
    {
      value: 'São Gabriel do Oeste',
      name: 'São Gabriel do Oeste',
      label: 'São Gabriel do Oeste',
    },
    { value: 'Selvíria', name: 'Selvíria', label: 'Selvíria' },
    { value: 'Sete Quedas', name: 'Sete Quedas', label: 'Sete Quedas' },
    { value: 'Sidrolândia', name: 'Sidrolândia', label: 'Sidrolândia' },
    { value: 'Sonora', name: 'Sonora', label: 'Sonora' },
    { value: 'Tacuru', name: 'Tacuru', label: 'Tacuru' },
    { value: 'Taquarussu', name: 'Taquarussu', label: 'Taquarussu' },
    { value: 'Terenos', name: 'Terenos', label: 'Terenos' },
    { value: 'Três Lagoas', name: 'Três Lagoas', label: 'Três Lagoas' },
    { value: 'Vicentina', name: 'Vicentina', label: 'Vicentina' },
  ],
  MT: [
    { value: 'Acorizal', name: 'Acorizal', label: 'Acorizal' },
    { value: 'Água Boa', name: 'Água Boa', label: 'Água Boa' },
    { value: 'Alta Floresta', name: 'Alta Floresta', label: 'Alta Floresta' },
    { value: 'Alto Araguaia', name: 'Alto Araguaia', label: 'Alto Araguaia' },
    {
      value: 'Alto Boa Vista',
      name: 'Alto Boa Vista',
      label: 'Alto Boa Vista',
    },
    { value: 'Alto Garças', name: 'Alto Garças', label: 'Alto Garças' },
    { value: 'Alto Paraguai', name: 'Alto Paraguai', label: 'Alto Paraguai' },
    { value: 'Alto Taquari', name: 'Alto Taquari', label: 'Alto Taquari' },
    { value: 'Apiacás', name: 'Apiacás', label: 'Apiacás' },
    { value: 'Araguaiana', name: 'Araguaiana', label: 'Araguaiana' },
    { value: 'Araguainha', name: 'Araguainha', label: 'Araguainha' },
    { value: 'Araputanga', name: 'Araputanga', label: 'Araputanga' },
    { value: 'Arenápolis', name: 'Arenápolis', label: 'Arenápolis' },
    { value: 'Aripuanã', name: 'Aripuanã', label: 'Aripuanã' },
    {
      value: 'Barão de Melgaço',
      name: 'Barão de Melgaço',
      label: 'Barão de Melgaço',
    },
    {
      value: 'Barra do Bugres',
      name: 'Barra do Bugres',
      label: 'Barra do Bugres',
    },
    {
      value: 'Barra do Garças',
      name: 'Barra do Garças',
      label: 'Barra do Garças',
    },
    {
      value: 'Bom Jesus do Araguaia',
      name: 'Bom Jesus do Araguaia',
      label: 'Bom Jesus do Araguaia',
    },
    { value: 'Brasnorte', name: 'Brasnorte', label: 'Brasnorte' },
    { value: 'Cáceres', name: 'Cáceres', label: 'Cáceres' },
    { value: 'Campinápolis', name: 'Campinápolis', label: 'Campinápolis' },
    {
      value: 'Campo Novo do Parecis',
      name: 'Campo Novo do Parecis',
      label: 'Campo Novo do Parecis',
    },
    { value: 'Campo Verde', name: 'Campo Verde', label: 'Campo Verde' },
    {
      value: 'Campos de Júlio',
      name: 'Campos de Júlio',
      label: 'Campos de Júlio',
    },
    {
      value: 'Canabrava do Norte',
      name: 'Canabrava do Norte',
      label: 'Canabrava do Norte',
    },
    { value: 'Canarana', name: 'Canarana', label: 'Canarana' },
    { value: 'Carlinda', name: 'Carlinda', label: 'Carlinda' },
    { value: 'Castanheira', name: 'Castanheira', label: 'Castanheira' },
    {
      value: 'Chapada dos Guimarães',
      name: 'Chapada dos Guimarães',
      label: 'Chapada dos Guimarães',
    },
    { value: 'Cláudia', name: 'Cláudia', label: 'Cláudia' },
    { value: 'Cocalinho', name: 'Cocalinho', label: 'Cocalinho' },
    { value: 'Colíder', name: 'Colíder', label: 'Colíder' },
    { value: 'Colniza', name: 'Colniza', label: 'Colniza' },
    { value: 'Comodoro', name: 'Comodoro', label: 'Comodoro' },
    { value: 'Confresa', name: 'Confresa', label: 'Confresa' },
    {
      value: "Conquista D'Oeste",
      name: "Conquista D'Oeste",
      label: "Conquista D'Oeste",
    },
    { value: 'Cotriguaçu', name: 'Cotriguaçu', label: 'Cotriguaçu' },
    { value: 'Curvelândia', name: 'Curvelândia', label: 'Curvelândia' },
    { value: 'Cuiabá', name: 'Cuiabá', label: 'Cuiabá' },
    { value: 'Denise', name: 'Denise', label: 'Denise' },
    { value: 'Diamantino', name: 'Diamantino', label: 'Diamantino' },
    { value: 'Dom Aquino', name: 'Dom Aquino', label: 'Dom Aquino' },
    { value: 'Feliz Natal', name: 'Feliz Natal', label: 'Feliz Natal' },
    {
      value: "Figueirópolis D'Oeste",
      name: "Figueirópolis D'Oeste",
      label: "Figueirópolis D'Oeste",
    },
    {
      value: 'Gaúcha do Norte',
      name: 'Gaúcha do Norte',
      label: 'Gaúcha do Norte',
    },
    {
      value: 'General Carneiro',
      name: 'General Carneiro',
      label: 'General Carneiro',
    },
    {
      value: "Glória D'Oeste",
      name: "Glória D'Oeste",
      label: "Glória D'Oeste",
    },
    {
      value: 'Guarantã do Norte',
      name: 'Guarantã do Norte',
      label: 'Guarantã do Norte',
    },
    { value: 'Guiratinga', name: 'Guiratinga', label: 'Guiratinga' },
    { value: 'Indiavaí', name: 'Indiavaí', label: 'Indiavaí' },
    { value: 'Itaúba', name: 'Itaúba', label: 'Itaúba' },
    { value: 'Itiquira', name: 'Itiquira', label: 'Itiquira' },
    { value: 'Jaciara', name: 'Jaciara', label: 'Jaciara' },
    { value: 'Jangada', name: 'Jangada', label: 'Jangada' },
    { value: 'Jauru', name: 'Jauru', label: 'Jauru' },
    { value: 'Juara', name: 'Juara', label: 'Juara' },
    { value: 'Juína', name: 'Juína', label: 'Juína' },
    { value: 'Juruena', name: 'Juruena', label: 'Juruena' },
    { value: 'Juscimeira', name: 'Juscimeira', label: 'Juscimeira' },
    {
      value: "Lambari D'Oeste",
      name: "Lambari D'Oeste",
      label: "Lambari D'Oeste",
    },
    {
      value: 'Lucas do Rio Verde',
      name: 'Lucas do Rio Verde',
      label: 'Lucas do Rio Verde',
    },
    { value: 'Luciára', name: 'Luciára', label: 'Luciára' },
    { value: 'Marcelândia', name: 'Marcelândia', label: 'Marcelândia' },
    { value: 'Matupá', name: 'Matupá', label: 'Matupá' },
    {
      value: "Mirassol D'Oeste",
      name: "Mirassol D'Oeste",
      label: "Mirassol D'Oeste",
    },
    { value: 'Nobres', name: 'Nobres', label: 'Nobres' },
    { value: 'Nortelândia', name: 'Nortelândia', label: 'Nortelândia' },
    {
      value: 'Nossa Senhora do Livramento',
      name: 'Nossa Senhora do Livramento',
      label: 'Nossa Senhora do Livramento',
    },
    {
      value: 'Nova Bandeirantes',
      name: 'Nova Bandeirantes',
      label: 'Nova Bandeirantes',
    },
    {
      value: 'Nova Brasilândia',
      name: 'Nova Brasilândia',
      label: 'Nova Brasilândia',
    },
    {
      value: 'Nova Canãa do Norte',
      name: 'Nova Canãa do Norte',
      label: 'Nova Canãa do Norte',
    },
    { value: 'Nova Guarita', name: 'Nova Guarita', label: 'Nova Guarita' },
    { value: 'Nova Lacerda', name: 'Nova Lacerda', label: 'Nova Lacerda' },
    {
      value: 'Nova Marilândia',
      name: 'Nova Marilândia',
      label: 'Nova Marilândia',
    },
    { value: 'Nova Maringá', name: 'Nova Maringá', label: 'Nova Maringá' },
    {
      value: 'Nova Monte Verde',
      name: 'Nova Monte Verde',
      label: 'Nova Monte Verde',
    },
    { value: 'Nova Mutum', name: 'Nova Mutum', label: 'Nova Mutum' },
    { value: 'Nova Nazaré', name: 'Nova Nazaré', label: 'Nova Nazaré' },
    { value: 'Nova Olímpia', name: 'Nova Olímpia', label: 'Nova Olímpia' },
    {
      value: 'Nova Santa Helena',
      name: 'Nova Santa Helena',
      label: 'Nova Santa Helena',
    },
    { value: 'Nova Ubiratã', name: 'Nova Ubiratã', label: 'Nova Ubiratã' },
    {
      value: 'Nova Xavantina',
      name: 'Nova Xavantina',
      label: 'Nova Xavantina',
    },
    {
      value: 'Novo Horizonte do Norte',
      name: 'Novo Horizonte do Norte',
      label: 'Novo Horizonte do Norte',
    },
    { value: 'Novo Mundo', name: 'Novo Mundo', label: 'Novo Mundo' },
    {
      value: 'Novo Santo Antônio',
      name: 'Novo Santo Antônio',
      label: 'Novo Santo Antônio',
    },
    {
      value: 'Novo São Joaquim',
      name: 'Novo São Joaquim',
      label: 'Novo São Joaquim',
    },
    { value: 'Paranaíta', name: 'Paranaíta', label: 'Paranaíta' },
    { value: 'Paranatinga', name: 'Paranatinga', label: 'Paranatinga' },
    { value: 'Pedra Preta', name: 'Pedra Preta', label: 'Pedra Preta' },
    {
      value: 'Peixoto de Azevedo',
      name: 'Peixoto de Azevedo',
      label: 'Peixoto de Azevedo',
    },
    {
      value: 'Planalto da Serra',
      name: 'Planalto da Serra',
      label: 'Planalto da Serra',
    },
    { value: 'Poconé', name: 'Poconé', label: 'Poconé' },
    {
      value: 'Pontal do Araguaia',
      name: 'Pontal do Araguaia',
      label: 'Pontal do Araguaia',
    },
    { value: 'Ponte Branca', name: 'Ponte Branca', label: 'Ponte Branca' },
    {
      value: 'Pontes e Lacerda',
      name: 'Pontes e Lacerda',
      label: 'Pontes e Lacerda',
    },
    {
      value: 'Porto Alegre do Norte',
      name: 'Porto Alegre do Norte',
      label: 'Porto Alegre do Norte',
    },
    {
      value: 'Porto dos Gaúchos',
      name: 'Porto dos Gaúchos',
      label: 'Porto dos Gaúchos',
    },
    {
      value: 'Porto Esperidião',
      name: 'Porto Esperidião',
      label: 'Porto Esperidião',
    },
    { value: 'Porto Estrela', name: 'Porto Estrela', label: 'Porto Estrela' },
    { value: 'Poxoréo', name: 'Poxoréo', label: 'Poxoréo' },
    {
      value: 'Primavera do Leste',
      name: 'Primavera do Leste',
      label: 'Primavera do Leste',
    },
    { value: 'Querência', name: 'Querência', label: 'Querência' },
    {
      value: 'Reserva do Cabaçal',
      name: 'Reserva do Cabaçal',
      label: 'Reserva do Cabaçal',
    },
    {
      value: 'Ribeirão Cascalheira',
      name: 'Ribeirão Cascalheira',
      label: 'Ribeirão Cascalheira',
    },
    { value: 'Ribeirãozinho', name: 'Ribeirãozinho', label: 'Ribeirãozinho' },
    { value: 'Rio Branco', name: 'Rio Branco', label: 'Rio Branco' },
    { value: 'Rondolândia', name: 'Rondolândia', label: 'Rondolândia' },
    { value: 'Rondonópolis', name: 'Rondonópolis', label: 'Rondonópolis' },
    { value: 'Rosário Oeste', name: 'Rosário Oeste', label: 'Rosário Oeste' },
    { value: 'Salto do Céu', name: 'Salto do Céu', label: 'Salto do Céu' },
    { value: 'Santa Carmem', name: 'Santa Carmem', label: 'Santa Carmem' },
    {
      value: 'Santa Cruz do Xingu',
      name: 'Santa Cruz do Xingu',
      label: 'Santa Cruz do Xingu',
    },
    {
      value: 'Santa Rita do Trivelato',
      name: 'Santa Rita do Trivelato',
      label: 'Santa Rita do Trivelato',
    },
    {
      value: 'Santa Terezinha',
      name: 'Santa Terezinha',
      label: 'Santa Terezinha',
    },
    { value: 'Santo Afonso', name: 'Santo Afonso', label: 'Santo Afonso' },
    {
      value: 'Santo Antônio do Leste',
      name: 'Santo Antônio do Leste',
      label: 'Santo Antônio do Leste',
    },
    {
      value: 'Santo Antônio do Leverger',
      name: 'Santo Antônio do Leverger',
      label: 'Santo Antônio do Leverger',
    },
    {
      value: 'São Félix do Araguaia',
      name: 'São Félix do Araguaia',
      label: 'São Félix do Araguaia',
    },
    {
      value: 'São José do Povo',
      name: 'São José do Povo',
      label: 'São José do Povo',
    },
    {
      value: 'São José do Rio Claro',
      name: 'São José do Rio Claro',
      label: 'São José do Rio Claro',
    },
    {
      value: 'São José do Xingu',
      name: 'São José do Xingu',
      label: 'São José do Xingu',
    },
    {
      value: 'São José dos Quatro Marcos',
      name: 'São José dos Quatro Marcos',
      label: 'São José dos Quatro Marcos',
    },
    {
      value: 'São Pedro da Cipa',
      name: 'São Pedro da Cipa',
      label: 'São Pedro da Cipa',
    },
    { value: 'Sapezal', name: 'Sapezal', label: 'Sapezal' },
    {
      value: 'Serra Nova Dourada',
      name: 'Serra Nova Dourada',
      label: 'Serra Nova Dourada',
    },
    { value: 'Sinop', name: 'Sinop', label: 'Sinop' },
    { value: 'Sorriso', name: 'Sorriso', label: 'Sorriso' },
    { value: 'Tabaporã', name: 'Tabaporã', label: 'Tabaporã' },
    {
      value: 'Tangará da Serra',
      name: 'Tangará da Serra',
      label: 'Tangará da Serra',
    },
    { value: 'Tapurah', name: 'Tapurah', label: 'Tapurah' },
    {
      value: 'Terra Nova do Norte',
      name: 'Terra Nova do Norte',
      label: 'Terra Nova do Norte',
    },
    { value: 'Tesouro', name: 'Tesouro', label: 'Tesouro' },
    { value: 'Torixoréu', name: 'Torixoréu', label: 'Torixoréu' },
    { value: 'União do Sul', name: 'União do Sul', label: 'União do Sul' },
    {
      value: 'Vale de São Domingos',
      name: 'Vale de São Domingos',
      label: 'Vale de São Domingos',
    },
    { value: 'Várzea Grande', name: 'Várzea Grande', label: 'Várzea Grande' },
    { value: 'Vera', name: 'Vera', label: 'Vera' },
    {
      value: 'Vila Bela da Santíssima Trindade',
      name: 'Vila Bela da Santíssima Trindade',
      label: 'Vila Bela da Santíssima Trindade',
    },
    { value: 'Vila Rica', name: 'Vila Rica', label: 'Vila Rica' },
  ],
  PA: [
    { value: 'Abaetetuba', name: 'Abaetetuba', label: 'Abaetetuba' },
    {
      value: 'Abel Figueiredo',
      name: 'Abel Figueiredo',
      label: 'Abel Figueiredo',
    },
    { value: 'Acará', name: 'Acará', label: 'Acará' },
    { value: 'Afuá', name: 'Afuá', label: 'Afuá' },
    {
      value: 'Água Azul do Norte',
      name: 'Água Azul do Norte',
      label: 'Água Azul do Norte',
    },
    { value: 'Alenquer', name: 'Alenquer', label: 'Alenquer' },
    { value: 'Almeirim', name: 'Almeirim', label: 'Almeirim' },
    { value: 'Altamira', name: 'Altamira', label: 'Altamira' },
    { value: 'Anajás', name: 'Anajás', label: 'Anajás' },
    { value: 'Ananindeua', name: 'Ananindeua', label: 'Ananindeua' },
    { value: 'Anapu', name: 'Anapu', label: 'Anapu' },
    {
      value: 'Augusto Corrêa',
      name: 'Augusto Corrêa',
      label: 'Augusto Corrêa',
    },
    {
      value: 'Aurora do Pará',
      name: 'Aurora do Pará',
      label: 'Aurora do Pará',
    },
    { value: 'Aveiro', name: 'Aveiro', label: 'Aveiro' },
    { value: 'Bagre', name: 'Bagre', label: 'Bagre' },
    { value: 'Baião', name: 'Baião', label: 'Baião' },
    { value: 'Bannach', name: 'Bannach', label: 'Bannach' },
    { value: 'Barcarena', name: 'Barcarena', label: 'Barcarena' },
    { value: 'Belém', name: 'Belém', label: 'Belém' },
    { value: 'Belterra', name: 'Belterra', label: 'Belterra' },
    { value: 'Benevides', name: 'Benevides', label: 'Benevides' },
    {
      value: 'Bom Jesus do Tocantins',
      name: 'Bom Jesus do Tocantins',
      label: 'Bom Jesus do Tocantins',
    },
    { value: 'Bonito', name: 'Bonito', label: 'Bonito' },
    { value: 'Bragança', name: 'Bragança', label: 'Bragança' },
    { value: 'Brasil Novo', name: 'Brasil Novo', label: 'Brasil Novo' },
    {
      value: 'Brejo Grande do Araguaia',
      name: 'Brejo Grande do Araguaia',
      label: 'Brejo Grande do Araguaia',
    },
    { value: 'Breu Branco', name: 'Breu Branco', label: 'Breu Branco' },
    { value: 'Breves', name: 'Breves', label: 'Breves' },
    { value: 'Bujaru', name: 'Bujaru', label: 'Bujaru' },
    {
      value: 'Cachoeira do Arari',
      name: 'Cachoeira do Arari',
      label: 'Cachoeira do Arari',
    },
    {
      value: 'Cachoeira do Piriá',
      name: 'Cachoeira do Piriá',
      label: 'Cachoeira do Piriá',
    },
    { value: 'Cametá', name: 'Cametá', label: 'Cametá' },
    {
      value: 'Canaã dos Carajás',
      name: 'Canaã dos Carajás',
      label: 'Canaã dos Carajás',
    },
    { value: 'Capanema', name: 'Capanema', label: 'Capanema' },
    { value: 'Capitão Poço', name: 'Capitão Poço', label: 'Capitão Poço' },
    { value: 'Castanhal', name: 'Castanhal', label: 'Castanhal' },
    { value: 'Chaves', name: 'Chaves', label: 'Chaves' },
    { value: 'Colares', name: 'Colares', label: 'Colares' },
    {
      value: 'Conceição do Araguaia',
      name: 'Conceição do Araguaia',
      label: 'Conceição do Araguaia',
    },
    {
      value: 'Concórdia do Pará',
      name: 'Concórdia do Pará',
      label: 'Concórdia do Pará',
    },
    {
      value: 'Cumaru do Norte',
      name: 'Cumaru do Norte',
      label: 'Cumaru do Norte',
    },
    { value: 'Curionópolis', name: 'Curionópolis', label: 'Curionópolis' },
    { value: 'Curralinho', name: 'Curralinho', label: 'Curralinho' },
    { value: 'Curuá', name: 'Curuá', label: 'Curuá' },
    { value: 'Curuçá', name: 'Curuçá', label: 'Curuçá' },
    { value: 'Dom Eliseu', name: 'Dom Eliseu', label: 'Dom Eliseu' },
    {
      value: 'Eldorado dos Carajás',
      name: 'Eldorado dos Carajás',
      label: 'Eldorado dos Carajás',
    },
    { value: 'Faro', name: 'Faro', label: 'Faro' },
    {
      value: 'Floresta do Araguaia',
      name: 'Floresta do Araguaia',
      label: 'Floresta do Araguaia',
    },
    {
      value: 'Garrafão do Norte',
      name: 'Garrafão do Norte',
      label: 'Garrafão do Norte',
    },
    {
      value: 'Goianésia do Pará',
      name: 'Goianésia do Pará',
      label: 'Goianésia do Pará',
    },
    { value: 'Gurupá', name: 'Gurupá', label: 'Gurupá' },
    { value: 'Igarapé-Açu', name: 'Igarapé-Açu', label: 'Igarapé-Açu' },
    { value: 'Igarapé-Miri', name: 'Igarapé-Miri', label: 'Igarapé-Miri' },
    { value: 'Inhangapi', name: 'Inhangapi', label: 'Inhangapi' },
    {
      value: 'Ipixuna do Pará',
      name: 'Ipixuna do Pará',
      label: 'Ipixuna do Pará',
    },
    { value: 'Irituia', name: 'Irituia', label: 'Irituia' },
    { value: 'Itaituba', name: 'Itaituba', label: 'Itaituba' },
    { value: 'Itupiranga', name: 'Itupiranga', label: 'Itupiranga' },
    { value: 'Jacareacanga', name: 'Jacareacanga', label: 'Jacareacanga' },
    { value: 'Jacundá', name: 'Jacundá', label: 'Jacundá' },
    { value: 'Juruti', name: 'Juruti', label: 'Juruti' },
    {
      value: 'Limoeiro do Ajuru',
      name: 'Limoeiro do Ajuru',
      label: 'Limoeiro do Ajuru',
    },
    { value: 'Mãe do Rio', name: 'Mãe do Rio', label: 'Mãe do Rio' },
    {
      value: 'Magalhães Barata',
      name: 'Magalhães Barata',
      label: 'Magalhães Barata',
    },
    { value: 'Marabá', name: 'Marabá', label: 'Marabá' },
    { value: 'Maracanã', name: 'Maracanã', label: 'Maracanã' },
    { value: 'Marapanim', name: 'Marapanim', label: 'Marapanim' },
    { value: 'Marituba', name: 'Marituba', label: 'Marituba' },
    { value: 'Medicilândia', name: 'Medicilândia', label: 'Medicilândia' },
    { value: 'Melgaço', name: 'Melgaço', label: 'Melgaço' },
    { value: 'Mocajuba', name: 'Mocajuba', label: 'Mocajuba' },
    { value: 'Moju', name: 'Moju', label: 'Moju' },
    { value: 'Monte Alegre', name: 'Monte Alegre', label: 'Monte Alegre' },
    { value: 'Muaná', name: 'Muaná', label: 'Muaná' },
    {
      value: 'Nova Esperança do Piriá',
      name: 'Nova Esperança do Piriá',
      label: 'Nova Esperança do Piriá',
    },
    { value: 'Nova Ipixuna', name: 'Nova Ipixuna', label: 'Nova Ipixuna' },
    {
      value: 'Nova Timboteua',
      name: 'Nova Timboteua',
      label: 'Nova Timboteua',
    },
    {
      value: 'Novo Progresso',
      name: 'Novo Progresso',
      label: 'Novo Progresso',
    },
    {
      value: 'Novo Repartimento',
      name: 'Novo Repartimento',
      label: 'Novo Repartimento',
    },
    { value: 'Óbidos', name: 'Óbidos', label: 'Óbidos' },
    {
      value: 'Oeiras do Pará',
      name: 'Oeiras do Pará',
      label: 'Oeiras do Pará',
    },
    { value: 'Oriximiná', name: 'Oriximiná', label: 'Oriximiná' },
    { value: 'Ourém', name: 'Ourém', label: 'Ourém' },
    {
      value: 'Ourilândia do Norte',
      name: 'Ourilândia do Norte',
      label: 'Ourilândia do Norte',
    },
    { value: 'Pacajá', name: 'Pacajá', label: 'Pacajá' },
    {
      value: 'Palestina do Pará',
      name: 'Palestina do Pará',
      label: 'Palestina do Pará',
    },
    { value: 'Paragominas', name: 'Paragominas', label: 'Paragominas' },
    { value: 'Parauapebas', name: 'Parauapebas', label: 'Parauapebas' },
    { value: "Pau D'Arco", name: "Pau D'Arco", label: "Pau D'Arco" },
    { value: 'Peixe-Boi', name: 'Peixe-Boi', label: 'Peixe-Boi' },
    { value: 'Piçarra', name: 'Piçarra', label: 'Piçarra' },
    { value: 'Placas', name: 'Placas', label: 'Placas' },
    {
      value: 'Ponta de Pedras',
      name: 'Ponta de Pedras',
      label: 'Ponta de Pedras',
    },
    { value: 'Portel', name: 'Portel', label: 'Portel' },
    { value: 'Porto de Moz', name: 'Porto de Moz', label: 'Porto de Moz' },
    { value: 'Prainha', name: 'Prainha', label: 'Prainha' },
    { value: 'Primavera', name: 'Primavera', label: 'Primavera' },
    { value: 'Quatipuru', name: 'Quatipuru', label: 'Quatipuru' },
    { value: 'Redenção', name: 'Redenção', label: 'Redenção' },
    { value: 'Rio Maria', name: 'Rio Maria', label: 'Rio Maria' },
    {
      value: 'Rondon do Pará',
      name: 'Rondon do Pará',
      label: 'Rondon do Pará',
    },
    { value: 'Rurópolis', name: 'Rurópolis', label: 'Rurópolis' },
    { value: 'Salinópolis', name: 'Salinópolis', label: 'Salinópolis' },
    { value: 'Salvaterra', name: 'Salvaterra', label: 'Salvaterra' },
    {
      value: 'Santa Bárbara do Pará',
      name: 'Santa Bárbara do Pará',
      label: 'Santa Bárbara do Pará',
    },
    {
      value: 'Santa Cruz do Arari',
      name: 'Santa Cruz do Arari',
      label: 'Santa Cruz do Arari',
    },
    {
      value: 'Santa Isabel do Pará',
      name: 'Santa Isabel do Pará',
      label: 'Santa Isabel do Pará',
    },
    {
      value: 'Santa Luzia do Pará',
      name: 'Santa Luzia do Pará',
      label: 'Santa Luzia do Pará',
    },
    {
      value: 'Santa Maria das Barreiras',
      name: 'Santa Maria das Barreiras',
      label: 'Santa Maria das Barreiras',
    },
    {
      value: 'Santa Maria do Pará',
      name: 'Santa Maria do Pará',
      label: 'Santa Maria do Pará',
    },
    {
      value: 'Santana do Araguaia',
      name: 'Santana do Araguaia',
      label: 'Santana do Araguaia',
    },
    { value: 'Santarém', name: 'Santarém', label: 'Santarém' },
    { value: 'Santarém Novo', name: 'Santarém Novo', label: 'Santarém Novo' },
    {
      value: 'Santo Antônio do Tauá',
      name: 'Santo Antônio do Tauá',
      label: 'Santo Antônio do Tauá',
    },
    {
      value: 'São Caetano de Odivela',
      name: 'São Caetano de Odivela',
      label: 'São Caetano de Odivela',
    },
    {
      value: 'São Domingos do Araguaia',
      name: 'São Domingos do Araguaia',
      label: 'São Domingos do Araguaia',
    },
    {
      value: 'São Domingos do Capim',
      name: 'São Domingos do Capim',
      label: 'São Domingos do Capim',
    },
    {
      value: 'São Félix do Xingu',
      name: 'São Félix do Xingu',
      label: 'São Félix do Xingu',
    },
    {
      value: 'São Francisco do Pará',
      name: 'São Francisco do Pará',
      label: 'São Francisco do Pará',
    },
    {
      value: 'São Geraldo do Araguaia',
      name: 'São Geraldo do Araguaia',
      label: 'São Geraldo do Araguaia',
    },
    {
      value: 'São João da Ponta',
      name: 'São João da Ponta',
      label: 'São João da Ponta',
    },
    {
      value: 'São João de Pirabas',
      name: 'São João de Pirabas',
      label: 'São João de Pirabas',
    },
    {
      value: 'São João do Araguaia',
      name: 'São João do Araguaia',
      label: 'São João do Araguaia',
    },
    {
      value: 'São Miguel do Guamá',
      name: 'São Miguel do Guamá',
      label: 'São Miguel do Guamá',
    },
    {
      value: 'São Sebastião da Boa Vista',
      name: 'São Sebastião da Boa Vista',
      label: 'São Sebastião da Boa Vista',
    },
    { value: 'Sapucaia', name: 'Sapucaia', label: 'Sapucaia' },
    {
      value: 'Senador José Porfírio',
      name: 'Senador José Porfírio',
      label: 'Senador José Porfírio',
    },
    { value: 'Soure', name: 'Soure', label: 'Soure' },
    { value: 'Tailândia', name: 'Tailândia', label: 'Tailândia' },
    { value: 'Terra Alta', name: 'Terra Alta', label: 'Terra Alta' },
    { value: 'Terra Santa', name: 'Terra Santa', label: 'Terra Santa' },
    { value: 'Tomé-Açu', name: 'Tomé-Açu', label: 'Tomé-Açu' },
    { value: 'Tracuateua', name: 'Tracuateua', label: 'Tracuateua' },
    { value: 'Trairão', name: 'Trairão', label: 'Trairão' },
    { value: 'Tucumã', name: 'Tucumã', label: 'Tucumã' },
    { value: 'Tucuruí', name: 'Tucuruí', label: 'Tucuruí' },
    { value: 'Ulianópolis', name: 'Ulianópolis', label: 'Ulianópolis' },
    { value: 'Uruará', name: 'Uruará', label: 'Uruará' },
    { value: 'Vigia', name: 'Vigia', label: 'Vigia' },
    { value: 'Viseu', name: 'Viseu', label: 'Viseu' },
    {
      value: 'Vitória do Xingu',
      name: 'Vitória do Xingu',
      label: 'Vitória do Xingu',
    },
    { value: 'Xinguara', name: 'Xinguara', label: 'Xinguara' },
  ],
  PB: [
    { value: 'Água Branca', name: 'Água Branca', label: 'Água Branca' },
    { value: 'Aguiar', name: 'Aguiar', label: 'Aguiar' },
    { value: 'Alagoa Grande', name: 'Alagoa Grande', label: 'Alagoa Grande' },
    { value: 'Alagoa Nova', name: 'Alagoa Nova', label: 'Alagoa Nova' },
    { value: 'Alagoinha', name: 'Alagoinha', label: 'Alagoinha' },
    { value: 'Alcantil', name: 'Alcantil', label: 'Alcantil' },
    {
      value: 'Algodão de Jandaíra',
      name: 'Algodão de Jandaíra',
      label: 'Algodão de Jandaíra',
    },
    { value: 'Alhandra', name: 'Alhandra', label: 'Alhandra' },
    { value: 'Amparo', name: 'Amparo', label: 'Amparo' },
    { value: 'Aparecida', name: 'Aparecida', label: 'Aparecida' },
    { value: 'Araçagi', name: 'Araçagi', label: 'Araçagi' },
    { value: 'Arara', name: 'Arara', label: 'Arara' },
    { value: 'Araruna', name: 'Araruna', label: 'Araruna' },
    { value: 'Areia', name: 'Areia', label: 'Areia' },
    {
      value: 'Areia de Baraúnas',
      name: 'Areia de Baraúnas',
      label: 'Areia de Baraúnas',
    },
    { value: 'Areial', name: 'Areial', label: 'Areial' },
    { value: 'Aroeiras', name: 'Aroeiras', label: 'Aroeiras' },
    { value: 'Assunção', name: 'Assunção', label: 'Assunção' },
    {
      value: 'Baía da Traição',
      name: 'Baía da Traição',
      label: 'Baía da Traição',
    },
    { value: 'Bananeiras', name: 'Bananeiras', label: 'Bananeiras' },
    { value: 'Baraúna', name: 'Baraúna', label: 'Baraúna' },
    {
      value: 'Barra de Santa Rosa',
      name: 'Barra de Santa Rosa',
      label: 'Barra de Santa Rosa',
    },
    {
      value: 'Barra de Santana',
      name: 'Barra de Santana',
      label: 'Barra de Santana',
    },
    {
      value: 'Barra de São Miguel',
      name: 'Barra de São Miguel',
      label: 'Barra de São Miguel',
    },
    { value: 'Bayeux', name: 'Bayeux', label: 'Bayeux' },
    { value: 'Belém', name: 'Belém', label: 'Belém' },
    {
      value: 'Belém do Brejo do Cruz',
      name: 'Belém do Brejo do Cruz',
      label: 'Belém do Brejo do Cruz',
    },
    {
      value: 'Bernardino Batista',
      name: 'Bernardino Batista',
      label: 'Bernardino Batista',
    },
    { value: 'Boa Ventura', name: 'Boa Ventura', label: 'Boa Ventura' },
    { value: 'Boa Vista', name: 'Boa Vista', label: 'Boa Vista' },
    { value: 'Bom Jesus', name: 'Bom Jesus', label: 'Bom Jesus' },
    { value: 'Bom Sucesso', name: 'Bom Sucesso', label: 'Bom Sucesso' },
    {
      value: 'Bonito de Santa Fé',
      name: 'Bonito de Santa Fé',
      label: 'Bonito de Santa Fé',
    },
    { value: 'Boqueirão', name: 'Boqueirão', label: 'Boqueirão' },
    { value: 'Borborema', name: 'Borborema', label: 'Borborema' },
    { value: 'Brejo do Cruz', name: 'Brejo do Cruz', label: 'Brejo do Cruz' },
    {
      value: 'Brejo dos Santos',
      name: 'Brejo dos Santos',
      label: 'Brejo dos Santos',
    },
    { value: 'Caaporã', name: 'Caaporã', label: 'Caaporã' },
    { value: 'Cabaceiras', name: 'Cabaceiras', label: 'Cabaceiras' },
    { value: 'Cabedelo', name: 'Cabedelo', label: 'Cabedelo' },
    {
      value: 'Cachoeira dos Índios',
      name: 'Cachoeira dos Índios',
      label: 'Cachoeira dos Índios',
    },
    {
      value: 'Cacimba de Areia',
      name: 'Cacimba de Areia',
      label: 'Cacimba de Areia',
    },
    {
      value: 'Cacimba de Dentro',
      name: 'Cacimba de Dentro',
      label: 'Cacimba de Dentro',
    },
    { value: 'Cacimbas', name: 'Cacimbas', label: 'Cacimbas' },
    { value: 'Caiçara', name: 'Caiçara', label: 'Caiçara' },
    { value: 'Cajazeiras', name: 'Cajazeiras', label: 'Cajazeiras' },
    { value: 'Cajazeirinhas', name: 'Cajazeirinhas', label: 'Cajazeirinhas' },
    {
      value: 'Caldas Brandão',
      name: 'Caldas Brandão',
      label: 'Caldas Brandão',
    },
    { value: 'Camalaú', name: 'Camalaú', label: 'Camalaú' },
    {
      value: 'Campina Grande',
      name: 'Campina Grande',
      label: 'Campina Grande',
    },
    {
      value: 'Campo de Santana',
      name: 'Campo de Santana',
      label: 'Campo de Santana',
    },
    { value: 'Capim', name: 'Capim', label: 'Capim' },
    { value: 'Caraúbas', name: 'Caraúbas', label: 'Caraúbas' },
    { value: 'Carrapateira', name: 'Carrapateira', label: 'Carrapateira' },
    { value: 'Casserengue', name: 'Casserengue', label: 'Casserengue' },
    { value: 'Catingueira', name: 'Catingueira', label: 'Catingueira' },
    {
      value: 'Catolé do Rocha',
      name: 'Catolé do Rocha',
      label: 'Catolé do Rocha',
    },
    { value: 'Caturité', name: 'Caturité', label: 'Caturité' },
    { value: 'Conceição', name: 'Conceição', label: 'Conceição' },
    { value: 'Condado', name: 'Condado', label: 'Condado' },
    { value: 'Conde', name: 'Conde', label: 'Conde' },
    { value: 'Congo', name: 'Congo', label: 'Congo' },
    { value: 'Coremas', name: 'Coremas', label: 'Coremas' },
    { value: 'Coxixola', name: 'Coxixola', label: 'Coxixola' },
    {
      value: 'Cruz do Espírito Santo',
      name: 'Cruz do Espírito Santo',
      label: 'Cruz do Espírito Santo',
    },
    { value: 'Cubati', name: 'Cubati', label: 'Cubati' },
    { value: 'Cuité', name: 'Cuité', label: 'Cuité' },
    {
      value: 'Cuité de Mamanguape',
      name: 'Cuité de Mamanguape',
      label: 'Cuité de Mamanguape',
    },
    { value: 'Cuitegi', name: 'Cuitegi', label: 'Cuitegi' },
    {
      value: 'Curral de Cima',
      name: 'Curral de Cima',
      label: 'Curral de Cima',
    },
    { value: 'Curral Velho', name: 'Curral Velho', label: 'Curral Velho' },
    { value: 'Damião', name: 'Damião', label: 'Damião' },
    { value: 'Desterro', name: 'Desterro', label: 'Desterro' },
    { value: 'Diamante', name: 'Diamante', label: 'Diamante' },
    { value: 'Dona Inês', name: 'Dona Inês', label: 'Dona Inês' },
    { value: 'Duas Estradas', name: 'Duas Estradas', label: 'Duas Estradas' },
    { value: 'Emas', name: 'Emas', label: 'Emas' },
    { value: 'Esperança', name: 'Esperança', label: 'Esperança' },
    { value: 'Fagundes', name: 'Fagundes', label: 'Fagundes' },
    { value: 'Frei Martinho', name: 'Frei Martinho', label: 'Frei Martinho' },
    { value: 'Gado Bravo', name: 'Gado Bravo', label: 'Gado Bravo' },
    { value: 'Guarabira', name: 'Guarabira', label: 'Guarabira' },
    { value: 'Gurinhém', name: 'Gurinhém', label: 'Gurinhém' },
    { value: 'Gurjão', name: 'Gurjão', label: 'Gurjão' },
    { value: 'Ibiara', name: 'Ibiara', label: 'Ibiara' },
    { value: 'Igaracy', name: 'Igaracy', label: 'Igaracy' },
    { value: 'Imaculada', name: 'Imaculada', label: 'Imaculada' },
    { value: 'Ingá', name: 'Ingá', label: 'Ingá' },
    { value: 'Itabaiana', name: 'Itabaiana', label: 'Itabaiana' },
    { value: 'Itaporanga', name: 'Itaporanga', label: 'Itaporanga' },
    { value: 'Itapororoca', name: 'Itapororoca', label: 'Itapororoca' },
    { value: 'Itatuba', name: 'Itatuba', label: 'Itatuba' },
    { value: 'Jacaraú', name: 'Jacaraú', label: 'Jacaraú' },
    { value: 'Jericó', name: 'Jericó', label: 'Jericó' },
    { value: 'João Pessoa', name: 'João Pessoa', label: 'João Pessoa' },
    { value: 'Juarez Távora', name: 'Juarez Távora', label: 'Juarez Távora' },
    { value: 'Juazeirinho', name: 'Juazeirinho', label: 'Juazeirinho' },
    {
      value: 'Junco do Seridó',
      name: 'Junco do Seridó',
      label: 'Junco do Seridó',
    },
    { value: 'Juripiranga', name: 'Juripiranga', label: 'Juripiranga' },
    { value: 'Juru', name: 'Juru', label: 'Juru' },
    { value: 'Lagoa', name: 'Lagoa', label: 'Lagoa' },
    {
      value: 'Lagoa de Dentro',
      name: 'Lagoa de Dentro',
      label: 'Lagoa de Dentro',
    },
    { value: 'Lagoa Seca', name: 'Lagoa Seca', label: 'Lagoa Seca' },
    { value: 'Lastro', name: 'Lastro', label: 'Lastro' },
    { value: 'Livramento', name: 'Livramento', label: 'Livramento' },
    { value: 'Logradouro', name: 'Logradouro', label: 'Logradouro' },
    { value: 'Lucena', name: 'Lucena', label: 'Lucena' },
    { value: "Mãe D'Água", name: "Mãe D'Água", label: "Mãe D'Água" },
    { value: 'Malta', name: 'Malta', label: 'Malta' },
    { value: 'Mamanguape', name: 'Mamanguape', label: 'Mamanguape' },
    { value: 'Manaíra', name: 'Manaíra', label: 'Manaíra' },
    { value: 'Marcação', name: 'Marcação', label: 'Marcação' },
    { value: 'Mari', name: 'Mari', label: 'Mari' },
    { value: 'Marizópolis', name: 'Marizópolis', label: 'Marizópolis' },
    { value: 'Massaranduba', name: 'Massaranduba', label: 'Massaranduba' },
    { value: 'Mataraca', name: 'Mataraca', label: 'Mataraca' },
    { value: 'Matinhas', name: 'Matinhas', label: 'Matinhas' },
    { value: 'Mato Grosso', name: 'Mato Grosso', label: 'Mato Grosso' },
    { value: 'Maturéia', name: 'Maturéia', label: 'Maturéia' },
    { value: 'Mogeiro', name: 'Mogeiro', label: 'Mogeiro' },
    { value: 'Montadas', name: 'Montadas', label: 'Montadas' },
    { value: 'Monte Horebe', name: 'Monte Horebe', label: 'Monte Horebe' },
    { value: 'Monteiro', name: 'Monteiro', label: 'Monteiro' },
    { value: 'Mulungu', name: 'Mulungu', label: 'Mulungu' },
    { value: 'Natuba', name: 'Natuba', label: 'Natuba' },
    { value: 'Nazarezinho', name: 'Nazarezinho', label: 'Nazarezinho' },
    { value: 'Nova Floresta', name: 'Nova Floresta', label: 'Nova Floresta' },
    { value: 'Nova Olinda', name: 'Nova Olinda', label: 'Nova Olinda' },
    { value: 'Nova Palmeira', name: 'Nova Palmeira', label: 'Nova Palmeira' },
    { value: "Olho D'Água", name: "Olho D'Água", label: "Olho D'Água" },
    { value: 'Olivedos', name: 'Olivedos', label: 'Olivedos' },
    { value: 'Ouro Velho', name: 'Ouro Velho', label: 'Ouro Velho' },
    { value: 'Parari', name: 'Parari', label: 'Parari' },
    { value: 'Passagem', name: 'Passagem', label: 'Passagem' },
    { value: 'Patos', name: 'Patos', label: 'Patos' },
    { value: 'Paulista', name: 'Paulista', label: 'Paulista' },
    { value: 'Pedra Branca', name: 'Pedra Branca', label: 'Pedra Branca' },
    { value: 'Pedra Lavrada', name: 'Pedra Lavrada', label: 'Pedra Lavrada' },
    {
      value: 'Pedras de Fogo',
      name: 'Pedras de Fogo',
      label: 'Pedras de Fogo',
    },
    { value: 'Pedro Régis', name: 'Pedro Régis', label: 'Pedro Régis' },
    { value: 'Piancó', name: 'Piancó', label: 'Piancó' },
    { value: 'Picuí', name: 'Picuí', label: 'Picuí' },
    { value: 'Pilar', name: 'Pilar', label: 'Pilar' },
    { value: 'Pilões', name: 'Pilões', label: 'Pilões' },
    { value: 'Pilõezinhos', name: 'Pilõezinhos', label: 'Pilõezinhos' },
    { value: 'Pirpirituba', name: 'Pirpirituba', label: 'Pirpirituba' },
    { value: 'Pitimbu', name: 'Pitimbu', label: 'Pitimbu' },
    { value: 'Pocinhos', name: 'Pocinhos', label: 'Pocinhos' },
    { value: 'Poço Dantas', name: 'Poço Dantas', label: 'Poço Dantas' },
    {
      value: 'Poço de José de Moura',
      name: 'Poço de José de Moura',
      label: 'Poço de José de Moura',
    },
    { value: 'Pombal', name: 'Pombal', label: 'Pombal' },
    { value: 'Prata', name: 'Prata', label: 'Prata' },
    {
      value: 'Princesa Isabel',
      name: 'Princesa Isabel',
      label: 'Princesa Isabel',
    },
    { value: 'Puxinanã', name: 'Puxinanã', label: 'Puxinanã' },
    { value: 'Queimadas', name: 'Queimadas', label: 'Queimadas' },
    { value: 'Quixabá', name: 'Quixabá', label: 'Quixabá' },
    { value: 'Remígio', name: 'Remígio', label: 'Remígio' },
    { value: 'Riachão', name: 'Riachão', label: 'Riachão' },
    {
      value: 'Riachão do Bacamarte',
      name: 'Riachão do Bacamarte',
      label: 'Riachão do Bacamarte',
    },
    {
      value: 'Riachão do Poço',
      name: 'Riachão do Poço',
      label: 'Riachão do Poço',
    },
    {
      value: 'Riacho de Santo Antônio',
      name: 'Riacho de Santo Antônio',
      label: 'Riacho de Santo Antônio',
    },
    {
      value: 'Riacho dos Cavalos',
      name: 'Riacho dos Cavalos',
      label: 'Riacho dos Cavalos',
    },
    { value: 'Rio Tinto', name: 'Rio Tinto', label: 'Rio Tinto' },
    { value: 'Salgadinho', name: 'Salgadinho', label: 'Salgadinho' },
    {
      value: 'Salgado de São Félix',
      name: 'Salgado de São Félix',
      label: 'Salgado de São Félix',
    },
    { value: 'Santa Cecília', name: 'Santa Cecília', label: 'Santa Cecília' },
    { value: 'Santa Cruz', name: 'Santa Cruz', label: 'Santa Cruz' },
    { value: 'Santa Helena', name: 'Santa Helena', label: 'Santa Helena' },
    { value: 'Santa Inês', name: 'Santa Inês', label: 'Santa Inês' },
    { value: 'Santa Luzia', name: 'Santa Luzia', label: 'Santa Luzia' },
    { value: 'Santa Rita', name: 'Santa Rita', label: 'Santa Rita' },
    {
      value: 'Santa Teresinha',
      name: 'Santa Teresinha',
      label: 'Santa Teresinha',
    },
    {
      value: 'Santana de Mangueira',
      name: 'Santana de Mangueira',
      label: 'Santana de Mangueira',
    },
    {
      value: 'Santana dos Garrotes',
      name: 'Santana dos Garrotes',
      label: 'Santana dos Garrotes',
    },
    { value: 'Santarém', name: 'Santarém', label: 'Santarém' },
    { value: 'Santo André', name: 'Santo André', label: 'Santo André' },
    { value: 'São Bentinho', name: 'São Bentinho', label: 'São Bentinho' },
    { value: 'São Bento', name: 'São Bento', label: 'São Bento' },
    {
      value: 'São Domingos de Pombal',
      name: 'São Domingos de Pombal',
      label: 'São Domingos de Pombal',
    },
    {
      value: 'São Domingos do Cariri',
      name: 'São Domingos do Cariri',
      label: 'São Domingos do Cariri',
    },
    { value: 'São Francisco', name: 'São Francisco', label: 'São Francisco' },
    {
      value: 'São João do Cariri',
      name: 'São João do Cariri',
      label: 'São João do Cariri',
    },
    {
      value: 'São João do Rio do Peixe',
      name: 'São João do Rio do Peixe',
      label: 'São João do Rio do Peixe',
    },
    {
      value: 'São João do Tigre',
      name: 'São João do Tigre',
      label: 'São João do Tigre',
    },
    {
      value: 'São José da Lagoa Tapada',
      name: 'São José da Lagoa Tapada',
      label: 'São José da Lagoa Tapada',
    },
    {
      value: 'São José de Caiana',
      name: 'São José de Caiana',
      label: 'São José de Caiana',
    },
    {
      value: 'São José de Espinharas',
      name: 'São José de Espinharas',
      label: 'São José de Espinharas',
    },
    {
      value: 'São José de Piranhas',
      name: 'São José de Piranhas',
      label: 'São José de Piranhas',
    },
    {
      value: 'São José de Princesa',
      name: 'São José de Princesa',
      label: 'São José de Princesa',
    },
    {
      value: 'São José do Bonfim',
      name: 'São José do Bonfim',
      label: 'São José do Bonfim',
    },
    {
      value: 'São José do Brejo do Cruz',
      name: 'São José do Brejo do Cruz',
      label: 'São José do Brejo do Cruz',
    },
    {
      value: 'São José do Sabugi',
      name: 'São José do Sabugi',
      label: 'São José do Sabugi',
    },
    {
      value: 'São José dos Cordeiros',
      name: 'São José dos Cordeiros',
      label: 'São José dos Cordeiros',
    },
    {
      value: 'São José dos Ramos',
      name: 'São José dos Ramos',
      label: 'São José dos Ramos',
    },
    { value: 'São Mamede', name: 'São Mamede', label: 'São Mamede' },
    {
      value: 'São Miguel de Taipu',
      name: 'São Miguel de Taipu',
      label: 'São Miguel de Taipu',
    },
    {
      value: 'São Sebastião de Lagoa de Roça',
      name: 'São Sebastião de Lagoa de Roça',
      label: 'São Sebastião de Lagoa de Roça',
    },
    {
      value: 'São Sebastião do Umbuzeiro',
      name: 'São Sebastião do Umbuzeiro',
      label: 'São Sebastião do Umbuzeiro',
    },
    { value: 'Sapé', name: 'Sapé', label: 'Sapé' },
    { value: 'Seridó', name: 'Seridó', label: 'Seridó' },
    { value: 'Serra Branca', name: 'Serra Branca', label: 'Serra Branca' },
    { value: 'Serra da Raiz', name: 'Serra da Raiz', label: 'Serra da Raiz' },
    { value: 'Serra Grande', name: 'Serra Grande', label: 'Serra Grande' },
    { value: 'Serra Redonda', name: 'Serra Redonda', label: 'Serra Redonda' },
    { value: 'Serraria', name: 'Serraria', label: 'Serraria' },
    { value: 'Sertãozinho', name: 'Sertãozinho', label: 'Sertãozinho' },
    { value: 'Sobrado', name: 'Sobrado', label: 'Sobrado' },
    { value: 'Solânea', name: 'Solânea', label: 'Solânea' },
    { value: 'Soledade', name: 'Soledade', label: 'Soledade' },
    { value: 'Sossêgo', name: 'Sossêgo', label: 'Sossêgo' },
    { value: 'Sousa', name: 'Sousa', label: 'Sousa' },
    { value: 'Sumé', name: 'Sumé', label: 'Sumé' },
    { value: 'Taperoá', name: 'Taperoá', label: 'Taperoá' },
    { value: 'Tavares', name: 'Tavares', label: 'Tavares' },
    { value: 'Teixeira', name: 'Teixeira', label: 'Teixeira' },
    { value: 'Tenório', name: 'Tenório', label: 'Tenório' },
    { value: 'Triunfo', name: 'Triunfo', label: 'Triunfo' },
    { value: 'Uiraúna', name: 'Uiraúna', label: 'Uiraúna' },
    { value: 'Umbuzeiro', name: 'Umbuzeiro', label: 'Umbuzeiro' },
    { value: 'Várzea', name: 'Várzea', label: 'Várzea' },
    { value: 'Vieirópolis', name: 'Vieirópolis', label: 'Vieirópolis' },
    { value: 'Vista Serrana', name: 'Vista Serrana', label: 'Vista Serrana' },
    { value: 'Zabelê', name: 'Zabelê', label: 'Zabelê' },
  ],
  PE: [
    { value: 'Abreu e Lima', name: 'Abreu e Lima', label: 'Abreu e Lima' },
    {
      value: 'Afogados da Ingazeira',
      name: 'Afogados da Ingazeira',
      label: 'Afogados da Ingazeira',
    },
    { value: 'Afrânio', name: 'Afrânio', label: 'Afrânio' },
    { value: 'Agrestina', name: 'Agrestina', label: 'Agrestina' },
    { value: 'Água Preta', name: 'Água Preta', label: 'Água Preta' },
    { value: 'Águas Belas', name: 'Águas Belas', label: 'Águas Belas' },
    { value: 'Alagoinha', name: 'Alagoinha', label: 'Alagoinha' },
    { value: 'Aliança', name: 'Aliança', label: 'Aliança' },
    { value: 'Altinho', name: 'Altinho', label: 'Altinho' },
    { value: 'Amaraji', name: 'Amaraji', label: 'Amaraji' },
    { value: 'Angelim', name: 'Angelim', label: 'Angelim' },
    { value: 'Araçoiaba', name: 'Araçoiaba', label: 'Araçoiaba' },
    { value: 'Araripina', name: 'Araripina', label: 'Araripina' },
    { value: 'Arcoverde', name: 'Arcoverde', label: 'Arcoverde' },
    {
      value: 'Barra de Guabiraba',
      name: 'Barra de Guabiraba',
      label: 'Barra de Guabiraba',
    },
    { value: 'Barreiros', name: 'Barreiros', label: 'Barreiros' },
    {
      value: 'Belém de Maria',
      name: 'Belém de Maria',
      label: 'Belém de Maria',
    },
    {
      value: 'Belém do São Francisco',
      name: 'Belém do São Francisco',
      label: 'Belém do São Francisco',
    },
    { value: 'Belo Jardim', name: 'Belo Jardim', label: 'Belo Jardim' },
    { value: 'Betânia', name: 'Betânia', label: 'Betânia' },
    { value: 'Bezerros', name: 'Bezerros', label: 'Bezerros' },
    { value: 'Bodocó', name: 'Bodocó', label: 'Bodocó' },
    { value: 'Bom Conselho', name: 'Bom Conselho', label: 'Bom Conselho' },
    { value: 'Bom Jardim', name: 'Bom Jardim', label: 'Bom Jardim' },
    { value: 'Bonito', name: 'Bonito', label: 'Bonito' },
    { value: 'Brejão', name: 'Brejão', label: 'Brejão' },
    { value: 'Brejinho', name: 'Brejinho', label: 'Brejinho' },
    {
      value: 'Brejo da Madre de Deus',
      name: 'Brejo da Madre de Deus',
      label: 'Brejo da Madre de Deus',
    },
    { value: 'Buenos Aires', name: 'Buenos Aires', label: 'Buenos Aires' },
    { value: 'Buíque', name: 'Buíque', label: 'Buíque' },
    {
      value: 'Cabo de Santo Agostinho',
      name: 'Cabo de Santo Agostinho',
      label: 'Cabo de Santo Agostinho',
    },
    { value: 'Cabrobó', name: 'Cabrobó', label: 'Cabrobó' },
    { value: 'Cachoeirinha', name: 'Cachoeirinha', label: 'Cachoeirinha' },
    { value: 'Caetés', name: 'Caetés', label: 'Caetés' },
    { value: 'Calçado', name: 'Calçado', label: 'Calçado' },
    { value: 'Calumbi', name: 'Calumbi', label: 'Calumbi' },
    { value: 'Camaragibe', name: 'Camaragibe', label: 'Camaragibe' },
    {
      value: 'Camocim de São Félix',
      name: 'Camocim de São Félix',
      label: 'Camocim de São Félix',
    },
    { value: 'Camutanga', name: 'Camutanga', label: 'Camutanga' },
    { value: 'Canhotinho', name: 'Canhotinho', label: 'Canhotinho' },
    { value: 'Capoeiras', name: 'Capoeiras', label: 'Capoeiras' },
    { value: 'Carnaíba', name: 'Carnaíba', label: 'Carnaíba' },
    {
      value: 'Carnaubeira da Penha',
      name: 'Carnaubeira da Penha',
      label: 'Carnaubeira da Penha',
    },
    { value: 'Carpina', name: 'Carpina', label: 'Carpina' },
    { value: 'Caruaru', name: 'Caruaru', label: 'Caruaru' },
    { value: 'Casinhas', name: 'Casinhas', label: 'Casinhas' },
    { value: 'Catende', name: 'Catende', label: 'Catende' },
    { value: 'Cedro', name: 'Cedro', label: 'Cedro' },
    {
      value: 'Chã de Alegria',
      name: 'Chã de Alegria',
      label: 'Chã de Alegria',
    },
    { value: 'Chã Grande', name: 'Chã Grande', label: 'Chã Grande' },
    { value: 'Condado', name: 'Condado', label: 'Condado' },
    { value: 'Correntes', name: 'Correntes', label: 'Correntes' },
    { value: 'Cortês', name: 'Cortês', label: 'Cortês' },
    { value: 'Cumaru', name: 'Cumaru', label: 'Cumaru' },
    { value: 'Cupira', name: 'Cupira', label: 'Cupira' },
    { value: 'Custódia', name: 'Custódia', label: 'Custódia' },
    { value: 'Dormentes', name: 'Dormentes', label: 'Dormentes' },
    { value: 'Escada', name: 'Escada', label: 'Escada' },
    { value: 'Exu', name: 'Exu', label: 'Exu' },
    { value: 'Feira Nova', name: 'Feira Nova', label: 'Feira Nova' },
    {
      value: 'Fernando de Noronha',
      name: 'Fernando de Noronha',
      label: 'Fernando de Noronha',
    },
    { value: 'Ferreiros', name: 'Ferreiros', label: 'Ferreiros' },
    { value: 'Flores', name: 'Flores', label: 'Flores' },
    { value: 'Floresta', name: 'Floresta', label: 'Floresta' },
    {
      value: 'Frei Miguelinho',
      name: 'Frei Miguelinho',
      label: 'Frei Miguelinho',
    },
    { value: 'Gameleira', name: 'Gameleira', label: 'Gameleira' },
    { value: 'Garanhuns', name: 'Garanhuns', label: 'Garanhuns' },
    {
      value: 'Glória do Goitá',
      name: 'Glória do Goitá',
      label: 'Glória do Goitá',
    },
    { value: 'Goiana', name: 'Goiana', label: 'Goiana' },
    { value: 'Granito', name: 'Granito', label: 'Granito' },
    { value: 'Gravatá', name: 'Gravatá', label: 'Gravatá' },
    { value: 'Iati', name: 'Iati', label: 'Iati' },
    { value: 'Ibimirim', name: 'Ibimirim', label: 'Ibimirim' },
    { value: 'Ibirajuba', name: 'Ibirajuba', label: 'Ibirajuba' },
    { value: 'Igarassu', name: 'Igarassu', label: 'Igarassu' },
    { value: 'Iguaraci', name: 'Iguaraci', label: 'Iguaraci' },
    {
      value: 'Ilha de Itamaracá',
      name: 'Ilha de Itamaracá',
      label: 'Ilha de Itamaracá',
    },
    { value: 'Inajá', name: 'Inajá', label: 'Inajá' },
    { value: 'Ingazeira', name: 'Ingazeira', label: 'Ingazeira' },
    { value: 'Ipojuca', name: 'Ipojuca', label: 'Ipojuca' },
    { value: 'Ipubi', name: 'Ipubi', label: 'Ipubi' },
    { value: 'Irajaí', name: 'Irajaí', label: 'Irajaí' },
    { value: 'Itacuruba', name: 'Itacuruba', label: 'Itacuruba' },
    { value: 'Itaíba', name: 'Itaíba', label: 'Itaíba' },
    { value: 'Itamaracá', name: 'Itamaracá', label: 'Itamaracá' },
    { value: 'Itambé', name: 'Itambé', label: 'Itambé' },
    { value: 'Itapetim', name: 'Itapetim', label: 'Itapetim' },
    { value: 'Itapissuma', name: 'Itapissuma', label: 'Itapissuma' },
    { value: 'Itaquitinga', name: 'Itaquitinga', label: 'Itaquitinga' },
    {
      value: 'Jabitaca',
      name: 'Jabitaca',
      label: 'Jabitaca',
    },
    {
      value: 'Jaboatão dos Guararapes',
      name: 'Jaboatão dos Guararapes',
      label: 'Jaboatão dos Guararapes',
    },
    { value: 'Jaqueira', name: 'Jaqueira', label: 'Jaqueira' },
    { value: 'Jataúba', name: 'Jataúba', label: 'Jataúba' },
    { value: 'Jatobá', name: 'Jatobá', label: 'Jatobá' },
    { value: 'João Alfredo', name: 'João Alfredo', label: 'João Alfredo' },
    {
      value: 'Joaquim Nabuco',
      name: 'Joaquim Nabuco',
      label: 'Joaquim Nabuco',
    },
    { value: 'Jucati', name: 'Jucati', label: 'Jucati' },
    { value: 'Jupi', name: 'Jupi', label: 'Jupi' },
    { value: 'Jurema', name: 'Jurema', label: 'Jurema' },
    {
      value: 'Lagoa do Carro',
      name: 'Lagoa do Carro',
      label: 'Lagoa do Carro',
    },
    {
      value: 'Lagoa do Itaenga',
      name: 'Lagoa do Itaenga',
      label: 'Lagoa do Itaenga',
    },
    { value: 'Lagoa do Ouro', name: 'Lagoa do Ouro', label: 'Lagoa do Ouro' },
    {
      value: 'Lagoa dos Gatos',
      name: 'Lagoa dos Gatos',
      label: 'Lagoa dos Gatos',
    },
    { value: 'Lagoa Grande', name: 'Lagoa Grande', label: 'Lagoa Grande' },
    { value: 'Lajedo', name: 'Lajedo', label: 'Lajedo' },
    { value: 'Limoeiro', name: 'Limoeiro', label: 'Limoeiro' },
    { value: 'Macaparana', name: 'Macaparana', label: 'Macaparana' },
    { value: 'Machados', name: 'Machados', label: 'Machados' },
    { value: 'Manari', name: 'Manari', label: 'Manari' },
    { value: 'Maraial', name: 'Maraial', label: 'Maraial' },
    { value: 'Mirandiba', name: 'Mirandiba', label: 'Mirandiba' },
    { value: 'Moreilândia', name: 'Moreilândia', label: 'Moreilândia' },
    { value: 'Moreno', name: 'Moreno', label: 'Moreno' },
    {
      value: 'Nazaré da Mata',
      name: 'Nazaré da Mata',
      label: 'Nazaré da Mata',
    },
    { value: 'Olinda', name: 'Olinda', label: 'Olinda' },
    { value: 'Orobó', name: 'Orobó', label: 'Orobó' },
    { value: 'Orocó', name: 'Orocó', label: 'Orocó' },
    { value: 'Ouricuri', name: 'Ouricuri', label: 'Ouricuri' },
    { value: 'Palmares', name: 'Palmares', label: 'Palmares' },
    { value: 'Palmeirina', name: 'Palmeirina', label: 'Palmeirina' },
    { value: 'Panelas', name: 'Panelas', label: 'Panelas' },
    { value: 'Paranatama', name: 'Paranatama', label: 'Paranatama' },
    { value: 'Parnamirim', name: 'Parnamirim', label: 'Parnamirim' },
    { value: 'Passira', name: 'Passira', label: 'Passira' },
    { value: 'Paudalho', name: 'Paudalho', label: 'Paudalho' },
    { value: 'Paulista', name: 'Paulista', label: 'Paulista' },
    { value: 'Pedra', name: 'Pedra', label: 'Pedra' },
    { value: 'Pesqueira', name: 'Pesqueira', label: 'Pesqueira' },
    { value: 'Petrolândia', name: 'Petrolândia', label: 'Petrolândia' },
    { value: 'Petrolina', name: 'Petrolina', label: 'Petrolina' },
    { value: 'Poção', name: 'Poção', label: 'Poção' },
    { value: 'Pombos', name: 'Pombos', label: 'Pombos' },
    { value: 'Primavera', name: 'Primavera', label: 'Primavera' },
    { value: 'Quipapá', name: 'Quipapá', label: 'Quipapá' },
    { value: 'Quixaba', name: 'Quixaba', label: 'Quixaba' },
    { value: 'Recife', name: 'Recife', label: 'Recife' },
    {
      value: 'Riacho das Almas',
      name: 'Riacho das Almas',
      label: 'Riacho das Almas',
    },
    { value: 'Ribeirão', name: 'Ribeirão', label: 'Ribeirão' },
    { value: 'Rio Formoso', name: 'Rio Formoso', label: 'Rio Formoso' },
    { value: 'Sairé', name: 'Sairé', label: 'Sairé' },
    { value: 'Salgadinho', name: 'Salgadinho', label: 'Salgadinho' },
    { value: 'Salgueiro', name: 'Salgueiro', label: 'Salgueiro' },
    { value: 'Saloá', name: 'Saloá', label: 'Saloá' },
    { value: 'Sanharó', name: 'Sanharó', label: 'Sanharó' },
    { value: 'Santa Cruz', name: 'Santa Cruz', label: 'Santa Cruz' },
    {
      value: 'Santa Cruz da Baixa Verde',
      name: 'Santa Cruz da Baixa Verde',
      label: 'Santa Cruz da Baixa Verde',
    },
    {
      value: 'Santa Cruz do Capibaribe',
      name: 'Santa Cruz do Capibaribe',
      label: 'Santa Cruz do Capibaribe',
    },
    {
      value: 'Santa Filomena',
      name: 'Santa Filomena',
      label: 'Santa Filomena',
    },
    {
      value: 'Santa Maria da Boa Vista',
      name: 'Santa Maria da Boa Vista',
      label: 'Santa Maria da Boa Vista',
    },
    {
      value: 'Santa Maria do Cambucá',
      name: 'Santa Maria do Cambucá',
      label: 'Santa Maria do Cambucá',
    },
    {
      value: 'Santa Terezinha',
      name: 'Santa Terezinha',
      label: 'Santa Terezinha',
    },
    {
      value: 'São Benedito do Sul',
      name: 'São Benedito do Sul',
      label: 'São Benedito do Sul',
    },
    {
      value: 'São Bento do Una',
      name: 'São Bento do Una',
      label: 'São Bento do Una',
    },
    { value: 'São Caitano', name: 'São Caitano', label: 'São Caitano' },
    { value: 'São João', name: 'São João', label: 'São João' },
    {
      value: 'São Joaquim do Monte',
      name: 'São Joaquim do Monte',
      label: 'São Joaquim do Monte',
    },
    {
      value: 'São José da Coroa Grande',
      name: 'São José da Coroa Grande',
      label: 'São José da Coroa Grande',
    },
    {
      value: 'São José do Belmonte',
      name: 'São José do Belmonte',
      label: 'São José do Belmonte',
    },
    {
      value: 'São José do Egito',
      name: 'São José do Egito',
      label: 'São José do Egito',
    },
    {
      value: 'São Lourenço da Mata',
      name: 'São Lourenço da Mata',
      label: 'São Lourenço da Mata',
    },
    {
      value: 'São Vicente Ferrer',
      name: 'São Vicente Ferrer',
      label: 'São Vicente Ferrer',
    },
    { value: 'Serra Talhada', name: 'Serra Talhada', label: 'Serra Talhada' },
    { value: 'Serrita', name: 'Serrita', label: 'Serrita' },
    { value: 'Sertânia', name: 'Sertânia', label: 'Sertânia' },
    { value: 'Sirinhaém', name: 'Sirinhaém', label: 'Sirinhaém' },
    { value: 'Solidão', name: 'Solidão', label: 'Solidão' },
    { value: 'Surubim', name: 'Surubim', label: 'Surubim' },
    { value: 'Tabira', name: 'Tabira', label: 'Tabira' },
    { value: 'Tacaimbó', name: 'Tacaimbó', label: 'Tacaimbó' },
    { value: 'Tacaratu', name: 'Tacaratu', label: 'Tacaratu' },
    { value: 'Tamandaré', name: 'Tamandaré', label: 'Tamandaré' },
    {
      value: 'Taquaritinga do Norte',
      name: 'Taquaritinga do Norte',
      label: 'Taquaritinga do Norte',
    },
    { value: 'Terezinha', name: 'Terezinha', label: 'Terezinha' },
    { value: 'Terra Nova', name: 'Terra Nova', label: 'Terra Nova' },
    { value: 'Timbaúba', name: 'Timbaúba', label: 'Timbaúba' },
    { value: 'Toritama', name: 'Toritama', label: 'Toritama' },
    { value: 'Tracunhaém', name: 'Tracunhaém', label: 'Tracunhaém' },
    { value: 'Trindade', name: 'Trindade', label: 'Trindade' },
    { value: 'Triunfo', name: 'Triunfo', label: 'Triunfo' },
    { value: 'Tupanatinga', name: 'Tupanatinga', label: 'Tupanatinga' },
    { value: 'Tuparetama', name: 'Tuparetama', label: 'Tuparetama' },
    { value: 'Venturosa', name: 'Venturosa', label: 'Venturosa' },
    { value: 'Verdejante', name: 'Verdejante', label: 'Verdejante' },
    {
      value: 'Vertente do Lério',
      name: 'Vertente do Lério',
      label: 'Vertente do Lério',
    },
    { value: 'Vertentes', name: 'Vertentes', label: 'Vertentes' },
    { value: 'Vicência', name: 'Vicência', label: 'Vicência' },
    {
      value: 'Vitória de Santo Antão',
      name: 'Vitória de Santo Antão',
      label: 'Vitória de Santo Antão',
    },
    { value: 'Xexéu', name: 'Xexéu', label: 'Xexéu' },
  ],
  PI: [
    { value: 'Acauã', name: 'Acauã', label: 'Acauã' },
    { value: 'Agricolândia', name: 'Agricolândia', label: 'Agricolândia' },
    { value: 'Água Branca', name: 'Água Branca', label: 'Água Branca' },
    {
      value: 'Alagoinha do Piauí',
      name: 'Alagoinha do Piauí',
      label: 'Alagoinha do Piauí',
    },
    {
      value: 'Alegrete do Piauí',
      name: 'Alegrete do Piauí',
      label: 'Alegrete do Piauí',
    },
    { value: 'Alto Longá', name: 'Alto Longá', label: 'Alto Longá' },
    { value: 'Altos', name: 'Altos', label: 'Altos' },
    {
      value: 'Alvorada do Gurguéia',
      name: 'Alvorada do Gurguéia',
      label: 'Alvorada do Gurguéia',
    },
    { value: 'Amarante', name: 'Amarante', label: 'Amarante' },
    {
      value: 'Angical do Piauí',
      name: 'Angical do Piauí',
      label: 'Angical do Piauí',
    },
    {
      value: 'Anísio de Abreu',
      name: 'Anísio de Abreu',
      label: 'Anísio de Abreu',
    },
    {
      value: 'Antônio Almeida',
      name: 'Antônio Almeida',
      label: 'Antônio Almeida',
    },
    { value: 'Aroazes', name: 'Aroazes', label: 'Aroazes' },
    { value: 'Arraial', name: 'Arraial', label: 'Arraial' },
    {
      value: 'Assunção do Piauí',
      name: 'Assunção do Piauí',
      label: 'Assunção do Piauí',
    },
    { value: 'Avelino Lopes', name: 'Avelino Lopes', label: 'Avelino Lopes' },
    {
      value: 'Baixa Grande do Ribeiro',
      name: 'Baixa Grande do Ribeiro',
      label: 'Baixa Grande do Ribeiro',
    },
    {
      value: "Barra D'Alcântara",
      name: "Barra D'Alcântara",
      label: "Barra D'Alcântara",
    },
    { value: 'Barras', name: 'Barras', label: 'Barras' },
    {
      value: 'Barreiras do Piauí',
      name: 'Barreiras do Piauí',
      label: 'Barreiras do Piauí',
    },
    { value: 'Barro Duro', name: 'Barro Duro', label: 'Barro Duro' },
    { value: 'Batalha', name: 'Batalha', label: 'Batalha' },
    {
      value: 'Bela Vista do Piauí',
      name: 'Bela Vista do Piauí',
      label: 'Bela Vista do Piauí',
    },
    {
      value: 'Belém do Piauí',
      name: 'Belém do Piauí',
      label: 'Belém do Piauí',
    },
    { value: 'Beneditinos', name: 'Beneditinos', label: 'Beneditinos' },
    { value: 'Bertolínia', name: 'Bertolínia', label: 'Bertolínia' },
    {
      value: 'Betânia do Piauí',
      name: 'Betânia do Piauí',
      label: 'Betânia do Piauí',
    },
    { value: 'Boa Hora', name: 'Boa Hora', label: 'Boa Hora' },
    { value: 'Bocaina', name: 'Bocaina', label: 'Bocaina' },
    { value: 'Bom Jesus', name: 'Bom Jesus', label: 'Bom Jesus' },
    {
      value: 'Bom Princípio do Piauí',
      name: 'Bom Princípio do Piauí',
      label: 'Bom Princípio do Piauí',
    },
    {
      value: 'Bonfim do Piauí',
      name: 'Bonfim do Piauí',
      label: 'Bonfim do Piauí',
    },
    {
      value: 'Boqueirão do Piauí',
      name: 'Boqueirão do Piauí',
      label: 'Boqueirão do Piauí',
    },
    { value: 'Brasileira', name: 'Brasileira', label: 'Brasileira' },
    {
      value: 'Brejo do Piauí',
      name: 'Brejo do Piauí',
      label: 'Brejo do Piauí',
    },
    {
      value: 'Buriti dos Lopes',
      name: 'Buriti dos Lopes',
      label: 'Buriti dos Lopes',
    },
    {
      value: 'Buriti dos Montes',
      name: 'Buriti dos Montes',
      label: 'Buriti dos Montes',
    },
    {
      value: 'Cabeceiras do Piauí',
      name: 'Cabeceiras do Piauí',
      label: 'Cabeceiras do Piauí',
    },
    {
      value: 'Cajazeiras do Piauí',
      name: 'Cajazeiras do Piauí',
      label: 'Cajazeiras do Piauí',
    },
    {
      value: 'Cajueiro da Praia',
      name: 'Cajueiro da Praia',
      label: 'Cajueiro da Praia',
    },
    {
      value: 'Caldeirão Grande do Piauí',
      name: 'Caldeirão Grande do Piauí',
      label: 'Caldeirão Grande do Piauí',
    },
    {
      value: 'Campinas do Piauí',
      name: 'Campinas do Piauí',
      label: 'Campinas do Piauí',
    },
    {
      value: 'Campo Alegre do Fidalgo',
      name: 'Campo Alegre do Fidalgo',
      label: 'Campo Alegre do Fidalgo',
    },
    {
      value: 'Campo Grande do Piauí',
      name: 'Campo Grande do Piauí',
      label: 'Campo Grande do Piauí',
    },
    {
      value: 'Campo Largo do Piauí',
      name: 'Campo Largo do Piauí',
      label: 'Campo Largo do Piauí',
    },
    { value: 'Campo Maior', name: 'Campo Maior', label: 'Campo Maior' },
    { value: 'Canavieira', name: 'Canavieira', label: 'Canavieira' },
    {
      value: 'Canto do Buriti',
      name: 'Canto do Buriti',
      label: 'Canto do Buriti',
    },
    {
      value: 'Capitão de Campos',
      name: 'Capitão de Campos',
      label: 'Capitão de Campos',
    },
    {
      value: 'Capitão Gervásio Oliveira',
      name: 'Capitão Gervásio Oliveira',
      label: 'Capitão Gervásio Oliveira',
    },
    { value: 'Caracol', name: 'Caracol', label: 'Caracol' },
    {
      value: 'Caraúbas do Piauí',
      name: 'Caraúbas do Piauí',
      label: 'Caraúbas do Piauí',
    },
    {
      value: 'Caridade do Piauí',
      name: 'Caridade do Piauí',
      label: 'Caridade do Piauí',
    },
    {
      value: 'Castelo do Piauí',
      name: 'Castelo do Piauí',
      label: 'Castelo do Piauí',
    },
    { value: 'Caxingó', name: 'Caxingó', label: 'Caxingó' },
    { value: 'Cocal', name: 'Cocal', label: 'Cocal' },
    {
      value: 'Cocal de Telha',
      name: 'Cocal de Telha',
      label: 'Cocal de Telha',
    },
    {
      value: 'Cocal dos Alves',
      name: 'Cocal dos Alves',
      label: 'Cocal dos Alves',
    },
    { value: 'Coivaras', name: 'Coivaras', label: 'Coivaras' },
    {
      value: 'Colônia do Gurguéia',
      name: 'Colônia do Gurguéia',
      label: 'Colônia do Gurguéia',
    },
    {
      value: 'Colônia do Piauí',
      name: 'Colônia do Piauí',
      label: 'Colônia do Piauí',
    },
    {
      value: 'Conceição do Canindé',
      name: 'Conceição do Canindé',
      label: 'Conceição do Canindé',
    },
    {
      value: 'Coronel José Dias',
      name: 'Coronel José Dias',
      label: 'Coronel José Dias',
    },
    { value: 'Corrente', name: 'Corrente', label: 'Corrente' },
    {
      value: 'Cristalândia do Piauí',
      name: 'Cristalândia do Piauí',
      label: 'Cristalândia do Piauí',
    },
    {
      value: 'Cristino Castro',
      name: 'Cristino Castro',
      label: 'Cristino Castro',
    },
    { value: 'Curimatá', name: 'Curimatá', label: 'Curimatá' },
    { value: 'Currais', name: 'Currais', label: 'Currais' },
    {
      value: 'Curral Novo do Piauí',
      name: 'Curral Novo do Piauí',
      label: 'Curral Novo do Piauí',
    },
    { value: 'Curralinhos', name: 'Curralinhos', label: 'Curralinhos' },
    {
      value: 'Demerval Lobão',
      name: 'Demerval Lobão',
      label: 'Demerval Lobão',
    },
    {
      value: 'Dirceu Arcoverde',
      name: 'Dirceu Arcoverde',
      label: 'Dirceu Arcoverde',
    },
    {
      value: 'Dom Expedito Lopes',
      name: 'Dom Expedito Lopes',
      label: 'Dom Expedito Lopes',
    },
    { value: 'Dom Inocêncio', name: 'Dom Inocêncio', label: 'Dom Inocêncio' },
    {
      value: 'Domingos Mourão',
      name: 'Domingos Mourão',
      label: 'Domingos Mourão',
    },
    {
      value: 'Elesbão Veloso',
      name: 'Elesbão Veloso',
      label: 'Elesbão Veloso',
    },
    {
      value: 'Eliseu Martins',
      name: 'Eliseu Martins',
      label: 'Eliseu Martins',
    },
    { value: 'Esperantina', name: 'Esperantina', label: 'Esperantina' },
    {
      value: 'Fartura do Piauí',
      name: 'Fartura do Piauí',
      label: 'Fartura do Piauí',
    },
    {
      value: 'Flores do Piauí',
      name: 'Flores do Piauí',
      label: 'Flores do Piauí',
    },
    {
      value: 'Floresta do Piauí',
      name: 'Floresta do Piauí',
      label: 'Floresta do Piauí',
    },
    { value: 'Floriano', name: 'Floriano', label: 'Floriano' },
    { value: 'Francinópolis', name: 'Francinópolis', label: 'Francinópolis' },
    {
      value: 'Francisco Ayres',
      name: 'Francisco Ayres',
      label: 'Francisco Ayres',
    },
    {
      value: 'Francisco Macedo',
      name: 'Francisco Macedo',
      label: 'Francisco Macedo',
    },
    {
      value: 'Francisco Santos',
      name: 'Francisco Santos',
      label: 'Francisco Santos',
    },
    { value: 'Fronteiras', name: 'Fronteiras', label: 'Fronteiras' },
    { value: 'Geminiano', name: 'Geminiano', label: 'Geminiano' },
    { value: 'Gilbués', name: 'Gilbués', label: 'Gilbués' },
    { value: 'Guadalupe', name: 'Guadalupe', label: 'Guadalupe' },
    { value: 'Guaribas', name: 'Guaribas', label: 'Guaribas' },
    { value: 'Hugo Napoleão', name: 'Hugo Napoleão', label: 'Hugo Napoleão' },
    { value: 'Ilha Grande', name: 'Ilha Grande', label: 'Ilha Grande' },
    { value: 'Inhuma', name: 'Inhuma', label: 'Inhuma' },
    {
      value: 'Ipiranga do Piauí',
      name: 'Ipiranga do Piauí',
      label: 'Ipiranga do Piauí',
    },
    { value: 'Isaías Coelho', name: 'Isaías Coelho', label: 'Isaías Coelho' },
    { value: 'Itainópolis', name: 'Itainópolis', label: 'Itainópolis' },
    { value: 'Itaueira', name: 'Itaueira', label: 'Itaueira' },
    {
      value: 'Jacobina do Piauí',
      name: 'Jacobina do Piauí',
      label: 'Jacobina do Piauí',
    },
    { value: 'Jaicós', name: 'Jaicós', label: 'Jaicós' },
    {
      value: 'Jardim do Mulato',
      name: 'Jardim do Mulato',
      label: 'Jardim do Mulato',
    },
    {
      value: 'Jatobá do Piauí',
      name: 'Jatobá do Piauí',
      label: 'Jatobá do Piauí',
    },
    { value: 'Jerumenha', name: 'Jerumenha', label: 'Jerumenha' },
    { value: 'João Costa', name: 'João Costa', label: 'João Costa' },
    { value: 'Joaquim Pires', name: 'Joaquim Pires', label: 'Joaquim Pires' },
    { value: 'Joca Marques', name: 'Joca Marques', label: 'Joca Marques' },
    {
      value: 'José de Freitas',
      name: 'José de Freitas',
      label: 'José de Freitas',
    },
    {
      value: 'Juazeiro do Piauí',
      name: 'Juazeiro do Piauí',
      label: 'Juazeiro do Piauí',
    },
    { value: 'Júlio Borges', name: 'Júlio Borges', label: 'Júlio Borges' },
    { value: 'Jurema', name: 'Jurema', label: 'Jurema' },
    { value: 'Lagoa Alegre', name: 'Lagoa Alegre', label: 'Lagoa Alegre' },
    {
      value: 'Lagoa de São Francisco',
      name: 'Lagoa de São Francisco',
      label: 'Lagoa de São Francisco',
    },
    {
      value: 'Lagoa do Barro do Piauí',
      name: 'Lagoa do Barro do Piauí',
      label: 'Lagoa do Barro do Piauí',
    },
    {
      value: 'Lagoa do Piauí',
      name: 'Lagoa do Piauí',
      label: 'Lagoa do Piauí',
    },
    {
      value: 'Lagoa do Sítio',
      name: 'Lagoa do Sítio',
      label: 'Lagoa do Sítio',
    },
    {
      value: 'Lagoinha do Piauí',
      name: 'Lagoinha do Piauí',
      label: 'Lagoinha do Piauí',
    },
    { value: 'Landri Sales', name: 'Landri Sales', label: 'Landri Sales' },
    { value: 'Luís Correia', name: 'Luís Correia', label: 'Luís Correia' },
    { value: 'Luzilândia', name: 'Luzilândia', label: 'Luzilândia' },
    { value: 'Madeiro', name: 'Madeiro', label: 'Madeiro' },
    { value: 'Manoel Emídio', name: 'Manoel Emídio', label: 'Manoel Emídio' },
    { value: 'Marcolândia', name: 'Marcolândia', label: 'Marcolândia' },
    {
      value: 'Marcos Parente',
      name: 'Marcos Parente',
      label: 'Marcos Parente',
    },
    {
      value: 'Massapê do Piauí',
      name: 'Massapê do Piauí',
      label: 'Massapê do Piauí',
    },
    {
      value: 'Matias Olímpio',
      name: 'Matias Olímpio',
      label: 'Matias Olímpio',
    },
    { value: 'Miguel Alves', name: 'Miguel Alves', label: 'Miguel Alves' },
    { value: 'Miguel Leão', name: 'Miguel Leão', label: 'Miguel Leão' },
    {
      value: 'Milton Brandão',
      name: 'Milton Brandão',
      label: 'Milton Brandão',
    },
    { value: 'Monsenhor Gil', name: 'Monsenhor Gil', label: 'Monsenhor Gil' },
    {
      value: 'Monsenhor Hipólito',
      name: 'Monsenhor Hipólito',
      label: 'Monsenhor Hipólito',
    },
    {
      value: 'Monte Alegre do Piauí',
      name: 'Monte Alegre do Piauí',
      label: 'Monte Alegre do Piauí',
    },
    {
      value: 'Morro Cabeça no Tempo',
      name: 'Morro Cabeça no Tempo',
      label: 'Morro Cabeça no Tempo',
    },
    {
      value: 'Morro do Chapéu do Piauí',
      name: 'Morro do Chapéu do Piauí',
      label: 'Morro do Chapéu do Piauí',
    },
    {
      value: 'Murici dos Portelas',
      name: 'Murici dos Portelas',
      label: 'Murici dos Portelas',
    },
    {
      value: 'Nazaré do Piauí',
      name: 'Nazaré do Piauí',
      label: 'Nazaré do Piauí',
    },
    {
      value: 'Nossa Senhora de Nazaré',
      name: 'Nossa Senhora de Nazaré',
      label: 'Nossa Senhora de Nazaré',
    },
    {
      value: 'Nossa Senhora dos Remédios',
      name: 'Nossa Senhora dos Remédios',
      label: 'Nossa Senhora dos Remédios',
    },
    {
      value: 'Nova Santa Rita',
      name: 'Nova Santa Rita',
      label: 'Nova Santa Rita',
    },
    {
      value: 'Novo Oriente do Piauí',
      name: 'Novo Oriente do Piauí',
      label: 'Novo Oriente do Piauí',
    },
    {
      value: 'Novo Santo Antônio',
      name: 'Novo Santo Antônio',
      label: 'Novo Santo Antônio',
    },
    { value: 'Oeiras', name: 'Oeiras', label: 'Oeiras' },
    {
      value: "Olho D'Água do Piauí",
      name: "Olho D'Água do Piauí",
      label: "Olho D'Água do Piauí",
    },
    { value: 'Padre Marcos', name: 'Padre Marcos', label: 'Padre Marcos' },
    { value: 'Paes Landim', name: 'Paes Landim', label: 'Paes Landim' },
    {
      value: 'Pajeú do Piauí',
      name: 'Pajeú do Piauí',
      label: 'Pajeú do Piauí',
    },
    {
      value: 'Palmeira do Piauí',
      name: 'Palmeira do Piauí',
      label: 'Palmeira do Piauí',
    },
    { value: 'Palmeirais', name: 'Palmeirais', label: 'Palmeirais' },
    { value: 'Paquetá', name: 'Paquetá', label: 'Paquetá' },
    { value: 'Parnaguá', name: 'Parnaguá', label: 'Parnaguá' },
    { value: 'Parnaíba', name: 'Parnaíba', label: 'Parnaíba' },
    {
      value: 'Passagem Franca do Piauí',
      name: 'Passagem Franca do Piauí',
      label: 'Passagem Franca do Piauí',
    },
    {
      value: 'Patos do Piauí',
      name: 'Patos do Piauí',
      label: 'Patos do Piauí',
    },
    {
      value: "Pau D'Arco do Piauí",
      name: "Pau D'Arco do Piauí",
      label: "Pau D'Arco do Piauí",
    },
    { value: 'Paulistana', name: 'Paulistana', label: 'Paulistana' },
    { value: 'Pavussu', name: 'Pavussu', label: 'Pavussu' },
    { value: 'Pedro II', name: 'Pedro II', label: 'Pedro II' },
    {
      value: 'Pedro Laurentino',
      name: 'Pedro Laurentino',
      label: 'Pedro Laurentino',
    },
    { value: 'Picos', name: 'Picos', label: 'Picos' },
    { value: 'Pimenteiras', name: 'Pimenteiras', label: 'Pimenteiras' },
    { value: 'Pio IX', name: 'Pio IX', label: 'Pio IX' },
    { value: 'Piracuruca', name: 'Piracuruca', label: 'Piracuruca' },
    { value: 'Piripiri', name: 'Piripiri', label: 'Piripiri' },
    { value: 'Porto', name: 'Porto', label: 'Porto' },
    {
      value: 'Porto Alegre do Piauí',
      name: 'Porto Alegre do Piauí',
      label: 'Porto Alegre do Piauí',
    },
    {
      value: 'Prata do Piauí',
      name: 'Prata do Piauí',
      label: 'Prata do Piauí',
    },
    { value: 'Queimada Nova', name: 'Queimada Nova', label: 'Queimada Nova' },
    {
      value: 'Redenção do Gurguéia',
      name: 'Redenção do Gurguéia',
      label: 'Redenção do Gurguéia',
    },
    { value: 'Regeneração', name: 'Regeneração', label: 'Regeneração' },
    { value: 'Riacho Frio', name: 'Riacho Frio', label: 'Riacho Frio' },
    {
      value: 'Ribeira do Piauí',
      name: 'Ribeira do Piauí',
      label: 'Ribeira do Piauí',
    },
    {
      value: 'Ribeiro Gonçalves',
      name: 'Ribeiro Gonçalves',
      label: 'Ribeiro Gonçalves',
    },
    {
      value: 'Rio Grande do Piauí',
      name: 'Rio Grande do Piauí',
      label: 'Rio Grande do Piauí',
    },
    {
      value: 'Santa Cruz do Piauí',
      name: 'Santa Cruz do Piauí',
      label: 'Santa Cruz do Piauí',
    },
    {
      value: 'Santa Cruz dos Milagres',
      name: 'Santa Cruz dos Milagres',
      label: 'Santa Cruz dos Milagres',
    },
    {
      value: 'Santa Filomena',
      name: 'Santa Filomena',
      label: 'Santa Filomena',
    },
    { value: 'Santa Luz', name: 'Santa Luz', label: 'Santa Luz' },
    {
      value: 'Santa Rosa do Piauí',
      name: 'Santa Rosa do Piauí',
      label: 'Santa Rosa do Piauí',
    },
    {
      value: 'Santana do Piauí',
      name: 'Santana do Piauí',
      label: 'Santana do Piauí',
    },
    {
      value: 'Santo Antônio de Lisboa',
      name: 'Santo Antônio de Lisboa',
      label: 'Santo Antônio de Lisboa',
    },
    {
      value: 'Santo Antônio dos Milagres',
      name: 'Santo Antônio dos Milagres',
      label: 'Santo Antônio dos Milagres',
    },
    {
      value: 'Santo Inácio do Piauí',
      name: 'Santo Inácio do Piauí',
      label: 'Santo Inácio do Piauí',
    },
    {
      value: 'São Braz do Piauí',
      name: 'São Braz do Piauí',
      label: 'São Braz do Piauí',
    },
    {
      value: 'São Félix do Piauí',
      name: 'São Félix do Piauí',
      label: 'São Félix do Piauí',
    },
    {
      value: 'São Francisco de Assis do Piauí',
      name: 'São Francisco de Assis do Piauí',
      label: 'São Francisco de Assis do Piauí',
    },
    {
      value: 'São Francisco do Piauí',
      name: 'São Francisco do Piauí',
      label: 'São Francisco do Piauí',
    },
    {
      value: 'São Gonçalo do Gurguéia',
      name: 'São Gonçalo do Gurguéia',
      label: 'São Gonçalo do Gurguéia',
    },
    {
      value: 'São Gonçalo do Piauí',
      name: 'São Gonçalo do Piauí',
      label: 'São Gonçalo do Piauí',
    },
    {
      value: 'São João da Canabrava',
      name: 'São João da Canabrava',
      label: 'São João da Canabrava',
    },
    {
      value: 'São João da Fronteira',
      name: 'São João da Fronteira',
      label: 'São João da Fronteira',
    },
    {
      value: 'São João da Serra',
      name: 'São João da Serra',
      label: 'São João da Serra',
    },
    {
      value: 'São João da Varjota',
      name: 'São João da Varjota',
      label: 'São João da Varjota',
    },
    {
      value: 'São João do Arraial',
      name: 'São João do Arraial',
      label: 'São João do Arraial',
    },
    {
      value: 'São João do Piauí',
      name: 'São João do Piauí',
      label: 'São João do Piauí',
    },
    {
      value: 'São José do Divino',
      name: 'São José do Divino',
      label: 'São José do Divino',
    },
    {
      value: 'São José do Peixe',
      name: 'São José do Peixe',
      label: 'São José do Peixe',
    },
    {
      value: 'São José do Piauí',
      name: 'São José do Piauí',
      label: 'São José do Piauí',
    },
    { value: 'São Julião', name: 'São Julião', label: 'São Julião' },
    {
      value: 'São Lourenço do Piauí',
      name: 'São Lourenço do Piauí',
      label: 'São Lourenço do Piauí',
    },
    {
      value: 'São Luis do Piauí',
      name: 'São Luis do Piauí',
      label: 'São Luis do Piauí',
    },
    {
      value: 'São Miguel da Baixa Grande',
      name: 'São Miguel da Baixa Grande',
      label: 'São Miguel da Baixa Grande',
    },
    {
      value: 'São Miguel do Fidalgo',
      name: 'São Miguel do Fidalgo',
      label: 'São Miguel do Fidalgo',
    },
    {
      value: 'São Miguel do Tapuio',
      name: 'São Miguel do Tapuio',
      label: 'São Miguel do Tapuio',
    },
    {
      value: 'São Pedro do Piauí',
      name: 'São Pedro do Piauí',
      label: 'São Pedro do Piauí',
    },
    {
      value: 'São Raimundo Nonato',
      name: 'São Raimundo Nonato',
      label: 'São Raimundo Nonato',
    },
    {
      value: 'Sebastião Barros',
      name: 'Sebastião Barros',
      label: 'Sebastião Barros',
    },
    {
      value: 'Sebastião Leal',
      name: 'Sebastião Leal',
      label: 'Sebastião Leal',
    },
    {
      value: 'Sigefredo Pacheco',
      name: 'Sigefredo Pacheco',
      label: 'Sigefredo Pacheco',
    },
    { value: 'Simões', name: 'Simões', label: 'Simões' },
    {
      value: 'Simplício Mendes',
      name: 'Simplício Mendes',
      label: 'Simplício Mendes',
    },
    {
      value: 'Socorro do Piauí',
      name: 'Socorro do Piauí',
      label: 'Socorro do Piauí',
    },
    { value: 'Sussuapara', name: 'Sussuapara', label: 'Sussuapara' },
    {
      value: 'Tamboril do Piauí',
      name: 'Tamboril do Piauí',
      label: 'Tamboril do Piauí',
    },
    {
      value: 'Tanque do Piauí',
      name: 'Tanque do Piauí',
      label: 'Tanque do Piauí',
    },
    { value: 'Teresina', name: 'Teresina', label: 'Teresina' },
    { value: 'União', name: 'União', label: 'União' },
    { value: 'Uruçuí', name: 'Uruçuí', label: 'Uruçuí' },
    {
      value: 'Valença do Piauí',
      name: 'Valença do Piauí',
      label: 'Valença do Piauí',
    },
    { value: 'Várzea Branca', name: 'Várzea Branca', label: 'Várzea Branca' },
    { value: 'Várzea Grande', name: 'Várzea Grande', label: 'Várzea Grande' },
    { value: 'Vera Mendes', name: 'Vera Mendes', label: 'Vera Mendes' },
    {
      value: 'Vila Nova do Piauí',
      name: 'Vila Nova do Piauí',
      label: 'Vila Nova do Piauí',
    },
    { value: 'Wall Ferraz', name: 'Wall Ferraz', label: 'Wall Ferraz' },
  ],
  PR: [
    { value: 'Abatiá', name: 'Abatiá', label: 'Abatiá' },
    { value: 'Adrianópolis', name: 'Adrianópolis', label: 'Adrianópolis' },
    { value: 'Agudos do Sul', name: 'Agudos do Sul', label: 'Agudos do Sul' },
    {
      value: 'Almirante Tamandaré',
      name: 'Almirante Tamandaré',
      label: 'Almirante Tamandaré',
    },
    {
      value: 'Altamira do Paraná',
      name: 'Altamira do Paraná',
      label: 'Altamira do Paraná',
    },
    { value: 'Alto Paraná', name: 'Alto Paraná', label: 'Alto Paraná' },
    { value: 'Alto Piquiri', name: 'Alto Piquiri', label: 'Alto Piquiri' },
    { value: 'Altônia', name: 'Altônia', label: 'Altônia' },
    {
      value: 'Alvorada do Sul',
      name: 'Alvorada do Sul',
      label: 'Alvorada do Sul',
    },
    { value: 'Amaporã', name: 'Amaporã', label: 'Amaporã' },
    { value: 'Ampére', name: 'Ampére', label: 'Ampére' },
    { value: 'Anahy', name: 'Anahy', label: 'Anahy' },
    { value: 'Andirá', name: 'Andirá', label: 'Andirá' },
    { value: 'Ângulo', name: 'Ângulo', label: 'Ângulo' },
    { value: 'Antonina', name: 'Antonina', label: 'Antonina' },
    {
      value: 'Antônio Olinto',
      name: 'Antônio Olinto',
      label: 'Antônio Olinto',
    },
    { value: 'Apucarana', name: 'Apucarana', label: 'Apucarana' },
    { value: 'Arapongas', name: 'Arapongas', label: 'Arapongas' },
    { value: 'Arapoti', name: 'Arapoti', label: 'Arapoti' },
    { value: 'Arapuã', name: 'Arapuã', label: 'Arapuã' },
    { value: 'Araruna', name: 'Araruna', label: 'Araruna' },
    { value: 'Araucária', name: 'Araucária', label: 'Araucária' },
    {
      value: 'Ariranha do Ivaí',
      name: 'Ariranha do Ivaí',
      label: 'Ariranha do Ivaí',
    },
    { value: 'Assaí', name: 'Assaí', label: 'Assaí' },
    {
      value: 'Assis Chateaubriand',
      name: 'Assis Chateaubriand',
      label: 'Assis Chateaubriand',
    },
    { value: 'Astorga', name: 'Astorga', label: 'Astorga' },
    { value: 'Atalaia', name: 'Atalaia', label: 'Atalaia' },
    { value: 'Balsa Nova', name: 'Balsa Nova', label: 'Balsa Nova' },
    { value: 'Bandeirantes', name: 'Bandeirantes', label: 'Bandeirantes' },
    {
      value: 'Barbosa Ferraz',
      name: 'Barbosa Ferraz',
      label: 'Barbosa Ferraz',
    },
    {
      value: 'Barra do Jacaré',
      name: 'Barra do Jacaré',
      label: 'Barra do Jacaré',
    },
    { value: 'Barracão', name: 'Barracão', label: 'Barracão' },
    {
      value: 'Bela Vista da Caroba',
      name: 'Bela Vista da Caroba',
      label: 'Bela Vista da Caroba',
    },
    {
      value: 'Bela Vista do Paraíso',
      name: 'Bela Vista do Paraíso',
      label: 'Bela Vista do Paraíso',
    },
    { value: 'Bituruna', name: 'Bituruna', label: 'Bituruna' },
    { value: 'Boa Esperança', name: 'Boa Esperança', label: 'Boa Esperança' },
    {
      value: 'Boa Esperança do Iguaçu',
      name: 'Boa Esperança do Iguaçu',
      label: 'Boa Esperança do Iguaçu',
    },
    {
      value: 'Boa Ventura de São Roque',
      name: 'Boa Ventura de São Roque',
      label: 'Boa Ventura de São Roque',
    },
    {
      value: 'Boa Vista da Aparecida',
      name: 'Boa Vista da Aparecida',
      label: 'Boa Vista da Aparecida',
    },
    {
      value: 'Bocaiúva do Sul',
      name: 'Bocaiúva do Sul',
      label: 'Bocaiúva do Sul',
    },
    {
      value: 'Bom Jesus do Sul',
      name: 'Bom Jesus do Sul',
      label: 'Bom Jesus do Sul',
    },
    { value: 'Bom Sucesso', name: 'Bom Sucesso', label: 'Bom Sucesso' },
    {
      value: 'Bom Sucesso do Sul',
      name: 'Bom Sucesso do Sul',
      label: 'Bom Sucesso do Sul',
    },
    { value: 'Borrazópolis', name: 'Borrazópolis', label: 'Borrazópolis' },
    { value: 'Braganey', name: 'Braganey', label: 'Braganey' },
    {
      value: 'Brasilândia do Sul',
      name: 'Brasilândia do Sul',
      label: 'Brasilândia do Sul',
    },
    { value: 'Cafeara', name: 'Cafeara', label: 'Cafeara' },
    { value: 'Cafelândia', name: 'Cafelândia', label: 'Cafelândia' },
    {
      value: 'Cafezal do Sul',
      name: 'Cafezal do Sul',
      label: 'Cafezal do Sul',
    },
    { value: 'Califórnia', name: 'Califórnia', label: 'Califórnia' },
    { value: 'Cambará', name: 'Cambará', label: 'Cambará' },
    { value: 'Cambé', name: 'Cambé', label: 'Cambé' },
    { value: 'Cambira', name: 'Cambira', label: 'Cambira' },
    {
      value: 'Campina da Lagoa',
      name: 'Campina da Lagoa',
      label: 'Campina da Lagoa',
    },
    {
      value: 'Campina do Simão',
      name: 'Campina do Simão',
      label: 'Campina do Simão',
    },
    {
      value: 'Campina Grande do Sul',
      name: 'Campina Grande do Sul',
      label: 'Campina Grande do Sul',
    },
    { value: 'Campo Bonito', name: 'Campo Bonito', label: 'Campo Bonito' },
    {
      value: 'Campo do Tenente',
      name: 'Campo do Tenente',
      label: 'Campo do Tenente',
    },
    { value: 'Campo Largo', name: 'Campo Largo', label: 'Campo Largo' },
    { value: 'Campo Magro', name: 'Campo Magro', label: 'Campo Magro' },
    { value: 'Campo Mourão', name: 'Campo Mourão', label: 'Campo Mourão' },
    {
      value: 'Cândido de Abreu',
      name: 'Cândido de Abreu',
      label: 'Cândido de Abreu',
    },
    { value: 'Candói', name: 'Candói', label: 'Candói' },
    { value: 'Cantagalo', name: 'Cantagalo', label: 'Cantagalo' },
    { value: 'Capanema', name: 'Capanema', label: 'Capanema' },
    {
      value: 'Capitão Leônidas Marques',
      name: 'Capitão Leônidas Marques',
      label: 'Capitão Leônidas Marques',
    },
    { value: 'Carambeí', name: 'Carambeí', label: 'Carambeí' },
    { value: 'Carlópolis', name: 'Carlópolis', label: 'Carlópolis' },
    { value: 'Cascavel', name: 'Cascavel', label: 'Cascavel' },
    { value: 'Castro', name: 'Castro', label: 'Castro' },
    { value: 'Catanduvas', name: 'Catanduvas', label: 'Catanduvas' },
    {
      value: 'Centenário do Sul',
      name: 'Centenário do Sul',
      label: 'Centenário do Sul',
    },
    { value: 'Cerro Azul', name: 'Cerro Azul', label: 'Cerro Azul' },
    { value: 'Céu Azul', name: 'Céu Azul', label: 'Céu Azul' },
    { value: 'Chopinzinho', name: 'Chopinzinho', label: 'Chopinzinho' },
    { value: 'Cianorte', name: 'Cianorte', label: 'Cianorte' },
    { value: 'Cidade Gaúcha', name: 'Cidade Gaúcha', label: 'Cidade Gaúcha' },
    { value: 'Clevelândia', name: 'Clevelândia', label: 'Clevelândia' },
    { value: 'Colombo', name: 'Colombo', label: 'Colombo' },
    { value: 'Colorado', name: 'Colorado', label: 'Colorado' },
    { value: 'Congonhinhas', name: 'Congonhinhas', label: 'Congonhinhas' },
    {
      value: 'Conselheiro Mairinck',
      name: 'Conselheiro Mairinck',
      label: 'Conselheiro Mairinck',
    },
    { value: 'Contenda', name: 'Contenda', label: 'Contenda' },
    { value: 'Corbélia', name: 'Corbélia', label: 'Corbélia' },
    {
      value: 'Cornélio Procópio',
      name: 'Cornélio Procópio',
      label: 'Cornélio Procópio',
    },
    {
      value: 'Coronel Domingos Soares',
      name: 'Coronel Domingos Soares',
      label: 'Coronel Domingos Soares',
    },
    {
      value: 'Coronel Vivida',
      name: 'Coronel Vivida',
      label: 'Coronel Vivida',
    },
    {
      value: 'Corumbataí do Sul',
      name: 'Corumbataí do Sul',
      label: 'Corumbataí do Sul',
    },
    { value: 'Cruz Machado', name: 'Cruz Machado', label: 'Cruz Machado' },
    {
      value: 'Cruzeiro do Iguaçu',
      name: 'Cruzeiro do Iguaçu',
      label: 'Cruzeiro do Iguaçu',
    },
    {
      value: 'Cruzeiro do Oeste',
      name: 'Cruzeiro do Oeste',
      label: 'Cruzeiro do Oeste',
    },
    {
      value: 'Cruzeiro do Sul',
      name: 'Cruzeiro do Sul',
      label: 'Cruzeiro do Sul',
    },
    { value: 'Cruzmaltina', name: 'Cruzmaltina', label: 'Cruzmaltina' },
    { value: 'Curitiba', name: 'Curitiba', label: 'Curitiba' },
    { value: 'Curiúva', name: 'Curiúva', label: 'Curiúva' },
    {
      value: "Diamante D'Oeste",
      name: "Diamante D'Oeste",
      label: "Diamante D'Oeste",
    },
    {
      value: 'Diamante do Norte',
      name: 'Diamante do Norte',
      label: 'Diamante do Norte',
    },
    {
      value: 'Diamante do Sul',
      name: 'Diamante do Sul',
      label: 'Diamante do Sul',
    },
    { value: 'Dois Vizinhos', name: 'Dois Vizinhos', label: 'Dois Vizinhos' },
    { value: 'Douradina', name: 'Douradina', label: 'Douradina' },
    {
      value: 'Doutor Camargo',
      name: 'Doutor Camargo',
      label: 'Doutor Camargo',
    },
    {
      value: 'Doutor Ulysses',
      name: 'Doutor Ulysses',
      label: 'Doutor Ulysses',
    },
    { value: 'Enéas Marques', name: 'Enéas Marques', label: 'Enéas Marques' },
    {
      value: 'Engenheiro Beltrão',
      name: 'Engenheiro Beltrão',
      label: 'Engenheiro Beltrão',
    },
    {
      value: 'Entre Rios do Oeste',
      name: 'Entre Rios do Oeste',
      label: 'Entre Rios do Oeste',
    },
    {
      value: 'Esperança Nova',
      name: 'Esperança Nova',
      label: 'Esperança Nova',
    },
    {
      value: 'Espigão Alto do Iguaçu',
      name: 'Espigão Alto do Iguaçu',
      label: 'Espigão Alto do Iguaçu',
    },
    { value: 'Farol', name: 'Farol', label: 'Farol' },
    { value: 'Faxinal', name: 'Faxinal', label: 'Faxinal' },
    {
      value: 'Fazenda Rio Grande',
      name: 'Fazenda Rio Grande',
      label: 'Fazenda Rio Grande',
    },
    { value: 'Fênix', name: 'Fênix', label: 'Fênix' },
    {
      value: 'Fernandes Pinheiro',
      name: 'Fernandes Pinheiro',
      label: 'Fernandes Pinheiro',
    },
    { value: 'Figueira', name: 'Figueira', label: 'Figueira' },
    {
      value: 'Flor da Serra do Sul',
      name: 'Flor da Serra do Sul',
      label: 'Flor da Serra do Sul',
    },
    { value: 'Floraí', name: 'Floraí', label: 'Floraí' },
    { value: 'Floresta', name: 'Floresta', label: 'Floresta' },
    { value: 'Florestópolis', name: 'Florestópolis', label: 'Florestópolis' },
    { value: 'Flórida', name: 'Flórida', label: 'Flórida' },
    {
      value: 'Formosa do Oeste',
      name: 'Formosa do Oeste',
      label: 'Formosa do Oeste',
    },
    { value: 'Foz do Iguaçu', name: 'Foz do Iguaçu', label: 'Foz do Iguaçu' },
    { value: 'Foz do Jordão', name: 'Foz do Jordão', label: 'Foz do Jordão' },
    {
      value: 'Francisco Alves',
      name: 'Francisco Alves',
      label: 'Francisco Alves',
    },
    {
      value: 'Francisco Beltrão',
      name: 'Francisco Beltrão',
      label: 'Francisco Beltrão',
    },
    {
      value: 'General Carneiro',
      name: 'General Carneiro',
      label: 'General Carneiro',
    },
    { value: 'Godoy Moreira', name: 'Godoy Moreira', label: 'Godoy Moreira' },
    { value: 'Goioerê', name: 'Goioerê', label: 'Goioerê' },
    { value: 'Goioxim', name: 'Goioxim', label: 'Goioxim' },
    { value: 'Grandes Rios', name: 'Grandes Rios', label: 'Grandes Rios' },
    { value: 'Guaíra', name: 'Guaíra', label: 'Guaíra' },
    { value: 'Guairaçá', name: 'Guairaçá', label: 'Guairaçá' },
    { value: 'Guamiranga', name: 'Guamiranga', label: 'Guamiranga' },
    { value: 'Guapirama', name: 'Guapirama', label: 'Guapirama' },
    { value: 'Guaporema', name: 'Guaporema', label: 'Guaporema' },
    { value: 'Guaraci', name: 'Guaraci', label: 'Guaraci' },
    { value: 'Guaraniaçu', name: 'Guaraniaçu', label: 'Guaraniaçu' },
    { value: 'Guarapuava', name: 'Guarapuava', label: 'Guarapuava' },
    { value: 'Guaraqueçaba', name: 'Guaraqueçaba', label: 'Guaraqueçaba' },
    { value: 'Guaratuba', name: 'Guaratuba', label: 'Guaratuba' },
    { value: 'Honório Serpa', name: 'Honório Serpa', label: 'Honório Serpa' },
    { value: 'Ibaiti', name: 'Ibaiti', label: 'Ibaiti' },
    { value: 'Ibema', name: 'Ibema', label: 'Ibema' },
    { value: 'Ibiporã', name: 'Ibiporã', label: 'Ibiporã' },
    { value: 'Icaraíma', name: 'Icaraíma', label: 'Icaraíma' },
    { value: 'Iguaraçu', name: 'Iguaraçu', label: 'Iguaraçu' },
    { value: 'Iguatu', name: 'Iguatu', label: 'Iguatu' },
    { value: 'Imbaú', name: 'Imbaú', label: 'Imbaú' },
    { value: 'Imbituva', name: 'Imbituva', label: 'Imbituva' },
    {
      value: 'Inácio Martins',
      name: 'Inácio Martins',
      label: 'Inácio Martins',
    },
    { value: 'Inajá', name: 'Inajá', label: 'Inajá' },
    { value: 'Indianópolis', name: 'Indianópolis', label: 'Indianópolis' },
    { value: 'Ipiranga', name: 'Ipiranga', label: 'Ipiranga' },
    { value: 'Iporã', name: 'Iporã', label: 'Iporã' },
    {
      value: 'Iracema do Oeste',
      name: 'Iracema do Oeste',
      label: 'Iracema do Oeste',
    },
    { value: 'Irati', name: 'Irati', label: 'Irati' },
    { value: 'Iretama', name: 'Iretama', label: 'Iretama' },
    { value: 'Itaguajé', name: 'Itaguajé', label: 'Itaguajé' },
    { value: 'Itaipulândia', name: 'Itaipulândia', label: 'Itaipulândia' },
    { value: 'Itambaracá', name: 'Itambaracá', label: 'Itambaracá' },
    { value: 'Itambé', name: 'Itambé', label: 'Itambé' },
    {
      value: "Itapejara D'Oeste",
      name: "Itapejara D'Oeste",
      label: "Itapejara D'Oeste",
    },
    { value: 'Itaperuçu', name: 'Itaperuçu', label: 'Itaperuçu' },
    { value: 'Itaúna do Sul', name: 'Itaúna do Sul', label: 'Itaúna do Sul' },
    { value: 'Ivaí', name: 'Ivaí', label: 'Ivaí' },
    { value: 'Ivaiporã', name: 'Ivaiporã', label: 'Ivaiporã' },
    { value: 'Ivaté', name: 'Ivaté', label: 'Ivaté' },
    { value: 'Ivatuba', name: 'Ivatuba', label: 'Ivatuba' },
    { value: 'Jaboti', name: 'Jaboti', label: 'Jaboti' },
    { value: 'Jacarezinho', name: 'Jacarezinho', label: 'Jacarezinho' },
    { value: 'Jaguapitã', name: 'Jaguapitã', label: 'Jaguapitã' },
    { value: 'Jaguariaíva', name: 'Jaguariaíva', label: 'Jaguariaíva' },
    {
      value: 'Jandaia do Sul',
      name: 'Jandaia do Sul',
      label: 'Jandaia do Sul',
    },
    { value: 'Janiópolis', name: 'Janiópolis', label: 'Janiópolis' },
    { value: 'Japira', name: 'Japira', label: 'Japira' },
    { value: 'Japurá', name: 'Japurá', label: 'Japurá' },
    { value: 'Jardim Alegre', name: 'Jardim Alegre', label: 'Jardim Alegre' },
    { value: 'Jardim Olinda', name: 'Jardim Olinda', label: 'Jardim Olinda' },
    { value: 'Jataizinho', name: 'Jataizinho', label: 'Jataizinho' },
    { value: 'Jesuítas', name: 'Jesuítas', label: 'Jesuítas' },
    {
      value: 'Joaquim Távora',
      name: 'Joaquim Távora',
      label: 'Joaquim Távora',
    },
    {
      value: 'Jundiaí do Sul',
      name: 'Jundiaí do Sul',
      label: 'Jundiaí do Sul',
    },
    { value: 'Juranda', name: 'Juranda', label: 'Juranda' },
    { value: 'Jussara', name: 'Jussara', label: 'Jussara' },
    { value: 'Kaloré', name: 'Kaloré', label: 'Kaloré' },
    { value: 'Lapa', name: 'Lapa', label: 'Lapa' },
    { value: 'Laranjal', name: 'Laranjal', label: 'Laranjal' },
    {
      value: 'Laranjeiras do Sul',
      name: 'Laranjeiras do Sul',
      label: 'Laranjeiras do Sul',
    },
    { value: 'Leópolis', name: 'Leópolis', label: 'Leópolis' },
    { value: 'Lidianópolis', name: 'Lidianópolis', label: 'Lidianópolis' },
    { value: 'Lindoeste', name: 'Lindoeste', label: 'Lindoeste' },
    { value: 'Loanda', name: 'Loanda', label: 'Loanda' },
    { value: 'Lobato', name: 'Lobato', label: 'Lobato' },
    { value: 'Londrina', name: 'Londrina', label: 'Londrina' },
    { value: 'Luiziana', name: 'Luiziana', label: 'Luiziana' },
    { value: 'Lunardelli', name: 'Lunardelli', label: 'Lunardelli' },
    { value: 'Lupionópolis', name: 'Lupionópolis', label: 'Lupionópolis' },
    { value: 'Mallet', name: 'Mallet', label: 'Mallet' },
    { value: 'Mamborê', name: 'Mamborê', label: 'Mamborê' },
    { value: 'Mandaguaçu', name: 'Mandaguaçu', label: 'Mandaguaçu' },
    { value: 'Mandaguari', name: 'Mandaguari', label: 'Mandaguari' },
    { value: 'Mandirituba', name: 'Mandirituba', label: 'Mandirituba' },
    { value: 'Manfrinópolis', name: 'Manfrinópolis', label: 'Manfrinópolis' },
    { value: 'Mangueirinha', name: 'Mangueirinha', label: 'Mangueirinha' },
    { value: 'Manoel Ribas', name: 'Manoel Ribas', label: 'Manoel Ribas' },
    {
      value: 'Marechal Cândido Rondon',
      name: 'Marechal Cândido Rondon',
      label: 'Marechal Cândido Rondon',
    },
    { value: 'Maria Helena', name: 'Maria Helena', label: 'Maria Helena' },
    { value: 'Marialva', name: 'Marialva', label: 'Marialva' },
    {
      value: 'Marilândia do Sul',
      name: 'Marilândia do Sul',
      label: 'Marilândia do Sul',
    },
    { value: 'Marilena', name: 'Marilena', label: 'Marilena' },
    { value: 'Mariluz', name: 'Mariluz', label: 'Mariluz' },
    { value: 'Maringá', name: 'Maringá', label: 'Maringá' },
    { value: 'Mariópolis', name: 'Mariópolis', label: 'Mariópolis' },
    { value: 'Maripá', name: 'Maripá', label: 'Maripá' },
    { value: 'Marmeleiro', name: 'Marmeleiro', label: 'Marmeleiro' },
    { value: 'Marquinho', name: 'Marquinho', label: 'Marquinho' },
    { value: 'Marumbi', name: 'Marumbi', label: 'Marumbi' },
    { value: 'Matelândia', name: 'Matelândia', label: 'Matelândia' },
    { value: 'Matinhos', name: 'Matinhos', label: 'Matinhos' },
    { value: 'Mato Rico', name: 'Mato Rico', label: 'Mato Rico' },
    { value: 'Mauá da Serra', name: 'Mauá da Serra', label: 'Mauá da Serra' },
    { value: 'Medianeira', name: 'Medianeira', label: 'Medianeira' },
    { value: 'Mercedes', name: 'Mercedes', label: 'Mercedes' },
    { value: 'Mirador', name: 'Mirador', label: 'Mirador' },
    { value: 'Miraselva', name: 'Miraselva', label: 'Miraselva' },
    { value: 'Missal', name: 'Missal', label: 'Missal' },
    { value: 'Moreira Sales', name: 'Moreira Sales', label: 'Moreira Sales' },
    { value: 'Morretes', name: 'Morretes', label: 'Morretes' },
    {
      value: 'Munhoz de Melo',
      name: 'Munhoz de Melo',
      label: 'Munhoz de Melo',
    },
    {
      value: 'Nossa Senhora das Graças',
      name: 'Nossa Senhora das Graças',
      label: 'Nossa Senhora das Graças',
    },
    {
      value: 'Nova Aliança do Ivaí',
      name: 'Nova Aliança do Ivaí',
      label: 'Nova Aliança do Ivaí',
    },
    {
      value: 'Nova América da Colina',
      name: 'Nova América da Colina',
      label: 'Nova América da Colina',
    },
    { value: 'Nova Aurora', name: 'Nova Aurora', label: 'Nova Aurora' },
    { value: 'Nova Cantu', name: 'Nova Cantu', label: 'Nova Cantu' },
    {
      value: 'Nova Esperança',
      name: 'Nova Esperança',
      label: 'Nova Esperança',
    },
    {
      value: 'Nova Esperança do Sudoeste',
      name: 'Nova Esperança do Sudoeste',
      label: 'Nova Esperança do Sudoeste',
    },
    { value: 'Nova Fátima', name: 'Nova Fátima', label: 'Nova Fátima' },
    {
      value: 'Nova Laranjeiras',
      name: 'Nova Laranjeiras',
      label: 'Nova Laranjeiras',
    },
    { value: 'Nova Londrina', name: 'Nova Londrina', label: 'Nova Londrina' },
    { value: 'Nova Olímpia', name: 'Nova Olímpia', label: 'Nova Olímpia' },
    {
      value: 'Nova Prata do Iguaçu',
      name: 'Nova Prata do Iguaçu',
      label: 'Nova Prata do Iguaçu',
    },
    {
      value: 'Nova Santa Bárbara',
      name: 'Nova Santa Bárbara',
      label: 'Nova Santa Bárbara',
    },
    {
      value: 'Nova Santa Rosa',
      name: 'Nova Santa Rosa',
      label: 'Nova Santa Rosa',
    },
    { value: 'Nova Tebas', name: 'Nova Tebas', label: 'Nova Tebas' },
    {
      value: 'Novo Itacolomi',
      name: 'Novo Itacolomi',
      label: 'Novo Itacolomi',
    },
    { value: 'Ortigueira', name: 'Ortigueira', label: 'Ortigueira' },
    { value: 'Ourizona', name: 'Ourizona', label: 'Ourizona' },
    {
      value: 'Ouro Verde do Oeste',
      name: 'Ouro Verde do Oeste',
      label: 'Ouro Verde do Oeste',
    },
    { value: 'Paiçandu', name: 'Paiçandu', label: 'Paiçandu' },
    { value: 'Palmas', name: 'Palmas', label: 'Palmas' },
    { value: 'Palmeira', name: 'Palmeira', label: 'Palmeira' },
    { value: 'Palmital', name: 'Palmital', label: 'Palmital' },
    { value: 'Palotina', name: 'Palotina', label: 'Palotina' },
    {
      value: 'Paraíso do Norte',
      name: 'Paraíso do Norte',
      label: 'Paraíso do Norte',
    },
    { value: 'Paranacity', name: 'Paranacity', label: 'Paranacity' },
    { value: 'Paranaguá', name: 'Paranaguá', label: 'Paranaguá' },
    { value: 'Paranapoema', name: 'Paranapoema', label: 'Paranapoema' },
    { value: 'Paranavaí', name: 'Paranavaí', label: 'Paranavaí' },
    { value: 'Pato Bragado', name: 'Pato Bragado', label: 'Pato Bragado' },
    { value: 'Pato Branco', name: 'Pato Branco', label: 'Pato Branco' },
    { value: 'Paula Freitas', name: 'Paula Freitas', label: 'Paula Freitas' },
    { value: 'Paulo Frontin', name: 'Paulo Frontin', label: 'Paulo Frontin' },
    { value: 'Peabiru', name: 'Peabiru', label: 'Peabiru' },
    { value: 'Perobal', name: 'Perobal', label: 'Perobal' },
    { value: 'Pérola', name: 'Pérola', label: 'Pérola' },
    {
      value: "Pérola D'Oeste",
      name: "Pérola D'Oeste",
      label: "Pérola D'Oeste",
    },
    { value: 'Piên', name: 'Piên', label: 'Piên' },
    { value: 'Pinhais', name: 'Pinhais', label: 'Pinhais' },
    {
      value: 'Pinhal de São Bento',
      name: 'Pinhal de São Bento',
      label: 'Pinhal de São Bento',
    },
    { value: 'Pinhalão', name: 'Pinhalão', label: 'Pinhalão' },
    { value: 'Pinhão', name: 'Pinhão', label: 'Pinhão' },
    { value: 'Piraí do Sul', name: 'Piraí do Sul', label: 'Piraí do Sul' },
    { value: 'Piraquara', name: 'Piraquara', label: 'Piraquara' },
    { value: 'Pitanga', name: 'Pitanga', label: 'Pitanga' },
    { value: 'Pitangueiras', name: 'Pitangueiras', label: 'Pitangueiras' },
    {
      value: 'Planaltina do Paraná',
      name: 'Planaltina do Paraná',
      label: 'Planaltina do Paraná',
    },
    { value: 'Planalto', name: 'Planalto', label: 'Planalto' },
    { value: 'Ponta Grossa', name: 'Ponta Grossa', label: 'Ponta Grossa' },
    {
      value: 'Pontal do Paraná',
      name: 'Pontal do Paraná',
      label: 'Pontal do Paraná',
    },
    { value: 'Porecatu', name: 'Porecatu', label: 'Porecatu' },
    {
      value: 'Porto Amazonas',
      name: 'Porto Amazonas',
      label: 'Porto Amazonas',
    },
    {
      value: 'Porto Barreiro',
      name: 'Porto Barreiro',
      label: 'Porto Barreiro',
    },
    { value: 'Porto Rico', name: 'Porto Rico', label: 'Porto Rico' },
    { value: 'Porto Vitória', name: 'Porto Vitória', label: 'Porto Vitória' },
    {
      value: 'Prado Ferreira',
      name: 'Prado Ferreira',
      label: 'Prado Ferreira',
    },
    { value: 'Pranchita', name: 'Pranchita', label: 'Pranchita' },
    {
      value: 'Presidente Castelo Branco',
      name: 'Presidente Castelo Branco',
      label: 'Presidente Castelo Branco',
    },
    {
      value: 'Primeiro de Maio',
      name: 'Primeiro de Maio',
      label: 'Primeiro de Maio',
    },
    { value: 'Prudentópolis', name: 'Prudentópolis', label: 'Prudentópolis' },
    {
      value: 'Quarto Centenário',
      name: 'Quarto Centenário',
      label: 'Quarto Centenário',
    },
    { value: 'Quatiguá', name: 'Quatiguá', label: 'Quatiguá' },
    { value: 'Quatro Barras', name: 'Quatro Barras', label: 'Quatro Barras' },
    { value: 'Quatro Pontes', name: 'Quatro Pontes', label: 'Quatro Pontes' },
    {
      value: 'Quedas do Iguaçu',
      name: 'Quedas do Iguaçu',
      label: 'Quedas do Iguaçu',
    },
    {
      value: 'Querência do Norte',
      name: 'Querência do Norte',
      label: 'Querência do Norte',
    },
    { value: 'Quinta do Sol', name: 'Quinta do Sol', label: 'Quinta do Sol' },
    { value: 'Quitandinha', name: 'Quitandinha', label: 'Quitandinha' },
    { value: 'Ramilândia', name: 'Ramilândia', label: 'Ramilândia' },
    { value: 'Rancho Alegre', name: 'Rancho Alegre', label: 'Rancho Alegre' },
    {
      value: "Rancho Alegre D'Oeste",
      name: "Rancho Alegre D'Oeste",
      label: "Rancho Alegre D'Oeste",
    },
    { value: 'Realeza', name: 'Realeza', label: 'Realeza' },
    { value: 'Rebouças', name: 'Rebouças', label: 'Rebouças' },
    { value: 'Renascença', name: 'Renascença', label: 'Renascença' },
    { value: 'Reserva', name: 'Reserva', label: 'Reserva' },
    {
      value: 'Reserva do Iguaçu',
      name: 'Reserva do Iguaçu',
      label: 'Reserva do Iguaçu',
    },
    {
      value: 'Ribeirão Claro',
      name: 'Ribeirão Claro',
      label: 'Ribeirão Claro',
    },
    {
      value: 'Ribeirão do Pinhal',
      name: 'Ribeirão do Pinhal',
      label: 'Ribeirão do Pinhal',
    },
    { value: 'Rio Azul', name: 'Rio Azul', label: 'Rio Azul' },
    { value: 'Rio Bom', name: 'Rio Bom', label: 'Rio Bom' },
    {
      value: 'Rio Bonito do Iguaçu',
      name: 'Rio Bonito do Iguaçu',
      label: 'Rio Bonito do Iguaçu',
    },
    {
      value: 'Rio Branco do Ivaí',
      name: 'Rio Branco do Ivaí',
      label: 'Rio Branco do Ivaí',
    },
    {
      value: 'Rio Branco do Sul',
      name: 'Rio Branco do Sul',
      label: 'Rio Branco do Sul',
    },
    { value: 'Rio Negro', name: 'Rio Negro', label: 'Rio Negro' },
    { value: 'Rolândia', name: 'Rolândia', label: 'Rolândia' },
    { value: 'Roncador', name: 'Roncador', label: 'Roncador' },
    { value: 'Rondon', name: 'Rondon', label: 'Rondon' },
    {
      value: 'Rosário do Ivaí',
      name: 'Rosário do Ivaí',
      label: 'Rosário do Ivaí',
    },
    { value: 'Sabáudia', name: 'Sabáudia', label: 'Sabáudia' },
    { value: 'Salgado Filho', name: 'Salgado Filho', label: 'Salgado Filho' },
    {
      value: 'Salto do Itararé',
      name: 'Salto do Itararé',
      label: 'Salto do Itararé',
    },
    {
      value: 'Salto do Lontra',
      name: 'Salto do Lontra',
      label: 'Salto do Lontra',
    },
    { value: 'Santa Amélia', name: 'Santa Amélia', label: 'Santa Amélia' },
    {
      value: 'Santa Cecília do Pavão',
      name: 'Santa Cecília do Pavão',
      label: 'Santa Cecília do Pavão',
    },
    {
      value: 'Santa Cruz Monte Castelo',
      name: 'Santa Cruz Monte Castelo',
      label: 'Santa Cruz Monte Castelo',
    },
    { value: 'Santa Fé', name: 'Santa Fé', label: 'Santa Fé' },
    { value: 'Santa Helena', name: 'Santa Helena', label: 'Santa Helena' },
    { value: 'Santa Inês', name: 'Santa Inês', label: 'Santa Inês' },
    {
      value: 'Santa Isabel do Ivaí',
      name: 'Santa Isabel do Ivaí',
      label: 'Santa Isabel do Ivaí',
    },
    {
      value: 'Santa Izabel do Oeste',
      name: 'Santa Izabel do Oeste',
      label: 'Santa Izabel do Oeste',
    },
    { value: 'Santa Lúcia', name: 'Santa Lúcia', label: 'Santa Lúcia' },
    {
      value: 'Santa Maria do Oeste',
      name: 'Santa Maria do Oeste',
      label: 'Santa Maria do Oeste',
    },
    { value: 'Santa Mariana', name: 'Santa Mariana', label: 'Santa Mariana' },
    { value: 'Santa Mônica', name: 'Santa Mônica', label: 'Santa Mônica' },
    {
      value: 'Santa Tereza do Oeste',
      name: 'Santa Tereza do Oeste',
      label: 'Santa Tereza do Oeste',
    },
    {
      value: 'Santa Terezinha de Itaipu',
      name: 'Santa Terezinha de Itaipu',
      label: 'Santa Terezinha de Itaipu',
    },
    {
      value: 'Santana do Itararé',
      name: 'Santana do Itararé',
      label: 'Santana do Itararé',
    },
    {
      value: 'Santo Antônio da Platina',
      name: 'Santo Antônio da Platina',
      label: 'Santo Antônio da Platina',
    },
    {
      value: 'Santo Antônio do Caiuá',
      name: 'Santo Antônio do Caiuá',
      label: 'Santo Antônio do Caiuá',
    },
    {
      value: 'Santo Antônio do Paraíso',
      name: 'Santo Antônio do Paraíso',
      label: 'Santo Antônio do Paraíso',
    },
    {
      value: 'Santo Antônio do Sudoeste',
      name: 'Santo Antônio do Sudoeste',
      label: 'Santo Antônio do Sudoeste',
    },
    { value: 'Santo Inácio', name: 'Santo Inácio', label: 'Santo Inácio' },
    {
      value: 'São Carlos do Ivaí',
      name: 'São Carlos do Ivaí',
      label: 'São Carlos do Ivaí',
    },
    {
      value: 'São Jerônimo da Serra',
      name: 'São Jerônimo da Serra',
      label: 'São Jerônimo da Serra',
    },
    { value: 'São João', name: 'São João', label: 'São João' },
    {
      value: 'São João do Caiuá',
      name: 'São João do Caiuá',
      label: 'São João do Caiuá',
    },
    {
      value: 'São João do Ivaí',
      name: 'São João do Ivaí',
      label: 'São João do Ivaí',
    },
    {
      value: 'São João do Triunfo',
      name: 'São João do Triunfo',
      label: 'São João do Triunfo',
    },
    {
      value: "São Jorge D'Oeste",
      name: "São Jorge D'Oeste",
      label: "São Jorge D'Oeste",
    },
    {
      value: 'São Jorge do Ivaí',
      name: 'São Jorge do Ivaí',
      label: 'São Jorge do Ivaí',
    },
    {
      value: 'São Jorge do Patrocínio',
      name: 'São Jorge do Patrocínio',
      label: 'São Jorge do Patrocínio',
    },
    {
      value: 'São José da Boa Vista',
      name: 'São José da Boa Vista',
      label: 'São José da Boa Vista',
    },
    {
      value: 'São José das Palmeiras',
      name: 'São José das Palmeiras',
      label: 'São José das Palmeiras',
    },
    {
      value: 'São José dos Pinhais',
      name: 'São José dos Pinhais',
      label: 'São José dos Pinhais',
    },
    {
      value: 'São Manoel do Paraná',
      name: 'São Manoel do Paraná',
      label: 'São Manoel do Paraná',
    },
    {
      value: 'São Mateus do Sul',
      name: 'São Mateus do Sul',
      label: 'São Mateus do Sul',
    },
    {
      value: 'São Miguel do Iguaçu',
      name: 'São Miguel do Iguaçu',
      label: 'São Miguel do Iguaçu',
    },
    {
      value: 'São Pedro do Iguaçu',
      name: 'São Pedro do Iguaçu',
      label: 'São Pedro do Iguaçu',
    },
    {
      value: 'São Pedro do Ivaí',
      name: 'São Pedro do Ivaí',
      label: 'São Pedro do Ivaí',
    },
    {
      value: 'São Pedro do Paraná',
      name: 'São Pedro do Paraná',
      label: 'São Pedro do Paraná',
    },
    {
      value: 'São Sebastião da Amoreira',
      name: 'São Sebastião da Amoreira',
      label: 'São Sebastião da Amoreira',
    },
    { value: 'São Tomé', name: 'São Tomé', label: 'São Tomé' },
    { value: 'Sapopema', name: 'Sapopema', label: 'Sapopema' },
    { value: 'Sarandi', name: 'Sarandi', label: 'Sarandi' },
    {
      value: 'Saudade do Iguaçu',
      name: 'Saudade do Iguaçu',
      label: 'Saudade do Iguaçu',
    },
    { value: 'Sengés', name: 'Sengés', label: 'Sengés' },
    {
      value: 'Serranópolis do Iguaçu',
      name: 'Serranópolis do Iguaçu',
      label: 'Serranópolis do Iguaçu',
    },
    { value: 'Sertaneja', name: 'Sertaneja', label: 'Sertaneja' },
    { value: 'Sertanópolis', name: 'Sertanópolis', label: 'Sertanópolis' },
    {
      value: 'Siqueira Campos',
      name: 'Siqueira Campos',
      label: 'Siqueira Campos',
    },
    { value: 'Sulina', name: 'Sulina', label: 'Sulina' },
    { value: 'Tamarana', name: 'Tamarana', label: 'Tamarana' },
    { value: 'Tamboara', name: 'Tamboara', label: 'Tamboara' },
    { value: 'Tapejara', name: 'Tapejara', label: 'Tapejara' },
    { value: 'Tapira', name: 'Tapira', label: 'Tapira' },
    {
      value: 'Teixeira Soares',
      name: 'Teixeira Soares',
      label: 'Teixeira Soares',
    },
    {
      value: 'Telêmaco Borba',
      name: 'Telêmaco Borba',
      label: 'Telêmaco Borba',
    },
    { value: 'Terra Boa', name: 'Terra Boa', label: 'Terra Boa' },
    { value: 'Terra Rica', name: 'Terra Rica', label: 'Terra Rica' },
    { value: 'Terra Roxa', name: 'Terra Roxa', label: 'Terra Roxa' },
    { value: 'Tibagi', name: 'Tibagi', label: 'Tibagi' },
    {
      value: 'Tijucas do Sul',
      name: 'Tijucas do Sul',
      label: 'Tijucas do Sul',
    },
    { value: 'Toledo', name: 'Toledo', label: 'Toledo' },
    { value: 'Tomazina', name: 'Tomazina', label: 'Tomazina' },
    {
      value: 'Três Barras do Paraná',
      name: 'Três Barras do Paraná',
      label: 'Três Barras do Paraná',
    },
    {
      value: 'Tunas do Paraná',
      name: 'Tunas do Paraná',
      label: 'Tunas do Paraná',
    },
    {
      value: 'Tuneiras do Oeste',
      name: 'Tuneiras do Oeste',
      label: 'Tuneiras do Oeste',
    },
    { value: 'Tupãssi', name: 'Tupãssi', label: 'Tupãssi' },
    { value: 'Turvo', name: 'Turvo', label: 'Turvo' },
    { value: 'Ubiratã', name: 'Ubiratã', label: 'Ubiratã' },
    { value: 'Umuarama', name: 'Umuarama', label: 'Umuarama' },
    {
      value: 'União da Vitória',
      name: 'União da Vitória',
      label: 'União da Vitória',
    },
    { value: 'Uniflor', name: 'Uniflor', label: 'Uniflor' },
    { value: 'Uraí', name: 'Uraí', label: 'Uraí' },
    { value: 'Ventania', name: 'Ventania', label: 'Ventania' },
    {
      value: 'Vera Cruz do Oeste',
      name: 'Vera Cruz do Oeste',
      label: 'Vera Cruz do Oeste',
    },
    { value: 'Verê', name: 'Verê', label: 'Verê' },
    { value: 'Vila Alta', name: 'Vila Alta', label: 'Vila Alta' },
    { value: 'Virmond', name: 'Virmond', label: 'Virmond' },
    { value: 'Vitorino', name: 'Vitorino', label: 'Vitorino' },
    {
      value: 'Wenceslau Braz',
      name: 'Wenceslau Braz',
      label: 'Wenceslau Braz',
    },
    { value: 'Xambrê', name: 'Xambrê', label: 'Xambrê' },
  ],
  RJ: [
    {
      value: 'Angra dos Reis',
      name: 'Angra dos Reis',
      label: 'Angra dos Reis',
    },
    { value: 'Aperibé', name: 'Aperibé', label: 'Aperibé' },
    { value: 'Araruama', name: 'Araruama', label: 'Araruama' },
    { value: 'Areal', name: 'Areal', label: 'Areal' },
    {
      value: 'Armação dos Búzios',
      name: 'Armação dos Búzios',
      label: 'Armação dos Búzios',
    },
    {
      value: 'Arraial do Cabo',
      name: 'Arraial do Cabo',
      label: 'Arraial do Cabo',
    },
    {
      value: 'Barra do Piraí',
      name: 'Barra do Piraí',
      label: 'Barra do Piraí',
    },
    { value: 'Barra Mansa', name: 'Barra Mansa', label: 'Barra Mansa' },
    { value: 'Belford Roxo', name: 'Belford Roxo', label: 'Belford Roxo' },
    { value: 'Bom Jardim', name: 'Bom Jardim', label: 'Bom Jardim' },
    {
      value: 'Bom Jesus do Itabapoana',
      name: 'Bom Jesus do Itabapoana',
      label: 'Bom Jesus do Itabapoana',
    },
    { value: 'Cabo Frio', name: 'Cabo Frio', label: 'Cabo Frio' },
    {
      value: 'Cachoeiras de Macacu',
      name: 'Cachoeiras de Macacu',
      label: 'Cachoeiras de Macacu',
    },
    { value: 'Cambuci', name: 'Cambuci', label: 'Cambuci' },
    {
      value: 'Campos dos Goytacazes',
      name: 'Campos dos Goytacazes',
      label: 'Campos dos Goytacazes',
    },
    { value: 'Cantagalo', name: 'Cantagalo', label: 'Cantagalo' },
    { value: 'Carapebus', name: 'Carapebus', label: 'Carapebus' },
    {
      value: 'Cardoso Moreira',
      name: 'Cardoso Moreira',
      label: 'Cardoso Moreira',
    },
    { value: 'Carmo', name: 'Carmo', label: 'Carmo' },
    {
      value: 'Casimiro de Abreu',
      name: 'Casimiro de Abreu',
      label: 'Casimiro de Abreu',
    },
    {
      value: 'Comendador Levy Gasparian',
      name: 'Comendador Levy Gasparian',
      label: 'Comendador Levy Gasparian',
    },
    {
      value: 'Conceição de Macabu',
      name: 'Conceição de Macabu',
      label: 'Conceição de Macabu',
    },
    { value: 'Cordeiro', name: 'Cordeiro', label: 'Cordeiro' },
    { value: 'Duas Barras', name: 'Duas Barras', label: 'Duas Barras' },
    {
      value: 'Duque de Caxias',
      name: 'Duque de Caxias',
      label: 'Duque de Caxias',
    },
    {
      value: 'Engenheiro Paulo de Frontin',
      name: 'Engenheiro Paulo de Frontin',
      label: 'Engenheiro Paulo de Frontin',
    },
    { value: 'Guapimirim', name: 'Guapimirim', label: 'Guapimirim' },
    { value: 'Iguaba Grande', name: 'Iguaba Grande', label: 'Iguaba Grande' },
    { value: 'Itaboraí', name: 'Itaboraí', label: 'Itaboraí' },
    { value: 'Itaguaí', name: 'Itaguaí', label: 'Itaguaí' },
    { value: 'Italva', name: 'Italva', label: 'Italva' },
    { value: 'Itaocara', name: 'Itaocara', label: 'Itaocara' },
    { value: 'Itaperuna', name: 'Itaperuna', label: 'Itaperuna' },
    { value: 'Itatiaia', name: 'Itatiaia', label: 'Itatiaia' },
    { value: 'Japeri', name: 'Japeri', label: 'Japeri' },
    {
      value: 'Laje do Muriaé',
      name: 'Laje do Muriaé',
      label: 'Laje do Muriaé',
    },
    { value: 'Macaé', name: 'Macaé', label: 'Macaé' },
    { value: 'Macuco', name: 'Macuco', label: 'Macuco' },
    { value: 'Magé', name: 'Magé', label: 'Magé' },
    { value: 'Mangaratiba', name: 'Mangaratiba', label: 'Mangaratiba' },
    { value: 'Maricá', name: 'Maricá', label: 'Maricá' },
    { value: 'Mendes', name: 'Mendes', label: 'Mendes' },
    { value: 'Mesquita', name: 'Mesquita', label: 'Mesquita' },
    {
      value: 'Miguel Pereira',
      name: 'Miguel Pereira',
      label: 'Miguel Pereira',
    },
    { value: 'Miracema', name: 'Miracema', label: 'Miracema' },
    { value: 'Natividade', name: 'Natividade', label: 'Natividade' },
    { value: 'Nilópolis', name: 'Nilópolis', label: 'Nilópolis' },
    { value: 'Niterói', name: 'Niterói', label: 'Niterói' },
    { value: 'Nova Friburgo', name: 'Nova Friburgo', label: 'Nova Friburgo' },
    { value: 'Nova Iguaçu', name: 'Nova Iguaçu', label: 'Nova Iguaçu' },
    { value: 'Papucaia', name: 'Papucaia', label: 'Papucaia' },
    { value: 'Paracambi', name: 'Paracambi', label: 'Paracambi' },
    {
      value: 'Paraíba do Sul',
      name: 'Paraíba do Sul',
      label: 'Paraíba do Sul',
    },
    { value: 'Paraty', name: 'Paraty', label: 'Paraty' },
    {
      value: 'Paty do Alferes',
      name: 'Paty do Alferes',
      label: 'Paty do Alferes',
    },
    { value: 'Petrópolis', name: 'Petrópolis', label: 'Petrópolis' },
    { value: 'Pinheiral', name: 'Pinheiral', label: 'Pinheiral' },
    { value: 'Piraí', name: 'Piraí', label: 'Piraí' },
    { value: 'Porciúncula', name: 'Porciúncula', label: 'Porciúncula' },
    { value: 'Porto Real', name: 'Porto Real', label: 'Porto Real' },
    { value: 'Quatis', name: 'Quatis', label: 'Quatis' },
    { value: 'Queimados', name: 'Queimados', label: 'Queimados' },
    { value: 'Quissamã', name: 'Quissamã', label: 'Quissamã' },
    { value: 'Resende', name: 'Resende', label: 'Resende' },
    { value: 'Rio Bonito', name: 'Rio Bonito', label: 'Rio Bonito' },
    { value: 'Rio Claro', name: 'Rio Claro', label: 'Rio Claro' },
    {
      value: 'Rio das Flores',
      name: 'Rio das Flores',
      label: 'Rio das Flores',
    },
    {
      value: 'Rio das Ostras',
      name: 'Rio das Ostras',
      label: 'Rio das Ostras',
    },
    {
      value: 'Rio de Janeiro',
      name: 'Rio de Janeiro',
      label: 'Rio de Janeiro',
    },
    {
      value: 'Santa Maria Madalena',
      name: 'Santa Maria Madalena',
      label: 'Santa Maria Madalena',
    },
    {
      value: 'Santo Antônio de Pádua',
      name: 'Santo Antônio de Pádua',
      label: 'Santo Antônio de Pádua',
    },
    { value: 'São Fidélis', name: 'São Fidélis', label: 'São Fidélis' },
    {
      value: 'São Francisco de Itabapoana',
      name: 'São Francisco de Itabapoana',
      label: 'São Francisco de Itabapoana',
    },
    { value: 'São Gonçalo', name: 'São Gonçalo', label: 'São Gonçalo' },
    {
      value: 'São João da Barra',
      name: 'São João da Barra',
      label: 'São João da Barra',
    },
    {
      value: 'São João de Meriti',
      name: 'São João de Meriti',
      label: 'São João de Meriti',
    },
    {
      value: 'São José de Ubá',
      name: 'São José de Ubá',
      label: 'São José de Ubá',
    },
    {
      value: 'São José do Vale do Rio Preto',
      name: 'São José do Vale do Rio Preto',
      label: 'São José do Vale do Rio Preto',
    },
    {
      value: 'São Pedro da Aldeia',
      name: 'São Pedro da Aldeia',
      label: 'São Pedro da Aldeia',
    },
    {
      value: 'São Sebastião do Alto',
      name: 'São Sebastião do Alto',
      label: 'São Sebastião do Alto',
    },
    { value: 'Sapucaia', name: 'Sapucaia', label: 'Sapucaia' },
    { value: 'Saquarema', name: 'Saquarema', label: 'Saquarema' },
    { value: 'Seropédica', name: 'Seropédica', label: 'Seropédica' },
    { value: 'Silva Jardim', name: 'Silva Jardim', label: 'Silva Jardim' },
    { value: 'Sumidouro', name: 'Sumidouro', label: 'Sumidouro' },
    { value: 'Tanguá', name: 'Tanguá', label: 'Tanguá' },
    { value: 'Teresópolis', name: 'Teresópolis', label: 'Teresópolis' },
    {
      value: 'Trajano de Moraes',
      name: 'Trajano de Moraes',
      label: 'Trajano de Moraes',
    },
    { value: 'Três Rios', name: 'Três Rios', label: 'Três Rios' },
    { value: 'Valença', name: 'Valença', label: 'Valença' },
    { value: 'Varre-Sai', name: 'Varre-Sai', label: 'Varre-Sai' },
    { value: 'Vassouras', name: 'Vassouras', label: 'Vassouras' },
    { value: 'Volta Redonda', name: 'Volta Redonda', label: 'Volta Redonda' },
  ],
  RN: [
    { value: 'Acari', name: 'Acari', label: 'Acari' },
    { value: 'Açu', name: 'Açu', label: 'Açu' },
    {
      value: 'Afonso Bezerra',
      name: 'Afonso Bezerra',
      label: 'Afonso Bezerra',
    },
    { value: 'Água Nova', name: 'Água Nova', label: 'Água Nova' },
    { value: 'Alexandria', name: 'Alexandria', label: 'Alexandria' },
    { value: 'Almino Afonso', name: 'Almino Afonso', label: 'Almino Afonso' },
    {
      value: 'Alto do Rodrigues',
      name: 'Alto do Rodrigues',
      label: 'Alto do Rodrigues',
    },
    { value: 'Angicos', name: 'Angicos', label: 'Angicos' },
    {
      value: 'Antônio Martins',
      name: 'Antônio Martins',
      label: 'Antônio Martins',
    },
    { value: 'Apodi', name: 'Apodi', label: 'Apodi' },
    { value: 'Areia Branca', name: 'Areia Branca', label: 'Areia Branca' },
    { value: 'Arês', name: 'Arês', label: 'Arês' },
    {
      value: 'Augusto Severo',
      name: 'Augusto Severo',
      label: 'Augusto Severo',
    },
    { value: 'Baía Formosa', name: 'Baía Formosa', label: 'Baía Formosa' },
    { value: 'Baraúna', name: 'Baraúna', label: 'Baraúna' },
    { value: 'Barcelona', name: 'Barcelona', label: 'Barcelona' },
    {
      value: 'Bento Fernandes',
      name: 'Bento Fernandes',
      label: 'Bento Fernandes',
    },
    { value: 'Bodó', name: 'Bodó', label: 'Bodó' },
    { value: 'Bom Jesus', name: 'Bom Jesus', label: 'Bom Jesus' },
    { value: 'Brejinho', name: 'Brejinho', label: 'Brejinho' },
    {
      value: 'Caiçara do Norte',
      name: 'Caiçara do Norte',
      label: 'Caiçara do Norte',
    },
    {
      value: 'Caiçara do Rio do Vento',
      name: 'Caiçara do Rio do Vento',
      label: 'Caiçara do Rio do Vento',
    },
    { value: 'Caicó', name: 'Caicó', label: 'Caicó' },
    { value: 'Campo Redondo', name: 'Campo Redondo', label: 'Campo Redondo' },
    { value: 'Canguaretama', name: 'Canguaretama', label: 'Canguaretama' },
    { value: 'Caraúbas', name: 'Caraúbas', label: 'Caraúbas' },
    {
      value: 'Carnaúba dos Dantas',
      name: 'Carnaúba dos Dantas',
      label: 'Carnaúba dos Dantas',
    },
    { value: 'Carnaubais', name: 'Carnaubais', label: 'Carnaubais' },
    { value: 'Ceará-Mirim', name: 'Ceará-Mirim', label: 'Ceará-Mirim' },
    { value: 'Cerro Corá', name: 'Cerro Corá', label: 'Cerro Corá' },
    {
      value: 'Coronel Ezequiel',
      name: 'Coronel Ezequiel',
      label: 'Coronel Ezequiel',
    },
    {
      value: 'Coronel João Pessoa',
      name: 'Coronel João Pessoa',
      label: 'Coronel João Pessoa',
    },
    { value: 'Cruzeta', name: 'Cruzeta', label: 'Cruzeta' },
    { value: 'Currais Novos', name: 'Currais Novos', label: 'Currais Novos' },
    {
      value: 'Doutor Severiano',
      name: 'Doutor Severiano',
      label: 'Doutor Severiano',
    },
    { value: 'Encanto', name: 'Encanto', label: 'Encanto' },
    { value: 'Equador', name: 'Equador', label: 'Equador' },
    {
      value: 'Espírito Santo',
      name: 'Espírito Santo',
      label: 'Espírito Santo',
    },
    { value: 'Extremoz', name: 'Extremoz', label: 'Extremoz' },
    { value: 'Felipe Guerra', name: 'Felipe Guerra', label: 'Felipe Guerra' },
    {
      value: 'Fernando Pedroza',
      name: 'Fernando Pedroza',
      label: 'Fernando Pedroza',
    },
    { value: 'Florânia', name: 'Florânia', label: 'Florânia' },
    {
      value: 'Francisco Dantas',
      name: 'Francisco Dantas',
      label: 'Francisco Dantas',
    },
    {
      value: 'Frutuoso Gomes',
      name: 'Frutuoso Gomes',
      label: 'Frutuoso Gomes',
    },
    { value: 'Galinhos', name: 'Galinhos', label: 'Galinhos' },
    { value: 'Goianinha', name: 'Goianinha', label: 'Goianinha' },
    {
      value: 'Governador Dix-Sept Rosado',
      name: 'Governador Dix-Sept Rosado',
      label: 'Governador Dix-Sept Rosado',
    },
    { value: 'Grossos', name: 'Grossos', label: 'Grossos' },
    { value: 'Guamaré', name: 'Guamaré', label: 'Guamaré' },
    { value: 'Ielmo Marinho', name: 'Ielmo Marinho', label: 'Ielmo Marinho' },
    { value: 'Ipanguaçu', name: 'Ipanguaçu', label: 'Ipanguaçu' },
    { value: 'Ipueira', name: 'Ipueira', label: 'Ipueira' },
    { value: 'Itajá', name: 'Itajá', label: 'Itajá' },
    { value: 'Itaú', name: 'Itaú', label: 'Itaú' },
    { value: 'Jaçanã', name: 'Jaçanã', label: 'Jaçanã' },
    { value: 'Jandaíra', name: 'Jandaíra', label: 'Jandaíra' },
    { value: 'Janduís', name: 'Janduís', label: 'Janduís' },
    {
      value: 'Januário Cicco',
      name: 'Januário Cicco',
      label: 'Januário Cicco',
    },
    { value: 'Japi', name: 'Japi', label: 'Japi' },
    {
      value: 'Jardim de Angicos',
      name: 'Jardim de Angicos',
      label: 'Jardim de Angicos',
    },
    {
      value: 'Jardim de Piranhas',
      name: 'Jardim de Piranhas',
      label: 'Jardim de Piranhas',
    },
    {
      value: 'Jardim do Seridó',
      name: 'Jardim do Seridó',
      label: 'Jardim do Seridó',
    },
    { value: 'João Câmara', name: 'João Câmara', label: 'João Câmara' },
    { value: 'João Dias', name: 'João Dias', label: 'João Dias' },
    { value: 'José da Penha', name: 'José da Penha', label: 'José da Penha' },
    { value: 'Jucurutu', name: 'Jucurutu', label: 'Jucurutu' },
    { value: 'Jundiá', name: 'Jundiá', label: 'Jundiá' },
    { value: "Lagoa D'Anta", name: "Lagoa D'Anta", label: "Lagoa D'Anta" },
    {
      value: 'Lagoa de Pedras',
      name: 'Lagoa de Pedras',
      label: 'Lagoa de Pedras',
    },
    {
      value: 'Lagoa de Velhos',
      name: 'Lagoa de Velhos',
      label: 'Lagoa de Velhos',
    },
    { value: 'Lagoa Nova', name: 'Lagoa Nova', label: 'Lagoa Nova' },
    { value: 'Lagoa Salgada', name: 'Lagoa Salgada', label: 'Lagoa Salgada' },
    { value: 'Lajes', name: 'Lajes', label: 'Lajes' },
    {
      value: 'Lajes Pintadas',
      name: 'Lajes Pintadas',
      label: 'Lajes Pintadas',
    },
    { value: 'Lucrécia', name: 'Lucrécia', label: 'Lucrécia' },
    { value: 'Luís Gomes', name: 'Luís Gomes', label: 'Luís Gomes' },
    { value: 'Macaíba', name: 'Macaíba', label: 'Macaíba' },
    { value: 'Macau', name: 'Macau', label: 'Macau' },
    { value: 'Major Sales', name: 'Major Sales', label: 'Major Sales' },
    {
      value: 'Marcelino Vieira',
      name: 'Marcelino Vieira',
      label: 'Marcelino Vieira',
    },
    { value: 'Martins', name: 'Martins', label: 'Martins' },
    { value: 'Maxaranguape', name: 'Maxaranguape', label: 'Maxaranguape' },
    {
      value: 'Messias Targino',
      name: 'Messias Targino',
      label: 'Messias Targino',
    },
    { value: 'Montanhas', name: 'Montanhas', label: 'Montanhas' },
    { value: 'Monte Alegre', name: 'Monte Alegre', label: 'Monte Alegre' },
    {
      value: 'Monte das Gameleiras',
      name: 'Monte das Gameleiras',
      label: 'Monte das Gameleiras',
    },
    { value: 'Mossoró', name: 'Mossoró', label: 'Mossoró' },
    { value: 'Natal', name: 'Natal', label: 'Natal' },
    {
      value: 'Nísia Floresta',
      name: 'Nísia Floresta',
      label: 'Nísia Floresta',
    },
    { value: 'Nova Cruz', name: 'Nova Cruz', label: 'Nova Cruz' },
    {
      value: "Olho-D'Água do Borges",
      name: "Olho-D'Água do Borges",
      label: "Olho-D'Água do Borges",
    },
    { value: 'Ouro Branco', name: 'Ouro Branco', label: 'Ouro Branco' },
    { value: 'Paraná', name: 'Paraná', label: 'Paraná' },
    { value: 'Paraú', name: 'Paraú', label: 'Paraú' },
    { value: 'Parazinho', name: 'Parazinho', label: 'Parazinho' },
    { value: 'Parelhas', name: 'Parelhas', label: 'Parelhas' },
    { value: 'Parnamirim', name: 'Parnamirim', label: 'Parnamirim' },
    { value: 'Passa e Fica', name: 'Passa e Fica', label: 'Passa e Fica' },
    { value: 'Passagem', name: 'Passagem', label: 'Passagem' },
    { value: 'Patu', name: 'Patu', label: 'Patu' },
    {
      value: 'Pau dos Ferros',
      name: 'Pau dos Ferros',
      label: 'Pau dos Ferros',
    },
    { value: 'Pedra Grande', name: 'Pedra Grande', label: 'Pedra Grande' },
    { value: 'Pedra Preta', name: 'Pedra Preta', label: 'Pedra Preta' },
    { value: 'Pedro Avelino', name: 'Pedro Avelino', label: 'Pedro Avelino' },
    { value: 'Pedro Velho', name: 'Pedro Velho', label: 'Pedro Velho' },
    { value: 'Pendências', name: 'Pendências', label: 'Pendências' },
    { value: 'Pilões', name: 'Pilões', label: 'Pilões' },
    { value: 'Poço Branco', name: 'Poço Branco', label: 'Poço Branco' },
    { value: 'Portalegre', name: 'Portalegre', label: 'Portalegre' },
    {
      value: 'Porto do Mangue',
      name: 'Porto do Mangue',
      label: 'Porto do Mangue',
    },
    {
      value: 'Presidente Juscelino',
      name: 'Presidente Juscelino',
      label: 'Presidente Juscelino',
    },
    { value: 'Pureza', name: 'Pureza', label: 'Pureza' },
    {
      value: 'Rafael Fernandes',
      name: 'Rafael Fernandes',
      label: 'Rafael Fernandes',
    },
    {
      value: 'Rafael Godeiro',
      name: 'Rafael Godeiro',
      label: 'Rafael Godeiro',
    },
    {
      value: 'Riacho da Cruz',
      name: 'Riacho da Cruz',
      label: 'Riacho da Cruz',
    },
    {
      value: 'Riacho de Santana',
      name: 'Riacho de Santana',
      label: 'Riacho de Santana',
    },
    { value: 'Riachuelo', name: 'Riachuelo', label: 'Riachuelo' },
    { value: 'Rio do Fogo', name: 'Rio do Fogo', label: 'Rio do Fogo' },
    {
      value: 'Rodolfo Fernandes',
      name: 'Rodolfo Fernandes',
      label: 'Rodolfo Fernandes',
    },
    { value: 'Ruy Barbosa', name: 'Ruy Barbosa', label: 'Ruy Barbosa' },
    { value: 'Santa Cruz', name: 'Santa Cruz', label: 'Santa Cruz' },
    { value: 'Santa Maria', name: 'Santa Maria', label: 'Santa Maria' },
    {
      value: 'Santana do Matos',
      name: 'Santana do Matos',
      label: 'Santana do Matos',
    },
    {
      value: 'Santana do Seridó',
      name: 'Santana do Seridó',
      label: 'Santana do Seridó',
    },
    { value: 'Santo Antônio', name: 'Santo Antônio', label: 'Santo Antônio' },
    {
      value: 'São Bento do Norte',
      name: 'São Bento do Norte',
      label: 'São Bento do Norte',
    },
    {
      value: 'São Bento do Trairí',
      name: 'São Bento do Trairí',
      label: 'São Bento do Trairí',
    },
    { value: 'São Fernando', name: 'São Fernando', label: 'São Fernando' },
    {
      value: 'São Francisco do Oeste',
      name: 'São Francisco do Oeste',
      label: 'São Francisco do Oeste',
    },
    {
      value: 'São Gonçalo do Amarante',
      name: 'São Gonçalo do Amarante',
      label: 'São Gonçalo do Amarante',
    },
    {
      value: 'São João do Sabugi',
      name: 'São João do Sabugi',
      label: 'São João do Sabugi',
    },
    {
      value: 'São José de Mipibu',
      name: 'São José de Mipibu',
      label: 'São José de Mipibu',
    },
    {
      value: 'São José do Campestre',
      name: 'São José do Campestre',
      label: 'São José do Campestre',
    },
    {
      value: 'São José do Seridó',
      name: 'São José do Seridó',
      label: 'São José do Seridó',
    },
    { value: 'São Miguel', name: 'São Miguel', label: 'São Miguel' },
    {
      value: 'São Miguel do Gostoso',
      name: 'São Miguel do Gostoso',
      label: 'São Miguel do Gostoso',
    },
    {
      value: 'São Miguel de Touros',
      name: 'São Miguel de Touros',
      label: 'São Miguel de Touros',
    },
    {
      value: 'São Paulo do Potengi',
      name: 'São Paulo do Potengi',
      label: 'São Paulo do Potengi',
    },
    { value: 'São Pedro', name: 'São Pedro', label: 'São Pedro' },
    { value: 'São Rafael', name: 'São Rafael', label: 'São Rafael' },
    { value: 'São Tomé', name: 'São Tomé', label: 'São Tomé' },
    { value: 'São Vicente', name: 'São Vicente', label: 'São Vicente' },
    {
      value: 'Senador Elói de Souza',
      name: 'Senador Elói de Souza',
      label: 'Senador Elói de Souza',
    },
    {
      value: 'Senador Georgino Avelino',
      name: 'Senador Georgino Avelino',
      label: 'Senador Georgino Avelino',
    },
    {
      value: 'Serra Caiada',
      name: 'Serra Caiada',
      label: 'Serra Caiada',
    },
    {
      value: 'Serra de São Bento',
      name: 'Serra de São Bento',
      label: 'Serra de São Bento',
    },
    { value: 'Serra do Mel', name: 'Serra do Mel', label: 'Serra do Mel' },
    {
      value: 'Serra Negra do Norte',
      name: 'Serra Negra do Norte',
      label: 'Serra Negra do Norte',
    },
    { value: 'Serrinha', name: 'Serrinha', label: 'Serrinha' },
    {
      value: 'Serrinha dos Pintos',
      name: 'Serrinha dos Pintos',
      label: 'Serrinha dos Pintos',
    },
    {
      value: 'Severiano Melo',
      name: 'Severiano Melo',
      label: 'Severiano Melo',
    },
    { value: 'Sítio Novo', name: 'Sítio Novo', label: 'Sítio Novo' },
    {
      value: 'Taboleiro Grande',
      name: 'Taboleiro Grande',
      label: 'Taboleiro Grande',
    },
    { value: 'Taipu', name: 'Taipu', label: 'Taipu' },
    { value: 'Tangará', name: 'Tangará', label: 'Tangará' },
    {
      value: 'Tenente Ananias',
      name: 'Tenente Ananias',
      label: 'Tenente Ananias',
    },
    {
      value: 'Tenente Laurentino Cruz',
      name: 'Tenente Laurentino Cruz',
      label: 'Tenente Laurentino Cruz',
    },
    { value: 'Tibau', name: 'Tibau', label: 'Tibau' },
    { value: 'Tibau do Sul', name: 'Tibau do Sul', label: 'Tibau do Sul' },
    {
      value: 'Timbaúba dos Batistas',
      name: 'Timbaúba dos Batistas',
      label: 'Timbaúba dos Batistas',
    },
    { value: 'Touros', name: 'Touros', label: 'Touros' },
    {
      value: 'Triunfo Potiguar',
      name: 'Triunfo Potiguar',
      label: 'Triunfo Potiguar',
    },
    { value: 'Umarizal', name: 'Umarizal', label: 'Umarizal' },
    { value: 'Upanema', name: 'Upanema', label: 'Upanema' },
    { value: 'Várzea', name: 'Várzea', label: 'Várzea' },
    { value: 'Venha-Ver', name: 'Venha-Ver', label: 'Venha-Ver' },
    { value: 'Vera Cruz', name: 'Vera Cruz', label: 'Vera Cruz' },
    { value: 'Viçosa', name: 'Viçosa', label: 'Viçosa' },
    { value: 'Vila Flor', name: 'Vila Flor', label: 'Vila Flor' },
  ],
  RO: [
    {
      value: "Alta Floresta D'Oeste",
      name: "Alta Floresta D'Oeste",
      label: "Alta Floresta D'Oeste",
    },
    {
      value: 'Alto Alegre do Parecis',
      name: 'Alto Alegre do Parecis',
      label: 'Alto Alegre do Parecis',
    },
    { value: 'Alto Paraíso', name: 'Alto Paraíso', label: 'Alto Paraíso' },
    {
      value: "Alvorada D'Oeste",
      name: "Alvorada D'Oeste",
      label: "Alvorada D'Oeste",
    },
    { value: 'Ariquemes', name: 'Ariquemes', label: 'Ariquemes' },
    { value: 'Buritis', name: 'Buritis', label: 'Buritis' },
    { value: 'Cabixi', name: 'Cabixi', label: 'Cabixi' },
    { value: 'Cacaulândia', name: 'Cacaulândia', label: 'Cacaulândia' },
    { value: 'Cacoal', name: 'Cacoal', label: 'Cacoal' },
    {
      value: 'Campo Novo de Rondônia',
      name: 'Campo Novo de Rondônia',
      label: 'Campo Novo de Rondônia',
    },
    {
      value: 'Candeias do Jamari',
      name: 'Candeias do Jamari',
      label: 'Candeias do Jamari',
    },
    { value: 'Castanheiras', name: 'Castanheiras', label: 'Castanheiras' },
    { value: 'Cerejeiras', name: 'Cerejeiras', label: 'Cerejeiras' },
    { value: 'Chupinguaia', name: 'Chupinguaia', label: 'Chupinguaia' },
    {
      value: 'Colorado do Oeste',
      name: 'Colorado do Oeste',
      label: 'Colorado do Oeste',
    },
    { value: 'Corumbiara', name: 'Corumbiara', label: 'Corumbiara' },
    { value: 'Costa Marques', name: 'Costa Marques', label: 'Costa Marques' },
    { value: 'Cujubim', name: 'Cujubim', label: 'Cujubim' },
    {
      value: "Espigão D'Oeste",
      name: "Espigão D'Oeste",
      label: "Espigão D'Oeste",
    },
    {
      value: 'Governador Jorge Teixeira',
      name: 'Governador Jorge Teixeira',
      label: 'Governador Jorge Teixeira',
    },
    { value: 'Guajará-Mirim', name: 'Guajará-Mirim', label: 'Guajará-Mirim' },
    {
      value: 'Itapuã do Oeste',
      name: 'Itapuã do Oeste',
      label: 'Itapuã do Oeste',
    },
    { value: 'Jaci Paraná', name: 'Jaci Paraná', label: 'Jaci Paraná' },
    { value: 'Jaru', name: 'Jaru', label: 'Jaru' },
    { value: 'Ji-Paraná', name: 'Ji-Paraná', label: 'Ji-Paraná' },
    {
      value: "Machadinho D'Oeste",
      name: "Machadinho D'Oeste",
      label: "Machadinho D'Oeste",
    },
    {
      value: 'Ministro Andreazza',
      name: 'Ministro Andreazza',
      label: 'Ministro Andreazza',
    },
    {
      value: 'Mirante da Serra',
      name: 'Mirante da Serra',
      label: 'Mirante da Serra',
    },
    { value: 'Monte Negro', name: 'Monte Negro', label: 'Monte Negro' },
    {
      value: "Nova Brasilândia D'Oeste",
      name: "Nova Brasilândia D'Oeste",
      label: "Nova Brasilândia D'Oeste",
    },
    { value: 'Nova Mamoré', name: 'Nova Mamoré', label: 'Nova Mamoré' },
    { value: 'Nova União', name: 'Nova União', label: 'Nova União' },
    {
      value: 'Novo Horizonte do Oeste',
      name: 'Novo Horizonte do Oeste',
      label: 'Novo Horizonte do Oeste',
    },
    {
      value: 'Ouro Preto do Oeste',
      name: 'Ouro Preto do Oeste',
      label: 'Ouro Preto do Oeste',
    },
    { value: 'Parecis', name: 'Parecis', label: 'Parecis' },
    { value: 'Pimenta Bueno', name: 'Pimenta Bueno', label: 'Pimenta Bueno' },
    {
      value: 'Pimenteiras do Oeste',
      name: 'Pimenteiras do Oeste',
      label: 'Pimenteiras do Oeste',
    },
    { value: 'Porto Velho', name: 'Porto Velho', label: 'Porto Velho' },
    {
      value: 'Presidente Médici',
      name: 'Presidente Médici',
      label: 'Presidente Médici',
    },
    {
      value: 'Primavera de Rondônia',
      name: 'Primavera de Rondônia',
      label: 'Primavera de Rondônia',
    },
    { value: 'Rio Crespo', name: 'Rio Crespo', label: 'Rio Crespo' },
    {
      value: 'Rolim de Moura',
      name: 'Rolim de Moura',
      label: 'Rolim de Moura',
    },
    {
      value: "Santa Luzia D'Oeste",
      name: "Santa Luzia D'Oeste",
      label: "Santa Luzia D'Oeste",
    },
    {
      value: "São Felipe D'Oeste",
      name: "São Felipe D'Oeste",
      label: "São Felipe D'Oeste",
    },
    {
      value: 'São Francisco do Guaporé',
      name: 'São Francisco do Guaporé',
      label: 'São Francisco do Guaporé',
    },
    {
      value: 'São Miguel do Guaporé',
      name: 'São Miguel do Guaporé',
      label: 'São Miguel do Guaporé',
    },
    { value: 'Seringueiras', name: 'Seringueiras', label: 'Seringueiras' },
    { value: 'Teixeirópolis', name: 'Teixeirópolis', label: 'Teixeirópolis' },
    { value: 'Theobroma', name: 'Theobroma', label: 'Theobroma' },
    { value: 'Urupá', name: 'Urupá', label: 'Urupá' },
    { value: 'Vale do Anari', name: 'Vale do Anari', label: 'Vale do Anari' },
    {
      value: 'Vale do Paraíso',
      name: 'Vale do Paraíso',
      label: 'Vale do Paraíso',
    },
    { value: 'Vilhena', name: 'Vilhena', label: 'Vilhena' },
  ],
  RR: [
    { value: 'Alto Alegre', name: 'Alto Alegre', label: 'Alto Alegre' },
    { value: 'Amajari', name: 'Amajari', label: 'Amajari' },
    { value: 'Boa Vista', name: 'Boa Vista', label: 'Boa Vista' },
    { value: 'Bonfim', name: 'Bonfim', label: 'Bonfim' },
    { value: 'Cantá', name: 'Cantá', label: 'Cantá' },
    { value: 'Caracaraí', name: 'Caracaraí', label: 'Caracaraí' },
    { value: 'Caroebe', name: 'Caroebe', label: 'Caroebe' },
    { value: 'Iracema', name: 'Iracema', label: 'Iracema' },
    { value: 'Mucajaí', name: 'Mucajaí', label: 'Mucajaí' },
    { value: 'Normandia', name: 'Normandia', label: 'Normandia' },
    { value: 'Pacaraima', name: 'Pacaraima', label: 'Pacaraima' },
    { value: 'Rorainópolis', name: 'Rorainópolis', label: 'Rorainópolis' },
    {
      value: 'São João da Baliza',
      name: 'São João da Baliza',
      label: 'São João da Baliza',
    },
    { value: 'São Luiz', name: 'São Luiz', label: 'São Luiz' },
    { value: 'Uiramutã', name: 'Uiramutã', label: 'Uiramutã' },
  ],
  RS: [
    { value: 'Aceguá', name: 'Aceguá', label: 'Aceguá' },
    { value: 'Água Santa', name: 'Água Santa', label: 'Água Santa' },
    { value: 'Agudo', name: 'Agudo', label: 'Agudo' },
    { value: 'Ajuricaba', name: 'Ajuricaba', label: 'Ajuricaba' },
    { value: 'Alecrim', name: 'Alecrim', label: 'Alecrim' },
    { value: 'Alegrete', name: 'Alegrete', label: 'Alegrete' },
    { value: 'Alegria', name: 'Alegria', label: 'Alegria' },
    {
      value: 'Almirante Tamandaré do Sul',
      name: 'Almirante Tamandaré do Sul',
      label: 'Almirante Tamandaré do Sul',
    },
    { value: 'Alpestre', name: 'Alpestre', label: 'Alpestre' },
    { value: 'Alto Alegre', name: 'Alto Alegre', label: 'Alto Alegre' },
    { value: 'Alto Feliz', name: 'Alto Feliz', label: 'Alto Feliz' },
    { value: 'Alvorada', name: 'Alvorada', label: 'Alvorada' },
    {
      value: 'Amaral Ferrador',
      name: 'Amaral Ferrador',
      label: 'Amaral Ferrador',
    },
    {
      value: 'Ametista do Sul',
      name: 'Ametista do Sul',
      label: 'Ametista do Sul',
    },
    {
      value: 'André da Rocha',
      name: 'André da Rocha',
      label: 'André da Rocha',
    },
    { value: 'Anta Gorda', name: 'Anta Gorda', label: 'Anta Gorda' },
    { value: 'Antônio Prado', name: 'Antônio Prado', label: 'Antônio Prado' },
    { value: 'Arambaré', name: 'Arambaré', label: 'Arambaré' },
    { value: 'Araricá', name: 'Araricá', label: 'Araricá' },
    { value: 'Aratiba', name: 'Aratiba', label: 'Aratiba' },
    {
      value: 'Arroio do Meio',
      name: 'Arroio do Meio',
      label: 'Arroio do Meio',
    },
    {
      value: 'Arroio do Padre',
      name: 'Arroio do Padre',
      label: 'Arroio do Padre',
    },
    { value: 'Arroio do Sal', name: 'Arroio do Sal', label: 'Arroio do Sal' },
    {
      value: 'Arroio do Tigre',
      name: 'Arroio do Tigre',
      label: 'Arroio do Tigre',
    },
    {
      value: 'Arroio dos Ratos',
      name: 'Arroio dos Ratos',
      label: 'Arroio dos Ratos',
    },
    { value: 'Arroio Grande', name: 'Arroio Grande', label: 'Arroio Grande' },
    { value: 'Arvorezinha', name: 'Arvorezinha', label: 'Arvorezinha' },
    {
      value: 'Augusto Pestana',
      name: 'Augusto Pestana',
      label: 'Augusto Pestana',
    },
    { value: 'Áurea', name: 'Áurea', label: 'Áurea' },
    { value: 'Bagé', name: 'Bagé', label: 'Bagé' },
    {
      value: 'Balneário Pinhal',
      name: 'Balneário Pinhal',
      label: 'Balneário Pinhal',
    },
    { value: 'Barão', name: 'Barão', label: 'Barão' },
    {
      value: 'Barão de Cotegipe',
      name: 'Barão de Cotegipe',
      label: 'Barão de Cotegipe',
    },
    {
      value: 'Barão do Triunfo',
      name: 'Barão do Triunfo',
      label: 'Barão do Triunfo',
    },
    {
      value: 'Barra do Guarita',
      name: 'Barra do Guarita',
      label: 'Barra do Guarita',
    },
    {
      value: 'Barra do Quaraí',
      name: 'Barra do Quaraí',
      label: 'Barra do Quaraí',
    },
    {
      value: 'Barra do Ribeiro',
      name: 'Barra do Ribeiro',
      label: 'Barra do Ribeiro',
    },
    {
      value: 'Barra do Rio Azul',
      name: 'Barra do Rio Azul',
      label: 'Barra do Rio Azul',
    },
    { value: 'Barra Funda', name: 'Barra Funda', label: 'Barra Funda' },
    { value: 'Barracão', name: 'Barracão', label: 'Barracão' },
    { value: 'Barros Cassal', name: 'Barros Cassal', label: 'Barros Cassal' },
    {
      value: 'Benjamin Constant do Sul',
      name: 'Benjamin Constant do Sul',
      label: 'Benjamin Constant do Sul',
    },
    {
      value: 'Bento Gonçalves',
      name: 'Bento Gonçalves',
      label: 'Bento Gonçalves',
    },
    {
      value: 'Boa Vista das Missões',
      name: 'Boa Vista das Missões',
      label: 'Boa Vista das Missões',
    },
    {
      value: 'Boa Vista do Buricá',
      name: 'Boa Vista do Buricá',
      label: 'Boa Vista do Buricá',
    },
    {
      value: 'Boa Vista do Cadeado',
      name: 'Boa Vista do Cadeado',
      label: 'Boa Vista do Cadeado',
    },
    {
      value: 'Boa Vista do Incra',
      name: 'Boa Vista do Incra',
      label: 'Boa Vista do Incra',
    },
    {
      value: 'Boa Vista do Sul',
      name: 'Boa Vista do Sul',
      label: 'Boa Vista do Sul',
    },
    { value: 'Bom Jesus', name: 'Bom Jesus', label: 'Bom Jesus' },
    { value: 'Bom Princípio', name: 'Bom Princípio', label: 'Bom Princípio' },
    { value: 'Bom Progresso', name: 'Bom Progresso', label: 'Bom Progresso' },
    {
      value: 'Bom Retiro do Sul',
      name: 'Bom Retiro do Sul',
      label: 'Bom Retiro do Sul',
    },
    {
      value: 'Boqueirão do Leão',
      name: 'Boqueirão do Leão',
      label: 'Boqueirão do Leão',
    },
    { value: 'Bossoroca', name: 'Bossoroca', label: 'Bossoroca' },
    { value: 'Bozano', name: 'Bozano', label: 'Bozano' },
    { value: 'Braga', name: 'Braga', label: 'Braga' },
    { value: 'Brochier', name: 'Brochier', label: 'Brochier' },
    { value: 'Butiá', name: 'Butiá', label: 'Butiá' },
    {
      value: 'Caçapava do Sul',
      name: 'Caçapava do Sul',
      label: 'Caçapava do Sul',
    },
    { value: 'Cacequi', name: 'Cacequi', label: 'Cacequi' },
    {
      value: 'Cachoeira do Sul',
      name: 'Cachoeira do Sul',
      label: 'Cachoeira do Sul',
    },
    { value: 'Cachoeirinha', name: 'Cachoeirinha', label: 'Cachoeirinha' },
    { value: 'Cacique Doble', name: 'Cacique Doble', label: 'Cacique Doble' },
    { value: 'Caibaté', name: 'Caibaté', label: 'Caibaté' },
    { value: 'Caiçara', name: 'Caiçara', label: 'Caiçara' },
    { value: 'Camaquã', name: 'Camaquã', label: 'Camaquã' },
    { value: 'Camargo', name: 'Camargo', label: 'Camargo' },
    {
      value: 'Cambará do Sul',
      name: 'Cambará do Sul',
      label: 'Cambará do Sul',
    },
    {
      value: 'Campestre da Serra',
      name: 'Campestre da Serra',
      label: 'Campestre da Serra',
    },
    {
      value: 'Campina das Missões',
      name: 'Campina das Missões',
      label: 'Campina das Missões',
    },
    {
      value: 'Campinas do Sul',
      name: 'Campinas do Sul',
      label: 'Campinas do Sul',
    },
    { value: 'Campo Bom', name: 'Campo Bom', label: 'Campo Bom' },
    { value: 'Campo Novo', name: 'Campo Novo', label: 'Campo Novo' },
    { value: 'Campos Borges', name: 'Campos Borges', label: 'Campos Borges' },
    { value: 'Candelária', name: 'Candelária', label: 'Candelária' },
    { value: 'Cândido Godói', name: 'Cândido Godói', label: 'Cândido Godói' },
    { value: 'Candiota', name: 'Candiota', label: 'Candiota' },
    { value: 'Canela', name: 'Canela', label: 'Canela' },
    { value: 'Canguçu', name: 'Canguçu', label: 'Canguçu' },
    { value: 'Canoas', name: 'Canoas', label: 'Canoas' },
    {
      value: 'Canudos do Vale',
      name: 'Canudos do Vale',
      label: 'Canudos do Vale',
    },
    {
      value: 'Capão Bonito do Sul',
      name: 'Capão Bonito do Sul',
      label: 'Capão Bonito do Sul',
    },
    {
      value: 'Capão da Canoa',
      name: 'Capão da Canoa',
      label: 'Capão da Canoa',
    },
    { value: 'Capão do Cipó', name: 'Capão do Cipó', label: 'Capão do Cipó' },
    { value: 'Capão do Leão', name: 'Capão do Leão', label: 'Capão do Leão' },
    {
      value: 'Capela de Santana',
      name: 'Capela de Santana',
      label: 'Capela de Santana',
    },
    { value: 'Capitão', name: 'Capitão', label: 'Capitão' },
    {
      value: 'Capivari do Sul',
      name: 'Capivari do Sul',
      label: 'Capivari do Sul',
    },
    { value: 'Caraá', name: 'Caraá', label: 'Caraá' },
    { value: 'Carazinho', name: 'Carazinho', label: 'Carazinho' },
    {
      value: 'Carlos Barbosa',
      name: 'Carlos Barbosa',
      label: 'Carlos Barbosa',
    },
    { value: 'Carlos Gomes', name: 'Carlos Gomes', label: 'Carlos Gomes' },
    { value: 'Casca', name: 'Casca', label: 'Casca' },
    { value: 'Caseiros', name: 'Caseiros', label: 'Caseiros' },
    { value: 'Catuípe', name: 'Catuípe', label: 'Catuípe' },
    { value: 'Caxias do Sul', name: 'Caxias do Sul', label: 'Caxias do Sul' },
    { value: 'Centenário', name: 'Centenário', label: 'Centenário' },
    { value: 'Cerrito', name: 'Cerrito', label: 'Cerrito' },
    { value: 'Cerro Branco', name: 'Cerro Branco', label: 'Cerro Branco' },
    { value: 'Cerro Grande', name: 'Cerro Grande', label: 'Cerro Grande' },
    {
      value: 'Cerro Grande do Sul',
      name: 'Cerro Grande do Sul',
      label: 'Cerro Grande do Sul',
    },
    { value: 'Cerro Largo', name: 'Cerro Largo', label: 'Cerro Largo' },
    { value: 'Chapada', name: 'Chapada', label: 'Chapada' },
    { value: 'Charqueadas', name: 'Charqueadas', label: 'Charqueadas' },
    { value: 'Charrua', name: 'Charrua', label: 'Charrua' },
    { value: 'Chiapetta', name: 'Chiapetta', label: 'Chiapetta' },
    { value: 'Chuí', name: 'Chuí', label: 'Chuí' },
    { value: 'Chuvisca', name: 'Chuvisca', label: 'Chuvisca' },
    { value: 'Cidreira', name: 'Cidreira', label: 'Cidreira' },
    { value: 'Ciríaco', name: 'Ciríaco', label: 'Ciríaco' },
    { value: 'Colinas', name: 'Colinas', label: 'Colinas' },
    { value: 'Colorado', name: 'Colorado', label: 'Colorado' },
    { value: 'Condor', name: 'Condor', label: 'Condor' },
    { value: 'Constantina', name: 'Constantina', label: 'Constantina' },
    {
      value: 'Coqueiro Baixo',
      name: 'Coqueiro Baixo',
      label: 'Coqueiro Baixo',
    },
    {
      value: 'Coqueiros do Sul',
      name: 'Coqueiros do Sul',
      label: 'Coqueiros do Sul',
    },
    {
      value: 'Coronel Barros',
      name: 'Coronel Barros',
      label: 'Coronel Barros',
    },
    {
      value: 'Coronel Bicaco',
      name: 'Coronel Bicaco',
      label: 'Coronel Bicaco',
    },
    { value: 'Coronel Pilar', name: 'Coronel Pilar', label: 'Coronel Pilar' },
    { value: 'Cotiporã', name: 'Cotiporã', label: 'Cotiporã' },
    { value: 'Coxilha', name: 'Coxilha', label: 'Coxilha' },
    { value: 'Crissiumal', name: 'Crissiumal', label: 'Crissiumal' },
    { value: 'Cristal', name: 'Cristal', label: 'Cristal' },
    {
      value: 'Cristal do Sul',
      name: 'Cristal do Sul',
      label: 'Cristal do Sul',
    },
    { value: 'Cruz Alta', name: 'Cruz Alta', label: 'Cruz Alta' },
    { value: 'Cruzaltense', name: 'Cruzaltense', label: 'Cruzaltense' },
    {
      value: 'Cruzeiro do Sul',
      name: 'Cruzeiro do Sul',
      label: 'Cruzeiro do Sul',
    },
    {
      value: 'David Canabarro',
      name: 'David Canabarro',
      label: 'David Canabarro',
    },
    { value: 'Derrubadas', name: 'Derrubadas', label: 'Derrubadas' },
    {
      value: 'Dezesseis de Novembro',
      name: 'Dezesseis de Novembro',
      label: 'Dezesseis de Novembro',
    },
    {
      value: 'Dilermando de Aguiar',
      name: 'Dilermando de Aguiar',
      label: 'Dilermando de Aguiar',
    },
    { value: 'Dois Irmãos', name: 'Dois Irmãos', label: 'Dois Irmãos' },
    {
      value: 'Dois Irmãos das Missões',
      name: 'Dois Irmãos das Missões',
      label: 'Dois Irmãos das Missões',
    },
    { value: 'Dois Lajeados', name: 'Dois Lajeados', label: 'Dois Lajeados' },
    { value: 'Dom Feliciano', name: 'Dom Feliciano', label: 'Dom Feliciano' },
    { value: 'Dom Pedrito', name: 'Dom Pedrito', label: 'Dom Pedrito' },
    {
      value: 'Dom Pedro de Alcântara',
      name: 'Dom Pedro de Alcântara',
      label: 'Dom Pedro de Alcântara',
    },
    {
      value: 'Dona Francisca',
      name: 'Dona Francisca',
      label: 'Dona Francisca',
    },
    {
      value: 'Doutor Maurício Cardoso',
      name: 'Doutor Maurício Cardoso',
      label: 'Doutor Maurício Cardoso',
    },
    {
      value: 'Doutor Ricardo',
      name: 'Doutor Ricardo',
      label: 'Doutor Ricardo',
    },
    {
      value: 'Eldorado do Sul',
      name: 'Eldorado do Sul',
      label: 'Eldorado do Sul',
    },
    { value: 'Encantado', name: 'Encantado', label: 'Encantado' },
    {
      value: 'Encruzilhada do Sul',
      name: 'Encruzilhada do Sul',
      label: 'Encruzilhada do Sul',
    },
    { value: 'Engenho Velho', name: 'Engenho Velho', label: 'Engenho Velho' },
    {
      value: 'Entre Rios do Sul',
      name: 'Entre Rios do Sul',
      label: 'Entre Rios do Sul',
    },
    { value: 'Entre-Ijuís', name: 'Entre-Ijuís', label: 'Entre-Ijuís' },
    { value: 'Erebango', name: 'Erebango', label: 'Erebango' },
    { value: 'Erechim', name: 'Erechim', label: 'Erechim' },
    { value: 'Ernestina', name: 'Ernestina', label: 'Ernestina' },
    { value: 'Erval Grande', name: 'Erval Grande', label: 'Erval Grande' },
    { value: 'Erval Seco', name: 'Erval Seco', label: 'Erval Seco' },
    { value: 'Esmeralda', name: 'Esmeralda', label: 'Esmeralda' },
    {
      value: 'Esperança do Sul',
      name: 'Esperança do Sul',
      label: 'Esperança do Sul',
    },
    { value: 'Espumoso', name: 'Espumoso', label: 'Espumoso' },
    { value: 'Estação', name: 'Estação', label: 'Estação' },
    {
      value: 'Estância Velha',
      name: 'Estância Velha',
      label: 'Estância Velha',
    },
    { value: 'Esteio', name: 'Esteio', label: 'Esteio' },
    { value: 'Estrela', name: 'Estrela', label: 'Estrela' },
    { value: 'Estrela Velha', name: 'Estrela Velha', label: 'Estrela Velha' },
    {
      value: 'Eugênio de Castro',
      name: 'Eugênio de Castro',
      label: 'Eugênio de Castro',
    },
    {
      value: 'Fagundes Varela',
      name: 'Fagundes Varela',
      label: 'Fagundes Varela',
    },
    { value: 'Farroupilha', name: 'Farroupilha', label: 'Farroupilha' },
    {
      value: 'Faxinal do Soturno',
      name: 'Faxinal do Soturno',
      label: 'Faxinal do Soturno',
    },
    { value: 'Faxinalzinho', name: 'Faxinalzinho', label: 'Faxinalzinho' },
    {
      value: 'Fazenda Vilanova',
      name: 'Fazenda Vilanova',
      label: 'Fazenda Vilanova',
    },
    { value: 'Feliz', name: 'Feliz', label: 'Feliz' },
    {
      value: 'Flores da Cunha',
      name: 'Flores da Cunha',
      label: 'Flores da Cunha',
    },
    {
      value: 'Floriano Peixoto',
      name: 'Floriano Peixoto',
      label: 'Floriano Peixoto',
    },
    {
      value: 'Fontoura Xavier',
      name: 'Fontoura Xavier',
      label: 'Fontoura Xavier',
    },
    { value: 'Formigueiro', name: 'Formigueiro', label: 'Formigueiro' },
    { value: 'Forquetinha', name: 'Forquetinha', label: 'Forquetinha' },
    {
      value: 'Fortaleza dos Valos',
      name: 'Fortaleza dos Valos',
      label: 'Fortaleza dos Valos',
    },
    {
      value: 'Frederico Westphalen',
      name: 'Frederico Westphalen',
      label: 'Frederico Westphalen',
    },
    { value: 'Garibaldi', name: 'Garibaldi', label: 'Garibaldi' },
    { value: 'Garruchos', name: 'Garruchos', label: 'Garruchos' },
    { value: 'Gaurama', name: 'Gaurama', label: 'Gaurama' },
    {
      value: 'General Câmara',
      name: 'General Câmara',
      label: 'General Câmara',
    },
    { value: 'Gentil', name: 'Gentil', label: 'Gentil' },
    {
      value: 'Getúlio Vargas',
      name: 'Getúlio Vargas',
      label: 'Getúlio Vargas',
    },
    { value: 'Giruá', name: 'Giruá', label: 'Giruá' },
    { value: 'Glorinha', name: 'Glorinha', label: 'Glorinha' },
    { value: 'Gramado', name: 'Gramado', label: 'Gramado' },
    {
      value: 'Gramado dos Loureiros',
      name: 'Gramado dos Loureiros',
      label: 'Gramado dos Loureiros',
    },
    {
      value: 'Gramado Xavier',
      name: 'Gramado Xavier',
      label: 'Gramado Xavier',
    },
    { value: 'Gravataí', name: 'Gravataí', label: 'Gravataí' },
    { value: 'Guabiju', name: 'Guabiju', label: 'Guabiju' },
    { value: 'Guaíba', name: 'Guaíba', label: 'Guaíba' },
    { value: 'Guaporé', name: 'Guaporé', label: 'Guaporé' },
    {
      value: 'Guarani das Missões',
      name: 'Guarani das Missões',
      label: 'Guarani das Missões',
    },
    { value: 'Harmonia', name: 'Harmonia', label: 'Harmonia' },
    { value: 'Herval', name: 'Herval', label: 'Herval' },
    { value: 'Herveiras', name: 'Herveiras', label: 'Herveiras' },
    { value: 'Horizontina', name: 'Horizontina', label: 'Horizontina' },
    { value: 'Hulha Negra', name: 'Hulha Negra', label: 'Hulha Negra' },
    { value: 'Humaitá', name: 'Humaitá', label: 'Humaitá' },
    { value: 'Ibarama', name: 'Ibarama', label: 'Ibarama' },
    { value: 'Ibiaçá', name: 'Ibiaçá', label: 'Ibiaçá' },
    { value: 'Ibiraiaras', name: 'Ibiraiaras', label: 'Ibiraiaras' },
    { value: 'Ibirapuitã', name: 'Ibirapuitã', label: 'Ibirapuitã' },
    { value: 'Ibirubá', name: 'Ibirubá', label: 'Ibirubá' },
    { value: 'Igrejinha', name: 'Igrejinha', label: 'Igrejinha' },
    { value: 'Ijuí', name: 'Ijuí', label: 'Ijuí' },
    { value: 'Ilópolis', name: 'Ilópolis', label: 'Ilópolis' },
    { value: 'Imbé', name: 'Imbé', label: 'Imbé' },
    { value: 'Imigrante', name: 'Imigrante', label: 'Imigrante' },
    { value: 'Independência', name: 'Independência', label: 'Independência' },
    { value: 'Inhacorá', name: 'Inhacorá', label: 'Inhacorá' },
    { value: 'Ipê', name: 'Ipê', label: 'Ipê' },
    {
      value: 'Ipiranga do Sul',
      name: 'Ipiranga do Sul',
      label: 'Ipiranga do Sul',
    },
    { value: 'Iraí', name: 'Iraí', label: 'Iraí' },
    { value: 'Itaara', name: 'Itaara', label: 'Itaara' },
    { value: 'Itacurubi', name: 'Itacurubi', label: 'Itacurubi' },
    { value: 'Itapuca', name: 'Itapuca', label: 'Itapuca' },
    { value: 'Itaqui', name: 'Itaqui', label: 'Itaqui' },
    { value: 'Itati', name: 'Itati', label: 'Itati' },
    {
      value: 'Itatiba do Sul',
      name: 'Itatiba do Sul',
      label: 'Itatiba do Sul',
    },
    { value: 'Ivorá', name: 'Ivorá', label: 'Ivorá' },
    { value: 'Ivoti', name: 'Ivoti', label: 'Ivoti' },
    { value: 'Jaboticaba', name: 'Jaboticaba', label: 'Jaboticaba' },
    { value: 'Jacuizinho', name: 'Jacuizinho', label: 'Jacuizinho' },
    { value: 'Jacutinga', name: 'Jacutinga', label: 'Jacutinga' },
    { value: 'Jaguarão', name: 'Jaguarão', label: 'Jaguarão' },
    { value: 'Jaguari', name: 'Jaguari', label: 'Jaguari' },
    { value: 'Jaquirana', name: 'Jaquirana', label: 'Jaquirana' },
    { value: 'Jari', name: 'Jari', label: 'Jari' },
    { value: 'Jóia', name: 'Jóia', label: 'Jóia' },
    {
      value: 'Júlio de Castilhos',
      name: 'Júlio de Castilhos',
      label: 'Júlio de Castilhos',
    },
    {
      value: 'Lagoa Bonita do Sul',
      name: 'Lagoa Bonita do Sul',
      label: 'Lagoa Bonita do Sul',
    },
    {
      value: 'Lagoa dos Três Cantos',
      name: 'Lagoa dos Três Cantos',
      label: 'Lagoa dos Três Cantos',
    },
    {
      value: 'Lagoa Vermelha',
      name: 'Lagoa Vermelha',
      label: 'Lagoa Vermelha',
    },
    { value: 'Lagoão', name: 'Lagoão', label: 'Lagoão' },
    { value: 'Lajeado', name: 'Lajeado', label: 'Lajeado' },
    {
      value: 'Lajeado do Bugre',
      name: 'Lajeado do Bugre',
      label: 'Lajeado do Bugre',
    },
    { value: 'Lavras do Sul', name: 'Lavras do Sul', label: 'Lavras do Sul' },
    {
      value: 'Liberato Salzano',
      name: 'Liberato Salzano',
      label: 'Liberato Salzano',
    },
    {
      value: 'Lindolfo Collor',
      name: 'Lindolfo Collor',
      label: 'Lindolfo Collor',
    },
    { value: 'Linha Nova', name: 'Linha Nova', label: 'Linha Nova' },
    { value: 'Maçambara', name: 'Maçambara', label: 'Maçambara' },
    { value: 'Machadinho', name: 'Machadinho', label: 'Machadinho' },
    { value: 'Mampituba', name: 'Mampituba', label: 'Mampituba' },
    { value: 'Manoel Viana', name: 'Manoel Viana', label: 'Manoel Viana' },
    { value: 'Maquiné', name: 'Maquiné', label: 'Maquiné' },
    { value: 'Maratá', name: 'Maratá', label: 'Maratá' },
    { value: 'Marau', name: 'Marau', label: 'Marau' },
    {
      value: 'Marcelino Ramos',
      name: 'Marcelino Ramos',
      label: 'Marcelino Ramos',
    },
    {
      value: 'Mariana Pimentel',
      name: 'Mariana Pimentel',
      label: 'Mariana Pimentel',
    },
    { value: 'Mariano Moro', name: 'Mariano Moro', label: 'Mariano Moro' },
    {
      value: 'Marques de Souza',
      name: 'Marques de Souza',
      label: 'Marques de Souza',
    },
    { value: 'Mata', name: 'Mata', label: 'Mata' },
    {
      value: 'Mato Castelhano',
      name: 'Mato Castelhano',
      label: 'Mato Castelhano',
    },
    { value: 'Mato Leitão', name: 'Mato Leitão', label: 'Mato Leitão' },
    { value: 'Mato Queimado', name: 'Mato Queimado', label: 'Mato Queimado' },
    {
      value: 'Maximiliano de Almeida',
      name: 'Maximiliano de Almeida',
      label: 'Maximiliano de Almeida',
    },
    { value: 'Minas do Leão', name: 'Minas do Leão', label: 'Minas do Leão' },
    { value: 'Miraguaí', name: 'Miraguaí', label: 'Miraguaí' },
    { value: 'Montauri', name: 'Montauri', label: 'Montauri' },
    {
      value: 'Monte Alegre dos Campos',
      name: 'Monte Alegre dos Campos',
      label: 'Monte Alegre dos Campos',
    },
    {
      value: 'Monte Belo do Sul',
      name: 'Monte Belo do Sul',
      label: 'Monte Belo do Sul',
    },
    { value: 'Montenegro', name: 'Montenegro', label: 'Montenegro' },
    { value: 'Mormaço', name: 'Mormaço', label: 'Mormaço' },
    {
      value: 'Morrinhos do Sul',
      name: 'Morrinhos do Sul',
      label: 'Morrinhos do Sul',
    },
    { value: 'Morro Redondo', name: 'Morro Redondo', label: 'Morro Redondo' },
    { value: 'Morro Reuter', name: 'Morro Reuter', label: 'Morro Reuter' },
    { value: 'Mostardas', name: 'Mostardas', label: 'Mostardas' },
    { value: 'Muçum', name: 'Muçum', label: 'Muçum' },
    { value: 'Muitos Capões', name: 'Muitos Capões', label: 'Muitos Capões' },
    { value: 'Muliterno', name: 'Muliterno', label: 'Muliterno' },
    { value: 'Não-Me-Toque', name: 'Não-Me-Toque', label: 'Não-Me-Toque' },
    {
      value: 'Nicolau Vergueiro',
      name: 'Nicolau Vergueiro',
      label: 'Nicolau Vergueiro',
    },
    { value: 'Nonoai', name: 'Nonoai', label: 'Nonoai' },
    { value: 'Nova Alvorada', name: 'Nova Alvorada', label: 'Nova Alvorada' },
    { value: 'Nova Araçá', name: 'Nova Araçá', label: 'Nova Araçá' },
    { value: 'Nova Bassano', name: 'Nova Bassano', label: 'Nova Bassano' },
    {
      value: 'Nova Boa Vista',
      name: 'Nova Boa Vista',
      label: 'Nova Boa Vista',
    },
    { value: 'Nova Bréscia', name: 'Nova Bréscia', label: 'Nova Bréscia' },
    {
      value: 'Nova Candelária',
      name: 'Nova Candelária',
      label: 'Nova Candelária',
    },
    {
      value: 'Nova Esperança do Sul',
      name: 'Nova Esperança do Sul',
      label: 'Nova Esperança do Sul',
    },
    { value: 'Nova Hartz', name: 'Nova Hartz', label: 'Nova Hartz' },
    { value: 'Nova Pádua', name: 'Nova Pádua', label: 'Nova Pádua' },
    { value: 'Nova Palma', name: 'Nova Palma', label: 'Nova Palma' },
    {
      value: 'Nova Petrópolis',
      name: 'Nova Petrópolis',
      label: 'Nova Petrópolis',
    },
    { value: 'Nova Prata', name: 'Nova Prata', label: 'Nova Prata' },
    { value: 'Nova Ramada', name: 'Nova Ramada', label: 'Nova Ramada' },
    {
      value: 'Nova Roma do Sul',
      name: 'Nova Roma do Sul',
      label: 'Nova Roma do Sul',
    },
    {
      value: 'Nova Santa Rita',
      name: 'Nova Santa Rita',
      label: 'Nova Santa Rita',
    },
    { value: 'Novo Barreiro', name: 'Novo Barreiro', label: 'Novo Barreiro' },
    { value: 'Novo Cabrais', name: 'Novo Cabrais', label: 'Novo Cabrais' },
    { value: 'Novo Hamburgo', name: 'Novo Hamburgo', label: 'Novo Hamburgo' },
    { value: 'Novo Machado', name: 'Novo Machado', label: 'Novo Machado' },
    {
      value: 'Novo Tiradentes',
      name: 'Novo Tiradentes',
      label: 'Novo Tiradentes',
    },
    { value: 'Novo Xingu', name: 'Novo Xingu', label: 'Novo Xingu' },
    { value: 'Osório', name: 'Osório', label: 'Osório' },
    { value: 'Paim Filho', name: 'Paim Filho', label: 'Paim Filho' },
    {
      value: 'Palmares do Sul',
      name: 'Palmares do Sul',
      label: 'Palmares do Sul',
    },
    {
      value: 'Palmeira das Missões',
      name: 'Palmeira das Missões',
      label: 'Palmeira das Missões',
    },
    { value: 'Palmitinho', name: 'Palmitinho', label: 'Palmitinho' },
    { value: 'Panambi', name: 'Panambi', label: 'Panambi' },
    {
      value: 'Pântano Grande',
      name: 'Pântano Grande',
      label: 'Pântano Grande',
    },
    { value: 'Paraí', name: 'Paraí', label: 'Paraí' },
    {
      value: 'Paraíso do Sul',
      name: 'Paraíso do Sul',
      label: 'Paraíso do Sul',
    },
    { value: 'Pareci Novo', name: 'Pareci Novo', label: 'Pareci Novo' },
    { value: 'Parobé', name: 'Parobé', label: 'Parobé' },
    { value: 'Passa Sete', name: 'Passa Sete', label: 'Passa Sete' },
    {
      value: 'Passo do Sobrado',
      name: 'Passo do Sobrado',
      label: 'Passo do Sobrado',
    },
    { value: 'Passo Fundo', name: 'Passo Fundo', label: 'Passo Fundo' },
    { value: 'Paulo Bento', name: 'Paulo Bento', label: 'Paulo Bento' },
    { value: 'Paverama', name: 'Paverama', label: 'Paverama' },
    { value: 'Pedras Altas', name: 'Pedras Altas', label: 'Pedras Altas' },
    { value: 'Pedro Osório', name: 'Pedro Osório', label: 'Pedro Osório' },
    { value: 'Pejuçara', name: 'Pejuçara', label: 'Pejuçara' },
    { value: 'Pelotas', name: 'Pelotas', label: 'Pelotas' },
    { value: 'Picada Café', name: 'Picada Café', label: 'Picada Café' },
    { value: 'Pinhal', name: 'Pinhal', label: 'Pinhal' },
    {
      value: 'Pinhal da Serra',
      name: 'Pinhal da Serra',
      label: 'Pinhal da Serra',
    },
    { value: 'Pinhal Grande', name: 'Pinhal Grande', label: 'Pinhal Grande' },
    {
      value: 'Pinheirinho do Vale',
      name: 'Pinheirinho do Vale',
      label: 'Pinheirinho do Vale',
    },
    {
      value: 'Pinheiro Machado',
      name: 'Pinheiro Machado',
      label: 'Pinheiro Machado',
    },
    { value: 'Pirapó', name: 'Pirapó', label: 'Pirapó' },
    { value: 'Piratini', name: 'Piratini', label: 'Piratini' },
    { value: 'Planalto', name: 'Planalto', label: 'Planalto' },
    {
      value: 'Poço das Antas',
      name: 'Poço das Antas',
      label: 'Poço das Antas',
    },
    { value: 'Pontão', name: 'Pontão', label: 'Pontão' },
    { value: 'Ponte Preta', name: 'Ponte Preta', label: 'Ponte Preta' },
    { value: 'Portão', name: 'Portão', label: 'Portão' },
    { value: 'Porto Alegre', name: 'Porto Alegre', label: 'Porto Alegre' },
    { value: 'Porto Lucena', name: 'Porto Lucena', label: 'Porto Lucena' },
    { value: 'Porto Mauá', name: 'Porto Mauá', label: 'Porto Mauá' },
    {
      value: 'Porto Vera Cruz',
      name: 'Porto Vera Cruz',
      label: 'Porto Vera Cruz',
    },
    { value: 'Porto Xavier', name: 'Porto Xavier', label: 'Porto Xavier' },
    { value: 'Pouso Novo', name: 'Pouso Novo', label: 'Pouso Novo' },
    {
      value: 'Presidente Lucena',
      name: 'Presidente Lucena',
      label: 'Presidente Lucena',
    },
    { value: 'Progresso', name: 'Progresso', label: 'Progresso' },
    {
      value: 'Protásio Alves',
      name: 'Protásio Alves',
      label: 'Protásio Alves',
    },
    { value: 'Putinga', name: 'Putinga', label: 'Putinga' },
    { value: 'Quaraí', name: 'Quaraí', label: 'Quaraí' },
    { value: 'Quatro Irmãos', name: 'Quatro Irmãos', label: 'Quatro Irmãos' },
    { value: 'Quevedos', name: 'Quevedos', label: 'Quevedos' },
    {
      value: 'Quinze de Novembro',
      name: 'Quinze de Novembro',
      label: 'Quinze de Novembro',
    },
    { value: 'Redentora', name: 'Redentora', label: 'Redentora' },
    { value: 'Relvado', name: 'Relvado', label: 'Relvado' },
    { value: 'Restinga Seca', name: 'Restinga Seca', label: 'Restinga Seca' },
    {
      value: 'Rio dos Índios',
      name: 'Rio dos Índios',
      label: 'Rio dos Índios',
    },
    { value: 'Rio Grande', name: 'Rio Grande', label: 'Rio Grande' },
    { value: 'Rio Pardo', name: 'Rio Pardo', label: 'Rio Pardo' },
    { value: 'Riozinho', name: 'Riozinho', label: 'Riozinho' },
    { value: 'Roca Sales', name: 'Roca Sales', label: 'Roca Sales' },
    { value: 'Rodeio Bonito', name: 'Rodeio Bonito', label: 'Rodeio Bonito' },
    { value: 'Rolador', name: 'Rolador', label: 'Rolador' },
    { value: 'Rolante', name: 'Rolante', label: 'Rolante' },
    { value: 'Ronda Alta', name: 'Ronda Alta', label: 'Ronda Alta' },
    { value: 'Rondinha', name: 'Rondinha', label: 'Rondinha' },
    {
      value: 'Roque Gonzales',
      name: 'Roque Gonzales',
      label: 'Roque Gonzales',
    },
    {
      value: 'Rosário do Sul',
      name: 'Rosário do Sul',
      label: 'Rosário do Sul',
    },
    {
      value: 'Sagrada Família',
      name: 'Sagrada Família',
      label: 'Sagrada Família',
    },
    {
      value: 'Saldanha Marinho',
      name: 'Saldanha Marinho',
      label: 'Saldanha Marinho',
    },
    {
      value: 'Salto do Jacuí',
      name: 'Salto do Jacuí',
      label: 'Salto do Jacuí',
    },
    {
      value: 'Salvador das Missões',
      name: 'Salvador das Missões',
      label: 'Salvador das Missões',
    },
    {
      value: 'Salvador do Sul',
      name: 'Salvador do Sul',
      label: 'Salvador do Sul',
    },
    { value: 'Sananduva', name: 'Sananduva', label: 'Sananduva' },
    {
      value: 'Santa Bárbara do Sul',
      name: 'Santa Bárbara do Sul',
      label: 'Santa Bárbara do Sul',
    },
    {
      value: 'Santa Cecília do Sul',
      name: 'Santa Cecília do Sul',
      label: 'Santa Cecília do Sul',
    },
    {
      value: 'Santa Clara do Sul',
      name: 'Santa Clara do Sul',
      label: 'Santa Clara do Sul',
    },
    {
      value: 'Santa Cruz do Sul',
      name: 'Santa Cruz do Sul',
      label: 'Santa Cruz do Sul',
    },
    {
      value: 'Santa Margarida do Sul',
      name: 'Santa Margarida do Sul',
      label: 'Santa Margarida do Sul',
    },
    { value: 'Santa Maria', name: 'Santa Maria', label: 'Santa Maria' },
    {
      value: 'Santa Maria do Herval',
      name: 'Santa Maria do Herval',
      label: 'Santa Maria do Herval',
    },
    { value: 'Santa Rosa', name: 'Santa Rosa', label: 'Santa Rosa' },
    { value: 'Santa Tereza', name: 'Santa Tereza', label: 'Santa Tereza' },
    {
      value: 'Santa Vitória do Palmar',
      name: 'Santa Vitória do Palmar',
      label: 'Santa Vitória do Palmar',
    },
    {
      value: 'Santana da Boa Vista',
      name: 'Santana da Boa Vista',
      label: 'Santana da Boa Vista',
    },
    {
      value: 'Santana do Livramento',
      name: 'Santana do Livramento',
      label: 'Santana do Livramento',
    },
    { value: 'Santiago', name: 'Santiago', label: 'Santiago' },
    { value: 'Santo Ângelo', name: 'Santo Ângelo', label: 'Santo Ângelo' },
    {
      value: 'Santo Antônio da Patrulha',
      name: 'Santo Antônio da Patrulha',
      label: 'Santo Antônio da Patrulha',
    },
    {
      value: 'Santo Antônio das Missões',
      name: 'Santo Antônio das Missões',
      label: 'Santo Antônio das Missões',
    },
    {
      value: 'Santo Antônio do Palma',
      name: 'Santo Antônio do Palma',
      label: 'Santo Antônio do Palma',
    },
    {
      value: 'Santo Antônio do Planalto',
      name: 'Santo Antônio do Planalto',
      label: 'Santo Antônio do Planalto',
    },
    { value: 'Santo Augusto', name: 'Santo Augusto', label: 'Santo Augusto' },
    { value: 'Santo Cristo', name: 'Santo Cristo', label: 'Santo Cristo' },
    {
      value: 'Santo Expedito do Sul',
      name: 'Santo Expedito do Sul',
      label: 'Santo Expedito do Sul',
    },
    { value: 'São Borja', name: 'São Borja', label: 'São Borja' },
    {
      value: 'São Domingos do Sul',
      name: 'São Domingos do Sul',
      label: 'São Domingos do Sul',
    },
    {
      value: 'São Francisco de Assis',
      name: 'São Francisco de Assis',
      label: 'São Francisco de Assis',
    },
    {
      value: 'São Francisco de Paula',
      name: 'São Francisco de Paula',
      label: 'São Francisco de Paula',
    },
    { value: 'São Gabriel', name: 'São Gabriel', label: 'São Gabriel' },
    { value: 'São Jerônimo', name: 'São Jerônimo', label: 'São Jerônimo' },
    {
      value: 'São João da Urtiga',
      name: 'São João da Urtiga',
      label: 'São João da Urtiga',
    },
    {
      value: 'São João do Polêsine',
      name: 'São João do Polêsine',
      label: 'São João do Polêsine',
    },
    { value: 'São Jorge', name: 'São Jorge', label: 'São Jorge' },
    {
      value: 'São José das Missões',
      name: 'São José das Missões',
      label: 'São José das Missões',
    },
    {
      value: 'São José do Herval',
      name: 'São José do Herval',
      label: 'São José do Herval',
    },
    {
      value: 'São José do Hortêncio',
      name: 'São José do Hortêncio',
      label: 'São José do Hortêncio',
    },
    {
      value: 'São José do Inhacorá',
      name: 'São José do Inhacorá',
      label: 'São José do Inhacorá',
    },
    {
      value: 'São José do Norte',
      name: 'São José do Norte',
      label: 'São José do Norte',
    },
    {
      value: 'São José do Ouro',
      name: 'São José do Ouro',
      label: 'São José do Ouro',
    },
    {
      value: 'São José do Sul',
      name: 'São José do Sul',
      label: 'São José do Sul',
    },
    {
      value: 'São José dos Ausentes',
      name: 'São José dos Ausentes',
      label: 'São José dos Ausentes',
    },
    { value: 'São Leopoldo', name: 'São Leopoldo', label: 'São Leopoldo' },
    {
      value: 'São Lourenço do Sul',
      name: 'São Lourenço do Sul',
      label: 'São Lourenço do Sul',
    },
    {
      value: 'São Luiz Gonzaga',
      name: 'São Luiz Gonzaga',
      label: 'São Luiz Gonzaga',
    },
    { value: 'São Marcos', name: 'São Marcos', label: 'São Marcos' },
    { value: 'São Martinho', name: 'São Martinho', label: 'São Martinho' },
    {
      value: 'São Martinho da Serra',
      name: 'São Martinho da Serra',
      label: 'São Martinho da Serra',
    },
    {
      value: 'São Miguel das Missões',
      name: 'São Miguel das Missões',
      label: 'São Miguel das Missões',
    },
    { value: 'São Nicolau', name: 'São Nicolau', label: 'São Nicolau' },
    {
      value: 'São Paulo das Missões',
      name: 'São Paulo das Missões',
      label: 'São Paulo das Missões',
    },
    {
      value: 'São Pedro da Serra',
      name: 'São Pedro da Serra',
      label: 'São Pedro da Serra',
    },
    {
      value: 'São Pedro das Missões',
      name: 'São Pedro das Missões',
      label: 'São Pedro das Missões',
    },
    {
      value: 'São Pedro do Butiá',
      name: 'São Pedro do Butiá',
      label: 'São Pedro do Butiá',
    },
    {
      value: 'São Pedro do Sul',
      name: 'São Pedro do Sul',
      label: 'São Pedro do Sul',
    },
    {
      value: 'São Sebastião do Caí',
      name: 'São Sebastião do Caí',
      label: 'São Sebastião do Caí',
    },
    { value: 'São Sepé', name: 'São Sepé', label: 'São Sepé' },
    { value: 'São Valentim', name: 'São Valentim', label: 'São Valentim' },
    {
      value: 'São Valentim do Sul',
      name: 'São Valentim do Sul',
      label: 'São Valentim do Sul',
    },
    {
      value: 'São Valério do Sul',
      name: 'São Valério do Sul',
      label: 'São Valério do Sul',
    },
    { value: 'São Vendelino', name: 'São Vendelino', label: 'São Vendelino' },
    {
      value: 'São Vicente do Sul',
      name: 'São Vicente do Sul',
      label: 'São Vicente do Sul',
    },
    { value: 'Sapiranga', name: 'Sapiranga', label: 'Sapiranga' },
    {
      value: 'Sapucaia do Sul',
      name: 'Sapucaia do Sul',
      label: 'Sapucaia do Sul',
    },
    { value: 'Sarandi', name: 'Sarandi', label: 'Sarandi' },
    { value: 'Seberi', name: 'Seberi', label: 'Seberi' },
    { value: 'Sede Nova', name: 'Sede Nova', label: 'Sede Nova' },
    { value: 'Segredo', name: 'Segredo', label: 'Segredo' },
    { value: 'Selbach', name: 'Selbach', label: 'Selbach' },
    {
      value: 'Senador Salgado Filho',
      name: 'Senador Salgado Filho',
      label: 'Senador Salgado Filho',
    },
    {
      value: 'Sentinela do Sul',
      name: 'Sentinela do Sul',
      label: 'Sentinela do Sul',
    },
    {
      value: 'Serafina Corrêa',
      name: 'Serafina Corrêa',
      label: 'Serafina Corrêa',
    },
    { value: 'Sério', name: 'Sério', label: 'Sério' },
    { value: 'Sertão', name: 'Sertão', label: 'Sertão' },
    {
      value: 'Sertão Santana',
      name: 'Sertão Santana',
      label: 'Sertão Santana',
    },
    {
      value: 'Sete de Setembro',
      name: 'Sete de Setembro',
      label: 'Sete de Setembro',
    },
    {
      value: 'Severiano de Almeida',
      name: 'Severiano de Almeida',
      label: 'Severiano de Almeida',
    },
    {
      value: 'Silveira Martins',
      name: 'Silveira Martins',
      label: 'Silveira Martins',
    },
    { value: 'Sinimbu', name: 'Sinimbu', label: 'Sinimbu' },
    { value: 'Sobradinho', name: 'Sobradinho', label: 'Sobradinho' },
    { value: 'Soledade', name: 'Soledade', label: 'Soledade' },
    { value: 'Tabaí', name: 'Tabaí', label: 'Tabaí' },
    { value: 'Tapejara', name: 'Tapejara', label: 'Tapejara' },
    { value: 'Tapera', name: 'Tapera', label: 'Tapera' },
    { value: 'Tapes', name: 'Tapes', label: 'Tapes' },
    { value: 'Taquara', name: 'Taquara', label: 'Taquara' },
    { value: 'Taquari', name: 'Taquari', label: 'Taquari' },
    {
      value: 'Taquaruçu do Sul',
      name: 'Taquaruçu do Sul',
      label: 'Taquaruçu do Sul',
    },
    { value: 'Tavares', name: 'Tavares', label: 'Tavares' },
    {
      value: 'Tenente Portela',
      name: 'Tenente Portela',
      label: 'Tenente Portela',
    },
    {
      value: 'Terra de Areia',
      name: 'Terra de Areia',
      label: 'Terra de Areia',
    },
    { value: 'Teutônia', name: 'Teutônia', label: 'Teutônia' },
    { value: 'Tio Hugo', name: 'Tio Hugo', label: 'Tio Hugo' },
    {
      value: 'Tiradentes do Sul',
      name: 'Tiradentes do Sul',
      label: 'Tiradentes do Sul',
    },
    { value: 'Toropi', name: 'Toropi', label: 'Toropi' },
    { value: 'Torres', name: 'Torres', label: 'Torres' },
    { value: 'Tramandaí', name: 'Tramandaí', label: 'Tramandaí' },
    { value: 'Travesseiro', name: 'Travesseiro', label: 'Travesseiro' },
    { value: 'Três Arroios', name: 'Três Arroios', label: 'Três Arroios' },
    {
      value: 'Três Cachoeiras',
      name: 'Três Cachoeiras',
      label: 'Três Cachoeiras',
    },
    { value: 'Três Coroas', name: 'Três Coroas', label: 'Três Coroas' },
    { value: 'Três de Maio', name: 'Três de Maio', label: 'Três de Maio' },
    {
      value: 'Três Forquilhas',
      name: 'Três Forquilhas',
      label: 'Três Forquilhas',
    },
    {
      value: 'Três Palmeiras',
      name: 'Três Palmeiras',
      label: 'Três Palmeiras',
    },
    { value: 'Três Passos', name: 'Três Passos', label: 'Três Passos' },
    {
      value: 'Trindade do Sul',
      name: 'Trindade do Sul',
      label: 'Trindade do Sul',
    },
    { value: 'Triunfo', name: 'Triunfo', label: 'Triunfo' },
    { value: 'Tucunduva', name: 'Tucunduva', label: 'Tucunduva' },
    { value: 'Tunas', name: 'Tunas', label: 'Tunas' },
    {
      value: 'Tupanci do Sul',
      name: 'Tupanci do Sul',
      label: 'Tupanci do Sul',
    },
    { value: 'Tupanciretã', name: 'Tupanciretã', label: 'Tupanciretã' },
    { value: 'Tupandi', name: 'Tupandi', label: 'Tupandi' },
    { value: 'Tuparendi', name: 'Tuparendi', label: 'Tuparendi' },
    { value: 'Turuçu', name: 'Turuçu', label: 'Turuçu' },
    { value: 'Ubiretama', name: 'Ubiretama', label: 'Ubiretama' },
    {
      value: 'União da Serra',
      name: 'União da Serra',
      label: 'União da Serra',
    },
    { value: 'Unistalda', name: 'Unistalda', label: 'Unistalda' },
    { value: 'Uruguaiana', name: 'Uruguaiana', label: 'Uruguaiana' },
    { value: 'Vacaria', name: 'Vacaria', label: 'Vacaria' },
    { value: 'Vale do Sol', name: 'Vale do Sol', label: 'Vale do Sol' },
    { value: 'Vale Real', name: 'Vale Real', label: 'Vale Real' },
    { value: 'Vale Verde', name: 'Vale Verde', label: 'Vale Verde' },
    { value: 'Vanini', name: 'Vanini', label: 'Vanini' },
    {
      value: 'Venâncio Aires',
      name: 'Venâncio Aires',
      label: 'Venâncio Aires',
    },
    { value: 'Vera Cruz', name: 'Vera Cruz', label: 'Vera Cruz' },
    { value: 'Veranópolis', name: 'Veranópolis', label: 'Veranópolis' },
    {
      value: 'Vespasiano Correa',
      name: 'Vespasiano Correa',
      label: 'Vespasiano Correa',
    },
    { value: 'Viadutos', name: 'Viadutos', label: 'Viadutos' },
    { value: 'Viamão', name: 'Viamão', label: 'Viamão' },
    { value: 'Vicente Dutra', name: 'Vicente Dutra', label: 'Vicente Dutra' },
    { value: 'Victor Graeff', name: 'Victor Graeff', label: 'Victor Graeff' },
    { value: 'Vila Flores', name: 'Vila Flores', label: 'Vila Flores' },
    { value: 'Vila Lângaro', name: 'Vila Lângaro', label: 'Vila Lângaro' },
    { value: 'Vila Maria', name: 'Vila Maria', label: 'Vila Maria' },
    {
      value: 'Vila Nova do Sul',
      name: 'Vila Nova do Sul',
      label: 'Vila Nova do Sul',
    },
    { value: 'Vista Alegre', name: 'Vista Alegre', label: 'Vista Alegre' },
    {
      value: 'Vista Alegre do Prata',
      name: 'Vista Alegre do Prata',
      label: 'Vista Alegre do Prata',
    },
    { value: 'Vista Gaúcha', name: 'Vista Gaúcha', label: 'Vista Gaúcha' },
    {
      value: 'Vitória das Missões',
      name: 'Vitória das Missões',
      label: 'Vitória das Missões',
    },
    { value: 'Westfália', name: 'Westfália', label: 'Westfália' },
    { value: 'Xangri-Lá', name: 'Xangri-Lá', label: 'Xangri-Lá' },
  ],
  SC: [
    { value: 'Abdon Batista', name: 'Abdon Batista', label: 'Abdon Batista' },
    { value: 'Abelardo Luz', name: 'Abelardo Luz', label: 'Abelardo Luz' },
    { value: 'Agrolândia', name: 'Agrolândia', label: 'Agrolândia' },
    { value: 'Agronômica', name: 'Agronômica', label: 'Agronômica' },
    { value: 'Água Doce', name: 'Água Doce', label: 'Água Doce' },
    {
      value: 'Águas de Chapecó',
      name: 'Águas de Chapecó',
      label: 'Águas de Chapecó',
    },
    { value: 'Águas Frias', name: 'Águas Frias', label: 'Águas Frias' },
    { value: 'Águas Mornas', name: 'Águas Mornas', label: 'Águas Mornas' },
    {
      value: 'Alfredo Wagner',
      name: 'Alfredo Wagner',
      label: 'Alfredo Wagner',
    },
    {
      value: 'Alto Bela Vista',
      name: 'Alto Bela Vista',
      label: 'Alto Bela Vista',
    },
    { value: 'Anchieta', name: 'Anchieta', label: 'Anchieta' },
    { value: 'Angelina', name: 'Angelina', label: 'Angelina' },
    {
      value: 'Anita Garibaldi',
      name: 'Anita Garibaldi',
      label: 'Anita Garibaldi',
    },
    { value: 'Anitápolis', name: 'Anitápolis', label: 'Anitápolis' },
    {
      value: 'Antônio Carlos',
      name: 'Antônio Carlos',
      label: 'Antônio Carlos',
    },
    { value: 'Apiúna', name: 'Apiúna', label: 'Apiúna' },
    { value: 'Arabutã', name: 'Arabutã', label: 'Arabutã' },
    { value: 'Araquari', name: 'Araquari', label: 'Araquari' },
    { value: 'Araranguá', name: 'Araranguá', label: 'Araranguá' },
    { value: 'Armazém', name: 'Armazém', label: 'Armazém' },
    { value: 'Arroio Trinta', name: 'Arroio Trinta', label: 'Arroio Trinta' },
    { value: 'Arvoredo', name: 'Arvoredo', label: 'Arvoredo' },
    { value: 'Ascurra', name: 'Ascurra', label: 'Ascurra' },
    { value: 'Atalanta', name: 'Atalanta', label: 'Atalanta' },
    { value: 'Aurora', name: 'Aurora', label: 'Aurora' },
    {
      value: 'Balneário Arroio do Silva',
      name: 'Balneário Arroio do Silva',
      label: 'Balneário Arroio do Silva',
    },
    {
      value: 'Balneário Barra do Sul',
      name: 'Balneário Barra do Sul',
      label: 'Balneário Barra do Sul',
    },
    {
      value: 'Balneário Camboriú',
      name: 'Balneário Camboriú',
      label: 'Balneário Camboriú',
    },
    {
      value: 'Balneário Gaivota',
      name: 'Balneário Gaivota',
      label: 'Balneário Gaivota',
    },
    { value: 'Bandeirante', name: 'Bandeirante', label: 'Bandeirante' },
    { value: 'Barra Bonita', name: 'Barra Bonita', label: 'Barra Bonita' },
    { value: 'Barra Velha', name: 'Barra Velha', label: 'Barra Velha' },
    {
      value: 'Bela Vista do Toldo',
      name: 'Bela Vista do Toldo',
      label: 'Bela Vista do Toldo',
    },
    { value: 'Belmonte', name: 'Belmonte', label: 'Belmonte' },
    { value: 'Benedito Novo', name: 'Benedito Novo', label: 'Benedito Novo' },
    { value: 'Biguaçu', name: 'Biguaçu', label: 'Biguaçu' },
    { value: 'Blumenau', name: 'Blumenau', label: 'Blumenau' },
    {
      value: 'Bocaina do Sul',
      name: 'Bocaina do Sul',
      label: 'Bocaina do Sul',
    },
    {
      value: 'Bom Jardim da Serra',
      name: 'Bom Jardim da Serra',
      label: 'Bom Jardim da Serra',
    },
    { value: 'Bom Jesus', name: 'Bom Jesus', label: 'Bom Jesus' },
    {
      value: 'Bom Jesus do Oeste',
      name: 'Bom Jesus do Oeste',
      label: 'Bom Jesus do Oeste',
    },
    { value: 'Bom Retiro', name: 'Bom Retiro', label: 'Bom Retiro' },
    { value: 'Bombinhas', name: 'Bombinhas', label: 'Bombinhas' },
    { value: 'Botuverá', name: 'Botuverá', label: 'Botuverá' },
    {
      value: 'Braço do Norte',
      name: 'Braço do Norte',
      label: 'Braço do Norte',
    },
    {
      value: 'Braço do Trombudo',
      name: 'Braço do Trombudo',
      label: 'Braço do Trombudo',
    },
    { value: 'Brunópolis', name: 'Brunópolis', label: 'Brunópolis' },
    { value: 'Brusque', name: 'Brusque', label: 'Brusque' },
    { value: 'Caçador', name: 'Caçador', label: 'Caçador' },
    { value: 'Caibi', name: 'Caibi', label: 'Caibi' },
    { value: 'Calmon', name: 'Calmon', label: 'Calmon' },
    { value: 'Camboriú', name: 'Camboriú', label: 'Camboriú' },
    { value: 'Campo Alegre', name: 'Campo Alegre', label: 'Campo Alegre' },
    {
      value: 'Campo Belo do Sul',
      name: 'Campo Belo do Sul',
      label: 'Campo Belo do Sul',
    },
    { value: 'Campo Erê', name: 'Campo Erê', label: 'Campo Erê' },
    { value: 'Campos Novos', name: 'Campos Novos', label: 'Campos Novos' },
    { value: 'Canelinha', name: 'Canelinha', label: 'Canelinha' },
    { value: 'Canoinhas', name: 'Canoinhas', label: 'Canoinhas' },
    { value: 'Capão Alto', name: 'Capão Alto', label: 'Capão Alto' },
    { value: 'Capinzal', name: 'Capinzal', label: 'Capinzal' },
    {
      value: 'Capivari de Baixo',
      name: 'Capivari de Baixo',
      label: 'Capivari de Baixo',
    },
    { value: 'Catanduvas', name: 'Catanduvas', label: 'Catanduvas' },
    {
      value: 'Caxambu do Sul',
      name: 'Caxambu do Sul',
      label: 'Caxambu do Sul',
    },
    { value: 'Celso Ramos', name: 'Celso Ramos', label: 'Celso Ramos' },
    { value: 'Cerro Negro', name: 'Cerro Negro', label: 'Cerro Negro' },
    {
      value: 'Chapadão do Lageado',
      name: 'Chapadão do Lageado',
      label: 'Chapadão do Lageado',
    },
    { value: 'Chapecó', name: 'Chapecó', label: 'Chapecó' },
    { value: 'Cocal do Sul', name: 'Cocal do Sul', label: 'Cocal do Sul' },
    { value: 'Concórdia', name: 'Concórdia', label: 'Concórdia' },
    {
      value: 'Cordilheira Alta',
      name: 'Cordilheira Alta',
      label: 'Cordilheira Alta',
    },
    {
      value: 'Coronel Freitas',
      name: 'Coronel Freitas',
      label: 'Coronel Freitas',
    },
    {
      value: 'Coronel Martins',
      name: 'Coronel Martins',
      label: 'Coronel Martins',
    },
    { value: 'Correia Pinto', name: 'Correia Pinto', label: 'Correia Pinto' },
    { value: 'Corupá', name: 'Corupá', label: 'Corupá' },
    { value: 'Criciúma', name: 'Criciúma', label: 'Criciúma' },
    { value: 'Cunha Porã', name: 'Cunha Porã', label: 'Cunha Porã' },
    { value: 'Cunhataí', name: 'Cunhataí', label: 'Cunhataí' },
    { value: 'Curitibanos', name: 'Curitibanos', label: 'Curitibanos' },
    { value: 'Descanso', name: 'Descanso', label: 'Descanso' },
    {
      value: 'Dionísio Cerqueira',
      name: 'Dionísio Cerqueira',
      label: 'Dionísio Cerqueira',
    },
    { value: 'Dona Emma', name: 'Dona Emma', label: 'Dona Emma' },
    {
      value: 'Doutor Pedrinho',
      name: 'Doutor Pedrinho',
      label: 'Doutor Pedrinho',
    },
    { value: 'Entre Rios', name: 'Entre Rios', label: 'Entre Rios' },
    { value: 'Ermo', name: 'Ermo', label: 'Ermo' },
    { value: 'Erval Velho', name: 'Erval Velho', label: 'Erval Velho' },
    {
      value: 'Faxinal dos Guedes',
      name: 'Faxinal dos Guedes',
      label: 'Faxinal dos Guedes',
    },
    {
      value: 'Flor do Sertão',
      name: 'Flor do Sertão',
      label: 'Flor do Sertão',
    },
    { value: 'Florianópolis', name: 'Florianópolis', label: 'Florianópolis' },
    {
      value: 'Formosa do Sul',
      name: 'Formosa do Sul',
      label: 'Formosa do Sul',
    },
    { value: 'Forquilhinha', name: 'Forquilhinha', label: 'Forquilhinha' },
    { value: 'Fraiburgo', name: 'Fraiburgo', label: 'Fraiburgo' },
    { value: 'Frei Rogério', name: 'Frei Rogério', label: 'Frei Rogério' },
    { value: 'Galvão', name: 'Galvão', label: 'Galvão' },
    { value: 'Garopaba', name: 'Garopaba', label: 'Garopaba' },
    { value: 'Garuva', name: 'Garuva', label: 'Garuva' },
    { value: 'Gaspar', name: 'Gaspar', label: 'Gaspar' },
    {
      value: 'Governador Celso Ramos',
      name: 'Governador Celso Ramos',
      label: 'Governador Celso Ramos',
    },
    { value: 'Grão Pará', name: 'Grão Pará', label: 'Grão Pará' },
    { value: 'Gravatal', name: 'Gravatal', label: 'Gravatal' },
    { value: 'Guabiruba', name: 'Guabiruba', label: 'Guabiruba' },
    { value: 'Guaraciaba', name: 'Guaraciaba', label: 'Guaraciaba' },
    { value: 'Guaramirim', name: 'Guaramirim', label: 'Guaramirim' },
    {
      value: 'Guarujá do Sul',
      name: 'Guarujá do Sul',
      label: 'Guarujá do Sul',
    },
    { value: 'Guatambú', name: 'Guatambú', label: 'Guatambú' },
    {
      value: "Herval D'Oeste",
      name: "Herval D'Oeste",
      label: "Herval D'Oeste",
    },
    { value: 'Ibiam', name: 'Ibiam', label: 'Ibiam' },
    { value: 'Ibicaré', name: 'Ibicaré', label: 'Ibicaré' },
    { value: 'Ibirama', name: 'Ibirama', label: 'Ibirama' },
    { value: 'Içara', name: 'Içara', label: 'Içara' },
    { value: 'Ilhota', name: 'Ilhota', label: 'Ilhota' },
    { value: 'Imaruí', name: 'Imaruí', label: 'Imaruí' },
    { value: 'Imbituba', name: 'Imbituba', label: 'Imbituba' },
    { value: 'Imbuia', name: 'Imbuia', label: 'Imbuia' },
    { value: 'Indaial', name: 'Indaial', label: 'Indaial' },
    { value: 'Iomerê', name: 'Iomerê', label: 'Iomerê' },
    { value: 'Ipira', name: 'Ipira', label: 'Ipira' },
    {
      value: 'Iporã do Oeste',
      name: 'Iporã do Oeste',
      label: 'Iporã do Oeste',
    },
    { value: 'Ipuaçu', name: 'Ipuaçu', label: 'Ipuaçu' },
    { value: 'Ipumirim', name: 'Ipumirim', label: 'Ipumirim' },
    { value: 'Iraceminha', name: 'Iraceminha', label: 'Iraceminha' },
    { value: 'Irani', name: 'Irani', label: 'Irani' },
    { value: 'Irati', name: 'Irati', label: 'Irati' },
    { value: 'Irineópolis', name: 'Irineópolis', label: 'Irineópolis' },
    { value: 'Itá', name: 'Itá', label: 'Itá' },
    { value: 'Itaiópolis', name: 'Itaiópolis', label: 'Itaiópolis' },
    { value: 'Itajaí', name: 'Itajaí', label: 'Itajaí' },
    { value: 'Itapema', name: 'Itapema', label: 'Itapema' },
    { value: 'Itapiranga', name: 'Itapiranga', label: 'Itapiranga' },
    { value: 'Itapoá', name: 'Itapoá', label: 'Itapoá' },
    { value: 'Ituporanga', name: 'Ituporanga', label: 'Ituporanga' },
    { value: 'Jaborá', name: 'Jaborá', label: 'Jaborá' },
    {
      value: 'Jacinto Machado',
      name: 'Jacinto Machado',
      label: 'Jacinto Machado',
    },
    { value: 'Jaguaruna', name: 'Jaguaruna', label: 'Jaguaruna' },
    {
      value: 'Jaraguá do Sul',
      name: 'Jaraguá do Sul',
      label: 'Jaraguá do Sul',
    },
    { value: 'Jardinópolis', name: 'Jardinópolis', label: 'Jardinópolis' },
    { value: 'Joaçaba', name: 'Joaçaba', label: 'Joaçaba' },
    { value: 'Joinville', name: 'Joinville', label: 'Joinville' },
    { value: 'José Boiteux', name: 'José Boiteux', label: 'José Boiteux' },
    { value: 'Jupiá', name: 'Jupiá', label: 'Jupiá' },
    { value: 'Lacerdópolis', name: 'Lacerdópolis', label: 'Lacerdópolis' },
    { value: 'Lages', name: 'Lages', label: 'Lages' },
    { value: 'Laguna', name: 'Laguna', label: 'Laguna' },
    {
      value: 'Lajeado Grande',
      name: 'Lajeado Grande',
      label: 'Lajeado Grande',
    },
    { value: 'Laurentino', name: 'Laurentino', label: 'Laurentino' },
    { value: 'Lauro Muller', name: 'Lauro Muller', label: 'Lauro Muller' },
    { value: 'Lebon Régis', name: 'Lebon Régis', label: 'Lebon Régis' },
    { value: 'Leoberto Leal', name: 'Leoberto Leal', label: 'Leoberto Leal' },
    {
      value: 'Lindóia do Sul',
      name: 'Lindóia do Sul',
      label: 'Lindóia do Sul',
    },
    { value: 'Lontras', name: 'Lontras', label: 'Lontras' },
    { value: 'Luiz Alves', name: 'Luiz Alves', label: 'Luiz Alves' },
    { value: 'Luzerna', name: 'Luzerna', label: 'Luzerna' },
    { value: 'Macieira', name: 'Macieira', label: 'Macieira' },
    { value: 'Mafra', name: 'Mafra', label: 'Mafra' },
    { value: 'Major Gercino', name: 'Major Gercino', label: 'Major Gercino' },
    { value: 'Major Vieira', name: 'Major Vieira', label: 'Major Vieira' },
    { value: 'Maracajá', name: 'Maracajá', label: 'Maracajá' },
    { value: 'Maravilha', name: 'Maravilha', label: 'Maravilha' },
    { value: 'Marema', name: 'Marema', label: 'Marema' },
    { value: 'Massaranduba', name: 'Massaranduba', label: 'Massaranduba' },
    { value: 'Matos Costa', name: 'Matos Costa', label: 'Matos Costa' },
    { value: 'Meleiro', name: 'Meleiro', label: 'Meleiro' },
    { value: 'Mirim Doce', name: 'Mirim Doce', label: 'Mirim Doce' },
    { value: 'Modelo', name: 'Modelo', label: 'Modelo' },
    { value: 'Mondaí', name: 'Mondaí', label: 'Mondaí' },
    { value: 'Monte Carlo', name: 'Monte Carlo', label: 'Monte Carlo' },
    { value: 'Monte Castelo', name: 'Monte Castelo', label: 'Monte Castelo' },
    {
      value: 'Morro da Fumaça',
      name: 'Morro da Fumaça',
      label: 'Morro da Fumaça',
    },
    { value: 'Morro Grande', name: 'Morro Grande', label: 'Morro Grande' },
    { value: 'Navegantes', name: 'Navegantes', label: 'Navegantes' },
    { value: 'Nova Erechim', name: 'Nova Erechim', label: 'Nova Erechim' },
    {
      value: 'Nova Itaberaba',
      name: 'Nova Itaberaba',
      label: 'Nova Itaberaba',
    },
    { value: 'Nova Trento', name: 'Nova Trento', label: 'Nova Trento' },
    { value: 'Nova Veneza', name: 'Nova Veneza', label: 'Nova Veneza' },
    {
      value: 'Novo Horizonte',
      name: 'Novo Horizonte',
      label: 'Novo Horizonte',
    },
    { value: 'Orleans', name: 'Orleans', label: 'Orleans' },
    {
      value: 'Otacílio Costa',
      name: 'Otacílio Costa',
      label: 'Otacílio Costa',
    },
    { value: 'Ouro', name: 'Ouro', label: 'Ouro' },
    { value: 'Ouro Verde', name: 'Ouro Verde', label: 'Ouro Verde' },
    { value: 'Paial', name: 'Paial', label: 'Paial' },
    { value: 'Painel', name: 'Painel', label: 'Painel' },
    { value: 'Palhoça', name: 'Palhoça', label: 'Palhoça' },
    { value: 'Palma Sola', name: 'Palma Sola', label: 'Palma Sola' },
    { value: 'Palmeira', name: 'Palmeira', label: 'Palmeira' },
    { value: 'Palmitos', name: 'Palmitos', label: 'Palmitos' },
    { value: 'Papanduva', name: 'Papanduva', label: 'Papanduva' },
    { value: 'Paraíso', name: 'Paraíso', label: 'Paraíso' },
    {
      value: 'Passo de Torres',
      name: 'Passo de Torres',
      label: 'Passo de Torres',
    },
    { value: 'Passos Maia', name: 'Passos Maia', label: 'Passos Maia' },
    { value: 'Paulo Lopes', name: 'Paulo Lopes', label: 'Paulo Lopes' },
    {
      value: 'Pedras Grandes',
      name: 'Pedras Grandes',
      label: 'Pedras Grandes',
    },
    { value: 'Penha', name: 'Penha', label: 'Penha' },
    { value: 'Peritiba', name: 'Peritiba', label: 'Peritiba' },
    { value: 'Petrolândia', name: 'Petrolândia', label: 'Petrolândia' },
    { value: 'Piçarras', name: 'Piçarras', label: 'Piçarras' },
    { value: 'Pinhalzinho', name: 'Pinhalzinho', label: 'Pinhalzinho' },
    {
      value: 'Pinheiro Preto',
      name: 'Pinheiro Preto',
      label: 'Pinheiro Preto',
    },
    { value: 'Piratuba', name: 'Piratuba', label: 'Piratuba' },
    {
      value: 'Planalto Alegre',
      name: 'Planalto Alegre',
      label: 'Planalto Alegre',
    },
    { value: 'Pomerode', name: 'Pomerode', label: 'Pomerode' },
    { value: 'Ponte Alta', name: 'Ponte Alta', label: 'Ponte Alta' },
    {
      value: 'Ponte Alta do Norte',
      name: 'Ponte Alta do Norte',
      label: 'Ponte Alta do Norte',
    },
    { value: 'Ponte Serrada', name: 'Ponte Serrada', label: 'Ponte Serrada' },
    { value: 'Porto Belo', name: 'Porto Belo', label: 'Porto Belo' },
    { value: 'Porto União', name: 'Porto União', label: 'Porto União' },
    { value: 'Pouso Redondo', name: 'Pouso Redondo', label: 'Pouso Redondo' },
    { value: 'Praia Grande', name: 'Praia Grande', label: 'Praia Grande' },
    {
      value: 'Presidente Castelo Branco',
      name: 'Presidente Castelo Branco',
      label: 'Presidente Castelo Branco',
    },
    {
      value: 'Presidente Getúlio',
      name: 'Presidente Getúlio',
      label: 'Presidente Getúlio',
    },
    {
      value: 'Presidente Nereu',
      name: 'Presidente Nereu',
      label: 'Presidente Nereu',
    },
    { value: 'Princesa', name: 'Princesa', label: 'Princesa' },
    { value: 'Quilombo', name: 'Quilombo', label: 'Quilombo' },
    {
      value: 'Rancho Queimado',
      name: 'Rancho Queimado',
      label: 'Rancho Queimado',
    },
    { value: 'Rio das Antas', name: 'Rio das Antas', label: 'Rio das Antas' },
    { value: 'Rio do Campo', name: 'Rio do Campo', label: 'Rio do Campo' },
    { value: 'Rio do Oeste', name: 'Rio do Oeste', label: 'Rio do Oeste' },
    { value: 'Rio do Sul', name: 'Rio do Sul', label: 'Rio do Sul' },
    {
      value: 'Rio dos Cedros',
      name: 'Rio dos Cedros',
      label: 'Rio dos Cedros',
    },
    { value: 'Rio Fortuna', name: 'Rio Fortuna', label: 'Rio Fortuna' },
    { value: 'Rio Negrinho', name: 'Rio Negrinho', label: 'Rio Negrinho' },
    { value: 'Rio Rufino', name: 'Rio Rufino', label: 'Rio Rufino' },
    { value: 'Riqueza', name: 'Riqueza', label: 'Riqueza' },
    { value: 'Rodeio', name: 'Rodeio', label: 'Rodeio' },
    { value: 'Romelândia', name: 'Romelândia', label: 'Romelândia' },
    { value: 'Salete', name: 'Salete', label: 'Salete' },
    { value: 'Saltinho', name: 'Saltinho', label: 'Saltinho' },
    { value: 'Salto Veloso', name: 'Salto Veloso', label: 'Salto Veloso' },
    { value: 'Sangão', name: 'Sangão', label: 'Sangão' },
    { value: 'Santa Cecília', name: 'Santa Cecília', label: 'Santa Cecília' },
    { value: 'Santa Helena', name: 'Santa Helena', label: 'Santa Helena' },
    {
      value: 'Santa Rosa de Lima',
      name: 'Santa Rosa de Lima',
      label: 'Santa Rosa de Lima',
    },
    {
      value: 'Santa Rosa do Sul',
      name: 'Santa Rosa do Sul',
      label: 'Santa Rosa do Sul',
    },
    {
      value: 'Santa Terezinha',
      name: 'Santa Terezinha',
      label: 'Santa Terezinha',
    },
    {
      value: 'Santa Terezinha do Progresso',
      name: 'Santa Terezinha do Progresso',
      label: 'Santa Terezinha do Progresso',
    },
    {
      value: 'Santiago do Sul',
      name: 'Santiago do Sul',
      label: 'Santiago do Sul',
    },
    {
      value: 'Santo Amaro da Imperatriz',
      name: 'Santo Amaro da Imperatriz',
      label: 'Santo Amaro da Imperatriz',
    },
    {
      value: 'São Bento do Sul',
      name: 'São Bento do Sul',
      label: 'São Bento do Sul',
    },
    {
      value: 'São Bernardino',
      name: 'São Bernardino',
      label: 'São Bernardino',
    },
    { value: 'São Bonifácio', name: 'São Bonifácio', label: 'São Bonifácio' },
    { value: 'São Carlos', name: 'São Carlos', label: 'São Carlos' },
    {
      value: 'São Cristovão do Sul',
      name: 'São Cristovão do Sul',
      label: 'São Cristovão do Sul',
    },
    { value: 'São Domingos', name: 'São Domingos', label: 'São Domingos' },
    {
      value: 'São Francisco do Sul',
      name: 'São Francisco do Sul',
      label: 'São Francisco do Sul',
    },
    {
      value: 'São João Batista',
      name: 'São João Batista',
      label: 'São João Batista',
    },
    {
      value: 'São João do Itaperiú',
      name: 'São João do Itaperiú',
      label: 'São João do Itaperiú',
    },
    {
      value: 'São João do Oeste',
      name: 'São João do Oeste',
      label: 'São João do Oeste',
    },
    {
      value: 'São João do Sul',
      name: 'São João do Sul',
      label: 'São João do Sul',
    },
    { value: 'São Joaquim', name: 'São Joaquim', label: 'São Joaquim' },
    { value: 'São José', name: 'São José', label: 'São José' },
    {
      value: 'São José do Cedro',
      name: 'São José do Cedro',
      label: 'São José do Cedro',
    },
    {
      value: 'São José do Cerrito',
      name: 'São José do Cerrito',
      label: 'São José do Cerrito',
    },
    {
      value: 'São Lourenço do Oeste',
      name: 'São Lourenço do Oeste',
      label: 'São Lourenço do Oeste',
    },
    { value: 'São Ludgero', name: 'São Ludgero', label: 'São Ludgero' },
    { value: 'São Martinho', name: 'São Martinho', label: 'São Martinho' },
    {
      value: 'São Miguel da Boa Vista',
      name: 'São Miguel da Boa Vista',
      label: 'São Miguel da Boa Vista',
    },
    {
      value: 'São Miguel do Oeste',
      name: 'São Miguel do Oeste',
      label: 'São Miguel do Oeste',
    },
    {
      value: 'São Pedro de Alcântara',
      name: 'São Pedro de Alcântara',
      label: 'São Pedro de Alcântara',
    },
    { value: 'Saudades', name: 'Saudades', label: 'Saudades' },
    { value: 'Schroeder', name: 'Schroeder', label: 'Schroeder' },
    { value: 'Seara', name: 'Seara', label: 'Seara' },
    { value: 'Serra Alta', name: 'Serra Alta', label: 'Serra Alta' },
    { value: 'Siderópolis', name: 'Siderópolis', label: 'Siderópolis' },
    { value: 'Sombrio', name: 'Sombrio', label: 'Sombrio' },
    { value: 'Sul Brasil', name: 'Sul Brasil', label: 'Sul Brasil' },
    { value: 'Taió', name: 'Taió', label: 'Taió' },
    { value: 'Tangará', name: 'Tangará', label: 'Tangará' },
    { value: 'Tigrinhos', name: 'Tigrinhos', label: 'Tigrinhos' },
    { value: 'Tijucas', name: 'Tijucas', label: 'Tijucas' },
    { value: 'Timbé do Sul', name: 'Timbé do Sul', label: 'Timbé do Sul' },
    { value: 'Timbó', name: 'Timbó', label: 'Timbó' },
    { value: 'Timbó Grande', name: 'Timbó Grande', label: 'Timbó Grande' },
    { value: 'Três Barras', name: 'Três Barras', label: 'Três Barras' },
    { value: 'Treviso', name: 'Treviso', label: 'Treviso' },
    { value: 'Treze de Maio', name: 'Treze de Maio', label: 'Treze de Maio' },
    { value: 'Treze Tílias', name: 'Treze Tílias', label: 'Treze Tílias' },
    {
      value: 'Trombudo Central',
      name: 'Trombudo Central',
      label: 'Trombudo Central',
    },
    { value: 'Tubarão', name: 'Tubarão', label: 'Tubarão' },
    { value: 'Tunápolis', name: 'Tunápolis', label: 'Tunápolis' },
    { value: 'Turvo', name: 'Turvo', label: 'Turvo' },
    {
      value: 'União do Oeste',
      name: 'União do Oeste',
      label: 'União do Oeste',
    },
    { value: 'Urubici', name: 'Urubici', label: 'Urubici' },
    { value: 'Urupema', name: 'Urupema', label: 'Urupema' },
    { value: 'Urussanga', name: 'Urussanga', label: 'Urussanga' },
    { value: 'Vargeão', name: 'Vargeão', label: 'Vargeão' },
    { value: 'Vargem', name: 'Vargem', label: 'Vargem' },
    { value: 'Vargem Bonita', name: 'Vargem Bonita', label: 'Vargem Bonita' },
    { value: 'Vidal Ramos', name: 'Vidal Ramos', label: 'Vidal Ramos' },
    { value: 'Videira', name: 'Videira', label: 'Videira' },
    {
      value: 'Vitor Meireles',
      name: 'Vitor Meireles',
      label: 'Vitor Meireles',
    },
    { value: 'Witmarsum', name: 'Witmarsum', label: 'Witmarsum' },
    { value: 'Xanxerê', name: 'Xanxerê', label: 'Xanxerê' },
    { value: 'Xavantina', name: 'Xavantina', label: 'Xavantina' },
    { value: 'Xaxim', name: 'Xaxim', label: 'Xaxim' },
    { value: 'Zortéa', name: 'Zortéa', label: 'Zortéa' },
  ],
  SE: [
    {
      value: 'Amparo de São Francisco',
      name: 'Amparo de São Francisco',
      label: 'Amparo de São Francisco',
    },
    { value: 'Aquidabã', name: 'Aquidabã', label: 'Aquidabã' },
    { value: 'Aracaju', name: 'Aracaju', label: 'Aracaju' },
    { value: 'Arauá', name: 'Arauá', label: 'Arauá' },
    { value: 'Areia Branca', name: 'Areia Branca', label: 'Areia Branca' },
    {
      value: 'Barra dos Coqueiros',
      name: 'Barra dos Coqueiros',
      label: 'Barra dos Coqueiros',
    },
    { value: 'Boquim', name: 'Boquim', label: 'Boquim' },
    { value: 'Brejo Grande', name: 'Brejo Grande', label: 'Brejo Grande' },
    {
      value: 'Campo do Brito',
      name: 'Campo do Brito',
      label: 'Campo do Brito',
    },
    { value: 'Canhoba', name: 'Canhoba', label: 'Canhoba' },
    {
      value: 'Canindé de São Francisco',
      name: 'Canindé de São Francisco',
      label: 'Canindé de São Francisco',
    },
    { value: 'Capela', name: 'Capela', label: 'Capela' },
    { value: 'Carira', name: 'Carira', label: 'Carira' },
    { value: 'Carmópolis', name: 'Carmópolis', label: 'Carmópolis' },
    {
      value: 'Cedro de São João',
      name: 'Cedro de São João',
      label: 'Cedro de São João',
    },
    { value: 'Cristinápolis', name: 'Cristinápolis', label: 'Cristinápolis' },
    { value: 'Cumbe', name: 'Cumbe', label: 'Cumbe' },
    {
      value: 'Divina Pastora',
      name: 'Divina Pastora',
      label: 'Divina Pastora',
    },
    { value: 'Estância', name: 'Estância', label: 'Estância' },
    { value: 'Feira Nova', name: 'Feira Nova', label: 'Feira Nova' },
    { value: 'Frei Paulo', name: 'Frei Paulo', label: 'Frei Paulo' },
    { value: 'Gararu', name: 'Gararu', label: 'Gararu' },
    {
      value: 'General Maynard',
      name: 'General Maynard',
      label: 'General Maynard',
    },
    {
      value: 'Gracho Cardoso',
      name: 'Gracho Cardoso',
      label: 'Gracho Cardoso',
    },
    {
      value: 'Ilha das Flores',
      name: 'Ilha das Flores',
      label: 'Ilha das Flores',
    },
    { value: 'Indiaroba', name: 'Indiaroba', label: 'Indiaroba' },
    { value: 'Itabaiana', name: 'Itabaiana', label: 'Itabaiana' },
    { value: 'Itabaianinha', name: 'Itabaianinha', label: 'Itabaianinha' },
    { value: 'Itabi', name: 'Itabi', label: 'Itabi' },
    {
      value: "Itaporanga D'Ajuda",
      name: "Itaporanga D'Ajuda",
      label: "Itaporanga D'Ajuda",
    },
    { value: 'Japaratuba', name: 'Japaratuba', label: 'Japaratuba' },
    { value: 'Japoatã', name: 'Japoatã', label: 'Japoatã' },
    { value: 'Lagarto', name: 'Lagarto', label: 'Lagarto' },
    { value: 'Laranjeiras', name: 'Laranjeiras', label: 'Laranjeiras' },
    { value: 'Macambira', name: 'Macambira', label: 'Macambira' },
    {
      value: 'Malhada dos Bois',
      name: 'Malhada dos Bois',
      label: 'Malhada dos Bois',
    },
    { value: 'Malhador', name: 'Malhador', label: 'Malhador' },
    { value: 'Maruim', name: 'Maruim', label: 'Maruim' },
    { value: 'Moita Bonita', name: 'Moita Bonita', label: 'Moita Bonita' },
    {
      value: 'Monte Alegre de Sergipe',
      name: 'Monte Alegre de Sergipe',
      label: 'Monte Alegre de Sergipe',
    },
    { value: 'Muribeca', name: 'Muribeca', label: 'Muribeca' },
    { value: 'Neópolis', name: 'Neópolis', label: 'Neópolis' },
    {
      value: 'Nossa Senhora Aparecida',
      name: 'Nossa Senhora Aparecida',
      label: 'Nossa Senhora Aparecida',
    },
    {
      value: 'Nossa Senhora da Glória',
      name: 'Nossa Senhora da Glória',
      label: 'Nossa Senhora da Glória',
    },
    {
      value: 'Nossa Senhora das Dores',
      name: 'Nossa Senhora das Dores',
      label: 'Nossa Senhora das Dores',
    },
    {
      value: 'Nossa Senhora de Lourdes',
      name: 'Nossa Senhora de Lourdes',
      label: 'Nossa Senhora de Lourdes',
    },
    {
      value: 'Nossa Senhora do Socorro',
      name: 'Nossa Senhora do Socorro',
      label: 'Nossa Senhora do Socorro',
    },
    { value: 'Pacatuba', name: 'Pacatuba', label: 'Pacatuba' },
    { value: 'Pedra Mole', name: 'Pedra Mole', label: 'Pedra Mole' },
    { value: 'Pedrinhas', name: 'Pedrinhas', label: 'Pedrinhas' },
    { value: 'Pinhão', name: 'Pinhão', label: 'Pinhão' },
    { value: 'Pirambu', name: 'Pirambu', label: 'Pirambu' },
    { value: 'Poço Redondo', name: 'Poço Redondo', label: 'Poço Redondo' },
    { value: 'Poço Verde', name: 'Poço Verde', label: 'Poço Verde' },
    {
      value: 'Porto da Folha',
      name: 'Porto da Folha',
      label: 'Porto da Folha',
    },
    { value: 'Propriá', name: 'Propriá', label: 'Propriá' },
    {
      value: 'Riachão do Dantas',
      name: 'Riachão do Dantas',
      label: 'Riachão do Dantas',
    },
    { value: 'Riachuelo', name: 'Riachuelo', label: 'Riachuelo' },
    { value: 'Ribeirópolis', name: 'Ribeirópolis', label: 'Ribeirópolis' },
    {
      value: 'Rosário do Catete',
      name: 'Rosário do Catete',
      label: 'Rosário do Catete',
    },
    { value: 'Salgado', name: 'Salgado', label: 'Salgado' },
    {
      value: 'Santa Luzia do Itanhy',
      name: 'Santa Luzia do Itanhy',
      label: 'Santa Luzia do Itanhy',
    },
    {
      value: 'Santa Rosa de Lima',
      name: 'Santa Rosa de Lima',
      label: 'Santa Rosa de Lima',
    },
    {
      value: 'Santana do São Francisco',
      name: 'Santana do São Francisco',
      label: 'Santana do São Francisco',
    },
    {
      value: 'Santo Amaro das Brotas',
      name: 'Santo Amaro das Brotas',
      label: 'Santo Amaro das Brotas',
    },
    { value: 'São Cristóvão', name: 'São Cristóvão', label: 'São Cristóvão' },
    { value: 'São Domingos', name: 'São Domingos', label: 'São Domingos' },
    { value: 'São Francisco', name: 'São Francisco', label: 'São Francisco' },
    {
      value: 'São Miguel do Aleixo',
      name: 'São Miguel do Aleixo',
      label: 'São Miguel do Aleixo',
    },
    { value: 'Simão Dias', name: 'Simão Dias', label: 'Simão Dias' },
    { value: 'Siriri', name: 'Siriri', label: 'Siriri' },
    { value: 'Telha', name: 'Telha', label: 'Telha' },
    {
      value: 'Tobias Barreto',
      name: 'Tobias Barreto',
      label: 'Tobias Barreto',
    },
    { value: 'Tomar do Geru', name: 'Tomar do Geru', label: 'Tomar do Geru' },
    { value: 'Umbaúba ', name: 'Umbaúba ', label: 'Umbaúba ' },
  ],
  SP: [
    { value: 'Adamantina', name: 'Adamantina', label: 'Adamantina' },
    { value: 'Adolfo', name: 'Adolfo', label: 'Adolfo' },
    { value: 'Aguaí', name: 'Aguaí', label: 'Aguaí' },
    {
      value: 'Águas da Prata',
      name: 'Águas da Prata',
      label: 'Águas da Prata',
    },
    {
      value: 'Águas de Lindóia',
      name: 'Águas de Lindóia',
      label: 'Águas de Lindóia',
    },
    {
      value: 'Águas de Santa Bárbara',
      name: 'Águas de Santa Bárbara',
      label: 'Águas de Santa Bárbara',
    },
    {
      value: 'Águas de São Pedro',
      name: 'Águas de São Pedro',
      label: 'Águas de São Pedro',
    },
    { value: 'Agudos', name: 'Agudos', label: 'Agudos' },
    { value: 'Alambari', name: 'Alambari', label: 'Alambari' },
    {
      value: 'Alfredo Marcondes',
      name: 'Alfredo Marcondes',
      label: 'Alfredo Marcondes',
    },
    { value: 'Altair', name: 'Altair', label: 'Altair' },
    { value: 'Altinópolis', name: 'Altinópolis', label: 'Altinópolis' },
    { value: 'Alto Alegre', name: 'Alto Alegre', label: 'Alto Alegre' },
    { value: 'Alumínio', name: 'Alumínio', label: 'Alumínio' },
    {
      value: 'Álvares Florence',
      name: 'Álvares Florence',
      label: 'Álvares Florence',
    },
    {
      value: 'Álvares Machado',
      name: 'Álvares Machado',
      label: 'Álvares Machado',
    },
    {
      value: 'Álvaro de Carvalho',
      name: 'Álvaro de Carvalho',
      label: 'Álvaro de Carvalho',
    },
    { value: 'Alvinlândia', name: 'Alvinlândia', label: 'Alvinlândia' },
    { value: 'Americana', name: 'Americana', label: 'Americana' },
    {
      value: 'Américo Brasiliense',
      name: 'Américo Brasiliense',
      label: 'Américo Brasiliense',
    },
    {
      value: 'Américo de Campos',
      name: 'Américo de Campos',
      label: 'Américo de Campos',
    },
    { value: 'Amparo', name: 'Amparo', label: 'Amparo' },
    { value: 'Analândia', name: 'Analândia', label: 'Analândia' },
    { value: 'Andradina', name: 'Andradina', label: 'Andradina' },
    { value: 'Angatuba', name: 'Angatuba', label: 'Angatuba' },
    { value: 'Anhembi', name: 'Anhembi', label: 'Anhembi' },
    { value: 'Anhumas', name: 'Anhumas', label: 'Anhumas' },
    { value: 'Aparecida', name: 'Aparecida', label: 'Aparecida' },
    {
      value: "Aparecida D'Oeste",
      name: "Aparecida D'Oeste",
      label: "Aparecida D'Oeste",
    },
    { value: 'Apiaí', name: 'Apiaí', label: 'Apiaí' },
    { value: 'Araçariguama', name: 'Araçariguama', label: 'Araçariguama' },
    { value: 'Araçatuba', name: 'Araçatuba', label: 'Araçatuba' },
    {
      value: 'Araçoiaba da Serra',
      name: 'Araçoiaba da Serra',
      label: 'Araçoiaba da Serra',
    },
    { value: 'Aramina', name: 'Aramina', label: 'Aramina' },
    { value: 'Arandu', name: 'Arandu', label: 'Arandu' },
    { value: 'Arapeí', name: 'Arapeí', label: 'Arapeí' },
    { value: 'Araraquara', name: 'Araraquara', label: 'Araraquara' },
    { value: 'Araras', name: 'Araras', label: 'Araras' },
    { value: 'Arco-Íris', name: 'Arco-Íris', label: 'Arco-Íris' },
    { value: 'Arealva', name: 'Arealva', label: 'Arealva' },
    { value: 'Areias', name: 'Areias', label: 'Areias' },
    { value: 'Areiópolis', name: 'Areiópolis', label: 'Areiópolis' },
    { value: 'Ariranha', name: 'Ariranha', label: 'Ariranha' },
    {
      value: 'Artur Nogueira',
      name: 'Artur Nogueira',
      label: 'Artur Nogueira',
    },
    { value: 'Arujá', name: 'Arujá', label: 'Arujá' },
    { value: 'Aspásia', name: 'Aspásia', label: 'Aspásia' },
    { value: 'Assis', name: 'Assis', label: 'Assis' },
    { value: 'Atibaia', name: 'Atibaia', label: 'Atibaia' },
    { value: 'Auriflama', name: 'Auriflama', label: 'Auriflama' },
    { value: 'Avaí', name: 'Avaí', label: 'Avaí' },
    { value: 'Avanhandava', name: 'Avanhandava', label: 'Avanhandava' },
    { value: 'Avaré', name: 'Avaré', label: 'Avaré' },
    { value: 'Bady Bassitt', name: 'Bady Bassitt', label: 'Bady Bassitt' },
    { value: 'Balbinos', name: 'Balbinos', label: 'Balbinos' },
    { value: 'Bálsamo', name: 'Bálsamo', label: 'Bálsamo' },
    { value: 'Bananal', name: 'Bananal', label: 'Bananal' },
    {
      value: 'Barão de Antonina',
      name: 'Barão de Antonina',
      label: 'Barão de Antonina',
    },
    { value: 'Barbosa', name: 'Barbosa', label: 'Barbosa' },
    { value: 'Bariri', name: 'Bariri', label: 'Bariri' },
    { value: 'Barra Bonita', name: 'Barra Bonita', label: 'Barra Bonita' },
    {
      value: 'Barra do Chapéu',
      name: 'Barra do Chapéu',
      label: 'Barra do Chapéu',
    },
    {
      value: 'Barra do Turvo',
      name: 'Barra do Turvo',
      label: 'Barra do Turvo',
    },
    { value: 'Barretos', name: 'Barretos', label: 'Barretos' },
    { value: 'Barrinha', name: 'Barrinha', label: 'Barrinha' },
    { value: 'Barueri', name: 'Barueri', label: 'Barueri' },
    { value: 'Bastos', name: 'Bastos', label: 'Bastos' },
    { value: 'Batatais', name: 'Batatais', label: 'Batatais' },
    { value: 'Bauru', name: 'Bauru', label: 'Bauru' },
    { value: 'Bebedouro', name: 'Bebedouro', label: 'Bebedouro' },
    {
      value: 'Bento de Abreu',
      name: 'Bento de Abreu',
      label: 'Bento de Abreu',
    },
    {
      value: 'Bernardino de Campos',
      name: 'Bernardino de Campos',
      label: 'Bernardino de Campos',
    },
    { value: 'Bertioga', name: 'Bertioga', label: 'Bertioga' },
    { value: 'Bilac', name: 'Bilac', label: 'Bilac' },
    { value: 'Birigui', name: 'Birigui', label: 'Birigui' },
    {
      value: 'Biritiba-Mirim',
      name: 'Biritiba-Mirim',
      label: 'Biritiba-Mirim',
    },
    {
      value: 'Boa Esperança do Sul',
      name: 'Boa Esperança do Sul',
      label: 'Boa Esperança do Sul',
    },
    { value: 'Bocaina', name: 'Bocaina', label: 'Bocaina' },
    { value: 'Bofete', name: 'Bofete', label: 'Bofete' },
    { value: 'Boituva', name: 'Boituva', label: 'Boituva' },
    {
      value: 'Bom Jesus dos Perdões',
      name: 'Bom Jesus dos Perdões',
      label: 'Bom Jesus dos Perdões',
    },
    {
      value: 'Bom Sucesso de Itararé',
      name: 'Bom Sucesso de Itararé',
      label: 'Bom Sucesso de Itararé',
    },
    { value: 'Borá', name: 'Borá', label: 'Borá' },
    { value: 'Boracéia', name: 'Boracéia', label: 'Boracéia' },
    { value: 'Borborema', name: 'Borborema', label: 'Borborema' },
    { value: 'Borebi', name: 'Borebi', label: 'Borebi' },
    { value: 'Botucatu', name: 'Botucatu', label: 'Botucatu' },
    {
      value: 'Bragança Paulista',
      name: 'Bragança Paulista',
      label: 'Bragança Paulista',
    },
    { value: 'Braúna', name: 'Braúna', label: 'Braúna' },
    { value: 'Brejo Alegre', name: 'Brejo Alegre', label: 'Brejo Alegre' },
    { value: 'Brodowski', name: 'Brodowski', label: 'Brodowski' },
    { value: 'Brotas', name: 'Brotas', label: 'Brotas' },
    { value: 'Buri', name: 'Buri', label: 'Buri' },
    { value: 'Buritama', name: 'Buritama', label: 'Buritama' },
    { value: 'Buritizal', name: 'Buritizal', label: 'Buritizal' },
    {
      value: 'Cabrália Paulista',
      name: 'Cabrália Paulista',
      label: 'Cabrália Paulista',
    },
    { value: 'Cabreúva', name: 'Cabreúva', label: 'Cabreúva' },
    { value: 'Caçapava', name: 'Caçapava', label: 'Caçapava' },
    {
      value: 'Cachoeira Paulista',
      name: 'Cachoeira Paulista',
      label: 'Cachoeira Paulista',
    },
    { value: 'Caconde', name: 'Caconde', label: 'Caconde' },
    { value: 'Cafelândia', name: 'Cafelândia', label: 'Cafelândia' },
    { value: 'Caiabu', name: 'Caiabu', label: 'Caiabu' },
    { value: 'Caieiras', name: 'Caieiras', label: 'Caieiras' },
    { value: 'Caiuá', name: 'Caiuá', label: 'Caiuá' },
    { value: 'Cajamar', name: 'Cajamar', label: 'Cajamar' },
    { value: 'Cajati', name: 'Cajati', label: 'Cajati' },
    { value: 'Cajobi', name: 'Cajobi', label: 'Cajobi' },
    { value: 'Cajuru', name: 'Cajuru', label: 'Cajuru' },
    {
      value: 'Campina do Monte Alegre',
      name: 'Campina do Monte Alegre',
      label: 'Campina do Monte Alegre',
    },
    { value: 'Campinas', name: 'Campinas', label: 'Campinas' },
    {
      value: 'Campo Limpo Paulista',
      name: 'Campo Limpo Paulista',
      label: 'Campo Limpo Paulista',
    },
    {
      value: 'Campos do Jordão',
      name: 'Campos do Jordão',
      label: 'Campos do Jordão',
    },
    {
      value: 'Campos Novos Paulista',
      name: 'Campos Novos Paulista',
      label: 'Campos Novos Paulista',
    },
    { value: 'Cananéia', name: 'Cananéia', label: 'Cananéia' },
    { value: 'Canas', name: 'Canas', label: 'Canas' },
    { value: 'Cândido Mota', name: 'Cândido Mota', label: 'Cândido Mota' },
    {
      value: 'Cândido Rodrigues',
      name: 'Cândido Rodrigues',
      label: 'Cândido Rodrigues',
    },
    { value: 'Canitar', name: 'Canitar', label: 'Canitar' },
    { value: 'Capão Bonito', name: 'Capão Bonito', label: 'Capão Bonito' },
    {
      value: 'Capela do Alto',
      name: 'Capela do Alto',
      label: 'Capela do Alto',
    },
    { value: 'Capivari', name: 'Capivari', label: 'Capivari' },
    { value: 'Caraguatatuba', name: 'Caraguatatuba', label: 'Caraguatatuba' },
    { value: 'Carapicuíba', name: 'Carapicuíba', label: 'Carapicuíba' },
    { value: 'Cardoso', name: 'Cardoso', label: 'Cardoso' },
    { value: 'Casa Branca', name: 'Casa Branca', label: 'Casa Branca' },
    {
      value: 'Cássia dos Coqueiros',
      name: 'Cássia dos Coqueiros',
      label: 'Cássia dos Coqueiros',
    },
    { value: 'Castilho', name: 'Castilho', label: 'Castilho' },
    { value: 'Catanduva', name: 'Catanduva', label: 'Catanduva' },
    { value: 'Catiguá', name: 'Catiguá', label: 'Catiguá' },
    { value: 'Cedral', name: 'Cedral', label: 'Cedral' },
    {
      value: 'Cerqueira César',
      name: 'Cerqueira César',
      label: 'Cerqueira César',
    },
    { value: 'Cerquilho', name: 'Cerquilho', label: 'Cerquilho' },
    { value: 'Cesário Lange', name: 'Cesário Lange', label: 'Cesário Lange' },
    { value: 'Charqueada', name: 'Charqueada', label: 'Charqueada' },
    { value: 'Chavantes', name: 'Chavantes', label: 'Chavantes' },
    { value: 'Clementina', name: 'Clementina', label: 'Clementina' },
    { value: 'Colina', name: 'Colina', label: 'Colina' },
    { value: 'Colômbia', name: 'Colômbia', label: 'Colômbia' },
    { value: 'Conchal', name: 'Conchal', label: 'Conchal' },
    { value: 'Conchas', name: 'Conchas', label: 'Conchas' },
    { value: 'Cordeirópolis', name: 'Cordeirópolis', label: 'Cordeirópolis' },
    { value: 'Coroados', name: 'Coroados', label: 'Coroados' },
    {
      value: 'Coronel Macedo',
      name: 'Coronel Macedo',
      label: 'Coronel Macedo',
    },
    { value: 'Corumbataí', name: 'Corumbataí', label: 'Corumbataí' },
    { value: 'Cosmópolis', name: 'Cosmópolis', label: 'Cosmópolis' },
    { value: 'Cosmorama', name: 'Cosmorama', label: 'Cosmorama' },
    { value: 'Cotia', name: 'Cotia', label: 'Cotia' },
    { value: 'Cravinhos', name: 'Cravinhos', label: 'Cravinhos' },
    {
      value: 'Cristais Paulista',
      name: 'Cristais Paulista',
      label: 'Cristais Paulista',
    },
    { value: 'Cruzália', name: 'Cruzália', label: 'Cruzália' },
    { value: 'Cruzeiro', name: 'Cruzeiro', label: 'Cruzeiro' },
    { value: 'Cubatão', name: 'Cubatão', label: 'Cubatão' },
    { value: 'Cunha', name: 'Cunha', label: 'Cunha' },
    { value: 'Descalvado', name: 'Descalvado', label: 'Descalvado' },
    { value: 'Diadema', name: 'Diadema', label: 'Diadema' },
    { value: 'Dirce Reis', name: 'Dirce Reis', label: 'Dirce Reis' },
    { value: 'Divinolândia', name: 'Divinolândia', label: 'Divinolândia' },
    { value: 'Dobrada', name: 'Dobrada', label: 'Dobrada' },
    { value: 'Dois Córregos', name: 'Dois Córregos', label: 'Dois Córregos' },
    { value: 'Dolcinópolis', name: 'Dolcinópolis', label: 'Dolcinópolis' },
    { value: 'Dourado', name: 'Dourado', label: 'Dourado' },
    { value: 'Dracena', name: 'Dracena', label: 'Dracena' },
    { value: 'Duartina', name: 'Duartina', label: 'Duartina' },
    { value: 'Dumont', name: 'Dumont', label: 'Dumont' },
    { value: 'Echaporã', name: 'Echaporã', label: 'Echaporã' },
    { value: 'Eldorado', name: 'Eldorado', label: 'Eldorado' },
    { value: 'Elias Fausto', name: 'Elias Fausto', label: 'Elias Fausto' },
    { value: 'Elisiário', name: 'Elisiário', label: 'Elisiário' },
    { value: 'Embaúba', name: 'Embaúba', label: 'Embaúba' },
    {
      value: 'Embu das Artes',
      name: 'Embu das Artes',
      label: 'Embu das Artes',
    },
    { value: 'Embu-Guaçu', name: 'Embu-Guaçu', label: 'Embu-Guaçu' },
    { value: 'Emilianópolis', name: 'Emilianópolis', label: 'Emilianópolis' },
    {
      value: 'Engenheiro Coelho',
      name: 'Engenheiro Coelho',
      label: 'Engenheiro Coelho',
    },
    {
      value: 'Espírito Santo do Pinhal',
      name: 'Espírito Santo do Pinhal',
      label: 'Espírito Santo do Pinhal',
    },
    {
      value: 'Espírito Santo do Turvo',
      name: 'Espírito Santo do Turvo',
      label: 'Espírito Santo do Turvo',
    },
    { value: 'Estiva Gerbi', name: 'Estiva Gerbi', label: 'Estiva Gerbi' },
    {
      value: "Estrela D'Oeste",
      name: "Estrela D'Oeste",
      label: "Estrela D'Oeste",
    },
    {
      value: 'Estrela do Norte',
      name: 'Estrela do Norte',
      label: 'Estrela do Norte',
    },
    {
      value: 'Euclides da Cunha Paulista',
      name: 'Euclides da Cunha Paulista',
      label: 'Euclides da Cunha Paulista',
    },
    { value: 'Fartura', name: 'Fartura', label: 'Fartura' },
    {
      value: 'Fernando Prestes',
      name: 'Fernando Prestes',
      label: 'Fernando Prestes',
    },
    { value: 'Fernandópolis', name: 'Fernandópolis', label: 'Fernandópolis' },
    { value: 'Fernão', name: 'Fernão', label: 'Fernão' },
    {
      value: 'Ferraz de Vasconcelos',
      name: 'Ferraz de Vasconcelos',
      label: 'Ferraz de Vasconcelos',
    },
    { value: 'Flora Rica', name: 'Flora Rica', label: 'Flora Rica' },
    { value: 'Floreal', name: 'Floreal', label: 'Floreal' },
    { value: 'Florínia', name: 'Florínia', label: 'Florínia' },
    {
      value: 'Flórida Paulista',
      name: 'Flórida Paulista',
      label: 'Flórida Paulista',
    },
    { value: 'Franca', name: 'Franca', label: 'Franca' },
    {
      value: 'Francisco Morato',
      name: 'Francisco Morato',
      label: 'Francisco Morato',
    },
    {
      value: 'Franco da Rocha',
      name: 'Franco da Rocha',
      label: 'Franco da Rocha',
    },
    {
      value: 'Gabriel Monteiro',
      name: 'Gabriel Monteiro',
      label: 'Gabriel Monteiro',
    },
    { value: 'Gália', name: 'Gália', label: 'Gália' },
    { value: 'Garça', name: 'Garça', label: 'Garça' },
    {
      value: 'Gastão Vidigal',
      name: 'Gastão Vidigal',
      label: 'Gastão Vidigal',
    },
    {
      value: 'Gavião Peixoto',
      name: 'Gavião Peixoto',
      label: 'Gavião Peixoto',
    },
    {
      value: 'General Salgado',
      name: 'General Salgado',
      label: 'General Salgado',
    },
    { value: 'Getulina', name: 'Getulina', label: 'Getulina' },
    { value: 'Glicério', name: 'Glicério', label: 'Glicério' },
    { value: 'Guaiçara', name: 'Guaiçara', label: 'Guaiçara' },
    { value: 'Guaimbê', name: 'Guaimbê', label: 'Guaimbê' },
    { value: 'Guaíra', name: 'Guaíra', label: 'Guaíra' },
    { value: 'Guapiaçu', name: 'Guapiaçu', label: 'Guapiaçu' },
    { value: 'Guapiara', name: 'Guapiara', label: 'Guapiara' },
    { value: 'Guará', name: 'Guará', label: 'Guará' },
    { value: 'Guaraçaí', name: 'Guaraçaí', label: 'Guaraçaí' },
    { value: 'Guaraci', name: 'Guaraci', label: 'Guaraci' },
    {
      value: "Guarani D'Oeste",
      name: "Guarani D'Oeste",
      label: "Guarani D'Oeste",
    },
    { value: 'Guarantã', name: 'Guarantã', label: 'Guarantã' },
    { value: 'Guararapes', name: 'Guararapes', label: 'Guararapes' },
    { value: 'Guararema', name: 'Guararema', label: 'Guararema' },
    { value: 'Guaratinguetá', name: 'Guaratinguetá', label: 'Guaratinguetá' },
    { value: 'Guareí', name: 'Guareí', label: 'Guareí' },
    { value: 'Guariba', name: 'Guariba', label: 'Guariba' },
    { value: 'Guarujá', name: 'Guarujá', label: 'Guarujá' },
    { value: 'Guarulhos', name: 'Guarulhos', label: 'Guarulhos' },
    { value: 'Guatapará', name: 'Guatapará', label: 'Guatapará' },
    { value: 'Guzolândia', name: 'Guzolândia', label: 'Guzolândia' },
    { value: 'Herculândia', name: 'Herculândia', label: 'Herculândia' },
    { value: 'Holambra', name: 'Holambra', label: 'Holambra' },
    { value: 'Hortolândia', name: 'Hortolândia', label: 'Hortolândia' },
    { value: 'Iacanga', name: 'Iacanga', label: 'Iacanga' },
    { value: 'Iacri', name: 'Iacri', label: 'Iacri' },
    { value: 'Iaras', name: 'Iaras', label: 'Iaras' },
    { value: 'Ibaté', name: 'Ibaté', label: 'Ibaté' },
    { value: 'Ibirá', name: 'Ibirá', label: 'Ibirá' },
    { value: 'Ibirarema', name: 'Ibirarema', label: 'Ibirarema' },
    { value: 'Ibitinga', name: 'Ibitinga', label: 'Ibitinga' },
    { value: 'Ibiúna', name: 'Ibiúna', label: 'Ibiúna' },
    { value: 'Icém', name: 'Icém', label: 'Icém' },
    { value: 'Iepê', name: 'Iepê', label: 'Iepê' },
    {
      value: 'Igaraçu do Tietê',
      name: 'Igaraçu do Tietê',
      label: 'Igaraçu do Tietê',
    },
    { value: 'Igarapava', name: 'Igarapava', label: 'Igarapava' },
    { value: 'Igaratá', name: 'Igaratá', label: 'Igaratá' },
    { value: 'Iguape', name: 'Iguape', label: 'Iguape' },
    { value: 'Ilha Comprida', name: 'Ilha Comprida', label: 'Ilha Comprida' },
    { value: 'Ilha Solteira', name: 'Ilha Solteira', label: 'Ilha Solteira' },
    { value: 'Ilhabela', name: 'Ilhabela', label: 'Ilhabela' },
    { value: 'Indaiatuba', name: 'Indaiatuba', label: 'Indaiatuba' },
    { value: 'Indiana', name: 'Indiana', label: 'Indiana' },
    { value: 'Indiaporã', name: 'Indiaporã', label: 'Indiaporã' },
    {
      value: 'Inúbia Paulista',
      name: 'Inúbia Paulista',
      label: 'Inúbia Paulista',
    },
    { value: 'Ipaussu', name: 'Ipaussu', label: 'Ipaussu' },
    { value: 'Iperó', name: 'Iperó', label: 'Iperó' },
    { value: 'Ipeúna', name: 'Ipeúna', label: 'Ipeúna' },
    { value: 'Ipiguá', name: 'Ipiguá', label: 'Ipiguá' },
    { value: 'Iporanga', name: 'Iporanga', label: 'Iporanga' },
    { value: 'Ipuã', name: 'Ipuã', label: 'Ipuã' },
    { value: 'Iracemápolis', name: 'Iracemápolis', label: 'Iracemápolis' },
    { value: 'Irapuã', name: 'Irapuã', label: 'Irapuã' },
    { value: 'Irapuru', name: 'Irapuru', label: 'Irapuru' },
    { value: 'Itaberá', name: 'Itaberá', label: 'Itaberá' },
    { value: 'Itaí', name: 'Itaí', label: 'Itaí' },
    { value: 'Itajobi', name: 'Itajobi', label: 'Itajobi' },
    { value: 'Itaju', name: 'Itaju', label: 'Itaju' },
    { value: 'Itanhaém', name: 'Itanhaém', label: 'Itanhaém' },
    { value: 'Itaóca', name: 'Itaóca', label: 'Itaóca' },
    {
      value: 'Itapecerica da Serra',
      name: 'Itapecerica da Serra',
      label: 'Itapecerica da Serra',
    },
    { value: 'Itapetininga', name: 'Itapetininga', label: 'Itapetininga' },
    { value: 'Itapeva', name: 'Itapeva', label: 'Itapeva' },
    { value: 'Itapevi', name: 'Itapevi', label: 'Itapevi' },
    { value: 'Itapira', name: 'Itapira', label: 'Itapira' },
    {
      value: 'Itapirapuã Paulista',
      name: 'Itapirapuã Paulista',
      label: 'Itapirapuã Paulista',
    },
    { value: 'Itápolis', name: 'Itápolis', label: 'Itápolis' },
    { value: 'Itaporanga', name: 'Itaporanga', label: 'Itaporanga' },
    { value: 'Itapuí', name: 'Itapuí', label: 'Itapuí' },
    { value: 'Itapura', name: 'Itapura', label: 'Itapura' },
    {
      value: 'Itaquaquecetuba',
      name: 'Itaquaquecetuba',
      label: 'Itaquaquecetuba',
    },
    { value: 'Itararé', name: 'Itararé', label: 'Itararé' },
    { value: 'Itariri', name: 'Itariri', label: 'Itariri' },
    { value: 'Itatiba', name: 'Itatiba', label: 'Itatiba' },
    { value: 'Itatinga', name: 'Itatinga', label: 'Itatinga' },
    { value: 'Itirapina', name: 'Itirapina', label: 'Itirapina' },
    { value: 'Itirapuã', name: 'Itirapuã', label: 'Itirapuã' },
    { value: 'Itobi', name: 'Itobi', label: 'Itobi' },
    { value: 'Itu', name: 'Itu', label: 'Itu' },
    { value: 'Itupeva', name: 'Itupeva', label: 'Itupeva' },
    { value: 'Ituverava', name: 'Ituverava', label: 'Ituverava' },
    { value: 'Jaborandi', name: 'Jaborandi', label: 'Jaborandi' },
    { value: 'Jaboticabal', name: 'Jaboticabal', label: 'Jaboticabal' },
    { value: 'Jacareí', name: 'Jacareí', label: 'Jacareí' },
    { value: 'Jaci', name: 'Jaci', label: 'Jaci' },
    { value: 'Jacupiranga', name: 'Jacupiranga', label: 'Jacupiranga' },
    { value: 'Jaguariúna', name: 'Jaguariúna', label: 'Jaguariúna' },
    { value: 'Jales', name: 'Jales', label: 'Jales' },
    { value: 'Jambeiro', name: 'Jambeiro', label: 'Jambeiro' },
    { value: 'Jandira', name: 'Jandira', label: 'Jandira' },
    { value: 'Jardinópolis', name: 'Jardinópolis', label: 'Jardinópolis' },
    { value: 'Jarinu', name: 'Jarinu', label: 'Jarinu' },
    { value: 'Jaú', name: 'Jaú', label: 'Jaú' },
    { value: 'Jeriquara', name: 'Jeriquara', label: 'Jeriquara' },
    { value: 'Joanópolis', name: 'Joanópolis', label: 'Joanópolis' },
    { value: 'João Ramalho', name: 'João Ramalho', label: 'João Ramalho' },
    {
      value: 'José Bonifácio',
      name: 'José Bonifácio',
      label: 'José Bonifácio',
    },
    {
      value: 'Júlio Mesquita',
      name: 'Júlio Mesquita',
      label: 'Júlio Mesquita',
    },
    { value: 'Jumirim', name: 'Jumirim', label: 'Jumirim' },
    { value: 'Jundiaí', name: 'Jundiaí', label: 'Jundiaí' },
    {
      value: 'Junqueirópolis',
      name: 'Junqueirópolis',
      label: 'Junqueirópolis',
    },
    { value: 'Juquiá', name: 'Juquiá', label: 'Juquiá' },
    { value: 'Juquitiba', name: 'Juquitiba', label: 'Juquitiba' },
    { value: 'Lagoinha', name: 'Lagoinha', label: 'Lagoinha' },
    {
      value: 'Laranjal Paulista',
      name: 'Laranjal Paulista',
      label: 'Laranjal Paulista',
    },
    { value: 'Lavínia', name: 'Lavínia', label: 'Lavínia' },
    { value: 'Lavrinhas', name: 'Lavrinhas', label: 'Lavrinhas' },
    { value: 'Leme', name: 'Leme', label: 'Leme' },
    {
      value: 'Lençóis Paulista',
      name: 'Lençóis Paulista',
      label: 'Lençóis Paulista',
    },
    { value: 'Limeira', name: 'Limeira', label: 'Limeira' },
    { value: 'Lindóia', name: 'Lindóia', label: 'Lindóia' },
    { value: 'Lins', name: 'Lins', label: 'Lins' },
    { value: 'Lorena', name: 'Lorena', label: 'Lorena' },
    { value: 'Lourdes', name: 'Lourdes', label: 'Lourdes' },
    { value: 'Louveira', name: 'Louveira', label: 'Louveira' },
    { value: 'Lucélia', name: 'Lucélia', label: 'Lucélia' },
    { value: 'Lucianópolis', name: 'Lucianópolis', label: 'Lucianópolis' },
    { value: 'Luís Antônio', name: 'Luís Antônio', label: 'Luís Antônio' },
    { value: 'Luiziânia', name: 'Luiziânia', label: 'Luiziânia' },
    { value: 'Lupércio', name: 'Lupércio', label: 'Lupércio' },
    { value: 'Lutécia', name: 'Lutécia', label: 'Lutécia' },
    { value: 'Macatuba', name: 'Macatuba', label: 'Macatuba' },
    { value: 'Macaubal', name: 'Macaubal', label: 'Macaubal' },
    { value: 'Macedônia', name: 'Macedônia', label: 'Macedônia' },
    { value: 'Magda', name: 'Magda', label: 'Magda' },
    { value: 'Mairinque', name: 'Mairinque', label: 'Mairinque' },
    { value: 'Mairiporã', name: 'Mairiporã', label: 'Mairiporã' },
    { value: 'Manduri', name: 'Manduri', label: 'Manduri' },
    {
      value: 'Marabá Paulista',
      name: 'Marabá Paulista',
      label: 'Marabá Paulista',
    },
    { value: 'Maracaí', name: 'Maracaí', label: 'Maracaí' },
    { value: 'Marapoama', name: 'Marapoama', label: 'Marapoama' },
    { value: 'Mariápolis', name: 'Mariápolis', label: 'Mariápolis' },
    { value: 'Marília', name: 'Marília', label: 'Marília' },
    { value: 'Marinópolis', name: 'Marinópolis', label: 'Marinópolis' },
    { value: 'Martinópolis', name: 'Martinópolis', label: 'Martinópolis' },
    { value: 'Matão', name: 'Matão', label: 'Matão' },
    { value: 'Mauá', name: 'Mauá', label: 'Mauá' },
    { value: 'Mendonça', name: 'Mendonça', label: 'Mendonça' },
    { value: 'Meridiano', name: 'Meridiano', label: 'Meridiano' },
    { value: 'Mesópolis', name: 'Mesópolis', label: 'Mesópolis' },
    { value: 'Miguelópolis', name: 'Miguelópolis', label: 'Miguelópolis' },
    {
      value: 'Mineiros do Tietê',
      name: 'Mineiros do Tietê',
      label: 'Mineiros do Tietê',
    },
    { value: 'Mira Estrela', name: 'Mira Estrela', label: 'Mira Estrela' },
    { value: 'Miracatu', name: 'Miracatu', label: 'Miracatu' },
    { value: 'Mirandópolis', name: 'Mirandópolis', label: 'Mirandópolis' },
    {
      value: 'Mirante do Paranapanema',
      name: 'Mirante do Paranapanema',
      label: 'Mirante do Paranapanema',
    },
    { value: 'Mirassol', name: 'Mirassol', label: 'Mirassol' },
    { value: 'Mirassolândia', name: 'Mirassolândia', label: 'Mirassolândia' },
    { value: 'Mococa', name: 'Mococa', label: 'Mococa' },
    {
      value: 'Mogi das Cruzes',
      name: 'Mogi das Cruzes',
      label: 'Mogi das Cruzes',
    },
    { value: 'Mogi Guaçu', name: 'Mogi Guaçu', label: 'Mogi Guaçu' },
    { value: 'Mogi Mirim', name: 'Mogi Mirim', label: 'Mogi Mirim' },
    { value: 'Mombuca', name: 'Mombuca', label: 'Mombuca' },
    { value: 'Monções', name: 'Monções', label: 'Monções' },
    { value: 'Mongaguá', name: 'Mongaguá', label: 'Mongaguá' },
    {
      value: 'Monte Alegre do Sul',
      name: 'Monte Alegre do Sul',
      label: 'Monte Alegre do Sul',
    },
    { value: 'Monte Alto', name: 'Monte Alto', label: 'Monte Alto' },
    {
      value: 'Monte Aprazível',
      name: 'Monte Aprazível',
      label: 'Monte Aprazível',
    },
    {
      value: 'Monte Azul Paulista',
      name: 'Monte Azul Paulista',
      label: 'Monte Azul Paulista',
    },
    { value: 'Monte Castelo', name: 'Monte Castelo', label: 'Monte Castelo' },
    { value: 'Monte Mor', name: 'Monte Mor', label: 'Monte Mor' },
    {
      value: 'Monteiro Lobato',
      name: 'Monteiro Lobato',
      label: 'Monteiro Lobato',
    },
    { value: 'Morro Agudo', name: 'Morro Agudo', label: 'Morro Agudo' },
    { value: 'Morungaba', name: 'Morungaba', label: 'Morungaba' },
    { value: 'Motuca', name: 'Motuca', label: 'Motuca' },
    {
      value: 'Murutinga do Sul',
      name: 'Murutinga do Sul',
      label: 'Murutinga do Sul',
    },
    { value: 'Nantes', name: 'Nantes', label: 'Nantes' },
    { value: 'Narandiba', name: 'Narandiba', label: 'Narandiba' },
    {
      value: 'Natividade da Serra',
      name: 'Natividade da Serra',
      label: 'Natividade da Serra',
    },
    {
      value: 'Nazaré Paulista',
      name: 'Nazaré Paulista',
      label: 'Nazaré Paulista',
    },
    {
      value: 'Neves Paulista',
      name: 'Neves Paulista',
      label: 'Neves Paulista',
    },
    { value: 'Nhandeara', name: 'Nhandeara', label: 'Nhandeara' },
    { value: 'Nipoã', name: 'Nipoã', label: 'Nipoã' },
    { value: 'Nova Aliança', name: 'Nova Aliança', label: 'Nova Aliança' },
    { value: 'Nova Campina', name: 'Nova Campina', label: 'Nova Campina' },
    {
      value: 'Nova Canaã Paulista',
      name: 'Nova Canaã Paulista',
      label: 'Nova Canaã Paulista',
    },
    { value: 'Nova Castilho', name: 'Nova Castilho', label: 'Nova Castilho' },
    { value: 'Nova Europa', name: 'Nova Europa', label: 'Nova Europa' },
    { value: 'Nova Granada', name: 'Nova Granada', label: 'Nova Granada' },
    {
      value: 'Nova Guataporanga',
      name: 'Nova Guataporanga',
      label: 'Nova Guataporanga',
    },
    {
      value: 'Nova Independência',
      name: 'Nova Independência',
      label: 'Nova Independência',
    },
    {
      value: 'Nova Luzitânia',
      name: 'Nova Luzitânia',
      label: 'Nova Luzitânia',
    },
    { value: 'Nova Odessa', name: 'Nova Odessa', label: 'Nova Odessa' },
    { value: 'Novais', name: 'Novais', label: 'Novais' },
    {
      value: 'Novo Horizonte',
      name: 'Novo Horizonte',
      label: 'Novo Horizonte',
    },
    { value: 'Nuporanga', name: 'Nuporanga', label: 'Nuporanga' },
    { value: 'Ocauçu', name: 'Ocauçu', label: 'Ocauçu' },
    { value: 'Óleo', name: 'Óleo', label: 'Óleo' },
    { value: 'Olímpia', name: 'Olímpia', label: 'Olímpia' },
    { value: 'Onda Verde', name: 'Onda Verde', label: 'Onda Verde' },
    { value: 'Oriente', name: 'Oriente', label: 'Oriente' },
    { value: 'Orindiúva', name: 'Orindiúva', label: 'Orindiúva' },
    { value: 'Orlândia', name: 'Orlândia', label: 'Orlândia' },
    { value: 'Osasco', name: 'Osasco', label: 'Osasco' },
    {
      value: 'Oscar Bressane',
      name: 'Oscar Bressane',
      label: 'Oscar Bressane',
    },
    { value: 'Osvaldo Cruz', name: 'Osvaldo Cruz', label: 'Osvaldo Cruz' },
    { value: 'Ourinhos', name: 'Ourinhos', label: 'Ourinhos' },
    { value: 'Ouro Verde', name: 'Ouro Verde', label: 'Ouro Verde' },
    { value: 'Ouroeste', name: 'Ouroeste', label: 'Ouroeste' },
    { value: 'Pacaembu', name: 'Pacaembu', label: 'Pacaembu' },
    { value: 'Palestina', name: 'Palestina', label: 'Palestina' },
    {
      value: 'Palmares Paulista',
      name: 'Palmares Paulista',
      label: 'Palmares Paulista',
    },
    {
      value: "Palmeira D'Oeste",
      name: "Palmeira D'Oeste",
      label: "Palmeira D'Oeste",
    },
    { value: 'Palmital', name: 'Palmital', label: 'Palmital' },
    { value: 'Panorama', name: 'Panorama', label: 'Panorama' },
    {
      value: 'Paraguaçu Paulista',
      name: 'Paraguaçu Paulista',
      label: 'Paraguaçu Paulista',
    },
    { value: 'Paraibuna', name: 'Paraibuna', label: 'Paraibuna' },
    { value: 'Paraíso', name: 'Paraíso', label: 'Paraíso' },
    { value: 'Paranapanema', name: 'Paranapanema', label: 'Paranapanema' },
    { value: 'Paranapuã', name: 'Paranapuã', label: 'Paranapuã' },
    { value: 'Parapuã', name: 'Parapuã', label: 'Parapuã' },
    { value: 'Pardinho', name: 'Pardinho', label: 'Pardinho' },
    { value: 'Pariquera-Açu', name: 'Pariquera-Açu', label: 'Pariquera-Açu' },
    { value: 'Parisi', name: 'Parisi', label: 'Parisi' },
    {
      value: 'Patrocínio Paulista',
      name: 'Patrocínio Paulista',
      label: 'Patrocínio Paulista',
    },
    { value: 'Paulicéia', name: 'Paulicéia', label: 'Paulicéia' },
    { value: 'Paulínia', name: 'Paulínia', label: 'Paulínia' },
    { value: 'Paulistânia', name: 'Paulistânia', label: 'Paulistânia' },
    {
      value: 'Paulo de Faria',
      name: 'Paulo de Faria',
      label: 'Paulo de Faria',
    },
    { value: 'Pederneiras', name: 'Pederneiras', label: 'Pederneiras' },
    { value: 'Pedra Bela', name: 'Pedra Bela', label: 'Pedra Bela' },
    { value: 'Pedranópolis', name: 'Pedranópolis', label: 'Pedranópolis' },
    { value: 'Pedregulho', name: 'Pedregulho', label: 'Pedregulho' },
    { value: 'Pedreira', name: 'Pedreira', label: 'Pedreira' },
    {
      value: 'Pedrinhas Paulista',
      name: 'Pedrinhas Paulista',
      label: 'Pedrinhas Paulista',
    },
    {
      value: 'Pedro de Toledo',
      name: 'Pedro de Toledo',
      label: 'Pedro de Toledo',
    },
    { value: 'Penápolis', name: 'Penápolis', label: 'Penápolis' },
    {
      value: 'Pereira Barreto',
      name: 'Pereira Barreto',
      label: 'Pereira Barreto',
    },
    { value: 'Pereiras', name: 'Pereiras', label: 'Pereiras' },
    { value: 'Peruíbe', name: 'Peruíbe', label: 'Peruíbe' },
    { value: 'Piacatu', name: 'Piacatu', label: 'Piacatu' },
    { value: 'Piedade', name: 'Piedade', label: 'Piedade' },
    { value: 'Pilar do Sul', name: 'Pilar do Sul', label: 'Pilar do Sul' },
    {
      value: 'Pindamonhangaba',
      name: 'Pindamonhangaba',
      label: 'Pindamonhangaba',
    },
    { value: 'Pindorama', name: 'Pindorama', label: 'Pindorama' },
    { value: 'Pinhalzinho', name: 'Pinhalzinho', label: 'Pinhalzinho' },
    { value: 'Piquerobi', name: 'Piquerobi', label: 'Piquerobi' },
    { value: 'Piquete', name: 'Piquete', label: 'Piquete' },
    { value: 'Piracaia', name: 'Piracaia', label: 'Piracaia' },
    { value: 'Piracicaba', name: 'Piracicaba', label: 'Piracicaba' },
    { value: 'Piraju', name: 'Piraju', label: 'Piraju' },
    { value: 'Pirajuí', name: 'Pirajuí', label: 'Pirajuí' },
    { value: 'Pirangi', name: 'Pirangi', label: 'Pirangi' },
    {
      value: 'Pirapora do Bom Jesus',
      name: 'Pirapora do Bom Jesus',
      label: 'Pirapora do Bom Jesus',
    },
    { value: 'Pirapozinho', name: 'Pirapozinho', label: 'Pirapozinho' },
    { value: 'Pirassununga', name: 'Pirassununga', label: 'Pirassununga' },
    { value: 'Piratininga', name: 'Piratininga', label: 'Piratininga' },
    { value: 'Pitangueiras', name: 'Pitangueiras', label: 'Pitangueiras' },
    { value: 'Planalto', name: 'Planalto', label: 'Planalto' },
    { value: 'Platina', name: 'Platina', label: 'Platina' },
    { value: 'Poá', name: 'Poá', label: 'Poá' },
    { value: 'Poloni', name: 'Poloni', label: 'Poloni' },
    { value: 'Pompéia', name: 'Pompéia', label: 'Pompéia' },
    { value: 'Pongaí', name: 'Pongaí', label: 'Pongaí' },
    { value: 'Pontal', name: 'Pontal', label: 'Pontal' },
    { value: 'Pontalinda', name: 'Pontalinda', label: 'Pontalinda' },
    { value: 'Pontes Gestal', name: 'Pontes Gestal', label: 'Pontes Gestal' },
    { value: 'Populina', name: 'Populina', label: 'Populina' },
    { value: 'Porangaba', name: 'Porangaba', label: 'Porangaba' },
    { value: 'Porto Feliz', name: 'Porto Feliz', label: 'Porto Feliz' },
    {
      value: 'Porto Ferreira',
      name: 'Porto Ferreira',
      label: 'Porto Ferreira',
    },
    { value: 'Potim', name: 'Potim', label: 'Potim' },
    { value: 'Potirendaba', name: 'Potirendaba', label: 'Potirendaba' },
    { value: 'Pracinha', name: 'Pracinha', label: 'Pracinha' },
    { value: 'Pradópolis', name: 'Pradópolis', label: 'Pradópolis' },
    { value: 'Praia Grande', name: 'Praia Grande', label: 'Praia Grande' },
    { value: 'Pratânia', name: 'Pratânia', label: 'Pratânia' },
    {
      value: 'Presidente Alves',
      name: 'Presidente Alves',
      label: 'Presidente Alves',
    },
    {
      value: 'Presidente Bernardes',
      name: 'Presidente Bernardes',
      label: 'Presidente Bernardes',
    },
    {
      value: 'Presidente Epitácio',
      name: 'Presidente Epitácio',
      label: 'Presidente Epitácio',
    },
    {
      value: 'Presidente Prudente',
      name: 'Presidente Prudente',
      label: 'Presidente Prudente',
    },
    {
      value: 'Presidente Venceslau',
      name: 'Presidente Venceslau',
      label: 'Presidente Venceslau',
    },
    { value: 'Promissão', name: 'Promissão', label: 'Promissão' },
    { value: 'Quadra', name: 'Quadra', label: 'Quadra' },
    { value: 'Quatá', name: 'Quatá', label: 'Quatá' },
    { value: 'Queiroz', name: 'Queiroz', label: 'Queiroz' },
    { value: 'Queluz', name: 'Queluz', label: 'Queluz' },
    { value: 'Quintana', name: 'Quintana', label: 'Quintana' },
    { value: 'Rafard', name: 'Rafard', label: 'Rafard' },
    { value: 'Rancharia', name: 'Rancharia', label: 'Rancharia' },
    {
      value: 'Redenção da Serra',
      name: 'Redenção da Serra',
      label: 'Redenção da Serra',
    },
    { value: 'Regente Feijó', name: 'Regente Feijó', label: 'Regente Feijó' },
    { value: 'Reginópolis', name: 'Reginópolis', label: 'Reginópolis' },
    { value: 'Registro', name: 'Registro', label: 'Registro' },
    { value: 'Restinga', name: 'Restinga', label: 'Restinga' },
    { value: 'Ribeira', name: 'Ribeira', label: 'Ribeira' },
    {
      value: 'Ribeirão Bonito',
      name: 'Ribeirão Bonito',
      label: 'Ribeirão Bonito',
    },
    {
      value: 'Ribeirão Branco',
      name: 'Ribeirão Branco',
      label: 'Ribeirão Branco',
    },
    {
      value: 'Ribeirão Corrente',
      name: 'Ribeirão Corrente',
      label: 'Ribeirão Corrente',
    },
    {
      value: 'Ribeirão do Sul',
      name: 'Ribeirão do Sul',
      label: 'Ribeirão do Sul',
    },
    {
      value: 'Ribeirão dos Índios',
      name: 'Ribeirão dos Índios',
      label: 'Ribeirão dos Índios',
    },
    {
      value: 'Ribeirão Grande',
      name: 'Ribeirão Grande',
      label: 'Ribeirão Grande',
    },
    {
      value: 'Ribeirão Pires',
      name: 'Ribeirão Pires',
      label: 'Ribeirão Pires',
    },
    {
      value: 'Ribeirão Preto',
      name: 'Ribeirão Preto',
      label: 'Ribeirão Preto',
    },
    { value: 'Rifaina', name: 'Rifaina', label: 'Rifaina' },
    { value: 'Rincão', name: 'Rincão', label: 'Rincão' },
    { value: 'Rinópolis', name: 'Rinópolis', label: 'Rinópolis' },
    { value: 'Rio Claro', name: 'Rio Claro', label: 'Rio Claro' },
    {
      value: 'Rio das Pedras',
      name: 'Rio das Pedras',
      label: 'Rio das Pedras',
    },
    {
      value: 'Rio Grande da Serra',
      name: 'Rio Grande da Serra',
      label: 'Rio Grande da Serra',
    },
    { value: 'Riolândia', name: 'Riolândia', label: 'Riolândia' },
    { value: 'Riversul', name: 'Riversul', label: 'Riversul' },
    { value: 'Rosana', name: 'Rosana', label: 'Rosana' },
    { value: 'Roseira', name: 'Roseira', label: 'Roseira' },
    { value: 'Rubiácea', name: 'Rubiácea', label: 'Rubiácea' },
    { value: 'Rubinéia', name: 'Rubinéia', label: 'Rubinéia' },
    { value: 'Sabino', name: 'Sabino', label: 'Sabino' },
    { value: 'Sagres', name: 'Sagres', label: 'Sagres' },
    { value: 'Sales', name: 'Sales', label: 'Sales' },
    {
      value: 'Sales Oliveira',
      name: 'Sales Oliveira',
      label: 'Sales Oliveira',
    },
    { value: 'Salesópolis', name: 'Salesópolis', label: 'Salesópolis' },
    { value: 'Salmourão', name: 'Salmourão', label: 'Salmourão' },
    { value: 'Saltinho', name: 'Saltinho', label: 'Saltinho' },
    { value: 'Salto', name: 'Salto', label: 'Salto' },
    {
      value: 'Salto de Pirapora',
      name: 'Salto de Pirapora',
      label: 'Salto de Pirapora',
    },
    { value: 'Salto Grande', name: 'Salto Grande', label: 'Salto Grande' },
    { value: 'Sandovalina', name: 'Sandovalina', label: 'Sandovalina' },
    { value: 'Santa Adélia', name: 'Santa Adélia', label: 'Santa Adélia' },
    {
      value: 'Santa Albertina',
      name: 'Santa Albertina',
      label: 'Santa Albertina',
    },
    {
      value: "Santa Bárbara D'Oeste",
      name: "Santa Bárbara D'Oeste",
      label: "Santa Bárbara D'Oeste",
    },
    { value: 'Santa Branca', name: 'Santa Branca', label: 'Santa Branca' },
    {
      value: "Santa Clara D'Oeste",
      name: "Santa Clara D'Oeste",
      label: "Santa Clara D'Oeste",
    },
    {
      value: 'Santa Cruz da Conceição',
      name: 'Santa Cruz da Conceição',
      label: 'Santa Cruz da Conceição',
    },
    {
      value: 'Santa Cruz da Esperança',
      name: 'Santa Cruz da Esperança',
      label: 'Santa Cruz da Esperança',
    },
    {
      value: 'Santa Cruz das Palmeiras',
      name: 'Santa Cruz das Palmeiras',
      label: 'Santa Cruz das Palmeiras',
    },
    {
      value: 'Santa Cruz do Rio Pardo',
      name: 'Santa Cruz do Rio Pardo',
      label: 'Santa Cruz do Rio Pardo',
    },
    {
      value: 'Santa Ernestina',
      name: 'Santa Ernestina',
      label: 'Santa Ernestina',
    },
    {
      value: 'Santa Fé do Sul',
      name: 'Santa Fé do Sul',
      label: 'Santa Fé do Sul',
    },
    {
      value: 'Santa Gertrudes',
      name: 'Santa Gertrudes',
      label: 'Santa Gertrudes',
    },
    { value: 'Santa Isabel', name: 'Santa Isabel', label: 'Santa Isabel' },
    { value: 'Santa Lúcia', name: 'Santa Lúcia', label: 'Santa Lúcia' },
    {
      value: 'Santa Maria da Serra',
      name: 'Santa Maria da Serra',
      label: 'Santa Maria da Serra',
    },
    {
      value: 'Santa Mercedes',
      name: 'Santa Mercedes',
      label: 'Santa Mercedes',
    },
    {
      value: "Santa Rita D'Oeste",
      name: "Santa Rita D'Oeste",
      label: "Santa Rita D'Oeste",
    },
    {
      value: 'Santa Rita do Passa Quatro',
      name: 'Santa Rita do Passa Quatro',
      label: 'Santa Rita do Passa Quatro',
    },
    {
      value: 'Santa Rosa de Viterbo',
      name: 'Santa Rosa de Viterbo',
      label: 'Santa Rosa de Viterbo',
    },
    { value: 'Santa Salete', name: 'Santa Salete', label: 'Santa Salete' },
    {
      value: 'Santana da Ponte Pensa',
      name: 'Santana da Ponte Pensa',
      label: 'Santana da Ponte Pensa',
    },
    {
      value: 'Santana de Parnaíba',
      name: 'Santana de Parnaíba',
      label: 'Santana de Parnaíba',
    },
    {
      value: 'Santo Anastácio',
      name: 'Santo Anastácio',
      label: 'Santo Anastácio',
    },
    { value: 'Santo André', name: 'Santo André', label: 'Santo André' },
    {
      value: 'Santo Antônio da Alegria',
      name: 'Santo Antônio da Alegria',
      label: 'Santo Antônio da Alegria',
    },
    {
      value: 'Santo Antônio de Posse',
      name: 'Santo Antônio de Posse',
      label: 'Santo Antônio de Posse',
    },
    {
      value: 'Santo Antônio do Aracanguá',
      name: 'Santo Antônio do Aracanguá',
      label: 'Santo Antônio do Aracanguá',
    },
    {
      value: 'Santo Antônio do Jardim',
      name: 'Santo Antônio do Jardim',
      label: 'Santo Antônio do Jardim',
    },
    {
      value: 'Santo Antônio do Pinhal',
      name: 'Santo Antônio do Pinhal',
      label: 'Santo Antônio do Pinhal',
    },
    {
      value: 'Santo Expedito',
      name: 'Santo Expedito',
      label: 'Santo Expedito',
    },
    {
      value: 'Santópolis do Aguapeí',
      name: 'Santópolis do Aguapeí',
      label: 'Santópolis do Aguapeí',
    },
    { value: 'Santos', name: 'Santos', label: 'Santos' },
    {
      value: 'São Bento do Sapucaí',
      name: 'São Bento do Sapucaí',
      label: 'São Bento do Sapucaí',
    },
    {
      value: 'São Bernardo do Campo',
      name: 'São Bernardo do Campo',
      label: 'São Bernardo do Campo',
    },
    {
      value: 'São Caetano do Sul',
      name: 'São Caetano do Sul',
      label: 'São Caetano do Sul',
    },
    { value: 'São Carlos', name: 'São Carlos', label: 'São Carlos' },
    { value: 'São Francisco', name: 'São Francisco', label: 'São Francisco' },
    {
      value: 'São João da Boa Vista',
      name: 'São João da Boa Vista',
      label: 'São João da Boa Vista',
    },
    {
      value: 'São João das Duas Pontes',
      name: 'São João das Duas Pontes',
      label: 'São João das Duas Pontes',
    },
    {
      value: 'São João de Iracema',
      name: 'São João de Iracema',
      label: 'São João de Iracema',
    },
    {
      value: "São João do Pau D'Alho",
      name: "São João do Pau D'Alho",
      label: "São João do Pau D'Alho",
    },
    {
      value: 'São Joaquim da Barra',
      name: 'São Joaquim da Barra',
      label: 'São Joaquim da Barra',
    },
    {
      value: 'São José da Bela Vista',
      name: 'São José da Bela Vista',
      label: 'São José da Bela Vista',
    },
    {
      value: 'São José do Barreiro',
      name: 'São José do Barreiro',
      label: 'São José do Barreiro',
    },
    {
      value: 'São José do Rio Pardo',
      name: 'São José do Rio Pardo',
      label: 'São José do Rio Pardo',
    },
    {
      value: 'São José do Rio Preto',
      name: 'São José do Rio Preto',
      label: 'São José do Rio Preto',
    },
    {
      value: 'São José dos Campos',
      name: 'São José dos Campos',
      label: 'São José dos Campos',
    },
    {
      value: 'São Lourenço da Serra',
      name: 'São Lourenço da Serra',
      label: 'São Lourenço da Serra',
    },
    {
      value: 'São Luís do Paraitinga',
      name: 'São Luís do Paraitinga',
      label: 'São Luís do Paraitinga',
    },
    { value: 'São Manuel', name: 'São Manuel', label: 'São Manuel' },
    {
      value: 'São Miguel Arcanjo',
      name: 'São Miguel Arcanjo',
      label: 'São Miguel Arcanjo',
    },
    { value: 'São Paulo', name: 'São Paulo', label: 'São Paulo' },
    { value: 'São Pedro', name: 'São Pedro', label: 'São Pedro' },
    {
      value: 'São Pedro do Turvo',
      name: 'São Pedro do Turvo',
      label: 'São Pedro do Turvo',
    },
    { value: 'São Roque', name: 'São Roque', label: 'São Roque' },
    { value: 'São Sebastião', name: 'São Sebastião', label: 'São Sebastião' },
    {
      value: 'São Sebastião da Grama',
      name: 'São Sebastião da Grama',
      label: 'São Sebastião da Grama',
    },
    { value: 'São Simão', name: 'São Simão', label: 'São Simão' },
    { value: 'São Vicente', name: 'São Vicente', label: 'São Vicente' },
    { value: 'Sarapuí', name: 'Sarapuí', label: 'Sarapuí' },
    { value: 'Sarutaiá', name: 'Sarutaiá', label: 'Sarutaiá' },
    {
      value: 'Sebastianópolis do Sul',
      name: 'Sebastianópolis do Sul',
      label: 'Sebastianópolis do Sul',
    },
    { value: 'Serra Azul', name: 'Serra Azul', label: 'Serra Azul' },
    { value: 'Serra Negra', name: 'Serra Negra', label: 'Serra Negra' },
    { value: 'Serrana', name: 'Serrana', label: 'Serrana' },
    { value: 'Sertãozinho', name: 'Sertãozinho', label: 'Sertãozinho' },
    { value: 'Sete Barras', name: 'Sete Barras', label: 'Sete Barras' },
    { value: 'Severínia', name: 'Severínia', label: 'Severínia' },
    { value: 'Silveiras', name: 'Silveiras', label: 'Silveiras' },
    { value: 'Socorro', name: 'Socorro', label: 'Socorro' },
    { value: 'Sorocaba', name: 'Sorocaba', label: 'Sorocaba' },
    { value: 'Sud Mennucci', name: 'Sud Mennucci', label: 'Sud Mennucci' },
    { value: 'Sumaré', name: 'Sumaré', label: 'Sumaré' },
    { value: 'Suzanápolis', name: 'Suzanápolis', label: 'Suzanápolis' },
    { value: 'Suzano', name: 'Suzano', label: 'Suzano' },
    { value: 'Tabapuã', name: 'Tabapuã', label: 'Tabapuã' },
    { value: 'Tabatinga', name: 'Tabatinga', label: 'Tabatinga' },
    {
      value: 'Taboão da Serra',
      name: 'Taboão da Serra',
      label: 'Taboão da Serra',
    },
    { value: 'Taciba', name: 'Taciba', label: 'Taciba' },
    { value: 'Taguaí', name: 'Taguaí', label: 'Taguaí' },
    { value: 'Taiaçu', name: 'Taiaçu', label: 'Taiaçu' },
    { value: 'Taiúva', name: 'Taiúva', label: 'Taiúva' },
    { value: 'Tambaú', name: 'Tambaú', label: 'Tambaú' },
    { value: 'Tanabi', name: 'Tanabi', label: 'Tanabi' },
    { value: 'Tapiraí', name: 'Tapiraí', label: 'Tapiraí' },
    { value: 'Tapiratiba', name: 'Tapiratiba', label: 'Tapiratiba' },
    { value: 'Taquaral', name: 'Taquaral', label: 'Taquaral' },
    { value: 'Taquaritinga', name: 'Taquaritinga', label: 'Taquaritinga' },
    { value: 'Taquarituba', name: 'Taquarituba', label: 'Taquarituba' },
    { value: 'Taquarivaí', name: 'Taquarivaí', label: 'Taquarivaí' },
    { value: 'Tarabai', name: 'Tarabai', label: 'Tarabai' },
    { value: 'Tarumã', name: 'Tarumã', label: 'Tarumã' },
    { value: 'Tatuí', name: 'Tatuí', label: 'Tatuí' },
    { value: 'Taubaté', name: 'Taubaté', label: 'Taubaté' },
    { value: 'Tejupá', name: 'Tejupá', label: 'Tejupá' },
    {
      value: 'Teodoro Sampaio',
      name: 'Teodoro Sampaio',
      label: 'Teodoro Sampaio',
    },
    { value: 'Terra Roxa', name: 'Terra Roxa', label: 'Terra Roxa' },
    { value: 'Tietê', name: 'Tietê', label: 'Tietê' },
    { value: 'Timburi', name: 'Timburi', label: 'Timburi' },
    {
      value: 'Torre de Pedra',
      name: 'Torre de Pedra',
      label: 'Torre de Pedra',
    },
    { value: 'Torrinha', name: 'Torrinha', label: 'Torrinha' },
    { value: 'Trabiju', name: 'Trabiju', label: 'Trabiju' },
    { value: 'Tremembé', name: 'Tremembé', label: 'Tremembé' },
    {
      value: 'Três Fronteiras',
      name: 'Três Fronteiras',
      label: 'Três Fronteiras',
    },
    { value: 'Tuiuti', name: 'Tuiuti', label: 'Tuiuti' },
    { value: 'Tupã', name: 'Tupã', label: 'Tupã' },
    { value: 'Tupi Paulista', name: 'Tupi Paulista', label: 'Tupi Paulista' },
    { value: 'Turiúba', name: 'Turiúba', label: 'Turiúba' },
    { value: 'Turmalina', name: 'Turmalina', label: 'Turmalina' },
    { value: 'Ubarana', name: 'Ubarana', label: 'Ubarana' },
    { value: 'Ubatuba', name: 'Ubatuba', label: 'Ubatuba' },
    { value: 'Ubirajara', name: 'Ubirajara', label: 'Ubirajara' },
    { value: 'Uchoa', name: 'Uchoa', label: 'Uchoa' },
    {
      value: 'União Paulista',
      name: 'União Paulista',
      label: 'União Paulista',
    },
    { value: 'Urânia', name: 'Urânia', label: 'Urânia' },
    { value: 'Uru', name: 'Uru', label: 'Uru' },
    { value: 'Urupês', name: 'Urupês', label: 'Urupês' },
    {
      value: 'Valentim Gentil',
      name: 'Valentim Gentil',
      label: 'Valentim Gentil',
    },
    { value: 'Valinhos', name: 'Valinhos', label: 'Valinhos' },
    { value: 'Valparaíso', name: 'Valparaíso', label: 'Valparaíso' },
    { value: 'Vargem', name: 'Vargem', label: 'Vargem' },
    {
      value: 'Vargem Grande do Sul',
      name: 'Vargem Grande do Sul',
      label: 'Vargem Grande do Sul',
    },
    {
      value: 'Vargem Grande Paulista',
      name: 'Vargem Grande Paulista',
      label: 'Vargem Grande Paulista',
    },
    {
      value: 'Várzea Paulista',
      name: 'Várzea Paulista',
      label: 'Várzea Paulista',
    },
    { value: 'Vera Cruz', name: 'Vera Cruz', label: 'Vera Cruz' },
    { value: 'Vinhedo', name: 'Vinhedo', label: 'Vinhedo' },
    { value: 'Viradouro', name: 'Viradouro', label: 'Viradouro' },
    {
      value: 'Vista Alegre do Alto',
      name: 'Vista Alegre do Alto',
      label: 'Vista Alegre do Alto',
    },
    {
      value: 'Vitória Brasil',
      name: 'Vitória Brasil',
      label: 'Vitória Brasil',
    },
    { value: 'Votorantim', name: 'Votorantim', label: 'Votorantim' },
    { value: 'Votuporanga', name: 'Votuporanga', label: 'Votuporanga' },
    { value: 'Zacarias ', name: 'Zacarias ', label: 'Zacarias ' },
  ],
  TO: [
    { value: 'Abreulândia', name: 'Abreulândia', label: 'Abreulândia' },
    { value: 'Aguiarnópolis', name: 'Aguiarnópolis', label: 'Aguiarnópolis' },
    {
      value: 'Aliança do Tocantins',
      name: 'Aliança do Tocantins',
      label: 'Aliança do Tocantins',
    },
    { value: 'Almas', name: 'Almas', label: 'Almas' },
    { value: 'Alvorada', name: 'Alvorada', label: 'Alvorada' },
    { value: 'Ananás', name: 'Ananás', label: 'Ananás' },
    { value: 'Angico', name: 'Angico', label: 'Angico' },
    {
      value: 'Aparecida do Rio Negro',
      name: 'Aparecida do Rio Negro',
      label: 'Aparecida do Rio Negro',
    },
    { value: 'Aragominas', name: 'Aragominas', label: 'Aragominas' },
    { value: 'Araguacema', name: 'Araguacema', label: 'Araguacema' },
    { value: 'Araguaçu', name: 'Araguaçu', label: 'Araguaçu' },
    { value: 'Araguaína', name: 'Araguaína', label: 'Araguaína' },
    { value: 'Araguanã', name: 'Araguanã', label: 'Araguanã' },
    { value: 'Araguatins', name: 'Araguatins', label: 'Araguatins' },
    { value: 'Arapoema', name: 'Arapoema', label: 'Arapoema' },
    { value: 'Arraias', name: 'Arraias', label: 'Arraias' },
    {
      value: 'Augustinópolis',
      name: 'Augustinópolis',
      label: 'Augustinópolis',
    },
    {
      value: 'Aurora do Tocantins',
      name: 'Aurora do Tocantins',
      label: 'Aurora do Tocantins',
    },
    {
      value: 'Axixá do Tocantins',
      name: 'Axixá do Tocantins',
      label: 'Axixá do Tocantins',
    },
    { value: 'Babaçulândia', name: 'Babaçulândia', label: 'Babaçulândia' },
    {
      value: 'Bandeirantes do Tocantins',
      name: 'Bandeirantes do Tocantins',
      label: 'Bandeirantes do Tocantins',
    },
    { value: 'Barra do Ouro', name: 'Barra do Ouro', label: 'Barra do Ouro' },
    { value: 'Barrolândia', name: 'Barrolândia', label: 'Barrolândia' },
    {
      value: 'Bernardo Sayão',
      name: 'Bernardo Sayão',
      label: 'Bernardo Sayão',
    },
    {
      value: 'Bom Jesus do Tocantins',
      name: 'Bom Jesus do Tocantins',
      label: 'Bom Jesus do Tocantins',
    },
    {
      value: 'Brasilândia do Tocantins',
      name: 'Brasilândia do Tocantins',
      label: 'Brasilândia do Tocantins',
    },
    {
      value: 'Brejinho de Nazaré',
      name: 'Brejinho de Nazaré',
      label: 'Brejinho de Nazaré',
    },
    {
      value: 'Buriti do Tocantins',
      name: 'Buriti do Tocantins',
      label: 'Buriti do Tocantins',
    },
    { value: 'Cachoeirinha', name: 'Cachoeirinha', label: 'Cachoeirinha' },
    { value: 'Campos Lindos', name: 'Campos Lindos', label: 'Campos Lindos' },
    {
      value: 'Cariri do Tocantins',
      name: 'Cariri do Tocantins',
      label: 'Cariri do Tocantins',
    },
    { value: 'Carmolândia', name: 'Carmolândia', label: 'Carmolândia' },
    {
      value: 'Carrasco Bonito',
      name: 'Carrasco Bonito',
      label: 'Carrasco Bonito',
    },
    { value: 'Caseara', name: 'Caseara', label: 'Caseara' },
    { value: 'Centenário', name: 'Centenário', label: 'Centenário' },
    {
      value: 'Chapada da Natividade',
      name: 'Chapada da Natividade',
      label: 'Chapada da Natividade',
    },
    {
      value: 'Chapada de Areia',
      name: 'Chapada de Areia',
      label: 'Chapada de Areia',
    },
    {
      value: 'Colinas do Tocantins',
      name: 'Colinas do Tocantins',
      label: 'Colinas do Tocantins',
    },
    { value: 'Colméia', name: 'Colméia', label: 'Colméia' },
    { value: 'Combinado', name: 'Combinado', label: 'Combinado' },
    {
      value: 'Conceição do Tocantins',
      name: 'Conceição do Tocantins',
      label: 'Conceição do Tocantins',
    },
    {
      value: 'Couto de Magalhães',
      name: 'Couto de Magalhães',
      label: 'Couto de Magalhães',
    },
    { value: 'Cristalândia', name: 'Cristalândia', label: 'Cristalândia' },
    {
      value: 'Crixás do Tocantins',
      name: 'Crixás do Tocantins',
      label: 'Crixás do Tocantins',
    },
    { value: 'Darcinópolis', name: 'Darcinópolis', label: 'Darcinópolis' },
    { value: 'Dianópolis', name: 'Dianópolis', label: 'Dianópolis' },
    {
      value: 'Divinópolis do Tocantins',
      name: 'Divinópolis do Tocantins',
      label: 'Divinópolis do Tocantins',
    },
    {
      value: 'Dois Irmãos do Tocantins',
      name: 'Dois Irmãos do Tocantins',
      label: 'Dois Irmãos do Tocantins',
    },
    { value: 'Dueré', name: 'Dueré', label: 'Dueré' },
    { value: 'Esperantina', name: 'Esperantina', label: 'Esperantina' },
    { value: 'Fátima', name: 'Fátima', label: 'Fátima' },
    { value: 'Figueirópolis', name: 'Figueirópolis', label: 'Figueirópolis' },
    { value: 'Filadélfia', name: 'Filadélfia', label: 'Filadélfia' },
    {
      value: 'Formoso do Araguaia',
      name: 'Formoso do Araguaia',
      label: 'Formoso do Araguaia',
    },
    {
      value: 'Fortaleza do Tabocão',
      name: 'Fortaleza do Tabocão',
      label: 'Fortaleza do Tabocão',
    },
    { value: 'Goianorte', name: 'Goianorte', label: 'Goianorte' },
    { value: 'Goiatins', name: 'Goiatins', label: 'Goiatins' },
    { value: 'Guaraí', name: 'Guaraí', label: 'Guaraí' },
    { value: 'Gurupi', name: 'Gurupi', label: 'Gurupi' },
    { value: 'Ipueiras', name: 'Ipueiras', label: 'Ipueiras' },
    { value: 'Itacajá', name: 'Itacajá', label: 'Itacajá' },
    { value: 'Itaguatins', name: 'Itaguatins', label: 'Itaguatins' },
    { value: 'Itapiratins', name: 'Itapiratins', label: 'Itapiratins' },
    {
      value: 'Itaporã do Tocantins',
      name: 'Itaporã do Tocantins',
      label: 'Itaporã do Tocantins',
    },
    {
      value: 'Jaú do Tocantins',
      name: 'Jaú do Tocantins',
      label: 'Jaú do Tocantins',
    },
    { value: 'Juarina', name: 'Juarina', label: 'Juarina' },
    {
      value: 'Lagoa da Confusão',
      name: 'Lagoa da Confusão',
      label: 'Lagoa da Confusão',
    },
    {
      value: 'Lagoa do Tocantins',
      name: 'Lagoa do Tocantins',
      label: 'Lagoa do Tocantins',
    },
    { value: 'Lajeado', name: 'Lajeado', label: 'Lajeado' },
    { value: 'Lavandeira', name: 'Lavandeira', label: 'Lavandeira' },
    { value: 'Lizarda', name: 'Lizarda', label: 'Lizarda' },
    { value: 'Luzinópolis', name: 'Luzinópolis', label: 'Luzinópolis' },
    {
      value: 'Marianópolis do Tocantins',
      name: 'Marianópolis do Tocantins',
      label: 'Marianópolis do Tocantins',
    },
    { value: 'Mateiros', name: 'Mateiros', label: 'Mateiros' },
    {
      value: 'Maurilândia do Tocantins',
      name: 'Maurilândia do Tocantins',
      label: 'Maurilândia do Tocantins',
    },
    {
      value: 'Miracema do Tocantins',
      name: 'Miracema do Tocantins',
      label: 'Miracema do Tocantins',
    },
    { value: 'Miranorte', name: 'Miranorte', label: 'Miranorte' },
    {
      value: 'Monte do Carmo',
      name: 'Monte do Carmo',
      label: 'Monte do Carmo',
    },
    {
      value: 'Monte Santo do Tocantins',
      name: 'Monte Santo do Tocantins',
      label: 'Monte Santo do Tocantins',
    },
    { value: 'Muricilândia', name: 'Muricilândia', label: 'Muricilândia' },
    { value: 'Natividade', name: 'Natividade', label: 'Natividade' },
    { value: 'Nazaré', name: 'Nazaré', label: 'Nazaré' },
    { value: 'Nova Olinda', name: 'Nova Olinda', label: 'Nova Olinda' },
    {
      value: 'Nova Rosalândia',
      name: 'Nova Rosalândia',
      label: 'Nova Rosalândia',
    },
    { value: 'Novo Acordo', name: 'Novo Acordo', label: 'Novo Acordo' },
    { value: 'Novo Alegre', name: 'Novo Alegre', label: 'Novo Alegre' },
    { value: 'Novo Jardim', name: 'Novo Jardim', label: 'Novo Jardim' },
    {
      value: 'Oliveira de Fátima',
      name: 'Oliveira de Fátima',
      label: 'Oliveira de Fátima',
    },
    { value: 'Palmas', name: 'Palmas', label: 'Palmas' },
    { value: 'Palmeirante', name: 'Palmeirante', label: 'Palmeirante' },
    {
      value: 'Palmeiras do Tocantins',
      name: 'Palmeiras do Tocantins',
      label: 'Palmeiras do Tocantins',
    },
    { value: 'Palmeirópolis', name: 'Palmeirópolis', label: 'Palmeirópolis' },
    {
      value: 'Paraíso do Tocantins',
      name: 'Paraíso do Tocantins',
      label: 'Paraíso do Tocantins',
    },
    { value: 'Paranã', name: 'Paranã', label: 'Paranã' },
    { value: "Pau D'Arco", name: "Pau D'Arco", label: "Pau D'Arco" },
    { value: 'Pedro Afonso', name: 'Pedro Afonso', label: 'Pedro Afonso' },
    { value: 'Peixe', name: 'Peixe', label: 'Peixe' },
    { value: 'Pequizeiro', name: 'Pequizeiro', label: 'Pequizeiro' },
    {
      value: 'Pindorama do Tocantins',
      name: 'Pindorama do Tocantins',
      label: 'Pindorama do Tocantins',
    },
    { value: 'Piraquê', name: 'Piraquê', label: 'Piraquê' },
    { value: 'Pium', name: 'Pium', label: 'Pium' },
    {
      value: 'Ponte Alta do Bom Jesus',
      name: 'Ponte Alta do Bom Jesus',
      label: 'Ponte Alta do Bom Jesus',
    },
    {
      value: 'Ponte Alta do Tocantins',
      name: 'Ponte Alta do Tocantins',
      label: 'Ponte Alta do Tocantins',
    },
    {
      value: 'Porto Alegre do Tocantins',
      name: 'Porto Alegre do Tocantins',
      label: 'Porto Alegre do Tocantins',
    },
    {
      value: 'Porto Nacional',
      name: 'Porto Nacional',
      label: 'Porto Nacional',
    },
    { value: 'Praia Norte', name: 'Praia Norte', label: 'Praia Norte' },
    {
      value: 'Presidente Kennedy',
      name: 'Presidente Kennedy',
      label: 'Presidente Kennedy',
    },
    { value: 'Pugmil', name: 'Pugmil', label: 'Pugmil' },
    { value: 'Recursolândia', name: 'Recursolândia', label: 'Recursolândia' },
    { value: 'Riachinho', name: 'Riachinho', label: 'Riachinho' },
    {
      value: 'Rio da Conceição',
      name: 'Rio da Conceição',
      label: 'Rio da Conceição',
    },
    { value: 'Rio dos Bois', name: 'Rio dos Bois', label: 'Rio dos Bois' },
    { value: 'Rio Sono', name: 'Rio Sono', label: 'Rio Sono' },
    { value: 'Sampaio', name: 'Sampaio', label: 'Sampaio' },
    { value: 'Sandolândia', name: 'Sandolândia', label: 'Sandolândia' },
    {
      value: 'Santa Fé do Araguaia',
      name: 'Santa Fé do Araguaia',
      label: 'Santa Fé do Araguaia',
    },
    {
      value: 'Santa Maria do Tocantins',
      name: 'Santa Maria do Tocantins',
      label: 'Santa Maria do Tocantins',
    },
    {
      value: 'Santa Rita do Tocantins',
      name: 'Santa Rita do Tocantins',
      label: 'Santa Rita do Tocantins',
    },
    {
      value: 'Santa Rosa do Tocantins',
      name: 'Santa Rosa do Tocantins',
      label: 'Santa Rosa do Tocantins',
    },
    {
      value: 'Santa Tereza do Tocantins',
      name: 'Santa Tereza do Tocantins',
      label: 'Santa Tereza do Tocantins',
    },
    {
      value: 'Santa Terezinha Tocantins',
      name: 'Santa Terezinha Tocantins',
      label: 'Santa Terezinha Tocantins',
    },
    {
      value: 'São Bento do Tocantins',
      name: 'São Bento do Tocantins',
      label: 'São Bento do Tocantins',
    },
    {
      value: 'São Félix do Tocantins',
      name: 'São Félix do Tocantins',
      label: 'São Félix do Tocantins',
    },
    {
      value: 'São Miguel do Tocantins',
      name: 'São Miguel do Tocantins',
      label: 'São Miguel do Tocantins',
    },
    {
      value: 'São Salvador do Tocantins',
      name: 'São Salvador do Tocantins',
      label: 'São Salvador do Tocantins',
    },
    {
      value: 'São Sebastião do Tocantins',
      name: 'São Sebastião do Tocantins',
      label: 'São Sebastião do Tocantins',
    },
    {
      value: 'São Valério da Natividade',
      name: 'São Valério da Natividade',
      label: 'São Valério da Natividade',
    },
    { value: 'Silvanópolis', name: 'Silvanópolis', label: 'Silvanópolis' },
    {
      value: 'Sítio Novo do Tocantins',
      name: 'Sítio Novo do Tocantins',
      label: 'Sítio Novo do Tocantins',
    },
    { value: 'Sucupira', name: 'Sucupira', label: 'Sucupira' },
    { value: 'Taguatinga', name: 'Taguatinga', label: 'Taguatinga' },
    {
      value: 'Taipas do Tocantins',
      name: 'Taipas do Tocantins',
      label: 'Taipas do Tocantins',
    },
    { value: 'Talismã', name: 'Talismã', label: 'Talismã' },
    { value: 'Tocantínia', name: 'Tocantínia', label: 'Tocantínia' },
    {
      value: 'Tocantinópolis',
      name: 'Tocantinópolis',
      label: 'Tocantinópolis',
    },
    { value: 'Tupirama', name: 'Tupirama', label: 'Tupirama' },
    { value: 'Tupiratins', name: 'Tupiratins', label: 'Tupiratins' },
    { value: 'Wanderlândia', name: 'Wanderlândia', label: 'Wanderlândia' },
    { value: 'Xambioá ', name: 'Xambioá ', label: 'Xambioá ' },
  ],
}
export default cities
