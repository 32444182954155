export const AgilHiringSteps = [
  {
    name: 'Verificar elegibilidade',
    status: 'done',
  },
  {
    name: 'Fazer simulação',
    status: 'done',
  },
  {
    name: 'Solicitar oferta',
    status: 'done',
  },
  {
    name: 'Aguadar o SMS da Ágil',
    status: 'not-started',
  },
  {
    name: 'Assinatura digital',
    status: 'not-started',
  },
  {
    name: 'Liberação do empréstimo',
    status: 'not-started',
  },
]
