<template>
  <SimpleLayout>
    <JbPrimeWelcomePageContainer />
  </SimpleLayout>
</template>
<script>
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import { defineAsyncComponent } from 'vue'
const JbPrimeWelcomePageContainer = defineAsyncComponent(
  () => import('@/containers/jb-prime/JbPrimeWelcomePageContainer.vue'),
)
export default {
  name: 'JbPrimeBemVindo',
  components: {
    SimpleLayout,
    JbPrimeWelcomePageContainer,
  },
}
</script>
