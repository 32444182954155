<template>
  <Form
    ref="form"
    class="BankAccountForm"
    :lbl-button-submit="lblButtonSubmit"
    alert-error-message="Dados bancários inválidos! Verifique e tente novamente"
    :busy="busy"
    @submit="submit"
  >
    <template #title>{{ title }}</template>
    <template #content="{ validation }">
      <BankAccountBankSelectInput
        id="bank"
        key="bank"
        v-model="bkAccount.bank"
        :allowed-bank-codes="allowedBankCodes"
        :validation="validation"
        @input="handleField('bank', $event)"
      />
      <BankAccountTypeRadioList
        key="paymentType"
        v-model="bkAccount.type"
        :options="accountTypeOptions"
        :validation="validation"
        @input="handleField('type', $event)"
      />
      <BankAccountBranchInput
        key="BankAccountBranchInput"
        v-model="bkAccount.branch"
        :validation="validation"
      />
      <BankAccountNumberInput
        key="BankAccountNumberInput"
        v-model="bkAccount.number"
        :validation="validation"
        :bankdata="bkAccount"
      />
      <Dialog
        v-if="displayWrongAccountDialog"
        class="wrong-account-dialog"
        :display="displayWrongAccountDialog"
        :hide-close-button="true"
        :persisted="true"
        @close="displayWrongAccountDialog = false"
      >
        <template #header-title>A sua conta com dígito está correta?</template>
        <template #content>
          <div class="wrong-account-wrapper">
            <p>
              Para evitar que sua análise de crédito seja negada, é importante
              que você confirme sua conta bancária.
            </p>
            <div class="account-data">
              <p class="branch">Agência: {{ bkAccount.branch }}</p>
              <p class="number">Conta com dígito: {{ bkAccount.number }}</p>
            </div>
          </div>
        </template>
        <template #footer>
          <Button type="primary" @click="submit(true)">
            Sim, está correta
          </Button>
          <Button type="outlined" @click="displayWrongAccountDialog = false">
            Corrigir conta
          </Button>
        </template>
      </Dialog>
      <Dialog
        v-if="displayCaixaDisclaimer"
        class="caixa-disclaimer-dialog"
        :display="displayCaixaDisclaimer"
        :hide-close-button="true"
        :persisted="true"
        @close="displayCaixaDisclaimer = false"
      >
        <template #header-title>
          Contas poupança da CAIXA estão com novo número
        </template>
        <template #content>
          <div class="caixa-disclaimer">
            <p>
              Os números das contas poupança agora possuem nove dígitos, ao
              invés de oito.
            </p>
            <div class="number-example">
              <div class="number">
                <span class="title">Número antigo</span>
                <span>99999999-9</span>
              </div>
              <div class="number">
                <span class="title">Nova numeração</span>
                <span>999999999-9</span>
              </div>
            </div>
            <p>
              Para evitar problemas na análise de crédito, verifique sua nova
              numeração de conta.
            </p>
            <p>
              <strong>
                Como verificar meu novo número de conta poupança da CAIXA?
              </strong>
            </p>
            <p>
              Verifique em seu aplicativo CAIXA, Internet Banking CAIXA,
              comprovantes de Caixas Eletrônicos, Correspondentes CAIXA Aqui ou
              Casas Lotéricas.
            </p>
          </div>
        </template>
        <template #footer>
          <Button @click="displayCaixaDisclaimer = false"> Entendi </Button>
        </template>
      </Dialog>
    </template>
  </Form>
</template>
<script>
import ValidateBankAccount from '@/lib/accountValidator/bankvalidator'
import Dialog from '@/aasgard/components/modals/Dialog.vue'
import BankAccountNumberInput from '@/components/forms/fields/input/BankAccountNumberInput.vue'
import BankAccountBranchInput from '@/components/forms/fields/input/BankAccountBranchInput.vue'
import BankAccountBankSelectInput from '@/components/forms/fields/selectInput/BankAccountBankSelectInput.vue'
import BankAccountTypeRadioList from '@/components/forms/fields/radioInputList/BankAccountTypeRadioList.vue'
import Button from '@/aasgard/components/buttons/Button.vue'
import Form from '@/components/forms/Form.vue'
export default {
  name: 'BankAccountForm',
  components: {
    Dialog,
    BankAccountNumberInput,
    BankAccountBranchInput,
    BankAccountBankSelectInput,
    BankAccountTypeRadioList,
    Button,
    Form,
  },
  props: {
    busy: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    title: { type: String, default: 'Dados bancários' },
    lblButtonSubmit: { type: String, default: 'Continuar' },
    allowedBankCodes: { type: Array, default: () => [] },
    accountTypeOptions: { type: Array, default: () => [] },
  },
  emits: ['submit'],
  data() {
    return {
      isBankAccountValid: true,
      displayCaixaDisclaimer: false,
      displayWrongAccountDialog: false,
      caixaDisclaimerAlreadyDisplayed: false,
      bkAccount: {
        bank: '',
        branch: '',
        number: '',
        type: '',
      },
    }
  },
  created() {
    if (Object.keys(this.data || {}).length) {
      this.bkAccount = this.data
    } else {
      this.bkAccount = {
        bank: '',
        branch: '',
        number: '',
        type: '',
      }
    }

    this.checkCaixaDisclaimer()
  },
  methods: {
    submit(byPassValidation = false) {
      this.isBankAccountValid = ValidateBankAccount(this.bkAccount)

      if (!byPassValidation && !this.isBankAccountValid) {
        this.displayWrongAccountDialog = true
        return
      }
      if (byPassValidation || this.isBankAccountValid) {
        this.$emit('submit', {
          ...this.bkAccount,
          number: this.bkAccount.number.replace(/[^0-9PX]/g, ''),
        })
      }
    },
    handleField(key, value) {
      if (key === 'bank') {
        this.bkAccount.type = ''
        this.bkAccount.branch = ''
        this.bkAccount.number = ''
      }
      this.bkAccount[key] = value
      this.checkCaixaDisclaimer()
    },
    checkCaixaDisclaimer() {
      if (
        !this.caixaDisclaimerAlreadyDisplayed &&
        this.bkAccount.bank === '104' &&
        this.bkAccount.type === 'SAVING'
      ) {
        this.displayCaixaDisclaimer = true
        this.caixaDisclaimerAlreadyDisplayed = true
      }
    },
  },
}
</script>
<style lang="scss">
.BankAccountForm {
  & > .content {
    & > .right {
      .Dialog.caixa-disclaimer-dialog {
        & > .content-wrapper {
          & > .content {
            & > .caixa-disclaimer {
              p {
                @include body-1;
                margin: 0 0 24px 0;
                color: $color-black-high;

                & strong {
                  @include subtitle-1;
                }
              }

              & > .number-example {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 24px 0;
                background: $color-neutral-light;
                border-radius: 12px;
                padding: 16px;
                gap: 16px;

                & > .number {
                  display: flex;
                  flex-direction: column;
                  width: 50%;

                  & > span {
                    @include body-2;
                    color: $color-black-high;
                  }

                  & > .title {
                    @include subtitle-2;
                  }
                }
              }
            }
          }
        }
      }
      .Dialog.wrong-account-dialog {
        & > .content-wrapper {
          & > .content {
            & > .wrong-account-wrapper {
              p {
                @include body-1;
                margin: 0 0 24px 0;
                color: $color-black-medium;
              }

              & > .account-data {
                background: $color-neutral-light;
                padding: 16px;
                gap: 16px;

                & > p {
                  @include caption;
                  color: $color-black-high;
                  margin: 0;
                }
              }
            }
          }
        }
      }
      & > .whatsappHelp {
        @include body-2;
        color: $color-black-high;
        margin-bottom: 16px;

        & > a {
          color: $color-brand-primary-dark;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
