<template>
  <SimpleLayout class="GenericSimulation">
    <div class="header">
      <JurosbaixosLogo />
    </div>
    <ContentContainerWrapper>
      <LoanProducts
        :products="cptdAvailableProducts"
        @changeProducts="selectProduct"
      >
        <template #title>Escolha o que você deseja para continuar</template>
      </LoanProducts>
    </ContentContainerWrapper>
  </SimpleLayout>
</template>
<script>
import { createUrlQueryParams } from '@/helpers/url'
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import { defineAsyncComponent } from 'vue'
const LoanProducts = defineAsyncComponent(
  () => import('@/components/loans/LoanProducts.vue'),
)
const JurosbaixosLogo = defineAsyncComponent(
  () => import('@/assets/images/logos/loan/jurosbaixos.svg?component'),
)
export default {
  name: 'SelectProductForegonPage',
  components: {
    ContentContainerWrapper,
    JurosbaixosLogo,
    LoanProducts,
    SimpleLayout,
  },
  data() {
    return {}
  },
  computed: {
    cptdAvailableProducts() {
      return [
        {
          name: 'personal_loan',
          label: 'Empréstimo sem garantia',
          icon: 'profile-color',
          cta: {
            path: '/login',
            query: {
              product: 'personal_loan',
            },
          },
        },
        {
          name: 'mobile_equity',
          label: 'Empréstimo com celular em garantia',
          icon: 'phone-iphone-color',
          cta: {
            path: '/login',
            query: {
              product: 'mobile_equity',
            },
          },
        },
        {
          name: 'fgts',
          label: 'Antecipação Saque-Aniversário FGTS',
          icon: 'gift-color',
          cta: {
            path: '/login',
            query: {
              product: 'fgts',
            },
          },
        },
        {
          name: 'auto_equity',
          label: 'Empréstimo com veículo em garantia',
          icon: 'car-color',
          cta: {
            path: '/login',
            query: {
              product: 'auto_equity',
            },
          },
        },
        {
          name: 'home_equity',
          label: 'Empréstimo com imóvel em garantia',
          icon: 'home-color',
          cta: {
            path: '/login',
            query: {
              product: 'home_equity',
            },
          },
        },
        {
          name: 'debt_renegotiation',
          label: 'Renegociação de dívidas',
          icon: 'handshake-color',
          cta: {
            path: '/login',
            query: {
              product: 'debt_renegotiation',
            },
          },
        },
      ]
    },
  },
  methods: {
    selectProduct(productName) {
      const { cta } =
        this.cptdAvailableProducts.find((p) => p.name === productName) || {}
      if (cta) {
        if (cta.path) {
          this.$router.push({
            path: cta.path,
            query: {
              ...this.$route?.query,
              ...(cta.query || {}),
            },
          })
        } else if (cta.url) {
          window.open(cta.url + createUrlQueryParams(cta.query || {}), '_self')
        }
      }
    },
  },
}
</script>
<style lang="scss">
.GenericSimulation {
  padding-top: 64px;
  & .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 56px;
    padding: 16px;
    align-items: center;
    justify-content: center;
    gap: 32px;
    align-self: stretch;
    background: $color-brand-primary;
    margin: 0 0 24px 0;
  }
}
</style>
