<template>
  <div class="JbProductsButtonsMenu">
    <div
      v-for="product in cptdJbProducts"
      :key="product.id"
      class="menu-item"
      :class="cssActive(product)"
      @click="onProductSelected(product.path)"
    >
      <button class="menu">
        <BaseIcon :name="product.icon" />
      </button>
      <p class="label">{{ product.label }}</p>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
export default {
  name: 'JbProductsButtonsMenu',
  components: {
    BaseIcon,
  },
  computed: {
    cptdJbProducts() {
      return {
        loan: {
          label: 'Empréstimo',
          id: 'loan',
          icon: 'hand-money-mono',
          path: '/ofertas',
        },
        insurance: {
          label: 'Seguros',
          id: 'insurance',
          icon: 'shield-check-mono',
          path: '/seguros/ofertas',
        },
        creditCard: {
          label: 'Cartão de crédito',
          id: 'creditCard',
          icon: 'credit-card-mono',
          path: '/cartao-credito/ofertas',
        },
        debtRenegotiation: {
          label: 'Renegociação de dívidas',
          icon: 'handshake-mono',
          id: 'debtRenegotiation',
          path: '/negociacao-dividas',
        },
      }
    },
  },
  methods: {
    cssActive(product) {
      return {
        active: this.$route.path === product.path,
      }
    },
    onProductSelected(path) {
      this.$router.push({
        path,
      })
    },
  },
}
</script>
<style lang="scss">
.JbProductsButtonsMenu {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  & > .menu-item {
    width: 64px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &.active,
    &:hover {
      & > button {
        background-color: $color-brand-primary;

        cursor: pointer;
        & > .BaseIcon {
          @include setSVGMonoColor($color-white-high);
        }
      }

      & > .label {
        color: $color-brand-primary;
      }
    }

    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      background-color: $color-neutral-light;
      border: none;
      border-radius: 100px;
      margin: 0 0 8px 0;
      transition: all 500ms linear;
      cursor: pointer;

      & > .BaseIcon {
        transition: all 500ms linear;
        cursor: pointer;
        @include setSVGMonoColor($color-black-high);
      }
    }

    & > .label {
      width: 64px;
      @include overline;
      text-transform: none;
      color: $color-black-high;
      text-align: center;
      margin: 0;
      transition: all 500ms linear;
      cursor: pointer;
    }
  }
}
</style>
