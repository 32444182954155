import appdocs from '@/store/modules/products/appdocs'
import cx_appdocs from '@/store/modules/products/cx_appdocs'
import loans from '@/store/modules/products/loans'
import creditScore from '@/store/modules/products/credit-score'
import offerAssistant from '~/store/modules/products/offer-assistant'
const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    loans,
    appdocs,
    cx_appdocs,
    'credit-score': creditScore,
    'offer-assistant': offerAssistant,
  },
}
