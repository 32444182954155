export enum UnexpectedPartnerOfferStepEnum {
  COLD_OFFER_SIMULATE = 'COLD_OFFER_SIMULATE',
  SIMULATE = 'SIMULATE',
  SELECT = 'SELECT',
  SIGN_CONTRACT = 'SIGN_CONTRACT',
  UPLOAD_DOCS = 'UPLOAD_DOCS',
}

export enum UIFeedbackTypes {
  NEUTRAL = 'neutral',
  WARNING = 'warning',
  'WARNING-LIGHT' = 'warning-light',
  POSITIVE = 'positive',
  'POSITIVE-LIGHT' = 'positive-light',
  ERROR = 'error',
}
