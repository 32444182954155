import checkBanrisulBankAccount from './bank-validator-js/banks/banrisul/account'
import checkBbBankAccount from './bank-validator-js/banks/bb/account'
import checkBradescoBankAccount from './bank-validator-js/banks/bradesco/account'
import checkCaixaBankAccount from './bank-validator-js/banks/caixa/account'
import checkCitybankAccount from './bank-validator-js/banks/citybank/account'
import checkHsbcBankAccount from './bank-validator-js/banks/hsbc/account'
import checkItauBankAccount from './bank-validator-js/banks/itau/account'
import checkSantanderBankAccount from './bank-validator-js/banks/santander/account'

import utilities from './bank-validator-js/utilities'
import generalValidator from './bank-validator-js/generalValidation'

function ValidateBankAccount({
  bank = '',
  branch = '',
  number = '',
  type = '',
}) {
  // check if all values exists
  if (!generalValidator({ bank, branch, number, type })) {
    return false
  }

  // check if the accouunt is saving and return true because we are not validating this type of account
  if (type === 'SAVING') {
    return true
  }

  // sanitize the values to have a pattern and remove special characters
  const bankAccount = utilities.sanitizeBankAccountValues({
    bank,
    branch,
    number,
    type,
  })
  const validateBankAccountFunctionDict = {
    '001': checkBbBankAccount,
    '033': checkSantanderBankAccount,
    '041': checkBanrisulBankAccount,
    '104': checkCaixaBankAccount,
    '237': checkBradescoBankAccount,
    '341': checkItauBankAccount,
    '399': checkHsbcBankAccount,
    '745': checkCitybankAccount,
  }

  if (!validateBankAccountFunctionDict[bankAccount.bank]) {
    return true
  }

  return validateBankAccountFunctionDict[bankAccount.bank](
    bankAccount.number,
    bankAccount.number_digit,
    bankAccount.branch,
  )
}

export default ValidateBankAccount
