<template>
  <ClearLayout>
    <ContainerWrapper v-if="gtAutoOfferSelected" class="LoanDataPage">
      <NavBar
        :progress="{ step: 1, steps: 1, type: 'stepped' }"
        @back="$router.back()"
        @close="displayProgressWillBeLost = true"
      />
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="displayProgressWillBeLost = false"
        @exit="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <EmpSimOccupationForm
          :data="cptdCurrentForm['occupation'] || ''"
          @submit="selectOffer"
        />
        <FullscreenLoading v-if="busy">
          <template #loading-message>
            Solicitando oferta...
            <p>Isso pode levar alguns minutos.</p>
          </template>
        </FullscreenLoading>
        <OfferPartnerTimeoutFeedbackDialog
          v-if="!busy && displayOfferPartnerTimeoutFeedbackDialog"
          :display="displayOfferPartnerTimeoutFeedbackDialog"
          partner="EMPRESTIMO_SIM"
        />
        <UnexpectedPartnerOfferErrorFeedbackDialog
          :display="displayUnexpectedPartnerOfferErrorFeedbackDialog"
          :offer-id="offerErrorId"
          partner="EMPRESTIMO_SIM"
          :step="currentStep"
        />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {
  isOfferSelected,
  isOfferWaitingForAgreementAcceptance,
  isOfferWaitingForDocumentUploads,
  partnerHasFullIntegration,
  waitForAResultWithOptions,
} from '@/helpers/offers'
import { OfferStatus, OfferSubStatus } from '@/data/common'
import { UnexpectedPartnerOfferStepEnum } from '@/helpers/offer-types'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const UnexpectedPartnerOfferErrorFeedbackDialog = defineAsyncComponent(
  () =>
    import('@/components/loans/UnexpectedPartnerOfferErrorFeedbackDialog.vue'),
)
const OfferPartnerTimeoutFeedbackDialog = defineAsyncComponent(
  () => import('@/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'),
)
const FullscreenLoading = defineAsyncComponent(
  () => import('@/components/commomComponents/loaders/FullscreenLoading.vue'),
)
const EmpSimOccupationForm = defineAsyncComponent(
  () =>
    import(
      '@/components/forms/loans/auto/partners/emprestimo_sim/EmpSimOccupationForm.vue'
    ),
)
export default {
  name: 'DynamicFormPage',
  components: {
    ClearLayout,
    ProgressWillBeLostModal,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
    UnexpectedPartnerOfferErrorFeedbackDialog,
    OfferPartnerTimeoutFeedbackDialog,
    FullscreenLoading,
    EmpSimOccupationForm,
  },
  data() {
    return {
      displayProgressWillBeLost: false,
      busy: false,
      displayOfferPartnerTimeoutFeedbackDialog: false,
      displayUnexpectedPartnerOfferErrorFeedbackDialog: false,
      errorMsg: 'Algo deu errado! Por favor tente novamente.',
      currentStep: '',
      formId: 'EMPRESTIMO_SIM',
      offerErrorId: '',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      gtAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/gtAutoOfferSelected',
    }),
    cptdCurrentForm() {
      return this.gtDynamicForms[this.formId] || {}
    },
  },
  created() {
    this.actSetupForm(this.formId)
    if (!this.gtAutoOfferSelected || !this.gtUser) {
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actSetupForm: 'dynamic-forms/actSetupForm',
      createFeedback: 'utils/feedback/createFeedback',
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      actSetAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/actSetAutoOfferSelected',
      actSelectAutoOffer:
        'products/loans/auto-equity/auto-equity/actSelectAutoOffer',
      actGetAutoOfferById:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferById',
    }),
    async selectOffer(occupation) {
      this.busy = true
      const selectResponse = await this.actSelectAutoOffer({
        offer: this.gtAutoOfferSelected,
        payload: {
          empsim: {
            occupation,
          },
        },
      })
      if (selectResponse?.status === 200) {
        return await this.waitCurrentOfferTurnsIntoSelected()
      } else {
        if (selectResponse === 'REDIRECTED_TO_PARTNER_PAGES') {
          return
        }
        this.createFeedback({ text: this.errorMsg })
      }
      this.busy = false
    },
    async waitCurrentOfferTurnsIntoSelected() {
      const { offer_id, partner } = this.gtAutoOfferSelected
      let offerResponse = null
      this.busy = true
      try {
        offerResponse = await waitForAResultWithOptions(
          {
            status: (os) =>
              [OfferStatus.ONGOING, OfferStatus.FATAL_ERROR].includes(os),
            sub_status: (oss) =>
              [
                OfferSubStatus.SELECTED,
                OfferSubStatus.WAITING_FOR_DOCUMENT_UPLOADS,
                OfferSubStatus.WAITING_FOR_AGREEMENT_ACCEPTANCE,
                OfferSubStatus.INFORMATION_INCORRECT,
              ].includes(oss),
          },
          async () => {
            return await this.actGetAutoOfferById(offer_id)
          },
        )
        if (offerResponse?.offer_id) {
          this.actSetAutoOfferSelected(offerResponse)
          if (
            isOfferSelected(this.gtAutoOfferSelected) ||
            isOfferWaitingForDocumentUploads(this.gtAutoOfferSelected) ||
            isOfferWaitingForAgreementAcceptance(this.gtAutoOfferSelected)
          ) {
            if (
              partnerHasFullIntegration({
                partner,
                product_type: 'auto',
              })
            ) {
              this.$router.push({
                path: `/contratacao/auto/${partner.toLowerCase()}/${offer_id}`,
              })
            } else {
              this.$router.push({
                path: `/contratacao/auto/${partner.toLowerCase()}/${offer_id}/redirecionamento`,
              })
            }
          } else {
            this.currentStep = UnexpectedPartnerOfferStepEnum.SELECT
            this.offerErrorId = this.gtAutoOfferSelected?.offer_id
            this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
          }
        } else {
          this.displayOfferPartnerTimeoutFeedbackDialog = true
        }
      } catch (e) {
        if (e?.message === 'MAX_RETRIES_REACHED') {
          this.offerErrorId = this.gtAutoOfferSelected?.offer_id
          this.currentStep = UnexpectedPartnerOfferStepEnum.SELECT
          this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
        } else {
          this.createFeedback({ text: this.errorMsg })
        }
      }

      this.busy = false
    },
  },
}
</script>
<style lang="scss"></style>
