import { OfferStatus } from '@/data/common'
import { NoCollateralOfferResponse } from '@/data/loans/no-collateral/offer'
import { fetchNoCollateralOfferUnauth } from '@/api/loans/no-collateral/offers'

const state = (): any => ({
  proposedOffers: [],
  onGoingOffers: [],
})

// GETTERS
const getters = {
  gtAllUnauthProposedNoCollateralOffers(state): any {
    return state.proposedOffers.filter((o) => o.status === OfferStatus.PROPOSED)
  },
}

// MUTATIONS
const mutations = {
  SET_UNAUTH_NO_COLLATERAL_OFFERS(
    state,
    {
      payload,
      status,
    }: {
      payload: Array<NoCollateralOfferResponse | any>
      status: OfferStatus | string
    },
  ): void {
    payload.forEach((o) => {
      o.product_type = 'no-collateral-unauth'
      o.display_interest_rate_monthly = o.interest_rate_monthly * 100
    })
    switch (status) {
      case OfferStatus.ONGOING:
        state.onGoingOffers = payload
        break
      case OfferStatus.PROPOSED:
        state.proposedOffers = payload
        break
    }
  },
}

// ACTIONS
const actions = {
  async actGetNoCollateralOffersUnauth(
    { dispatch, commit },
    { userId, options = { status: OfferStatus.PROPOSED } },
  ) {
    const errorMsg =
      'Tivemos um probleminha ao consultar novas ofertas para voce'
    try {
      const response = await fetchNoCollateralOfferUnauth({ userId, options })

      const { data } = response

      if (data) {
        commit('SET_UNAUTH_NO_COLLATERAL_OFFERS', {
          payload: data,
          status: options.status,
        })
      } else {
        dispatch('utils/feedback/createFeedback', errorMsg, { root: true })
        return
      }
    } catch (e: any) {
      dispatch('utils/feedback/createFeedback', errorMsg, { root: true })
    }
  },
}

export default { namespaced: true, state, getters, actions, mutations }
