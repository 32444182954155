import UserAPI from '@/api/user/user'
import { trackAdCampaign } from '@/api/user/ad-campaigns'
import { GaEvent } from '@/store/modules/services/ga'
import JbOAuth from '@/helpers/oauth'
// STATE
const state = (): any => ({
  user: {},
})

// GETTERS
const getters = {
  gtUser(state): any {
    return state.user
  },
}

// MUTATIONS
const mutations = {
  UPDATE_USER(state, user): void {
    state.user = {
      ...state.user,
      ...user,
      email: user.email || state.user.email,
      mobile_phone: (
        user.mobile_phone ||
        state.user.mobile_phone ||
        ''
      ).replace(/\D/g, ''),
      cpf: (user.cpf || state.user.cpf || '').replace(/\D/g, ''),
    }
  },
}

// ACTIONS
const actions = {
  // Create a new user
  actUpdateUser({ commit }, payload) {
    commit('UPDATE_USER', payload)
  },
  async actGetUser({ dispatch }) {
    try {
      const response = await UserAPI.getUser()
      if (response?.data) {
        dispatch('actUpdateUser', response?.data)
      }
    } catch (e: any) {
      return e?.response
    }
  },
  async actPatchUser({ dispatch, state }, payload) {
    try {
      dispatch('actUpdateUser', payload)
      dispatch(
        'user/persona/setPersona',
        { persona: { info: { name: payload?.name } } },
        { root: true },
      )
      return await UserAPI.patchUser(state.user)
    } catch (e: any) {
      return e?.response
    }
  },
  async actDeleteUser() {
    try {
      return await UserAPI.deleteUser()
    } catch (e: any) {
      return e?.response
    }
  },
  async actTrackAdCampaign({ dispatch }, eventName = 'login') {
    try {
      const storeActionName =
        eventName === 'login'
          ? 'services/ga/actEmitGa4LoginEvent'
          : 'services/ga/actEmitGa4SignUpEvent'
      dispatch(
        storeActionName,
        {
          user_id: JbOAuth.getDecodedAccessToken()?.id,
          method: JbOAuth.getAccessTokenProvider(),
        },
        { root: true },
      )

      const ad_interest = (
        sessionStorage.getItem('product') || 'login'
      )?.replace('_', '')
      const entrypoint_url = location.pathname.substring(1)
      const gaEvent = {
        eventCategory: 'new-login',
        eventLabel: entrypoint_url,
        eventAction: ad_interest,
      } as GaEvent

      dispatch('services/ga/actEmitGaEvent', gaEvent, { root: true })

      return await trackAdCampaign({
        ad_campaign: sessionStorage.getItem('utm_campaign') || '',
        ad_source: sessionStorage.getItem('utm_source') || '',
        entrypoint_url,
        ad_set:
          sessionStorage.getItem('aff_id') ||
          sessionStorage.getItem('utm_ad_set') ||
          '',
        ad_keyword: sessionStorage.getItem('utm_ad_keyword') || '',
        ad_creative: sessionStorage.getItem('utm_ad_creative') || '',
        ad_interest,
      })
    } catch (e: any) {}
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
