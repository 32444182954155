<template>
  <ClearLayout>
    <OfferPartnerQrCodeRedirect
      v-if="cptdPartnerQrCodeUrl"
      :partner="cptdPartner"
      :url="cptdPartnerQrCodeUrl"
    />
    <OfferPartnerRedirectModal
      v-else
      :partner="cptdPartner"
      :url="cptdOfferUrl"
      @redirected="registerIntent"
    >
      <template #title>
        {{ cptdTitleRedirect }}
      </template>
      <template #alert-message>
        {{ cptdRedirectMessage }}
      </template>
    </OfferPartnerRedirectModal>
  </ClearLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PartnersName, PartnerWithQrCode } from '@/helpers/partners/common'
import { generateWhatsAppLink } from '@/helpers/whatsapp'

import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
import axios from 'axios'
const OfferPartnerQrCodeRedirect = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerQrCodeRedirect.vue'
    ),
)
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
export default {
  components: {
    ClearLayout,
    OfferPartnerQrCodeRedirect,
    OfferPartnerRedirectModal,
  },
  computed: {
    ...mapGetters({
      personaData: 'user/persona/personaData',
      gtHomeEquityLatestColdOfferRequest:
        'products/loans/home-equity/cold-offer/gtHomeEquityLatestColdOfferRequest',
      gtHomeEquityExtraQuestions:
        'products/loans/home-equity/cold-offer/gtHomeEquityExtraQuestions',
      gtUser: 'user/user/gtUser',
    }),
    cptdPartner() {
      const { partner } = this.$route?.params
      return partner?.toUpperCase() ?? ''
    },
    cptdPartnerQrCodeUrl() {
      if (PartnerWithQrCode[this.cptdPartner ?? '']) {
        const { origin, pathname } = location || {}
        if (origin && pathname) {
          return `${origin}${pathname}/qrcode`
        }
      }

      return ''
    },
    cptdOfferUrl() {
      switch (this.cptdPartner) {
        case 'CONEXAO_FINANCEIRA':
          return 'https://api.whatsapp.com/send?phone=5511964613844&text=Ol%C3%A1!%20Quero%20continuar%20minha%20solicita%C3%A7%C3%A3o%20de%20oferta%20com%20Conex%C3%A3o%20Financeira.%20Poderia%20me%20ajudar'
        default:
          return generateWhatsAppLink({
            text: `Olá! Quero continuar minha solicitação de oferta com a ${this.cptdPartnerName}. Poderia me ajudar?`,
          })
      }
    },
    cptdPartnerName() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdTitleRedirect() {
      switch (this.cptdPartner) {
        case 'CONEXAO_FINANCEIRA':
          return 'Solicitação enviada! Finalize a contratação no WhatsApp'
        default:
          return `Tudo certo! Finalize a contratação da sua oferta com a ${this.cptdPartnerName}`
      }
    },
    cptdRedirectMessage() {
      switch (this.cptdPartner) {
        case 'CONEXAO_FINANCEIRA':
          return `Este serviço é uma parceria com a ${this.cptdPartnerName} e o processo continuará no WhatsApp.`
        default:
          return `Este serviço é uma parceria com a ${this.cptdPartnerName} e o processo continuará em seu site.`
      }
    },
  },
  mounted() {
    this.actEmitInfobipAffiliateOfferSelected({
      affiliate: this.cptdPartner,
    })

    if (this.cptdPartner === 'CONEXAO_FINANCEIRA') {
      this.selectConexaoFinanceiraOffer()
    }
  },
  methods: {
    ...mapActions({
      actInsertIntent: 'user/intent/actInsertIntent',
      actEmitInfobipAffiliateOfferSelected:
        'services/events/actEmitInfobipAffiliateOfferSelected',
    }),
    registerIntent() {
      this.actInsertIntent({
        identifier: `PARTNER_${this.cptdPartner}_REDIRECT`,
      })
    },
    async selectConexaoFinanceiraOffer() {
      this.actInsertIntent({
        identifier: 'PARTNER_CONEXAO_FINANCEIRA_ACCEPTED',
      })

      try {
        const options = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
        await axios.post(
          'https://hooks.zapier.com/hooks/catch/13541339/3jde7ae/',
          JSON.stringify({
            name: this.gtUser?.name,
            cpf: this?.gtUser?.cpf,
            mobile_phone: this?.gtUser?.mobile_phone,
            zip_code: this?.gtHomeEquityLatestColdOfferRequest?.zip_code,
            collateral_value:
              this?.gtHomeEquityLatestColdOfferRequest?.collateral_value,
            collateral_type: this?.gtHomeEquityExtraQuestions?.collateral_type,
            requested_amount:
              this?.gtHomeEquityLatestColdOfferRequest?.requested_loan_amount,
            outstanding_balance: this?.gtHomeEquityLatestColdOfferRequest
              ?.residence_debt
              ? this?.gtHomeEquityExtraQuestions?.outstanding_financing
              : 0,
            collateral_owner:
              this?.gtHomeEquityLatestColdOfferRequest?.collateral_owner,
          }),
          options,
        )
      } catch (e) {}
    },
  },
}
</script>

<style lang="scss"></style>
