import axios from 'axios'
import { sanitizeAddress } from '@/helpers/address'
import { genericRegexes } from '@/helpers/regex'
import cities from '@/helpers/cities'
import { normalizeString } from '@/helpers/globalFunctions'

const state = (): any => ({
  address_cep: {
    address: undefined,
    city: undefined,
    zip_code: undefined,
    state: undefined,
    neighborhood: undefined,
  },
  cep_was_found: {
    status: undefined,
  },
  cities: { ...cities },
})

const getters = {
  addressCepData(state): any {
    return state?.address_cep
  },
  cepWasFound(state): any {
    return state?.cep_was_found
  },
  gtCities(state): any {
    return state.cities
  },
}

const mutations = {
  UPDATE_ADDRESS_CEP(state, payload): void {
    state.address_cep = {
      address: payload?.address,
      city: payload?.city,
      zip_code: payload?.code,
      state: payload?.state,
      neighborhood: payload?.district,
    }
  },
  UPDATE_CITIES(state, payload): void {
    if (state.cities[payload.state]) {
      state.cities[payload.state].push({
        name: payload.city,
        value: payload.city,
        label: payload.city,
      })
    }
  },
  CEP_WAS_FOUND(state, value): void {
    state.cep_was_found = value
  },
}

const actions = {
  // get CEP
  async getCep({ commit, dispatch }, cep) {
    try {
      const response = await axios.get(
        `https://ws.apicep.com/cep.json?code=${cep}`,
      )
      if (response?.data?.status === 200) {
        if (response.data.address) {
          response.data.address = sanitizeAddress(response?.data?.address)
        }

        if (response.data.district) {
          response.data.district = sanitizeAddress(response?.data?.district)
        }
        // set what the API found
        commit('CEP_WAS_FOUND', {
          has_address:
            !!response?.data?.address &&
            !!response.data.address.match(genericRegexes.address.regex),
          has_city:
            !!response?.data?.city &&
            !!response.data.city.match(genericRegexes.city.regex),
          has_district:
            !!response?.data?.district &&
            !!response.data.district.match(genericRegexes.address.regex),
          has_state: !!response?.data?.state,
          status: response?.data?.ok,
        })
        dispatch('checkCity', response?.data)
        commit('UPDATE_ADDRESS_CEP', response?.data)
        return response
      }
    } catch (e: any) {
      commit('CEP_WAS_FOUND', false)
      return e?.response
    }
  },
  async checkCity({ commit }, payload) {
    try {
      const { city, state, code } = payload
      const cep = code
      if (city && state) {
        if (
          !cities[state].find(
            (ct) => normalizeString(ct.value) === normalizeString(city),
          )
        ) {
          commit('UPDATE_CITIES', { state, city })
          const today = new Date()
          await axios.post(
            'https://hooks.zapier.com/hooks/catch/719337/bwwbvek/',
            JSON.stringify({
              city,
              state,
              cep,
              date: `${today.getDate()}/${
                today.getMonth() + 1
              }/${today.getFullYear()}`,
            }),
          )
        }
      }
    } catch (e: any) {}
  },
}

export default { namespaced: true, state, getters, actions, mutations }
