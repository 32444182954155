import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'

const getSubscriptions = async () => {
  return await JurosBaixosSystemsAPI.get(`/user/subscriptions`).catch(
    createRequestException,
  )
}

export default { getSubscriptions }
