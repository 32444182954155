import intent from '@/store/modules/user/intent'
import persona from '@/store/modules/user/persona'
import subscriptions from '@/store/modules/user/subscriptions'
import user from '@/store/modules/user/user'
import userAttributes from '@/store/modules/user/user-attributes'

const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    intent,
    persona,
    subscriptions,
    user,
    'user-attributes': userAttributes,
  },
}
