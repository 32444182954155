import TokenAPI from '@/api/token/token'
import JbOAuth from '@/helpers/oauth'
import { HttpStatusCode } from '@/helpers/jb-error'
import { User } from '@/data/user/user'

// STATE
const state = (): any => ({})

// GETTERS
const getters = {}

// MUTATIONS
const mutations = {}

// ACTIONS
const actions = {
  async actTokenRegisterUser({ dispatch }, user: User) {
    try {
      const sanitazedUser = {
        email: user.email,
        mobile_phone: (user.mobile_phone || '').replace(/\D/g, ''),
        cpf: (user.cpf || '').replace(/\D/g, ''),
        name: user.name,
      }
      const response = await TokenAPI.register(sanitazedUser)
      if (response?.status === HttpStatusCode.OK) {
        sessionStorage.setItem('jb.state.oauth.login-trough-send-token', 'true')
        JbOAuth.handleJwtResponse(response?.data, 'TOKEN')
        await dispatch('user/user/actGetUser', null, { root: true })
        await dispatch('user/user/actTrackAdCampaign', 'sign_up', {
          root: true,
        })
        dispatch(
          'user/persona/setPersona',
          {
            persona: {
              info: { name: user.name },
            },
            save: false,
          },
          { root: true },
        )
      }

      return response
    } catch (e: any) {
      return e?.response
    }
  },
  // send token
  async actTokenLogin({ dispatch }, { tokenRequest, displayErrors = true }) {
    try {
      const payload = { ...tokenRequest }
      if (payload.cpf) {
        payload.cpf = payload.cpf.replace(/\D/g, '')
      }

      return await TokenAPI.login(payload)
    } catch (e: any) {
      if (displayErrors) {
        const errorMessage = {
          text: 'Oops! algo deu errado.',
        }
        if (HttpStatusCode.UNAUTHORIZED) {
          errorMessage.text =
            'Usuário não encontrado. Cadastre-se para continuar.'
        }

        dispatch('utils/feedback/createFeedback', errorMessage, { root: true })
        if (e?.response?.status === 401) {
          dispatch(
            'services/ga/actEmitGaEvent',
            {
              eventCategory: 'login_issues',
              eventAction: '401_error',
              eventLabel: '401_error',
            },
            { root: true },
          )
        }
      }

      return e?.response
    }
  },

  // verify token
  async actTokenVerify({ dispatch }, payload) {
    try {
      const response = await TokenAPI.verify(payload)
      const { data, status } = response
      if (data && status === HttpStatusCode.OK) {
        JbOAuth.handleJwtResponse(response?.data, 'TOKEN')
        await dispatch('user/user/actGetUser', null, { root: true })
        await Promise.all([
          dispatch('user/persona/getPersona', null, { root: true }),
          dispatch(
            'products/credit-score/credit-score/actCreditScoreGetLatest',
            null,
            { root: true },
          ),
          dispatch('user/subscriptions/actGetUserSubscriptions', null, {
            root: true,
          }),
          dispatch('user/user/actTrackAdCampaign', 'login', { root: true }),
        ])
        sessionStorage.setItem('jb.state.oauth.login-trough-send-token', 'true')
      }

      return response
    } catch (e: any) {
      return e?.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
