<template>
  <DefaultLayout>
    <ResultsContainer />
  </DefaultLayout>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
const ResultsContainer = defineAsyncComponent(
  () => import('@/containers/simulation/UnloggedOffersContainer.vue'),
)
export default {
  name: 'OffersResultsUnlogged',
  components: {
    DefaultLayout,
    ResultsContainer,
  },
}
</script>
