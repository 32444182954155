export const EmprestimoSimHiringSteps = [
  {
    name: 'Solicitar oferta',
    status: 'done',
  },
  {
    name: 'Confirmar dados',
    status: 'done',
  },
  {
    name: 'Enviar documentação',
    status: 'done',
  },
  {
    name: 'Assinar contrato',
    status: 'done',
  },
  {
    name: 'Aguardar análise de dados',
    status: 'not-started',
  },
  {
    name: 'Liberação de empréstimo',
    status: 'not-started',
  },
]
