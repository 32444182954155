import {
  getHomeEquityColdOfferRequest,
  getUserHomeEquityColdOffers,
  submitHomeEquityColdOffer,
  getLatestColdOfferRequest,
} from '@/api/loans/home-equity/cold-offers'
import { simulateHomeEquityColdOffer } from '@/api/loans/home-equity/simulate'
import { HomeEquitySimulationRequest } from '@/data/loans/home-equity/simulation'
import { HomeEquityColdRequest } from '@/data/loans/home-equity/cold-offer'
import { filterExpiredOffers, offerPartnersConfig } from '@/helpers/offers'

const LS_HOME_EQUITY_REQUESTED_OFFERS_KEY =
  'state.jb.home-equity.requested-cold-offers'
const LS_HOME_EQUITY_EXTRA_QUESTIONS_KEY =
  'state.jb.home-equity.extra-questions'

// STATE
const state = (): any => ({
  requestedColdOffers: JSON.parse(
    sessionStorage.getItem(LS_HOME_EQUITY_REQUESTED_OFFERS_KEY) || '[]',
  ),
  homeEquityColdOffers: [],
  homeEquityColdOfferSelected: null,
  homeEquityColdOfferRequest: null,
  homeEquityLatestColdOfferRequest: null,
  homeEquityColdOfferSchedulerTimeoutSeconds:
    import.meta.env.VITE_SIMULATING_SECONDS_WAIT_TIME_HOME_EQUITY || 30,
  homeEquityColdOfferExtraQuestions: JSON.parse(
    localStorage.getItem(LS_HOME_EQUITY_EXTRA_QUESTIONS_KEY) || '{}',
  ),
})

// GETTERS
const getters = {
  gtHomeEquityColdOffers(state): any {
    return state.homeEquityColdOffers
      .filter(filterExpiredOffers)
      .filter(
        ({ cold_offer_id }) =>
          !state.requestedColdOffers.includes(cold_offer_id),
      )
  },
  gtHomeEquityColdOfferSelected(state): any {
    return state.homeEquityColdOfferSelected || null
  },
  gtHomeEquityColdOfferRequest(state) {
    return state.homeEquityColdOfferRequest
  },
  gtHomeEquityColdOfferSchedulerTimeoutSeconds(state) {
    return state.homeEquityColdOfferSchedulerTimeoutSeconds
  },
  gtHomeEquityLatestColdOfferRequest(state): HomeEquityColdRequest | null {
    return state.homeEquityLatestColdOfferRequest
  },
  gtHomeEquityExtraQuestions(state) {
    return state.homeEquityColdOfferExtraQuestions
  },
}

// MUTATIONS

const mutations = {
  SET_HOME_EQUITY_AFFILIATE_EXTRA_QUESTIONS(state, payload) {
    state.homeEquityColdOfferExtraQuestions = payload
    localStorage.setItem(
      LS_HOME_EQUITY_EXTRA_QUESTIONS_KEY,
      JSON.stringify(payload),
    )
  },
  UPDATE_HOME_EQUITY_COLD_OFFERS(state, payload): void {
    state.homeEquityColdOffers = payload.map((coldOffer) => {
      const partnerConfig = offerPartnersConfig[coldOffer.partner]
      return {
        ...coldOffer,
        amount_min: coldOffer?.amount_min || partnerConfig.amount_min,
        amount_max: coldOffer?.amount_max || partnerConfig.amount_max,
        duration_min: coldOffer?.duration_min || partnerConfig.duration_min,
        duration_max: coldOffer?.duration_max || partnerConfig.duration_max,
        product_type: 'home-equity-cold-offer',
      }
    })
  },
  SET_SELECTED_HOME_EQUITY_COLD_OFFER(state, payload) {
    state.homeEquityColdOfferSelected = payload
  },
  SET_HOME_EQUITY_COLD_OFFER_REQUEST(
    state,
    coldOfferRequest: HomeEquityColdRequest,
  ) {
    state.homeEquityColdOfferRequest = coldOfferRequest
  },
  SET_LATEST_HOME_EQUITY_COLD_OFFER_REQUEST(
    state,
    coldOfferRequest: HomeEquityColdRequest,
  ) {
    state.homeEquityLatestColdOfferRequest = coldOfferRequest
  },
  SET_REQUESTED_HOME_EQUITY_COLD_OFFERS(state, offer_id) {
    state.requestedColdOffers.push(offer_id)
    sessionStorage.setItem(
      LS_HOME_EQUITY_REQUESTED_OFFERS_KEY,
      JSON.stringify(state.requestedColdOffers),
    )
  },
}

// ACTIONS

const actions = {
  async actGetHomeEquityColdOffers({ commit }) {
    const disableColdOffers = true
    if (disableColdOffers) {
      commit('UPDATE_HOME_EQUITY_COLD_OFFERS', [])
      return { data: [] }
    }
    try {
      const response = await getUserHomeEquityColdOffers()
      if (response.status === 200) {
        commit('UPDATE_HOME_EQUITY_COLD_OFFERS', response?.data || [])
      } else {
        commit('UPDATE_HOME_EQUITY_COLD_OFFERS', [])
      }

      return response
    } catch (e: any) {
      commit('UPDATE_HOME_EQUITY_COLD_OFFERS', [])
      return e?.response
    }
  },
  async actGetHomeEquityColdOfferRequest({ commit }, coldOfferId) {
    try {
      const response = await getHomeEquityColdOfferRequest(coldOfferId)
      if (response.status === 200) {
        commit('SET_HOME_EQUITY_COLD_OFFER_REQUEST', response?.data || {})
      } else {
        commit('SET_HOME_EQUITY_COLD_OFFER_REQUEST', {})
      }

      return response
    } catch (e: any) {
      commit('SET_HOME_EQUITY_COLD_OFFER_REQUEST', {})
      return e?.response
    }
  },
  async actSetSelectedHomeEquityColdOffer({ commit, dispatch }, payload) {
    await dispatch('actGetHomeEquityColdOfferRequest', payload?.cold_offer_id)
    commit('SET_SELECTED_HOME_EQUITY_COLD_OFFER', payload)
  },
  async actSubmitHomeEquityColdOffer(
    { commit },
    payload: HomeEquityColdRequest,
  ) {
    try {
      commit('SET_HOME_EQUITY_COLD_OFFER_REQUEST', payload)
      commit('SET_LATEST_HOME_EQUITY_COLD_OFFER_REQUEST', payload)
      return await submitHomeEquityColdOffer(payload)
    } catch (e: any) {
      return e?.response
    }
  },
  async actSimulateHomeEquityColdOffer(
    _,
    homeEquitySimulationRequest: HomeEquitySimulationRequest,
  ) {
    try {
      return await simulateHomeEquityColdOffer(homeEquitySimulationRequest)
    } catch (e: any) {
      return e?.response
    }
  },
  async actGetHomeEquityLatestColdOfferRequest({ commit }) {
    try {
      const response = await getLatestColdOfferRequest()
      if (response.status === 200) {
        commit(
          'SET_LATEST_HOME_EQUITY_COLD_OFFER_REQUEST',
          response?.data || {},
        )
      } else {
        commit('SET_LATEST_HOME_EQUITY_COLD_OFFER_REQUEST', {})
      }

      return response
    } catch (e: any) {
      commit('SET_LATEST_HOME_EQUITY_COLD_OFFER_REQUEST', {})
      return e?.response
    }
  },
  actSetRequestedHomeEquityColdOffers({ commit }, offer_id) {
    commit('SET_REQUESTED_HOME_EQUITY_COLD_OFFERS', offer_id)
  },
  actSetHomeEquityAffiliateExtraQuestions({ commit }, payload) {
    commit('SET_HOME_EQUITY_AFFILIATE_EXTRA_QUESTIONS', payload)
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
