<template>
  <ClearLayout>
    <div class="ConectedAccountsPage">
      <NavBar
        :title="cptdTitle"
        :extended="true"
        :display-back-button="false"
        @close="$router.push('/home')"
      />
      <ContentContainerWrapper class="social-login-wrapper">
        <div class="login-options">
          <GoogleSignInButton context="link" />
          <MicrosoftSignInButton context="link" />
        </div>
      </ContentContainerWrapper>
    </div>
  </ClearLayout>
</template>
<script>
import { defineAsyncComponent } from 'vue'

const MicrosoftSignInButton = defineAsyncComponent(
  () => import('@/containers/oauth/providers/MicrosoftSignInButton.vue'),
)
const GoogleSignInButton = defineAsyncComponent(
  () => import('@/containers/oauth/providers/GoogleSignInButton.vue'),
)
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
export default {
  name: 'ConectedAccountsPage',
  components: {
    ClearLayout,
    MicrosoftSignInButton,
    GoogleSignInButton,
    ContentContainerWrapper,
    NavBar,
  },
  data() {
    return {
      loggedInBySendToken:
        sessionStorage.getItem('jb.state.oauth.login-trough-send-token') ===
        'true',
    }
  },
  computed: {
    cptdTitle() {
      return this.loggedInBySendToken
        ? 'Conecte-se utilizando uma de suas Redes Sociais'
        : 'Contas Conectadas'
    },
  },
}
</script>
<style lang="scss">
.ConectedAccountsPage {
  height: 100%;
  max-width: 600px;
  margin: 0 auto;

  .social-login-wrapper {
    h2 {
      @include headline-6;
      margin: 0 0 24px 0;
    }

    .login-options {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      margin: 0 auto;
      max-width: 300px;
    }
  }
}
</style>
