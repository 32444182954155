import BankAccountValidatorTool from '../../digitCalculator'
import utilities from '@/lib/accountValidator/bank-validator-js/utilities'

function checkCitybankAccount(number, number_digit, branch) {
  const branchPadded = utilities.padStart(branch, 4)
  if (branchPadded.length > 4) return false

  const numberPadded = utilities.padStart(number, 10)
  if ((numberPadded + number_digit).length > 11) return false

  const digit_calculator = new BankAccountValidatorTool.Banks.DigitCalculator(
    numberPadded,
    [11, 10, 9, 8, 7, 6, 5, 4, 3, 2],
  )

  const digit = digit_calculator.sum_numbers() % 11

  if (digit === 0 || digit === 1) {
    return number_digit === '0'
  }

  return String(11 - digit) === number_digit
}

export default checkCitybankAccount
