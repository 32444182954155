<template>
  <div class="app">
    <!-- PAGE CONTENT -->
    <main class="main page-content-wrapper">
      <div class="header">
        <BaseIcon
          v-if="displayBackButton"
          class="back-icon"
          name="arrow-back-mono"
          @click="onBackClick"
        />
        <div v-if="displayProgressBar" class="progress-wrapper">
          <ProgressBarInderterminated :progress="currentProgress" />
        </div>
        <BaseIcon class="close-icon" name="close-mono" @click="onCloseClick" />
      </div>
      <!-- CONTENT -->
      <div class="page-content">
        <transition
          name="custom-classes"
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in"
        >
          <AppLoader v-if="busy" key="loader" simple />
          <slot v-else key="content" />
        </transition>
      </div>

      <!-- FEEDBACK -->
      <Feedback :data="feedback" />
    </main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AppLoader from '@/components/appPartials/AppLoader2.vue'
import ProgressBarInderterminated from '@/aasgard/components/feedback/ProgressBarInderterminated.vue'
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
import Feedback from '@/components/ui/Feedback.vue'
export default {
  name: 'UnloggedLayout',
  components: {
    AppLoader,
    ProgressBarInderterminated,
    BaseIcon,
    Feedback,
  },
  data() {
    return {
      busy: true,
      currentPage: undefined,
      displayProgressBar: false,
      currentProgress: 0,
      displayBackButton: true,
    }
  },
  computed: {
    ...mapGetters({
      feedback: 'utils/feedback/feedback',
    }),
  },
  created() {
    this.gEventBus.on('layout-unlogged:display-back-button', () => {
      this.displayBackButton = true
    })
    this.gEventBus.on('layout-unlogged:hide-back-button', () => {
      this.displayBackButton = false
    })
    this.gEventBus.on('layout-unlogged:display-progress-bar', () => {
      this.displayProgressBar = true
    })
    this.gEventBus.on('layout-unlogged:hide-progress-bar', () => {
      this.displayProgressBar = false
    })
    this.gEventBus.on('layout-unlogged:update-current-progress', (e) => {
      this.currentProgress = e
    })
  },
  mounted() {
    this.busy = false
  },
  methods: {
    onBackClick() {
      this.gEventBus.emit('layout-unlogged:back-clicked')
    },
    onCloseClick() {
      this.gEventBus.emit('layout-unlogged:close-clicked')
    },
  },
}
</script>
<style lang="scss">
.page-content-wrapper {
  max-width: 1280px;
  padding: 16px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: $color-white-high;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 0 16px 0;

    .progress-wrapper {
      width: 100%;
      margin-left: auto;
      padding: 0 24px;
    }

    .action-button-desktop {
      border: solid 1px $color-brand-primary;
      box-sizing: border-box;
      width: 48px;
      min-width: 48px;
      height: 48px;
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      .BaseIcon {
        margin: 0;

        :not(svg) {
          fill: $color-brand-primary;
          fill-opacity: 1;
        }
      }
    }

    .back-icon {
      margin-left: unset;
    }

    .close-icon {
      margin-left: auto;
    }

    .BaseIcon {
      cursor: pointer;
    }
  }

  .page-content {
    min-height: calc(100vh - 80px);
  }
}

.page-content-wrapper {
  @include desktop {
    .header {
      padding: 0 0 40px 0;

      .progress-wrapper {
        padding: 0 48px;
      }
    }

    .page-content {
      min-height: calc(100vh - 148px);
    }
  }
}
</style>
