import {
  fetchUserColdOffers,
  getColdOfferRequest,
  getLatestColdOfferRequest,
  getUserColdOffersByRequestId,
  submitAutoColdOffer,
} from '@/api/loans/auto/cold-offers'
import {
  simulateAutoColdOffer,
  simulateAutoColdOfferV2,
} from '@/api/loans/auto/simulate'
import {
  AutoEquitySimulationRequest,
  AutoEquitySimulationRequestV2,
} from '@/data/loans/auto/simulation'
import {
  AutoEquityColdOffer,
  ColdOfferRequest,
  ColdOfferResponse,
} from '@/data/loans/auto/coldoffer'
import {
  filterExpiredOffers,
  offerPartnersConfig,
  waitForAResultWithOptions,
} from '@/helpers/offers'
import { Partner } from '@/data/common'
import { sanitizeAddressNumber } from '~/helpers/address'

// STATE
const state = (): any => ({
  coldOffers: [],
  coldOfferSelected: null,
  coldOfferSelectedRequest: null,
  latestColdOfferRequest: null,
  lastSimulationId: '',
  coldOfferSchedulerTimeoutSeconds:
    import.meta.env.VITE_SIMULATING_SECONDS_WAIT_TIME_AUTO || 30,
})

// GETTERS
const getters = {
  gtAutoColdOffers(state, getters, rootState, rootGetters): any {
    const filterProposedPartnersKeys = { [Partner.CREDITAS]: true }
    const proposedOffersPartnersKeys = {} as any
    const validatingOffersPartnersKeys = {} as any
    ;(
      rootGetters[
        'products/loans/auto-equity/auto-equity/gtAllProposedAutoOffers'
      ] || []
    ).forEach((o) => (proposedOffersPartnersKeys[o.partner] = true))
    ;(
      rootGetters[
        'products/loans/auto-equity/auto-equity/gtAllValidatingAutoOffers'
      ] || []
    ).forEach((o) => (validatingOffersPartnersKeys[o.partner] = true))
    return (state.coldOffers || [])
      .filter(
        (coldOffer) =>
          !(
            import.meta.env.VITE_AUTO_EQUITY_FILTER_PARTNER_COLD_OFFER ?? ''
          ).includes(coldOffer.partner),
      )
      .filter(filterExpiredOffers)
      .filter(({ partner }) => {
        return !(
          filterProposedPartnersKeys[partner] &&
          (proposedOffersPartnersKeys[partner] ||
            validatingOffersPartnersKeys[partner])
        )
      })
  },
  gtColdOfferSelected(state): any {
    return state.coldOfferSelected || null
  },
  gtColdOfferSelectedRequest(state) {
    return state.coldOfferSelectedRequest
  },
  gtAutoEquityLatestColdOfferRequest(state) {
    return state.latestColdOfferRequest
  },
  gtAutoEquityColdOfferSchedulerTimeoutSeconds(state) {
    return state.coldOfferSchedulerTimeoutSeconds
  },
  gtAutoColdOfferLastSimulationId(state) {
    return state.lastSimulationId
  },
}

// MUTATIONS

const mutations = {
  UPDATE_COLD_OFFERS(state, payload: ColdOfferResponse[]): void {
    state.coldOffers = payload.map((coldOffer) => {
      const partnerConfig = offerPartnersConfig[coldOffer.partner]
      return {
        ...coldOffer,
        amount_min: coldOffer?.amount_min || partnerConfig.amount_min,
        amount_max: coldOffer?.amount_max || partnerConfig.amount_max,
        duration_min: coldOffer?.duration_min || partnerConfig.duration_min,
        duration_max: coldOffer?.duration_max || partnerConfig.duration_max,
        interest_monthly_min:
          (coldOffer?.interest_monthly_min ||
            partnerConfig.interest_monthly_min) * 100,
        interest_monthly_max: coldOffer.interest_monthly_max * 100,
        product_type: 'auto-cold-offer',
      }
    })
  },
  SET_SELECTED_COLD_OFFER(state, payload) {
    state.coldOfferSelected = payload
  },
  SET_COLD_OFFER_REQUEST(state, coldOfferSelectedRequest: ColdOfferRequest) {
    state.coldOfferSelectedRequest = coldOfferSelectedRequest
  },
  SET_LATEST_COLD_OFFER_REQUEST(
    state,
    coldOfferSelectedRequest: ColdOfferRequest,
  ) {
    state.latestColdOfferRequest = coldOfferSelectedRequest
  },
  SET_LATEST_COLD_OFFER_REQUEST_ID(state, id: string) {
    state.lastSimulationId = id
  },
}

// ACTIONS

const actions = {
  async actGetAutoColdOffers({ commit }) {
    try {
      const response = await fetchUserColdOffers()
      if (response.status === 200) {
        commit('UPDATE_COLD_OFFERS', response?.data || [])
      } else {
        commit('UPDATE_COLD_OFFERS', [])
      }

      return response
    } catch (e: any) {
      commit('UPDATE_COLD_OFFERS', [])
      return e?.response
    }
  },
  async actGetColdOfferRequest({ commit }, coldOfferId) {
    try {
      const response = await getColdOfferRequest(coldOfferId)
      if (response.status === 200) {
        commit('SET_COLD_OFFER_REQUEST', response?.data || {})
      } else {
        commit('SET_COLD_OFFER_REQUEST', {})
      }

      return response
    } catch (e: any) {
      commit('SET_COLD_OFFER_REQUEST', {})
      return e?.response
    }
  },
  async actGetAutoEquityLatestColdOfferRequest({ commit }) {
    try {
      const response = await getLatestColdOfferRequest()
      if (response.status === 200) {
        commit('SET_LATEST_COLD_OFFER_REQUEST', response?.data || {})
      } else {
        commit('SET_LATEST_COLD_OFFER_REQUEST', {})
      }

      return response
    } catch (e: any) {
      commit('SET_LATEST_COLD_OFFER_REQUEST', {})
      return e?.response
    }
  },
  async actSetSelectedColdOffer({ commit, dispatch }, payload) {
    commit('SET_SELECTED_COLD_OFFER', payload)
    await dispatch('actGetColdOfferRequest', payload?.cold_offer_id)
  },
  async actSubmitAutoColdOffer({ commit }, payload: ColdOfferRequest) {
    try {
      const { vehicle, score } = payload
      const requestPayload = {
        ...payload,
        vehicle: {
          ...vehicle,
          vehicle_debt_over_eighty_percent:
            vehicle?.vehicle_debt_over_eighty_percent ?? vehicle?.vehicle_debt,
        },
      }
      if (score === undefined) delete requestPayload.score
      const response = await submitAutoColdOffer(requestPayload)
      if (response?.data?.id) {
        commit('SET_LATEST_COLD_OFFER_REQUEST_ID', response?.data?.id)
      }
      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actSimulateAutoColdOffer(
    _,
    autoEquitySimulationRequest: AutoEquitySimulationRequest,
  ) {
    try {
      const request = {
        ...autoEquitySimulationRequest,
        residence: {
          ...autoEquitySimulationRequest.residence,
          number: sanitizeAddressNumber(
            autoEquitySimulationRequest.residence.number,
          ),
        },
      }
      return await simulateAutoColdOffer(request)
    } catch (e: any) {
      return e?.response
    }
  },
  async actSimulateAutoColdOfferV2(
    _,
    autoEquitySimulationRequest: AutoEquitySimulationRequestV2,
  ) {
    try {
      const request = {
        ...autoEquitySimulationRequest,
        residence: {
          ...autoEquitySimulationRequest.residence,
          number: sanitizeAddressNumber(
            autoEquitySimulationRequest.residence.number,
          ),
        },
      }
      return await simulateAutoColdOfferV2(request)
    } catch (e: any) {
      return e?.response
    }
  },
  async actTrackAutoColdOfferSimulationEnd(_, { requestId }) {
    let response
    try {
      const expectedPartners = [
        Partner.CREDITAS,
        Partner.BV,
        Partner.EMPRESTIMO_SIM,
      ]
      response = await waitForAResultWithOptions(
        (data: Array<AutoEquityColdOffer>) => {
          return (
            data?.length &&
            expectedPartners.every(
              (p: Partner) =>
                !!data.find((cold: AutoEquityColdOffer) => cold.partner === p),
            )
          )
        },
        async () => {
          try {
            return await getUserColdOffersByRequestId(requestId)
          } catch (e: any) {
            return e?.response
          }
        },
        5000,
        3,
      )
    } catch (e: any) {
      response = null
    }

    return response
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
