import coldOffer from '@/store/modules/products/loans/home-equity/cold-offer'
import homeEquity from '@/store/modules/products/loans/home-equity/home-equity'
const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    'cold-offer': coldOffer,
    'home-equity': homeEquity,
  },
}
