<template>
  <ClearLayout>
    <SendingDocumentsContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const SendingDocumentsContainer = defineAsyncComponent(
  () => import('@/containers/newAppDocs/SendingDocumentsContainer.vue'),
)
export default {
  name: 'SendDocuments',
  components: {
    ClearLayout,
    SendingDocumentsContainer,
  },
}
</script>
<style lang="scss"></style>
