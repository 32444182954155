import { loadAsyncScript } from '@/helpers/script'
import { sentryException } from '@/helpers/sentry'
window.addEventListener('load', () => {
  setTimeout(() => {
    window.hj =
      window.hj ||
      function (...args: any[]) {
        ;(window.hj.q = window.hj.q || []).push(args)
      }
    window._hjSettings = { hjid: 1583366, hjsv: 6 }
    try {
      loadAsyncScript({
        src: 'https://static.hotjar.com/c/hotjar-1583366.js?sv=6',
        defer: true,
      })
    } catch (e: any) {
      sentryException(e)
    }
  })
})
