<template>
  <ClearLayout>
    <IncomeLossInsurancePersonaPageContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const IncomeLossInsurancePersonaPageContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/income-loss/hiring/IncomeLossInsurancePersonaPageContainer.vue'
    ),
)
export default {
  name: 'IncomeLossInsurancePersonaPage',
  components: {
    ClearLayout,
    IncomeLossInsurancePersonaPageContainer,
  },
}
</script>
<style lang="scss"></style>
