<template>
  <div class="ProgressBarInderterminated">
    <div class="progress" :style="currentProgressStyle" />
  </div>
</template>

<script>
export default {
  name: 'ProgressBarInderterminated',
  props: {
    progress: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100
      },
    },
  },
  computed: {
    currentProgressStyle() {
      return `left: calc(${this.progress}% - 50%);`
    },
  },
}
</script>

<style lang="scss">
.ProgressBarInderterminated {
  width: 100%;
  height: 4px;
  position: relative;
  background-color: $color-neutral-dark;
  overflow: hidden;

  &,
  * {
    box-sizing: border-box;
  }

  .progress {
    background: linear-gradient(
      270deg,
      rgba(84, 32, 164, 0) 0%,
      #5420a4 51.08%,
      rgba(84, 32, 164, 0) 100%
    );
    width: 58%;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s ease-in-out;
    animation: movingWidth 8s linear infinite;
  }

  @keyframes movingWidth {
    0% {
      width: 58%;
    }

    50% {
      width: 80%;
    }

    100% {
      width: 58%;
    }
  }
}
</style>
