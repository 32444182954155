import { fetchAutoOfferById, fetchAutoOffers } from '@/api/loans/auto/offers'
import { selectAutoOffer } from '@/api/loans/auto/offers/select-offer'
import {
  filterExpiredOffers,
  offerPartnersConfig,
  partnerHasStepFields,
  sortOffersByInterestRate,
} from '@/helpers/offers'
import {
  getContractToken,
  getOfferContract,
  signContract,
} from '@/api/loans/contract'
import { AutoOfferResponse } from '@/data/loans/auto/offer'
import { SelectAutoEquityOffer } from '@/data/loans/auto/select'
import { SignContractRequest } from '@/data/contract/contract'
import { OfferStatus, Partner } from '@/data/common'
import { fetchAutoOffersBySimulationId } from '@/api/loans/auto/simulate'
import router from '@/router'

// STATE
const state = (): any => ({
  brands: [],
  models: [],
  proposedOffers: [],
  onGoingOffers: [],
  validatingOffers: [],
  manufacturingYears: [],
  simulationInfo: {},
  autoOfferSelected: null,
  autoOfferSelectedContract: '',
})

// GETTERS
const getters = {
  gtAllProposedAutoOffers(state) {
    return state.proposedOffers
      .filter(
        (o) =>
          !(
            import.meta.env.VITE_AUTO_EQUITY_FILTER_PARTNER_OFFER ?? ''
          ).includes(o.partner),
      )
      .filter((offer: AutoOfferResponse) => {
        // FILTER EMPRESTIMO_SIM OFFERS CREATED BEFORE THAN 1 HOUR AGO
        if (offer.partner === Partner.EMPRESTIMO_SIM) {
          const today = new Date()
          const todayOneHourAgo = new Date(
            new Date().setHours(today.getHours() - 1),
          )
          return new Date(offer.created).getTime() > todayOneHourAgo.getTime()
        }

        return true
      })
      .filter(filterExpiredOffers)
      .sort(sortOffersByInterestRate)
  },
  gtAllValidatingAutoOffers(state) {
    return state.validatingOffers.filter(
      (o) =>
        !(import.meta.env.VITE_AUTO_EQUITY_FILTER_PARTNER_OFFER ?? '').includes(
          o.partner,
        ),
    )
  },
  gtAllOnGoingAutoOffers(state) {
    return state.onGoingOffers.filter(
      (o) =>
        !(import.meta.env.VITE_AUTO_EQUITY_FILTER_PARTNER_OFFER ?? '').includes(
          o.partner,
        ),
    )
  },
  gtAutoOfferSelected(state): any {
    return state.autoOfferSelected
  },
  gtAutoOfferSelectedContract(state): any {
    return state.autoOfferSelectedContract
  },
}

// MUTATIONS

const mutations = {
  SET_NEW_OFFERS(state, payload: Array<AutoOfferResponse | any>): void {
    payload.forEach((o: AutoOfferResponse) => {
      // STANDARDIZE OFFER
      o.product_type = 'auto'
      o.interest_rate_monthly =
        o.interest_rate_monthly ||
        offerPartnersConfig[o.partner].interest_monthly_min
      o.duration_max =
        o.duration_max || offerPartnersConfig[o.partner].duration_max

      // SET OFFER DECIMALS
      o.cet_rate = o.cet_rate * 100
      o.interest_rate_annually = o.interest_rate_annually * 100
      o.interest_rate_monthly = o.interest_rate_monthly * 100
      // o.iof = o.iof * 100
      o.delay_fee = o.delay_fee * 100
    })

    state.proposedOffers = payload.filter(
      (o) => o.status === OfferStatus.PROPOSED,
    )
    state.onGoingOffers = payload.filter(
      (o) => o.status === OfferStatus.ONGOING,
    )
    state.validatingOffers = payload.filter(
      (o) => o.status === OfferStatus.VALIDATING,
    )
  },
  SET_AUTO_OFFER_CONTRACT(state, payload): void {
    const response = Object.values(payload) as any
    if (response[0] && response[0].contract_text) {
      state.autoOfferSelectedContract = response[0].contract_text
    }
  },
  SET_AUTO_OFFER_SELECTED(state, payload) {
    state.autoOfferSelected = payload
  },
}

// ACTIONS

const actions = {
  // NEW MODEL METHODS
  actSetAutoOfferSelected({ commit }, payload) {
    commit('SET_AUTO_OFFER_SELECTED', payload)
  },
  async actGetAutoOffers(
    { dispatch, commit },
    options = {
      status: [
        OfferStatus.PROPOSED,
        OfferStatus.ONGOING,
        OfferStatus.VALIDATING,
      ],
    },
  ) {
    const errorMsg =
      'Tivemos um probleminha ao consultar novas ofertas para voce'
    try {
      const response = await fetchAutoOffers(options)
      const { data } = response
      if (data) {
        commit('SET_NEW_OFFERS', data)
      } else {
        dispatch('utils/feedback/createFeedback', errorMsg, { root: true })
        return
      }
      return response
    } catch (e: any) {
      dispatch('utils/feedback/createFeedback', errorMsg, { root: true })
      commit('SET_NEW_OFFERS', [])
      return []
    }
  },
  async actGetAutoOfferById(_, offer_id) {
    try {
      const response = await fetchAutoOfferById(offer_id)
      if (response?.data) {
        response.data = {
          ...response.data,
          cet_rate: response.data?.cet_rate * 100,
          interest_rate_annually: response.data?.cet_rate * 100,
          interest_rate_monthly: response.data?.cet_rate * 100,
          iof: response.data?.iof * 100,
          delay_fee: response.data?.delay_fee * 100,
        }
      }
      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actGetAutoOffersBySimulationId(
    _,
    {
      simulationId,
      statusList,
    }: { simulationId: string; statusList: Array<OfferStatus> | undefined },
  ) {
    try {
      const response = await fetchAutoOffersBySimulationId(
        simulationId,
        statusList,
      )
      if (response?.status === 200 && response?.data?.length > 0) {
        response.data.forEach((o: AutoOfferResponse) => {
          // STANDARDIZE OFFER
          o.interest_rate_monthly =
            o.interest_rate_monthly ||
            offerPartnersConfig[o.partner].interest_monthly_min
          o.duration_max =
            o.duration_max || offerPartnersConfig[o.partner].duration_max

          // SET OFFER DECIMALS
          o.cet_rate = o.cet_rate * 100
          o.interest_rate_annually = o.interest_rate_annually * 100
          o.interest_rate_monthly = o.interest_rate_monthly * 100
          o.iof = o.iof * 100
          o.delay_fee = o.delay_fee * 100
        })
      }
      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actSelectAutoOffer(
    _,
    {
      offer,
      payload = {},
    }: {
      offer: AutoOfferResponse
      payload: SelectAutoEquityOffer | object
    },
  ) {
    try {
      const partnerFields = partnerHasStepFields({ offer, step: 'auto:select' })
      const { partner } = offer

      if (partnerFields && !(Object.keys(payload).length > 0)) {
        router.push({
          path: `/forms/emprestimo/auto/parceiros/${partner.toLowerCase()}/selecionar-oferta`,
        })
        return 'REDIRECTED_TO_PARTNER_PAGES'
      }

      return await selectAutoOffer({
        payload,
        offer,
      })
    } catch (e: any) {
      return e?.response
    }
  },
  async actGetAutoOfferContractToken(_, offer_id: string) {
    try {
      return await getContractToken({
        offer_id,
        product: 'auto',
      })
    } catch (e: any) {
      return e?.response
    }
  },
  async actGetAutoOfferContract({ commit }, offer_id: string) {
    try {
      const response = await getOfferContract({
        offer_id,
        product: 'auto',
      })
      const { data, status } = response

      if (status === 200) {
        commit('SET_AUTO_OFFER_CONTRACT', data)
      }

      return response
    } catch (e: any) {
      return e?.response
    }
  },
  async actSignAutoOfferContract(
    _,
    {
      offer,
      payload = {},
    }: {
      offer: any
      payload: SignContractRequest | object
    },
  ) {
    try {
      const partnerFields = partnerHasStepFields({
        offer,
        step: 'auto:contract',
      })
      const { offer_id, partner } = offer

      if (partnerFields && !(Object.keys(payload).length > 0)) {
        router.push({
          path: `/forms/emprestimo/auto/parceiros/${partner.toLowerCase()}/contrato/${offer_id}`,
        })
        return
      }

      return await signContract({
        payload,
        offer_id,
        product: 'auto',
      })
    } catch (e: any) {
      return e?.response
    }
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
