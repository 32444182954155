<template>
  <ClearLayout>
    <div>
      <SelfieComponent
        v-if="cptdDisplaySelfie && !busy"
        :busy="busy"
        :status="status"
        @upload="upload"
        @submit="submit"
        @done="submit"
      />
      <UnexpectedPartnerOfferErrorFeedbackDialog
        v-if="!busy && displayUnexpectedPartnerOfferErrorFeedbackDialog"
        :display="displayUnexpectedPartnerOfferErrorFeedbackDialog"
        :offer-id="offer_id"
        :partner="partner"
        :step="cptdCurrentStep"
      />
      <OfferPartnerTimeoutFeedbackDialog
        v-if="!busy && displayOfferPartnerTimeoutFeedbackDialog"
        :display="displayOfferPartnerTimeoutFeedbackDialog"
        :partner="partner"
      />
      <FullscreenLoading v-if="busy">
        <template #loading-message> Finalizando solicitação... </template>
      </FullscreenLoading>
    </div>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { productsMap } from '@/helpers/appdocs'

import { OfferStatus, OfferSubStatus } from '@/data/common'
import { wait } from '@/helpers/globalFunctions'
import { UnexpectedPartnerOfferStepEnum } from '@/helpers/offer-types'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const OfferPartnerTimeoutFeedbackDialog = defineAsyncComponent(
  () => import('@/components/loans/OfferPartnerTimeoutFeedbackDialog.vue'),
)
const UnexpectedPartnerOfferErrorFeedbackDialog = defineAsyncComponent(
  () =>
    import('@/components/loans/UnexpectedPartnerOfferErrorFeedbackDialog.vue'),
)
const FullscreenLoading = defineAsyncComponent(
  () => import('@/components/commomComponents/loaders/FullscreenLoading.vue'),
)
const SelfieComponent = defineAsyncComponent(
  () => import('@/components/newAppDocs/SelfieComponent.vue'),
)
export default {
  name: 'EmprestimoSimAutoSelfieSignContractPage',
  components: {
    ClearLayout,
    SelfieComponent,
    OfferPartnerTimeoutFeedbackDialog,
    UnexpectedPartnerOfferErrorFeedbackDialog,
    FullscreenLoading,
  },
  data() {
    return {
      formId: 'EMPRESTIMO_SIM',
      partner: 'EMPRESTIMO_SIM',
      offer_id: '',
      doc_id: '',
      product: 'auto',
      status: 'loading',
      busy: false,
      displayUnexpectedPartnerOfferErrorFeedbackDialog: false,
      displayOfferPartnerTimeoutFeedbackDialog: false,
      waitForAgreementAcceptedOfferTimeout: {
        id: undefined,
        attempts: 1,
        maxRetries: 3,
      },
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      gtAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/gtAutoOfferSelected',
      gtOfferDocumentsMappedByType:
        'products/appdocs/gtOfferDocumentsMappedByType',
      gtCurrentDocumentData: 'products/appdocs/gtCurrentDocumentData',
    }),
    cptdCurrentStep() {
      return UnexpectedPartnerOfferStepEnum.SIGN_CONTRACT
    },
    cptdDisplaySelfie() {
      return this.offer_id && this.partner && this.product && this.doc_id
    },
    cptdCurrentForm() {
      return this.gtDynamicForms[this.formId] || {}
    },
  },
  async created() {
    const { offer_id } = this.$route?.params
    if (!this.cptdCurrentForm || !this.cptdCurrentForm?.code) {
      this.$router.push({
        path: `/forms/emprestimo/auto/parceiros/emprestimo_sim/contrato/${offer_id}`,
      })
      return
    }
    this.offer_id = offer_id
    await this.actRetrieveOfferDocs({
      offer_id,
      product: this.product,
      payload: {
        offer_id,
        partner: this.partner,
        for_product: productsMap.auto,
      },
    })
    this.doc_id = this.gtOfferDocumentsMappedByType?.selfie?.id

    if (this.doc_id) {
      await this.actGetDocument({
        offer_id,
        doc_id: this.doc_id,
        product: this.product,
      })
    } else {
      this.createFeedback({
        text: 'Link para upload de documento não encontrado',
      })
      this.$router.push({
        path: '/ofertas',
      })
    }
  },
  methods: {
    ...mapActions({
      actSetupForm: 'dynamic-forms/actSetupForm',
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      createFeedback: 'utils/feedback/createFeedback',
      actGetAutoOfferContractToken:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferContractToken',
      actSignAutoOfferContract:
        'products/loans/auto-equity/auto-equity/actSignAutoOfferContract',
      actGetAutoOfferById:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferById',
      actGetDocument: 'products/appdocs/actGetDocument',
      actPutDocS3: 'products/appdocs/actPutDocS3',
      actRefreshOfferDocsLinks: 'products/appdocs/actRefreshOfferDocsLinks',
      actRetrieveOfferDocs: 'products/appdocs/actRetrieveOfferDocs',
    }),
    upload() {
      this.status = 'completed'
    },
    async submit(v) {
      this.upload()
      const contractSigned = await this.signContract()
      if (contractSigned) {
        this.busy = true
        const response = await this.actPutDocS3({
          file: v,
          url: this.gtCurrentDocumentData?.s3_link,
        })
        if (response?.status === 200) {
          this.scheduleWaitForAgreementAcceptedOffer()
        } else {
          await this.actRefreshOfferDocsLinks({
            offer_id: this.offer_id,
            product: this.product,
          })
          this.busy = false
          this.status = 'error'
          clearTimeout(this.waitForAgreementAcceptedOfferTimeout.id)
          this.createFeedback({
            text: 'Algo deu errado! Por favor tente novamente.',
          })
        }
      } else {
        this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
      }
    },
    async signContract() {
      this.busy = true
      const response = await this.actSignAutoOfferContract({
        offer: {
          offer_id: this.offer_id,
          partner: 'EMPRESTIMO_SIM',
        },
        payload: {
          empsim: {
            code: this.cptdCurrentForm?.code,
          },
        },
      })

      if (response?.status === 200) {
        await wait(5000)
        return true
      } else {
        this.createFeedback({
          text: 'Algo deu errado! Por favor tente novamente.',
        })
      }
      return false
    },
    scheduleWaitForAgreementAcceptedOffer() {
      this.waitForAgreementAcceptedOfferTimeout.id = setInterval(async () => {
        if (
          this.waitForAgreementAcceptedOfferTimeout.attempts >
          this.waitForAgreementAcceptedOfferTimeout.maxRetries
        ) {
          this.waitForAgreementAcceptedOfferTimeout.attempts = 1
          this.displayOfferPartnerTimeoutFeedbackDialog = true
          this.busy = false
          clearTimeout(this.waitForAgreementAcceptedOfferTimeout.id)
          return
        }
        try {
          const response = await this.actGetAutoOfferById(this.offer_id)
          if (response?.data && response?.status === 200) {
            if (
              response?.data?.sub_status ===
                OfferSubStatus.AGREEMENT_ACCEPTED ||
              response?.data.status === OfferStatus.GRANTED
            ) {
              this.$router.push({
                path: `/oferta/aceita/emprestimo_sim/${this.offer_id}`,
              })
              clearTimeout(this.waitForAgreementAcceptedOfferTimeout.id)
              return
            }

            if (
              response?.data?.sub_status ===
                OfferSubStatus.INFORMATION_INCORRECT ||
              response?.data?.sub_status ===
                OfferSubStatus.PROBLEM_WITH_DOCUMENT
            ) {
              this.busy = false
              this.displayUnexpectedPartnerOfferErrorFeedbackDialog = true
              clearTimeout(this.waitForAgreementAcceptedOfferTimeout.id)
              return
            }
          }
        } catch (e) {}

        this.waitForAgreementAcceptedOfferTimeout.attempts += 1
      }, 10000)
    },
  },
}
</script>
<style lang="scss"></style>
