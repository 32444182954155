<template>
  <div class="Checkbox" :class="cptdCssCheckBox">
    <input
      :id="id"
      :value="modelValue"
      type="checkbox"
      :checked="modelValue"
      @change="onChange"
    />
    <label>
      <label :for="id" class="checkbox-square">
        <BaseIcon v-if="modelValue" name="check-mono" />
      </label>
      <span class="texts-wrapper">
        <span class="label">
          <slot>{{ label }}</slot>
        </span>
        <span v-if="!modelValue && error" class="error">
          {{ errorMessage }}
        </span>
      </span>
    </label>
  </div>
</template>

<script>
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'
export default {
  name: 'Checkbox',
  components: { BaseIcon },
  props: {
    id: {
      type: [String, Number],
      default: 'checkbox',
    },
    label: {
      type: [String, Number],
      default: '',
    },
    errorMessage: {
      type: [String, Number],
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    background: {
      type: Boolean,
      default: true,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Boolean, Number],
      default: null,
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      modelValue: false,
    }
  },
  computed: {
    cptdCssCheckBox() {
      return {
        error: !this.checked && this.error,
        checked: this.checked,
        'no-background': !this.background,
        optional: this.optional,
      }
    },
  },
  mounted() {
    this.modelValue = this.value
  },
  methods: {
    onChange(event) {
      this.modelValue = event?.target?.checked ?? !this.modelValue
      this.$emit('update:value', this.modelValue)
      this.$emit('input', this.modelValue)
    },
  },
}
</script>

<style lang="scss">
.Checkbox {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  }

  input[type='checkbox'] {
    position: fixed;
    top: -1.5em;
    left: -1.5em;
  }
  input[type='checkbox'] + label {
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 16px;
    background-color: $color-neutral-light;
    border-radius: 4px;
    width: 100%;

    .checkbox-square {
      align-items: center;
      justify-content: center;
      border: 2px solid $color-neutral-dark;
      background: $color-white-high;
      color: $color-brand-primary;
      display: inline-flex;
      margin-right: 20px;
      border-radius: 4px;
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      position: relative;
      box-sizing: border-box;
      transition: all 0.2s ease;
      cursor: pointer;

      &:before {
        content: '';
        width: 0;
        height: 0;
        background-color: transparent;
        opacity: 0;
        box-sizing: border-box;
        border-radius: 50%;
        transition: all 0.2s ease;
      }
    }

    .texts-wrapper {
      display: flex;
      flex-direction: column;
      .label,
      .label *,
      .error {
        @include body-2;
      }

      .label {
        color: $color-black-high;
      }

      .error {
        color: $color-feedback-negative;
        animation: fadeIn ease 0.3s forwards;
      }
    }
  }
  input[type='checkbox']:checked + label {
    .checkbox-square {
      border: 2px solid $color-brand-primary;
      background-color: $color-brand-primary;

      svg {
        position: absolute;
        width: 20px;
        height: 20px;

        :not(svg) {
          stroke: #fff;
          stroke-opacity: 1;
        }
      }
    }
  }
}
.Checkbox.error {
  input[type='checkbox'] + label {
    background-color: $color-feedback-negative-light;

    .checkbox-square {
      border: 2px solid $color-feedback-negative;
      color: $color-feedback-negative;
    }
  }
}
.Checkbox.no-background {
  input[type='checkbox'] + label {
    background-color: transparent;
  }
}

.Checkbox.optional {
  input[type='checkbox'] + label {
    background-color: transparent;
    border: solid 1px $color-neutral-dark;
    border-radius: 12px;
  }
}
</style>
