<template>
  <div
    v-if="!cptdIsVisible"
    v-when-visible="displayImageWhenVisible"
    class="ImageLazyLoader skeleton"
  />
  <img
    v-else
    :id="cptdId"
    class="ImageLazyLoader"
    :src="cptdImgSrc"
    :alt="alt"
  />
</template>

<script>
import whenVisibleDirective from '@/aasgard/directives/when-visible'
export default {
  name: 'ImageLazyLoader',
  directives: {
    ...whenVisibleDirective,
  },
  props: {
    src: { type: String, required: true },
    alt: { type: String, required: true },
    id: { type: String, required: true },
    mountedTimeoutMs: { type: Number, default: 500 },
  },
  data() {
    return {
      visible: false,
      mounted: false,
    }
  },
  computed: {
    cptdId() {
      return 'ImageLazyLoader-' + this.id
    },
    cptdIsVisible() {
      return this.mounted && this.visible
    },
    cptdImgSrc() {
      return this.cptdIsVisible ? this.src : ''
    },
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true
    }, this.mountedTimeoutMs)
  },
  methods: {
    displayImageWhenVisible() {
      this.visible = true
    },
  },
}
</script>

<style lang="scss">
.ImageLazyLoader {
  background-color: inherit;
  border-radius: inherit;
  animation: ani-fade-in 1s linear;
  max-width: 100%;
  max-height: 100%;

  &.skeleton {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    animation: skeleton-loading 0.5s linear infinite alternate;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: $color-neutral-dark;
    }
    100% {
      background-color: $color-neutral-light;
    }
  }

  @keyframes ani-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
