<template>
  <div class="fullscreen-loading-component">
    <Spinner theme="brand-dark" />
    <p><slot name="loading-message" /></p>
    <slot name="other-content" />
  </div>
</template>
<script>
import Spinner from '@/aasgard/components/feedback/Spinner.vue'
export default {
  name: 'FullscreenLoadingComponent',
  components: {
    Spinner,
  },
}
</script>
<style lang="scss">
.fullscreen-loading-component {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  min-width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: $color-brand-primary;

  & > p {
    @include subtitle-2;
    text-align: center;
    color: $color-white-high;
    margin-top: 24px;
  }
}
</style>
