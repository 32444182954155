<template>
  <div class="Spinner" :class="[theme, size]" />
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg'].includes(value)
      },
    },
    theme: {
      type: String,
      default: 'brand-light',
      validator(value) {
        return (
          ['brand-light', 'brand-dark', 'positive'].includes(value) ||
          value === 'brand-light'
        )
      },
    },
  },
}
</script>

<style lang="scss">
.Spinner {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  min-width: 32px;
  min-height: 32px;
  position: relative;
  border-style: solid;
  text-indent: -9999em;
  transform: translate(0);
  -webkit-animation: spin 1.1s infinite linear;
  animation:
    spin 1.1s 0.3s infinite linear,
    fade-in 0.3s linear;

  &,
  * {
    box-sizing: border-box;
  }

  &:after {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
  }

  &.brand-light {
    border-top-color: $color-black-low;
    border-right-color: $color-black-low;
    border-bottom-color: $color-black-low;
    border-left-color: $color-brand-primary;
  }

  &.brand-dark {
    border-top-color: $color-white-lower;
    border-right-color: $color-white-lower;
    border-bottom-color: $color-white-lower;
    border-left-color: $color-white-high;
  }

  &.positive {
    border-top-color: $color-feedback-positive-light;
    border-right-color: $color-feedback-positive-light;
    border-bottom-color: $color-feedback-positive-light;
    border-left-color: $color-feedback-positive;
  }

  &.sm {
    width: 24px;
    height: 24px;
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-width: 3px;

    &:after {
      width: 24px;
      height: 24px;
      max-width: 24px;
      max-height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &.md {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-width: 4px;

    &:after {
      width: 32px;
      height: 32px;
      max-width: 32px;
      max-height: 32px;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &.lg {
    width: 48px;
    height: 48px;
    max-width: 48px;
    max-height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-width: 4px;

    &:after {
      width: 48px;
      height: 48px;
      max-width: 48px;
      max-height: 48px;
      min-width: 48px;
      min-height: 48px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
