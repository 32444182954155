import { createRequestException } from '@/helpers/requestException'
import { JurosBaixosSystemsAPI } from '@/helpers/axios/jurosbaixos-systems'

export const fetchUserAttributes = async () => {
  return await JurosBaixosSystemsAPI.get(`user/attribute`).catch(
    createRequestException,
  )
}

export const updateUserAttributes = async (payload) => {
  return await JurosBaixosSystemsAPI.put(`user/attribute`, payload).catch(
    createRequestException,
  )
}

export const saveUserAttributes = async (payload) => {
  return await JurosBaixosSystemsAPI.post(`/user/attribute`, payload).catch(
    createRequestException,
  )
}
