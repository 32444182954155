<template>
  <ClearLayout>
    <NoCollateralSimulateFormContainer
      @submit="$router.push('/simulando/emprestimo?product=no-collateral')"
    />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const NoCollateralSimulateFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/no-collateral/NoCollateralSimulateFormContainer.vue'
    ),
)
export default {
  name: 'SimularNoCollateral',
  components: {
    ClearLayout,
    NoCollateralSimulateFormContainer,
  },
}
</script>
<style lang="scss"></style>
