import 'animate.css'
import '@/assets/scss/_global.scss'
import '@/plugins/vee-validate'
import '@/plugins/ad-manager'
import '@/plugins/facebook-meta-pixel'
import '@/plugins/google-analytics'
import '@/plugins/google-tag-manager'
import '@/plugins/hotjar'
import '@/plugins/sentry'
import '@/plugins/clarity'

import { mediaQueryMixin } from '@/mixins/media'

import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import mitt from 'mitt'
import adCampaignCookieManager from '~/plugins/ad-campaign-cookie-manager'
import { Form } from 'vee-validate'

// App Configuration
export const app = createApp(App)

// Store
app.use(store)

// Router
adCampaignCookieManager(router)
app.use(router)

// Mitt - Global Event Bus
const emitter = mitt()
app.config.globalProperties.gEventBus = emitter

// Global Mixins
app.mixin(mediaQueryMixin)

// Global Components
app.component('FormVee', Form)

app.mount('#app')
