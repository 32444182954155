import { wait } from '@/helpers/globalFunctions'
import { OfferStatus, OfferSubStatus, Partner } from '@/data/common'
import { AutoOfferResponse, Fee } from '@/data/loans/auto/offer'
import { NoCollateralOfferResponse } from '@/data/loans/no-collateral/offer'
import { HomeEquityOfferResponse } from '@/data/loans/home-equity/offer'
import { ColdOfferResponse } from '@/data/loans/auto/coldoffer'
import { HomeEquityColdOfferResponse } from '@/data/loans/home-equity/cold-offer'
import { parseToBRL } from './currency'

const objectMatchOptions = (obj: object, options: object): boolean =>
  Object.entries(options).every(([optionKey, optionValue]) => {
    if (typeof optionValue === 'function') {
      return optionValue(obj[optionKey])
    }

    return obj[optionKey] === optionValue
  })

export const waitForAResultWithOptions = async (
  // eslint-disable-next-line @typescript-eslint/ban-types
  options: object | Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  requestCallback: Function,
  waitTimeMs = 10000,
  maxRetries = 3,
  attempts = 1,
): Promise<any | null> => {
  if (typeof options !== 'function' && !Object.keys(options).length) {
    throw new Error('OPTIONS_CANNOT_BE_EMPTY')
  }
  if (attempts > Number(maxRetries)) {
    throw new Error('MAX_RETRIES_REACHED')
  }

  await wait(Number(waitTimeMs))

  try {
    const response = await requestCallback()
    const { data, status } = response
    if (data && status === 200) {
      if (typeof options === 'function') {
        if (options(data)) {
          return data
        }
      } else if (Array.isArray(data)) {
        const filterByOptionsResult = data.filter((value) => {
          return typeof value === 'object' && objectMatchOptions(value, options)
        })

        if (filterByOptionsResult.length > 0) {
          return filterByOptionsResult[0]
        }
      } else if (
        typeof data === 'object' &&
        objectMatchOptions(data, options)
      ) {
        return data
      }
    }
  } catch (e) {
    return null
  }

  return await waitForAResultWithOptions(
    options,
    requestCallback,
    waitTimeMs,
    maxRetries,
    attempts + 1,
  )
}

// Has Fields Map
const autoSelectOfferPartnerHasFields = () => ({
  emprestimo_sim: true,
  creditas: false,
  bv: true,
})

const autoSignContractOfferPartnerHasFields = () => ({
  emprestimo_sim: false,
  creditas: false,
  bv: false,
})

// Has Full Integration
const autoFullIntegrationPartners = () => ({
  emprestimo_sim: false,
  creditas: false,
  bv: false,
})

// No Collateral Partners
// Has Fields Maps
const noCollateralSelectOfferPartnerHasFields = () => ({
  rebel: true,
  supersim: true,
  agil: true,
})

const noCollateralSignContractOfferPartnerHasFields = () => ({
  rebel: false,
  emprestimo_sim: false,
})
// Has Full Integration
const noCollateralFullIntegrationPartners = () => ({
  agil: false,
  jeitto: false,
  juvo: false,
  noverde: false,
  emprestimo_sim: false,
  simplic: false,
  supersim: false,
  portocred: false,
  rebel: false,
  itau: false,
  crefaz: false,
})

// HOME Partners
// Has Fields Maps
const homeSelectOfferPartnerHasFields = () => ({})

const homeSignContractOfferPartnerHasFields = () => ({})
// Has Full Integration
const homeFullIntegrationPartners = () => ({})

export const partnerHasFullIntegration = ({
  partner,
  product_type,
}): boolean | null => {
  const stepsMap = {
    auto: autoFullIntegrationPartners(),
    'no-collateral': noCollateralFullIntegrationPartners(),
    'home-equity': homeFullIntegrationPartners(),
  }
  if (product_type.match('old')) {
    return false
  }
  if (
    !product_type ||
    product_type === '' ||
    ![...Object.keys(stepsMap)].includes(product_type)
  ) {
    throw new Error(
      `partnerHasFullIntegration: product_type ${product_type} not found`,
    )
  }
  const stepData = stepsMap[product_type] || null
  return (partner && stepData && stepData[partner.toLowerCase()]) || null
}

const partnerHasStepFields = ({ offer, step = '' }): boolean | null => {
  const stepsMap = {
    'auto:contract': autoSignContractOfferPartnerHasFields(),
    'auto:select': autoSelectOfferPartnerHasFields(),
    'no-collateral:select': noCollateralSelectOfferPartnerHasFields(),
    'no-collateral:contract': noCollateralSignContractOfferPartnerHasFields(),
    'home-equity:select': homeSelectOfferPartnerHasFields(),
    'home-equity:contract': homeSignContractOfferPartnerHasFields(),
  }
  if (!step || step === '' || ![...Object.keys(stepsMap)].includes(step)) {
    throw new Error(`partnerHasStepFields: step ${step} not found`)
  }
  const offerPartner = offer.partner
  const stepData = stepsMap[step] || null

  return (
    (offerPartner && stepData && stepData[offerPartner.toLowerCase()]) || null
  )
}

export const offerPartnersConfig: Record<Partner, any> = {
  ZIPPI: {},
  CONEXAO_FINANCEIRA: {},
  FLOWCREDI: {},
  BANCOPAN: {},
  BANCO_DO_BRASIL: {},
  CREDIHOME: {},
  CREDITAS: {
    amount_min: 5000,
    amount_max: 150000,
    duration_min: 18,
    duration_max: 60,
    interest_monthly_min: 0.0349,
  },
  EMPRESTIMO_SIM: {
    amount_min: 5000,
    amount_max: 150000,
    duration_min: 6,
    duration_max: 36,
    interest_monthly_min: 0.0149,
  },
  BV: {
    amount_min: 5000,
    amount_max: 150000,
    duration_min: 6,
    duration_max: 36,
    interest_monthly_min: 0.0149,
  },
  NOVERDE: {},
  ITAU: {},
  PROVU: {},
  PORTOCRED: {},
  REBEL: {},
  SIMPLIC: {},
  SUPERSIM: {},
  UNKNOWN_PARTNER: {},
  ZEMA: {},
  JEITTO: {},
  AGIL: {},
  CREFAZ: {},
  JBCRED: {},
  HCRED: {},
  UPP: {},
  JUVO: {},
  BLIPAY: {},
  FACIO: {},
}

export const isOfferSelected = (
  offer: AutoOfferResponse | NoCollateralOfferResponse,
) => {
  return (
    [OfferStatus.ONGOING].includes(offer.status) &&
    [OfferSubStatus.SELECTED].includes(offer.sub_status)
  )
}

export const isOfferWaitingForDocumentUploads = (
  offer: AutoOfferResponse | NoCollateralOfferResponse,
) => {
  return (
    [OfferStatus.ONGOING].includes(offer.status) &&
    [OfferSubStatus.WAITING_FOR_DOCUMENT_UPLOADS].includes(offer.sub_status)
  )
}

export const isOfferWaitingForAgreementAcceptance = (
  offer: AutoOfferResponse | NoCollateralOfferResponse,
) => {
  return (
    [OfferStatus.ONGOING].includes(offer.status) &&
    [OfferSubStatus.WAITING_FOR_AGREEMENT_ACCEPTANCE].includes(offer.sub_status)
  )
}

export const isOfferWithInformationIncorrect = (
  offer: AutoOfferResponse | NoCollateralOfferResponse,
) => {
  return (
    [OfferStatus.FATAL_ERROR].includes(offer.status) &&
    [OfferSubStatus.INFORMATION_INCORRECT].includes(offer.sub_status)
  )
}
export const isOfferProblemWithDocuments = (
  offer: AutoOfferResponse | NoCollateralOfferResponse,
) => {
  return (
    [OfferStatus.FATAL_ERROR].includes(offer.status) &&
    [OfferSubStatus.PROBLEM_WITH_DOCUMENT].includes(offer.sub_status)
  )
}

export const sortOffersByInterestRate = (
  a: AutoOfferResponse | NoCollateralOfferResponse | HomeEquityOfferResponse,
  b: AutoOfferResponse | NoCollateralOfferResponse | HomeEquityOfferResponse,
): number => {
  const interestRateA = a?.interest_rate_monthly
  const interestRateB = b?.interest_rate_monthly

  return interestRateA < interestRateB
    ? -1
    : interestRateA > interestRateB
      ? 1
      : 0
}

export const filterExpiredOffers = (
  offer:
    | AutoOfferResponse
    | ColdOfferResponse
    | HomeEquityOfferResponse
    | HomeEquityColdOfferResponse,
): boolean => {
  if (offer?.expiration_date_time) {
    return new Date(offer.expiration_date_time)?.getTime() > Date.now()
  }

  return true
}

export const getTranslatedOfferFeeDetails = (feeDetails: Fee[]): Fee[] => {
  if (!feeDetails || !feeDetails.length) return []

  const fieldTranslationMap = {
    registration_services_fee: 'Registro do contrato',
    registry_fee: 'Tarifa de cadastro',
    asset_appraisal_fee: 'Tarifa de avaliação do bem',
    iof: 'IOF',
  }

  return feeDetails
    .filter((feeDetail) => !!fieldTranslationMap[feeDetail.name])
    .map((feeDetail) => ({
      name: fieldTranslationMap[feeDetail.name],
      value: parseToBRL(feeDetail.value),
    }))
}

export { partnerHasStepFields }
