<template>
  <div class="AdManagerAnchorSlot" />
</template>

<script>
import { toRaw } from 'vue'

export default {
  props: {
    adUnitPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      definedSlot: undefined,
      slotEventListener: undefined,
      visible: false,
    }
  },
  mounted() {
    try {
      window.googletag = window.googletag || { cmd: [] }

      this.definedSlot = window.googletag
        .defineOutOfPageSlot(
          this.adUnitPath,
          document.body.clientWidth <= 500
            ? window.googletag.enums.OutOfPageFormat.TOP_ANCHOR
            : window.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR,
        )
        .addService(window.googletag.pubads())

      this.slotEventListener = (event) => {
        if (event.slot.getSlotId() === this.definedSlot.getSlotId()) {
          if (event.inViewPercentage === 100) {
            setTimeout(() => {
              this.setContentPadding()
            }, 1000)
          } else {
            setTimeout(() => {
              this.removeContentPadding()
            }, 1000)
          }
        }
      }

      window.googletag.cmd.push(() => {
        window.googletag.display(toRaw(this.definedSlot))
        window.googletag
          .pubads()
          .addEventListener(
            'slotVisibilityChanged',
            toRaw(this.slotEventListener),
          )
      })
    } catch (e) {
      this.definedSlot = undefined
    }
  },
  beforeUnmount() {
    if (this.visible && this.definedSlot) {
      this.removeContentPadding()
    }

    if (this.definedSlot) {
      window.googletag.destroySlots([toRaw(this.definedSlot)])
    }
  },
  methods: {
    setContentPadding() {
      if (!this.visible) {
        this.visible = true
        const anchorEl = document.querySelector('ins[data-anchor-shown]')
        if (this.isDesktop && !!anchorEl?.clientHeight) {
          document
            .querySelector('#JbPrimeServiceAttendantButton')
            ?.setAttribute('style', `bottom: ${anchorEl.clientHeight + 32}px`)
        }

        const containerPadding = this.isDesktop ? 128 : 160
        document
          .querySelector('.main')
          .setAttribute('style', `padding-bottom: ${containerPadding}px;`)
      }
    },
    removeContentPadding() {
      if (this.visible) {
        this.visible = false
        document
          .querySelector('#JbPrimeServiceAttendantButton')
          ?.removeAttribute('style')
        document.querySelector('.main')?.removeAttribute('style')
      }
    },
  },
}
</script>

<style lang="scss"></style>
