<template>
  <div class="app -logged">
    <JbAnchorAdsContainer
      v-if="
        !gtIsAppBusy &&
        !(cptdUserHasOngoingOrProposedOffers || cptdUserHasColdOffers)
      "
      ad-unit-path="/22834235630/jurosbaixos.com.br/Jurosbaixos_Anchor"
    />

    <!-- HEADER -->
    <AppHeader logged :user="gtUser" :persona="personaData" />

    <main class="main">
      <ContainerWrapper>
        <h1 v-if="cptdRouteName" class="page-title">{{ cptdRouteName }}</h1>
        <JbProductsButtonsMenu v-if="cptdDisplayProductsSubMenu" />
        <transition
          name="custom-classes"
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in"
        >
          <AppLoader v-if="gtIsAppBusy" key="loader" simple />
          <slot v-else key="content" />
        </transition>
      </ContainerWrapper>
      <Feedback :data="feedback" />
    </main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import JbProductsButtonsMenu from '@/components/commomComponents/JbProductsButtonsMenu.vue'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import AppHeader from '@/components/appPartials/AppHeader.vue'
import AppLoader from '@/components/appPartials/AppLoader.vue'
import Feedback from '@/components/ui/Feedback.vue'
import JbAnchorAdsContainer from '@/containers/ads/JbAnchorAdsContainer.vue'
import JbOAuth from '@/helpers/oauth'
export default {
  name: 'LoggedLayout',
  components: {
    JbProductsButtonsMenu,
    ContainerWrapper,
    AppHeader,
    AppLoader,
    Feedback,
    JbAnchorAdsContainer,
  },
  data() {
    return {
      busy: true,
    }
  },
  computed: {
    ...mapGetters({
      gtUser: 'user/user/gtUser',
      personaData: 'user/persona/personaData',
      feedback: 'utils/feedback/feedback',
      gtAllProposedNoCollateralOffers:
        'products/loans/no-collateral/no-collateral/gtAllProposedNoCollateralOffers',
      gtAllProposedAutoOffers:
        'products/loans/auto-equity/auto-equity/gtAllProposedAutoOffers',
      gtAutoColdOffers:
        'products/loans/auto-equity/cold-offer/gtAutoColdOffers',
      gtAllOnGoingAutoOffers:
        'products/loans/auto-equity/auto-equity/gtAllOnGoingAutoOffers',
      gtAllOnGoingNoCollateralOffers:
        'products/loans/no-collateral/no-collateral/gtAllOnGoingNoCollateralOffers',
      gtAllOnGoingHomeEquityOffers:
        'products/loans/home-equity/home-equity/gtAllOnGoingHomeEquityOffers',
      gtHomeEquityColdOffers:
        'products/loans/home-equity/cold-offer/gtHomeEquityColdOffers',
      gtIsAppBusy: 'ui/app/gtIsAppBusy',
    }),
    cptdUserHasOngoingOrProposedOffers() {
      return (
        this.cptdProposedOffersList?.length > 0 ||
        this.cptdRequestedOffersList?.length > 0
      )
    },
    cptdProposedOffersList() {
      return [
        ...this.gtAllProposedNoCollateralOffers,
        ...this.gtAllProposedAutoOffers,
      ]
    },
    cptdRequestedOffersList() {
      return [
        ...this.gtAllOnGoingNoCollateralOffers,
        ...this.gtAllOnGoingAutoOffers,
        ...this.gtAllOnGoingHomeEquityOffers,
      ]
    },
    cptdColdOffersList() {
      return [...this.gtAutoColdOffers, ...this.gtHomeEquityColdOffers]
    },
    cptdUserHasColdOffers() {
      return this.cptdColdOffersList?.length > 0
    },
    cptdCurrentPage() {
      return this.$route?.name ?? ''
    },
    cptdDisplayProductsSubMenu() {
      return [
        'ofertas',
        'cartao-credito-ofertas',
        'seguros-ofertas',
        'negociacao-dividas',
      ].includes(this.cptdCurrentPage)
    },
    cptdRouteName() {
      const routesDict = {
        ofertas: 'Ofertas',
        conta: 'Conta',
        'cartao-credito-ofertas': 'Ofertas',
        'seguros-ofertas': 'Ofertas',
        'negociacao-dividas': 'Ofertas',
        'quase-la-id': 'Quase lá',
        empty: '',
      }

      return routesDict[this.cptdCurrentPage || 'empty']
    },
  },
  beforeMount() {
    if (!JbOAuth.isAccessTokenValid()) {
      JbOAuth.logout()
    }
  },
}
</script>
<style lang="scss">
.app {
  &.-logged {
    background: $color-white-high;
    @media (max-width: 768px) {
      padding-bottom: 64px;
    }

    & > .main {
      padding: 0 16px 32px 16px;
      @include media(lg) {
        min-height: calc(100vh - 470px);
      }
    }
  }
}
</style>
