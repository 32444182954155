<template>
  <span class="Badge" :class="[type]"><slot /></span>
</template>

<script>
export default {
  name: 'Badge',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'neutral',
      validator(value) {
        return ['info', 'negative', 'neutral', 'positive', 'warning'].includes(
          value,
        )
      },
    },
  },
}
</script>

<style lang="scss">
.Badge {
  @include caption;
  padding: 4px 8px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-black-high;
  border-radius: 4px;
  white-space: nowrap;

  &,
  * {
    box-sizing: border-box;
  }

  &.neutral {
    background-color: $color-black-lower;
  }

  &.info {
    background-color: $color-feedback-info-light;
  }

  &.negative {
    background-color: $color-feedback-negative-light;
  }

  &.positive {
    background-color: $color-feedback-positive-light;
  }

  &.warning {
    background-color: $color-feedback-warning-light;
  }
}
</style>
