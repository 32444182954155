<template>
  <LoggedLayout>
    <CreditCardOffersContainer />
  </LoggedLayout>
</template>
<script>
import { mapActions } from 'vuex'
import LoggedLayout from '@/layouts/LoggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const CreditCardOffersContainer = defineAsyncComponent(
  () => import('@/containers/credit-card/CreditCardOffersContainer.vue'),
)
export default {
  name: 'OffersCards',
  components: {
    CreditCardOffersContainer,
    LoggedLayout,
  },
  mounted() {
    this.actEmitInfobipPageview()
  },
  methods: {
    ...mapActions({
      actEmitInfobipPageview: 'services/events/actEmitInfobipPageview',
    }),
  },
}
</script>
