<template>
  <ClearLayout>
    <div class="JbPrimeRedirectPage">
      <ContainerWrapper size="xl">
        <NavBar :display-close-button="false" theme="white" @back="back" />
        <ContentContainerWrapper size="md">
          <h1 class="title">
            Tudo certo! Finalize a contratação em nossa página de pagamento
          </h1>
          <p class="description">
            Ainda com dúvidas? Fale com a gente! Estamos aqui para responder
            suas perguntas pelo WhatsApp.
          </p>
          <div class="actions">
            <Button :busy="busy" variation="inversed" @click="sendToPayment">
              Ir para pagamento
            </Button>
            <Button
              type="outlined"
              variation="inversed"
              :busy="busy"
              @click="sendToWhatsApp"
            >
              Tirar dúvidas
            </Button>
          </div>
          <p class="disclaimer">
            Ao clicar em “Ir para pagamento”, você será redirecionado(a) para
            nossa página de pagamento no Asaas.
          </p>
        </ContentContainerWrapper>
      </ContainerWrapper>
      <JbPrimeSurveyModal
        v-if="displaySurveyDialog"
        :display="displaySurveyDialog"
        @selected="handleSurveyReason"
      />
      <JbPrimeSurveyReasonsModal
        v-if="displaySurveyReasonsDialog && subscriptionSelected"
        :display="displaySurveyReasonsDialog"
        :reason="surveyReason"
        :subscription="subscriptionSelected"
        :user="gtUser"
        @submit="$router.back()"
      />
    </div>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import { generateWhatsAppLink } from '@/helpers/whatsapp'
import { formatDateToNumericDate } from '@/helpers/date'
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import Button from '@/aasgard/components/buttons/Button.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const JbPrimeSurveyModal = defineAsyncComponent(
  () => import('@/components/jb-prime/JbPrimeSurveyModal.vue'),
)
const JbPrimeSurveyReasonsModal = defineAsyncComponent(
  () => import('@/components/jb-prime/JbPrimeSurveyReasonsModal.vue'),
)
export default {
  name: 'JbPrimeRedirectPage',
  components: {
    JbPrimeSurveyReasonsModal,
    JbPrimeSurveyModal,
    ClearLayout,
    ContainerWrapper,
    NavBar,
    ContentContainerWrapper,
    Button,
  },
  data() {
    return {
      surveyReason: '',
      busy: false,
      displaySurveyDialog: false,
      displaySurveyReasonsDialog: false,
      subscriptionSelected: this.$route.query?.subscription ?? '',
    }
  },
  computed: {
    ...mapGetters({
      gtUser: 'user/user/gtUser',
    }),
  },
  methods: {
    ...mapActions({
      actEmitGaEvent: 'services/ga/actEmitGaEvent',
      actEmitInfobipButtonClickEvent:
        'services/events/actEmitInfobipButtonClickEvent',
      actInsertIntent: 'user/intent/actInsertIntent',
    }),
    back() {
      this.actEmitGaEvent({
        eventCategory: 'jb-prime-page',
        eventAction: `click-back-redirect-page`,
        eventLabel: `click-back-redirect-page`,
      })
      this.displaySurveyDialog = true
    },
    async trackRedirect() {
      try {
        return await axios.post(
          'https://hooks.zapier.com/hooks/catch/13541339/3mupnh6/',
          JSON.stringify({
            data: formatDateToNumericDate(new Date(), 'dd/mm/yyyy'),
            utm_campaign: sessionStorage.getItem('utm_campaign') || '',
            aff_id: sessionStorage.getItem('aff_id') || null,
            cpf: this.gtUser?.cpf,
            email: this.gtUser?.email,
            telefone: this.gtUser?.mobile_phone,
            user_id: this.gtUser?.id,
            plano: this.subscriptionSelected,
            produto: 'jb_prime',
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
      } catch (e) {
        return e?.response
      }
    },
    async emitEvents(destination, buttonText) {
      await Promise.all([
        this.actEmitGaEvent({
          eventCategory: 'jb-prime-page',
          eventAction: `redirected-to-${destination}`,
          eventLabel: `redirected-to-${destination}`,
        }),

        this.actInsertIntent({
          identifier: 'JB_PRIME_REDIRECT',
        }),

        this.actEmitInfobipButtonClickEvent({
          product: 'jbprime',
          button_text: buttonText,
        }),
        ...[destination === 'payment' && this.trackRedirect()],
      ])
    },
    async sendToWhatsApp() {
      this.busy = true
      await this.emitEvents('whatsapp', 'tirar_duvida')
      window.open(
        generateWhatsAppLink({
          phone: '554792617775',
          text: 'Olá! Quero contratar o JB Prime, você pode me auxiliar?',
        }),
        '_self',
      )
      this.busy = false
    },
    async sendToPayment() {
      this.busy = true
      await this.emitEvents('payment', 'ir_para_pagamento')
      window.open(this.$route?.query?.url, '_self')
      this.busy = false
    },
    handleSurveyReason(reason) {
      this.surveyReason = reason
      this.displaySurveyReasonsDialog = true
      this.displaySurveyDialog = false
    },
  },
}
</script>
<style lang="scss">
.JbPrimeRedirectPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-brand-primary;

  & > .ContainerWrapper {
    & > .ContentContainerWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .title {
        @include headline-5;
        color: $color-white-high;
        text-align: center;
        margin: 0 0 16px 0;
      }
      & > .description {
        @include body-1;
        color: $color-white-medium;
        text-align: center;
        margin: 0 0 32px 0;
      }
      & > .actions {
        width: 100%;
        max-width: 328px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 0 0 32px 0;
      }
      & > .disclaimer {
        @include body-2;
        color: $color-white-medium;
        text-align: center;
        margin: 0;
      }
    }
  }
}
</style>
