<template>
  <header v-if="isMounted" :class="['app--header', { '-logged': logged }]">
    <!-- topo com logo e menu desktop -->
    <div class="top">
      <div class="content">
        <button
          v-if="logged && cptdDisplayNavMenu && !isDesktop"
          class="mobile-menu"
          @click="displaySideBar = true"
        >
          <MenuIcon />
        </button>
        <div class="logo">
          <JBLogo />
        </div>
        <NavMenu
          v-if="cptdDisplayNavMenu"
          :requested-offers-length="requestedOffersLength"
          @logout="signOut"
        />
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="displaySideBar" key="mobile-menu" class="mobile-side-menu">
        <div class="top">
          <div class="info">
            <h1>
              {{
                cptdUserName.split(' ')[0] + ' ' + cptdUserName.split(' ')[1]
              }}
            </h1>
            <p>
              {{ (user || {}).email }}
            </p>
          </div>
          <CloseIcon class="close" @click="displaySideBar = false" />
        </div>

        <div class="pages">
          <div
            class="page"
            :class="cssActive(['perfil-dados-perfil'])"
            @click="redirectTo('/perfil/dados-perfil')"
          >
            Dados do perfil
          </div>
          <div
            class="page"
            :class="cssActive(['perfil-contas-conectadas'])"
            @click="redirectTo('/perfil/contas-conectadas')"
          >
            Contas conectadas
          </div>
          <div
            class="page"
            :class="cssActive(['home'])"
            @click="redirectTo('/home')"
          >
            Início
          </div>
          <div
            class="page"
            :class="cssActive(['simular'])"
            @click="redirectTo('/simular')"
          >
            Simular
          </div>
          <div
            class="page"
            :class="
              cssActive([
                'ofertas',
                'ofertas-fgts',
                'cartao-credito-ofertas',
                'negociacao-dividas',
                'seguros-ofertas',
              ])
            "
            @click="redirectTo('/ofertas')"
          >
            Ofertas
          </div>
          <p v-if="user" class="page" @click="displayChatUs = true">
            Falar com a gente
          </p>
          <ContactUsDialog
            v-if="user"
            :user="user"
            :display="displayChatUs"
            @close="displayChatUs = false"
          />
          <p
            v-if="user"
            class="page delete-account"
            @click="displayDeleteAccount = true"
          >
            Excluir minha conta
          </p>
          <DeleteAccountDialog
            v-if="user"
            :user="user"
            :display="displayDeleteAccount"
            @close="displayDeleteAccount = false"
            @success="signOut"
          />
          <p class="page" @click="signOut">Sair</p>
        </div>
      </div>
    </transition>
  </header>
</template>
<script>
import { mapActions } from 'vuex'
import JBLogo from './AppLogo.vue'
import NavMenu from '@/components/appPartials/NavMenu.vue'
import { defineAsyncComponent } from 'vue'
const DeleteAccountDialog = defineAsyncComponent(
  () =>
    import('@/components/commomComponents/feedback/DeleteAccountDialog.vue'),
)
const ContactUsDialog = defineAsyncComponent(
  () => import('@/components/commomComponents/feedback/ContactUsDialog.vue'),
)
const MenuIcon = defineAsyncComponent(
  () => import('@/assets/images/icons/menu.svg?component'),
)
const CloseIcon = defineAsyncComponent(
  () => import('@/assets/images/icons/close.svg?component'),
)
export default {
  name: 'AppHeader',
  components: {
    DeleteAccountDialog,
    ContactUsDialog,
    JBLogo,
    NavMenu,
    MenuIcon,
    CloseIcon,
  },
  props: {
    logged: { type: Boolean, default: false },
    persona: { type: Object, default: () => ({}) },
    user: { type: Object, default: () => ({}) },
    requestedOffersLength: { type: Number, default: 0 },
  },
  data() {
    return {
      isMounted: false,
      displaySideBar: false,
      displayChatUs: false,
      displayDeleteAccount: false,
    }
  },
  computed: {
    cptdCurrentPage() {
      return this.$route?.name ?? ''
    },
    cptdDisplayNavMenu() {
      return (
        this.logged &&
        !this.isCurrentPage('simulando-emprestimo') &&
        !this.isCurrentPage('simulando')
      )
    },
    cptdUserName() {
      return this.user?.name || ''
    },
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    ...mapActions({
      actOAuthLogout: 'oauth/actOAuthLogout',
      actEmitGaEvent: 'services/ga/actEmitGaEvent',
    }),
    cssActive(pages) {
      return {
        '-active': pages.some((page) => this.isCurrentPage(page)),
      }
    },
    isCurrentPage(page) {
      return this.cptdCurrentPage === page
    },
    redirectTo(path) {
      this.closeMenu()
      this.$router.push({ path })
    },
    closeMenu() {
      this.displaySideBar = false
    },
    signOut() {
      this.actOAuthLogout()
    },
  },
}
</script>
<style lang="scss">
.app--header {
  // top
  & > .top {
    & > .content {
      box-sizing: border-box;
      position: relative;
      background: $color-white-high;
      box-shadow: 0 2px 5px rgba(215, 215, 242, 0.5);
      padding: 16px 16px 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 56px;
      max-height: 56px;
      width: 100%;

      @include media('md') {
        min-height: 64px;
        max-height: 64px;
      }

      & > .mobile-menu {
        background-color: transparent;
        border: none;

        @include media(md) {
          display: none;
        }

        & > svg {
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
        }

        &:active,
        &:focus {
          outline: none;
        }
      }

      & > .logo {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include media('md') {
          position: relative;
          left: unset;
          transform: unset;
        }

        & > .app--logo {
          height: 16px;

          @include media('md') {
            height: 24px;
          }

          & > svg {
            height: 16px;
            @include media('md') {
              height: 24px;
            }
          }
        }
      }
    }
  }

  // route
  & > .route-name {
    background-color: $color-brand-primary;
    padding: 0 16px;
    & > .content {
      padding: 8px 0 16px 0;

      @include media('md') {
        max-width: 1280px;
        padding: 16px 0 24px 0;
        margin: 0 auto;
      }

      @include media('lg') {
        max-width: 1280px;
      }

      & > h1 {
        @include headline-5;
        color: $color-white-high;
        margin: 0;
      }

      & > .name {
        @include subtitle-1;
        color: $color-white-high;
        margin-bottom: 4px;
        margin-top: 16px;
      }

      & > .email {
        @include body-2;
        color: $color-white-high;
        margin: 0;
      }
    }
  }

  & > .submenu {
    background-color: #fbfcfe;
    border: 1px solid $color-neutral-dark;
    padding: 0 16px 16px;

    @include media('md') {
      background: rgba(246, 247, 252, 0.4);
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 16px;
      height: 80px;
      display: flex;
      align-items: center;
    }
    & > .content {
      padding: 24px 0 0;
      width: 100%;
      @include desktop {
        max-width: 1280px;
        padding: 0;
        margin: 0 auto;
      }

      @include media('lg') {
        max-width: 1280px;
      }

      & > .sub-nav {
        width: calc(100% + 24px);
        overflow: auto;
        white-space: nowrap;
        padding-bottom: 8px;

        @include media('md') {
          width: 100%;
          padding-bottom: 0;
        }

        & > .Button {
          margin-right: 8px;
        }
      }
    }
  }

  & > .mobile-side-menu {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    background: $color-white-high;
    position: fixed;
    z-index: 90 !important;
    top: 0;

    & > .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .info {
        min-width: 288px;
        max-width: calc(100vw - 72px);
        width: min-content;
        padding: 24px;
        border-radius: 0 0 24px 0;
        background: $color-brand-primary;

        & > h1,
        & > p {
          @include body-1;
          color: $color-white-high;
        }

        & > h1 {
          font-weight: 600;
          margin: 0 0 4px 0;
        }

        & > p {
          font-weight: 400;
          margin: 0;
        }
      }

      & > .close {
        margin-right: 24px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    & > .pages {
      padding: 12px 24px;
      display: flex;
      flex-direction: column;

      & > .page {
        @include body-1;
        margin: 0;
        cursor: pointer;
        padding: 12px 0;
        color: $color-black-medium;

        &.-active,
        &:hover {
          color: $color-black-high;
        }

        &.delete-account {
          color: $color-feedback-negative;
        }
      }
    }
  }

  // logged variation
  &.-logged {
    & > .top {
      background-color: $color-brand-primary;
      padding: 0 16px;
      min-height: 56px;
      max-height: 56px;

      @include media('md') {
        min-height: 64px;
        max-height: 64px;
      }

      & > .content {
        padding: 0;
        min-height: 56px;
        max-height: 56px;
        @include media('md') {
          min-height: 64px;
          max-height: 64px;
        }

        @include desktop {
          max-width: 1280px;
          margin: 0 auto;
        }

        @include media(lg) {
          max-width: 1280px;
        }
        box-shadow: none;
        background-color: $color-brand-primary;

        & > .logo {
          & > .app--logo {
            & > svg {
              path {
                fill: $color-white-high;
              }
            }
          }
        }
      }
    }
  }
}

.page-wrapper {
  padding-top: 0 !important;
}

.page-wrapper {
  padding-top: 0 !important;
}

.page-wrapper {
  padding-top: 0 !important;
}

// animation
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-active
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-200px);
  opacity: 0;
}

.slide-fade-enter-from
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-200px);
  opacity: 0;
}
</style>
