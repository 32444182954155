// STATE
const state = (): any => ({
  busy: false,
})

// GETTERS
const getters = {
  gtIsAppBusy(state) {
    return state.busy
  },
}

// MUTATIONS
const mutations = {
  SET_BUSY(state, payload) {
    state.busy = payload
  },
}

// ACTIONS
const actions = {
  actSetBusy({ commit }, busy) {
    commit('SET_BUSY', busy)
  },
}

// export
export default { namespaced: true, state, getters, actions, mutations }
