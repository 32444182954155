<template>
  <LoggedLayout>
    <DebtOffersContainer />
  </LoggedLayout>
</template>
<script>
import { mapActions } from 'vuex'
import LoggedLayout from '@/layouts/LoggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const DebtOffersContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/logged/products/debtRenegotiation/DebtOffersContainer.vue'
    ),
)

export default {
  name: 'DebtOfferPage',
  components: {
    LoggedLayout,
    DebtOffersContainer,
  },
  mounted() {
    this.actEmitInfobipPageview()
  },
  methods: {
    ...mapActions({
      actEmitInfobipPageview: 'services/events/actEmitInfobipPageview',
    }),
  },
}
</script>
