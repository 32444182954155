export const isDateObject = (date: Date): boolean =>
  date && typeof date === 'object' && typeof date.getTime === 'function'
export const generateYearListFrom = (year: number) => {
  const arr: any[] = []
  for (let i = year; i <= new Date().getFullYear(); i++) {
    arr.push(i)
  }
  return arr.sort((curr, next) => next - curr) || []
}

export const isDateGreaterThan = (date: Date, compareDate: Date) => {
  if (!isDateObject(date) || !isDateObject(compareDate))
    throw new Error('Invalid date')
  return date.getTime() > compareDate.getTime()
}
export const isDateLowerThan = (date: Date, compareDate: Date) => {
  return !isDateGreaterThan(date, compareDate)
}
export const checkAge = (min, max, birthDate) => {
  if (!birthDate) return false

  const today = new Date()
  const birth = new Date(birthDate)
  const age = today?.getFullYear() - birth?.getFullYear()

  return age >= min && age <= max
}

export function formatDateToNumericDate(date, format) {
  if (!date || typeof date !== 'object' || typeof date.getMonth !== 'function')
    throw new Error('Invalid date')

  const [year, month, day, hours, minutes, seconds] = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ].map((x) => x.toString().padStart(2, '0'))

  switch (format) {
    case 'yyyy-mm-dd':
      return `${year}-${month}-${day}`
    case 'dd-mm-yyyy':
      return `${day}-${month}-${year}`
    case 'yyyy/mm/dd':
      return `${year}/${month}/${day}`
    case 'dd/mm/yyyy':
      return `${day}/${month}/${year}`
    case 'dd/mm/yyyy hh:mm:ss':
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
    case 'dd/mm/yyyy as hh:mm':
      return `${day}/${month}/${year} às ${hours}:${minutes}`
    default:
      return `${year}-${month}-${day}`
  }
}

export function utcDate(date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}

export function localDate(datetime) {
  const date = new Date(datetime)
  const newDate = utcDate(date)
  const offset = date.getTimezoneOffset() / 60

  newDate.setHours(date.getHours() - offset)
  return newDate
}
