<template>
  <UnloggedLayout>
    <FormContainer>
      <EmpSimAccessCodeForm
        :mobile-phone="mobilePhone"
        @sendCode="sendCode"
        @submit="submit"
      />
    </FormContainer>
  </UnloggedLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const FormContainer = defineAsyncComponent(
  () => import('@/containers/forms/FormContainer.vue'),
)
const EmpSimAccessCodeForm = defineAsyncComponent(
  () =>
    import(
      '@/components/forms/loans/auto/partners/emprestimo_sim/EmpSimAccessCodeForm.vue'
    ),
)
export default {
  name: 'EmprestimoSimAutoSignContractPage',
  components: {
    UnloggedLayout,
    EmpSimAccessCodeForm,
    FormContainer,
  },
  data() {
    return {
      formId: 'EMPRESTIMO_SIM',
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      gtAutoOfferSelected:
        'products/loans/auto-equity/auto-equity/gtAutoOfferSelected',
    }),
    mobilePhone() {
      return this.gtUser?.mobile_phone ?? ''
    },
  },
  async created() {
    this.actSetupForm(this.formId)
    await this.sendCode()
  },
  methods: {
    ...mapActions({
      actSetupForm: 'dynamic-forms/actSetupForm',
      actUpdateForm: 'dynamic-forms/actUpdateForm',
      actGetAutoOfferContractToken:
        'products/loans/auto-equity/auto-equity/actGetAutoOfferContractToken',
    }),
    async sendCode() {
      const { offer_id } = this.$route?.params
      await this.actGetAutoOfferContractToken(offer_id)
    },
    submit(data) {
      const { offer_id } = this.$route?.params
      this.actUpdateForm({ formId: this.formId, key: 'code', value: data })
      this.$router.push({
        path: `/forms/emprestimo/auto/parceiros/emprestimo_sim/contrato/${offer_id}/selfie`,
      })
    },
  },
}
</script>
<style lang="scss"></style>
