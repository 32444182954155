import autoEquity from '@/store/modules/products/loans/auto-equity/auto-equity'
import car from '@/store/modules/products/loans/auto-equity/car'
import coldOffer from '@/store/modules/products/loans/auto-equity/cold-offer'
const state = () => ({})
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    'auto-equity': autoEquity,
    car,
    'cold-offer': coldOffer,
  },
}
