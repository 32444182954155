<template>
  <SimpleLayout>
    <SelectProductForegonContainer />
  </SimpleLayout>
</template>
<script>
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import { defineAsyncComponent } from 'vue'
const SelectProductForegonContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/partnerships/foregon/SelectProductForegonContainer.vue'
    ),
)
export default {
  name: 'SelectProductForegonPage',
  components: {
    SimpleLayout,
    SelectProductForegonContainer,
  },
}
</script>
<style lang="scss">
.app {
  & > .ContainerWrapper {
    width: 100%;

    @include media('xl') {
      max-width: 100%;
    }
  }
}
</style>
