<template>
  <div class="OfferSimulationFeedbackDialogContainer">
    <OfferSimulationFeedbackDialog
      v-bind="$attrs"
      v-on="{
        'jb-prime-service-attendence': onPrimeServiceAttendence,
      }"
    />
    <JbPrimeServiceAttendantDialogContainer
      v-if="displayJbPrimeServiceAttendence"
      :display="displayJbPrimeServiceAttendence"
      @close="displayJbPrimeServiceAttendence = false"
    />
    <JbPrimeNoResultSubscribeModal
      v-if="displayJbPrimeSubscribeModal"
      :display="displayJbPrimeSubscribeModal"
      @not-interested="displayJbPrimeSubscribeModal = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import OfferSimulationFeedbackDialog from '@/components/commomComponents/feedback/OfferSimulationFeedbackDialog.vue'
import { removeQueryString } from '@/helpers/url'
import { checkAge } from '@/helpers/date'
import { generateWhatsAppLink } from '@/helpers/whatsapp'
import { defineAsyncComponent } from 'vue'
const JbPrimeServiceAttendantDialogContainer = defineAsyncComponent(
  () =>
    import('@/containers/jb-prime/JbPrimeServiceAttendantDialogContainer.vue'),
)
const JbPrimeNoResultSubscribeModal = defineAsyncComponent(
  () => import('@/components/jb-prime/JbPrimeNoResultSubscribeModal.vue'),
)
export default {
  name: 'OfferSimulationFeedbackDialogContainer',
  components: {
    JbPrimeNoResultSubscribeModal,
    JbPrimeServiceAttendantDialogContainer,
    OfferSimulationFeedbackDialog,
  },
  data() {
    return {
      displayJbPrimeServiceAttendence: false,
      displayJbPrimeSubscribeModal: false,
    }
  },
  computed: {
    ...mapGetters({
      personaData: 'user/persona/personaData',
      gtCreditScoreLatest:
        'products/credit-score/credit-score/gtCreditScoreLatest',
      gtUserHasJbPrimeSubscription:
        'user/subscriptions/gtUserHasJbPrimeSubscription',
    }),
  },
  mounted() {
    if (this.$route.query?.last_product_simulation) {
      history.replaceState(
        history.state,
        document.title,
        removeQueryString(location.href, 'last_product_simulation'),
      )
    }
  },
  methods: {
    ...mapActions({
      actEmitGaEvent: 'services/ga/actEmitGaEvent',
    }),
    onPrimeServiceAttendence() {
      if (this.gtUserHasJbPrimeSubscription) {
        this.displayJbPrimeServiceAttendence = true
        this.actEmitGaEvent({
          eventCategory:
            'offer-simulation-no-results-jb-prime-service-attendant-button',
          eventAction: 'click',
          eventLabel: location.pathname,
        })
      } else if (
        this.personaData?.info?.birth_date &&
        this.gtCreditScoreLatest?.data?.score &&
        this.personaData?.finance?.monthly_income &&
        checkAge(
          Number(import.meta.env.VITE_JB_PRIME_MIN_AGE_SERVICE_ATTENDANT),
          80,
          this.personaData?.info?.birth_date,
        ) &&
        this.gtCreditScoreLatest?.data?.score >=
          Number(import.meta.env.VITE_JB_PRIME_MIN_SCORE_SERVICE_ATTENDANT) &&
        this.personaData?.finance?.monthly_income >=
          Number(
            import.meta.env.VITE_JB_PRIME_MIN_MONTHLY_INCOME_SERVICE_ATTENDANT,
          )
      ) {
        window.open(
          generateWhatsAppLink({
            text: `Estou precisando de ajuda com minha simulação de empréstimo. Pode me ajudar?`,
          }),
          '_self',
        )
      } else {
        this.displayJbPrimeSubscribeModal = true
      }
    },
  },
}
</script>
<style lang="scss"></style>
