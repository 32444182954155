<template>
  <div class="text-input">
    <label
      v-if="label"
      :for="name"
      :class="[{ '-disabled': disabled, '-optional': cptdIsOptional }]"
    >
      {{ label }}
      <span v-if="!presentation && cptdIsOptional">Opcional</span>
      <label v-if="!presentation && !cptdIsOptional" class="required">
        &nbsp;*
      </label>
    </label>
    <div class="input-wrapper">
      <money3
        v-if="money"
        :id="name"
        v-model.number="value"
        autocomplete="off"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="cptdClass"
        v-bind="money3Config"
        @input="change($event.target.value)"
        @blur="blur($event.target.value)"
      />
      <input
        v-else
        :id="name"
        v-model="value"
        v-maska
        :data-maska="mask"
        data-maska-eager
        autocomplete="off"
        v-bind="$attrs"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="cptdClass"
        @input="change($event.target.value)"
        @blur="blur($event.target.value)"
        @keydown.enter="blur($event.target.value)"
      />
      <span v-if="cptdIsValid === true" class="validation-icon">
        <OutlineSuccessIcon name="outline-success" />
      </span>
      <span v-if="cptdIsInvalid === true" class="validation-icon">
        <OutlineIcon name="outline-error" />
      </span>
    </div>
    <div class="complement">
      <div class="validation">
        <span v-if="cptdIsInternalValid === false" class="error-message">
          {{ errorMessage }}
        </span>
        <span v-else-if="cptdIsExternalValid === false" class="error-message">
          <slot name="external-message">{{ externalMessage }}</slot>
        </span>
      </div>

      <div class="helper">
        <span v-if="helperMessage" class="message">
          {{ helperMessage }}
        </span>

        <span v-if="helperSize" class="size">
          {{ (value || '').replace(/\D/g, '').length }}/{{ helperSize }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
import OutlineSuccessIcon from '~/assets/images/icons/outline-success.svg'
import OutlineIcon from '~/assets/images/icons/outline-error.svg'
import { vMaska } from 'maska'
import { Money3Component } from 'v-money3'

export default {
  name: 'TextInput',
  directives: {
    maska: vMaska,
  },
  components: { OutlineIcon, OutlineSuccessIcon, money3: Money3Component },
  props: {
    disabled: { type: Boolean, default: false },
    externalValidation: { type: Boolean, default: false },
    externalMessage: { type: String, default: '' },
    helperMessage: { type: String, default: '' },
    helperSize: { type: [Number, String], default: undefined },
    label: { type: String, default: '' },
    name: { type: String, required: true },
    placeholder: { type: String, default: '' },
    presentation: { type: Boolean, default: false },
    onlyNumbers: { type: Boolean, default: false },
    mask: { type: String, default: undefined },
    money: { type: Boolean, default: false },
    modelValue: { type: [String, Number], default: '' },
    validation: { type: Boolean, default: false },
    validations: { type: [Object, String], default: '' },
  },
  emits: ['input', 'blur', 'update:modelValue'],
  setup(props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'name')
    const rules = toRef(props, 'validations')

    const { errorMessage, setTouched, handleChange, meta, value, resetField } =
      useField(name, rules, {
        syncVModel: true,
      })

    return {
      handleChange,
      setTouched,
      errorMessage,
      meta,
      value,
      resetField,
    }
  },
  data() {
    return {
      money3Config: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 0,
        disableNegative: true,
        disabled: this.disabled,
        min: null,
        max: null,
        allowBlank: true,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
    }
  },
  computed: {
    cptdIsTouchedOrInValidation() {
      return this.meta.touched || this.validation
    },
    cptdIsExternalValid() {
      if (!this.cptdIsTouchedOrInValidation) return null
      return !this.externalValidation
    },
    cptdIsInternalValid() {
      if (!this.cptdIsTouchedOrInValidation) return null
      return this.meta.valid
    },
    cptdIsValid() {
      if (!this.cptdIsTouchedOrInValidation) return null
      return (
        !!this.value && this.cptdIsInternalValid && this.cptdIsExternalValid
      )
    },
    cptdIsInvalid() {
      if (!this.cptdIsTouchedOrInValidation) return null
      return !this.cptdIsValid
    },
    cptdClass() {
      return {
        'input-field': true,
        '-invalid': this.cptdIsInvalid === true,
        '-valid': this.cptdIsValid === true,
        '-disabled': this.disabled,
      }
    },
    cptdIsOptional() {
      return !this.validations || this.validations?.required === false
    },
  },
  mounted() {
    if (this.value) {
      this.setTouched(true)
    }
  },
  methods: {
    change(value) {
      let val = value
      if (this.money || this.onlyNumbers) {
        val = String(value).replace(/\D/g, '')
      }
      this.handleChange(val)
      this.$emit('input', val)
    },
    blur(value) {
      let val = String(value)
      val = val.trim()
      this.change(val)
      this.setTouched(true)
      this.$emit('blur', this.value)
    },
  },
}
</script>

<style lang="scss">
.text-input {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding-bottom: 8px;

  & > label {
    @include subtitle-1;
    color: $color-black-high;
    margin-bottom: 11px;

    &.-optional {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > span {
        @include body-2;
        color: $color-black-medium;
      }
    }

    &.-disabled {
      color: $color-brand-tertiary;
    }

    & > .required {
      color: $color-feedback-negative;
    }
  }

  & > .input-wrapper {
    position: relative;
    .input-field {
      @include body-1;
      width: 100%;
      height: 48px;
      border: 1.5px solid $color-neutral-dark;
      background: $color-white-high;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 14px 48px 14px 16px;
      color: $color-brand-primary-dark;
      transition: border 400ms ease;
      outline: none;

      &::placeholder {
        color: $color-brand-tertiary;
      }

      &:focus {
        border-color: $color-brand-primary;
      }

      &.-valid {
        border-color: $color-feedback-positive;
      }
      &.-invalid {
        border-color: $color-feedback-negative;
      }
      &.-disabled {
        background: $color-neutral-light;
        cursor: not-allowed;
        pointer-events: none;
        color: $color-black-low;
      }
    }

    & > .validation-icon {
      background: transparent;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 12px;
      right: 16px;
      cursor: pointer;
      pointer-events: none;

      &:hover {
        cursor: pointer;
      }
    }

    & > .clear-field-icon {
      background: transparent;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 12px;
      right: 16px;
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }
    }
  }

  & > .complement {
    display: flex;
    flex-direction: column;
    &:empty {
      display: none;
    }
    & > .helper {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .message,
      .size {
        @include caption;
        color: $color-black-medium;
      }
    }
    & > .validation {
      display: flex;
      flex-direction: column;
      &:empty {
        display: none;
        margin: 0;
      }

      & > .error-message {
        margin-top: 8px;
        @include caption;
        color: $color-feedback-negative;
      }
    }
  }
}
</style>
