<template>
  <ClearLayout>
    <ProofOfIncomeContainer />
  </ClearLayout>
</template>
<script>
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProofOfIncomeContainer = defineAsyncComponent(
  () => import('@/containers/newAppDocs/ProofOfIncomeContainer.vue'),
)
export default {
  name: 'ProofOfIncome',
  components: {
    ClearLayout,
    ProofOfIncomeContainer,
  },
}
</script>
<style lang="scss"></style>
