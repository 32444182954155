import * as Sentry from '@sentry/browser'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://450cc2fd87a74eedabc3646e36284009@o770096.ingest.sentry.io/5795481',
    environment: 'production',

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    // release: 'my-project-name@2.3.12',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      String(import.meta.env.VITE_BASE_URL),
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
