<template>
  <UnloggedLayout>
    <HomeEquityColdOfferFormContainer
      @submit="$router.push('/simulando/emprestimo?product=home-equity')"
    />
  </UnloggedLayout>
</template>
<script>
import UnloggedLayout from '@/layouts/UnloggedLayout.vue'
import { defineAsyncComponent } from 'vue'
const HomeEquityColdOfferFormContainer = defineAsyncComponent(
  () =>
    import(
      '@/containers/forms/loans/home-equity/cold-offer/HomeEquityColdOfferFormContainer.vue'
    ),
)
export default {
  name: 'SimularHomeEquityColdOffer',
  components: {
    UnloggedLayout,
    HomeEquityColdOfferFormContainer,
  },
}
</script>
<style lang="scss"></style>
