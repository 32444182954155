<template>
  <ClearLayout>
    <ContainerWrapper v-if="currentFormData" class="LoanDataPage">
      <NavBar
        :progress="{ step: 2, steps: 4, type: 'stepped' }"
        @back="$router.back()"
        @close="displayProgressWillBeLost = true"
      />
      <ProgressWillBeLostModal
        v-if="displayProgressWillBeLost"
        :display="displayProgressWillBeLost"
        @close="displayProgressWillBeLost = false"
        @exit="$router.push('/home')"
      />
      <ContentContainerWrapper>
        <InfocarAutoFormContainer
          v-if="isMounted"
          :data="cptdCurrentForm['auto'] || {}"
          @submit="submit"
        />
      </ContentContainerWrapper>
    </ContainerWrapper>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ContentContainerWrapper from '@/components/ui/newComponents/ContentContainerWrapper.vue'
import NavBar from '@/aasgard/components/navigation/NavBar.vue'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const ProgressWillBeLostModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/feedback/ProgressWillBeLostModal.vue'
    ),
)
const ContainerWrapper = defineAsyncComponent(
  () => import('@/components/ui/newComponents/ContainerWrapper.vue'),
)
const InfocarAutoFormContainer = defineAsyncComponent(
  () => import('@/containers/forms/persona/InfocarAutoFormContainer.vue'),
)
export default {
  name: 'DynamicFormPage',
  components: {
    ClearLayout,
    ProgressWillBeLostModal,
    ContentContainerWrapper,
    NavBar,
    ContainerWrapper,
    InfocarAutoFormContainer,
  },
  data() {
    return {
      isMounted: false,
      displayProgressWillBeLost: false,
      formId: 'GENERIC_AUTO_EQUITY',
      currentFormData: null,
    }
  },
  computed: {
    ...mapGetters({
      gtDynamicForms: 'dynamic-forms/gtDynamicForms',
      gtUser: 'user/user/gtUser',
      gtAutoColdOffers:
        'products/loans/auto-equity/cold-offer/gtAutoColdOffers',
      gtAutoEquityLatestColdOfferRequest:
        'products/loans/auto-equity/cold-offer/gtAutoEquityLatestColdOfferRequest',
    }),
    cptdCurrentForm() {
      return this.gtDynamicForms[this.formId] || {}
    },
  },
  async created() {
    if (
      !this.gtAutoColdOffers.length ||
      !this.gtUser ||
      !this.cptdCurrentForm
    ) {
      this.$router.push({
        path: '/ofertas',
      })
    }
    if (!this.cptdCurrentForm?.auto) {
      this.currentFormData = {
        auto: {
          ...(this.gtAutoEquityLatestColdOfferRequest
            ? {
                plate: this.gtAutoEquityLatestColdOfferRequest?.vehicle?.plate,
              }
            : {}),
        },
      }
      await this.actUpdateForm({
        formId: this.formId,
        value: this.currentFormData,
      })
    } else {
      this.currentFormData = this.cptdCurrentForm
    }
    this.isMounted = true
  },
  methods: {
    ...mapActions({
      actUpdateForm: 'dynamic-forms/actUpdateForm',
    }),
    submit(data) {
      this.actUpdateForm({ formId: this.formId, value: data })
      this.$router.push({
        path: '/forms/emprestimo/auto/parceiros/simular/dados-ocupacao',
      })
    },
  },
}
</script>
<style lang="scss"></style>
