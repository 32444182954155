<template>
  <ContainerWrapper v-bind="$attrs" class="ContentContainerWrapper">
    <slot />
  </ContainerWrapper>
</template>

<script>
import ContainerWrapper from '@/components/ui/newComponents/ContainerWrapper.vue'
export default {
  name: 'ContentContainerWrapper',
  components: {
    ContainerWrapper,
  },
}
</script>

<style lang="scss">
.ContentContainerWrapper {
  &.ContainerWrapper {
    padding: 16px;
  }
}
</style>
