export enum OfferAssistantReasonsEnum {
  BUY = 'BUY',
  BILLS = 'BILLS',
  PAY_ANOTHER_LOAN = 'PAY_ANOTHER_LOAN',
  MEDICAL_EXPENSES = 'MEDICAL_EXPENSES',
  HOME_IMPROVEMENT = 'HOME_IMPROVEMENT',
  FIX_CAR = 'FIX_CAR',
  HELP_FAMILY = 'HELP_FAMILY',
  VACATION = 'VACATION',
  EDUCATION_EXPENSES = 'EDUCATION_EXPENSES',
  INVESTMENT = 'INVESTMENT',
  MARRIAGE = 'MARRIAGE',
  OTHER_REASON = 'OTHER_REASON',
}

export enum OfferAssistantAmountRangesEnum {
  LOWER_THAN_500 = 'LOWER_THAN_500',
  FROM_500_TO_5000 = 'FROM_500_TO_5000',
  FROM_5001_TO_10000 = 'FROM_5001_TO_10000',
  FROM_10001_TO_20000 = 'FROM_10001_TO_20000',
  FROM_20001_TO_30000 = 'FROM_20001_TO_30000',
  FROM_30001_TO_40000 = 'FROM_30001_TO_40000',
  FROM_40001_TO_50000 = 'FROM_40001_TO_50000',
  FROM_50001_TO_100000 = 'FROM_50001_TO_100000',
  GREATER_THAN_100000 = 'GREATER_THAN_100000',
}

export enum OfferAssistantPaymentDelayEnum {
  ONE_DAY = 'ONE_DAY',
  ONE_WEEK = 'ONE_WEEK',
  FROM_TWO_TO_THREE_WEEKS = 'FROM_TWO_TO_THREE_WEEKS',
  MORE_THAN_FOUR_WEEKS = 'MORE_THAN_FOUR_WEEKS',
}

export enum OfferAssistantBanks {
  BANCO_BRASIL = 'BANCO_BRASIL',
  BRADESCO = 'BRADESCO',
  CAIXA_ECONOMICA = 'CAIXA_ECONOMICA',
  ITAU = 'ITAU',
  SANTANDER = 'SANTANDER',
  OTHERS = 'OTHERS',
}

export enum OfferAssistantHasFgtsBalanceEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum OfferAssistantCollateralTypesEnum {
  CAR = 'CAR',
  PROPERTY = 'PROPERTY',
  MOBILE_PHONE = 'MOBILE_PHONE',
}
