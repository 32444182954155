<template>
  <ClearLayout>
    <OfferPartnerRedirectModal
      :partner="cptdPartner"
      :url="cptdOfferUrl"
      :redirect-seconds="12"
      @redirected="emitEvents"
    >
      <template #title>
        {{ cptdTitleRedirect }}
      </template>
      <template #alert-message>
        {{ cptdRedirectMessage }}
      </template>
    </OfferPartnerRedirectModal>
  </ClearLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { generateWhatsAppLink } from '@/helpers/whatsapp'
import { PartnersName } from '@/helpers/partners/common'
import { Partner } from '@/data/common'
import ClearLayout from '@/layouts/ClearLayout.vue'
import { defineAsyncComponent } from 'vue'
const OfferPartnerRedirectModal = defineAsyncComponent(
  () =>
    import(
      '@/components/commomComponents/modals/OfferPartnerRedirectModal.vue'
    ),
)
export default {
  name: 'RedirectPage',
  components: {
    ClearLayout,
    OfferPartnerRedirectModal,
  },
  computed: {
    ...mapGetters({
      gtUser: 'user/user/gtUser',
    }),
    cptdPartner() {
      const { partner } = this.$route?.params
      return partner?.toUpperCase() ?? ''
    },
    cptdPartnerName() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdOfferUrl() {
      switch (this.cptdPartner) {
        case Partner.BV:
          return generateWhatsAppLink({
            text: `Olá, poderia informar o valor eu posso contratar do Saque Aniversário? Meu número de identificação é: ${this.gtUser?.id}`,
          })
        case Partner.UPP:
          return 'https://upp.com.br/?utm_source=jurosbaixos&utm_medium=site&utm_campaign=card_jurosbaixos=id_v1_fgts_v1'
        default:
          return generateWhatsAppLink({
            text: `Olá, poderia informar o valor eu posso contratar do Saque Aniversário? Meu número de identificação é: ${this.gtUser?.id}`,
          })
      }
    },
    cptdPartnerNameFormatted() {
      return PartnersName[this.cptdPartner ?? ''] ?? ''
    },
    cptdTitleRedirect() {
      switch (this.cptdPartner) {
        case Partner.BV:
          return `Muito bem! Inicie uma conversa em nosso whastapp para consultar o valor`
        case Partner.UPP:
          return `Tudo certo! Finalize a contratação da sua oferta com a ${this.cptdPartnerName}`
        default:
          return `Muito bem! Inicie uma conversa em nosso whastapp para consultar o valor`
      }
    },
    cptdRedirectMessage() {
      switch (this.cptdPartner) {
        case Partner.BV:
          return `Este serviço é uma parceria com a ${this.cptdPartnerNameFormatted} e o processo continuará com nosso time de atendimento no WhatsApp.`
        default:
          return `Este serviço é uma parceria com a ${this.cptdPartnerNameFormatted} e o processo continuará em seu site.`
      }
    },
  },
  mounted() {
    this.actEmitInfobipAffiliateOfferSelected({
      affiliate: this.cptdPartner,
    })
  },
  methods: {
    ...mapActions({
      actEmitGaEvent: 'services/ga/actEmitGaEvent',
      actEmitInfobipButtonClickEvent:
        'services/events/actEmitInfobipButtonClickEvent',
      actInsertIntent: 'user/intent/actInsertIntent',
      actEmitInfobipAffiliateOfferSelected:
        'services/events/actEmitInfobipAffiliateOfferSelected',
    }),
    emitEvents() {
      this.actEmitInfobipButtonClickEvent({
        product: 'fgts',
        button_text: 'ir_para_whatsapp',
      })

      const identifier =
        Partner.BV === this.cptdPartner
          ? 'FGTS_WHATSAPP_REDIRECT'
          : `PARTNER_${this.cptdPartner}_REDIRECT`

      this.actInsertIntent({
        identifier,
      })

      this.actEmitGaEvent({
        eventCategory: `card-${this.cptdPartner?.toLowerCase()}-fgts`,
        eventLabel: 'redirected-to-whatsapp',
        eventAction: 'redirected-to-whatsapp',
      })
    },
  },
}
</script>
<style lang="scss">
.FgtsRedirectPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-brand-primary;

  & > .ContainerWrapper {
    & > .ContentContainerWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .title {
        @include headline-5;
        color: $color-white-high;
        text-align: center;
        margin: 0 0 16px 0;
      }
      & > .description {
        @include body-1;
        text-align: center;
        color: $color-white-medium;
        margin: 0 0 32px 0;
      }
      & > .actions {
        width: 100%;
        max-width: 328px;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
</style>
