<template>
  <nav v-if="!isCurrentPage('login')" class="nav-menu">
    <div class="list">
      <div class="item">
        <RouterLink
          id="home"
          class="link"
          :class="cssMenuActive(['home'])"
          to="/home"
        >
          <BaseIcon v-if="isCurrentPage('home')" name="home-color" />
          <BaseIcon v-else name="home-mono" />
          Início
        </RouterLink>
      </div>
      <div class="item">
        <RouterLink
          id="simulate"
          class="link"
          :class="cssMenuActive(['simular'])"
          to="/simular"
        >
          <BaseIcon v-if="isCurrentPage('simular')" name="simular-color" />
          <BaseIcon v-else name="simulation-mono" />
          Simular
        </RouterLink>
      </div>
      <div class="item">
        <RouterLink
          id="offer"
          class="link"
          :class="
            cssMenuActive([
              'ofertas',
              'ofertas-fgts',
              'cartao-credito-ofertas',
              'negociacao-dividas',
              'seguros-ofertas',
            ])
          "
          to="/ofertas"
        >
          <BaseIcon
            v-if="
              isCurrentPage('ofertas') ||
              isCurrentPage('ofertas-fgts') ||
              isCurrentPage('cartao-credito-ofertas') ||
              isCurrentPage('negociacao-dividas') ||
              isCurrentPage('seguros-ofertas')
            "
            name="offers-color"
          />
          <BaseIcon v-else name="offers-mono" />
          Ofertas
        </RouterLink>
      </div>
      <div class="item -account">
        <RouterLink class="link" :class="cssMenuActive(['conta'])" to="/conta">
          Conta
        </RouterLink>
      </div>
    </div>
  </nav>
</template>
<script>
import BaseIcon from '@/aasgard/components/base/BaseIcon.vue'

export default {
  name: 'NavMenu',
  components: {
    BaseIcon,
  },
  computed: {
    cptdCurrentPage() {
      return this.$route?.name ?? ''
    },
  },
  methods: {
    cssMenuActive(pages) {
      return {
        '-active': pages.some((page) => this.isCurrentPage(page)),
      }
    },
    isCurrentPage(page) {
      return this.cptdCurrentPage === page
    },
  },
}
</script>
<style lang="scss">
.nav-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 20;
  height: 64px;
  background: white;
  border: 1px solid rgba(194, 201, 209, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;

  @include media('md') {
    display: flex;
    position: relative;
    background: transparent;
    border: none;
    width: auto;
  }

  & > .list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 100%;
    padding: 0;
    width: 100%;

    @include media('md') {
      width: 100%;
    }

    & > .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      width: 25%;
      height: 100%;

      @include media('md') {
        width: auto;
        &:not(:first-child) {
          margin-left: 24px;
        }
      }

      .link {
        @include caption;
        box-sizing: border-box;
        color: $color-brand-tertiary;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        transition:
          color 0.1s,
          background-color 0.1s,
          padding 0.2s ease-in;
        flex-direction: column;
        cursor: pointer;

        .Notification {
          position: absolute;
          top: 4px;
          right: 11px;

          @include media('md') {
            top: 0;
            right: -8px;
          }
        }

        @include media('md') {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          padding: 0 16px;
          color: $color-white-high;
          border-bottom: 2px solid transparent;
        }

        svg {
          @include media('md') {
            display: none;
          }
        }

        &:hover,
        &:focus,
        &:active,
        &.-active {
          color: $color-brand-primary;
          text-decoration: none;

          @include media('md') {
            color: $color-brand-secondary-light;
            border-bottom: 2px solid $color-brand-secondary-light;
            font-weight: bold;
          }

          path {
            fill: $color-brand-primary;
          }

          circle {
            stroke: $color-brand-primary;
          }

          .line {
            stroke: $color-brand-primary;
          }
        }

        &:hover {
          border-color: transparent;
          font-weight: normal;

          &.-active {
            border-color: $color-brand-secondary-light;
            font-weight: bold;
          }

          &::before {
            transform-origin: left top;
            transform: scale(1, 1);
          }
        }

        &:focus {
          &::before {
            transform-origin: left top;
            transform: scale(1, 1);
          }
        }

        &.-active {
          &::before {
            transform-origin: left top;
            transform: scale(1, 1);
          }
        }
      }

      &.-account {
        display: none;

        @include media(md) {
          display: block;
        }
      }
    }
  }
}
</style>
