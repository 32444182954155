import BankAccountValidatorTool from '../../digitCalculator'
import utilities from '@/lib/accountValidator/bank-validator-js/utilities'

function checkHsbcBankAccount(number, number_digit, branch) {
  const branchPadded = utilities.padStart(branch, 4)
  if (branchPadded.length > 4) return false

  const numberPadded = utilities.padStart(number, 6)
  if ((numberPadded + number_digit).length > 7) return false

  const fullAccountNumberWithoutDigit = branch + numberPadded
  const digit_calculator = new BankAccountValidatorTool.Banks.DigitCalculator(
    fullAccountNumberWithoutDigit,
    [8, 9, 2, 3, 4, 5, 6, 7, 8, 9],
  )

  const digit = digit_calculator.sum_numbers() % 11

  if (digit === 0 || digit === 10) {
    return number_digit === '0'
  }

  return String(digit) === number_digit
}

export default checkHsbcBankAccount
