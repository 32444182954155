<template>
  <footer
    :class="[
      'app-footer',
      {
        '-short': routeName === 'simulando',
      },
    ]"
  >
    <p class="text">
      O Juros Baixos é uma empresa intermediadora do processo de concessão de
      crédito entre bancos, fintechs e clientes. Não somos uma instituição
      financeira. Somos correspondente bancário da Zema CFI S.A. – CNPJ/MF
      05.351.887/0001-86, Banco Votorantim S.A. – CNPJ/ME: 59.588.111/0001-03,
      Portocred S.A. Crédito, Financiamento e Investimento - CNPJ
      01.800.019/0001-85, Aymoré Crédito, Financiamento e Investimento S.A. -
      CNPJ: 07.707.650/0001-10, regulamentado pela lei 3.954/11 do Banco Central
      do Brasil.
      <br />-<br />
      Juros Baixos | JB AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL LTDA -
      CNPJ.: 28.812.324/0001-43.<br /><br />
      Rua Pamplona, 1119, salas 43 e 44, Jardim Paulista/SP - CEP 01405-001.
    </p>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  computed: {
    routeName() {
      return this.$route?.name || ''
    },
  },
}
</script>
<style lang="scss">
.app-footer {
  display: flex;
  justify-content: center;
  padding: 24px;
  margin-top: 24px;
  border-top: 1px solid $color-neutral-dark;

  & > .text {
    max-width: 1280px;
    font-size: 10px;
    color: $color-black-high;
    font-family: 'Inter';

    @include media(lg) {
      max-width: 1280px;
    }
  }

  &.-short {
    & > .text {
      max-width: 760px;
    }
  }
}
</style>
