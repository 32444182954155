<template>
  <RadioInputList
    v-bind="$attrs"
    :id="$attrs.id || 'accountType'"
    :key="$attrs.key || 'accountType'"
    :title="$attrs.title || 'Tipo de conta'"
    :options="cptdAccountTypeOptions"
    :searchable="$attrs.searchable !== undefined ? $attrs.searchable : false"
    @input="$emit('input', $event)"
  />
</template>
<script>
import RadioInputList from '@/components/ui/newComponents/RadioInputList.vue'
export default {
  name: 'BankAccountTypeRadioList',
  components: {
    RadioInputList,
  },
  inheritAttrs: false,
  emits: ['input'],
  computed: {
    cptdAccountTypeOptions() {
      if (this.$attrs?.options?.length > 0) {
        return this.$attrs.options
      }
      return [
        {
          label: 'Conta Corrente',
          value: 'CHECKING',
          id: 'CHECKING',
          options: {
            badge: {
              type: 'info',
              label: 'Recomendado',
            },
          },
        },
        {
          label: 'Conta Poupança',
          value: 'SAVING',
          id: 'SAVING',
        },
      ]
    },
  },
}
</script>
<style lang="scss"></style>
